import { API, PerformJSONRPCRequest } from "../../../../api/jsonrpc";

export interface CreateManyRequest {
  opportunityIDs: string[];
  goalID: string;
  clientName: string;
}

export interface CreateManyResponse {
  success: boolean;
}

export interface DeleteOneRequest {
  opportunityID: string;
  goalID: string;
  clientName: string;
}

export interface DeleteOneResponse {
  success: boolean;
}
export interface LinkMultipleRequest {
  opportunityIDs: string[];
  goalIDs: string[];
  clientName: string;
}

export interface LinkMultipleResponse {
  success: boolean;
}

export interface FindAllByOppIdRequest {
  opportunityID: string;
  clientName: string;
}

export interface FindAllByOppIdResponse {
  goalOpps : GoalOpportunity[]
}

export interface GoalOpportunity {
  opportunity_ID: string
  goalID: string
}

const Store = {
  serviceProvider: 'Goal-Opportunity-Store',
  async CreateMany(request: CreateManyRequest): Promise<CreateManyResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CreateMany`,
      request: [{
        "opportunityIDs": request.opportunityIDs,
        "goalID": request.goalID,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.DeleteOne`,
      request: [{
        "opportunityID": request.opportunityID,
        "goalID": request.goalID,
        "clientName": request.clientName
      }]
    });
    return response
  },
  //Used to link Goals and Opportunities accepting one-many of each
  //If single opportunity will delete deselected goals
  //If multiple opportunities will not delete, only add new relations
  async LinkMultiple(request: LinkMultipleRequest): Promise<LinkMultipleResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.LinkMultiple`,
      request: [{
        "opportunityIDs": request.opportunityIDs,
        "goalIDs": request.goalIDs,
        "clientName": request.clientName
      }]
    });
    return response
  },

  async FindAllLinkedByOppId(request: FindAllByOppIdRequest): Promise<FindAllByOppIdResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.FindAll`,
      request: [{
        "opportunityID": request.opportunityID,
        "clientName": request.clientName
      }]
    });
    console.log("FindAllLinkedByOppId response",response)
    return response
  },
}

export default Store;