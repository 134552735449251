import React from 'react';
import type { FC } from 'react';
import logoPath from "./WutowIcon.svg"

interface WutowLogoProps {
  [key: string]: any;
}

const WutowLogo: FC<WutowLogoProps> = (props) => (
  <img
    alt="Wutow Logo"
    src={logoPath}
    height={100}
    width={200}
    style={{
      margin: 1
    }}
    {...props}
  />
);

export default WutowLogo;