/* eslint-disable */

import {PerformJSONRPCRequest, API} from '../../../api/jsonrpc';
import Template from "./Template";

export interface CloneOneRequest {
  dashboardId: string;
  template: Template[];
}

export interface CloneOneResponse {
  dashboardId: string;
}

const Store = {
  serviceProvider: 'Dashboard-Cloner',
  async CloneOne(request: CloneOneRequest): Promise<CloneOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CloneOne`,
      request: [{
        'dashboardId': request.dashboardId,
        'template': request.template
      }]
    });
    return {
      dashboardId: response.dashboardId,
    };
  }
}

export default Store;