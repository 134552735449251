/* eslint-disable */
import {FilterItemProps} from "../../../pages/app/keystone/filter/Filter";

export interface FilterItem {
    prefix: string
    header: string
    operation: string
    values: any
    level?: boolean
    FilterItemProps?: any
    // checked?: boolean
}

export default class Filter {
    public id?: string = '';
    public userID: string = '';
    public organizationID: string = '';
    public filters: FilterItem[] = [];

    constructor(filter?: Filter) {
        if (!filter) {
            return;
        }
        this.id = filter.id;
        this.userID = filter.userID;
        this.organizationID = filter.organizationID;
        this.filters = filter.filters;
    }
}

export class ColumnMapDict {
    public Overall_Hierarchy_1: string = '';
    public Overall_Hierarchy_2: string = '';
    public Product_Hierarchy_1: string = '';
    public Product_Hierarchy_2: string = '';
    public Product_Hierarchy_3: string = '';
    public Product_Hierarchy_4: string = '';
    public Product: string = '';
    public Product_Code: string = '';
    public Customer_Hierarchy_1: string = '';
    public Customer_Hierarchy_2: string = '';
    public Customer_Hierarchy_3: string = '';
    public Area_Hierarchy_1: string = '';
    public Area_Hierarchy_2: string = '';
    public Area_Hierarchy_3: string = '';
    public Area_Hierarchy_3_Code: string = '';

    public Value: string = ''
    public Qty: string = ''
    public Date: string = ''

    constructor(colMap?: ColumnMapDict) {
        if (!colMap) {
            return;
        }
        this.Overall_Hierarchy_1 = colMap.Overall_Hierarchy_1;
        this.Overall_Hierarchy_2 = colMap.Overall_Hierarchy_2;
        this.Product_Hierarchy_1 = colMap.Product_Hierarchy_1;
        this.Product_Hierarchy_2 = colMap.Product_Hierarchy_2;
        this.Product_Hierarchy_3 = colMap.Product_Hierarchy_3;
        this.Product_Hierarchy_4 = colMap.Product_Hierarchy_4;
        this.Product = colMap.Product;
        this.Product_Code = colMap.Product_Code;
        this.Customer_Hierarchy_1 = colMap.Customer_Hierarchy_1;
        this.Customer_Hierarchy_2 = colMap.Customer_Hierarchy_2;
        this.Customer_Hierarchy_3 = colMap.Customer_Hierarchy_3;
        this.Area_Hierarchy_1 = colMap.Area_Hierarchy_1;
        this.Area_Hierarchy_2 = colMap.Area_Hierarchy_2;
        // Area_Hierarchy_3 is actually stores
        this.Area_Hierarchy_3 = colMap.Area_Hierarchy_3;
        this.Area_Hierarchy_3_Code = colMap.Area_Hierarchy_3_Code;

        this.Value = colMap.Value;
        this.Qty = colMap.Qty;
    }
}