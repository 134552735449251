import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {AppThunk} from 'src/store';
import initialState from "./initialState";
import {ExecuteOneResponse, Executor} from "../cog/bitool/executor";

// todo: fix this
import {BIDashboard} from "../types/bitool";

import Widget from "../cog/bitool/widget/Widget";
import WidgetStore from "../cog/bitool/widget/Store";


// Interfaces
export interface DashboardState {
    dashboard: BIDashboard
}

interface ChangeIsLoading {
    id: string;
    isLoading: boolean;
}

interface ChangeIOData {
    id: string;
    data: Record<string, any>;
}

interface ChangeColumnData {
    id: string;
    columns: Array<string>;
}

interface ChangeExecutorController {
    id: string;
    controller: AbortController;
}

interface ChangeWidget {
    id: string;
    widget: Widget;
}

interface ChangeSelectedMemberIndex {
    id: string;
    index: number;
}

interface ChangeVariable {
    id: string;
    data: any;
}

interface SetWidgets {
    widgets: Record<string, Widget>
}

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setInitialized(state: DashboardState, action: PayloadAction<boolean>) {
            state.dashboard.initialized = action.payload
        },
        setReportMode(state: DashboardState, action: PayloadAction<boolean>) {
            state.dashboard.reportMode = action.payload
        },
        setEmbedMode(state: DashboardState, action: PayloadAction<boolean>) {
            state.dashboard.embedMode = action.payload
        },
        setWidgets(state: DashboardState, action: PayloadAction<SetWidgets>) {
            state.dashboard.widgets = action.payload.widgets
        },
        widgetChangeIsLoading(state: DashboardState, action: PayloadAction<ChangeIsLoading>) {
            state.dashboard.widgetIsLoading[action.payload.id] = action.payload.isLoading
        },
        widgetChangeExecutorController(state: DashboardState, action: PayloadAction<ChangeExecutorController>) {
            state.dashboard.widgetExecutorControllers[action.payload.id] = action.payload.controller
        },
        widgetChangeData(state: DashboardState, action: PayloadAction<ChangeIOData>) {
            //console.time('widgetChangeData')
            state.dashboard.widgetData[action.payload.id] = action.payload.data
            //console.timeEnd('widgetChangeData')
            // console.log(state.dashboard.widgets[action.payload.id].name, action.payload.id )
        },
        widgetChangeColumns(state: DashboardState, action: PayloadAction<ChangeColumnData>) {
            //console.time('widgetChangeData')
            state.dashboard.widgetColumns[action.payload.id] = action.payload.columns
            //console.timeEnd('widgetChangeData')
            //console.log(state.dashboard.widgets[action.payload.id].name, action.payload.id )
        },
        widgetChangeTemplate(state: DashboardState, action: PayloadAction<ChangeIOData>) {
            state.dashboard.widgetTemplates[action.payload.id] = action.payload.data
        },
        widgetChangeVariable(state: DashboardState, action: PayloadAction<ChangeVariable>) {
            if (!state.dashboard.reportMode) {
                state.dashboard.widgetVariables[action.payload.id] = action.payload.data
            }
        },
        widgetChangeVariableData(state: DashboardState, action: PayloadAction<Record<string, any>>) {
            Object.keys(action.payload).map((k) => {
                state.dashboard.widgetVariables[k] = action.payload[k]
            })
            // state.dashboard.widgetVariables = action.payload.data
        },

        widgetChangeWidget(state: DashboardState, action: PayloadAction<ChangeWidget>) {
            state.dashboard.widgets[action.payload.id] = action.payload.widget
        },

        widgetChangeSelectedGroupMemberIndex(state: DashboardState,
                                             action: PayloadAction<ChangeSelectedMemberIndex>) {
            state.dashboard.widgetSelectedGroupMemberIndex[action.payload.id] = action.payload.index
        },
        widgetChangeSelectedGroupMemberMaxIndex(state: DashboardState,
                                             action: PayloadAction<ChangeSelectedMemberIndex>) {
            state.dashboard.widgetSelectedGroupMemberMaxIndex[action.payload.id] = action.payload.index
        }
    }
});

export const reducer = slice.reducer;


export const initBitool = (dashboardId: string, reportMode?: boolean, embedMode?: boolean): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.setInitialized(false))
    if (reportMode) dispatch(slice.actions.setReportMode(reportMode))
    if (embedMode) dispatch(slice.actions.setEmbedMode(embedMode))
    let response = await WidgetStore.FindAll({
        dashboardId: dashboardId,
        report: reportMode || embedMode
    })
    let resp = response.widgets.map((w) => {
        return {[w.id]: w}
    })
    let respCombined = resp.reduce(function(result, current) {
        return Object.assign(result, current);
    }, {})
    dispatch(slice.actions.setWidgets({widgets: respCombined}))
    dispatch(widgetsInit())
}

export const widgetsInit = (): AppThunk => async (dispatch, getState) => {
    await dispatch(slice.actions.setInitialized(false))
    const state = getState()
    Object.keys(state.biTool.dashboard.widgets).map((k) => {
        dispatch(slice.actions.widgetChangeData({
            id: state.biTool.dashboard.widgets[k].id,
            data: null
        }))
        dispatch(slice.actions.widgetChangeColumns({
            id: state.biTool.dashboard.widgets[k].id,
            columns: null
        }))
        dispatch(slice.actions.widgetChangeTemplate({
            id: state.biTool.dashboard.widgets[k].id,
            data: state.biTool.dashboard.widgets[k].arguments.templates
        }))
        dispatch(slice.actions.widgetChangeVariable({
            id: state.biTool.dashboard.widgets[k].id,
            data: null
        }))

        if (state.biTool.dashboard.widgets[k].arguments.templates) {
            Object.keys(state.biTool.dashboard.widgets[k].arguments.templates).map((i) => {
                const t = state.biTool.dashboard.widgets[k].arguments.templates[k]
                if (t && t.hasOwnProperty('variableId')) {
                    dispatch(slice.actions.widgetChangeVariable({
                        id: state.biTool.dashboard.widgets[k].id,
                        data: null
                    }))
                }
            })
        }
        if (state.biTool.dashboard.widgets[k].arguments.type) {
            if (state.biTool.dashboard.widgets[k].arguments.type.toLowerCase().includes("group")) {
                dispatch(slice.actions.widgetChangeSelectedGroupMemberIndex({
                    id: state.biTool.dashboard.widgets[k].id, // == k?
                    index: 0
                }))
                dispatch(slice.actions.widgetChangeSelectedGroupMemberMaxIndex({
                    id: state.biTool.dashboard.widgets[k].id, // == k?
                    index: 0
                }))
            }
        }
    })
    await dispatch(slice.actions.setInitialized(true))
};

export const widgetExecute = (widget: Widget, template: {}, controller: AbortController): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.widgetChangeIsLoading({id: widget.id, isLoading: true}));
    let executedResults: ExecuteOneResponse = {
        columns: [],
        data: {},
        Errors: null
    }
    if (widget.arguments.executorArgs && (template)) {
        executedResults = (await Executor.ExecuteOne({
            report: getState().biTool.dashboard.embedMode,
            connectorId: widget.arguments.executorArgs.connectorId,
            queryId: widget.arguments.executorArgs.queryId,
            templates: template,
            controller: controller,
        }))
    }
    if (executedResults.Errors) {
        console.log("Errorsssss: ", executedResults.Errors)
    }

    dispatch(slice.actions.widgetChangeData({id: widget.id, data: executedResults.data}));
    dispatch(slice.actions.widgetChangeColumns({id: widget.id, columns: executedResults.columns}));
    if (executedResults.Errors != 'finished early') {
        dispatch(slice.actions.widgetChangeIsLoading({id: widget.id, isLoading: false}));
    }
    if (!executedResults.data) {
        dispatch(slice.actions.widgetChangeVariable({id: widget.id, data: null}));
    }

}

export const widgetChangeVariable = (id: string, outputData: any): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.widgetChangeVariable({id: id, data: outputData}));
};

export const widgetUpdate = (id: string, widget: Widget): AppThunk => async (dispatch, getState) => {
    dispatch(slice.actions.widgetChangeTemplate({
        id: id,
        data: widget.arguments.templates
    }))
    dispatch(slice.actions.widgetChangeData({
        id: id,
        data: null
    }))
    dispatch(slice.actions.widgetChangeVariable({
        id: id,
        data: null
    }))
    if (widget.arguments.type.toLowerCase().includes("group")) {
        dispatch(slice.actions.widgetChangeSelectedGroupMemberIndex({
            id: widget.id,
            index: 0
        }))
    }

    dispatch(slice.actions.widgetChangeWidget({id: id, widget: widget}));
};


export default slice;


