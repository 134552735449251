import React, { FC } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BullRedLogo from "../../../../../icons/logos/BullRedLogo";
import CASalesLogo from "../../../../../icons/logos/CASalesLogo";
import CloverLogo from "../../../../../icons/logos/CloverLogo";
import KimberlyClarkLogo from "../../../../../icons/logos/KimberlyClarkLogo";
import LogicoLogo from "../../../../../icons/logos/LogicoLogo";
import PNSLogo from "../../../../../icons/logos/PNSLogo";
import RBLogo from "../../../../../icons/logos/RBLogo";
import RCLLogo from "../../../../../icons/logos/RCLLogo";
import SMCLogo from "../../../../../icons/logos/SMCLogo";
import UnileverLogo from "../../../../../icons/logos/UnileverLogo";
import WutowLogo from "../../../../../icons/logos/WutowLogo";
import EditLogo from "../../../../../icons/logos/EditLogo";

interface OrgCardProps {
  name: string;
  orgId: string;
}

const OrgCard: FC<OrgCardProps> = ({ name, orgId }) => {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate("/app/bi/dashchoose/" + orgId);
  };

  switch (name) {
    case "BR DISTRIBUTION":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box ml={1}>
                  <BullRedLogo />
                </Box>
                <Typography ml={9}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "CASALES":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box ml={7}>
                  <CASalesLogo />
                </Box>
                <Typography ml={9}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "CLOVER":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <Box ml={1}>
                  <CloverLogo />
                </Box>
                <Typography ml={9}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "CHECKERS_KC":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <KimberlyClarkLogo />
                <Typography ml={6}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "CHECKERS_RB":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <Box ml={1}>
                  <RBLogo />
                </Box>
                <Typography ml={6}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "EDITING":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <Box ml={1}>
                  <EditLogo />
                </Box>
                <Typography ml={9}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "KIMBERLY CLARK":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <KimberlyClarkLogo />
                <Typography ml={5}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "LOGICO":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <Box ml={4} mt={2}>
                  <LogicoLogo />
                </Box>
                <Typography ml={9}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "MASSCASH_KC":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <KimberlyClarkLogo />
                <Typography ml={6}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "MASS_DISCOUNTERS_KC":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <KimberlyClarkLogo />
                <Typography ml={1}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "MAKRO_KC":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <KimberlyClarkLogo />
                <Typography ml={8}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "MANAGEMENT":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <Box ml={7}>
                  <CASalesLogo />
                </Box>
                <Typography ml={6}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "PNP_KC":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <KimberlyClarkLogo />
                <Typography ml={9}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "PNS":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <Box ml={7}>
                  <PNSLogo />
                </Box>
                <Typography ml={11}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "RCL":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <RCLLogo />
                <Typography ml={11}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "SMC":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <SMCLogo />
                <Typography ml={11}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "UNILEVER":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <Box ml={6}>
                  <UnileverLogo />
                </Box>
                <Typography ml={8}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    case "WUTOW":
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <Box ml={4} mt={2}>
                  <WutowLogo />
                </Box>
                <Typography ml={10}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    default:
      return (
        <Grid item>
          <Card style={{ height: 150, width: 250 }}>
            <CardActionArea onClick={() => handleClick()}>
              <CardContent>
                <Box ml={1}>
                  <EditLogo />
                </Box>
                <Typography ml={8}>{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
  }
};

export default OrgCard;
