/* eslint-disable */

import {GridSize} from "@mui/material";

export interface Location {
    x: number;
    y: number;
    w: number;
    h: number;
    [key: string]: any;
}

export interface GridLocation {
    lg?: Location;
    md?: Location;
    sm?: Location;
}

export interface WidgetGroupItemWithWidget extends WidgetGroupItem {
    widget?: Widget
}

export interface WidgetGroupItem {
    id: string;
    label?: string;
    description?: string;
    gridLocation?: GridLocation;
    row?: number;
}

export interface Template {
    value: any;

    widgetId?: string;
    variableId?: string;

    keyOrIndex?: string | number;
    serializerStringFunction?: string;

    invisible?: boolean;
}

// export interface Variable {
//     value: any;
//     timestamp: number;
// }

export interface ExecutorArgs {
    queryId: string;
    connectorId: string;
}

export interface WidgetArguments {
    type: string;

    location?: GridLocation | Location | null;
    orientation?: "vertical" | "horizontal"

    group?: WidgetGroupItem[]; // https://stackoverflow.com/a/5525820

    code?: string;

    executorArgs?: ExecutorArgs;
    templates?: Record<string, Template>;

    setVariableIds?: string[];
}

export default class Widget {
    public id?: string = '';
    public name: string = '';
    public dashboardId: string = '';
    public arguments?: WidgetArguments = null;

    constructor(widget?: Widget) {
        if (!widget) {
            return;
        }
        this.id = widget.id;
        this.name = widget.name;
        this.dashboardId = widget.dashboardId;
        this.arguments = widget.arguments;
    }
}
