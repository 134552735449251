import * as React from "react";
import {FC, useEffect, useState} from "react";
import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Stack,
    Step,
    StepButton,
    Stepper,
    Typography
} from "@mui/material";
import {useMainContext} from "../../../../../contexts/MainContext";
import PieChartStat from "../user/PieChart";
import SingleValueIndicator from "../user/SingleValueIndicator";
import {GridColDef, GridEventListener} from "@mui/x-data-grid";
import {DataGridPro, DataGridProProps, GridRowId} from '@mui/x-data-grid-pro';
import {StyledDataGrid} from "../../listview/CustomDataGridTheme";
import HeatMapStat from "../user/heatmap";
import currencyFormatter from "src/utils/currencyFormatter";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {UserReportingStore} from "../../../../../cog/keystone/reporting";
import HomeIssueSkeleton from "../../summary/Skeleton";
import _ from "lodash";
import currencySwitcher from "../../../../../utils/currencySwitcher";
import {Helmet} from "react-helmet-async";
import {ErrorOutline} from "@mui/icons-material";
import {
    ConvertTreeFromBeingUsedToApiSave,
    GenerateNewPsuTree
} from "../../productstoreuniverse/utility/NodeTypesAndOptions";

const steps = ['All Users Contribution', 'Selected User Contribution'];

interface StatsViewProps {
}

const MyCustomNoRowsOverlay = () => (
    <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        zIndex: 99
    }}><Typography variant={"h5"}>No Opps</Typography>
        <ErrorOutline/>
    </Box>
)

const UserReportingPage: FC<StatsViewProps> = (props) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const [loading, setLoading] = useState<boolean>()
    const [userLoading, setUserLoading] = useState<boolean>(true)
    const [activeUser, setActiveUser] = useState<string>(null)
    const [userRepData, setUserRepData] = useState<any>({})
    const [specificUserRepData, setSpecificUserRepData] = useState<any>({})
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
    
    // Sets which options to display in the toolbar
    useEffect(() => {
        if (keystoneCtx.toolBarDisplayOptions.selectOpps != false || keystoneCtx.toolBarDisplayOptions.filterToMe != false || keystoneCtx.toolBarDisplayOptions.stats != false || keystoneCtx.toolBarDisplayOptions.filter != false || keystoneCtx.toolBarDisplayOptions.landingDateSelector != true) {
            keystoneCtx.setToolBarDisplayOptions({
                filter: false,
                stats: false,
                selectOpps: false,
                filterToMe: false,
                landingDateSelector: true
            })
            
        }
    }, [keystoneCtx.toolBarDisplayOptions])
    
    
    const fetchUserRepStatData = async () => {
        setLoading(true)
        
        const dateSelectionStart = keystoneCtx.dateValue[0].toISOString().slice(0, 10)
        const dateSelectionEnd = keystoneCtx.dateValue[1].toISOString().slice(0, 10)
        // const tempStartDate = "2021-10-02"
        // const tempEndDate = "2024-05-27"
        let limit = "15"
        
        if (window.innerWidth <= 800) {
            limit = "5"
        }
        
        const response = await UserReportingStore.UserReportingStats({
            clientName: mainCtx.activeOrganization.name,
            // dateSelectionStart: tempStartDate,
            dateSelectionStart: dateSelectionStart,
            // dateSelectionEnd: tempEndDate,
            dateSelectionEnd: dateSelectionEnd,
            limit: limit,
            filters: keystoneCtx.filters,
            defaultFilters: keystoneCtx.defaultFilters,
            PSU: keystoneCtx.activePSU != null
                ? ConvertTreeFromBeingUsedToApiSave(keystoneCtx.activePSU)
                : ConvertTreeFromBeingUsedToApiSave(GenerateNewPsuTree(mainCtx.activeOrganization.name))
        })
        
        if (response) {
            console.log("resping", response)
            setUserRepData(response)
        }
        setLoading(false)
    }
    
    const fetchSpecificUserRep = async () => {
        setUserLoading(true)
        let user: string
        if (activeUser && activeUser.length >= 2 && activeUser.startsWith('"') && activeUser.endsWith('"')) {
            user = activeUser.slice(1, -1);
        } else {user = activeUser}
        const dateSelectionStart = keystoneCtx.dateValue[0].toISOString().slice(0, 10)
        const dateSelectionEnd = keystoneCtx.dateValue[1].toISOString().slice(0, 10)
        // const tempStartDate = "2021-10-02"
        // const tempEndDate = "2024-05-27"
        let limit = "15"
        
        if (window.innerWidth <= 800) {
            limit = "5"
        }
        
        const response = await UserReportingStore.UserSpecificReportingStats({
            clientName: mainCtx.activeOrganization.name,
            userName: user,
            // dateSelectionStart: tempStartDate,
            dateSelectionStart: dateSelectionStart,
            // dateSelectionEnd: tempEndDate,
            dateSelectionEnd: dateSelectionEnd,
            limit: limit,
            filters: keystoneCtx.filters,
            defaultFilters: keystoneCtx.defaultFilters,
            PSU: keystoneCtx.activePSU != null
                ? ConvertTreeFromBeingUsedToApiSave(keystoneCtx.activePSU)
                : ConvertTreeFromBeingUsedToApiSave(GenerateNewPsuTree(mainCtx.activeOrganization.name))
        })
        
        if (response) {
            console.log("resping spec", response)
            setSpecificUserRepData(response)
        }
        setUserLoading(false)
    }
    
    useEffect(() => {
        if (keystoneCtx.filtersReady == true) {
            fetchUserRepStatData()
        }
    }, [mainCtx.activeOrganization.name, keystoneCtx.dateValue, keystoneCtx.flipSwitchForCards])
    
    useEffect(() => {
        if (userRepData && Object.keys(userRepData).length > 0) {
            fetchSpecificUserRep()
        }
    }, [activeUser, keystoneCtx.dateValue])
    
    
    const edit = (params: string) => {
        let assignedMembers: string
        if (params && params.length >= 2 && params.startsWith('"') && params.endsWith('"')) {
            assignedMembers = params.slice(1, -1);
        } else {assignedMembers = params}
        
        return mainCtx.members && mainCtx.members.find((mem) => mem.id === assignedMembers) ? mainCtx.members.find((mem) => mem.id === assignedMembers).displayName : ""
    }
    
    
    const columns: GridColDef[] = [{field: 'id', headerName: '#', width: 10}, {
        field: 'Username', valueFormatter: (params => {
            
            return edit(params.value)
            
        }), headerName: 'User', flex: 1,
    }, {
        field: 'Efficiency_Rate', headerName: 'Efficiency Rate', flex: 1,  description: 'Opportunities Realised VS Total Opportunities Assigned ',
        valueFormatter: ({value}) => (typeof value === "number") ? (Math.round(value * 100)).toFixed(0).toString() + "%" : null,
    }, {
        field: 'Contribution',
        headerName: 'Contribution',
        type: 'number',
        description: 'Total Contribution of User as a Weekly Value',
        valueFormatter: ({value}) => (typeof value === "number") ? currencyFormatter(mainCtx.activeOrganization.name, (Math.round(value * 100) / 100).toFixed(2)) : null,
        flex: 1,
    }];
    const columnsUserDropDown: GridColDef[] = [{field: 'id', headerName: '#', width: 10}, {
        field: 'Username', valueFormatter: (params => {
            return edit(params.value)
            
        }), headerName: 'Username', flex: 1,
    }, {
        field: 'Contribution',
        headerName: 'Contribution',
        type: 'number',
        valueFormatter: ({value}) => (typeof value === "number") ? currencyFormatter(mainCtx.activeOrganization.name, (Math.round(value * 100) / 100).toFixed(2)) : null,
        flex: 1,
    },];
    const oppsColumns: GridColDef[] = [{field: 'id', headerName: '#', width: 10}, {
        field: 'assigned_to', valueFormatter: (params => {
            return edit(params.value)
            
        }), headerName: 'User', flex: 1,
    }, {
        field: 'opportunity_type', headerName: 'Opportunity Type', flex: 1,
    }, {
        field: 'value',
        headerName: 'Value',
        type: 'number',
        valueFormatter: ({value}) => (typeof value === "number") ? currencyFormatter(mainCtx.activeOrganization.name, (Math.round(value * 100) / 100).toFixed(2)) : null,
        flex: 1,
    },]
    
    const handleChange = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    
    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        console.log(params.row)
        setActiveUser(params.row.Username ? params.row.Username : params.row.assigned_to)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };
    
    const genBarSeries = (data: object) => {
        let tempSeries: object[] = [];
        
        // Assuming 'opportunity_gained' is the common key in all scenarios
        const commonKey = 'opportunity_gained';
        
        if (data != null) {
            // Iterate over keys in data
            Object.keys(data).forEach((key) => {
                // Skip the common key
                if (key !== commonKey) {
                    data[key].forEach((k: any, index: number) => {
                        let tempObj  = {
                            name: k,
                            data: [data[commonKey][index]],
                        };
                        
                        tempSeries.push(tempObj);
                    });
                }
            });
        }
        
        console.log(tempSeries);
        return tempSeries;
    };
    
    const removeObject = (ObjFilter, oKey): object => {
        if (ObjFilter != null) {
            ObjFilter = Object.keys(ObjFilter).filter(key => key !== oKey).reduce((obj, key) => {
                obj[key] = ObjFilter[key];
                return obj;
            }, {});
            return ObjFilter
        }
    }
    
    const combineArrays = (first, second) => {
        
        try {
            return first.reduce((acc, val, ind) => {
                acc[val] = second[ind];
                return acc;
            }, {});
        } catch {
            console.log("error combining", first, second)
        }
        
    };
    const mapTableRows = (data: object) => {
        let tempRows = []
        if (data && Object.keys(data) && Object.keys(data).length) _.range(data[Object.keys(data)[0]].length).map((idx) => {
            let row = {}
            row['id'] = idx + 1
            Object.keys(data).map((k) => {
                if (Number.isFinite(data[k][idx]) && data[k][idx] !== null) {
                    let temp = data[k][idx]
                    row[k] = temp
                } else {
                    row[k] = data[k][idx]
                }
            })
            tempRows.push(row)
        })
        return tempRows
    }
    
    const columnsTEST: GridColDef[] = [
        //{ field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'Name',
            headerName: 'Product Name',
            flex: 1,
            width: 150,

            //editable: true,
        },
        {
            field: 'Display_Value',
            headerName: 'Store',
            flex: 1,
            width: 150,
            
            //editable: true,
        },
        {
            field: 'Date_Created',
            headerName: 'Date Created',
            flex: 1,
            width: 150,
            
            //editable: true,
        },
        {
            field: 'assigned_to',
            headerName: 'Assigned To',
            width: 150,
            //editable: true,
        },
        {
            field: 'Value',
            headerName: 'Value',
            flex: 0.5,
            width: 150,
            //editable: true,
        },
        {
            field: 'Opportunity_Type',
            headerName: 'Opportunity Type',
            width: 150,
            //editable: true,
        },
    ];
    
    //JANKY REPETITION of Content- TODO: Make one
    function DetailPanelContent({ row: rowProp, data, selectedUser }: { row: any, data: any, selectedUser: string }) {
        console.log(rowProp, data);

        
        return (
            <Stack
                sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
                direction="column"
            >
                <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                    <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                        <Typography variant="h6">{`Opportunities Assigned`}</Typography>
                        <Grid container>
                            <DataGridPro
                                rows={mapTableRows(data['AllUsersLiveOpps'])}
                                columns={columnsTEST}
                                autoHeight
                                sx={{ flex: 1 }}
                                hideFooter
                                density="compact"
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            Opportunity_Type: false,
                                            assigned_to: false,
                                        }
                                    },
                                    filter: {
                                        filterModel: {
                                            items: [{
                                                id: 1,
                                                columnField: 'Opportunity_Type',
                                                operatorValue: 'contains',
                                                value: rowProp.opportunity_type
                                            },
                                                {
                                                id: 2,
                                                columnField: 'assigned_to',
                                                operatorValue: 'contains',
                                                value: selectedUser
                                            }],
                                        },
                                        
                                    },
                                }}
                            />
                        </Grid>
                    </Stack>
                </Paper>
            </Stack>
        );
    }
    const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
        ({ row }) => <DetailPanelContent row={row} data={userRepData} selectedUser={activeUser} />,
        [userRepData, activeUser]
    );
    function DetailPanelContent2({ row: rowProp, data, selectedUser }: { row: any, data: any, selectedUser: string }) {
        
        
        return (
            <Stack
                sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
                direction="column"
            >
                <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                    <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                        <Typography variant="h6">{`Opportunities Assigned`}</Typography>
                        <Grid container>
                            <DataGridPro
                                rows={mapTableRows(data['AllUsersArchivedOpps'])}
                                columns={columnsTEST}
                                autoHeight
                                sx={{ flex: 1 }}
                                hideFooter
                                density="compact"
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            Opportunity_Type: false,
                                            assigned_to: false,
                                        }
                                    },
                                    filter: {
                                        filterModel: {
                                            items: [{
                                                id: 1,
                                                columnField: 'Opportunity_Type',
                                                operatorValue: 'contains',
                                                value: rowProp.opportunity_type
                                            },
                                                {
                                                    id: 2,
                                                    columnField: 'assigned_to',
                                                    operatorValue: 'contains',
                                                    value: selectedUser
                                                }],
                                        },
                                        
                                    },
                                }}
                            />
                        </Grid>
                    </Stack>
                </Paper>
            </Stack>
        );
    }
    const getDetailPanelContent2 = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
        ({ row }) => <DetailPanelContent2 row={row} data={userRepData} selectedUser={activeUser} />,
        [userRepData, activeUser]
    );
    const getDetailPanelHeight = React.useCallback(() => 400, []);

    return (<>
        <Helmet>
            <title>Users Reporting</title>
        </Helmet>
            {!loading && keystoneCtx.filtersReady ? <Box>
                <Box sx={{mb: 4}}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            <Typography variant="h4">Keystone Impact: User Contributions Revealed
                                Analytics</Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{marginTop: 3}}/>
                </Box>
                <Grid container spacing={2} height={'100%'}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title={"Top 5 Contributing Users"}/>
                            <Divider/>
                            <CardContent>
                                {userRepData['Top5UsersPie'] && userRepData['Top5UsersPie']['Contribution'] && Math.max(...userRepData['Top5UsersPie']['Contribution']) != 0 ?
                                    <PieChartStat
                                        data={combineArrays(userRepData['Top5UsersPie']['Username'].map(edit), userRepData['Top5UsersPie']['Contribution'])}
                                        members={mainCtx.members}/> : <Alert severity="warning" sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: "warning"
                                    }}>No Data Available for Selected Period & Filters</Alert>}
                                {userRepData['Top5UsersTable'] && userRepData['Top5UsersTable']['Contribution'] && Math.max(...userRepData['Top5UsersTable']['Contribution']) != 0 ?
                                    <StyledDataGrid
                                        autoHeight
                                        rows={mapTableRows(userRepData['Top5UsersTable'])}
                                        columns={columns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        disableSelectionOnClick
                                        disableDensitySelector={true}
                                    /> : <Alert severity="warning" sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: "warning"
                                    }}>No Data Available for Selected Period & Filters</Alert>}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title={"Bottom 5 Contributing Users"}/>
                            <Divider/>
                            <CardContent>
                                {userRepData['Bottom5UsersPie'] && userRepData['Bottom5UsersPie']['Contribution'] && userRepData['Bottom5UsersPie']['Contribution'].length > 0 ?
                                    <PieChartStat
                                        data={combineArrays(userRepData['Bottom5UsersPie']['Username'].map(edit), userRepData['Bottom5UsersPie']['Contribution'])}
                                        members={mainCtx.members}/> : <Alert severity="warning" sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: "warning"
                                    }}>No Data Available for Selected Period & Filters</Alert>}
                                {userRepData['Bottom5UsersTable'] && userRepData['Bottom5UsersTable']['Contribution'] && Math.max(...userRepData['Bottom5UsersTable']['Contribution']) != 0 ?
                                    <StyledDataGrid
                                        autoHeight
                                        rows={mapTableRows(userRepData['Bottom5UsersTable'])}
                                        columns={columns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        disableSelectionOnClick
                                    /> : <Alert severity="warning" sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: "warning"
                                    }}>No Data Available for Selected Period & Filters</Alert>}
                            </CardContent>
                        </Card>
                    </Grid>
{/*                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title={"Opportunities Gained for Selected Period"}/>
                            <Divider/>
                            <CardContent>
                                {userRepData['OpportunityGainedBar'] && userRepData['OpportunityGainedBar']['opportunity_gained'] && userRepData['OpportunityGainedBar']['opportunity_gained'].length > 0 ?
                                    <BarGraphStat Series={genBarSeries(userRepData['OpportunityGainedBar'])}/> :
                                    <Alert severity="warning" sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: "warning"
                                    }}>No Data Available for Selected Period & Filters</Alert>}
                            </CardContent>
                        </Card>
                    </Grid>*/}
{/*                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title={"Opportunities Missed for Selected Period"}/>
                            <Divider/>
                            <CardContent>
                                {userRepData['OpportunityAvailableBar'] && userRepData['OpportunityAvailableBar']['opportunity_gained'] && userRepData['OpportunityAvailableBar']['opportunity_gained'].length > 0 ?
                                    <BarGraphStat Series={genBarSeries(userRepData['OpportunityAvailableBar'])}/> :
                                    <Alert severity="warning" sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: "warning"
                                    }}>No Data Available for Selected Period & Filters</Alert>}
                            </CardContent>
                        </Card>
                    </Grid>*/}
                    
                    
                    <Grid item xs={24} md={12}>
                        <Card
                            sx={{
                                width: '100%', mt: 3, px: 0.2, // alignItems: 'left'
                            }}>
                            <Box>
                                
                                <Stepper alternativeLabel activeStep={activeStep}
                                         sx={{padding: '16px', marginX: '10%', paddingLeft: '0px'}}>
                                    {steps.map((k, idx) => {
                                        return (<Step key={k}
                                                      sx={{
                                                          display: 'flex',
                                                          flexDirection: 'column',
                                                          marginTop: '12px'
                                                      }}>
                                            <StepButton style={{wordBreak: "break-all"}} color="inherit"
                                                        onClick={handleStep(idx)}>
                                                {k}
                                            </StepButton>
                                        </Step>)
                                    })}
                                </Stepper>
                            </Box>
                            <Box>
                                {(activeStep == 0) ? <CardContent sx={{padding: '10px'}}>
                                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                            <Grid container>
                                                <Grid item xs={12} md={12} sx={{marginTop: '20px'}}>
                                                    <Card>
                                                        <CardHeader title={"Users Contributions"}/>
                                                        <Divider/>
                                                        <CardContent sx={{padding: '10px'}}>
                                                            {userRepData['AllUsersTable'] && userRepData['AllUsersTable']['Contribution'] && Math.max(...userRepData['AllUsersTable']['Contribution']) != 0 ?
                                                                <DataGridPro
                                                                    autoHeight
                                                                    rows={mapTableRows(userRepData['AllUsersTable'])}
                                                                    columns={columns}
                                                                    pagination
                                                                    pageSize={5}
                                                                    rowsPerPageOptions={[5]}
                                                                    disableSelectionOnClick
                                                                    onRowClick={handleRowClick}/> :
                                                                <Alert severity="warning" sx={{
                                                                    height: 165,
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    marginBottom: '15px',
                                                                    backgroundColor: "warning"
                                                                }}>No Data Available for Selected Period & Filters</Alert>}
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} md={12} sx={{marginTop: '20px'}}>
                                                    <Card>
                                                        <CardHeader title={"Users Resolved Opportunities"}/>
                                                        <Divider/>
                                                        <CardContent>
                                                            {userRepData['AllUsersResolvedOpps'] && userRepData['AllUsersResolvedOpps']['assigned_to'] && Math.max(...userRepData['AllUsersResolvedOpps']['value']) != 0 ?
                                                                <DataGridPro
                                                                    autoHeight
                                                                    rows={mapTableRows(userRepData['AllUsersResolvedOpps'])}
                                                                    columns={oppsColumns}
                                                                    pagination
                                                                    pageSize={5}
                                                                    rowsPerPageOptions={[5]}
                                                                    disableSelectionOnClick
                                                                    onRowClick={handleRowClick}
                                                                    // experimentalFeatures={{ newEditingApi: true }}
                                                                /> : <Alert severity="warning" sx={{
                                                                    height: 165,
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    marginBottom: '15px',
                                                                    backgroundColor: "warning"
                                                                }}>No Data Available for Selected Period & Filters</Alert>}
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} md={12} sx={{marginTop: '20px'}}>
                                                    <Card>
                                                        <CardHeader title={"Users Pending Opportunities"}/>
                                                        <Divider/>
                                                        <CardContent>
                                                            {userRepData['AllUsersPendingOpps'] && userRepData['AllUsersPendingOpps']['assigned_to'] && Math.max(...userRepData['AllUsersPendingOpps']['value']) != 0 ?
                                                                <DataGridPro
                                                                    autoHeight
                                                                    rows={mapTableRows(userRepData['AllUsersPendingOpps'])}
                                                                    columns={oppsColumns}
                                                                    pagination
                                                                    pageSize={5}
                                                                    rowsPerPageOptions={[5]}
                                                                    disableSelectionOnClick
                                                                    onRowClick={handleRowClick}
                                                                    // experimentalFeatures={{ newEditingApi: true }}
                                                                /> : <Alert severity="warning" sx={{
                                                                    height: 165,
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    marginBottom: '15px',
                                                                    backgroundColor: "warning"
                                                                }}>No Data Available for Selected Period & Filters</Alert>}
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            
                                            </Grid>
                                        </Box>
                                    </CardContent> :
                                    <Box>{(userRepData) ? <Typography></Typography> : <Typography>No Data</Typography>}
                                    </Box>}
                                {(activeStep == 1) ?
                                    (!userLoading) ?
                                        <CardContent sx={{
                                        // display: 'flex',
                                        // flexDirection: 'column',
                                        pt: 2
                                    }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                                {specificUserRepData['UserOutstandingIndicator'] && specificUserRepData['UserOutstandingIndicator']['pending_sum_opp'] ?
                                                    <SingleValueIndicator title={"User Outstanding"}
                                                                          value={specificUserRepData && specificUserRepData['UserOutstandingIndicator']['pending_sum_opp'].length > 0 ? specificUserRepData['UserOutstandingIndicator']['pending_sum_opp'] : [0]}
                                                                          symbol={(currencySwitcher(mainCtx.activeOrganization.name)) ? currencySwitcher(mainCtx.activeOrganization.name) : ""}/> :
                                                    <Alert severity="warning" sx={{
                                                        height: 165,
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        marginBottom: '15px',
                                                        backgroundColor: "warning"
                                                    }}>No Data Available for Selected Period & Filters</Alert>}
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                {specificUserRepData['UserContributionIndicator'] && specificUserRepData['UserContributionIndicator']['resolved_sum_opp'] ?
                                                    <SingleValueIndicator title={"User Contribution"}
                                                                          value={specificUserRepData['UserContributionIndicator']['resolved_sum_opp'].length > 0 ? specificUserRepData['UserContributionIndicator']['resolved_sum_opp'] : [0]}
                                                                          symbol={(currencySwitcher(mainCtx.activeOrganization.name)) ? currencySwitcher(mainCtx.activeOrganization.name) : ""}/> :
                                                    <Alert severity="warning" sx={{
                                                        height: 165,
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        marginBottom: '15px',
                                                        backgroundColor: "warning"
                                                    }}>No Data Available for Selected Period & Filters</Alert>}
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Card sx={{width: '100%', padding: '16px', height: '100%'}}>
                                                    <CardHeader title={'Active Opportunities by type of selected user'} />
                                                    {(specificUserRepData['UserOppsByTypePie'] && specificUserRepData['UserOppsByTypePie']['opportunity_type']) ?
                                                        
                                                        <Box sx={{paddingTop: '10%'}}><PieChartStat
                                                            data={combineArrays(specificUserRepData['UserOppsByTypePie']['opportunity_type'], specificUserRepData['UserOppsByTypePie']['sum_opp'])}/></Box> :
                                                        <Alert severity="warning" sx={{
                                                            height: 165,
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>No Opportunities Pending for User</Alert>}
                                                
                                                </Card>
                                            </Grid>
                                            
                                            
                                            <Grid item xs={12} lg={6}>
                                                <Card sx={{width: '100%'}}>
                                                    <CardHeader
                                                        title={'Contribution of user grouped by resolved opportunity type'}/>
                                                    {(specificUserRepData['UserValsHeatMap'] && Object.keys(specificUserRepData['UserValsHeatMap']).length > 0) ?
                                                        
                                                        <HeatMapStat series={specificUserRepData['UserValsHeatMap']}/> :
                                                        <Alert severity="warning" sx={{
                                                            height: 123,
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            marginBottom: '18px',
                                                            marginLeft: '17px',
                                                            marginRight: '17px'
                                                        }}>No User Contribution</Alert>}
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={12} sx={{marginTop: '20px'}}>
                                                <Card>
                                                    <CardHeader title={"User Resolved Opportunities"}/>
                                                    <Divider/>
                                                    <CardContent>
                                                        {userRepData['AllUsersResolvedOpps'] && userRepData['AllUsersResolvedOpps']['assigned_to'] && userRepData['AllUsersResolvedOpps']['assigned_to'].length > 0 ?
                                                            <DataGridPro
                                                                initialState={{
                                                                    columns: {
                                                                        columnVisibilityModel: {
                                                                            id: false,
                                                                        }
                                                                    },
                                                                    filter: {
                                                                        filterModel: {
                                                                            items: [{
                                                                                columnField: 'assigned_to',
                                                                                operatorValue: 'contains',
                                                                                value: activeUser
                                                                            }],
                                                                        },
                                                                        
                                                                    },
                                                                }}
                                                                
                                                                autoHeight
                                                                rows={mapTableRows(userRepData['AllUsersResolvedOpps'])}
                                                                columns={oppsColumns}
                                                                pagination
                                                                pageSize={5}
                                                                rowsPerPageOptions={[5]}
                                                                disableSelectionOnClick
                                                                getDetailPanelHeight={getDetailPanelHeight}
                                                                getDetailPanelContent={getDetailPanelContent2}
                                                                rowThreshold={0}
                                                                /> :
                                                            <Alert severity="warning" sx={{
                                                                height: 165,
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                marginBottom: '15px',
                                                                backgroundColor: "warning"
                                                            }}>No Data Available for Selected Period & Filters</Alert>}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            
                                            
                                            <Grid item xs={12} md={12} sx={{marginTop: '20px'}}>
                                                <Card>
                                                    <CardHeader title={"User Pending Opportunities"}/>
                                                    <Divider/>
                                                    <CardContent>
                                                        {userRepData['AllUsersPendingOpps'] && userRepData['AllUsersPendingOpps']['assigned_to'] && userRepData['AllUsersPendingOpps']['assigned_to'].length > 0 ?
                                                            <DataGridPro
                                                                initialState={{
                                                                    columns: {
                                                                        columnVisibilityModel: {
                                                                            id: false,
                                                                        }
                                                                    },
                                                                    filter: {
                                                                        filterModel: {
                                                                            items: [{
                                                                                columnField: 'assigned_to',
                                                                                operatorValue: 'contains',
                                                                                value: activeUser
                                                                            }],
                                                                        },
                                                                        
                                                                    },
                                                                }}
                                                                
                                                                autoHeight
                                                                rows={mapTableRows(userRepData['AllUsersPendingOpps'])}
                                                                columns={oppsColumns}
                                                                pagination
                                                                pageSize={5}
                                                                rowsPerPageOptions={[5]}
                                                                disableSelectionOnClick
                                                                getDetailPanelHeight={getDetailPanelHeight}
                                                                getDetailPanelContent={getDetailPanelContent}
                                                                rowThreshold={0}
                                                            /> :
                                                            <Alert severity="warning" sx={{
                                                                height: 165,
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                marginBottom: '15px',
                                                                backgroundColor: "warning"
                                                            }}>No Data Available for Selected Period & Filters</Alert>}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </CardContent> : <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '100%'
                                            }}>
                                            <CircularProgress sx={{
                                                margin: 5, justifySelf: "center"
                                            }}/>
                                        </Box>: null}
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box> : <Box>
                <LinearProgress
                    sx={{
                        marginLeft: 5, marginRight: 5, marginTop: 2, justifySelf: "center"
                    }}/>
                <HomeIssueSkeleton/>
            </Box>}
        </>)
}

export default UserReportingPage;