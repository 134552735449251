/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import {FC, useCallback, useContext, useLayoutEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import slice, {widgetChangeVariable, widgetExecute} from "../../../../../../slices/bitool";
import {
    itemsFromGroupIdSelector,
    selectedMemberIndexFunc,
    selectedMemberMaxIndexFunc,
    widgetColumnsSelectorFunc,
    widgetDataSelectorFunc,
    widgetDependencyVariablesSelector,
    widgetIsLoadingSelectorFunc,
    widgetMaxGroupItemsSelector,
    widgetsFromGroupIdSelector,
    widgetTemplatesSelectorFunc,
    widgetVariablesSelectorFunc,
    allWidgetVariablesSelectorFunc,
} from "../../../../../../slices/selectors";
import {serializeOutputData} from "../serializers";
import { Box, Button, Grid } from "@mui/material";
import _ from "lodash";
import {WidgetProps} from "../../WidgetSwitcher";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import Skeleton from "@mui/material/Skeleton";
import SettingsContext from "../../../../../../contexts/SettingsContext";

export const BasicWidgetRender: FC<WidgetProps> = (props) => {
    const dispatch = useDispatch()
    const {widget, children, nextStep} = props
    const selectWidgetData = useMemo(widgetDataSelectorFunc, [])
    const selectVariables = useMemo(widgetVariablesSelectorFunc, [])
    const selectWidgetColumns = useMemo(widgetColumnsSelectorFunc, [])
    const selectWidgetVariables = useMemo(allWidgetVariablesSelectorFunc, [])

    const widgetVariables = useSelector((state) =>
        selectVariables(state, widget.id)
    ), widgetData = useSelector((state) =>
        selectWidgetData(state, widget.id)
    ), widgetColumns = useSelector((state) =>
        selectWidgetColumns(state, widget.id)
    ), allWidgetVariables = useSelector((state) =>
        selectWidgetVariables(state)
    )

    const setVariableOutputs = (data: any) => {
        if (data != null && widgetData != null) {
            console.log("setOutputs ------>: ", widget.id, data, nextStep)
            if (!widget.arguments.setVariableIds) {
                dispatch(widgetChangeVariable(widget.id, data))
            }
            else {
                widget.arguments.setVariableIds.map((i) => {
                    dispatch(widgetChangeVariable(i, data))
                })
            }
        }
        if (nextStep) nextStep()
    }


    const childrenWithProps = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            // @ts-ignore
            return React.cloneElement(child, {
                // @ts-ignore
                widget: widget,
                // height: height,
                widgetData: widgetData,
                widgetColumns: widgetColumns,
                widgetVariables: widgetVariables,
                allWidgetVariables: allWidgetVariables,
                setVariableOutputs: setVariableOutputs,
            });
        }
        return child;
    });
    return <> {childrenWithProps} </>
}

const BasicWidget: FC<WidgetProps> = (props) => {
    const ctx = useContext(SettingsContext)
    let {dashId} = useParams();
    const {widget, index, maxIndex, nextStep, children} = props
    const dispatch = useDispatch()
    let navigate = useNavigate();
    let url = window.location.pathname.toString()
    // const selectWidgetDependencyOutputs = useMemo(widgetDependencyOutputsSelector, [])
    const selectWidgetVariableOutputs = useMemo(widgetDependencyVariablesSelector, [])

    const selectTemplates = useMemo(widgetTemplatesSelectorFunc, [])
    const selectWidgetIsLoading = useMemo(widgetIsLoadingSelectorFunc, [])
    const selectWidgetsWidgetGroup = useMemo(widgetsFromGroupIdSelector, [])

    const selectMemberIndex = useMemo(selectedMemberIndexFunc, [])
    const selectMemberMaxIndex = useMemo(selectedMemberMaxIndexFunc, [])

    const selectWidgetMaxGroupItems = useMemo(widgetMaxGroupItemsSelector, [])

    const isLoading = useSelector((state) =>
        selectWidgetIsLoading(state, widget.id)
    ), widgetVariableOutputs = useSelector((state) =>
        selectWidgetVariableOutputs(state, widget.id)
    ), widgetTemplates = useSelector( (state) =>
        selectTemplates(state)
    )

    const widgets = useSelector((state) =>
        selectWidgetsWidgetGroup(state, widget.id)
    ), widgetMaxGroupItems = useSelector((state) =>
        selectWidgetMaxGroupItems(state, widget.id)
    ), widgetSelectedIndex: number = useSelector( (state) =>
        selectMemberIndex(state, widget.id)
    ), widgetSelectedMaxIndex: number = useSelector( (state) =>
        selectMemberMaxIndex(state, widget.id)
    )
    // widgetDependencyOutputs = useSelector((state) =>
    //     selectWidgetDependencyOutputs(state, widget.id)
    // )
    // const [widgetDependencyOutputsState, setWidgetDependencyOutputsState] = useState(null)

    const selectItemsWidgetGroup = useMemo(itemsFromGroupIdSelector, [])

    const items = useSelector((state) =>
        selectItemsWidgetGroup(state, widget.id)
    )

    const [widgetVariableOutputsState, setWidgetVariableOutputsState] = useState(null)
    const [widgetController, setWidgetController] = useState<AbortController>(null)

    const executeWidget = () => {
        if (widget.arguments.executorArgs) {
            let template: {} = {}
            if (widgetTemplates[widget.id]) {
                Object.keys(widgetTemplates[widget.id]).map((k) => {
                    if (widgetTemplates[widget.id][k].widgetId || widgetTemplates[widget.id][k].variableId) {
                        template[k] = serializeOutputData(
                            widgetVariableOutputsState ? widgetVariableOutputsState[k] : null,
                            widgetTemplates[widget.id][k]
                        )
                    } else {
                        template[k] = widgetTemplates[widget.id][k].value
                    }
                })
            }
            if (widgetTemplates[widget.id] &&
                (Object.keys(widgetTemplates[widget.id]).length == Object.keys(template).length)) {
                if (widgetController) widgetController.abort()
                const newController = new AbortController()
                console.log("ExecuteTemplates: ", widget, template, widgetVariableOutputsState)
                dispatch(widgetExecute(widget, template, newController))
                setWidgetController(newController)
            }
        } else {
            if (widget.arguments.templates && widget.arguments.templates.widgetData) {
                dispatch(slice.actions.widgetChangeData({
                    id: widget.id,
                    data: widget.arguments.templates.widgetData.value
                }));
            } else if (widget.arguments.templates && widget.arguments.templates.labelVariable){
                dispatch(slice.actions.widgetChangeData({
                    id: widget.id,
                    data: widget.arguments.templates.labelVariable.value
                }))
            }
        }
    }

    const resetSteps = () => {
        dispatch(slice.actions.widgetChangeSelectedGroupMemberIndex({
            id: widget.id,
            index: 0
        }))
        dispatch(slice.actions.widgetChangeSelectedGroupMemberMaxIndex({
            id: widget.id,
            index: 0
        }))
    }

    const executeWidgetCallback = useCallback(
        () => {
            if (widgetVariableOutputsState ||
                    (widget.arguments.type == 'BasicAutoCompleteFilter' ||
                    widget.arguments.type == 'BasicDateDisplay' ||
                    widget.arguments.type == 'BasicQuickStats')) {
                if (!widget.arguments.type.toLowerCase().includes('group')) {
                    executeWidget();
                } else if (widget.arguments.type.toLowerCase().includes('stepper')) {
                    resetSteps()
                }
            }
        },
        [widgetVariableOutputsState, widgetTemplates, widget],
    );

    useLayoutEffect(() => {
        if (widgetVariableOutputs && !(_.isEqual(widgetVariableOutputsState, widgetVariableOutputs)
            && widgetVariableOutputsState != null)) {
            setWidgetVariableOutputsState(widgetVariableOutputs)
        }
    }, [widgetVariableOutputs])

    useLayoutEffect(() => {
        executeWidgetCallback()
    }, [widgetVariableOutputsState, widgetTemplates])

    // todo: change to is executing
    if (isLoading) {
        return (
            <Grid item >
                <Box >
                    <Skeleton variant={ctx.settings.roundedCorners ? "text" : "rectangular"} width={350} height={95} />
                </Box>
            </Grid>
        )
    }

    const childrenWithProps = React.Children.map(children, child => {
        const idx = index ? index : widgetSelectedIndex
        let maxIdx = maxIndex ? maxIndex : widgetSelectedMaxIndex
        // console.log(widget.id, idx > maxIndex ? maxIndex : idx, maxIdx, maxIdx > widgetSelectedMaxIndex ? widgetSelectedMaxIndex : maxIdx)
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                // @ts-ignore
                widget: widget,
                // height: height,
                index: idx > maxIndex ? maxIndex : idx,
                maxIndex: maxIdx > widgetSelectedMaxIndex ? widgetSelectedMaxIndex : maxIdx,
                maxIndexItems: widgetSelectedMaxIndex,
                items: items,
                nextStep: nextStep,
            });
        }
        return child;
    });

    const handleEditWidget = () => {
        if (widget.arguments && widget.arguments.executorArgs && widget.arguments.executorArgs.queryId) {
            navigate('/app/bi/widgetedit/' + dashId + "/" + widget.arguments.executorArgs.queryId + "/" + widget.id)
        } else {
            navigate('/app/bi/widgetedit/' + dashId + "/00000000-0000-0000-0000-000000000000/" + widget.id)
        }
    }

    return (
        <div style={{height: "100%", width: "100%"}}>
            { url.includes("/bi/") && !url.includes("/widgetedit") &&
                <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                    <Button variant="text" onClick={handleEditWidget}>Edit Widget</Button>
                </Box>
            }
            <BasicWidgetRender {...props} children={childrenWithProps} />
        </div>
    )
}

export default BasicWidget