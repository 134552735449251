import React, {FC, useEffect, useState} from "react";
import {Autocomplete, Box, Dialog, Divider, LinearProgress, TableContainer, TextField, Tooltip} from "@mui/material";
import {Add, InfoOutlined} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import PSUTreeComponent from "./PSUTreeComponent";
import {ConvertTreeFromBeingUsedToApiSave, GenerateNewPsuTree, TreeForUseByPSU} from "./utility/NodeTypesAndOptions";
import {PSUStore} from "../../../../cog/keystone/productStoreUniverse";
import {useMainContext} from "../../../../contexts/MainContext";
import {useSnackbar} from "notistack";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {ColumnMappingDictionaryWithData} from "../exlude/components/ColumnMappingDictionaryWithData";
import {TreeNodeDatum} from "react-d3-tree";
import {PSUinsideShadows, TreeControls} from "./components/PSUSettingsComponent";
import Button from "@mui/material/Button";


let dialogBody = () => {
    return (
        <div>
        </div>
    )
};

/** todo : to make it work
 *         Install these"
 *         npm i --save react-d3-tree
 *         npm install d3-selection    -- this one is a dependency that already exists in the project,
 *                                        installing d3 tree installs a new version of this but doesn't update the old one
 *                                        so install again to update
 * */

const ContainerPSU: FC = () => {
    // Contexts
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const cogTheme = useTheme()
    const {enqueueSnackbar} = useSnackbar()
    const autoHideSnackDuration: number = 2500 // time it takes to hide snackbars

    const [psuTrees, setPsuTrees] = useState<TreeForUseByPSU[]>(null)
    const [colMapWithDataReference, setColMapWithDataReference] = useState<ColumnMappingDictionaryWithData>(null)

    // const [psuTrees, setPsuTrees] = useState<TreeForUseByPSU[]>([GenerateNewPsuTree()])
    // [currentTab, setCurrentTab] : Holds and sets the current tab number
    const [currentTab, setCurrentTab] = useState<number>(-1)
    const [treeOrGrid, setTreeOrGrid] = useState<boolean>(false)// false for tree, true for grid
    // const [currentTab, setCurrentTab] = useState<number>(psuTrees != null && psuTrees.length > 0 ? 0 : -1)
    // We wanna use this to track if changes have been made to the tree, in which case it needs saving
    const [needsSaving, setNeedsSaving] = useState<boolean>(false)

    const [loadingBar, setLoadingBar] = useState<boolean>(true)

    // This needs to be here, referencing it inside the psuTrees structure doesn't work for some reason
    // needs its own hook i guess
    const [orgLevelSwitch, setOrgLevelSwitch] = useState<boolean>(false)
    const [confirmActionDialogueOpen, setConfirmActionDialogueOpen] = useState<boolean>(false)

    /** MOVE TO CONTEXT ? */
    const [selectedNode, setSelectedNode] = useState<TreeNodeDatum>(null)

    const [newTreeNeedsSaving, setNewTreeNeedsSaving] = useState<boolean>(false)
    // When creating a new tree, this remembers the other ids, so that we can auto select the new tree when the page is refreshed
    const [oldTreeIDS, setOldTreeIDS] = useState<string[]>([])

    // handleConfirmClose : for closing confirmation modal
    const handleConfirmClose = () => {
        setConfirmActionDialogueOpen(false);
        enqueueSnackbar('Action Cancelled', {
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'top'
            },
            variant: 'warning',
            autoHideDuration: autoHideSnackDuration,
            preventDuplicate: true
        });
    };


    const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedNode(null)
        if (needsSaving) {
            Promise.resolve().then(() => SaveTree())
            setNeedsSaving(false)
        }
        if (newValue != null) {
            setCurrentTab(newValue);
            if (newValue > -1) {
                setOrgLevelSwitch(psuTrees[newValue].Org_Level)
            }
        } else {
            setCurrentTab(null)
        }
    };


    // handleTreeNameChange : Changes the name of the tree in local memory, there might be a better way to do this
    const handleTreeNameChange = (e) => {
        // These two things slightly improve the user experience when using the textbox in the tabs
        e.stopPropagation()
        e.preventDefault()
        let tempTrees = psuTrees
        tempTrees[currentTab].Name = e.target.value
        setPsuTrees(tempTrees)
        setNeedsSaving(true)
    }

    // Handle Adding a new tree
    const handleAddNewTree = () => {
        setSelectedNode(null)
        if (needsSaving) {
            Promise.resolve().then(() => SaveTree())
            setNeedsSaving(false)
        }
        // this is for later to change the tabs when we add a new tree
        let tempNavIndex = psuTrees.length
        let tempTrees = psuTrees
        // Defaulting to COGNIZANCE isn't great todo : no defaulting to COGNIZANCE
        let tempBaseNode = GenerateNewPsuTree(mainCtx.activeOrganization != null
            ? mainCtx.activeOrganization.name
            : "COGNIZANCE")
        tempBaseNode.Name += " " + tempNavIndex.toString() // this is not the best way, but fine for now, make better
        tempTrees.push(tempBaseNode)

        setPsuTrees(tempTrees)
        setCurrentTab(tempNavIndex)
        setNeedsSaving(true)
        setNewTreeNeedsSaving(true)
        // track the ids of the existing trees
        let tempOldIDs: string[] = []
        for (let i = 0; i < psuTrees.length; i++) {
            tempOldIDs.push(psuTrees[i].ID)
        }
        setOldTreeIDS(tempOldIDs)
    }

    useEffect(() => {
        if (newTreeNeedsSaving) {
            Promise.resolve().then(SaveTree)
        }
    }, [newTreeNeedsSaving]);

    const UpdateTrees = (tree) => {
        console.log(tree)
        let tempTrees = psuTrees
        tempTrees[currentTab].Tree = tree
        setPsuTrees(tempTrees)
        // setNeedsSaving(true)
    }

    async function DeleteTree() {
        Promise.resolve().then(() => RemoveTreeFromDB(psuTrees[currentTab].ID)).then(() => FetchPSUTrees())
    }

    async function RemoveTreeFromDB(psuID: string) {
        setLoadingBar(true)
        setSelectedNode(null)
        if (keystoneCtx.activePSU != null && psuID == keystoneCtx.activePSU.ID) {
            keystoneCtx.setActivePSU(null)
        }
        // DeleteTreeLocally()
        return await PSUStore.RemoveOne({
            ClientName: mainCtx.activeOrganization.name,
            ID: psuID
        })
    }

    // Save the current Tree
    async function SaveTree() {
        if (psuTrees[currentTab].ID == "") {
            setLoadingBar(true)
        }
        let tempPSU = ConvertTreeFromBeingUsedToApiSave(psuTrees[currentTab])
        // if PSU is new we assign it to current user
        if (tempPSU.ID == "") {
            tempPSU.User_ID = mainCtx.user.id
        }
        enqueueSnackbar(tempPSU.Name + psuTrees[currentTab].ID == "" ? ' Created' : ' PSU changes saved', {
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'top'
            },
            variant: 'success',
            autoHideDuration: autoHideSnackDuration,
            preventDuplicate: true
        });
        setNeedsSaving(false)
        await PSUStore.SaveOne({
            ClientName: mainCtx.activeOrganization.name,
            PSU: tempPSU
        })
        if (psuTrees[currentTab].ID == "") {
            // setPsuTrees(null)
            Promise.resolve().then(FetchPSUTrees)
            setNewTreeNeedsSaving(false)
        }
    }


    async function FetchPSUTrees() {
        Promise.resolve().then(() => keystoneCtx.FetchPSUTrees())
    }

    function dealWithContextPSUTrees() {
        if (keystoneCtx.psuTrees != null) {
            setPsuTrees(keystoneCtx.psuTrees)
            setNeedsSaving(false)
            if (keystoneCtx.psuTrees.length != 0) {
                // basically makes sure the tab that gets selected is the new one if there is a new one
                if (oldTreeIDS.length > 0) {
                    for (let i = 0; i < keystoneCtx.psuTrees.length; i++) {
                        if (oldTreeIDS.includes(keystoneCtx.psuTrees[i].ID) == false) {
                            setCurrentTab(i)
                            break
                        }
                    }
                    setOldTreeIDS([])
                } else {
                    setCurrentTab(0)
                }
                setOrgLevelSwitch(keystoneCtx.psuTrees[0].Org_Level)
            } else {
                setCurrentTab(-1)
            }
            setLoadingBar(false)
        }
    }

    useEffect(() => {
        if (keystoneCtx.psuTrees != null) {
            dealWithContextPSUTrees()
        }
    }, [keystoneCtx.psuTreesUpdatedSwitch, keystoneCtx.psuTrees]);

    /** MOVE TO CONTEXT ? */
    async function FetchFilterValues() {
        let response = await PSUStore.FetchFilterValues({
            ClientName: mainCtx.activeOrganization.name
        })
        let tempMapRef = new ColumnMappingDictionaryWithData()
        tempMapRef.PopulateWithColMap(keystoneCtx.colMap)
        // console.log("FilterValues response", response)
        tempMapRef.PopulateWithPSUFilterValues(response.FilterValues)
        // console.log("tempMapRef", tempMapRef)
        setColMapWithDataReference(tempMapRef)
    }


    useEffect(() => {
        if (mainCtx.activeOrganization != null && keystoneCtx.colMap != null && colMapWithDataReference == null) {
            Promise.resolve().then(() => FetchFilterValues())
        }
    }, [mainCtx.activeOrganization, keystoneCtx.colMap]);


    const handleOrgLevelChange = (event, checked) => {
        let tempTree = psuTrees
        tempTree[currentTab].Org_Level = !tempTree[currentTab].Org_Level
        setOrgLevelSwitch(tempTree[currentTab].Org_Level)
        setPsuTrees(tempTree)
        setNeedsSaving(true)
    }

    return <Box>
        {
            loadingBar == false && <Box
                sx={{
                    p: 1,
                    display: "flex",
                    gap: 1,
                }}
            >
                <Box
                    sx={{
                        // p: 1,
                        display: "flex",
                        gap: 1,
                        minWidth: "20%",
                    }}
                >
                    <Autocomplete
                        sx={{
                            boxShadow: PSUinsideShadows,
                            borderRadius: 1
                        }}
                        fullWidth={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={`${"PSU Selector"}`}
                                placeholder="Select PSU"
                                value={psuTrees[currentTab] != null ? psuTrees[currentTab].Name : null}
                            />
                        )}
                        options={psuTrees}
                        value={psuTrees[currentTab] != null ? psuTrees[currentTab] : null}
                        getOptionLabel={(option: TreeForUseByPSU) => option.Name}
                        renderOption={(params, option) => <li {...params}>{option.Name}</li>}
                        onChange={(_, value: TreeForUseByPSU) => {
                            if (value != null) {
                                handleTabsChange(_, psuTrees.findIndex(element => element.ID == value.ID))
                            } else {
                                handleTabsChange(_, null)
                            }
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        // p: 1,
                        display: "flex",
                        gap: 1,
                        minWidth: "8%",
                        // flexGrow: 1
                    }}
                >
                    <Button fullWidth variant={"outlined"}
                            onClick={() => handleAddNewTree()}
                            sx={{
                                boxShadow: PSUinsideShadows,
                                borderRadius: 1
                            }}
                        // icon={<Add fontSize={"small"}/>}
                    >
                        <Add fontSize={"small"}/>
                        New Tree
                    </Button>
                </Box>
                <Tooltip title={TreeControls} >
                    <Box
                        sx={{
                            border: `1px solid ${cogTheme.palette.divider}`,
                            borderRadius: 1,
                            p: 1,
                            // backgroundColor: cogTheme.palette.background.paper,
                            boxShadow: PSUinsideShadows,
                            display: "flex",
                        }}
                    >
                        <InfoOutlined fontSize={"large"}/>
                    </Box>
                </Tooltip>
            </Box>
        }

        {(loadingBar) && (
            <LinearProgress
                sx={{
                    marginLeft: 5,
                    marginRight: 5,
                    my: 2,
                    justifySelf: "center",
                }}
            />
        )}

        <Divider />

        {(loadingBar || colMapWithDataReference == null) && (
            <LinearProgress
                sx={{
                    marginLeft: 5,
                    marginRight: 5,
                    my: 2,
                    justifySelf: "center",
                }}
            />
        )}

        {loadingBar == false && <Box
            sx={{
                height: "83.5vh",
            }}>

            {
                psuTrees != null && colMapWithDataReference != null && psuTrees[currentTab] != null &&
                <PSUTreeComponent
                    psuTree={psuTrees[currentTab]}
                    UpdateTrees={UpdateTrees}
                    setNeedsSaving={setNeedsSaving}
                    colMapWithDataReference={colMapWithDataReference}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                    needsSaving={needsSaving}
                    SaveTree={SaveTree}
                    DeleteTree={DeleteTree}
                    orgLevelSwitch={orgLevelSwitch}
                    handleOrgLevelChange={handleOrgLevelChange}
                    loadingBar={loadingBar}
                    key={psuTrees[currentTab].ID}
                    TreeOrGrid={treeOrGrid}
                    setTreeOrGrid={setTreeOrGrid}
                    handleTreeNameChange={handleTreeNameChange}
                />
            }

            {/*}*/}
            {currentTab == -1 || currentTab == null &&
                <Box>
                    {/* When there are no trees we show this*/}
                    No PSU selected
                </Box>
            }
        </Box>}
        <Dialog
            open={confirmActionDialogueOpen}
            onClose={handleConfirmClose}
            aria-labelledby="confirm-action-dialog"
            fullWidth
            maxWidth="md"
        >
            {dialogBody()}
        </Dialog>
    </Box>;
}

export default ContainerPSU;