import {Box} from "@mui/system";
import { FC, useContext, useEffect, useState } from "react";
import {ChatMessageStying} from "./ChatMessageStying";
import {Avatar, Grid, Typography} from "@mui/material";
import {Theme, useTheme} from "@mui/material/styles";
import CogLogo from "../../../../logo.svg"
import KeystoneLogo from "../../../../KsBulletWhite.svg"
import KeystoneLogoDark from "../../../../ksBulletDark.png"
import orgChecker from "../../../../utils/orgChecker";
import { THEMES } from "../../../../constants";
import { useMainContext } from "../../../../contexts/MainContext";
import SettingsContext from "../../../../contexts/SettingsContext";

interface ChatMessage{
    id:number
    avatar: string
    messages:string[]
    side: string
}

const ChatMessage : FC<ChatMessage> = ({id,avatar,messages,side}) => {
    const mainCtx = useMainContext();
    const settingsCtx = useContext(SettingsContext);
    const chatMessageStyling = ChatMessageStying({palette:useTheme().palette, spacing:useTheme().spacing})
    const [logo, setLogo] = useState<string>(avatar)

    const attachClass = (index) => {
        if (index === 0) {
            return (chatMessageStyling[`${side}First`])
        }
        if (index === messages.length - 1) {
            return chatMessageStyling[`${side}Last`]
        }
        return '';
    }

    const logoSwitcher = (av: string, org: string, theme: string) => {
        const cogLogo = CogLogo
        const keyLogoLight = KeystoneLogo
        const keyLogoDark = KeystoneLogoDark
        let avatar = av

        if (orgChecker(org)) {
            avatar = cogLogo
        } else {
            if (av === keyLogoLight && theme === THEMES.LIGHT) {
                avatar = keyLogoDark
            } else {
                avatar = keyLogoLight
            }

            if (av === keyLogoDark && theme === THEMES.LIGHT) {
                avatar = keyLogoDark
            } else {
                avatar = keyLogoLight
            }

            if (theme === THEMES.LIGHT) {
                avatar = keyLogoDark
            } else {
                avatar = keyLogoLight
            }
        }
        // console.log(avatar)

        setLogo(avatar)
    }

    useEffect(() => {
        logoSwitcher(avatar, (mainCtx.isLoading? "":mainCtx.activeOrganization.name), settingsCtx.settings.theme)
    }, [settingsCtx.settings.theme]);

    return (
        <Grid key={id} container spacing={2} justifyContent={(side === 'right' ? 'flex-end' : 'flex-start')} alignItems={'flex-end'} >
            {side === 'left' && (
                <Grid item >
                    <Avatar
                        src={logo}
                    />
                </Grid>
            )}
            <Grid item sx={{ maxWidth: 350 }}>
                {messages.map((msg, i) => {
                    return (
                        <Box
                          display="flex"
                          key={('message'+ `${id}` + `${i}`)}
                          sx={Object.assign({}, chatMessageStyling[`${side}First`], chatMessageStyling[`${side}`],attachClass(i))}
                        >
                            <Typography
                              key={('message'+ `${id}` + `${i}`)}
                                sx={Object.assign({}, chatMessageStyling.msg, chatMessageStyling[`${side}Row`])}
                            >
                                {msg}
                            </Typography>
                        </Box>
                    );
                })}
            </Grid>


        </Grid>
    )
}
export default ChatMessage