import type {FC} from 'react';
import {useEffect, useState} from "react";
import {Box} from '@mui/material';
import {Helmet} from "react-helmet-async";
import {Card as KanbanCardObj} from "../../../../cog/keystone/card";
import DataGridDetail from "./DataGridDetail";
// import {Member} from "../../../../cog/kanban/member";
import {useMainContext} from "../../../../contexts/MainContext";
import {Member} from "../../../../cog/keystone/member";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";

interface ListViewProps {
    allCards: Record<string, KanbanCardObj>
    // members: Member[]
    myRows: any[]
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: any) => void
    allLabels: OppLabel[]
}

const ListViewPage: FC<ListViewProps> = (props) => {
    const mainCtx = useMainContext()
    const [isLoading, setIsLoading] = useState<boolean>(true); // to avoid passing nulls to props
    const [allCards, setAllCards] = useState<Record<string, KanbanCardObj>>(props.allCards); // unfiltered cards
    // const [members, setMembers] = useState<Member[]>(props.members)
    const [allLabels, setAllLabels] = useState<OppLabel[]>(props.allLabels)

    // This one just checks that we can set loading to false, and also lets us filter ze cards if it hasn't been
    useEffect(() => {
        if (allCards != null) {
            if (isLoading) {
                if (mainCtx.members != null) {
                    setIsLoading(false) // nothing is null, so loading can happen
                    // console.log("Members", mainCtx.members)
                }
            }
        }
    }, [allCards, isLoading])

    return (
        <>
            <Helmet>
                <title>Detailed List View</title>
            </Helmet>
            <div>
                <Box>

                    <DataGridDetail cards={allCards}
                                    // members={members}
                                    myRows={props.myRows}
                                    selectedCardsIDs={props.selectedCardsIDs}
                                    updateSelectedCards={props.updateSelectedCards}
                                    selectCards={props.selectCards}
                                    allLabels={allLabels}/>
                </Box>
            </div>
        </>
    );
};

export default ListViewPage;
