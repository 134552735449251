/* eslint-disable */

export default class Comment {
    public id?: string = '';
    public item_id: string = '';
    public user_id: string = '';
    public content: string = '';
    public time_stamp?: string = '';

    constructor(comment?: Comment) {
        if (!comment) {
            return;
        }
        this.id = comment.id;
        this.item_id = comment.item_id;
        this.user_id = comment.user_id;
        this.content = comment.content;
        this.time_stamp = comment.time_stamp;
    }
}