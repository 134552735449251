/* eslint-disable */

export default class History {
    public id: string = '';
    public subject: string = '';

    public actionedById: string = '';
    public actionedByName: string = '';

    public actionType: string = '';
    public timestamp: string = '';

    constructor(history?: History) {
        if (!history) {
            return;
        }
        this.id = history.id;
        this.subject = history.subject;

        this.actionedById = history.actionedById;
        this.actionedByName = history.actionedByName;

        this.actionType = history.actionType;
        this.timestamp = history.timestamp;
    }
}

export class CreateHistory {
    // public id: string = '';
    public actionType: string = '';
    public subject: string = '';
    public cardId: string = '';
    public actionedBy: string = '';
    // public timestamp: string = '';

    constructor(inHistory?: CreateHistory) {
        if (!inHistory) {
            return;
        }
        // this.id = inHistory.id;
        this.actionType = inHistory.actionType;
        this.subject = inHistory.subject;
        this.cardId = inHistory.cardId;
        this.actionedBy = inHistory.actionedBy;
        // this.timestamp = inHistory.timestamp;
    }
}