import type {FC} from 'react';
import logoPath from "./EditLogo.svg"

interface EditLogoProps extends LogoSwitcherProps{
    [key: string]: any;
}

interface LogoSwitcherProps {
    org?: string;
    icon?: boolean;
}

const EditLogo: FC<EditLogoProps> = (icon, props) => (
    <img
        alt="Edit Logo"
        src={logoPath}
        height={props.icon ? 100 : 66}
        width={props.icon? 200 : 66}
        style={{
            margin: 1
        }}
        {...props}
    />
);
export default EditLogo;