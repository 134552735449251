import {Mention, MentionsInput} from 'react-mentions'
import type {FC} from 'react';
import React, {useLayoutEffect, useState} from 'react';
import {Box} from "@mui/system";
import { Avatar, Button, CircularProgress, Divider } from "@mui/material";
import {User} from "../../../../cog/user";
import {CommentsStore} from "../../../../cog/keystone/comments";
import {Member} from "../../../../cog/keystone/member";
import {useTheme} from "@mui/material/styles";
import {NotificationStore} from "../../../../cog/keystone/notifications";
import {useMainContext} from "../../../../contexts/MainContext";

interface LogProps {
    onClose?: () => void;
    issueID: string,
    user: User,
    orgMembers: Member[]
}

interface mentionMember {
    id: string,
    display: string
}

const MentionsInputStyle = (anTheme) => {
    return {
        color: anTheme.palette.text.primary,
        overflowWrap: "anywhere",
        wordBreak: "break-all",
        hyphens: "auto",
        border: "0px",
        backgroundColor: "transparent",
        padding: "10px",
        input: {
            color: anTheme.palette.text.primary,
            border: '1px solid silver',
            borderRadius: '15px',
            '&focus-visible':
                {
                    border: '1px solid silver',
                    backgroundColor: 'red',
                    outline: "0px"
                },
            '&focused': {
                backgroundColor: 'red',
            },
            padding: "15px",

            height: "4rem",
            fontSize: "1rem"
        },
        suggestions: {
            backgroundColor: anTheme.palette.background.default,
            padding: `5px`,
            position: 'fixed',
            // zIndex: 1,
            border: `1px solid black`,
            boxShadow: `0 0 8px 0`,
            MaxHeight: "8rem",
            // overflow: "auto",
            list: {
                paddingBottom: "0px",
            },
            item: {
                backgroundColor: anTheme.palette.background.paper,
                padding: "2px",
                paddingLeft: "5px",
                paddingRight: "5px",
                '&focused': {
                    backgroundColor: anTheme.palette.success.main
                },
                borderRadius: "8px"
            }
        }
    }
}

interface CommentFormProps {
    CreateComment: (e: any) => void;
    mention: Mention,
    handleChange: (event, newValue, newPlainTextValue, mentions) => void;
    mentionMembers: mentionMember[],
    submitting: boolean,
    user: User
}

let container // used to position the suggestion box

const CommentForm: FC<CommentFormProps> = ({
                                               CreateComment,
                                               mention,
                                               handleChange,
                                               mentionMembers,
                                               submitting,
                                               user
                                           }) => {
    return (

        <form onSubmit={CreateComment}>
            <Box display="flex" flexDirection="row" alignItems="stretch" padding={1}
                 sx={{color: useTheme().palette.text.primary}}>
                <Box display="flex" padding={"10px"}>
                    <Avatar
                        alt={``}
                        src={user.avatar}
                    />
                </Box>
                <Box display="block" width={"98vw"} padding={"5px"} sx={{
                    maxHeight: "4rem", height: '4rem',
                    fontSize: {
                        lg: "1.1rem",
                        md: "1.1rem",
                        sm: "0.75rem",
                        xs: "0.70rem"
                    },

                }}
                     id="suggestionPortal" // so that the suggestion box is always above the cursor
                     ref={el => {
                         container = el
                     }}>

                    <MentionsInput
                        value={mention}
                        onChange={handleChange}
                        placeholder="Type comment, use @ to tag other users."
                        style={MentionsInputStyle(useTheme())}
                        id={"commentMade"}
                        allowSuggestionsAboveCursor={true}
                        suggestionsPortalHost={container}
                        forceSuggestionsAboveCursor={true}
                    >

                        <Mention
                            type="orgMember"
                            trigger='@'
                            data={mentionMembers}
                        />
                    </MentionsInput>
                </Box>
                <Box height={'100%'} alignSelf={"center"} width={'6rem'} padding={"5px"}>
                    <Button
                        type="submit" //variant="contained" //color="primary"
                        sx={{
                            color: useTheme().palette.text.primary,
                            backgroundColor: useTheme().palette.mode === 'dark' ? useTheme().palette.primary.main : useTheme().palette.background.paper,
                            fontSize: '1rem',
                            padding: '10px',
                            height: '4rem',
                            width: '5rem',
                            borderRadius: useTheme().shape.borderRadius
                        }}
                        disabled={submitting}

                    >
                        {submitting == false ? <Box>Submit</Box> : <CircularProgress sx={{
                            color: useTheme().palette.text.primary,
                            backgroundColor: useTheme().palette.mode === 'dark' ? useTheme().palette.primary.main : useTheme().palette.background.paper,
                            fontSize: '1rem',
                            padding: '5px',
                            height: '4rem',
                            width: '5rem',
                            borderRadius: useTheme().shape.borderRadius
                        }}/>}
                    </Button>
                </Box>

            </Box>
        </form>
    )
}

let updated = 0
let currentOrgID = "1472a9c3-29a6-435c-a231-fbf2dbdc5dd7"

const Comments: FC<LogProps> = (props) => {//({onClose, issueID, user, orgMembers}) => {
    const [mention, setMention] = useState<string>("");
    const [mentionMembers, setMentionMembers] = useState<mentionMember[]>(null)
    const [submitting, setSubmitting] = useState<boolean>(false)
    // console.log("\tUser:", props.user)
    // const mainCtx = useMainContext()
    const mainCtx = useMainContext()
    
    useLayoutEffect(() => {
        formatMembers(props.orgMembers).then(r => {
            setMentionMembers(r)
        })
    }, [props])

    const updatedAction = () => {
        updated = Math.random() * (1000)
        setMention("")
    }

    const handleChange = (event, newValue, newPlainTextValue, mentions) => {
        setMention(newValue)
    }

    const formatMembers = async (members) => {
        let formattedMembers: mentionMember[] = []
        Object.keys(members).map((member) => {
            let cUser: mentionMember = {id: members[member].id, display: members[member].displayName}
            formattedMembers.push(cUser)
        })
        return formattedMembers
    }

    const CreateComment = (e) => {
        setSubmitting(true)
        e.preventDefault()
        CommentsStore.CreateOne({
            comment: {
                user_id: props.user.id,
                item_id: props.issueID,
                content: mention
            }
        }).then(() => {
            // TODO: Need to still implement creation of notification when comment is created on card where user is assigned
            console.log("In the comments", mention)
            const regex = /(?<=\().+?(?=\))/g
            const values: string[] = mention.match(regex) || [];
            
            values.forEach((user) => {
                NotificationStore.CreateOne({
                    ClientName: mainCtx.activeOrganization.name,
                    UserId: user,
                    CardId: props.issueID,
                    ActionType: "Tagged",
                    Description: "",
                    Platform: "Internal",
                    IsRead: false,
                });
            });
            
            props.onClose(), setSubmitting(false)});
        updatedAction()
    }

    return (
        <Box width={"100%"} sx={{ mb: 1 }}>
            <Divider variant="middle" sx={{ marginBottom: "5px" }} />
            <CommentForm CreateComment={CreateComment} mention={mention} handleChange={handleChange} mentionMembers={mentionMembers} submitting={submitting} user={props.user}/>
        </Box>
    )

}

export default Comments;