import { StatsData } from "./Distribution";
import {FilterItem} from "../../filter/Filter";
import { API, PerformJSONRPCRequest } from "../../../api/jsonrpc";
import {TreeFromAPI} from "../../../../pages/app/keystone/productstoreuniverse/utility/NodeTypesAndOptions";

export interface DistributionStatsRequest {
  clientName: string
  dateSelectionStart: string
  dateSelectionEnd: string
  limit: string
  filters: FilterItem[]
  defaultFilters: FilterItem[]
  PSU: TreeFromAPI
}

export interface DistributionStatsResponse {
  distributionStats: Record<string, StatsData>
}

const Store = {
  serviceProvider: 'Explore-Distribution-Store',
  async DistributionStats(request: DistributionStatsRequest): Promise<DistributionStatsResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.DistributionStats`,
      request: [{
        "client": request.clientName,
        "dateSelectionStart": request.dateSelectionStart,
        "dateSelectionEnd": request.dateSelectionEnd,
        "limit": request.limit,
        "filters": request.filters,
        "defaultFilters": request.defaultFilters,
        "PSU": request.PSU
      }]
    })
    return response.distributionStats
  },
};

export default Store