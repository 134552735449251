const CustomerName = (props) =>{
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Business_Icons" viewBox="0 0 203 203" height={height} >
            <defs>
                <linearGradient id="customernameblob" x1={60} x2="160.2" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="customerNameLine" x1={27} x2="31.2" y1="44.2" y2="39.9" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={lineColour}/>
                </linearGradient>
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-2" x1="180.2" x2="184.4" y1="73.9" y2="69.6" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-3" x1="72.3" x2="76.6" y1="202.1" y2="197.9" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-4" x1="114.7" x2="116.7" y1="4.6" y2="2.6" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-5" x1="116.9" x2="118.8" y1="2.5" y2=".5" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-6" x1="119.1" x2={121} y1="4.6" y2="2.7" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-7" x1="116.9" x2="118.8" y1="6.7" y2="4.8" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-8" x1=".6" x2="2.5" y1="130.7" y2="128.7" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-9" x1="2.7" x2="4.7" y1="128.5" y2="126.6" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-10" x1="4.9" x2="6.8" y1="130.7" y2="128.7" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-11" x1="2.7" x2="4.7" y1="132.8" y2="130.9" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-12" x1="196.2" x2="198.1" y1="160.5" y2="158.5" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-13" x1="198.3" x2="200.3" y1="158.3" y2="156.4" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-14" x1="200.5" x2="202.5" y1="160.4" y2="158.5" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-15" x1="198.3" x2="200.3" y1="162.6" y2="160.7" />
                <linearGradient id="customerNameFill" x1="78.3" x2="114.9" y1={187} y2="150.4" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#customerNameFill" id="customerNameFill2" x1="77.3" x2="106.5" y1="180.4" y2="151.2" />
                <linearGradient xlinkHref="#customerNameFill" id="customerNameFill3" x1="65.7" x2="116.8" y1="124.3" y2="73.2" />
                <linearGradient xlinkHref="#customerNameFill" id="customerNameFill4" x1="97.5" x2="127.5" y1="104.3" y2="74.3" />
                <linearGradient xlinkHref="#customerNameFill" id="customerNameFill5" x1="79.5" x2="104.9" y1="62.3" y2="36.9" />
                <linearGradient xlinkHref="#customerNameFill" id="customerNameFill6" x1="86.3" x2="104.8" y1={78} y2="59.5" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-16" x1="51.9" x2="155.4" y1="162.5" y2={59} />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-17" x1="81.3" x2={127} y1="91.6" y2="45.9" />
                <linearGradient xlinkHref="#customerNameLine" id="customernameLine-18" x1="89.6" x2="101.1" y1="73.4" y2="61.9" />
                <style dangerouslySetInnerHTML={{__html: ".iconWhite{fill:#fff}" }} />
            </defs>
            <g id="Icon_14">
                <path id="Contour" d="M54 132.1a2.9 2.9 0 0 0-3-1.4c-6 1-22.9 1.5-24.2-25-1.6-32 37.3-86.8 94.4-76 45.8 8.8 11.6 52.5 39.7 63.5 16.4 6.4 31.3 39.4-5.2 68.1-19.8 15.5-70.5 29.3-101.7-29.2Z" style={{fill: 'url(#customernameblob)'}} />
                <path id="Contour-2" d="M29.1 45a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" style={{fill: 'url(#customerNameLine)'}} />
                <path id="Contour-3" d="M182.3 74.7a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" style={{fill: 'url(#customernameLine-2)'}} />
                <path id="Contour-4" d="M74.5 203a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" style={{fill: 'url(#customernameLine-3)'}} />
                <g id="Group">
                    <path id="Contour-5" d="M117.2 3.6a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#customernameLine-4)'}} />
                    <path id="Contour-6" d="M117.8 3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#customernameLine-5)'}} />
                    <path id="Contour-7" d="M120.5 2.6h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#customernameLine-6)'}} />
                    <path id="Contour-8" d="M117.8 4.2a1 1 0 0 0-1 1v1.1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#customernameLine-7)'}} />
                </g>
                <g id="Group-2">
                    <path id="Contour-9" d="M3 129.7a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#customernameLine-8)'}} />
                    <path id="Contour-10" d="M3.7 129a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#customernameLine-9)'}} />
                    <path id="Contour-11" d="M6.4 128.7h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#customernameLine-10)'}} />
                    <path id="Contour-12" d="M3.7 130.3a1 1 0 0 0-1 1v1.1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#customernameLine-11)'}} />
                </g>
                <g id="Group-3">
                    <path id="Contour-13" d="M198.7 159.5a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#customernameLine-12)'}} />
                    <path id="Contour-14" d="M199.3 158.9a1 1 0 0 0 1-1v-1.1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#customernameLine-13)'}} />
                    <path id="Contour-15" d="M202 158.5h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#customernameLine-14)'}} />
                    <path id="Contour-16" d="M199.3 160.1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#customernameLine-15)'}} />
                </g>
                <path id="Contour-17" d="M59.6 162.7v6.6s6.6 8.8 44 8.1c0 0 39.8.3 44-9.6l-.1-5.2Z" className="iconWhite" />
                <ellipse id="Contour-18" cx="103.6" cy={162} className="iconWhite" rx={44} ry="9.6" />
                <path id="Contour-19" d="M133.6 169v5.8s-58.7 8.5-74-6.5v-5.7s9 14.6 74 6.4Z" style={{fill: 'url(#customerNameFill)'}} />
                <path id="Contour-20" d="M125 169.7s-52.3 1-52-8c0 0-.6-3.3 9-7.3 0 0-16.6.7-21 5.5 0 0-1 .1-1.2 1.4s.2 6.7 24.4 9.3 40.8-.9 40.8-.9Z" style={{fill: 'url(#customerNameFill2)'}} />
                <path id="Contour-21" d="M117.3 28.8a46.2 46.2 0 0 0-27.4 0c-24.8 7.7-35.7 37-24.3 60.3l34.3 70.3a4 4 0 0 0 7.3 0l34.4-70.3c11.3-23.3.4-52.6-24.3-60.3ZM103.6 89a21.3 21.3 0 1 1 21.3-21.4 21.3 21.3 0 0 1-21.3 21.4Z" className="iconWhite" />
                <circle id="Contour-22" cx="104.2" cy="68.8" r="31.3" className="iconWhite" />
                <path id="Contour-23" d="M73 40.4s-16.3 27.3 10 61.1l19.4 25.3a1.6 1.6 0 0 0 2.5.2l33-32.8-32.5 67s-2.8 3.2-7.9-7.2c-3.5-7.2-21-40.7-30.5-61.6a87 87 0 0 1-5.9-17.5l.1-11.4 3.1-12Z" style={{fill: 'url(#customerNameFill3)'}} />
                <path id="Contour-24" d="m89.8 96.6 28.1-17-3.8-7.5 7-4.1 10.4 16s-13 26-41.7 12.6Z" style={{fill: 'url(#customerNameFill4)'}} />
                <path id="Contour-25" d="m105.5 37.5 4 7.7-8 4.5L97 45 74.9 57.7s6.3-19.3 30.6-20.2Z" style={{fill: 'url(#customerNameFill5)'}} />
                <circle id="Contour-26" cx="95.6" cy="68.8" r="13.1" style={{fill: 'url(#customerNameFill6)'}} />
                <path id="Contour-27" d="m96.3 78 6.7-12.5a.4.4 0 0 0-.3-.6l-14.1-1.6a.4.4 0 0 0-.3.6l6.4 5.4a.4.4 0 0 1 .2.2l.7 8.4a.4.4 0 0 0 .7.1Z" className="iconWhite" />
                <g id="Group-4">
                    <path id="Contour-28" d="m112.2 151.5 30.3-62a45.7 45.7 0 0 0 .8-38.2 41.2 41.2 0 0 0-25.7-23.5 47.5 47.5 0 0 0-28 0A41.2 41.2 0 0 0 64 51.3a45.7 45.7 0 0 0 .8 38.2l30.3 62c-19 .9-36.4 4.5-36.4 10.5v5.8c0 7 23.2 10.6 45 10.6s45-3.7 45-10.6V162c0-6-17.5-9.6-36.4-10.5ZM66.5 88.6a43.7 43.7 0 0 1-.8-36.5 39.2 39.2 0 0 1 24.5-22.4 45.3 45.3 0 0 1 26.8 0 39.2 39.2 0 0 1 24.5 22.4 43.7 43.7 0 0 1-.8 36.5L110 151.4l-1 2-2.7 5.6a3 3 0 0 1-5.5 0l-2.7-5.5-1-2ZM96 153.5l3.1 6.3a5 5 0 0 0 4.6 2.9 5 5 0 0 0 4.5-2.9l3.1-6.3c22.1.8 35.4 5.2 35.4 8.5 0 3.6-16.4 8.6-43 8.6s-43-5-43-8.6c0-3.3 13.3-7.7 35.3-8.5Zm7.7 23c-26.6 0-43-5-43-8.7v-2.5c6 4.8 25 7.3 43 7.3s37-2.5 43-7.3v2.5c0 3.6-16.3 8.6-43 8.6Z" style={{fill: 'url(#customernameLine-16)'}} />
                    <path id="Contour-29" d="M136.5 68.8a32.3 32.3 0 1 0-32.3 32.3 32.3 32.3 0 0 0 32.3-32.3ZM104.2 99a30.2 30.2 0 0 1-12.3-2.6L118 80.9a1.5 1.5 0 0 0 .5-2l-3.5-6.2 6.2-3.6 9.1 15a30.3 30.3 0 0 1-26.1 15Zm30.3-30.3a30.2 30.2 0 0 1-.6 5.8l-8.7-15a2.3 2.3 0 0 0-3.2-.9l-12.6 7.4a14 14 0 0 0-4.4-7.8l12.8-7.4a2.3 2.3 0 0 0 .8-3.2L114 40a30.4 30.4 0 0 1 20.5 28.6ZM85.9 79l-8 4.8a30.2 30.2 0 0 1-3.4-8.8l7.2-4a14 14 0 0 0 4.2 8Zm-2.4-10.3a12.1 12.1 0 1 1 12 12.2 12.1 12.1 0 0 1-12-12.2Zm20.7-30.3h.7l3.5 6a.3.3 0 0 1 0 .2.3.3 0 0 1-.1.2l-6.8 3.9a.3.3 0 0 1-.4-.1L98.8 45a2.3 2.3 0 0 0-3.1-.9L77.3 54.8a30.3 30.3 0 0 1 26.9-16.3ZM75.9 58 96.8 46a.3.3 0 0 1 .4.1l2.2 3.5a2.3 2.3 0 0 0 3.1.8l6.8-3.8a2.3 2.3 0 0 0 .8-3.2l-2.8-4.8a31.7 31.7 0 0 1 3.8.7l5.8 9.4a.3.3 0 0 1 0 .3.3.3 0 0 1-.2.2L103.3 57a14.1 14.1 0 0 0-21.8 11.8l-7.4 4a31.5 31.5 0 0 1-.2-4 30.3 30.3 0 0 1 2-11Zm3 27.6 8.7-5.1a14.1 14.1 0 0 0 22-11.7v-.4l13.4-7.8a.3.3 0 0 1 .3 0 .3.3 0 0 1 .1.1l9.8 16.8a30 30 0 0 1-1.8 4.6l-8.7-14.4a1.5 1.5 0 0 0-2-.5l-7.1 4.1a1.5 1.5 0 0 0-.5 2l3.5 6.1-26.8 16A30.5 30.5 0 0 1 79 85.5Z" style={{fill: 'url(#customernameLine-17)'}} />
                    <path id="Contour-30" d="M96 79.2a1.4 1.4 0 0 0 1.2-.7l6.7-12.6a1.4 1.4 0 0 0 0-1.2 1.3 1.3 0 0 0-1-.8l-14.2-1.6a1.3 1.3 0 0 0-1.4.8 1.4 1.4 0 0 0 .4 1.6l6.2 5.2.7 8a1.4 1.4 0 0 0 1 1.3 1.3 1.3 0 0 0 .4 0Zm-5.4-14.7 11 1.3-5.2 9.9-.5-6.3a1.4 1.4 0 0 0-.5-.9Z" style={{fill: 'url(#customernameLine-18)'}} />
                </g>
            </g>
        </svg>


    )
}
export default CustomerName