import {useEffect, useState} from 'react';
import type { FC } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography
} from "@mui/material";
import * as React from "react";
import _ from "lodash";
import {
  GridComparatorFn,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector
} from "@mui/x-data-grid";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import {useTheme} from "@mui/system";
import { DataGridPro } from '@mui/x-data-grid-pro';
import {InsightWidgetProps} from "../InsightWidgetSwitcher";

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    '& .red': {
      color: '#f00',
    },
    '& .green': {
      color: '#00FF00',
    },
    '& .super-app-theme--Filled': {
      backgroundColor: '#6b778c',
      '&:hover': {
        backgroundColor: '#42526e',
      },
    },
  }
});

const customComparator: GridComparatorFn = (v1, v2) => {
  const numArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let flag1 = false;
  let flag2 = false;

  if (v1 == null && v2 != null) {
    let num = (v2 as number)
    return num
  } else if (v1 != null && v2 == null) {
    let num = (v1 as number)
    return num
  } else  if(v1 != null && v2 != null) {
    let temp1 = v1.toString().replace(/,/g, "")
    let temp2 = v2.toString().replace(/,/g, "")

    for (let i=0; i<numArray.length; i++) {
      if (temp1.includes(numArray[i])) {
        flag1 = true;
      }
    }

    for (let i=0; i<numArray.length; i++) {
      if (temp2.includes(numArray[i])) {
        flag2 = true;
      }
    }

    if (flag1 == true && flag2 == true) {
      let tempNum1 = parseInt(temp1)
      let tempNum2 = parseInt(temp2)

      return tempNum1 - tempNum2
    }

    return temp1.localeCompare(temp2)
  } else {
    console.log("Cannot Sort")
    return 0
  }

}

const setCSVFilename = () => {
  const tab = document.title
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const timestamp = today.toISOString().slice(0, 19);
  const filename = tab + "_" + timestamp
  return filename
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: setCSVFilename()
        }}
      />
    </GridToolbarContainer>
  );
}

const GenericTable: FC<InsightWidgetProps> = ({chartName, chartData, chartColumns}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const clsxWrapper = (params: {}) => clsx('super-app', params)

  const [data, setData] = useState<tableData>({columns: [], rows: []});
  const [page, setPage] = React.useState(0);

  interface tableData {
    columns: any[],
    rows: any[]
  }

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  let formatCol = (k: string) => {
    if (k.includes("perc") || k.includes("Perc") || k.includes("PERC")) {
      return {
        field: k,
        headerName: k,
        width: 200,
        sortComparator: customComparator,
        cellClassName: (params) =>
          clsxWrapper({
            negative: typeof params.value == 'string' ? params.value.includes('-') :  params.value < 0,
            positive: typeof params.value == 'string' ? !params.value.includes('-') :  params.value > 0,
          })
      }
    } else {
      return {
        field: k,
        headerName: k,
        width: 200,
        sortComparator: customComparator,
      }
    }
  };

  let formatData = () => {
    if (chartData && Object.keys(chartData) && Object.keys(chartData).length &&
      chartData[Object.keys(chartData)[0]]) {

      const cols = chartColumns.map((k) => {
        return formatCol(k)
      })

      let rows = []

      _.range(chartData[Object.keys(chartData)[0]].length).map((idx) => {
        let row = {}
        row['id'] = idx + 1
        Object.keys(chartData).map((k) => {
          if (Number.isFinite(chartData[k][idx]) && chartData[k][idx] !== null) {
            let temp = numberWithCommas(chartData[k][idx])
            row[k] = temp
          } else {
            row[k] = chartData[k][idx]
          }
        })
        rows.push(row)
      })
      setData({
        columns: cols,
        rows: rows,
      })
    }
    else {
      const cols = [{"field": "NoData", "headerName": "NO DATA TO DISPLAY", "width": 400}]
      const rows = [{id: 0, NoData: "Query returned no results"}]
      setData({
        columns:cols,
        rows: rows
      })
    }

  }

  useEffect(() => {
    formatData()
  }, [chartData, chartColumns])

  if (chartData && Object.keys(chartData) && Object.keys(chartData).length && chartData[Object.keys(chartData)[0]]) {
    return (
      <div className={classes.root}>
        <Box
          sx={{
            display: "grid",
            gridAutoColumns: "1fr",
            gridAutoFlow: "row",
            height: '100%',
            width: '100%'
        }}
        >
          <Box>
            <Typography>{chartName}</Typography>
          </Box>
          <Box
            sx={{
              height: "100%",
              flexGrow: 1,
              '& .super-app.negative': {
                color: '#af092c',
                fontWeight: 600
              },
              '& .super-app.positive': {
                color: '#09af28',
                fontWeight: 600
              },
              '& .super-app.amber': {
                color: '#f7b900',
                fontWeight: 600
              },
              '& .super-app.red': {
                color: '#af092c',
                fontWeight: 600
              },
              '& .super-app.green': {
                color: '#09af28',
                fontWeight: 600
              },
              '& .super-app.bold': {
                fontWeight: 900
              },
            }}
          >
            <DataGridPro
              autoHeight
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              loading={data.rows.length === 0}
              rowHeight={38}
              columns={data.columns}
              rows={data.rows}
              pageSize={10}
              columnBuffer={2}
              rowsPerPageOptions={[10]}
              pagination
              components={{ Toolbar: CustomToolbar}}
            />
          </Box>
        </Box>
      </div>
    );
  } else {
    return (
      <Card>
        <CardHeader
          title="NO DATA TO DISPLAY"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="body1"
          >
            Query returned no results
          </Typography>
        </CardContent>
      </Card>
    )
  }
}

export default GenericTable

