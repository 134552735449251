const ValueReturned = (props) =>{
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Business_Icons" data-name="Business Icons" version="1.1" viewBox="0 0 203 203" height={height}>
            <defs id="defs55">
                <linearGradient id="valueReturnedBlob" x1="59.9" x2="160.1" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop id="stop4" offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="valueReturnedLine" x1=".9" x2="5.1" y1="117.9" y2="113.6" gradientUnits="userSpaceOnUse">
                    <stop id="stop9" offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-2" x1="121.6" x2="125.8" y1="5.1" y2=".9" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-3" x1="197.9" x2="202.1" y1="174.8" y2="170.5" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-4" x1="29.5" x2="31.5" y1="29.5" y2="27.6" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-5" x1="31.7" x2="33.6" y1="27.4" y2="25.5" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-6" x1="33.9" x2="35.8" y1="29.5" y2="27.6" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-7" x1="31.7" x2="33.6" y1="31.6" y2="29.7" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-8" x1={180} x2="181.9" y1="64.4" y2="62.5" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-9" x1="182.2" x2="184.1" y1="62.3" y2="60.4" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-10" x1="184.4" x2="186.3" y1="64.4" y2="62.5" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-11" x1="182.2" x2="184.1" y1="66.5" y2="64.6" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-12" x1="74.8" x2="76.8" y1="200.4" y2="198.4" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-13" x1={77} x2="78.9" y1="198.2" y2="196.3" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-14" x1="79.2" x2="81.1" y1="200.3" y2="198.4" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-15" x1={77} x2="78.9" y1="202.5" y2="200.5" />
                <linearGradient id="valueReturnedFill" x1="54.3" x2="149.9" y1="94.7" y2="94.7" gradientUnits="userSpaceOnUse">
                    <stop id="stop28" offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#valueReturnedFill" id="valueReturnedFill-2" x1="77.5" x2="168.6" y1="103.9" y2="103.9" />
                <linearGradient xlinkHref="#valueReturnedFill" id="valueReturnedFill-3" x1="34.5" x2="60.4" y1="78.5" y2="78.5" />
                <linearGradient xlinkHref="#valueReturnedFill" id="valueReturnedFill-4" x1="101.7" x2="124.7" y1={83} y2={83} />
                <linearGradient xlinkHref="#valueReturnedFill" id="valueReturnedFill-5" x1={83} x2="105.3" y1="91.8" y2="91.8" />
                <linearGradient xlinkHref="#valueReturnedFill" id="valueReturnedFill-6" x1="97.1" x2="107.2" y1="94.7" y2="94.7" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-16" x1="67.6" x2="136.6" y1="129.2" y2="60.3" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-17" x1="72.1" x2="132.1" y1="124.7" y2="64.8" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-18" x1="56.8" x2="156.3" y1="160.7" y2="61.2" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-19" x1={26} x2="76.1" y1="111.9" y2="61.8" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-20" x1="66.8" x2="72.7" y1="97.7" y2="91.8" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-21" x1="98.3" x2="128.7" y1="105.9" y2="75.5" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-22" x1="83.6" x2="85.8" y1="65.4" y2="63.2" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-23" x1="69.7" x2={74} y1="114.3" y2={110} />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-24" x1="118.4" x2="120.6" y1="126.3" y2="124.1" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-25" x1="99.2" x2={105} y1="65.3" y2="59.4" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-26" x1="70.8" x2={73} y1="78.4" y2="76.2" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-27" x1="82.5" x2="86.9" y1="127.5" y2={123} />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-28" x1="99.2" x2={105} y1="130.1" y2="124.2" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-29" x1="131.3" x2="133.4" y1="113.3" y2="111.1" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-30" x1="131.6" x2="137.5" y1="97.7" y2="91.8" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-31" x1="130.2" x2="134.5" y1="79.5" y2="75.2" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-32" x1="117.3" x2="121.7" y1="66.5" y2={62} />
                <linearGradient xlinkHref="#valueReturnedFill" id="valueReturnedFill-36" x1="91.1" x2={114} y1="72.3" y2="49.4" gradientUnits="userSpaceOnUse"/>
                <linearGradient xlinkHref="#valueReturnedFill" id="valueReturnedFill-2-3" x1="76.8" x2="115.8" y1="86.2" y2="47.2" gradientTransform="matrix(.26 0 0 .26 58.3 105.9)" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-18-5" x1="80.9" x2="122.8" y1="82.3" y2="40.3" gradientTransform="matrix(.26 0 0 .26 58.3 105.9)" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-62" x1="12.8" x2="17.1" y1="130.9" y2="126.7" gradientUnits="userSpaceOnUse"/>
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-16-2" x1="76.1" x2="127.3" y1={87} y2="35.8" gradientTransform="matrix(.26 0 0 .26 58.3 105.9)" />
                <linearGradient xlinkHref="#valueReturnedLine" id="valueReturnedLine-17-7" x1="90.9" x2="114.3" y1="72.6" y2="49.2" gradientTransform="matrix(.26 0 0 .26 58.3 105.9)" />
                <linearGradient xlinkHref="#valueReturnedFill-36" id="valueReturnedLinearGradient2945" x1="91.1" x2={114} y1="72.3" y2="49.4" gradientTransform="matrix(.26 0 0 .26 58.3 105.9)" gradientUnits="userSpaceOnUse" />
                <style id="style2" dangerouslySetInnerHTML={{__html: ".iconWhite{fill:#fff}.valueReturnedCls-19{fill:url(#valueReturnedFill-2)}" }} />
            </defs>
            <g id="Icon_15">
                <path id="Contour" d="M53.9 132.1a2.9 2.9 0 0 0-3-1.4c-6 1-23 1.5-24.3-25C25 73.7 64 19 121 29.7c45.9 8.8 11.6 52.5 39.7 63.5 16.5 6.4 31.3 39.4-5.2 68.1-19.7 15.5-70.4 29.3-101.7-29.2Z" style={{fill: 'url(#valueReturnedBlob)'}} />
                <path id="Contour-2" d="M3 118.7a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" style={{fill: 'url(#valueReturnedLine)'}} />
                <path id="Contour-3" d="M123.7 6a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" style={{fill: 'url(#valueReturnedLine-2)'}} />
                <path id="Contour-4" d="M200 175.6a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 0 0 0 2 1 1 0 1 0 0-2Z" style={{fill: 'url(#valueReturnedLine-3)'}} />
                <g id="Group">
                    <path id="Contour-5" d="M32 28.5a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#valueReturnedLine-4)'}} />
                    <path id="Contour-6" d="M32.6 28a1 1 0 0 0 1-1v-1.2a1 1 0 0 0-2 0V27a1 1 0 0 0 1 1Z" style={{fill: 'url(#valueReturnedLine-5)'}} />
                    <path id="Contour-7" d="M35.3 27.5h-1a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#valueReturnedLine-6)'}} />
                    <path id="Contour-8" d="M32.6 29.1a1 1 0 0 0-1 1v1.1a1 1 0 0 0 2 0v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#valueReturnedLine-7)'}} />
                </g>
                <g id="Group-2">
                    <path id="Contour-9" d="M182.5 63.5a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#valueReturnedLine-8)'}} />
                    <path id="Contour-10" d="M183.1 62.8a1 1 0 0 0 1-1v-1a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#valueReturnedLine-9)'}} />
                    <path id="Contour-11" d="M185.8 62.5h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#valueReturnedLine-10)'}} />
                    <path id="Contour-12" d="M183.1 64a1 1 0 0 0-1 1v1.2a1 1 0 0 0 2 0V65a1 1 0 0 0-1-1Z" style={{fill: 'url(#valueReturnedLine-11)'}} />
                </g>
                <g id="Group-3">
                    <path id="Contour-13" d="M77.4 199.4a1 1 0 0 0-1-1h-1.1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#valueReturnedLine-12)'}} />
                    <path id="Contour-14" d="M78 198.8a1 1 0 0 0 1-1v-1.1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#valueReturnedLine-13)'}} />
                    <path id="Contour-15" d="M80.7 198.4h-1.1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#valueReturnedLine-14)'}} />
                    <path id="Contour-16" d="M78 200a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#valueReturnedLine-15)'}} />
                </g>
                <path id="Contour-17" d="M55.8 56.9a.5.5 0 0 0-.6 0 56.2 56.2 0 0 0-6.9 76l-1.2-1.6a61.4 61.4 0 0 1 3.7-79 .5.5 0 0 0 0-.6l-3.2-4a.5.5 0 0 1 .4-.8l11.9.4a.5.5 0 0 1 .5.5L60 59.7a.5.5 0 0 1-.8.3Z" className="iconWhite" />
                <path id="Contour-18" d="M100.4 36.4a60.4 60.4 0 1 1-31.8 111.8.5.5 0 0 1-.2-.7l4.1-6.1a.5.5 0 0 0-.5-.8l-16.5 2a.5.5 0 0 0-.5.6l2 16.5a.5.5 0 0 0 .9.3l4.6-4.7a.5.5 0 0 1 .6 0 66 66 0 1 0 39.4-119Z" className="iconWhite" />
                <path id="Contour-21" d="M78 162.6c13.9 2.6 60.9 7.7 77.2-40 0 0 27.4-54.2-34.5-82.8 0 0 48.9 10.2 47.9 65.5 0 0-2.5 52.4-52.2 61.4 0 0-21.2 4.6-38.6-3.2a.5.5 0 0 1 .3-1Z" className="valueReturnedCls-19" />
                <path id="Contour-22" d="M37.5 74.7s10.1-18.3 22.1-26.9a.5.5 0 0 1 .8.5L60 59.8a.5.5 0 0 1-.8.4l-4.6-3.5a.5.5 0 0 0-.6 0 60 60 0 0 0-16.5 52.5s-6.8-13.2 0-34.5Z" style={{fill: 'url(#valueReturnedFill-3)'}} />
                <g id="Group-4">
                    <path id="Contour-28" d="M150.4 55.3a66.4 66.4 0 0 0-47.7-20h-2.2a1 1 0 0 0 0 2h.2A59.4 59.4 0 0 1 160 97.7a60 60 0 0 1-58 58.5 59.2 59.2 0 0 1-32.3-8.6l3.8-5.7a1.5 1.5 0 0 0-1.4-2.3l-16.6 2a1.5 1.5 0 0 0-1 .5 1.6 1.6 0 0 0-.3 1.2l2 16.5a1.5 1.5 0 0 0 2.6.9l4.2-4.4a66.2 66.2 0 0 0 39.5 13l2.8-.1a67 67 0 0 0 45-113.8Zm-45 111.8A64.4 64.4 0 0 1 64 154.4a1.5 1.5 0 0 0-2 .1l-3.9 4-1.8-15 15-1.8-3.5 5.2a1.5 1.5 0 0 0-.2 1.2 1.5 1.5 0 0 0 .7 1 61.4 61.4 0 0 0 76.1-95.4 62.8 62.8 0 0 0-8.5-7.2A65.7 65.7 0 0 1 149 56.7a65 65 0 0 1-43.6 110.4Z" style={{fill: 'url(#valueReturnedLine-18)'}} />
                    <path id="Contour-29" d="M49 132.2A55.5 55.5 0 0 1 55.6 58l3 2.8a1.5 1.5 0 0 0 2.5-1l.4-12a1.5 1.5 0 0 0-.4-1 1.5 1.5 0 0 0-1-.5L48 46a1.5 1.5 0 0 0-1.4.8 1.5 1.5 0 0 0 .2 1.7l3 3.5a62.1 62.1 0 0 0-3.5 80l1.2 1.5a1 1 0 0 0 .8.4 1 1 0 0 0 1-1 1 1 0 0 0-.3-.6ZM51.6 53a1.5 1.5 0 0 0 0-2l-2.4-3 10.3.2-.3 10.3-2.6-2.3a1.5 1.5 0 0 0-2 0A57.2 57.2 0 0 0 35.8 101a60.1 60.1 0 0 1 15.7-48Z" style={{fill: 'url(#valueReturnedLine-19)'}} />
                </g>
            </g>
            <g id="layer1" transform="matrix(5.04 0 0 5.04 -329 -519.1)">
                <circle id="Contour-17-0" cx="85.1" cy={122} r="9.3" className="iconWhite" style={{fill: '#fff', strokeWidth: '.264583'}} />
                <path id="Contour-18-9" d="M84.7 116.6v1.4s-2.3 1.3-1.5 3c.7 1.7 3 1.8 3 1.8s.5 1.8-.9 1.7c-1.3 0-1.1-.9-1.1-.9h-1.5s.5 1.9 1.9 2.2v1.5l1.4.1.2-1.5s1.6-.8 1.6-2.2a3 3 0 0 0-2-2.7c-1.1-.4-1.6-1.3-.4-1.6 0 0 .8.2.8.7h1.5s-.4-1.5-1.2-1.8c-.9-.3-.5-1.7-.5-1.7z" style={{fill: 'url(#linearGradient2945)', strokeWidth: '.264583'}} />
                <path id="Contour-19-3" d="M94.4 123.9S89 131 83 129c-3.2-1.1-5.3-4.1-5.2-7.4.1-2.2 1-5 3.6-8a9.2 9.2 0 0 0-3.4 2.4c-2.2 2.3-3.7 6.4-.5 11.2 5.5 8.1 16.9 2.3 16.9-3.4z" className="valueReturnedCls-19" style={{fill: 'url(#valueReturnedFill-2-3)', strokeWidth: '.264583'}} />
                <path id="Contour-25-6" d="M85.2 131.7a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2zm0-18.6a9 9 0 1 1 0 18 9 9 0 0 1 0-18z" style={{fill: 'url(#valueReturnedLine-16-2)', strokeWidth: '.264583'}} />
                <path id="Contour-26-0" d="M83.4 125.3c.3.3.6.6 1 .7v1c0 .3.3.5.5.5h1c.3 0 .5-.2.5-.5v-1c1-.4 1.7-1.4 1.7-2.5 0-.6-.2-1.2-.6-1.7-.4-.4-1-.8-1.7-1-.2 0-.5-.1-.7-.3a.4.4 0 0 1-.2-.3c0-.2 0-.3.2-.4l.3-.1.3.1.2.2c0 .2.2.4.4.4h1a.5.5 0 0 0 .5-.6 2.2 2.2 0 0 0-1.4-1.8v-1c0-.3-.2-.5-.5-.5h-1c-.3 0-.5.2-.4.5v1c-.4.1-.6.3-.9.5-.4.5-.7 1.1-.7 1.8 0 .5.2 1 .6 1.5.4.4 1 .7 1.6.9.3 0 .6.2.8.4l.2.3c0 .2 0 .4-.2.6l-.6.2c-.1 0-.3 0-.5-.2a.7.7 0 0 1-.2-.4c0-.2-.2-.4-.4-.4h-1c-.4 0-.6.3-.5.6 0 .4.2 1.1.7 1.5zm.7-1.5c0 .2.2.4.4.6.2.2.5.3.8.3.4 0 .7-.1 1-.3.2-.3.3-.6.3-1a1 1 0 0 0-.3-.7l-1-.5c-.6-.1-1-.4-1.5-.8-.2-.3-.4-.7-.3-1.1a1.8 1.8 0 0 1 1.2-1.8c.2-.1.3-.3.3-.5v-1h.8v1c0 .3.2.4.4.5.2 0 .4.2.5.3.3.3.5.7.6 1h-1a1 1 0 0 0-.2-.4 1 1 0 0 0-.7-.2 1 1 0 0 0-.7.2 1 1 0 0 0 0 1.5l1 .5c.5.1 1 .4 1.4.8.3.4.5.8.4 1.3a2 2 0 0 1-1.3 2c-.2 0-.4.2-.4.4v1H85v-1c0-.2-.2-.4-.4-.4l-.9-.5c-.3-.4-.4-.8-.5-1.2z" style={{fill: 'url(#valueReturnedLine-17-7)', strokeWidth: '.264583'}} />
                <path id="Contour-27-6" d="M85.2 130a7.9 7.9 0 1 0 0-15.8 7.9 7.9 0 0 0 0 15.7zm0-15.2a7.3 7.3 0 1 1 0 14.6 7.3 7.3 0 0 1 0-14.6z" style={{fill: 'url(#valueReturnedLine-18-5)', strokeWidth: '.264583'}} />
            </g>
        </svg>

    )
}
export default ValueReturned