import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CardHeader,
  Checkbox,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import {indexOf} from "lodash";
import {CheckBoxOutlineBlank, CheckBoxOutlined} from "@mui/icons-material";
import {Card as KanbanCardObj, KanbanStore} from "../../../../../../cog/keystone/card";
import {Member} from "../../../../../../cog/keystone/member";
import {useMainContext} from "../../../../../../contexts/MainContext";
import {ActionLogStore} from "../../../../../../cog/keystone/actionLog";
import {NotificationStore} from "../../../../../../cog/keystone/notifications";

interface AssignmentDialogProps {
  loadingAssign: boolean;
  theIssue: KanbanCardObj;
  members: Member[];
  multiUserToAssign: any[];
  setMultiUserToAssign: (users: any[]) => void;
  setLoadingAssign: (state: boolean) => void;
  onClick: ()=>void;
}

// think the problem has to do with when and how multiuserchange thing is passed into over here

const AssignmentDialog: FC<AssignmentDialogProps> = ({loadingAssign, theIssue, members, multiUserToAssign, setMultiUserToAssign, setLoadingAssign, onClick}) => {
  const mainCtx = useMainContext();

  const [usersToAssign, setUsersToAssign] = useState<Member[]>(null);
  const [memberOptions, setMemberOptions] = useState<Member[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  function handleOnChange(event, newValue) {
    setMultiUserToAssign(newValue)
    setUsersToAssign(newValue)
  }

  // This one is used to establish the defa
  useLayoutEffect(() => {
    // makeColumns();
    try {
      JSON.parse(`{"AssignedTo": ${theIssue.assignedTo} }`)
    } catch (e) {
      // cannot parse
      theIssue.assignedTo = ""
    }
    if (theIssue.assignedTo != "") {
      let tempUsers = []

      Object.values(members).map((item) => {
        if (indexOf(JSON.parse(`{"AssignedTo": ${theIssue.assignedTo} }`)['AssignedTo'], item.id) != -1) {
          tempUsers.push(item)
        }

      })
      setUsersToAssign(tempUsers)
    } else {
      setUsersToAssign([])
    }

    if (members != null ) {
      setMemberOptions(Object.values(members))
    }

  }, []);

  // behaviour is weird without this refresh
  useEffect(() => {
    // PrintEverything("Start of Use effect")
    // setUsersToAssign(null)
    // setUsersToAssign(multiUserToAssign)
    if (members != null ) {
      setMemberOptions(Object.values(members))
    }
    // if (multiUserToAssign != null) {
    //   setUsersToAssign(multiUserToAssign)
    // }
    // PrintEverything("End of Use effect")
  }, [members, multiUserToAssign])


  useEffect(() => {
    // PrintEverything("Start of second Use effect")
    if (memberOptions != null && usersToAssign != null) {
      setIsLoading(false)
    }
    // PrintEverything("End of second Use effect")
  }, [memberOptions, usersToAssign])

  return (
      <Box>
        <DialogTitle>Assign Users</DialogTitle>
        <DialogContent>
          {isLoading == false && memberOptions != null &&  usersToAssign != null &&
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Grid item sx={{
                  mt: 1,
                  mb: 5,
                  flex: 1

                }}>
                  <Autocomplete
                      value={usersToAssign}
                      onChange={(event, newValue) => {
                        // @ts-ignore
                        setUsersToAssign(newValue);
                        handleOnChange(event, newValue);
                      }}
                      multiple
                      id="checkboxes-tags-demo"
                      limitTags={2}
                      options={memberOptions}
                      defaultValue={ theIssue.assignedTo != "" ?
                          memberOptions.map((item) => {
                            if (indexOf(JSON.parse(`{"AssignedTo": ${theIssue.assignedTo} }`)['AssignedTo'], item.id) != -1) {
                              return item
                            }
                          }) : [] }
                      disableCloseOnSelect
                      // @ts-ignore
                      getOptionLabel={(option) => option.displayName}
                      renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                // style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            <CardHeader
                                avatar={
                                  <Avatar
                                      src={option.avatar}
                                      alt={option.displayName}
                                      variant={"circular"}
                                      sx={{width: 24, height: 24}}
                                  />
                                }
                                title={option.displayName} disableTypography={true}
                                sx={{padding: 0}}
                                key={`AssignedUser_${option.id}`}
                            >
                            </CardHeader>
                          </li>
                      )}
                      renderInput={(params) => (
                          <TextField {...params} label="Assignees" placeholder="Assignees" value={usersToAssign}  />
                      )}
                  />
                </Grid>
              </Grid>}
          <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
            {isLoading? <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  gap: "10px",
                  px: 0.2}}>
                  <Typography sx={{pt:1}}>Saving</Typography>
                  <CircularProgress/>
                </Box>
                :
            <ButtonGroup>
              <Button
                onClick={() => {
                  setLoadingAssign(true)
                  setIsLoading(true)
                  usersToAssign.map((v: any) => {
                    ActionLogStore.InsertOne({userID: mainCtx.user.id, orgID: mainCtx.activeOrganization.id, cardID: theIssue.id, actionType: "Opportunity Assigned", assignedUserID: v.id, timezone: "", clientName: mainCtx.activeOrganization.name})
                    NotificationStore.CreateOne({
                      ClientName: mainCtx.activeOrganization.name,
                      UserId: v.id,
                      CardId: theIssue.id,
                      ActionType: "Assigned",
                      Description: "",
                      Platform: "Internal",
                      IsRead: false
                    })
                  })
                  // setMultiUserToAssign(usersToAssign)

                  KanbanStore.AssignMultiUsers({
                    assignUsers: usersToAssign.map((v: any) => {
                      return v.id
                    }),
                    cardIDs: [theIssue.id],
                    client: mainCtx.activeOrganization.name,
                    userID: mainCtx.user.id
                  }).then((p) => {
                    theIssue.assignedTo = p.toString()
                    setLoadingAssign(false)
                    setIsLoading(false)
                    onClick()
                  })
                }}
                variant="text"
                disabled={isLoading}
              >
                Save Changes
              </Button>
              <Button variant="text" onClick={onClick}>
                Close
              </Button>
            </ButtonGroup>
            }
          </Box>
        </DialogContent>
      </Box>
  );
}

export default AssignmentDialog;