import React, {FC, useEffect} from "react";
import {Avatar, Box, CircularProgress, Tooltip, Typography} from "@mui/material";
import {useMainContext} from "../../../../../contexts/MainContext";
import AvatarGroup from "@mui/material/AvatarGroup";
import {AnimatePresence} from 'framer-motion/dist/framer-motion'
import AnimationComponent from "../AnimationComponent";


interface OwnerComponentProps {
    owner: any;
}

const processUser = (name, members) => {
    return members.find(member => member.displayName === name || member.id === name) || {
        displayName: name,
        id: '0',
        avatar: ''
    };
};
const OwnerComponent: FC<OwnerComponentProps> = (props: OwnerComponentProps) => {
    const {owner} = props;
    const [targetLoading, setTargetLoading] = React.useState<boolean>(true);
    const ctx = useMainContext()
    let userDetails;

    useEffect(() => {
        if (ctx.members) {
            setTargetLoading(false)
        }
    }, [ctx.members])

    return <Box sx={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }}>

        <AnimatePresence>
            {targetLoading ? (
                <AnimationComponent keyId={'owner' + 1}
                                    component={<CircularProgress size={20}/>}
                />
            ) : (
                <AnimationComponent keyId={'owner' + 2}
                                    component={<AvatarGroup max={3}
                                                            spacing={"medium"}
                                                            sx={{
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                '& .MuiAvatar-root': {
                                                                    border: '1px solid black',
                                                                },
                                                            }}>
                                        {(owner && owner.length && ctx.members) ? owner.map((user) =>
                                                (userDetails = processUser(user, ctx.members)) ?
                                                    <Tooltip title={userDetails.displayName}
                                                             key={userDetails.id}>
                                                        <Avatar
                                                            src={userDetails.avatar}
                                                            sx={{
                                                                width: 24,
                                                                height: 24,
                                                                border: '1px solid #000',
                                                                backgroundColor: 'fff',
                                                            }}>{userDetails.displayName.substring(0,1)}</Avatar></Tooltip> : null) :
                                            (ctx.members) ? <Typography variant={'body2'}>N/A</Typography> :
                                                <CircularProgress/>}
                                    </AvatarGroup>}
                />
            )}
        </AnimatePresence>
    </Box>
};
export default OwnerComponent