import React from 'react';
import type { FC } from 'react';
import logoPath from "./RCLLogo.svg";
import mainLogo from './Rainbow-Chickens-Logo.png';

interface RCLLogoProps {
  [key: string]: any;
}

const RCLLogo: FC<RCLLogoProps> = (props) => (
  <img
    alt="RCL Logo"
    src={mainLogo}
    height={100}
    width={350}
    style={{
      margin: 1
    }}
    {...props}
  />
);

export default RCLLogo;