import React, {FC, useEffect, useState} from "react";
import {Avatar, Box, CardHeader, LinearProgress, Tooltip, Typography} from "@mui/material";
import {StyledDataGrid} from "../../listview/CustomDataGridTheme";
import {GridAlignment, GridToolbar} from "@mui/x-data-grid-pro";
import {CalendarTodayOutlined, ExpandLess, ExpandMore} from "@mui/icons-material";
import {useMainContext} from "../../../../../contexts/MainContext";
import {useTheme} from "@mui/material/styles";
import EFLog from "../../../../../cog/keystone/exclude/EFLog";
import {RemoveOneRequest} from "../../../../../cog/keystone/exclude/Store";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {ExcludeStore} from "../../../../../cog/keystone/exclude";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {ColumnMappingDictionaryWithData} from "./ColumnMappingDictionaryWithData";
import {values} from "lodash";
import {Member} from "../../../../../cog/keystone/member";
import ConfirmActionModal from "../ConfirmActionModal";
import Button from "@mui/material/Button";

interface ExcludeManagerLogsComponentProps {
    excludeLogRows: any[]
    isLoading: boolean
    currentExcludesColumns: GridColDef[]
}

/** todo
 * Weve made some progress, we can get the new filter format to pretty print in the log table
 * excluded table is still disabled
 * still gotta do the removeOne at some point
 * Gotta move lots of the stuff in here back to the container for this, right now it's a bit in parts
 * Gotta check for correct values
 * Probably gotta add a scroll bar or something to the filter cell, shes big
 * Other stuff too, good luck
 * */

/** todo
 * We've made even more progress, the Exclude manager seems to be working as it should
 * Still should probably check for duplicates when inserting a new exclude filter, but like in the backend, maybe?
 * Maybe improve the UI for the adding of exclude filters
 * */

const ExcludeManagerLogsComponent: FC<ExcludeManagerLogsComponentProps> = (props: ExcludeManagerLogsComponentProps) => {
    const mainctx = useMainContext()// Main Context
    const keystoneCtx = useKeystoneContext()
    const tempTheme = useTheme() // fetches current theme being used
    const [myTheme, setMyTheme] = useState(tempTheme)
    const [excludeLogRows, setExcludeLogRows] = useState<any[]>(props.excludeLogRows);
    const [isLoading, setIsLoading] = useState<boolean>(props.isLoading)

    const [currentExcludesColumns, setCurrentExcludesColumns] = useState<GridColDef[]>(props.currentExcludesColumns);


    return <Box>
        <Box sx={{width: "100%", height: "40vh"}}>
            {isLoading && <LinearProgress
                sx={{
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: 2,
                    justifySelf: "center"
                }}/>}
            {/*{colMapWithData != null && <Typography>{PrettyPrintColMapWithData(colMapWithData)}</Typography>}*/}
            {excludeLogRows != null && mainctx.members != null &&
                <StyledDataGrid
                    columns={currentExcludesColumns}
                    rows={excludeLogRows}
                    getRowHeight={() => 'auto'}
                    // getEstimatedRowHeight={() => 1000 / 100}
                    components={{
                        Toolbar: GridToolbar,
                        DetailPanelExpandIcon: ExpandMore,
                        DetailPanelCollapseIcon: ExpandLess,
                    }}
                    componentsProps={{
                        panel: {
                            sx: {
                                '& .MuiDataGrid-panelWrapper': {
                                    backgroundColor: myTheme.palette.background.default,
                                    margin: 0.2,
                                    padding: 0.2
                                },
                                // replaces scroll bar
                                '& .MuiDataGrid-panelContent': {
                                    overflowX: "hidden",
                                    paddingRight: 2,
                                    "&::-webkit-scrollbar": {
                                        width: 10
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        backgroundColor: myTheme.palette.background.paper,
                                        borderRadius: 1,
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: myTheme.palette.text.secondary,
                                        borderRadius: 2,
                                    }
                                }
                            }
                        }
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'date_actioned', sort: 'desc' }],
                        },
                    }}
                    disableDensitySelector={true}
                    pageSize={100}
                />
            }

        </Box>

    </Box>
}

// PrettyPrintColMapWithData : Prints the passed object for the table
const PrettyPrintColMapWithData: FC<ColumnMappingDictionaryWithData> = (PrintMe: ColumnMappingDictionaryWithData) => {
    return <Box sx={{
        whiteSpace: "pre-wrap",
        height: "10vh",
        overflowY: "scroll"
    }}>
        {PrintMe.PrettyPrint()}
    </Box>
}


export default ExcludeManagerLogsComponent;