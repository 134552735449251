import React, {FC, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {ConvertTreeFromBeingUsedToApiSave, TreeForUseByPSU} from "../utility/NodeTypesAndOptions";
import {PSUStore} from "../../../../../cog/keystone/productStoreUniverse";
import GenericDataGridComponent from "../../genericcomponents/datagrid/GenericDataGridComponent";
import GenericDataGridComponentLoader from "../../genericcomponents/datagrid/GenericDataGridComponentLoader";
import Button from "@mui/material/Button";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {PSUinsideShadows} from "./PSUSettingsComponent";

interface SalesViewDataPreviewComponentProps {
    tree: TreeForUseByPSU
    ClientName: string
    styling: any
}

/** SalesViewDataPreviewComponent :
 *
 * */
const SalesViewDataPreviewComponent: FC<SalesViewDataPreviewComponentProps> = (props) => {

    const keystoneCtx = useKeystoneContext()
    const [salesViewProductPreviewData, setSalesViewProductPreviewData] = useState<any[]>(null)
    const [salesViewCustomerPreviewData, setSalesViewCustomerPreviewData] = useState<any[]>(null)
    const [salesViewPreviewLoading, setSalesViewPreviewLoading] = useState<boolean>(false)
    const [productOrCustomer, setProductOrCustomer] = useState<string>("Product")

    async function FetchSalesViewPreview() {
        setSalesViewPreviewLoading(true)
        let response = await FetchSalesViewWithPSU()
        if (response.ProductSalesViewGenericObject != null) {
            setSalesViewProductPreviewData(response.ProductSalesViewGenericObject)
        }
        if (response.CustomerSalesViewGenericObject != null) {
            setSalesViewCustomerPreviewData(response.CustomerSalesViewGenericObject)
        }
        setSalesViewPreviewLoading(false)
    }

    async function FetchSalesViewWithPSU() {
        return await PSUStore.FetchSalesViewWithPSU({
            PSU: ConvertTreeFromBeingUsedToApiSave(props.tree),
            ClientName: props.ClientName,
            ProductCode: keystoneCtx.colMap.Product_Code,
            Product: keystoneCtx.colMap.Product,
            ProductHierarchy1: keystoneCtx.colMap.Product_Hierarchy_1,
            ProductHierarchy2: keystoneCtx.colMap.Product_Hierarchy_2,
            ProductHierarchy3: keystoneCtx.colMap.Product_Hierarchy_3,
            ProductHierarchy4: keystoneCtx.colMap.Product_Hierarchy_4,
            AreaHierarchy3Code: keystoneCtx.colMap.Area_Hierarchy_3_Code,
            AreaHierarchy3: keystoneCtx.colMap.Area_Hierarchy_3,
            CustomerHierarchy1: keystoneCtx.colMap.Customer_Hierarchy_1,
            CustomerHierarchy2: keystoneCtx.colMap.Customer_Hierarchy_2,
            CustomerHierarchy3: keystoneCtx.colMap.Customer_Hierarchy_3
        })
    }

    useEffect(() => {
        if (props.tree != null && props.ClientName != null && salesViewPreviewLoading != true){
            if (salesViewProductPreviewData == null) {
                Promise.resolve().then(FetchSalesViewPreview)
            }
        }
    }, [props]);

    const inheritStyle = {
        height: "inherit",
        width: "inherit",
        minHeight: "inherit",
        minWidth: "inherit"
    }

    return <Box sx={{
    }}>
        <Box
            sx={{
                display: "flex",
                gap: 1,
            }}
        >
            <Button fullWidth variant={productOrCustomer == "Product" ? "contained" : "outlined"}
                    onClick={() => setProductOrCustomer("Product")}
                    sx={{
                        boxShadow: PSUinsideShadows,
                        borderRadius: 1
                    }}
            >
                Product
            </Button>
            <Button fullWidth variant={productOrCustomer == "Customer" ? "contained" : "outlined"}
                    onClick={() => setProductOrCustomer("Customer")}
                    sx={{
                        boxShadow: PSUinsideShadows,
                        borderRadius: 1
                    }}
            >
                Customer
            </Button>
        </Box>
        <Box sx={props.styling}>
            {productOrCustomer == "Product" && salesViewProductPreviewData != null && salesViewPreviewLoading == false ?
                <Box sx={props.styling}>
                    <GenericDataGridComponent
                        GenericData={salesViewProductPreviewData}
                        enableSorting={true}
                        enableMenu={true}
                        RowsPerPageOptions={[10,20,50, 100]}
                        pagination={true}
                    />
                </Box>
                : productOrCustomer == "Product" &&  salesViewPreviewLoading == true ? <Box
                        sx={props.styling}>
                        <GenericDataGridComponentLoader/>
                    </Box>
                    : productOrCustomer == "Product" && salesViewProductPreviewData == null &&
                    <Box>
                        <Typography variant={"h6"} >
                            No Data
                        </Typography>
                        <Button
                            variant={"outlined"}
                            onClick={() => FetchSalesViewPreview()}>
                            Refresh
                        </Button>
                    </Box>
            }
            { productOrCustomer == "Customer" && salesViewCustomerPreviewData != null && salesViewPreviewLoading == false ?
                <Box sx={props.styling}>
                    <GenericDataGridComponent
                        GenericData={salesViewCustomerPreviewData}
                        enableSorting={true}
                        enableMenu={true}
                        RowsPerPageOptions={[10,20,50, 100]}
                        pagination={true}
                    />
                </Box>
                : productOrCustomer == "Customer" && salesViewPreviewLoading == true ? <Box
                        sx={props.styling}>
                        <GenericDataGridComponentLoader/>
                    </Box>
                    : productOrCustomer == "Customer" && salesViewCustomerPreviewData == null &&
                    <Box>
                        <Typography variant={"h6"} >
                            No Data
                        </Typography>
                        <Button
                            variant={"outlined"}
                            onClick={() => FetchSalesViewPreview()}>
                            Refresh
                        </Button>
                    </Box>
            }
        </Box>
    </Box>
}

export default SalesViewDataPreviewComponent