import type {FC} from 'react';
import React from 'react';
import logoPath from "./KimberlyClarkLogoIcon2.png"

interface KimberlyClarkLogoProps extends LogoSwitcherProps{
    [key: string]: any;
}

interface LogoSwitcherProps {
    org?: string;
    icon?: boolean;
}

const KimberlyClarkLogo: FC<KimberlyClarkLogoProps> = (icon, props) => (
    <img
        alt="Kimberly Clark Logo"
        src={logoPath}
        height={props.icon? 100: 64}
        width={props.icon? 100: 64}
        style={{
            margin: 1
        }}
        {...props}
    />
);

export default KimberlyClarkLogo;