/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../../api/jsonrpc';
import Dashboard from "./../Dashboard";

export interface Embbeding {
    id?: string
    dashboardId: string
}
export interface CreateOneRequest {
    embedding: Embbeding
    email: string
    expire: string //datestring
    org_id: string
}

export interface CreateOneResponse {
    id: string
    key: string
}
export interface FindOneRequest {
    Key: string
}

export interface FindOneResponse {
    message?: any;
    code?: any
    ID: string
    DashboardId: string
    DashboardVariables: object
    dashboard?: Dashboard
}
export interface UpdateOneRequest {
    key: string
    secret: string
}
export interface UpdateOneResponse {
    key: string
    id: string
    dash_id: string
}


const Store = {
    serviceProvider: 'Dashboard-Embedding-Store',

    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                'key': request.Key
            }]
        });
        return response
    },
    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                'embedding': request.embedding,
                'email': request.email,
                'expire': request.expire,
                'org_id': request.org_id
            }]
        })
        return response
    },
    async UpdateOne(request: UpdateOneRequest): Promise<UpdateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Public,
            method: `${Store.serviceProvider}.UpdateOne`,
            request: [{
                "key": request.key,
                "secret": request.secret
            }]
        })
        return response
    },
    async FindOneAndDashboard(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Public,
            method: `${Store.serviceProvider}.FindOneAndDashboard`,
            request: [{
                'key': request.Key,
            }]
        });
        return response
    },

};

export default Store;
