import * as React from 'react';
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { useMainContext } from "../../../../../../contexts/MainContext";
import _ from "lodash";
import {useKeystoneContext} from "../../../../../../contexts/KeystoneContext";
import {useNavigate} from "react-router-dom";

const DistributionSwitchTable = ({ chartName, statRows}) => {
    const mainCtx = useMainContext();
    const keystoneCtx = useKeystoneContext();
    const navigate = useNavigate();
    let rows = [];

    const formatCol = (k: string) => {
        return {
            field: k,
            headerName: k,
            width: 150,
            flex: 1
        }
    };

    const cols = Object.keys(statRows).map((k) => {
        return formatCol(k)
    })

    if (Object.keys(statRows).length === 0) {
        rows = [{ id: 0, NoData: 'Query returned no results' }];
    } else {
        _.range(statRows[Object.keys(statRows)[0]].length).map((idx) => {
            let row: { [key: string]: any } = { id: idx + 1 };
            Object.keys(statRows).map((k) => {
                row[k] = statRows[k][idx];
            });
            rows.push(row);
        });
    }
    const updateFilter = (org: string, chartName: string, column: string, params: any) => {
        const formattedColumn = column.toString().replace(/_/g, ' ');
        const productHeader = keystoneCtx.colMap.Product;
        const categoryHeader = keystoneCtx.colMap.Product_Hierarchy_1;
        const areaHeader = keystoneCtx.colMap.Area_Hierarchy_1;
        const customerHeader = keystoneCtx.colMap.Customer_Hierarchy_1;
        const area3Header =  keystoneCtx.colMap.Area_Hierarchy_3;

        let filters = [];


        switch (chartName) {
            case 'DistributionOfTopSKUs':
                if (params && params.row && params.row[productHeader]) {

                    const oppTypeFilter = {
                    header: 'opportunity_type',
                    operation: '=',
                    prefix: 'AND',
                    values: "Distribution",
                    };

                    const productFilter = {
                        header: productHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[productHeader],
                    };

                    filters.push(oppTypeFilter, productFilter);
                }
                break;

            case 'RegionalProductDistribution':
                if (params && params.row && params.row[productHeader] && params.row[areaHeader]) {

                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: "Distribution",
                    };

                    const productFilter = {
                        header: productHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[productHeader],
                    };

                    const areaFilter = {
                        header: areaHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[areaHeader],
                    };

                    filters.push(oppTypeFilter, productFilter, areaFilter);
                }
                break;

            case 'RegionalCategoryDistribution':
                if (params && params.row && params.row[categoryHeader] && params.row[areaHeader]) {

                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: "Distribution",
                    };

                    const categoryFilter = {
                        header: categoryHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[categoryHeader],
                    };

                    const areaFilter = {
                        header: areaHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[areaHeader],
                    };

                    filters.push(oppTypeFilter, categoryFilter, areaFilter);
                }
                break;

            case 'DistributionPerCategory':
                if (params && params.row && params.row[customerHeader]) {
                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: "Distribution",
                    };

                    const area3Filter = {
                        header: area3Header,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[area3Header],
                    };

                    if (column && column !== area3Header) {
                        const categoryFilter = {
                            header: categoryHeader,
                            operation: '=',
                            prefix: 'AND',
                            values: column.toString().replace("Distribution_", ''),
                        };
                        filters.push(oppTypeFilter, area3Filter, categoryFilter);
                    } else {
                        filters.push(oppTypeFilter, area3Filter);
                    }
                }
                break;

            case 'MostDistributedProducts':
                if (params && params.row && params.row[productHeader]) {
                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: "Distribution",
                    };

                    const productFilter = {
                        header: productHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[productHeader],
                    };

                    if (column && column !== productHeader) {

                        const lastUnderscoreIndex = column.lastIndexOf('_');
                        const values = lastUnderscoreIndex !== -1 ? column.substring(lastUnderscoreIndex + 1) : column;

                        const columnFilter = {
                            header: customerHeader,
                            operation: '=',
                            prefix: 'AND',
                            values: values,
                        };

                        filters.push(oppTypeFilter, productFilter, columnFilter);
                    } else {
                        console.error("Clicked column is undefined, empty, or the product column.");
                        filters.push(oppTypeFilter, productFilter)
                    }
                }
                break;

            case 'LeastDistributedProducts':
                if (params && params.row && params.row[productHeader]) {
                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: "Distribution",
                    };

                    const productFilter = {
                        header: productHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[productHeader],
                    };

                    const clickedColumn = params.colDef.field;

                    if (clickedColumn) {

                        const lastUnderscoreIndex = clickedColumn.lastIndexOf('_');
                        const values = lastUnderscoreIndex !== -1 ? clickedColumn.substring(lastUnderscoreIndex + 1) : clickedColumn;

                        const columnFilter = {
                            header: customerHeader,
                            operation: '=',
                            prefix: 'AND',
                            values: values,
                        };

                        if (formattedColumn === 'Item_Name') {
                            filters.push(columnFilter);
                        } else {
                            filters.push(oppTypeFilter, productFilter, columnFilter);
                        }
                    } else {
                        console.error("Clicked column is undefined or empty.");
                    }
                }
                break;

            case 'Top10PercStores':
                if (params && params.row && params.row[area3Header]) {
                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: "Distribution",
                    };

                    const area3Filter = {
                        header: area3Header,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[area3Header],
                    };

                    filters.push(oppTypeFilter, area3Filter);
                }
                break;

            default:
                filters = [];
                break;
        }

        console.log('Filters Array:', filters);

        keystoneCtx.setClickThroughFilters(filters);
        keystoneCtx.RefreshCards();
        navigate("/app/" + org.toLowerCase() + "/opportunities");
    };

    const handleCellDoubleClick = (params: any) => {
        const cellValue = params.value;
        const org = mainCtx.activeOrganization.name.replace(/ |_/g, '-');

        if (cellValue) {
            const column = params.colDef.field; // Get the field or column name that was clicked
            updateFilter(org, chartName, column, params);
        }
    };

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <DataGridPro
                columns={cols}
                rows={rows}
                pageSize={5}
                rowsPerPageOptions={[5]}
                pagination={true}
                onCellDoubleClick={handleCellDoubleClick}
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </Box>
    );
};

export default DistributionSwitchTable;