import React from 'react';
import type { FC } from 'react';
import logoPath from "../ksLogoWhite.svg"
import oldLogoPath from "../logo.svg"
import orgChecker from "../utils/orgChecker";
import { useMainContext } from "../contexts/MainContext";
import { Box } from "@mui/material";

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  const mainCtx = useMainContext()

  return (
    <Box>
      {mainCtx.activeOrganization && mainCtx.activeOrganization.name &&
        <img
          alt="Logo"
          src={orgChecker(mainCtx.activeOrganization.name) ? oldLogoPath : logoPath}
          width={orgChecker(mainCtx.activeOrganization.name) ? 60 : 180}
          style={{
            marginRight: orgChecker(mainCtx.activeOrganization.name) ? 0 : 29,
            marginLeft: orgChecker(mainCtx.activeOrganization.name) ? 0 : 29,
            marginTop: 1
          }}
          {...props}
        />
      }
    </Box>
  );
};

export default Logo;
