import type {FC} from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import { Box, styled, Typography } from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {ApexOptions} from "apexcharts";
import currencySwitcher from "../../../../../../utils/currencySwitcher";
import { useMainContext } from "../../../../../../contexts/MainContext";
import { useKeystoneContext } from "../../../../../../contexts/KeystoneContext";
import { useNavigate } from "react-router-dom";
import { FilterItem } from "../../../../../../cog/keystone/filter/Filter";
import { Filter as FilterObject, FilterStore } from "../../../../../../cog/keystone/filter";

const StyledBox = styled('div')(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    minHeight: 500,
    minWidth: 500
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 400,
    minWidth: 300
  }
}));

interface LandingPieProps {
  series: number[];
  labels: string[];
  org: string;
}

const LandingPie: FC<LandingPieProps> = ({series, labels, org}) => {
  const chartType = "pie"
  let formatter = Intl.NumberFormat('en', {notation: 'compact'})
  const mainCtx = useMainContext()
  const keystoneCtx = useKeystoneContext()
  const navigate = useNavigate()

  const updateFilter = async () => {
    keystoneCtx.RefreshCards()
    const org = mainCtx.activeOrganization.name.replace(/ |_/g,'-');
    navigate("/app/" + org.toLowerCase() + "/opportunities")
  }

  const chartOptions: ApexOptions = {
    chart: {
      type: "line",
      foreColor: useTheme().palette.text.primary,
      width: 500,
      height: 500,
      events: {
        dataPointSelection(event, chartContext, config) {
          const formatted = labels[config.dataPointIndex].replaceAll("_", " ")

          const oppTypeFilter: FilterItem = {
            header: "opportunity_type",
            operation: "=",
            prefix: "AND",
            values: formatted
          }

          const filters: FilterItem[] = []
          filters.push(oppTypeFilter)

          const filteredFilter = filters.filter((f) => !f.level)
          const filteredWithDefault = filteredFilter.concat(keystoneCtx.filters)

          keystoneCtx.setClickThroughFilters(filteredWithDefault)
          // keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
          updateFilter()
        }
      }
    },
    labels: labels,
    dataLabels: {
      formatter: function (val, opt) {
        return currencySwitcher(org) + formatter.format(series[opt.seriesIndex])
      },
    },
    colors: ["#4b4b4b", "#f2bc53", "#f45c5c", "#e1d5c6", "#8c9494"],
    tooltip: {
      y: {
        formatter: function (val) {
          return currencySwitcher(org) + formatter.format(val)
        }
      }
    },
    legend: {
      // offsetY: 200,
      // offsetX: 50,
      position: 'right'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          // show: false
        },
      }
    }]
  };

  if (series != null) {
    return (
      <StyledBox>
        <Chart
          type={chartType}
          series={series}
          options={chartOptions}
          height={"100%"}
          width={"100%"}
        />
      </StyledBox>
    );
  } else {
    return (
      <StyledBox>
        <Typography>
          No Data To Display
        </Typography>
      </StyledBox>
    );
  }

};

export default LandingPie;