export const DefaultLayout = {
    "lg": [
        {
            "w": 2,
            "h": 1,
            "x": 10,
            "y": 0,
            "i": "0",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 2,
            "x": 0,
            "y": 0,
            "i": "1",
            "moved": false,
            "static": false
        },
        {
            "w": 7,
            "h": 3,
            "x": 0,
            "y": 4,
            "i": "2",
            "moved": false,
            "static": false
        },
        {
            "w": 1,
            "h": 2,
            "x": 3,
            "y": 0,
            "i": "3",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "h": 31,
            "x": 0,
            "y": 10,
            "i": "4",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "h": 31,
            "x": 6,
            "y": 10,
            "i": "5",
            "moved": false,
            "static": false
        },
        {
            "w": 1,
            "h": 3,
            "x": 7,
            "y": 4,
            "i": "6",
            "moved": false,
            "static": false
        },
        {
            "w": 12,
            "h": 14,
            "x": 0,
            "y": 41,
            "i": "7",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 3,
            "x": 4,
            "y": 0,
            "i": "8",
            "moved": false,
            "static": false
        }
    ]
}