import type {FC} from 'react';
import React from 'react';
import logoPath from "./Rainbow-Chickens-Logo-Icon.png"

interface RBLogoProps {
    [key: string]: any;
}

const RBLogo: FC<RBLogoProps> = (props) => (
    <img
        alt="RCL Logo"
        src={logoPath}
        height={66}
        width={66}
        style={{
            margin: 1
        }}
        {...props}
    />
);

export default RBLogo;