import type {FC} from 'react';
import * as React from "react";
import {useLayoutEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import {GoalTargetStore} from "../../../../../../cog/keystone/goals";
import {useTheme} from "@mui/system";
import Chart from "react-apexcharts";
import currencySwitcher from "../../../../../../utils/currencySwitcher";
import Goal from "../../../../../../cog/keystone/goals/goal/Goal";
import {useMainContext} from "../../../../../../contexts/MainContext";
import {SVDetails} from "../../../../../../cog/keystone/goals/goal/goalTarget/SVTarget";

interface SVChartLoaderProps {
    org?: string;
    labelValue?: string;
    goal?: Goal;
    hasDataHandler?: () => void
}

const SVChartLoader: FC<SVChartLoaderProps> = ({org, labelValue, goal, hasDataHandler}) => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(true)

    const [options, setOptions] = useState<{}>()
    const [series, setSeries] = useState<any>()

    const ctx = useMainContext()

    let formatter = Intl.NumberFormat('en', {notation: 'compact'})
    const createOptions = (columns: SVDetails) => {
        return {
            chart: {
                type: "line",
                background: theme.palette.background.paper,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                }
            },
            xaxis: {
                type: 'category',
                tickAmount: 'dataPoints',
                labels: {
                    rotateAlways: true,
                    rotate: -60,
                    trim: true,
                    formatter: (value) => {
                        return value
                    }
                },
                tickPlacement: 'on',
                categories: columns,
            },

            theme: {
                mode: theme.palette.mode
            },
            stroke: {
                curve: 'smooth',
                colors: ["#008ffa", "#00e396", "#feb019", "#ff6178", "#008A61", "#775dd0"]
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                position: 'bottom',
            },
            grid: {
                padding: {
                    bottom: 20,
                    left: 80,
                }
            },
            colors: ["#008ffa", "#00e396", "#feb019", "#ff6178", "#008A61", "#775dd0"],
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return currencySwitcher(org) + formatter.format(val)
                    }
                }
            },
        }

    }

    const getGoalDetails = async () => {
        let yaxises = []
        const response = await GoalTargetStore.CalculateSVDetails({
            clientName: ctx.activeOrganization.name,
            criteriaFilters: JSON.parse(goal.criteria),
            dueDate: goal.dueDate,
            goalID: goal.id,
            startDate: goal.startDate
        })
        if (response) {
            let x = Object.keys(response).filter((k) => k != labelValue)
            let y = Object.keys(response).filter((k) => k == labelValue)
            const seriesTest = x ? x.map((c) => {
                yaxises.push({
                    opposite: yaxises.length % 2 !== 0,
                    title: {
                        text: c,
                    },
                    labels: {
                        formatter: function (val) {
                            return currencySwitcher(org) + formatter.format(val)
                        }
                    },
                })
                return {
                    name: c,
                    data: response && response[c] ? response[c] : []
                }
            }) : [{name: "y", data: []}]
            seriesTest.unshift(seriesTest.pop())
            setSeries(seriesTest)
            setOptions(createOptions(response[labelValue]))
        } else {
            hasDataHandler()
            setSeries([])
            setOptions({
                chart: {
                    type: "line",
                }
            })
        }

    }

    useLayoutEffect(() => {
        setIsLoading(true)
        getGoalDetails().then(() => {
            setIsLoading(false)
            setIsOptionsLoading(false)
        })
    }, [theme.palette.mode])

    return (
        <Box sx={{minHeight: 500, minWidth: '300px', justifyContent: 'center'}}>
            {isLoading ? (
                <Box sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <CircularProgress/></Box>
            ) : isOptionsLoading ? (
                <Box sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <CircularProgress/>
                </Box>
            ) : (
                <Chart
                    type={"line"}
                    options={options}
                    height={"90%"}
                    width={"100%"}
                    series={series}
                    sx={{justifyContent: 'center', display: 'flex'}}
                />
            )
            }
        </Box>
    );
}

export default SVChartLoader;