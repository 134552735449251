import type {FC} from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import { Box, styled, Typography } from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {ApexOptions} from "apexcharts";
import currencySwitcher from "../../../../../../utils/currencySwitcher";
import { useMainContext } from "../../../../../../contexts/MainContext";
import { useKeystoneContext } from "../../../../../../contexts/KeystoneContext";
import { useNavigate } from "react-router-dom";
import { FilterItem } from "../../../../../../cog/keystone/filter/Filter";
import { Filter as FilterObject, FilterStore } from "../../../../../../cog/keystone/filter";
import { useContext } from "react";
import SettingsContext from "../../../../../../contexts/SettingsContext";

const StyledBox = styled('div')(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    minHeight: 500,
    minWidth: 500
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 400,
    minWidth: 300
  }
}));

interface dataPoint {
  x: string;
  y: number;
}

interface LandingTreeProps {
  data: {};
  org: string;
  productHeader: string;
}

const LandingTree: FC<LandingTreeProps> = ({data, org, productHeader}) => {
  const chartType = "treemap"
  const mainCtx = useMainContext()
  const keystoneCtx = useKeystoneContext()
  const settingsCtx = useContext(SettingsContext)
  const navigate = useNavigate()

  const updateFilter = async () => {
    keystoneCtx.RefreshCards()
    const org = mainCtx.activeOrganization.name.replace(/ |_/g,'-');
    navigate("/app/" + org.toLowerCase() + "/opportunities")
  }

  const apexData: dataPoint[] = []

  if (Object.values(data).length > 0) {
    for (let i = 0; i < data[productHeader].length; i++) {
      let tempDataPoint: dataPoint = {
        x: data[productHeader][i] === null ? "" : data[productHeader][i],
        y: data["Distribution"][i] === null ? 0 : data["Distribution"][i]
      }
      apexData.push(tempDataPoint)
    }
  }

  const seriesData = {
    data: apexData
  }

  const series = []
  series.push(seriesData)

  const chartOptions: ApexOptions = {
    chart: {
      type: "treemap",
      background: useTheme().palette.background.paper,
      foreColor: useTheme().palette.text.primary,
      width: 500,
      height: 500,
      events: {
        dataPointSelection(event, chartContext, config) {
          const OppTypeFilter: FilterItem = {
            header: "opportunity_type",
            operation: "=",
            prefix: "AND",
            values: "Distribution"
          }

          const productFilter: FilterItem = {
            header: keystoneCtx.colMap.Product,
            operation: "=",
            prefix: "AND",
            values: data[productHeader][config.dataPointIndex]
          }

          const filters: FilterItem[] = []
          filters.push(OppTypeFilter, productFilter)

          const filteredFilter = filters.filter((f) => !f.level)
          const filteredWithDefault = filteredFilter.concat(keystoneCtx.filters)

          keystoneCtx.setClickThroughFilters(filteredWithDefault)
          // keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
          updateFilter()
        }
      }
    },
    colors: [useTheme().palette.mode === 'dark' ? '#1aa6c3' : '#3aa9a6'],
    grid: {
      row: {
        colors: [useTheme().palette.background.paper]
      },
      column: {
        colors: [useTheme().palette.background.paper]
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%"
        }
      },
      theme: settingsCtx.settings.theme === 'LIGHT' ? 'light' : 'dark'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          // show: false
        },
      }
    }]
  };

  if (Object.keys(data).length > 0) {
    return (
      <StyledBox>
        <Chart
          type={chartType}
          series={series}
          options={chartOptions}
          height={"100%"}
          width={"100%"}
        />
      </StyledBox>
    );
  } else {
    return (
      <StyledBox>
        <Typography>
          No Data To Display
        </Typography>
      </StyledBox>
    );
  }

};

export default LandingTree;