/* eslint-disable */

import {API, PerformJSONRPCRequest} from "../../api/jsonrpc";
import Filter from "../filter/Filter";

export interface FindOneRequest {
  userID: string;
  organizationID: string;
}

export interface FindOneResponse {
  defaultFilter: Filter;
}

export interface UpsertOneRequest {
  defaultFilter: Filter;
}

export interface UpsertOneResponse {
  id: string;
}

export interface DeleteOneRequest {
  id: string;
}

export interface DeleteOneResponse {}

export interface FindSuppliersRequest {
  clientName: string;
}

export interface FindSuppliersResponse {
  findSuppliersList: Record<string, string[]>;
}

const Store = {
  serviceProvider: "Default-Filter-Store",

  async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.FindOne`,
      request: [
        {
          "userID": request.userID,
          "organizationID": request.organizationID,
        },
      ],
    });
    return { defaultFilter: response.defaultFilter };
  },
  async UpsertOne(request: UpsertOneRequest): Promise<UpsertOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.UpsertOne`,
      request: [
        {
          "defaultFilter": request.defaultFilter,
        },
      ],
    });
    return {
      "id": response.id,
    };
  },
  async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.DeleteOne`,
      request: [
        {
          "id": request.id,
        },
      ],
    });
    return {};
  },
  async FindSuppliers(
    request: FindSuppliersRequest
  ): Promise<FindSuppliersResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.FindSuppliers`,
      request: [
        {
          "client": request.clientName,
        },
      ],
    });
    return {
      findSuppliersList: response.findSuppliersList,
    };
  },
};

export default Store;
