import type {FC} from 'react';
import * as React from "react";
import {useContext} from "react";
import Chart from 'react-apexcharts';
import {Box, useTheme} from "@mui/material";
import {ApexOptions} from "apexcharts";
import SettingsContext from "../../../../contexts/SettingsContext";

interface dataset {
    name: string
    data: any[]
}
interface StackedBarProps {
    categories: any[]
    data: dataset[]
}
const StackedBar: FC<StackedBarProps> = (props) => {
    const chartType = "bar";
    const settingsCtx = useContext(SettingsContext)
    
    const chartOptions: ApexOptions = {
        chart: {
            type: "bar", stacked: true, stackType: '100%', events: {},
            background: useTheme().palette.background.paper,
            foreColor: useTheme().palette.text.primary,
        }, plotOptions: {
            bar: {
                horizontal: true,
            },
        }, stroke: {
            width: 1, colors: ['#fff']
        }, dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                var seriesIndex = opts.seriesIndex;
                var dataPointIndex = opts.dataPointIndex;
                var seriesData = opts.w.config.series[seriesIndex].data;
                
                // Calculate the sum of all data points in the stack
                var sum = seriesData.reduce((acc, dataPoint) => acc + dataPoint, 0);
                // Get the actual value of the data point
                var actualValue = seriesData[dataPointIndex];
                
                // Format the data label to display the actual value
                return typeof val === "number" && actualValue > 0 ? actualValue.toFixed(0) + ' (' + val?.toFixed(2) + '%)': val.toString()
            },
        }, colors: [ "#3aa9a6", "#f45c5c","#f2bc53", "#e1d5c6", "#4b4b4b", "#8c9494"],
        xaxis: {
            categories: props.categories ? props.categories : [],
            title: {
                text: "Percentage of Total Sales (%)"
            },
        }, tooltip: {
            y: {
                formatter: function (val) {
                    return val.toString()
                },},
            theme: settingsCtx.settings.theme === 'LIGHT' ? 'light' : 'dark'
        }, fill: {
            opacity: 1
            
        }, legend: {
            position: 'top', horizontalAlign: 'left', offsetX: 40
        }
    };
    
    return (<Box sx={{minHeight: 500, minWidth: 300}}>
        <Chart
            type={chartType}
            options={chartOptions}
            height={"90%"}
            width={"90%"}
            series={props.data ? props.data : []}
        />
    </Box>);
}

export default StackedBar;