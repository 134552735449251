/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import EFLog from "./EFLog";

export interface FindOneRequest {
    orgName:   string
}

export interface FindOneResponse {
    exclude: {organisation: string, "filter_dict": string }
}

export interface UpdateOneRequest {
    orgName:   string
    valueChange: string
    userID: string
}

export interface UpdateOneResponse {
    excludeFilter: string
}

export interface UpdateMultipleRequest {
    orgName:   string
    valueChange: string[]
    userID: string
}

export interface UpdateMultipleResponse {
    excludeFilter: string
}

export interface RemoveOneRequest {
    orgName:   string
    valueChange: string
    header: string
    userID: string
}

export interface RemoveOneResponse {
    excludeFilter: string
}

export interface FindLogsRequest {
    orgName:   string
}

export interface FindLogsResponse {
    exclude_logs: EFLog[]
}

export interface AddToExcludeRequest {
    client: string
    filter: string
    userID: string
}

export interface AddToExcludeResponse {
    testResponse: string
}

const Store = {
    serviceProvider: 'Keystone-ExcludeFilter-Store',
    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                "organisation": request.orgName
            }]
        });
        return response
    },

    async UpdateOne(request: UpdateOneRequest): Promise<UpdateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateOne`,
            request: [{
                "organisation": request.orgName,
                "addToIgnore": request.valueChange,
                "userID": request.userID
            }]
        });
        return response
    },

    async UpdateMultiple(request: UpdateMultipleRequest): Promise<UpdateMultipleResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.InsertMultiple`,
            request: [{
                "organisation": request.orgName,
                "addToIgnore": request.valueChange,
                "userID": request.userID
            }]
        });
        return response
    },

    async AddToExclude(request: AddToExcludeRequest): Promise<AddToExcludeResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.AddToExclude`,
            request: [{
                "client": request.client,
                "filter": request.filter,
                "userID": request.userID
            }]
        });
        // console.log("Add To Exclude Response: ", response)
        return response
    },

    async RemoveOne(request: RemoveOneRequest): Promise<RemoveOneResponse> {
        // console.log(request)
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.RemoveOne`,
            request: [{
                "organisation": request.orgName,
                "removeFromIgnore": request.valueChange,
                "header": request.header,
                "userID": request.userID
            }]
        });
        return response
    },

    async FindLogs(request: FindLogsRequest): Promise<FindLogsResponse> {
        const response: FindLogsResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FetchExcludeLogs`,
            request: [{
                "org_name": request.orgName
            }]
        });
        return response
    },
};

export default Store;