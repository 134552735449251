import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import type {Direction, Theme} from '@mui/material';
import {THEMES} from '../constants';
import {darkShadows, lightShadows} from './shadows';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const baseOptions = createTheme({
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        contained: {
          '&:hover': {
            backgroundColor: '#252525',
          },
        },
        outlined: {
          '&:hover': {
            borderColor: '#252525',
            backgroundColor: '#252525',
            color: '#FFFFFF',
            fontWeight: 'bold !important',
          },
        },
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        },
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } },
    },
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segue UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segue UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '2.5rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.12rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1rem'
    },
    overline: {
      fontWeight: 600
    },
    body1: {
      fontWeight: 300,
      fontSize: '0.95rem'
    },
    body2: {
      fontWeight: 300,
      fontSize: '0.8rem'
    },
  }
});

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiAutocomplete-popupIndicator': {
              color: '#4b4b4b',  
            },
            '& .MuiAutocomplete-clearIndicator': {
              color: '#4b4b4b',  
            },
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-sortIcon': {
              color: '#4b4b4b',
            },
            '& .MuiDataGrid-menuIconButton': {
              color: '#4b4b4b',
            },
          }
        }
      }      
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#f4f5f7',
        paper: '#ffffff'
      },
      divider: 'rgba(145, 158, 171, 0.5)',
      error: {
        contrastText: '#ffffff',
        main: '#f45c5c'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#4b4b4b'
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#e1d5c6'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#4b4b4b',
        secondary: '#4b4b4b'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#f2bc53'
      },
      info: {
        contrastText: '#ffffff',
        main: '#8c9494'
      },
    },
    shadows: lightShadows
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
            color: '#ffffff'
          }
        }
      }, 
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiAutocomplete-popupIndicator': {
              color: '#f2bc53',  
            },
            '& .MuiAutocomplete-clearIndicator': {
              color: '#f2bc53',  
            },
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: "#242d41",
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-sortIcon': {
              color: '#f2bc53',
            },
            '& .MuiDataGrid-menuIconButton': {
              color: '#f2bc53',
            },
          }
        }
      } 
    },
    palette: {
      background: {
        default: '#242d41',
        paper: '#2f3b52'
      },
      divider: 'rgba(145, 158, 171, 0.3)',
      error: {
        contrastText: '#ffffff',
        main: '#f45c5c'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#f2bc53'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50' //main: '#168f1d'
      },
      text: {
        primary: '#ffffff',
        secondary: '#ffffff', // secondary: '#919eab',
        info: '#ffffff'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#f2bc53'
      },
      info: {
        main: '#8c9494', //main: '#0287cf',
        contrastText: '#ffffff'
      }
    },
    shadows: darkShadows
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      },
      info: {
        contrastText: '#ffffff',
        main: '#0287cf'
      }
    },
    shadows: darkShadows
  },
  [THEMES.OLDCOGNIZANCE]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#f4f5f7',
        paper: '#ffffff'
      },
      divider: 'rgba(145, 158, 171, 0.5)',
      error: {
        contrastText: '#ffffff',
        main: '#fb5e56'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#18456b'
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#588aaf'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      },
      info: {
        contrastText: '#ffffff',
        main: '#0287cf'
      },
    },
    shadows: lightShadows
  },
};

export const createCogTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme];
  // let themeOptions = themesOptions[THEMES.LIGHT];
  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }
  // const [cornerShape, setCornerShape] = useState<number>(4)
  // // let tempShape = 4
  // if (config.roundedCorners == false) {
  //   setCornerShape(0)
  // }
  // console.log("config",config)
  // console.log("cornerShape",cornerShape)

  let theme = createTheme(
      {},
      baseOptions,
      themeOptions,
      {
        ...(
            {
              shape: {
                borderRadius: config.roundedCorners == true ? 16 : 0
              }
            }
        )
      },
      {
        direction: config.direction
      }
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
};
