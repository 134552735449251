declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

 const exportAllCsv = (data: any) => {
    //console.log(data)
    Object.keys(data).map((k) => {
        if (data[k] && data[k].data) {
            const fn = data[k].name.replace(/ /g,"_") + ".csv"
            // let rows = []
            // rows.push(Object.keys(data[k].data))
            let tempRows = []
            Object.keys(data[k].data).map((dk, index) => {
                tempRows.push(data[k].data[dk])
            })
            tempRows = tempRows[0].map((_, colIndex) => tempRows.map(row => row[colIndex]));
            tempRows.unshift(Object.keys(data[k].data))
            exportToCsv(fn, tempRows)
        }
    })
}

 function exportToCsv(filename, rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

 export default exportAllCsv