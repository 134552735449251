import {FilterItem} from "../../filter/Filter";
import {API, PerformJSONRPCRequest} from "../../../api/jsonrpc";
import {StatsData} from "../../card/Card";
import {TreeFromAPI} from "../../../../pages/app/keystone/productstoreuniverse/utility/NodeTypesAndOptions";

export interface StoreStatsRequest {
    clientName: string
    dateSelectionStart: string
    dateSelectionEnd: string
    defaultFilters: FilterItem[]
    filters: FilterItem[]
    limit: string
    PSU: TreeFromAPI
}

export interface StoreStatsResponse {
    storeStats: Record<string, StatsData>
}

const Store = {
    serviceProvider: 'Explore-Stores-Store',
    async StoreStats(request: StoreStatsRequest): Promise<StoreStatsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.StoreStats`,
            request: [{
                "client": request.clientName,
                "dateSelectionStart": request.dateSelectionStart,
                "dateSelectionEnd": request.dateSelectionEnd,
                "defaultFilters": request.defaultFilters,
                "filters": request.filters,
                "limit" : request.limit,
                "PSU": request.PSU
            }]
        })
        return response.storeStats
    },
};

export default Store