import Goal from "../goal/Goal";

export interface CreateCampaign {
    name: string;
    type: string;
    description: string;
    priority: number;
    dueDate: string;
    owner: string;
    target: number;
}

export interface UpdateCampaign {
    id: string;
    name: string;
    type: string;
    description: string;
    priority: number;
    startDate: string;
    dueDate: string;
    owner: string;
    target: number;
}

export default class Campaign {
    public id?: string = '';
    public name: string = '';
    public type: string = '';
    public owner: string = '';
    public description: string = '';
    public priority: number = 0;
    public startDate: string = '';
    public dueDate: string = '';
    public target: number = 0;
    public goalDetails: Goal[] = [];

    constructor(TempName?: Campaign) {
        if (!TempName) {
            return;
        }
        this.id = TempName.id;
        this.name = TempName.name;
        this.type = TempName.type;
        this.owner = TempName.owner;
        this.description = TempName.description;
        this.priority = TempName.priority;
        this.startDate = TempName.startDate;
        this.dueDate = TempName.dueDate;
        this.target = TempName.target;
        this.goalDetails = TempName.goalDetails;
    }
}
