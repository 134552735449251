/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import {FC, useEffect, useState} from "react";
import {Box, Paper, Typography, Grid} from "@mui/material";
import BasicWidget from "../BasicWidget";
import {WidgetProps} from "../../../WidgetSwitcher";
import {isArray} from "lodash";


const LabelInst: FC<WidgetProps> = ({widget, widgetData}) => {
    let labelVariable = widget.arguments && widget.arguments.templates.labelVariable &&
        widget.arguments.templates.labelVariable
    const code = widget.arguments.code ? unescape(widget.arguments.code) : null
    let labelText = widget.arguments && widget.arguments.templates.labelText &&
        widget.arguments.templates.labelText.value
    let labelAlign = widget.arguments && widget.arguments.templates.labelAlign &&
        widget.arguments.templates.labelAlign.value
    let labelFont = widget.arguments && widget.arguments.templates.labelFont &&
        widget.arguments.templates.labelFont.value
    let labelColour = widget.arguments && widget.arguments.templates.labelColour &&
        widget.arguments.templates.labelColour.value
    let labelSize = widget.arguments && widget.arguments.templates.labelSize &&
        widget.arguments.templates.labelSize.value
    let labelVariant = widget.arguments && widget.arguments.templates.labelVariant &&
        widget.arguments.templates.labelVariant.value

    try {
        eval(code);
    } catch (e) {
        console.log(e)
    }
    return (
        <Box >
        <Typography variant = {(labelVariant) ? labelVariant : "h3"} align={(labelAlign) ? labelAlign : "center"} sx={{fontSize: (labelSize) ? labelSize : "inherit", fontFamily: labelFont, color: (labelColour) ? labelColour : (theme) => theme.palette.text.primary}}>{ labelText}</Typography>
        </Box>
    );
}

const BasicLabel: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<LabelInst />}/>
}

export default BasicLabel
