/* eslint-disable */

export default class Argument {
    public id: string = '';
    public name: string = '';
    public queryId: string = '';
    public argument: string = '';

    constructor(argument?: Argument) {
        if (!argument) {
            return;
        }
        this.id = argument.id;
        this.name = argument.name;
        this.queryId = argument.queryId;
        this.argument = argument.argument;
    }
}
