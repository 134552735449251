import React, { FC } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useKeystoneContext } from "../../../../contexts/KeystoneContext";
import { GoalCriteria } from "../../../../cog/keystone/goals/goal/goalTarget/SVTarget";
import { forEach } from "lodash";

interface AddCriteriaDialogProps {
  connectCriteriaOpen: boolean;
  handleConnectCriteriaClose: () => void;
  criteria: GoalCriteria[];
  setCriteria: (c: GoalCriteria[]) => void;
}

const AddCriteriaDialog: FC<AddCriteriaDialogProps> = (props) => {
  const keystoneCtx = useKeystoneContext()

  const handleChange = (header: string, values: string[]) => {
    const newCriterion: GoalCriteria = {
      header: header,
      values: values,
    };

    const savedCriteria = props.criteria.slice()

    const index = savedCriteria.findIndex(obj => obj.header === header);

    if (index !== -1) {
      savedCriteria.splice(index, 1, newCriterion);
      props.setCriteria(savedCriteria)
    } else {
      savedCriteria.push(newCriterion)
      props.setCriteria(savedCriteria)
    }
  }

  return (
    <Dialog
        //disableEnforceFocus required to open chat if Dialog opened first
        disableEnforceFocus
      onClose={() => {
        props.handleConnectCriteriaClose()
      }}
      open={props.connectCriteriaOpen}
      maxWidth={"xl"}
    >
      <DialogTitle>
        <Box sx={{
          // fontSize: "small",
          display: 'flex',
          justifyContent: 'space-between',
          px: 1,
          alignItems: "center",
          // width: "100%",
          flexGrow: 10,
          // gap: "10px"
        }}>
          <span>Add Goal Criteria</span>
          <Button
            // type="submit"
            // fullWidth
            variant="contained"
            // sx={{mt: 3, mb: 2}}
            onClick={() => {
              props.handleConnectCriteriaClose()
            }}
          >
            Save and Close
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            {keystoneCtx.filtersReady == true
              && keystoneCtx.colMap != null
              && Object.keys(keystoneCtx.filterableFields).length > 0
              && Object.keys(keystoneCtx.filterableFields).map((key) => {
                const item = keystoneCtx.filterableFields[key]
                const values = []
                for (let i = 0; i < props.criteria.length; i++) {
                  if (props.criteria[i].header === item.header) {
                    props.criteria[i].values.map((value) => {
                      values.push(value)
                    })
                  }
                }

                if (item.type === "TEXT" && item.header !== "assigned_to" && item.name !== "level" && item.name !== "m_value") {
                  return (
                    <Grid item lg={6} xs={6} key={item.name + " Grid Item"}>
                      <Autocomplete
                        key={item.name + " Grid Item"}
                        disablePortal
                        multiple={true}
                        id={item.name + " Autocomplete"}
                        disableCloseOnSelect
                        options={item.data != null ? item.data : []}
                        onChange={(e, v) => handleChange(item.header, v)}
                        getOptionLabel={(option) => option}
                        value={values}
                        renderInput={(params) => <TextField {...params} label={item.name}/>}
                      />
                    </Grid>
                  );
                }
              })
            }
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddCriteriaDialog;