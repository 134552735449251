import type {FC} from 'react';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Alert, Box, Grid, Paper} from '@mui/material';
// import {useCustomThemeContext} from "../util/Context";
import {ApexOptions} from "apexcharts";
import Chart from "react-apexcharts";

interface HeatMapStatProps {
    series: object

}

const HeatMapStat: FC<HeatMapStatProps> = (props) => {
    const chartType = 'heatmap'

    const [series, setSeries] = useState<any[]>([])

    //TODO: set this up to map over the 6 weeks of data incomming, currently hard referencing for data display
    useLayoutEffect(() => {
        (props.series) ? setSeries(
            [{
                name: props.series['opportunity_type'][0],
                data: [{
                    x: props.series['Date_Resolved'][0],
                    y: props.series['Value'][0]
                }] },
                {
                    name: props.series['opportunity_type'][1],
                    data: [{
                        x: props.series['Date_Resolved'][1],
                        y: props.series['Value'][1]
                    }] }]
        ) : []
    }, [])



    const chartOptions: (ApexOptions) = {
        plotOptions: {
            heatmap: {
                colorScale: {
                    ranges: [{
                        from: 0,
                        to: 2000,
                        color: '#FF0000',
                        name: 'low',
                    },
                        {
                            from: 2001,
                            to: 10000,
                            color: '#FFB200',
                            name: 'medium',
                        },
                        {
                            from: 10000,
                            to: 100000,
                            color: '#00A100',
                            name: 'high',
                        }
                    ]
                }
            }
        }
    }

    return (
        <Box sx={{
                    color: '#F4F8F9',
                        width: '100%',
                        height: '90%',
                        minHeight: {md:'400px', xs: '300'},
                    // boxShadow: 3,
                    p: 2,
                        borderRadius: 1,
                }}>
            <Chart
                type={chartType}
                width={"100%"}
                height={"100%"}

                series={series}
                options={chartOptions}
                />
        </Box>

);
}

export default HeatMapStat;