import type {FC} from 'react';
import {useSnackbar} from 'notistack';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid, Skeleton,
  TextField
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Store } from "../../../../../cog/bitool/globalVariable"
import { useParams } from "react-router";
import { useMainContext } from "../../../../../contexts/MainContext";
import GlobalVariable from "../../../../../cog/bitool/globalVariable/GlobalVariable";

interface GlobalVariablesFormProps {
  handleDialogClose: () => void;
  organizationId: string;
}

const useStyles = makeStyles((theme:Theme) => ({
  formControl: {
    minWidth: 140,
    width: "100%"
  }
}));

const GlobalVariablesForm: FC<GlobalVariablesFormProps> = ({handleDialogClose, organizationId}) => {
  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();

  const [variables, setVariables] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchVariables = () => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => Store.FindAll({organizationId: organizationId}))
      .then((r) => setVariables(r.variables))
      .then(() => setLoading(false))
  }

  useEffect(() => {
    fetchVariables()
  }, [organizationId]);

  const handleSubmit = async (event) => {
    event.preventDefault()

    let newVariables : GlobalVariable[] = [];
    let globalVar : GlobalVariable

    for (let i = 0; i < variables.length; i++) {
      let name = event.target.elements["VariableName" + i].value
      let value = event.target.elements["VariableValue" + i].value

      globalVar = {
        id: variables[i].id,
        name: name,
        value: value,
        organizationId: organizationId
      }

      newVariables.push(globalVar)
    }

    const response = await Store.UpdateMany({variables: newVariables})
    if (response) {
      enqueueSnackbar("Global Variables Set", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "success",
      });
    } else {
      enqueueSnackbar("Failed to Set Global Variables!", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }

    handleDialogClose();
  };

  const handleAddVariable = useCallback(async () => {
    setLoading(true)
    await Store.CreateOne({name: "#NewVariable", value: "", organizationId: organizationId})
    let response = await Store.FindAll({organizationId: organizationId})
    if (response) {
      setVariables(response.variables)
    }
    setLoading(false)
  }, []);

  return (
    <Card sx={{ overflowY: "scroll"}}>
      {!loading ?
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Organization Global Variables" sx={{ textAlign: 'center' }} titleTypographyProps={{variant:'h3' }}/>
            <Divider />
            <CardContent>
              <FormControl variant="outlined" className={classes.formControl}>
                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="stretch">
                {variables != null && variables.map((variable, i) => {
                  return (
                    <Grid container item spacing={3} direction="row" justifyContent="space-evenly" alignItems="center" key={"inner container " + i}>
                      <Grid item md={6} xs={12} sx={{ width: "100%"}} key={"Grid Item VN " + i}>
                        <TextField key={"VN" + i} name={"VariableName" + i} label={variable.name} defaultValue={variable.name} fullWidth/>
                      </Grid>
                      <Grid item md={6} xs={12} sx={{ width: "100%"}} key={"Grid Item VV " + i}>
                        <TextField key={"VV" + i} name={"VariableValue" + i} label={variable.value} defaultValue={variable.value} fullWidth/>
                      </Grid>
                    </Grid>
                  );
                })}
                </Grid>
              </FormControl>
            </CardContent>
            <Divider />
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}>
              <ButtonGroup variant="contained">
                <Button onClick={handleAddVariable}>Add Global Variable</Button>
                <Button type="submit">Set Global Variables</Button>
                <Button onClick={handleDialogClose}>Cancel</Button>
              </ButtonGroup>
            </Box>
          </Card>
        </form> : <Card>
          <CardHeader title="Organization Global Variables" sx={{ textAlign: 'center' }} titleTypographyProps={{variant:'h3' }}/>
          <Divider />
          <CardContent>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 1}}/>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 1}}/>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 1}}/>
          </CardContent>
        </Card>
      }
    </Card>
  );
}

export default GlobalVariablesForm;