import React, {FC, useEffect, useState} from "react";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {Badge, Box} from "@mui/material";
import {useMainContext} from "../../../../contexts/MainContext";
import {Card as KanbanCardObj} from "../../../../cog/keystone/card";
import {values} from "lodash";
import {GetComparator, StableSort} from "../../../../utils/stableSort";
import {GridColDef} from "@mui/x-data-grid";
import {ListViewDefaultDisplayValues, ListViewIgnore} from "../listview/IgnoreColumnList";
import {BuildColumnObject} from "../listview/DataGridDetail";
import currencySwitcher from "../../../../utils/currencySwitcher";
import {GridToolbar} from "@mui/x-data-grid-pro";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {StyledDataGrid} from "../listview/CustomDataGridTheme";
import {useTheme} from "@mui/material/styles";

interface ConnectGoalOppProps {
    Opportunities: Record<string, KanbanCardObj>
    SelectedOpps: any[]
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    updateSelectedCards: (v: any) => void
}

const ConnectGoalOpportunity: FC<ConnectGoalOppProps> = (props) => {
    const keystoneCtx = useKeystoneContext()
    const mainCtx = useMainContext()
    const myTheme = useTheme()

    // Rows with extracted v0 and levelFilter fields, for use by the DataGrid
    const [myRows, setMyRows] = useState<any[]>(null);
    // This one is used to define the columns in the DataGrid
    const [myGridColumns, setMyGridColumns] = useState<GridColDef[]>(null);
    // Amount of records per page of the DataGrid, I think this needs to still exist despite not using pagination for the grid
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    });

    // MakeRows: Creates an array of cards with the V0 and levelFilter data extracted and added to the rows.
    // This is for the DataGrid
    const MakeRows = (cards: Record<string, KanbanCardObj>, excl: any) => {
        let empRows = []
        values(Object.values(cards)).map((
            value: KanbanCardObj, index: number, array: KanbanCardObj[]) => (
            (Object.keys(excl).length > 0) ? Object.keys(excl).map((key) => (
                    (excl[key].includes(JSON.parse(value.levelFilter)[key])) ? null : empRows.push({
                        id: value.id,
                        ...value,
                        ...JSON.parse(value.levelFilter),
                        ...JSON.parse(value.v0)
                    })
                )
            ) : empRows.push({
                id: value.id,
                ...value,
                ...JSON.parse(value.levelFilter),
                ...JSON.parse(value.v0)
            })))
        setMyRows(null) // done to reload data
        // setMyRows(tempRows) // no sorting
        setMyRows(StableSort(empRows.flat(1), GetComparator("desc", "mValue"))) // sorted by mValue
    }

    // makeColumns: Initializes the GridColDef array for the DataGrid to use for organizing the data
    const makeColumns = () => {
        let tempColumns = []
        let gridColumns: GridColDef[] = []


        for (let i = 0; keystoneCtx.levelMode ? i < (myRows).length : i < (myRows).length; i++) {
            for (const [key, value] of Object.entries((myRows)[i])) {
                if (value != null) {
                    // if (value != "" && value != "-") {
                    if (ListViewDefaultDisplayValues.indexOf(key) != -1) {
                        if (tempColumns.indexOf(key) == -1 && ListViewIgnore.indexOf(key) == -1) {
                            tempColumns.push(key)
                            if (key == "mValue") {
                                gridColumns.push(BuildColumnObject(key, value, mainCtx.members, [], currencySwitcher(mainCtx.activeOrganization.name), false, false, "right"))
                            } else if (key == "opportunityType" || key == "name" || key == "description" || key == "") {
                                gridColumns.push(BuildColumnObject(key, value, mainCtx.members, [], currencySwitcher(mainCtx.activeOrganization.name), false, false, "left"))
                            } else {
                                gridColumns.push(BuildColumnObject(key, value, mainCtx.members, [], currencySwitcher(mainCtx.activeOrganization.name), false, true, "left"))
                            }
                        }
                    } else if (tempColumns.indexOf(key) == -1 && ListViewIgnore.indexOf(key) == -1) {
                        tempColumns.push(key)
                        gridColumns.push(BuildColumnObject(key, value, mainCtx.members, [], currencySwitcher(mainCtx.activeOrganization.name), false, true))
                    }
                    // }
                }
            }
        }
        setMyGridColumns(gridColumns)
    }

    // When a filter exists, use it to fetch from api
    useEffect(() => {
        if (props.Opportunities != null) {
            MakeRows(props.Opportunities, [])
        }
    }, [props.Opportunities]);

    // When a filter exists, use it to fetch from api
    useEffect(() => {
        if (myRows != null && myRows.length > 0) {
            makeColumns()
        }
    }, [myRows]);

    return <>
        <Box sx={{height: "80vh", minWidth: "70vw"}}>
            {myRows != null && myGridColumns != null && <StyledDataGrid
                columns={myGridColumns}
                rows={myRows}
                getRowHeight={() => 'auto'}
                getEstimatedRowHeight={() => 1000 / pageSize}
                components={{
                    Toolbar: GridToolbar,
                    DetailPanelExpandIcon: ExpandMore,
                    DetailPanelCollapseIcon: ExpandLess,
                }}
                componentsProps={{
                    panel: {
                        sx: {
                            '& .MuiDataGrid-panelWrapper': {
                                backgroundColor: myTheme.palette.background.default,
                                margin: 0.2,
                                padding: 0.2
                            },
                            // replaces scroll bar
                            '& .MuiDataGrid-panelContent': {
                                overflowX: "hidden",
                                paddingRight: 2,
                                "&::-webkit-scrollbar": {
                                    width: 10
                                },
                                "&::-webkit-scrollbar-track": {
                                    backgroundColor: myTheme.palette.background.paper,
                                    borderRadius: 1,
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: myTheme.palette.text.secondary,
                                    borderRadius: 2,
                                }
                            }
                        }
                    }
                }}
                disableDensitySelector={true}
                checkboxSelection={true}
                selectionModel={props.SelectedOpps.map((v) => v)}
                onSelectionModelChange={(newSelectionModel) => {
                    let tempSelectedOpps: any[] = []
                    for (let i = 0; i < newSelectionModel.length; i++) {
                        tempSelectedOpps.push(newSelectionModel[i])
                    }
                    props.setFieldValue("opportunities", [...tempSelectedOpps] || "");
                }}
                // disableSelectionOnClick={!props.selectCards}
                // experimentalFeatures={{ rowGrouping: true }}
                // disableVirtualization={true}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                // rowCount={pageSize}
                // autoPageSize={true}
                pageSize={pageSize}
                pagination
            />}
        </Box>
    </>
}

export default ConnectGoalOpportunity;