import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';

export interface ExecuteOneRequest {
    queryId: string;
    connectorId: string;
    templates: any;
    controller?: AbortController;
    report?: boolean,
    embed?: boolean,
}

export interface ExecuteOneResponse {
    columns: string[];
    data: any;
    Errors: any;
}

const Executor = {
    serviceProvider: 'Executor',
    async ExecuteOne(request: ExecuteOneRequest): Promise<ExecuteOneResponse> {
        return await PerformJSONRPCRequest({
            api: request.report || request.embed ? API.Public : API.Authorised,
            method: `${Executor.serviceProvider}.ExecuteOne`,
            request: [{
                queryId: request.queryId,
                connectorId: request.connectorId,
                templates: request.templates
            }],
            controller: request.controller
        });
    }
};

export {
    Executor
}