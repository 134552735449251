import React, {FC, useCallback, useEffect, useMemo} from "react";
import Box from "@mui/material/Box";
import Widget, {WidgetGroupItem} from "../../../../../cog/bitool/widget/Widget";
import WidgetSwitcher from "../WidgetSwitcher";
import Button from "@mui/material/Button";
import AceCodeEditor from "../../../../../components/AceCodeEditor";
import { Autocomplete, CircularProgress, Grid, Paper, TextField, Typography } from "@mui/material";
import 'react-grid-layout/css/styles.css'
import {useDispatch, useSelector} from "react-redux";
import {
  initializedSelector,
  widgetDependencyIdsFunc,
  widgetDependencyVariablesSelector
} from "../../../../../slices/selectors";
import {ArgumentStore} from "../../../../../cog/bitool/query";
import Argument from "../../../../../cog/bitool/query/argument/Argument";
import WidgetFormDialog from "./WidgetFormDialog";
import WidgetTemplateDialog from "./WidgetTemplateDialog";
import { widgetChangeVariable } from "../../../../../slices/bitool";

interface WidgetEditorProps {
  widget: Widget;
  setWidget: (Widget, string) => void;
  allWidgets: Widget[]
  OnWidSave: any;
  queryID: string;
}

const WidgetEditor: FC<WidgetEditorProps> = ({widget, setWidget, OnWidSave, allWidgets, queryID}) => {
  let dispatch = useDispatch();
  const selectWidgetDependencyIdsFunc = useMemo(widgetDependencyIdsFunc, []);
  const selectWidgetDependencyOutputs = useMemo(widgetDependencyVariablesSelector, []);

  // TODO: CLEAN UP
  const initSelector = useSelector( (state) =>
    initializedSelector(state)
  ), widgets = useSelector((state) =>
    selectWidgetDependencyIdsFunc(state, widget.id)
  ), widgetDependencyOutputs = useSelector((state) =>
    selectWidgetDependencyOutputs(state, widget.id)
  )

  const [queryArguments, setQueryArguments] = React.useState<Argument[]>();
  const [widArg, setWidArg] = React.useState<string>(JSON.stringify(widget.arguments,  null, "\t"));
  const [widgetName, setWidgetName] = React.useState<string>(widget.name ? widget.name : "");
  const [code, setCode] = React.useState<string>(widget.arguments.code ? widget.arguments.code : "");

  const isGroupOrStepper = widget.arguments.type == "BasicWidgetGroupStepper" ||
    widget.arguments.type == "BasicWidgetGroupTabs" ||
    widget.arguments.type == "BasicWidgetGroup"

  const handleChangeTextField = (event) => {
    setWidgetName(event.target.value)
  }

  const handleAddWidgetToGroup = (event, val:Widget) => {
    if (val == undefined || null) {
      return;
    }
    let group: WidgetGroupItem = {id: val.id, label: val.name}
    setWidArg((prevState => (JSON.stringify({
      code: JSON.parse(prevState).code,
      type: JSON.parse(prevState).type,
      group: [...JSON.parse(prevState).group, group],
      templates: {...JSON.parse(prevState).templates},
      location: JSON.parse(prevState).location
    }, null, "\t"))))
  }

  const fetchQueryArgs = useCallback(async () => {
    if (widget.arguments.executorArgs && widget.arguments.executorArgs.queryId) {
      let response = await ArgumentStore.FindAll({
        queryId: widget.arguments.executorArgs.queryId
      })
      setQueryArguments(response.queryArguments)
    } else {
      setQueryArguments(null)
    }
  }, [widget])

  const setDepOutputs = useCallback(async () => {
    if (queryArguments && queryArguments.length > 0 && widget && widget.arguments.templates) {
      const arg = JSON.parse(queryArguments[0].argument)
      console.log("QueryArgs: ",arg)

      let outputDataDict: Record<string, Array<any>> = {}
      Object.keys(widget.arguments.templates).map((k) => {
        if (arg[k]) {
          let val = arg[k]
          if (widget.arguments.templates[k].serializerStringFunction == "$StringSerializer" ||
            widget.arguments.templates[k].serializerStringFunction == "$SqlStringSerializer" ) {
            val = val.replaceAll("'", "")
          }
          if (widget.arguments.templates[k].serializerStringFunction == "$DateSerializer") {
            val = val.replaceAll("'", "")
            val = new Date(val)
          }
          if (widget.arguments.templates[k].serializerStringFunction == "$StringArraySerializer") {
            val = val.replaceAll("'", "")
            val = val.replaceAll("(", "")
            val = val.replaceAll(")", "")
            val = val.split(",")
            val = val.map((v) => v.trim())
          }
          let tmp = outputDataDict[widget.arguments.templates[k].widgetId]
          if (tmp == null) tmp = []
          tmp.push({
            [widget.arguments.templates[k].keyOrIndex]: val
          })
          outputDataDict[widget.arguments.templates[k].widgetId] = tmp
        }
      })

      Object.keys(outputDataDict).map((id) => {
        dispatch(widgetChangeVariable(id, Object.assign({}, ...outputDataDict[id])))
      })
    }
  }, [queryArguments, widget])

  useEffect(() => {
    fetchQueryArgs()
  }, [fetchQueryArgs])

  useEffect(() => {
    setDepOutputs()
  }, [setDepOutputs])

  useEffect(() => {
  }, [widgetDependencyOutputs, initSelector])

  if (!widget) {
    return <Box />
  }

  if (!initSelector) {
    return (
      <Grid item xs={8}>
        <Box sx={{minHeight: "100"}}>
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  return (
    <Grid container spacing={2} direction="column" m={2}>
      <Grid item>
        <Typography variant="h5" component="div" gutterBottom>
          {widget.id}
        </Typography>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item>
          <div style={{height: "100%", width: "25vw"}}>
            <TextField
              style={{height: "100%", width: "100%"}}
              defaultValue ={widgetName}
              id="outlined-basic" label={"Widget Name"}
              key={widget.id}
              onChange={handleChangeTextField}
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid item sx={{marginTop: 1}}>
          <WidgetFormDialog label = "Build Widget" onAdd={setWidArg} allWids={allWidgets} queryID={queryID} setCode={setCode}/>
        </Grid>
        <Grid item sx={{marginTop: 1}}>
          <WidgetTemplateDialog label = "Update Templates" onAdd={setWidArg} allWids={allWidgets} queryID={queryID} widget={widget} />
        </Grid>
        <Grid item sx={{marginTop: 1}}>
          <Button onClick={()=> {OnWidSave(widget, code, widArg, widgetName)}} variant="contained">Save & Update</Button>
        </Grid>
        <Grid item>
          <div style={{height: "100%", width: "25vw"}}>{(widget.arguments.type == "BasicWidgetGroup" || widget.arguments.type =="BasicWidgetGroupStepper") ? (
            <Autocomplete
              disablePortal
              id="combo-box"
              sx = {{width: "100%", height: "100%"}}
              options={allWidgets}
              getOptionLabel={(option: Widget) => option.name}
              onChange={handleAddWidgetToGroup}
              renderInput={(params) => <TextField {...params} label="Add Widgets to Group" />}
            />): null}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item sx={{height: "60vh", width: "40vw"}}>
          <AceCodeEditor setCode={currentCode => setWidArg(currentCode)} code={widArg} language={'json'}/>
        </Grid>
        <Grid item sx={{height: "60vh", width: "40vw"}}>
          <AceCodeEditor code={code} setCode={setCode} language={'javascript'}/>
        </Grid>
      </Grid>
      <Grid item sx={{width: "80vw"}}>
        <WidgetSwitcher widget={widget}/>
      </Grid>
      <Grid>
        <Paper sx={{ display: 'none' }} >
          { isGroupOrStepper && widgets.map((widg) => {
            return <WidgetSwitcher widget={widg} key={widg}/> })
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default WidgetEditor