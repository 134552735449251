/* eslint-disable */

import type {FC} from 'react';
import React, {useContext, useEffect, useState} from "react";
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Button,
    Drawer,
    FormControl,
    Hidden,
    IconButton,
    InputBase,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Stack,
    styled,
    Typography
} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import {User} from "../../cog/user";
import NavSection from "./NavSection";
import orgChecker from "../../utils/orgChecker";
import {useMainContext} from "../../contexts/MainContext";
import {Organization} from "../../cog/organization";
import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material/styles";
import NavSectionComp from "./NavSectionComp";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LogoSwitcher from "../../pages/app/bitool/dashboard/header/LogoSwitcher";
import {useTheme} from "@mui/system";
import BusinessIcon from '@mui/icons-material/Business';
import LogoDark from "../../components/LogoDark";
import SettingsContext from "../../contexts/SettingsContext";
import {THEMES} from "../../constants";
import Logo from 'src/components/Logo';
import ActivePSUSelector from "../../pages/app/keystone/productstoreuniverse/components/ActivePSUSelector";
import {useKeystoneContext} from "../../contexts/KeystoneContext";
import { AdminStore } from 'src/cog/adminManagement';

export interface DashboardSidebarProps {
    onMobileClose: () => void;
    openMobile: boolean;
    organizations: Organization[] | null,
    activeOrganization: Organization | null;
    user: User;
    isLoading: boolean;
    areDashboardsLoading: boolean;
    sections: Array<any>; //todo: change this?
}

interface OrgProps {
    organizations: Organization[],
    activeOrganization: Organization | null;
    isLoading: boolean,
}

const OrgSelect: FC<OrgProps> = (props: OrgProps) => {
    const mainCtx = useMainContext()
    const settingsCtx = useContext(SettingsContext)

    let navigate = useNavigate()

    //  styling for the OrgSelect
    const useStyles = makeStyles((theme: Theme) => ({
        formControl: {
            minWidth: 40,
            minHeight: 20,
            color: theme.palette.text.secondary,
            fontColor: "white",
            marginLeft: useMainContext().slider ? '18px' : '0px',
            marginTop: useMainContext().slider ? '15px' : '0px',
        }, select: {
            // fontColor: "#6b778c",
            // fontColor: `"${theme.palette.text.secondary}"`,
            fontColor: theme.palette.text.secondary, font: "Segue UI", fontWeight: "300", fontSize: "0.80rem",
        }, selectEmpty: {
            // fontColor: "#6b778c",
            fontColor: theme.palette.text.secondary, // fontColor: `"${theme.palette.text.secondary}"`,
            font: "Segue UI", fontWeight: "300", fontSize: "0.80rem",
        }, whiteColor: {
            // color: `"${theme.palette.text.secondary}"`,
            color: theme.palette.text.secondary, // color: "#6b778c",
            fontWeight: "300", fontSize: "0.80rem",
        },
    }));

    // this is used to disable the highlight and borders of the org select textField
    const BootstrapInput = styled(InputBase)(({theme}) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        }, '& .MuiInputBase-input': {
            '&:focus': {
                borderRadius: 0, borderColor: '#fff', boxShadow: '0 0 0 0 rgba(0,123,255, 0)',
            },
            position: 'relative',
            backgroundColor: theme.palette.background.paper, // fontSize: 16,
            padding: useMainContext().slider ? '5px 0px 10px 0px' : '10px 0px 10px 0px', // fontColor: "#172b4d",
            font: "Segue UI",
            fontWeight: "300",
            fontSize: "0.80rem", //transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            // fontFamily: [
            //     '-apple-system',
            //     'BlinkMacSystemFont',
            //     '"Segue UI"',
            //     'Roboto',
            //     '"Helvetica Neue"',
            //     'Arial',
            //     'sans-serif',
            //     '"Apple Color Emoji"',
            //     '"Segue UI Emoji"',
            //     '"Segue UI Symbol"',
            // ].join(','),
        },
    }));

    const classes = useStyles();

    const handleChange = (event) => {
        navigate('/app/' + props.organizations.find(x => x.id === event.target.value).slug)
        mainCtx.setActiveOrganization(props.organizations.find(x => x.id === event.target.value))
        const org = props.organizations.find(x => x.id === event.target.value)
        
        if (settingsCtx.settings.theme !== 'DARK') {
            if (orgChecker(org.name)) {
                settingsCtx.saveSettings({
                    ...settingsCtx.settings, ['theme']: THEMES.OLDCOGNIZANCE,
                });
            } else {
                settingsCtx.saveSettings({
                    ...settingsCtx.settings, ['theme']: THEMES.LIGHT,
                });
            }
        }
    };
    
    useEffect(() => {}, [props.organizations, props.activeOrganization])
    
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    
    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const openPop = Boolean(anchorEl);
    
    if (props.organizations == null || props.activeOrganization == null || props.isLoading == true) {
        return <Skeleton variant="rectangular"/>
    }
    
    //  just some components that I didn't want to repeat in the different versions
    const selectConst = () => {
        return (<Select
            variant={'outlined'}
            classes={{
                select: classes.whiteColor, icon: classes.whiteColor
            }}
            input={<BootstrapInput/>}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={props.activeOrganization.id}
            onChange={handleChange}
            label="Organization"
            sx={{paddingLeft: "10px",}}
        >
            {props.organizations.map((org) => {
                return (<MenuItem
                        value={org.id}
                        key={org.id}>
                        {org.name}
                    </MenuItem>)
            })}
        </Select>)
    }
    
    // different org selector for different state of slider on useMainContext() along with setSlider
    if (mainCtx.slider) {
        // Expanded sidebar OrgSelector
        return (<Box sx={{padding: "16px", paddingBottom: "0px"}}>
                <Typography variant={"body1"} fontSize={"0.75rem"} fontWeight={"700"}
                            lineHeight={"2.5"}>ORGANIZATION</Typography>
                <FormControl variant="standard" className={classes.formControl}>
                    <InputLabel
                        id="demo-simple-select-outlined-label"
                        classes={{
                            root: classes.whiteColor
                        }}
                        sx={{color: `"${useTheme().palette.text.primary}"`,}}
                    >
                    </InputLabel>
                    <Box sx={{display: 'inline-flex',}}>
                        <BusinessIcon sx={{color: `"${useTheme().palette.text.secondary}"`,}}/>
                        {selectConst()}
                    </Box>
                </FormControl>
            </Box>)
    } else if (!mainCtx.slider) {
        // compressed sidebar OrgSelector
        return (<Box sx={{paddingX: "8px", paddingBottom: "0px", paddingTop: "5px"}}>
                <FormControl variant="standard" className={classes.formControl}>
                    
                    <InputLabel
                        id="demo-simple-select-outlined-label"
                        classes={{
                            root: classes.whiteColor
                        }}
                        sx={{color: `"${useTheme().palette.text.primary}"`,}}
                    >
                    </InputLabel>
                    
                    <Box sx={{
                        display: 'inline-flex',
                        color: 'text.primary',
                        fontSize: '0.75rem',
                        lineHeight: 2.5,
                        fontWeight: 700,
                        textTransform: 'uppercase'
                    }}>
                        
                        <IconButton onClick={handleClick} sx={{
                            padding: "0px",
                            border: `1px solid ${useTheme().palette.text.secondary}`,
                            marginBottom: "10px"
                        }}>
                            <Avatar sx={{width: 66, height: 66, background: `${useTheme().palette.background.paper}`}}>
                                {/*there are icon dependant styling on the logo.tsx and the LogoSwitcher*/}
                                <LogoSwitcher icon={true} org={props.activeOrganization.slug.toString()}/>
                            </Avatar>
                        </IconButton>
                        
                        <Popover
                            //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                            disableEnforceFocus
                            id={"test"}
                            open={openPop}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom', horizontal: 'right',
                            }}>
                            {selectConst()}
                        </Popover>
                    </Box>
                </FormControl>
            </Box>)
    }
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const {
        onMobileClose,
        openMobile,
        user,
        isLoading,
        areDashboardsLoading,
        sections,
        organizations,
        activeOrganization
    } = props;

    const location = useLocation();
    const mainCtx = useMainContext();
    const keystoneCtx = useKeystoneContext()

    const [psuAuthorized, setPSUAuthorized] = useState<boolean>(false)


    const toggleSlider = () => {
        const newState: boolean = !mainCtx.slider;
        mainCtx.setSlider(newState);
    };


    
    const arrowButton = (<Button onClick={toggleSlider}
                                 sx={{
                                     padding: mainCtx.slider ? '5%' : '10%',
                                     overflow: 'hidden',
                                     position: 'relative',
                                     borderRadius: "0",
                                     marginTop: mainCtx.slider ? "8%" : "18%",
                                     marginLeft: mainCtx.slider ? "82%" : "45%",
                                     marginRight: "0%",
                                 }}>
            {mainCtx.slider ? <ArrowBackIosIcon sx={{position: 'relative', fontSize: '16px'}}/> :
                <ArrowForwardIosIcon sx={{position: 'relative', fontSize: '16px'}}/>}
        </Button>)

    const fetchKeystoneAccess = async () => {
        if (mainCtx.user && mainCtx.user.id && mainCtx.activeOrganization && mainCtx.activeOrganization.id) {
            const response = await AdminStore.CheckKeystoneAccess({user_id: mainCtx.user.id, org_id: mainCtx.activeOrganization.id})
        
            if (response) {
              return response
            }
        }
    }

    useEffect(() => {
        let isMounted = true;
        fetchKeystoneAccess().then((r) => {
            if (isMounted && r) {
                if (r.user_permission && r.admin_permission) {
                    setPSUAuthorized(true);
                }
            }
        })

        return () => {
            isMounted = false;
        };
    }, [mainCtx.activeOrganization, mainCtx.user])
    
    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);
    
    useEffect(() => {
        openMobile ? mainCtx.setSlider(true) : null
    }, [openMobile])


    
    const content = (<Box
            sx={{
                display: 'flex', flexDirection: 'column', height: '100%',
            }}
        >
            <Hidden mdUp>
                <Box
                    sx={{
                        display: 'flex', justifyContent: 'center', p: 2, // mt: 7
                        mt: 3
                    }}
                >
                    <RouterLink to="/">
                        {/*ToDo: make ternary between light mode and dark mode whenever that comes out*/}
                        {(orgChecker(activeOrganization?.name)) ? <Logo
                            sx={{
                                height: 40, width: 40
                            }}
                        /> : <LogoDark
                            sx={{
                                height: 40, width: 40
                            }}/>}
                        {/*<Logo*/}
                        {/*    sx={{*/}
                        {/*        height: 40,*/}
                        {/*        width: 40,*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </RouterLink>
                </Box>
            </Hidden>
            <OrgSelect
                organizations={organizations}
                activeOrganization={activeOrganization}
                isLoading={isLoading}
            />
            {mainCtx.user != null && mainCtx.activeOrganization != null && keystoneCtx.psuTrees != null && psuAuthorized && <ActivePSUSelector />}
            <Box sx={{p: 2, paddingTop: mainCtx.slider ? 2 : '0px'}}>
                {!areDashboardsLoading && sections ? (!mainCtx.slider ? (sections.map((section) => (// after a loading check and then a sidebar check we use the NavSectionComp for the compressed sidebar,
                            // this is done to render the children instead of the items and children, except in the case for Dashboards
                            // with Dashboards we render its children within a popover
                            <NavSectionComp
                                key={section.title + section.id + `${Math.floor(Math.random() * 11)}`}
                                pathname={location.pathname}
                                sx={{
                                    '& + &': {
                                        // m: 3
                                    }
                                }}
                                {...section}
                            />))) : (sections.map((section) => (<NavSection
                                key={section.title + section.id + `${Math.floor(Math.random() * 11)}`}
                                pathname={location.pathname}
                                sx={{
                                    '& + &': {
                                        m: 3
                                    }
                                }}
                                {...section}
                            />)))) : mainCtx.slider ? (
                    <Stack sx={{display: 'grid', flexDirection: {xs: 'column', md: 'row'}}}>
                        <Skeleton variant="text" width={100} height={30}/>
                        <Skeleton variant="text" width={150} height={30}/>
                        <Box>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                            <Skeleton variant="rectangular" width={245} height={70} sx={{my: 3, borderRadius: '16px'}}/>
                        </Box>
                    </Stack>) : (<Stack sx={{display: 'grid', flexDirection: {xs: 'column', md: 'row'}}}>
                    <Box>
                        <Skeleton variant="rectangular" width={40} height={40}
                                  sx={{ml: "6px", my: 3, borderRadius: '16px'}}/>
                        <Skeleton variant="rectangular" width={40} height={40}
                                  sx={{ml: "6px", my: 3, borderRadius: '16px'}}/>
                        <Skeleton variant="rectangular" width={40} height={40}
                                  sx={{ml: "6px", my: 3, borderRadius: '16px'}}/>
                        <Skeleton variant="rectangular" width={40} height={40}
                                  sx={{ml: "6px", my: 3, borderRadius: '16px'}}/>
                        <Skeleton variant="rectangular" width={40} height={40}
                                  sx={{ml: "6px", my: 3, borderRadius: '16px'}}/>
                    </Box>
                </Stack>)}
            </Box>
        </Box>);
    
    return <>
        {// this first part is for mobile view and does not contain the sidebar open/close button
        }
        <Hidden mdUp>
            <Drawer
                anchor="left"
                onClose={onMobileClose}
                open={openMobile}
                PaperProps={{
                    sx: {
                        width: 280, backgroundColor: 'background.paper', // backgroundColor: '#4B4B4B',
                        height: 'calc(100% - 64px) !important', top: '64px !Important',
                    }
                }}
                variant="temporary"
            >
                {content}
            </Drawer>
        </Hidden>
        <Hidden mdDown>
            {mainCtx.activeOrganization && mainCtx.activeOrganization.name && <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        overflowX: "hidden",
                        backgroundColor: 'background.paper',
                        height: 'calc(100% - 64px) !important',
                        top: orgChecker(mainCtx.activeOrganization.name) ? '64px !Important' : '84px !Important', // width: 280
                        // percentages
                        width: mainCtx.slider ? 280 : 85,
                    }
                }}
                variant="persistent"
            >
                {arrowButton}
                {content}
            </Drawer>}
        </Hidden>
    </>;
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func, openMobile: PropTypes.bool,
};

export default DashboardSidebar;