/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import {FC, useLayoutEffect, useState} from 'react';
import {Box, Button, Fade, Paper, Popper, TextField, Typography} from "@mui/material";
import {enZA} from 'date-fns/locale'
import {WidgetProps} from "../../../WidgetSwitcher";
import BasicWidget from "../BasicWidget";
import slice from "../../../../../../../slices/bitool";
import {useDispatch} from "react-redux";
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns';
import {DesktopDateRangePicker} from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import {DateRange} from '@mui/x-date-pickers-pro/DateRangePicker';
import {useTheme} from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
    customIcon: {
      "& .MuiSvgIcon-root": {
        color: useTheme().palette.mode === 'dark' ? "#f2bc53" : '#6b778c',
      },
    },
  }));

const KEY_START = 'dateStart'
const KEY_END = 'dateEnd'

const BasicDateRangePickerWidgetInst: FC<WidgetProps> = ({widget, widgetVariables}) => {
    const dispatch = useDispatch()
    let url = window.location.pathname.toString()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    let dateStart = widget && widget.arguments && widget.arguments.templates &&
        widget.arguments.templates[KEY_START] ? widget.arguments.templates[KEY_START].value : null

    let dateEnd = widget && widget.arguments && widget.arguments.templates &&
        widget.arguments.templates[KEY_END] ? widget.arguments.templates[KEY_END].value : null

    // const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

    let getValue = ():DateRange<Date> => {
        if (widgetVariables && widgetVariables[KEY_START] && widgetVariables[KEY_END]) {
            return [widgetVariables[KEY_START], widgetVariables[KEY_END]]
        } else {
            return [null, null]
        }
    }

    // todo: unescape depreciated, does decodeURI work?
    const code = widget.arguments.code ? decodeURI(widget.arguments.code) : null
    try {
        eval(code);
    } catch (e) {
    }

    useLayoutEffect(() => {
        if (!widgetVariables) {
            dispatch(slice.actions.widgetChangeVariable({id: widget.id, data: {
                [KEY_START]: new Date(dateStart),
                [KEY_END]: new Date(dateEnd)
            }}));
        }
    }, [widgetVariables])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((previousOpen) => !previousOpen);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enZA}>
            <DesktopDateRangePicker
                className={classes.customIcon}
                startText="Start Date"
                endText="End Date"
                value={getValue()}
                onChange={(newValue) => {
                  let dateStart: Date
                  let dateEnd: Date
                  if (newValue[0] != null) {
                    dateStart = new Date(newValue[0])
                    const offset1 = dateStart.getTimezoneOffset()
                    dateStart = new Date(dateStart.getTime() - (offset1*60*1000))
                  } else {
                    dateStart = null
                  }
                  if (newValue[1] != null) {
                    dateEnd = new Date(newValue[1])
                    const offset2 = dateEnd.getTimezoneOffset()
                    dateEnd = new Date(dateEnd.getTime() - (offset2*60*1000))
                  } else {
                    dateEnd = null
                  }
                  if (dateStart != null && dateEnd != null) {
                    dispatch(slice.actions.widgetChangeVariable({id: widget.id, data: {
                        [KEY_START]: dateStart,
                        [KEY_END]: dateEnd
                      }}));
                  }
                }}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <TextField {...startProps} />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField {...endProps} />
                    </React.Fragment>
                )}
            />
            {url.includes("/bi/") &&
              <Box>
                <Button variant="text" onClick={handleClick}>
                  Widget Output
                </Button>
                <Popper id={id} open={open} anchorEl={anchorEl} placement="right-end" transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <Typography sx={{ p: 2 }}>WidgetOutput: {JSON.stringify(widgetVariables)}</Typography>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </Box>
            }
        </LocalizationProvider>
    );
}

const BasicDateRangeWidget: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<BasicDateRangePickerWidgetInst />}/>
}

export default BasicDateRangeWidget