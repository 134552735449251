import {Box, Grid, Grow, LinearProgress, Skeleton, Typography} from "@mui/material";
import React, {FC} from "react";

interface CardSkeleProps {
  w: string,
  h: string
}

interface SummonProps {
  CardDimensions: CardSkeleProps,
  SummonAmount: number,
  numColumns: number
}

const Skelly: FC<CardSkeleProps> = ({w, h}) => {
  // console.log("CardSkele: ", w,h)
  return (
    <Grow in={true}>
      <Box sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',minWidth: '100%' }}>
        <Skeleton variant="rectangular" width={w} height={h} sx={{padding: 5, borderRadius: "16px"}}/>
      </Box>
    </Grow>
  )
}

const SkeleSummon: FC<SummonProps> = (props) => {
  // console.log("SkeleSummon: ", props)
  let colTemplate = "1fr "
  for (let i = 1; i < props.numColumns; i++) {
    colTemplate += "1fr "
  }

  var summons = [];
  for (let i = 0; i < (props.SummonAmount); i++) {
    summons.push(<Skelly key={i} w={props.CardDimensions.w} h={props.CardDimensions.h}/>)
  }


  return (
    <Grid container display={"grid"} gridTemplateColumns={colTemplate} gridAutoFlow={"row"} gridAutoRows={"1fr"}>
      {summons}
    </Grid>
  )
}

const GoalPageSkeleton = () => {

  return (
    <Box sx={{minHeight: '800px', overflowY: 'auto', overflowX: 'hidden', margin: 'auto'}}>
      <Grid container spacing={1} columns={12}>
        <Grid item xs={12} xl={12}>
          <Typography variant={'h4'} sx={{m: 1}}>
            <LinearProgress/>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} xl={10}>
          <SkeleSummon SummonAmount={1} numColumns={1} CardDimensions={{w:"100%",h:"25rem"}}/>
          <SkeleSummon SummonAmount={1} numColumns={1} CardDimensions={{w:"100%",h:"10rem"}}/>
          <SkeleSummon SummonAmount={1} numColumns={1} CardDimensions={{w:"100%",h:"25rem"}}/>
        </Grid>
        <Grid item xs={12} md={2} xl={2}>
          <SkeleSummon SummonAmount={4} numColumns={1} CardDimensions={{w:"100%",h:"5rem"}}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default GoalPageSkeleton