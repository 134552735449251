import * as React from 'react';
import {FC, useEffect, useState, useMemo} from 'react';
import Box from '@mui/material/Box';
import {DataGridPro, GridColumnHeaderParams, GridSortModel} from "@mui/x-data-grid-pro";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  Divider,
  IconButton,
  Popover,
  styled,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import PriorityRating from "../GoalPriorityRating";
import {useMainContext} from "../../../../../contexts/MainContext";
import {useNavigate} from "react-router-dom";
import {GridColDef} from "@mui/x-data-grid";
import moment from "moment";
import Goal from "../../../../../cog/keystone/goals/goal/Goal";
import ReactGA from "react-ga4";
import DeleteGoalDialog from "./dialogs/DeleteGoalDialog";
import DeleteMultipleGoalsDialog from "../DeleteMultipleGoalsDialog";
import {
  AddCircleOutline,
  AttachFileOutlined,
  Delete,
  DeleteOutlined,
  LocalOfferOutlined,
  Pageview,
  PersonAddAlt,
} from "@mui/icons-material";
import CreateGoalDialog from "./dialogs/CreateGoalDialog";
import AssignUsersDialog from "./dialogs/AssignUsersDialog";
import TargetComponent from "./components/TargetComponent";
import {useParams} from "react-router";
import Campaign from "../../../../../cog/keystone/goals/campaigns/Campaign";
import ConfirmSettingsDialogCampaignGoalsDialog from "../goal/settings/ConfirmCampaignSettingsDialog";
import AddTagsDialog from "./dialogs/AddTagsDialog";
import { CreateOneRequest } from "../../../../../cog/keystone/goals/goal/Store";
import { GoalStore } from "../../../../../cog/keystone/goals";
import { useKeystoneContext } from "../../../../../contexts/KeystoneContext";
import { useSnackbar } from "notistack";
import GoalValueComponent from "./components/GoalValueComponent";

let dialogBody = () => {
    return (
        <div>
        </div>
    )
};


interface GoalsDataGridProps {
    goals: Goal[];
    source?: string;
    update?: () => void;
    campaigns?: Campaign[]
    isAdmin?: boolean
}

const GoalsDataGrid: FC<GoalsDataGridProps> = ({goals, source, update, campaigns, isAdmin}) => {
    const ctx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    let navigate = useNavigate()
    let {campaignId} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        ReactGA.event("keystone_view", {
            userID: ctx.user.id,
            orgID: ctx.activeOrganization.id,
            pageName: 'Goals'
        });
    }, [])

    const [dialogOpen, setDialogOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [createDisabled, setCreateDisabled] = useState<boolean>(false)

    const handleRowSelection = (newSelection) => {
        setSelectedRows(newSelection);
        if (newSelection.length > 0) {
            setCreateDisabled(true)
        } else {
            setCreateDisabled(false)
        }
    };

    const handleGoalCreation = async (goal: CreateOneRequest) => {
        const response = await GoalStore.CreateOne(goal)

        if (response && response.id) {
            enqueueSnackbar("Goal Created", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "success",
            });
        } else {
            enqueueSnackbar("Failed to Create Goal!", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
        }

        keystoneCtx.setFlipSwitchForGoals(!keystoneCtx.flipSwitchForGoals)
        update()
    }

    const openCreate = () => {
        setCreateOpen((prevState) => {
            return !prevState
        })
    }

    const handleMultiDelete = () => {
        dialogBody = () => {
            return (
              <DeleteMultipleGoalsDialog goalIDs={selectedRows} handleDialogClose={handleDialogClose}/>
            );
        }
        setDialogOpen(true);
    }

    const handleAssignUsers = () => {
        dialogBody = () => {
            return (
              <AssignUsersDialog goalIDs={selectedRows} handleDialogClose={handleDialogClose}/>
            );
        }
        setDialogOpen(true);
    }

    const handleAddTags = () => {
        dialogBody = () => {
            return (
              <AddTagsDialog goalIDs={selectedRows} handleDialogClose={handleDialogClose}/>
            );
        }
        setDialogOpen(true);
    }

    const StyledDataGrid = styled(DataGridPro)(({theme}) => ({
        '& .goalsGrid': {
            borderRadius: 5,
            margin: 2,
        },
        '& .goalsCell': {
            borderRadius: 5,
            margin: 2,
        },

        '& .goalsHeader': {
            fontSize: "medium",
            margin: 2,
        },
        // '& .MuiDataGrid-columnSeparator': {
        //     visibility: 'hidden',
        // },
    }))
    const handleDialogClose = () => {
        if (source && source == 'campaign') {
            update()
        }
        setDialogOpen(false);
    };

    const handleDeleteGoal = (goalID) => {
        dialogBody = () => {
            return (
                <DeleteGoalDialog goalID={goalID}
                                  handleDialogClose={handleDialogClose}/>
            );
        }
        setDialogOpen(true);
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.1,
            resizable: true,
            headerAlign: 'center',
            headerClassName: 'goalsHeader',
            align: 'left',
            renderCell: (cellValue) => {
                return <Box sx={{
                }}><Tooltip
                    title={cellValue.row.description}>
                    <Typography variant="body2"
                                fontSize="small"
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: ''
                                }}>{cellValue.value}
                    </Typography>
                </Tooltip>
                </Box>
            }
        },
        {
            field: 'type',
            headerName: 'Goal Type',
            flex: 0.1,
            resizable: false,
            headerAlign: 'center',
            headerClassName: 'goalsHeader',
            align: 'center',
            renderCell: (cellValue) => {
                return <Box sx={{display: 'flex', alignItems: 'center'}}><Tooltip
                    title={cellValue.row.description}><Typography variant="body2"
                                                                  fontSize="small">{cellValue.value}</Typography></Tooltip></Box>
            }
        },
        {
            field: 'value',
            headerName: 'Value',
            flex: 0.1,
            resizable: false,
            headerAlign: 'center',
            headerClassName: 'goalsHeader',
            align: 'center',
            renderCell: (params) => {
                return <GoalValueComponent goalID={params.row.id} />
            }
        },
        // {
        //     field: 'userID',
        //     headerName: 'Assignee(s)',
        //     flex: 0.2,
        //     headerAlign: 'left',
        //     resizable: false,
        //     headerClassName: 'goalsHeader',
        //     align: 'left',
        //     renderCell: (cellValue) => {
        //         let tempCell = []
        //         if (Array.isArray(cellValue.row.goalDetails) && cellValue.row.goalDetails.length > 0 && cellValue.row.goalDetails[0].id != "") {
        //             cellValue.row.goalDetails.map((goal) => {
        //                 tempCell = [...tempCell, ...JSON.parse(goal.userID)]
        //             })
        //         }else{
        //             tempCell = [...tempCell, ...JSON.parse(cellValue.row.userID)]
        //         }
        //         const uniqueArr = [...new Set(tempCell)]
        //         return <AssigneeComponent assignees={uniqueArr}/>
        //
        //     }
        // },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            flex: 0.1,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'goalsHeader',
            renderHeader: (params: GridColumnHeaderParams) => ('Due Date'),
            renderCell: (cell) => {
                return <Typography variant="body2"
                                   fontSize="small"
                                   sx={{
                                       display: 'flex',
                                       alignItems: 'center'
                                   }}>{moment(cell.value).format('DD-MM-YYYY')}</Typography>
            }
        },
        {
            field: 'target',
            headerName: 'Progress',
            flex: 0.1,
            resizable: false,
            headerAlign: 'center',
            headerClassName: 'goalsHeader',
            align: 'left',
            renderCell: (cellValues) => {
                return <TargetComponent value={0}
                                        row={cellValues}/>
            },
            type: 'number'
        },
        // {
        //     field: 'tag',
        //     headerName: 'Tags',
        //     flex: 0.1,
        //     resizable: false,
        //     headerAlign: 'center',
        //     align: 'center',
        //     headerClassName: 'goalsHeader',
        //     renderCell: (cellValue) => {
        //         return <Box sx={{display: 'flex', alignItems: 'center'}}>
        //             {cellValue.value && cellValue.value !== "" && JSON.parse(cellValue.value).length > 0 ? JSON.parse(cellValue.value).map((tag) => (
        //                     <Tooltip title={tag}
        //                              key={cellValue.id.toString() + tag}><Chip variant="outlined"
        //                                                                        sx={{
        //                                                                            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? "#657487" : '#f7f7f7'),
        //                                                                            margin: (theme) => (theme.palette.mode === 'dark' ? 0.3 : 0.1),
        //                                                                            boxShadow: (theme) => (theme.palette.mode === 'dark' ? 4 : 0),
        //                                                                        }}
        //                                                                        size={"small"}
        //                                                                        label={tag}/></Tooltip>)) :
        //                 <Typography>N/A</Typography>}</Box>
        //     }
        // },
        {
            field: 'priority',
            headerName: 'Priority',
            flex: 0.1,
            resizable: false,
            headerAlign: 'center',
            headerClassName: 'goalsHeader',
            align: 'center',
            renderHeader: (params: GridColumnHeaderParams) => ('Priority'),
            renderCell: (cellValues) => {
                return <PriorityRating rating={cellValues.value}
                                       readOnly={true}/>
            }
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     flex: 0.1,
        //     resizable: false,
        //     headerAlign: 'center',
        //     align: 'center',
        //     headerClassName: 'goalsHeader',
        //     renderHeader: (params: GridColumnHeaderParams) => ('Status'),
        //     renderCell: (cellValue) => {
        //         return <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Tooltip
        //             title={cellValue.row.description}><Typography variant="body2"
        //                                                           fontSize="small">{cellValue.value}</Typography></Tooltip></Box>
        //     }
        // },
        {
            field: "action",
            headerName: "Actions",
            sortable: false,
            flex: 0.1,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'goalsHeader',
            renderCell: (params) => {
                const [multiCampaignToAssign, setMultiCampaignToAssign] = useState([]);
                const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
                const [campaignLoading, setCampaignLoading] = useState<boolean>(false);

                // todo: Need to Add Connection for Adding Dial
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    handleDeleteGoal(params.row.id)
                };

                const handleNavigate = () => {
                    const cid = campaignId ? campaignId : (params && params.row.campaignID && JSON.parse(params.row.campaignID).length > 0) ? params.row.campaignID[0] : "d2057666-6ec6-4682-9f1f-101c28dd3c2a";
                    navigate('/app/' + ctx.activeOrganization.slug + "/goal/" + cid + "/" + params.row.id)
                }

                const handleCampaignUpdate = async () => {
                    const campaignIDs = []
                    for (let i = 0; i < multiCampaignToAssign.length; i++) {
                        campaignIDs.push(multiCampaignToAssign[i].id)
                    }

                    const tempGoal = new Goal()
                    tempGoal.campaignID = JSON.stringify(campaignIDs)
                    tempGoal.id = params.row.id

                    dialogBody = () => {
                        return (
                            <ConfirmSettingsDialogCampaignGoalsDialog confirmMessage={"Are you sure you want to attach the goal to the campaign(s)?"}
                                                                      type={"CampaignGoals"}
                                                                      goal={tempGoal}
                                                                      handleDialogClose={handleDialogClose}
                                                                      loadingMethod={setCampaignLoading}
                                                                      admin={isAdmin}
                                                                      campaigns={multiCampaignToAssign}/>
                        );
                    }

                    setDialogOpen(true);
                }
                const handleElClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget);
                };

                const handleElClose = () => {
                    setAnchorEl(null);
                    handleCampaignUpdate()
                };

                const open = Boolean(anchorEl);
                const id = open ? 'simple-popover' : undefined;

                return (
                    <Box>
                        <Tooltip title="View Goal" PopperProps={{ modifiers: [{ name: "offset", options: { offset: [0, 20], }, },], }}>
                            <span>
                               <IconButton aria-label="delete"
                                           onClick={handleNavigate}
                                           disabled={params.row.status === "Generating"}
                                           sx={{
                                             color: (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                                           }}>
                                <Pageview/>
                               </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={"Delete Goal"}>
                            <IconButton aria-label="delete"
                                        onClick={onClick}
                                        sx={{
                                            color: (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                                        }}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                        {source == 'campaign' ? <Tooltip title={"Add Goal To Campaign"}>
                            <IconButton aria-label="add_goal_campaign"
                                        onClick={handleElClick}
                                        sx={{
                                            color: (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                                        }}>
                                <AttachFileOutlined />
                            </IconButton>
                        </Tooltip> : ""
                        }
                        {source == 'campaign' ? <Popover
                            //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                            disableEnforceFocus
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleElClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    direction: 'column',
                                    px: 0.2,
                                    gap: "10px",
                                    margin: 1
                                }}>
                                <Card sx={{border: "none", boxShadow: "none"}}>
                                    <CardHeader title={"Assign goal to campaigns"}
                                                sx={{textAlign: "center"}}/>
                                    <Divider/>
                                    <CardContent>
                                        <Autocomplete
                                            sx={{
                                                width: "15vw",
                                            }}
                                            value={multiCampaignToAssign}
                                            onChange={(event, newValue) => {
                                                setMultiCampaignToAssign(newValue);
                                            }}
                                            multiple={true}
                                            id="Campaign_Autocomplete"
                                            limitTags={1}
                                            options={Object.values(campaigns)}
                                            defaultValue={multiCampaignToAssign}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Assign Campaigns"/>}
                                            renderOption={(props, option) => (
                                                <Box component="li"
                                                     sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <Chip label={option.name}
                                                          key={option.id}
                                                          id={option.id}
                                                          size={'small'}/>
                                                </Box>
                                            )}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Popover> : ''}
                    </Box>
                );
            }
        },
    ];

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'priority',
            sort: 'desc',
        },
    ]);

    const StyledDataGridMemo = useMemo(() => <StyledDataGrid
        columns={columns}
        rows={goals}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        checkboxSelection
        onSelectionModelChange={handleRowSelection}
        // selectionModel={selectedRows}
        // initialState={{ pinnedColumns: { right: ['action'] } }}
        hideFooter
        //autoHeight
        sx={{
            height: "50vh", m: 2,
            // '& .MuiDataGrid-cell': {
            //     whiteSpace: 'normal!important'
            // }
        }}
        getRowClassName={() => `goalsGrid`}
        getCellClassName={() => `goalsCell`}
    />, []);

    return (
        <Box>
            <Box>
                <Card>
                    <CardHeader
                      action={
                          <Box ari-label="text button group">
                              <Button title={"Assign Users"} onClick={handleAssignUsers} disabled={selectedRows.length <= 0 } >
                                  <PersonAddAlt fontSize={"large"}/>
                              </Button>
                              <Button title={"Add Tags"} onClick={handleAddTags} disabled={selectedRows.length <= 0 }>
                                  <LocalOfferOutlined fontSize={"large"}/>
                              </Button>
                              <Button title={"Delete Goals"} onClick={handleMultiDelete} disabled={selectedRows.length <= 0 }>
                                  <DeleteOutlined fontSize={"large"}/>
                              </Button>
                              <Button title={"Create Goal"} onClick={openCreate} disabled={createDisabled}>
                                  <AddCircleOutline fontSize={"large"}/>
                              </Button>
                          </Box>
                      }
                    />
                    {StyledDataGridMemo}
                </Card>
            </Box>
            <Dialog
                //disableEnforceFocus required to open chat if Dialog opened first
                disableEnforceFocus
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                {dialogBody()}
            </Dialog>
            <CreateGoalDialog
              open={createOpen}
              setOpen={openCreate}
              source={source}
              update={update}
              handleGoalCreation={handleGoalCreation}
            />
        </Box>
    );
}

export default GoalsDataGrid;

