import * as React from 'react';
import {FC, Fragment, useEffect, useLayoutEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {DataGridPro, DataGridProProps, GridAlignment, GridRowId, GridSortModel} from '@mui/x-data-grid-pro';
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {StyledDataGrid} from "./CustomDataGridTheme";
import {
    AccessTimeOutlined,
    AddCircleOutline,
    AssessmentOutlined,
    AutorenewOutlined,
    BlockOutlined,
    CalendarTodayOutlined,
    CheckCircleOutlineOutlined,
    Circle,
    Delete, ErrorOutline,
    ExpandLess,
    ExpandMore,
    Label,
    NewReleasesOutlined,
    Pageview,
    Remove,
    TaskAltOutlined
} from "@mui/icons-material";
import {ThemeProvider, useTheme} from "@mui/material/styles";
import {
    Avatar,
    Button, CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Dialog,
    IconButton,
    styled,
    Theme,
    Tooltip
} from "@mui/material";
import {Box} from "@mui/system";
import {values} from "lodash";
import {OppLabel} from "src/cog/keystone/oppLabel/OppLabel";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {useMainContext} from "../../../../../contexts/MainContext";
import currencySwitcher from "../../../../../utils/currencySwitcher";
import {Member} from "../../../../../cog/keystone/member";
import Loader from "../../kanban/util/Loader";
import Campaign from "../../../../../cog/keystone/goals/campaigns/Campaign";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import PriorityRating from "../GoalPriorityRating";
import {makeHeaderString} from "../../../../../utils/makeHeaderString";
import AssigneeComponent from "../goalDataGrid/components/AsigneesComponent";
import TargetComponent from "../goalDataGrid/components/TargetComponent";
import TagsComponent from "../TagsComponent";
import CreateCampaignDialog from "../CreateCampaignDialog";
import {CampaignGoalStore, CampaignStore} from "../../../../../cog/keystone/goals/campaigns";
import {useSnackbar} from "notistack";
import Goal from "../../../../../cog/keystone/goals/goal/Goal";
import ConfirmSettingsDialogCampaignGoalsDialog from "../goal/settings/ConfirmCampaignSettingsDialog";
import ConfirmDialogDeleteCampaign from "./ConfirmDialogDeleteCampaign";
import {KeystoneAccessResponse} from "../../../../../cog/adminManagement/Store";
import {AdminStore} from "../../../../../cog/adminManagement";
import ConfirmDialogDeleteCampaignGoal from "./ConfirmDialogDeleteCampaignGoal";
import OwnerComponent from "./OwnerComponent";
import CampaignValueComponent from "./CampaignValueComponent";


let dialogBody = () => {
    return (
        <div>
        </div>
    )
};


/**********************************************************************************************************************
 * CampaignDetailPanelContent: Creates the content for expanded DataGrid rows
 **********************************************************************************************************************/

function CampaignDetailPanelContent({
                                        row: rowProp,
                                        refreshRows: refreshRows,
                                        updateFunc: updateFunc,
                                        handleRemoveCampaignGoal: handleRemoveCampaignGoal
                                    }: {
    row: any,
    refreshRows: () => void,
    updateFunc: () => void
    handleRemoveCampaignGoal: (goalId, campaignId) => void
}) {
    // For loading things
    const [loading, setLoading] = useState<boolean>(true);
    const [myGridColumns, setMyGridColumns] = useState<GridColDef[]>(null);
    const [myRows, setMyRows] = useState<any[]>(null);
    // Contexts
    let navigate = useNavigate()
    const ctx = useMainContext()
    const {enqueueSnackbar} = useSnackbar();
    const MyCustomNoRowsOverlay = () => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            zIndex: 99
        }}><Typography variant={"h5"}>No Goals</Typography>
            <ErrorOutline/>
        </Box>
    )

    const StyledDataGrid = styled(DataGridPro)(({theme}) => ({
        '& .goalsGrid': {
            //backgroundColor: "#ebebeb",
            borderRadius: 5,
            margin: 0,
            '&:hover': {
                backgroundColor: theme.palette.mode === 'dark' ? "#cdcdcd" : '#f1f1f1',
            },
            '&.Mui-selected': {
                backgroundColor: theme.palette.mode === 'dark' ? "#cdcdcd" : '#f1f1f1',
                '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark' ? "#f1f1f1" : '#c0c0c0',
                },
            },
        },
        '& .goalsCell': {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'dark' ? "#4c5765" : "#f6f6f6",
            margin: 2,
        },
        '& .goalsHeader': {
            fontSize: "small",
            borderRadius: 5,
        },
    }))

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.3,
            resizable: false,
            headerClassName: 'goalsHeader',
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValue) => {
                return (
                    <Box>
                        <Tooltip
                            title={cellValue.row.description}>
                            <Typography sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>{cellValue.value}</Typography>
                        </Tooltip>
                    </Box>
                )
            }
        },
        {
            field: 'type',
            headerName: 'Goal Type',
            flex: 0.2,
            resizable: false,
            headerAlign: 'center',
            headerClassName: 'goalsHeader',
            align: 'center',
            renderCell: (cellValue) => {
                return <Box sx={{display: 'flex', alignItems: 'center'}}><Tooltip
                    title={cellValue.row.description}><Typography variant="body2"
                                                                  fontSize="small">{cellValue.value}</Typography></Tooltip></Box>
            }
        },
        // {
        //     field: 'userID',
        //     headerName: 'Assignee(s)',
        //     flex: 0.3,
        //     headerAlign: 'center',
        //     resizable: false,
        //     renderCell: (cellValue) => {
        //         return <AssigneeComponent assignees={cellValue?.value ? JSON.parse(cellValue.value) : []}/>
        //     }
        // },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            flex: 0.3,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cell) => {
                return <Typography>{moment(cell.value).format('DD-MM-YYYY')}</Typography>
            }
        },
        {
            field: 'target',
            headerName: 'Progress',
            flex: 0.3,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return <TargetComponent value={0}
                                        row={cellValues}/>
            },
            type: 'number'
        },
        // {
        //     field: 'tag',
        //     headerName: 'Tags',
        //     flex: 0.4,
        //     resizable: false,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: (cellValue) => {
        //         return (<Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        //             <TagsComponent cellValue={cellValue}/>
        //         </Box>)
        //     }
        // },
        {
            field: 'priority',
            headerName: 'Priority',
            flex: 0.3,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return <PriorityRating rating={cellValues.value}
                                       readOnly={true}/>
            }
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     flex: 0.5,
        //     resizable: false,
        //     headerAlign: 'center',
        //     renderCell: (cellValues) => {
        //         return <Typography>{cellValues.value}</Typography>
        //     }
        // },
        {
            field: "action",
            headerName: "Actions",
            sortable: false,
            flex: 0.3,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {

                const [isDisabled, setIsDisabled] = useState(false)

                const handleNavigate = () => {
                    navigate(`/app/${ctx.activeOrganization.slug}/goal/${params.row.campaignID}/${params.row.id}`);
                }

                // const deleteCampaignGoal = async () => {
                //     setIsDisabled(true)
                //     const response = await CampaignGoalStore.DeleteOne({
                //         campaignID: params.row.campaignID,
                //         clientName: ctx.activeOrganization.name,
                //         goalID: params.row.id
                //     })
                //     if (response) {
                //         return response
                //     }
                // }
                // const handleDelete = () => {
                //     deleteCampaignGoal().then((r) => {
                //         setIsDisabled(false)
                //         if (r.success) {
                //             enqueueSnackbar("Goal Removed", {
                //                 anchorOrigin: {
                //                     horizontal: "right",
                //                     vertical: "top",
                //                 },
                //                 variant: "success",
                //             });
                //         } else {
                //             enqueueSnackbar("Failed to Remove Goal!", {
                //                 anchorOrigin: {
                //                     horizontal: "right",
                //                     vertical: "top",
                //                 },
                //                 variant: "error",
                //             });
                //         }
                //         updateFunc()
                //     })
                // }
                // const deleteCampaignGoal = async () => {
                //     setIsDisabled(true)
                //     const response = await CampaignGoalStore.DeleteOne({
                //         campaignID: params.row.campaignID,
                //         clientName: ctx.activeOrganization.name,
                //         goalID: params.row.id
                //     })
                //     if (response) {
                //         return response
                //     }
                // }
                // const handleDelete = () => {
                //     deleteCampaignGoal().then((r) => {
                //         setIsDisabled(false)
                //         if (r.success) {
                //             enqueueSnackbar("Goal Removed", {
                //                 anchorOrigin: {
                //                     horizontal: "right",
                //                     vertical: "top",
                //                 },
                //                 variant: "success",
                //             });
                //         } else {
                //             enqueueSnackbar("Failed to Remove Goal!", {
                //                 anchorOrigin: {
                //                     horizontal: "right",
                //                     vertical: "top",
                //                 },
                //                 variant: "error",
                //             });
                //         }
                //         updateFunc()
                //     })
                // }

                return (
                    <Box>
                        <Tooltip title={"View Goal"}>
                            <IconButton aria-label="View Goal"
                                        onClick={handleNavigate}
                                        sx={{
                                            color: (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                                        }}>
                                <Pageview/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Remove Goal From Campaign"}>
                            <IconButton aria-label="Remove Goal From Campaign"
                                        onClick={() => handleRemoveCampaignGoal(params.row.campaignID, params.row.id)}
                                        sx={{
                                            color: (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                                        }}
                                        disabled={isDisabled}>
                                {isDisabled ? <CircularProgress size={20}/> : <Remove/>}
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            }
        },
    ];

    return (
        <Box sx={{backgroundColor: (theme) => (theme.palette.mode === 'dark' ? "#2f3b52" : '#ebebeb')}}>
            <Box>
                <StyledDataGrid
                    columns={columns}
                    rows={rowProp.goalDetails?.[0]?.id !== "" ? rowProp.goalDetails : []}
                    initialState={{sorting: {sortModel: [{field: 'priority', sort: 'desc'}],},}}
                    hideFooter
                    //autoHeight
                    sx={{height: "200px", width: '100%'}}
                    getRowClassName={() => `goalsGrid`}
                    getCellClassName={() => `goalsCell`}
                    components={{
                        NoRowsOverlay: MyCustomNoRowsOverlay
                    }}
                />
            </Box>
        </Box>
    );


    // Makes GridColDef arrays for the additional fields
    const makeColumns = () => {
        setLoading(false)

    }

    useLayoutEffect(() => {
        makeColumns()
    }, []);

    return (
        <>
            {loading ? <Loader/> : <Paper sx={{flex: 1, width: '100%'}}/>}
        </>
    );
}

/**********************************************************************************************************************
 * CampaignDataGridDetailProps with Campaign Detail Panel
 **********************************************************************************************************************/

// CampaignDataGridDetailProps: Props for DataGrid
interface CampaignDataGridDetailProps {
    campaigns: Campaign[]
    myRows?: any[]
    allLabels?: OppLabel[]
    update?: () => void
    createOpen?: boolean
    setCreateOpen?: (boolean) => void
    isAdmin: boolean
}

// CampaignDataGridDetail: Creates and returns a stylized DataGrid using given props
const CampaignDataGridDetail: FC<CampaignDataGridDetailProps> = (props) => {
    // Contexts
    const mainCtx = useMainContext();
    const keystoneCtx = useKeystoneContext();

    let navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar();

    const tempTheme = useTheme() // fetches current theme being used
    const currencySymbol = currencySwitcher(mainCtx.activeOrganization.name)

    // Column that contains the keys of the fields used in the grid as strings
    // Basically only used when making the data grid Columns to ensure there are no duplicates,
    // having it as a Hook might be unnecessary
    const [myColumns, setMyColumns] = useState<string[]>(null);

    // This one is used to define the columns in the DataGrid
    const [myGridColumns, setMyGridColumns] = useState<GridColDef[]>(null);

    // Sets whether the data is still loading or not
    const [loading, setLoading] = useState<boolean>(true);

    // These are used as the rows of the dataGrid
    const [myRows, setMyRows] = useState<any[]>(props.campaigns);

    // Theme Object
    const [myTheme, setMyTheme] = useState(tempTheme)

    // Used to adjust the width of the grid to be bigger than the screen when screen size gets too smol
    const [gridWidthResponse, setGridWidthResponse] = useState<string>("100%")

    // Members from org data, used for assigned_To display
    // const [members, setMembers] = useState<Member[]>(props.members);
    // Amount of records per page of the DataGrid, I think this needs to still exist despite not using pagination for the grid
    const [pageSize, setPageSize] = React.useState<number>(100);

    //All labels
    const [allLabels, setAllLabels] = useState<OppLabel[]>(props.allLabels)

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [CampaignChanged, setCampaignChanged] = React.useState(false);

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);

    const [deleteCampaignLoading, setDeleteCampaignLoading] = useState<boolean>(false);
    const [deleteCampaignGoalLoading, setDeleteCampaignGoalLoading] = useState<boolean>(false);

    const handleDetailPanelExpandedRowIdsChange = React.useCallback(
        (newIds: GridRowId[]) => {
            setDetailPanelExpandedRowIds(
                newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
            );
        },
        [],
    );
    const openCreate = () => {
        props.setCreateOpen((prevState) => {
            return !prevState
        })
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        props.setCreateOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setDialogOpen(false);
        if (CampaignChanged) {
            props.update()
        }
    };
    const handleDeleteCampaignGoalDialogClose = () => {
        setDialogOpen(false);
        if (CampaignChanged) {
            props.update()
        }
    };


    const MakeRows = (campaigns: Campaign[]) => {
        let empRows = []
        values(Object.values(campaigns)).map((value: Campaign) => (
            empRows.push({...value})))
        setMyRows(null) // done to reload data
        // setMyRows(tempRows) // no sorting
        setMyRows(empRows) // sorted by mValue
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    }, [])

    useEffect(() => {
        MakeRows(props.campaigns!)
    }, [props.campaigns!])

    useLayoutEffect(() => {
        makeColumns();
        handleResize()
    }, []);

    useEffect(() => {
        if (myColumns != null && loading == true) {
            setLoading(false)
        }
    }, [myColumns, mainCtx.members])

    function refreshRows() {
        setLoading(true)
        let tempRows = myRows
        setMyRows(null)
        setMyRows(tempRows)
        setLoading(false)
    }

    const handleDeleteCampaignGoal = async (goalId, campaignId) => {
        dialogBody = () => {
            return (
                <ConfirmDialogDeleteCampaignGoal
                    handleDialogClose={handleDeleteCampaignGoalDialogClose}
                    loadingMethod={setDeleteCampaignGoalLoading}
                    admin={props.isAdmin}
                    clientName={mainCtx.activeOrganization.name}
                    goalID={goalId}
                    updateCampaigns={() => props.update()}
                    changed={() => setCampaignChanged(true)}
                    campaignID={campaignId}/>
            );
        }

        setDialogOpen(true);
    }

    // getDetailPanelContent: The expand button content for the DataGrid rows
    const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(({row}) =>
        <CampaignDetailPanelContent row={row}
                                    refreshRows={refreshRows}
                                    updateFunc={props.update}
                                    handleRemoveCampaignGoal={(goalId, campaignId) => handleDeleteCampaignGoal(goalId, campaignId)}/>, []);

    // getDetailPanelHeight: For the height of the detail Panel
    const getDetailPanelHeight = React.useCallback(() => 200, []);

    // Makes the DataGrid table width exceed screen size when screen gets too small
    const handleResize = () => {
        if (window.innerWidth <= 500) {
            setGridWidthResponse("250%")
            return;
        }
        if (window.innerWidth <= 800) {
            setGridWidthResponse("200%")
            return;
        }
        if (window.innerWidth > 800 && window.innerWidth <= 1100) {
            setGridWidthResponse("150%")
            return;
        }
        if (window.innerWidth > 1100) {
            setGridWidthResponse("100%")
            return;
        }
    }

    const getGoalNames = (obj) => {
        return (
            <React.Fragment>
                <ul key={Math.floor(Math.random() * 9001)}>
                    {obj.map((goal) => <li key={goal.name + Math.floor(Math.random() * 9001)}>{goal.name}</li>)}
                </ul>
            </React.Fragment>
        )
    }

    const MyCustomNoRowsOverlay = () => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            zIndex: 99
        }}><Typography variant={"h5"}>No Campaigns</Typography>
            <ErrorOutline/>
        </Box>
    )

    const handleDeleteCampaign = async (goalId) => {
        dialogBody = () => {
            return (
                <ConfirmDialogDeleteCampaign
                    handleDialogClose={handleDeleteDialogClose}
                    loadingMethod={setDeleteCampaignLoading}
                    admin={props.isAdmin}
                    clientName={mainCtx.activeOrganization.name}
                    goalID={goalId}
                    updateCampaigns={() => props.update()}
                    changed={() => setCampaignChanged(true)}/>
            );
        }

        setDialogOpen(true);
    }


    // makeColumns: Initializes the GridColDef array for the DataGrid to use for organizing the data
    const makeColumns = () => {
        let tempColumns = []
        const columns: GridColDef[] = [
            {
                field: 'name',
                headerName: 'Name',
                flex: 0.4,
                resizable: false,
                headerClassName: 'goalsHeader',
                align: 'center',
                headerAlign: 'center',
                renderCell: (cellValue) => {
                    return (
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Tooltip
                                title={cellValue.row.description}>
                                <Typography>
                                    {cellValue.value}
                                </Typography>
                            </Tooltip>
                        </Box>
                    )
                }
            },
            {
                field: 'goalCount',
                headerName: 'Goals',
                flex: 0.2,
                resizable: false,
                headerClassName: 'goalsHeader',
                align: 'center',
                headerAlign: 'center',
                renderCell: (cellValue) => {
                    return (
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {cellValue.row.goalDetails?.length > 0 && cellValue.row.goalDetails[0].id !== '' && (
                                <Tooltip title={getGoalNames(cellValue.row.goalDetails)}>
                                    <Chip
                                        label={cellValue.row.goalDetails.length}
                                        size="small"
                                        sx={{ml: 1}}
                                        // variant="outlined"
                                    />
                                </Tooltip>
                            )}
                        </Box>
                    )
                }
            },
            {
                field: 'priority',
                headerName: 'Priority',
                flex: 0.2,
                resizable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (cellValues) => {
                    return (<PriorityRating rating={cellValues.value}
                                            readOnly={true}/>
                    )
                }
            },
            {
                field: 'owner',
                headerName: 'Owner',
                flex: 0.2,
                resizable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (cellValue) => {

                    // todo: We need to convert the way we receive Owner so that we either have the uuid or the correct display name. Once we have the UUID we can just pass it without any modification
                    return <OwnerComponent owner={[cellValue.value]} />

                    // return (
                    //     <Box>
                    //         <Tooltip
                    //             title={cellValue.row.description}>
                    //             <Typography>
                    //                 {cellValue.value}
                    //             </Typography>
                    //         </Tooltip>
                    //     </Box>
                    // )
                }
            },
            // {
            //     field: 'start_date',
            //     headerName: 'Start Date',
            //     flex: 0.2,
            //     resizable: false,
            //     headerAlign: 'center',
            //     align: 'center',
            //     renderCell: (cell) => {
            //         return <Typography>{moment(cell.row.startDate).format('DD-MM-YYYY')}</Typography>
            //     }
            // },
            {
                field: 'value',
                headerName: 'Value',
                flex: 0.1,
                resizable: false,
                headerAlign: 'center',
                headerClassName: 'goalsHeader',
                align: 'center',
                renderCell: (params) => {
                    return <CampaignValueComponent campaignID={params.row.id} />
                }
            },
            {
                field: 'due_date',
                headerName: 'Due Date',
                flex: 0.2,
                resizable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (cell) => {
                    return <Typography>{moment(cell.row.dueDate).format('DD-MM-YYYY')}</Typography>
                }
            },

            // {
            //     field: 'type',
            //     headerName: 'Type',
            //     flex: 0.5,
            //     resizable: false,
            //     headerAlign: 'center',
            //     align: 'center',
            //     renderCell: (cellValues) => {
            //         return (
            //             <Tooltip title={cellValues.value}>
            //                 <Chip icon={<Circle fontSize="small"/>}
            //                       label={cellValues.value}
            //                       color="primary"
            //                       size="small"
            //                       variant="outlined"/>
            //             </Tooltip>)
            //     },
            // },
            // {
            //     field: 'target',
            //     headerName: 'Target',
            //     flex: 0.5,
            //     resizable: false,
            //     headerAlign: 'center',
            //     renderCell: (cellValues) => {
            //         return <TargetComponent value={0}
            //                                 row={cellValues}
            //                                 source={'campaign'}/>
            //     },
            //     type: 'number'
            // },
            // {
            //     field: 'userID',
            //     headerName: 'Assignee(s)',
            //     flex: 0.4,
            //     headerAlign: 'center',
            //     resizable: false,
            //     renderCell: (cellValue) => {
            //         const uniqueArr = Array.isArray(cellValue.row.goalDetails) && cellValue.row.goalDetails.length > 0 && cellValue.row.goalDetails[0].id !== ""
            //             ? [...new Set(cellValue.row.goalDetails.flatMap(goal => JSON.parse(goal.userID)))]
            //             : [];
            //         return <AssigneeComponent assignees={uniqueArr}/>;
            //
            //     }
            // }
            , {
                field: "action",
                align: 'center',
                headerName: "Actions",
                sortable: false,
                flex: 0.2,
                resizable: false,
                headerAlign: 'center',
                renderCell: (params) => {

                    const [isDisabled, setIsDisabled] = useState(false)
                    const handleNavigate = () => {
                        navigate(`/app/${mainCtx.activeOrganization.slug}/campaigns/${params.row.id}`);
                    }

                    return (
                        <Box>
                            <Tooltip title={"View Goals"}>
                                <IconButton aria-label="view"
                                            onClick={handleNavigate}
                                            sx={{
                                                color : (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                                            }}>
                                    <Pageview/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Delete Campaign"}>
                                <IconButton aria-label="delete"
                                            onClick={() => handleDeleteCampaign(params.row.id)}
                                            sx={{
                                                color : (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                                            }}
                                            disabled={isDisabled}>
                                    {isDisabled ? <CircularProgress size={20}/> : <Delete/>}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                }
            },
        ];
        setMyGridColumns(columns)
        setMyColumns(tempColumns)
    }


    const handleChanged = () => {
        setCampaignChanged(true)
    }

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'priority',
            sort: 'desc',
        },
    ]);


    return (
        <React.Fragment>
            <Paper sx={{width: gridWidthResponse, height: "62vh", marginBottom: 1}}>
                <CardHeader action={<Button title={"Create Campaign"} onClick={openCreate}><AddCircleOutline fontSize={"large"}/></Button>}/>
                {loading ? <Loader/> :
                  <StyledDataGrid
                    sx={{
                        margin: 2,
                        width: "98%",
                        height: "83%"
                    }}
                    columns={myGridColumns}
                    rows={myRows}
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 1000 / pageSize}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    components={{
                        DetailPanelExpandIcon: ExpandMore,
                        DetailPanelCollapseIcon: ExpandLess,
                        NoRowsOverlay: MyCustomNoRowsOverlay
                    }}
                    componentsProps={{
                        panel: {
                            sx: {
                                '& .MuiDataGrid-panelWrapper': {
                                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? "#2f3b52" : '#ebebeb'),
                                },
                                // replaces scroll bar
                                '& .MuiDataGrid-panelContent': {
                                    overflowX: "hidden",
                                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? "#2f3b52" : '#ebebeb'),
                                    paddingRight: 0,
                                    "&::-webkit-scrollbar": {
                                        width: 10,
                                        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? "#2f3b52" : '#ebebeb'),
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? "#2f3b52" : '#ebebeb'),
                                        borderRadius: 1,
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: useTheme().palette.text.secondary,
                                        borderRadius: 2,
                                    }
                                }
                            }
                        }
                    }}
                    disableDensitySelector={true}
                    // getDetailPanelHeight={getDetailPanelHeight}
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={getDetailPanelContent}
                    pageSize={pageSize}
                    onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                    detailPanelExpandedRowIds={detailPanelExpandedRowIds}

                  />
                }
            </Paper>
            <Dialog
                //disableEnforceFocus required to open chat if Dialog opened first
                disableEnforceFocus
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                {dialogBody()}
            </Dialog>
            <CreateCampaignDialog open={props.createOpen}
                                  setOpen={openCreate}
                                  update={props.update}
                                  changed={handleChanged}/>
        </React.Fragment>
    );
}

/**********************************************************************************************************************
 * Helper Functions
 * Todo: Move everything below this line into a utils folder
 **********************************************************************************************************************/

interface RendCellProps {
    params: GridRenderCellParams
    members: Member[]
    currencySymbol?: string
    labels: OppLabel[]
}

// RendCell: Handles the rendering of the DataGrid cells based on passed values
const RendCell: FC<RendCellProps> = (props) => {
    // mValue cell
    if (props.params.field == "mValue") {
        return <span style={{overflow: "hidden", textOverflow: "ellipsis"}}>
            <Tooltip title={GetCellTooltip(props.params, props.members)}>
                <div> {props.currencySymbol + " " + props.params.value} </div>
            </Tooltip>
        </span>
    }

    // console.log("props", props)
    if (props.params.value == "" || props.params.value == null) {
        return <Tooltip title={"empty"}>
            <div>-</div>
        </Tooltip>
    }

    // assignTo custom cell
    if (props.params.field == "assignedTo") {
        {
            // console.log(props.params.value)
        }
        // return <Tooltip title={GetCellTooltip(props.params, props.members)}>
        //     {MakeValueNice(props.params.field, props.params.value, props.members)}
        return <FormatUsers k={props.params.field}
                            v={props.params.value}
                            m={props.members}
                            key={"assigned"}></FormatUsers>
        // </Tooltip>
    }
    // Custom Cell for dates
    if (props.params.field == "createdOn" || props.params.field == "due") {
        return <Tooltip title={GetCellTooltip(props.params, props.members)}>
            <Box sx={{display: "flex"}}>
                <CalendarTodayOutlined fontSize={"small"}
                                       color={"info"}
                                       sx={{mr: 1}}/>
                <div> {props.params.value} </div>
            </Box>
        </Tooltip>
    }
    // Custom chip Cell
    if (props.params.field == "status" || props.params.field == "resolved" || props.params.field == "repeat" || props.params.field == "active") {
        return <Tooltip title={GetCellTooltip(props.params, props.members, props.currencySymbol)}>
            {/*@ts-ignore*/}
            <Chip variant="outlined"
                  size="small" {...GetChipProps(props.params, useTheme())} />
        </Tooltip>
    }

    //Custom for Opportunity Labels
    if (props.params.field == "opportunityLabels") {

        return <FormatLabels field={props.params.field}
                             value={props.params.value}
                             oppLabels={props.labels}
                             theme={useTheme()}
                             key={"oppLabels"}></FormatLabels>
    }

    // Basic Cell
    return <Tooltip title={GetCellTooltip(props.params, props.members)}>
        <div> {props.params.field.includes("Value") != false || props.params.field.includes("Price") != false ? props.currencySymbol + " " + props.params.value : props.params.value} </div>
    </Tooltip>
}

// GetColType: Returns whether the column is a date, number or string
const GetColType = (k: string, v: string) => {
    if (k == "due" || k == "createdOn") {
        return "date"
    }
    if (k == "repeat" || k == "mValue") {
        return "number"
    }
    return "string"
}

// GetColFlex: Used to set how much of the data grid width is dedicated to each field in the data grid
// Works great when the default values are displayed, once more columns are added users can just manually adjust it to
// their needs
const GetColFlex = (k: string, v: string) => {
    let tempType = GetColType(k, v)
    let tLength = 0
    if (tempType == "date") {
        return 0.3;
    }
    if (tempType == "number") {
        return 0.28;
    }
    if (k == "resolved" || k == "status" || k == "assignedTo") {
        return 0.3;
    }
    if (k == "active") {
        return 0.25;
    }

    if (k == "opportunityType" || k == "opportunityLabels") {
        return 0.35;
    }
    if (k == "solution") {
        return 0.55;
    }

    if (tempType == "string") {
        tLength = v.length
        if (tLength > 20) {
            return 0.6;
        }
    }
    return 0.5;
}

// GetCellTooltip: Creates custom or default tooltip titles based on passed props
const GetCellTooltip = (props, members?: Member[], currencySymbol?: string) => {
    //AssignedTo
    if (props.field == "assignedTo") {
        // let tempAssigns = JSON.parse(`{"AssignedTo": ${props.value} }`)['AssignedTo']
        // // console.log("assignedTo", props.value)
        // if (props.value == "" || !members) {
        //     return <div>No User Assigned</div>
        // } else if (members[props.value.replace("auth0|", "")] == null && props.value != "") {
        //     return <div>No User Assigned<br/>Uid: {props.value} is provided, but user not found</div>
        // }
        // return <div>Name: {members[props.value.replace("auth0|", "")].name}<br/>
        //     Email: {members[props.value.replace("auth0|", "")].email}<br/>
        //     MetaData: {members[props.value.replace("auth0|", "")].user_metadata}</div>

        return ""
    }
    // Repeat value and field
    if (props.field == "repeat" || props.value == "Repeat") {
        return <div>Repeats: {props.row.repeat}<br/>M
            Value: {currencySymbol + " " + props.row.mValue}<br/>Total: {currencySymbol + " " + props.row.mValue * props.row.repeat}
        </div>
    }
    // dates
    if (props.field == "due" || props.field == "createdOn") {
        // let overdue = false
        let today = new Date()
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let tempCreated = new Date(Date.parse(props.row.createdOn)).toISOString().slice(0, 10)
        let tempDue = new Date(Date.parse(props.row.due)).toISOString().slice(0, 10)
        let tempDueDays = dateConverter(date, tempDue)
        let overdueString = ' in ' + tempDueDays + ' day(s) '
        if (tempDueDays == 0) {
            tempDueDays = dateConverter(tempDue, date)
            overdueString = " " + tempDueDays + " day(s) ago "
            // overdue = true
        }
        let tempCreatedDays = dateConverter(tempCreated, date)
        return <div>Start Date: {tempCreated} ( {tempCreatedDays} day(s) ago )
            <br/>Goal: {tempDue} ({overdueString})</div>
    }


    return <div>{makeHeaderString(props.field)}:<br/>{props.value}</div>
}

// BuildColumnObject: Uses passed parameters to create and then return a GridColDef object
export function BuildColumnObject(key: string, value: any, members: Member[], labels: OppLabel[], currencySymbol: string, hideSortIcons: boolean, hideByDefault: boolean, contentAlign?: GridAlignment): GridColDef {
    if (key == "createdOn" || key == "due") {
        return {
            field: key,
            type: GetColType(key, value.toString()),
            valueGetter: ({row}) => {
                return new Date(Date.parse(row[key])).toISOString().slice(0, 10)
            },
            align: contentAlign != null ? contentAlign : "left",
            hideSortIcons: hideSortIcons,
            hide: hideByDefault,
            description: makeHeaderString(key),
            headerName: makeHeaderString(key),
            flex: GetColFlex(key, value.toString()),
            sortingOrder: ['desc', 'asc'],
            renderCell: (params) => <RendCell params={params}
                                              members={members}
                                              labels={labels}/>
        }
    }

    return {
        field: key,
        type: GetColType(key, value.toString()),
        headerName: makeHeaderString(key),
        description: makeHeaderString(key),
        flex: GetColFlex(key, value.toString()),
        sortingOrder: ['desc', 'asc'],
        align: contentAlign != null ? contentAlign : "left",
        hideSortIcons: hideSortIcons,
        hide: hideByDefault,
        renderCell: (params) => <RendCell params={params}
                                          members={members}
                                          labels={labels}
                                          currencySymbol={currencySymbol}/>
    }
}

// GetChipProps: I feel like this can be simplified somehow
// But for now it is long, but basically just returns a colorful chip for the DataGrid fields that want one
function GetChipProps(params: GridRenderCellParams, theme: Theme) {
    if (params.field === "repeat") {
        if (params.value != 0) {
            return {
                icon: <AutorenewOutlined style={{color: theme.palette.info.main}}/>,
                label: params.value,
                style: {
                    borderColor: theme.palette.info.main,
                    padding: 5
                }
            };
        }
        return {
            // icon: <AutorenewOutlined style={{color: theme.palette.info.main}}/>,
            label: params.value,
            style: {
                borderColor: theme.palette.success.main,
                padding: 5
            }
        };
    }
    if (params.field === "active") {
        if (params.value == "active") {
            return {
                icon: <CheckCircleOutlineOutlined style={{color: theme.palette.success.main}}/>,
                label: params.value,
                style: {
                    borderColor: theme.palette.success.main,
                    // padding: 1,
                    // paddingLeft: 10
                }
            };
        }
        return {
            icon: <AccessTimeOutlined style={{color: theme.palette.warning.main}}/>,
            // label: params.value,
            style: {
                borderColor: theme.palette.warning.main,
                padding: 5
            }

        };
    }
    if (params.value === "Repeat") {
        return {
            icon: <AutorenewOutlined style={{color: theme.palette.info.main}}/>,
            label: params.value,
            // color: "info",
            style: {
                borderColor: theme.palette.info.main,
                // color: "error",
                padding: 5
            }
        };
    }
    if (params.value === "In Progress") {
        return {
            icon: <AccessTimeOutlined style={{color: theme.palette.success.main}}/>,
            label: params.value,
            style: {
                borderColor: theme.palette.success.main,
                padding: 5
            }
        };
    }
    if (params.value === "QA") {
        return {
            icon: <AssessmentOutlined style={{color: theme.palette.success.main}}/>,
            label: params.value,
            style: {
                borderColor: theme.palette.success.main,
                padding: 5
            }
        };
    }
    if (params.value === "True") {
        return {
            icon: <TaskAltOutlined style={{color: theme.palette.success.main}}/>,
            // label: params.value,
            style: {
                borderColor: theme.palette.success.main,
                padding: 5
            }
        };
    }
    if (params.value === "False") {
        return {
            icon: <BlockOutlined style={{color: theme.palette.warning.main}}/>,
            label: params.value,
            style: {
                borderColor: theme.palette.warning.main,
                padding: 5
            }
        };
    }
    return {
        icon: <NewReleasesOutlined style={{color: theme.palette.primary.main}}/>,
        label: params.value,
        style: {
            borderColor: theme.palette.primary.main,
            padding: 5
        }
    };

}

interface UserAssignStyleProps {
    k: string
    m: Member[]
    v: string
}

// FormatUsers: Formats and returns the users for display
const FormatUsers: FC<UserAssignStyleProps> = (props) => {
    // k = field, v = value, m = members
    if (props.k != "assignedTo" || !props.m) {
        return <div>{props.v}</div>
    }
    if (props.v == "" || props.v.length < 5) {
        return <div>No User Assigned</div>
    }

    try {
        let test = JSON.parse(`{"AssignedTo": ${props.v} }`)['AssignedTo']
    } catch (e) {
        // console.log(props.v)
        return <Tooltip title={"empty"}>
            <div>-</div>
        </Tooltip>
    }

    // This creates a JSON string and extracts just the user array from the parsed JSON
    // The "AssignedTo" string could be anything, it's just there to extract the json things
    let tempAssigns = JSON.parse(`{"AssignedTo": ${props.v} }`)['AssignedTo']
    let userMap: Member[] = []
    for (let i = 0; i < Object.values(props.m).length; i++) {
        userMap[props.m[i].id] = props.m[i]
    }

    return <div>
        {tempAssigns.map((val, index) => (
            userMap[val] != null &&
            <Tooltip
                title={<div>Name: {userMap[val].displayName}<br/>
                    Email: {userMap[val].email}</div>}
                key={`Tooltip_${index}`}
            >
                <div>
                    <CardHeader
                        avatar={
                            <Avatar
                                src={userMap[val].avatar}
                                alt={userMap[val].displayName}
                                variant={"circular"}
                                sx={{width: 24, height: 24}}
                            />
                        }
                        title={userMap[val].displayName}
                        disableTypography={true}
                        sx={{padding: 0}}
                        key={`AssignedUser_${index}`}
                    >
                    </CardHeader>
                </div>
            </Tooltip>
        ))}
    </div>
}

interface OppLabelsDisplayProps {
    field: string
    oppLabels: OppLabel[]
    value: string
    theme: Theme
}

const FormatLabels: FC<OppLabelsDisplayProps> = (props) => {
    if (props.field != "opportunityLabels" || !props.oppLabels) {
        return <div>No Labels</div>
    }
    if (props.value == "" || props.value.length < 5) {
        return <div>No Labels</div>
    }

    try {
        let test = JSON.parse(`{"opportunityLabels": ${props.value} }`)['opportunityLabels']
    } catch (e) {
        // console.log(props.v)
        return <Tooltip title={"empty"}>
            <div></div>
        </Tooltip>
    }

    // This creates a JSON string and extracts just the user array from the parsed JSON
    // The "AssignedTo" string could be anything, it's just there to extract the json things
    let tempOppLabels = JSON.parse(`{"opportunityLabels": ${props.value} }`)['opportunityLabels']
    let labelMap: OppLabel[] = []
    for (let i = 0; i < Object.values(props.oppLabels).length; i++) {
        labelMap[props.oppLabels[i].id] = props.oppLabels[i]
    }

    return <div>
        {tempOppLabels.map((val, index) => (
            labelMap[val] != null &&
            <Tooltip
                title={<div>Name: {labelMap[val].label}</div>}
                key={`OppTooltip_${index}`}
            >
                <div style={{marginTop: 3}}>
                    <Chip variant="outlined"
                          size="small"
                          icon={<Label style={{color: props.theme.palette.primary.main}}/>}
                          label={labelMap[val].label}
                          style={{
                              borderColor: props.theme.palette.primary.main,
                              padding: 5
                          }}

                    />
                </div>
            </Tooltip>
        ))}
    </div>

}

// dateConverter: Returns amount of days that has passed from the start date to the end date
const dateConverter = (startDate, timeEnd) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    const one_day = 1000 * 60 * 60 * 24;
    let result
    result = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / (one_day))
    // console.log('date Converter result', result)
    if (result < 0) {
        return 0
    }
    return result
}

export default CampaignDataGridDetail;