import * as React from 'react';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Box, Chip } from "@mui/material";
import { CheckCircleOutline, ListAlt } from "@mui/icons-material";
import currencyFormatter from "../../../../../../utils/currencyFormatter";
import { useMainContext } from "../../../../../../contexts/MainContext";
import { FC } from "react";
import _ from "lodash";

interface LandingTableProps {
  statRows: {};
}

const OverviewTable: FC<LandingTableProps> = ({statRows}) => {
  const mainCtx = useMainContext()
  let columns: GridColDef[] = []
  let rows = []

  if (Object.keys(statRows).length === 0) {
    columns = [{"field": "NoData", "headerName": "NO DATA TO DISPLAY", "width": 400}]
  } else {
    columns = [
      { field: 'Date_Actioned', headerName: 'Date Actioned', width: 200},
      { field: 'Status', headerName: 'Status', width: 200, renderCell: (params) => {
          if (params.value == 'Resolved') {
            return <Chip icon={<CheckCircleOutline />} label="Resolved" color="primary" />
          } else {
            return <Chip icon={<ListAlt />} label="Actioned" color="error" />
          }
        }},
      { field: 'User_Name', headerName: 'User', width: 200},
      { field: 'Opportunity_Name', headerName: 'Opportunity Name', width: 200},
      { field: 'Opportunity_Type', headerName: 'Opportunity Type', width: 200},
      { field: 'Value', headerName: 'Value', width: 200, renderCell: (params) => {
          return currencyFormatter(mainCtx.activeOrganization.name, params.value)
        }}
    ]
  }

  if (Object.keys(statRows).length === 0) {
    rows = [{id: 0, NoData: "Query returned no results"}]
  } else {
    _.range(statRows[Object.keys(statRows)[0]].length).map((idx) => {
      let row = {}
      row['id'] = idx + 1
      Object.keys(statRows).map((k) => {
        row[k] = statRows[k][idx]
      })
      rows.push(row)
    })
  }

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      <DataGridPro
        columns={columns}
        rows={rows}
        pageSize={5}
        rowsPerPageOptions={[5]}
        pagination={true}
        // autoHeight
        components={{
          Toolbar: GridToolbar
        }}
      />
    </Box>
  );
}

export default OverviewTable;