import {FilterItem} from "../../filter/Filter";
import {API, PerformJSONRPCRequest} from "../../../api/jsonrpc";
import {StatResults} from "../../card/Card";
import {TreeFromAPI} from "../../../../pages/app/keystone/productstoreuniverse/utility/NodeTypesAndOptions";

export interface StatsData {
    statName: string;
    statResults: StatResults;
}

export interface UserReportingStatsRequest {
    clientName: string
    userName?: string
    dateSelectionStart: string
    dateSelectionEnd: string
    limit: string
    filters: FilterItem[]
    defaultFilters: FilterItem[]
    PSU: TreeFromAPI
}

export interface UserReportingStatsResponse {
    UserReportPageStats: Record<string, StatsData>
}
export interface UserSpecificReportingStatsResponse {
    UserReport: Record<string, StatsData>
}
const Store = {
    serviceProvider: 'UserReporting-Store',
    async UserReportingStats(request: UserReportingStatsRequest): Promise<UserReportingStatsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UserReportPageStats`,
            request: [{
                "client": request.clientName,
                "dateSelectionStart": request.dateSelectionStart,
                "dateSelectionEnd": request.dateSelectionEnd,
                "limit": request.limit,
                "filters": request.filters,
                "defaultFilters": request.defaultFilters,
                "PSU": request.PSU
            }]
        })
        return response.UserReportPageStats
    },
    async UserSpecificReportingStats(request: UserReportingStatsRequest): Promise<UserSpecificReportingStatsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.SingleUserReport`,
            request: [{
                "client": request.clientName,
                "userName": request.userName,
                "dateSelectionStart": request.dateSelectionStart,
                "dateSelectionEnd": request.dateSelectionEnd,
                "limit": request.limit,
                "filters": request.filters,
                "defaultFilters": request.defaultFilters,
                "PSU": request.PSU
            }]
        })
        // console.log(response)
        return response.UserReport
    },
};

export default Store