import {Responsive, WidthProvider} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css'
import React, {FC, useEffect, useMemo, useState} from "react";
import {
    anyWidgetIsLoadingSelectorFunc,
    exportCsvSelectorFunc,
    exportExcelSelectorFunc,
    initializedSelector,
    renderWidgetsToLayoutExportSelectorFunc,
    renderWidgetsToLayoutSelectorFunc,
} from "../../../../slices/selectors";
import {useDispatch, useSelector} from "react-redux";
import {Backdrop, Box, CircularProgress,} from "@mui/material";
import WidgetSwitcher from "../widget/WidgetSwitcher";
import {initBitool} from "../../../../slices/bitool";
import {useParams} from "react-router";
import Header from "./header/Header";
import DashboardEmbedStore from "../../../../cog/bitool/dashboard/embedding/Store";
import exportAllCsv from "./reportsAndExports/exportCsv";
import exportAllExcel from "./reportsAndExports/exportExcel";
import Stack from '@mui/material/Stack';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DashboardSkeleton from "./DashboardSkeleton";
import ImageIcon from '@mui/icons-material/Image';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ExcelFileIcon from "../../../../icons/logos/ExcelLogo";
import DownloadIcon from "@mui/icons-material/Download";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router-dom";
import html2canvas from 'html2canvas';
import {useJwt} from "react-jwt";
import AuthorizationRequired from "../../../../components/guards/AuthorizationRequired";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayout {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number
}

interface DashboardEmbeddingProps {
    token?: string
}
const action = [
    { icon: <ImageIcon color="primary" />, name: 'Download as PNG File', operation : 'PNG'},
    { icon: <TextSnippetIcon color="primary" />, name: 'Download as CSV', operation : 'CSV'},
    { icon: <ExcelFileIcon />, name: 'Download as Excel File', operation : 'Excel'},
];

interface TokenClaims  {
    embedID: string
    dashID: string
    email: string
    exp: string
}
const DashboardEmbed : FC<DashboardEmbeddingProps>= (props) => {
    let navigate = useNavigate();
    const { token: propToken } = props;
    const { token: urlToken } = useParams();
    const tokenToUse = propToken || urlToken;
    const { decodedToken, isExpired } = useJwt(tokenToUse);
    const dT = decodedToken as TokenClaims;
    let dashId: string
    if (dT) {
        dashId = dT.dashID
    }
    let dispatch = useDispatch();
    let host = window.location.host.toString()
    const [layouts, setLayouts] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dashboard, setDashboard] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [reportEnabled, setReportEnabled] = useState(false);
/*    const ctx = useMainContext()*/

    const doStuff = () => {
        setReportEnabled(true)
        setOpen(true)
    };

    const exportCsvSelector = useMemo(exportCsvSelectorFunc, [])
    const exportCsv = useSelector((state) => exportCsvSelector(state));

    const exportExcelSelector = useMemo(exportExcelSelectorFunc, [])
    const exportExcel = useSelector((state) => exportExcelSelector(state));

    const selectAnyIsLoadingSelector = useMemo(anyWidgetIsLoadingSelectorFunc, [])

    const selectRenderWidgetsToLayoutSelector = useMemo(renderWidgetsToLayoutSelectorFunc, [])
    const selectRenderWidgetsToLayoutSelectorReport = useMemo(renderWidgetsToLayoutExportSelectorFunc, [])

    const renderWidgetsToLayout = useSelector((state) =>
        selectRenderWidgetsToLayoutSelector(state)
    )
    const renderWidgetsToLayoutReport = useSelector((state) =>
        selectRenderWidgetsToLayoutSelectorReport(state)
    )

    const selectAnyIsLoading = useSelector((state) =>
        selectAnyIsLoadingSelector(state)
    )

    const initSelector = useSelector((state) =>
        initializedSelector(state)
    )

    const getDashName = (dashId) => {
        Promise.resolve()
            .then(() => setLoading(true))
            .then(() => DashboardEmbedStore.FindOneAndDashboard({
                Key: tokenToUse}))
            .then((r) => {
                //TODO: Handle codes to redirect URL as needed.
                if (r.dashboard) {
                    setDashboard(r.dashboard)
                    dispatch(initBitool(dashId, false, true))
                } else {
                    //setMismatched(true)
                    setDashboard({})
                    }})
            .finally(() =>
                setLoading(false))
    };

    function handleClick (e,operation:String) {
        e.preventDefault();
        if (operation == 'PNG') {
            doStuff()
        } else if (operation == 'CSV') {
            exportAllCsv(exportCsv)
        } else if (operation == 'Excel') {
            exportAllExcel(exportExcel)
        }
/*        ReactGA.event("export" ,{
            userID: "embedded",
            dashID: dashId

      });*/
    };


    useEffect(() => {
        localStorage.setItem('apiToken', tokenToUse)
        if (dashId != null && !isExpired) {
            getDashName(dashId)
/*            ReactGA.event("dashboard_view" ,{
                userID: ctx.user.id,
                dashID: dashId,
                orgID: ctx.activeOrganization.id
            })*/;

        } else if (dashId != null && isExpired) {
            console.log("This token is expired")
            navigate("/401")
/*            DashboardEmbedStore.UpdateOne({key: tokenToUse, secret: "org_specific_secret"}).then((newKey) => {
                navigate("/")
                navigate("/embedding/" + newKey.key, {replace: true})
            })*/
        }
    }, [dashId])
    useEffect(() => {
        setLayouts(renderWidgetsToLayout.layouts)
    }, [renderWidgetsToLayout])
    
    useEffect(() => {
        if (!selectAnyIsLoading && reportEnabled) {
            const timer = () => setTimeout(function () {
                const captureElement = document.querySelector('#capture')
                html2canvas(captureElement as HTMLElement)
                    .then(canvas => {
                        canvas.style.display = 'none'
                        document.body.appendChild(canvas)
                        return canvas
                    })
                    .then(canvas => {
                        const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
                        const a = document.createElement('a')
                        a.setAttribute('download', 'my-image.png')
                        a.setAttribute('href', image)
                        a.click()
                        canvas.remove()
                    }).then(canvas => {
                    setOpen(false)
                    setReportEnabled(false)
                })
            }, 5500);
            const timerId = timer();
            return () => {
                clearTimeout(timerId);
            };
        }
    }, [selectAnyIsLoading, reportEnabled])
    if (!dashId) {
        return <AuthorizationRequired/>
    }

    if (!initSelector) {
        return <DashboardSkeleton/>
        //check
    }


    if (renderWidgetsToLayout) {
        return (
            <div>
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
                <Box sx={{position: 'relative'}}>
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={open}
                    >
                        <DashboardSkeleton/>
                    </Backdrop>
                    <Box>
                        <Header
                            dashboard={dashboard}
                            org={"NO LOGO"}
                            loading={loading}
                        />
                    </Box>

                        {!selectAnyIsLoading ? <SpeedDial
                            direction={"up"}
                            ariaLabel="SpeedDial basic example"
                            onClick={() => handleClick}
                            icon={<DownloadIcon />}
                            sx={{ position: 'fixed', bottom: 16 , right: 16 }}
                        >
                            {action.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => {
                                        handleClick(e, action.operation)
                                    }}
                                />
                            ))}
                        </SpeedDial> : <CircularProgress sx={{ position: 'fixed', bottom: 20 , right: 20 }}/>}
                    <Box id={"capture"}>
                        <ResponsiveGridLayout className="layout"
                                              layouts={reportEnabled ? renderWidgetsToLayoutReport.layouts : renderWidgetsToLayout.layouts} //reportEnabled ? renderWidgetsToLayoutReport.layouts : renderWidgetsToLayout.layouts
                                              rowHeight={15}
                                              isDraggable={false}
                                              isResizable={false}
                                              compactType={null}
                                              onLayoutChange={(_, _layouts) => {
                                                  console.log("Layouts: ", _layouts)
                                                  setLayouts(_layouts)
                                                  // layoutsToWidgetsRequest(_layouts)
                                              }}
                                              breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                              cols={{lg: 12, md: 12, sm: 12, xs: 4, xxs: 2}}>
                            {renderWidgetsToLayout.widgets.map((w) => {
                                return <div key={w.id.toString()}>
                                    <div style={{ height: "auto", width: "auto" }}>
                                        <WidgetSwitcher widget={w} report={reportEnabled} /> {/*reportEnabled*/}
                                    </div>
                                </div>
                            })}
                        </ResponsiveGridLayout>
                    </Box>
                </Box>
            </div>
        );
    } else {
        return (
            <Stack>
                <DashboardSkeleton/>
            </Stack>
        )
    }
}

export default DashboardEmbed