import React, {FC, useEffect, useState} from "react";
import {Avatar, Box, Dialog, IconButton, Tooltip, Typography} from "@mui/material";
import {useMainContext} from "../../../../../../contexts/MainContext";
import {GridColDef} from "@mui/x-data-grid";
import {Add} from "@mui/icons-material";
import currencyFormatter from "../../../../../../utils/currencyFormatter";
import _ from "lodash";
import {DataGridPro, GridToolbar} from "@mui/x-data-grid-pro";
import AvatarGroup from "@mui/material/AvatarGroup";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import Goal from "../../../../../../cog/keystone/goals/goal/Goal";
import ConfirmDialogOpps from "./ConfirmDialogOpps";
import {KeystoneAccessResponse} from "../../../../../../cog/adminManagement/Store";
import {AdminStore} from "../../../../../../cog/adminManagement";

interface SuggestedOppsDataGridProps {
    suggestionsData: {};
    setGoal: (goal: (prev) => Goal) => void;
    goal: Goal;
    update?: () => void
}



let dialogBody = () => {
    return (
        <div>
        </div>
    )
};
const SuggestedOppsDataGrid: FC<SuggestedOppsDataGridProps> = ({suggestionsData, setGoal, goal, update}) => {
    let {goalId, org, campaignId} = useParams();
    const mainCtx = useMainContext()

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    let columns: GridColDef[] = []
    let rows = []
    let navigate = useNavigate()

    const checkAccess = async (): Promise<KeystoneAccessResponse> => {
        try {
            return AdminStore.CheckKeystoneAccess(
                {
                    user_id: mainCtx.user.id,
                    org_id: mainCtx.activeOrganization.id
                }
            )
        } catch (e) {
            console.error(`${e}`);
        }
    }

    const processUserForDisplay = (id) => {
        if (mainCtx.members != null) {
            return mainCtx.members.find((obj) => {
                return obj.id === id;
            })
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        update()
    };

    const handleAddOpp = async (oppid) => {

        dialogBody = () => {
            return (
                <ConfirmDialogOpps handleDialogClose={handleDialogClose}
                                   loadingMethod={setLoading}
                                   admin={isAdmin}
                                   goalID={goalId}
                                   clientName={mainCtx.activeOrganization.name}
                                   oppID={oppid}
                                   goal={goal}
                                   setGoal={setGoal}
                                   type={"Add"}/>
            )
                ;
        }
        setDialogOpen(true);
    }

    const handleNavigate = (row) => {
        navigate('/app/' + mainCtx.activeOrganization.slug + "/opportunity/" + row.id)
    }

    if (!suggestionsData) {
        columns = [{"field": "NoData", "headerName": "NO DATA TO DISPLAY", "width": 400}]
    } else if (Object.keys(suggestionsData).length === 0) {
        columns = [{"field": "NoData", "headerName": "NO DATA TO DISPLAY", "width": 400}]
    } else {
        columns = [
            {field: 'Product', headerName: 'Name', minWidth: 200, flex: 1},
            {field: 'Store', headerName: 'Store', minWidth: 200, flex: 1},
            {field: 'Opportunity_Type', headerName: 'Opportunity Type', minWidth: 200, flex: 1},
            {
                field: 'Value', headerName: 'Opportunity Value', minWidth: 200, flex: 1, renderCell: (params) => {
                    return currencyFormatter(mainCtx.activeOrganization.name, params.value)
                }
            },
            {
                field: 'assigned_to', headerName: 'Users', minWidth: 200, flex: 1, renderCell: (cellValue) => {
                    let userDetails;
                    let userIDs = []
                    if (cellValue.value && cellValue.value.length > 0) {
                        userIDs = JSON.parse(cellValue.value)
                    }
                    return <Box sx={{display: 'flex', flexDirection: 'row'}}><AvatarGroup max={4}>
                        {(userIDs.length > 0) ? userIDs.map((user) =>
                                (userDetails = processUserForDisplay(user)) ?
                                    <Tooltip title={userDetails.displayName}
                                             key={userDetails.id}><Avatar
                                        src={userDetails.avatar}/></Tooltip> : null) :
                            <Typography></Typography>}
                    </AvatarGroup></Box>
                }
            }, {
                field: "action",
                headerName: "Actions",
                sortable: false,
                flex: 0.2,
                resizable: false,
                headerAlign: 'center',
                renderCell: (params) => {
                    const handleAddOppTemp = () => {
                        handleAddOpp(params.row.id)
                    }

                    return (
                        <Box>
                            <Tooltip title={"Add Opp"}>
                                <IconButton
                                  aria-label="add"
                                  onClick={handleAddOppTemp}
                                  sx={{
                                      color: (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                                  }}
                                >
                                    <Add/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                }
            },
        ]
    }
    if (!suggestionsData) {
        rows = [{id: 0, NoData: "Query returned no results"}]
    } else if (Object.keys(suggestionsData).length === 0) {
        rows = [{id: 0, NoData: "Query returned no results"}]
    } else {
        _.range(suggestionsData[Object.keys(suggestionsData)[0]].length).map((idx) => {
            let row = {}
            row['id'] = suggestionsData["Opportunity_ID"][idx]
            Object.keys(suggestionsData).map((k) => {
                row[k] = suggestionsData[k][idx]
            })
            rows.push(row)
        })
    }

    useEffect(() => {
        checkAccess()
            .then((r) => {
                if (r && r.admin_permission && r.user_permission) {
                    setIsAdmin(true)
                }
            })
    }, [])

    return (<Box sx={{height: 500, width: '100%'}}>
        <DataGridPro
            columns={columns}
            rows={rows}
            pageSize={5}
            rowsPerPageOptions={[5]}
            pagination={true}
            onRowDoubleClick={handleNavigate}
            components={{
                Toolbar: GridToolbar
            }}
        />
        <Dialog
            //disableEnforceFocus required to open chat if Dialog opened first
            disableEnforceFocus
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="confirm-dialog"
            fullWidth
            maxWidth="md"
        >
            {dialogBody()}
        </Dialog>
    </Box>)
}

export default SuggestedOppsDataGrid;