import {
    Alert,
    Box,
    Dialog,
    Skeleton,
    Tooltip,
    Typography
} from "@mui/material";
import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {Theme, useTheme} from "@mui/material/styles";
import {Helmet} from "react-helmet-async";
import {GoalStore} from "../../../../cog/keystone/goals";
import {useMainContext} from "../../../../contexts/MainContext";
import HomeGoal from "./HomeGoal";
import HomeCard from "./HomeCard";
import HomeOpportunityTable from "./HomeOpportunityTable";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Goal from "../../../../cog/keystone/goals/goal/Goal";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {HomeStore} from "../../../../cog/keystone/home";
import CreateGoalDialog from "../goals/goalDataGrid/dialogs/CreateGoalDialog";
import OpportunityCard from "../opportunity/OpportunityCard";
import {useNavigate} from "react-router-dom";
import {GetComparator, StableSort} from "../../../../utils/stableSort";
import HomeStatsSkeleton from "./components/HomeStatsSkeleton";
import LandingPageGoals from "../explore/overview/widgets/LandingPageGoals";
import { CreateOneRequest } from "../../../../cog/keystone/goals/goal/Store";
import { useSnackbar } from "notistack";
import { AdminStore } from "../../../../cog/adminManagement";
import { useParams } from "react-router";
import SplashDashboard from "../../SplashDashboard";

interface KeystoneHomeProps {}

const KeystoneHome: FC<KeystoneHomeProps> = (props) => {
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const {enqueueSnackbar} = useSnackbar();
    let navigate = useNavigate()
    let { org } = useParams();

    const [allGoals, setAllGoals] = useState<Goal[]>(null)
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0)
    const [homeStatData, setHomeStatData] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)

    const firstName = mainCtx.user.name.split(/\s+/)
    const timeElapsed = Date.now();
    const now = new Date(timeElapsed);
    const curHr = now.getHours()
    let greetingMessage: string

    if (curHr < 12) {
        greetingMessage = "Good Morning "
    } else if (curHr < 18) {
        greetingMessage = "Good Afternoon "
    } else {
        greetingMessage = "Good Evening "
    }

    const GoalSlideSettings = {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "25%",
        speed: 500,
        focusOnSelect: true,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlideIndex(newIndex)
        },


    }

    const getGoals = async () => {
        // setIsLoading(true)
        const response = await GoalStore.FindGoalsAndOppsByUserID({
            clientName: mainCtx.activeOrganization.name,
            userID: mainCtx.user.id,
        })
        if (response != null && response.goals != null) {
            let tempGoal: any[] = StableSort(response.goals, GetComparator("desc", "priority"))
            setAllGoals(tempGoal)
        } else {
            setAllGoals([])
        }
        // setIsLoading(false)
    }

    useEffect(() => {
        Promise.resolve().then(() => getGoals())
    }, [])

    // fetchStatData: Fetches data for the stats
    const fetchLandingStatData = async () => {
        setLoading(true)

        const dateSelectionStart = keystoneCtx.dateValue[0].toISOString().slice(0, 10)
        const dateSelectionEnd = keystoneCtx.dateValue[1].toISOString().slice(0, 10)
        let limit = "15"

        if (window.innerWidth <= 800) {
            limit = "5"
        }

        const response = await HomeStore.HomePageStats(
            {
                clientName: mainCtx.activeOrganization.name,
                dateSelectionStart: dateSelectionStart,
                dateSelectionEnd: dateSelectionEnd,
                limit: limit,
                userID: mainCtx.user.id,
            })

        if (response) {
            setHomeStatData(response)
        }

        setLoading(false)
    }

    const handleGoalCreation = async (goal: CreateOneRequest) => {
        const response = await GoalStore.CreateOne(goal)

        if (response && response.id) {
            enqueueSnackbar("Goal Created", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "success",
            });
        } else {
            enqueueSnackbar("Failed to Create Goal!", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
        }

        keystoneCtx.setFlipSwitchForGoals(!keystoneCtx.flipSwitchForGoals)
    }

    // Checks if the filter is ready to use and then fetches cards with filter
    useEffect(() => {
        if (keystoneCtx.filtersReady == true) {
            fetchLandingStatData()
        }
    }, [mainCtx.activeOrganization.name, keystoneCtx.dateValue, keystoneCtx.flipSwitchForCards])

    useEffect(() => {
        if (keystoneCtx.filtersReady == true) {
            keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
        }
    }, [keystoneCtx.filtersReady])

    // Sets which options to display in the toolbar
    useLayoutEffect(() => {
        if (keystoneCtx.toolBarDisplayOptions.selectOpps != true ||
            keystoneCtx.toolBarDisplayOptions.filterToMe != false ||
            keystoneCtx.toolBarDisplayOptions.stats != false ||
            keystoneCtx.toolBarDisplayOptions.filter != false) {
            keystoneCtx.setToolBarDisplayOptions({
                filter: false,
                stats: false,
                selectOpps: true,
                filterToMe: false,
                landingDateSelector: false
            })
            // if (keystoneCtx.selectCards == true) {
            //     keystoneCtx.handleSelectCards()
            // }
        }
    }, [keystoneCtx.toolBarDisplayOptions])

    // useEffect(() => {
    //     keystoneUserCheck()
    // }, [mainCtx.activeOrganization])

    return (
        <Box sx={{
            m: 1
        }}>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <Box sx={{
                width: "98%",
                p: 1,
                pr: 0,
                backgroundColor: useTheme().palette.background.default,
                color: useTheme().palette.text.primary,
                borderRadius: "2px"
            }}>
                <Box sx={{
                    p: 1,
                    textAlign: "center"
                }}>
                    <Typography variant="h3" gutterBottom>{greetingMessage + firstName[0] + ", Here Is Your Activity Summary"}</Typography>
                </Box>
                <Box sx={{ m: 1 }}>
                    {homeStatData["GoalProgress"] && homeStatData["RateOfClosure"] && homeStatData["UserContributions"] ?
                      <LandingPageGoals
                        actual={homeStatData["GoalProgress"]["m_value"]}
                        org={mainCtx.activeOrganization.name}
                        areaData={homeStatData["RateOfClosure"]}
                        areaLabelValue={"day"}
                        barData={homeStatData["UserContributions"]}
                        members={mainCtx.members}/> : <HomeStatsSkeleton/>}
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        textAlign: "center",
                        m: 1,
                        minHeight: allGoals != null && allGoals.length > 0 ? "46vh" : "20vh",
                        border: `1px solid ${useTheme().palette.divider}`,
                        backgroundColor: useTheme().palette.background.paper,
                        borderRadius: 1,
                        ' .goalSlide': {
                            border: `1px solid ${useTheme().palette.divider}`,
                            transition: "all .2s ease",
                            height: "100%",
                            margin: 5,
                            fontSize: "0.6rem",
                        },
                        ' .slick-center': {
                            p: 2,
                            px: 3,
                            ' .goalSlide': {
                                margin: 0,
                                height: "100%",
                                fontSize: "0.95rem"
                            }
                        }

                    }}>
                    <Typography variant={"h4"} align={"left"} m={2}>Goals: {allGoals != null ? allGoals.length : "0"}</Typography>
                    <Slider {...GoalSlideSettings} >
                        {allGoals != null && allGoals.length > 0 ? allGoals.map((mappedGoal, index) => {
                            return <Tooltip title={"Number of Opportunities available: " + (mappedGoal.cards == null ? "0" : mappedGoal.cards.length )}
                                            key={mappedGoal.id + "_Tooltip"}>
                                {/*<Badge badgeContent={mappedGoal.cards.length} color="primary">*/}
                                <Box
                                  onClick={() => {
                                    if (allGoals[currentSlideIndex].id == mappedGoal.id) {
                                        if (mappedGoal.campaignID !== "[]") {
                                            navigate('/app/' + mainCtx.activeOrganization.slug + "/goal/" + mappedGoal.campaignID + "/" + mappedGoal.id)
                                        } else {
                                            navigate('/app/' + mainCtx.activeOrganization.slug + "/goal/d2057666-6ec6-4682-9f1f-101c28dd3c2a/" + mappedGoal.id)
                                        }
                                    }
                                  }}
                                >
                                    <HomeGoal goal={mappedGoal} key={mappedGoal.id}/>
                                </Box>
                                {/*</Badge>*/}
                            </Tooltip>
                        }) : allGoals != null && allGoals.length == 0 ? <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center"}}>No Currently Active Goals </Alert>
                            : <Box>
                                <Skeleton sx={{ height: "35vh", width: "40vw"}} />
                            </Box>}
                    </Slider>
                    <Box sx={{
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                        p: 1
                    }}>
                        <Box sx={{
                            width: "98%",
                            m: 1,
                            p: 0,
                            backgroundColor: useTheme().palette.background.paper,
                            color: useTheme().palette.text.primary,
                            borderRadius: 1,
                        }}>
                            {(allGoals != null && allGoals[currentSlideIndex] != null && allGoals[currentSlideIndex].cards != null) ? <Box>
                                  <Typography variant={"h4"} align={"left"} m={2}>Opportunities For {allGoals[currentSlideIndex].name}: {allGoals[currentSlideIndex].cards.length}</Typography>
                                  <HomeOpportunityTable opportunities={allGoals[currentSlideIndex].cards} />
                              </Box>
                                : null}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Dialog
                //disableEnforceFocus required to open chat if Dialog opened first
                disableEnforceFocus
                onClose={() => {keystoneCtx.handleCardClose()}}
                open={keystoneCtx.cardOpen}
                maxWidth={"xl"}
            >
                {keystoneCtx.cardOpen ? <OpportunityCard onClose={() => {keystoneCtx.handleCardClose()}}/> : ""}
            </Dialog>
            <CreateGoalDialog open={keystoneCtx.createGoalOpen} setOpen={keystoneCtx.setCreateGoalOpen} handleGoalCreation={handleGoalCreation}/>
        </Box>

    )
}


export default KeystoneHome