/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import Member from "./Member";
import {FindUserEmailRequest, FindUserEmailResponse} from "../../user/Store";
import {MemberStore} from "./index";


export interface FindManyRequest {
    orgID:   string
}

export interface FindManyResponse {
    members: Member[]
}

const Store = {
    serviceProvider: 'KanbanMembers-Store',
    async FindMany(request: FindManyRequest): Promise<FindManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `KanbanMembers-Store.FindMany`,
            request: [{
                "id": request.orgID,
            }]
        })
        if (response.message == "record not found") {
            return null
        }
        // console.log("members",response)
        if (response.kanbanMembers) {
            return {members: response.kanbanMembers}
        } else {
            return {members: []}
        }

    },
};

export default Store;