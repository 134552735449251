/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import {KanbanColumnObj} from "./KanbanCard";
import Card, {StatsData} from "./Card";
import {FilterItem} from "../filter/Filter";
import {KanbanStore} from "./index";
import {TreeFromAPI} from "../../../pages/app/keystone/productstoreuniverse/utility/NodeTypesAndOptions";


export interface FindAllRequest {
    clientName: string
    filter: FilterItem[]
    PSU: TreeFromAPI
}

export interface FindAllResponse {
    columns: Record<string, KanbanColumnObj>,
    allCards: Record<string, Card>
}

export interface StatsRequest {
    clientName: string
}

export interface StatsResponse {
    stats: Record<string, StatsData>
}

export interface LandingPageRequest {
    clientName: string
    dateSelectionStart: string
    dateSelectionEnd: string
    limit: string
    filters: FilterItem[]
    defaultFilters: FilterItem[]
    PSU: TreeFromAPI
}

export interface LandingPageResponse {
    landingPageStats: Record<string, StatsData>
}

export interface ArchivedStatsPageRequest {
    clientName: string
    dateSelectionStart: string
    dateSelectionEnd: string
}

export interface ArchivedStatsPageResponse {
    ArchivedPageStats: Record<string, StatsData>
}
export interface UserStatsPageRequest {
    clientName: string
    dateSelectionStart: string
    dateSelectionEnd: string
}

export interface UserStatsPageResponse {
    UserPageStats: Record<string, StatsData>
}
export interface SingleUserStatsPageRequest {
    clientName: string
    userName: string
    dateSelectionStart: string
    dateSelectionEnd: string
}

export interface SingleUserStatsPageResponse {
    UserPageStats: Record<string, StatsData>
}
export interface FindCardsRequest {
    clientName: string
}

export interface FindCardsResponse {
    allCards: Record<string, Card>
}

export interface UpdateMultipleRequest {
    ID: string;
    columnName: string;
    valueChange: string;
    organisation: string;
    UserId: string;
}

export interface UpdateMultipleResponse {
    KanCard: Card;
}

export interface AssignUserRequest {
    assignUsers: string[]
    cardID: string
    client: string
    userID: string
}
export interface AssignMultiUserRequest {
    assignUsers: string[]
    cardIDs: string[]
    client: string
    userID: string
}

export interface OppLabelRequest {
    oppLabelIDs: string[]
    cardID: string
    client: string
    userID: string
}export interface OppLabelMultiCardRequest {
    oppLabelIDs: string[]
    cardIDs: string[]
    client: string
    userID: string
}

export interface SwapPositionsRequest {
    IDOne: string;
    IDTwo: string;
    PositionOne: string;
    PositionTwo: string;
}

export interface SwapPositionsResponse {
    success: number
}

export interface ArchiveOneRequest {
    ID: string;
}

export interface ArchiveOneResponse {
    KanCard: Card;
}

export interface FindFilterRequest {
    userID: string,
    orgID: string
}

export interface SaveFilterRequest {
    OrgId: string;
    UserId: string;
    Filter: Record<string, Record<string, any>>;
    Name: string;
    Chips: string[]
}

export interface SaveFilterResponse {
}

export interface FindHomeTopWidgetValuesRequest {
    UserId: string
    ClientName: string
}

export interface FindHomeTopWidgetValuesResponse {
    mValueTotal: {
        OrganizationTotalMValue: number
        CompletedTotalMValue: number
        UserTotalMValue: number
    }
}

export interface FindOneRequest {
    id: string
    Org: string
}

export interface FindOneResponse {
    card: Card
}

export interface AccessRequest {
    identifier: string,
}

export interface AssignGoalsRequest {
    assignGoals: string[]
    cardID: string
    organisation: string
    userID: string
}

export interface AssignMultiGoalsRequest {
    assignGoals: string[]
    cardIDs: string[]
    organisation: string
    userID: string
}

export interface FindStatusAllCardsRequest {
    userID: string;
    organizationID: string;
}

export interface FindStatusAllCardsResponse {
    statusCard: any[]
}

export interface SendToExternalRequest {
    opportunityID: string;
    clientName: string;
}

export interface SendToExternalResponse {
    success: boolean;
}

const Store = {
    serviceProvider: 'Kanban-Card-Store',
    async FindStatusAllCards(request: FindStatusAllCardsRequest): Promise<FindStatusAllCardsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindStatusAllCards`,
            request: [
                {
                    "userID": request.userID,
                    "organizationID": request.organizationID,
                },
            ],
        });
        // return { findStatusCardList: response.findStatusCardList };
        return response
    },
    
    async FindAll(request: FindAllRequest): Promise<FindAllResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAll`,
            request: [{
                "client": request.clientName,
                "filter": request.filter,
                "PSU": request.PSU
            }]
        });

        if (response.columns != null) {
            return {
                'columns': response.columns,
                'allCards': response.allCards
            }
        }
        return response;
    },
    async Stats(request: StatsRequest): Promise<StatsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.Stats`,
            request: [{
                "client": request.clientName,
            }]
        });

        return response.stats
    },

    // TODO ADD PSU
    async LandingPageStats(request: LandingPageRequest): Promise<LandingPageResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.LandingPageStats`,
            request: [{
                "client": request.clientName,
                "dateSelectionStart": request.dateSelectionStart,
                "dateSelectionEnd": request.dateSelectionEnd,
                "limit": request.limit,
                "filters": request.filters,
                "defaultFilters": request.defaultFilters,
                "PSU": request.PSU
            }]
        })
        return response.landingPageStats
    },
    async ArchivedPageStats(request: ArchivedStatsPageRequest): Promise<ArchivedStatsPageResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.ArchivedStats`,
            request: [{
                "client": request.clientName,
                "dateSelectionStart": request.dateSelectionStart,
                "dateSelectionEnd": request.dateSelectionEnd
            }]
        })
        return response.archivedPageStats
    },
    async UserPageStats(request: UserStatsPageRequest): Promise<UserStatsPageResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UserReportPageStats`,
            request: [{
                "client": request.clientName,
                "dateSelectionStart": request.dateSelectionStart,
                "dateSelectionEnd": request.dateSelectionEnd
            }]
        })
        return response.UserReportPageStats
    },
    async SingleUserStats(request: SingleUserStatsPageRequest): Promise<SingleUserStatsPageResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.SingleUserReport`,
            request: [{
                "client": request.clientName,
                "userName": request.userName,
                "dateSelectionStart": request.dateSelectionStart,
                "dateSelectionEnd": request.dateSelectionEnd
            }]
        })
        //console.log("Store response",response.UserReportPageStats)
        // console.log(response)
        return response.UserReport
    },

    async UpdateMultiple(request: UpdateMultipleRequest): Promise<UpdateMultipleResponse> {
        //when updating "list_id" no need to concatenate value to string with brackets as it is held in different table on BQ
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateMultiple`,
            request: [{
                "organisation": request.organisation,
                "columnName": request.columnName,
                "valueChange": request.valueChange,
                "id": request.ID
            }]
        });

        // console.log("Response of UpdateOne: ", response.kanban_card)
        return response
    },


    async AssignMultiUsers(request: AssignMultiUserRequest): Promise<string> {
        let tempAssign = ""
        // if no users are assigned, just assign the new one
        if (request.assignUsers == null || request.assignUsers.length < 1 ) {
            //No User to assigned, make it a blank new one
            tempAssign = '[""]'
        } else {
            // user assignments already exist, extract, check for duplicates, and add new user assign
            let tempAssigns = JSON.parse(`{"AssignedTo": "${request.assignUsers.toString()}" }`)['AssignedTo']
            // if (tempAssigns.indexOf(request.userIDToAssign) == -1) {
            //     tempAssigns.push(request.userIDToAssign)
            // }

            tempAssign = '["' + (tempAssigns.toString()).replaceAll(',', '", "') + '"]'
        }

        if (request.cardIDs.length < 1) {
            // No cards to update
            return tempAssign
        }
        let tempCardIDs = "("
        request.cardIDs.map((val, index) => {
            if (index > 0) {
                tempCardIDs += ", "
            }
            tempCardIDs += "'" + val + "'"
        })
        tempCardIDs += ")"

        // console.log("tempAssign", tempAssign)
        // console.log("tempCardIDs", tempCardIDs)
        try {
            await KanbanStore.UpdateMultiple({
                ID: tempCardIDs,
                organisation: request.client,
                columnName: "assigned_to",
                valueChange: tempAssign,
                UserId: request.userID
            })
        } catch (e) {
            console.error(`${e}`);
        }
        if (tempAssign == '[""]') {
            return ""
        }
        return tempAssign
    },

    async AssignMultiLabels(request: OppLabelMultiCardRequest): Promise<string> {
        //console.log("LabelOpportunityCard request", request)
        //console.log("LabelOpportunityCard", `{"Opp labels": ${request.oppLabelIDs.toString()} }`)
        let tempLabel = ""
        // if no labels are assigned, just assign the new one
        if (request.oppLabelIDs == null || request.oppLabelIDs.length < 1) {
            //No Label to assigned, make it a blank new one
            tempLabel = '[""]'
        } else {
            // Labels already exist, extract, check for duplicates, and add new user assign
            let tempLabels = JSON.parse(`{"OppLabels": "${request.oppLabelIDs.toString()}" }`)['OppLabels']
            tempLabel = '["' + (tempLabels.toString()).replaceAll(',', '", "') + '"]'
        }
        // console.log("Card store LabelOpportunityCard tempLabel", tempLabel)

        if (request.cardIDs.length < 1) {
            // No cards to update
            return tempLabel
        }
        let tempCardIDs = "("
        request.cardIDs.map((val, index) => {
            if (index > 0) {
                tempCardIDs += ", "
            }
            tempCardIDs += "'" + val + "'"
        })
        tempCardIDs += ")"

        // console.log("tempAssign", tempAssign)
        // console.log("tempCardIDs", tempCardIDs)
        try {
            await KanbanStore.UpdateMultiple({
                ID: tempCardIDs,
                organisation: request.client,
                columnName: "opp_label",
                valueChange: tempLabel,
                UserId: request.userID
            })
        } catch (e) {
            console.error(`${e}`);
        }
        if (tempLabel == '[""]') {
            return ""
        }
        return tempLabel
    },


    async SwapPositions(request: SwapPositionsRequest): Promise<SwapPositionsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.SwapPositions`,
            request: [{
                "idOne": request.IDOne,
                "idTwo": request.IDTwo,
                "positionOne": request.PositionOne,
                "positionTwo": request.PositionTwo
            }]
        });
        return {
            success: response.success
        }
    },
    async ArchiveOne(request: ArchiveOneRequest): Promise<ArchiveOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.ArchiveOne`,
            request: [{
                "id": request.ID
            }]
        });
        return {
            KanCard: response.KanCard
        }
    },
    async FindFilter(request: FindFilterRequest): Promise<Record<string, Record<string, any>>> {
        // console.log(request)
        let response
        try {
            response = await PerformJSONRPCRequest({
                api: API.Authorised,
                method: `Filter-Store.FindOne`,
                request: [{
                    "user_id": request.userID,
                    "org_id": request.orgID
                }]
            })
        } catch (e) {
            response = null
            console.log(e)
        }
        return await response;
    },
    async SaveFilter(request: SaveFilterRequest): Promise<SaveFilterResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Filter-Store.UpsertOne`,
            request: [{
                "filter": {
                    "user_id": request.UserId,
                    "org_id": request.OrgId,
                    "name": request.Name,
                    "filter": request.Filter,
                    "chips": request.Chips.toString()
                }
            }]
        })
        return await response
    },
    async FindHomeTopWidgetValues(request: FindHomeTopWidgetValuesRequest): Promise<FindHomeTopWidgetValuesResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindData`,
            request: [{
                "clientName": request.ClientName,
                "userID": request.UserId
            }]
        })
        // console.log("response", response)
        return await response

    },
    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        // console.log("Request: ", request)
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [
                {
                    "id": request.id,
                    "org": request.Org
                }
            ],
        });
        // console.log("Response:", response)
        return response
    },

    async AssignGoals(request: AssignGoalsRequest): Promise<string> {
        let tempAssign = ""

        // if no goals are assigned, just assign the new one
        if (request.assignGoals == null || request.assignGoals.length < 1) {
            //No goal to assign, make it a blank new one
            tempAssign = '[""]'
        } else {
            // goals already exist, extract, check for duplicates, and add new goal
            let tempAssigns = JSON.parse(`{"GoalID": "${request.assignGoals.toString()}" }`)['GoalID']
            tempAssign = '["' + (tempAssigns.toString()).replaceAll(',', '", "') + '"]'
        }

        try {
            await KanbanStore.UpdateMultiple({
                ID: '("' + request.cardID + '")',
                organisation: request.organisation,
                columnName: "goal_id",
                valueChange: tempAssign,
                UserId: request.userID
            })
        } catch (e) {
            console.error(`${e}`);
        }
        if (tempAssign == '[""]') {
            return ""
        }
        return tempAssign
    },
    async AssignMultiGoals(request: AssignMultiGoalsRequest): Promise<string> {
        let tempAssign = ""

        // if no goals are assigned, just assign the new one
        if (request.assignGoals == null || request.assignGoals.length < 1 ) {
            //No goal, make it a blank new one
            tempAssign = '[""]'
        } else {
            // goals already exist, extract, check for duplicates, and add new goal
            let tempAssigns = JSON.parse(`{"GoalID": "${request.assignGoals.toString()}" }`)['GoalID']
            tempAssign = '["' + (tempAssigns.toString()).replaceAll(',', '", "') + '"]'
        }

        if (request.cardIDs.length < 1) {
            // No cards to update
            return tempAssign
        }
        let tempCardIDs = "("
        request.cardIDs.map((val, index) => {
            if (index > 0) {
                tempCardIDs += ", "
            }
            tempCardIDs += "'" + val + "'"
        })
        tempCardIDs += ")"

        try {
            await KanbanStore.UpdateMultiple({
                ID: tempCardIDs,
                organisation: request.organisation,
                columnName: "goal_id",
                valueChange: tempAssign,
                UserId: request.userID
            })
        } catch (e) {
            console.error(`${e}`);
        }
        if (tempAssign == '[""]') {
            return ""
        }
        return tempAssign
    },
    async SendToExternal(request: SendToExternalRequest): Promise<SendToExternalResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.SendToExternal`,
            request: [
                {
                    "opportunityID": request.opportunityID,
                    "clientName": request.clientName
                }
            ],
        });
        // console.log("Response:", response)
        return response
    }
};

export default Store;
