import {Drawer} from "@mui/material";
import {ClickAwayListener, Divider, List, ListItem, ListItemIcon, ListItemText, Slide} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useEffect, useState} from "react";
import {Box} from "@mui/system";
import {useTheme} from "@mui/material/styles";
import Filter from "./Filter";
import {FilterItem} from "../../../../cog/keystone/filter/Filter";
import {useSnackbar} from "notistack";
import {GoalStore} from "../../../../cog/keystone/goals";
import {CampaignStore} from "../../../../cog/keystone/goals/campaigns";
import {useMainContext} from "../../../../contexts/MainContext";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";

interface FilterDrawerProps {
    userID: string
    organizationId: string
    client: string

    filters: FilterItem[]
    setFilters: (f: FilterItem[]) => void
    setLevelMode: (b: boolean) => void

    open: boolean
    setOpen: (o: boolean) => void
    onClose: () => void
    onClear: () => void
}

const useStyles = makeStyles({
    drawerPaper: {
        marginTop: "7vh"
    }
});

export default function FilterAsDrawer(props: FilterDrawerProps) {
    const keystoneCtx = useKeystoneContext()
    const ctx = useMainContext()
    const tempTheme = useTheme()
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const [allGoals, setAllGoals] = useState<any[]>(null)
    const [campaigns, setCampaigns] = useState<any[]>(null)
    const [loaded, setLoaded] = useState<boolean>(false)

    const summonData = () => {
        const fetchAllGoals = async () => {
            const response = await GoalStore.FindAll({clientName: ctx.activeOrganization.name})
            if (response) {
                const groupedGoals = response.goals.map((goal) => {
                    if (goal.campaignID === "" || goal.campaignID === "[]") {
                        return {...goal, campaignID: "Unattached"};
                    }
                    return goal;
                })
                setAllGoals(groupedGoals)
                return response.goals
            }
        }
        const getCampaignGoals = async () => {
            const response = await CampaignStore.FindMany({clientName: ctx.activeOrganization.name})
            if (response) {
                setCampaigns(response.campaigns)
            }
        }
        getCampaignGoals()
        fetchAllGoals()
        setLoaded(true)
    }

    useEffect(() => {
        summonData()
    }, []);

    const handleClickAway = () => {
        props.onClose()
        if (keystoneCtx.filterHasChanged == true) {
            enqueueSnackbar('Filter Updated', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
        }
    };

    return (
        <Drawer
            //disableEnforceFocus required to open chat if Dialog opened first
            disableEnforceFocus
            anchor="right"
            open={props.open}
            onClose={handleClickAway}
            classes={{paper: classes.drawerPaper}}>
        {/*<ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>*/}
        {/*<Slide direction="right" in={props.open} mountOnEnter unmountOnExit>*/}
            <Box
                //className required for CogSpeedDial positioning
                className={"PopoutSideBar"}
                sx={{
                // width: 150,
                // maxWidth: 400,
                backgroundColor: "background.default",
                // position: "absolute",
                // borderRadius: tempTheme.shape.borderRadius,
                zIndex: 1000,
                height: "100%",
                padding: 2,
                // boxShadow: tempTheme.shadows[3],
            }}>
                {loaded ? <Filter setFilters={props.setFilters}
                        setLevelMode={props.setLevelMode}
                        filters={props.filters}
                        open={props.open}
                        setOpen={props.setOpen}
                        onClose={props.onClose}
                        client={props.client}
                        organizationId={props.organizationId}
                        userID={props.userID}
                        onClear={props.onClear}
                        allGoals={allGoals}
                        allCampaigns={campaigns}
                /> : null}
            </Box>
        </Drawer>
        // <Slide direction="right" in={props.open} mountOnEnter unmountOnExit>
        //     <Box sx={{
        //         // width: 150,
        //         // maxWidth: 400,
        //         backgroundColor: "background.default",
        //         border: "1px solid",
        //         position: "absolute",
        //         borderRadius: tempTheme.shape.borderRadius,
        //         zIndex: 1000,
        //         height: "84vh",
        //         padding: 2,
        //         boxShadow: tempTheme.shadows[3],
        //     }} >
        //         <Filter setFilters={props.setFilters}
        //                 setLevelMode={props.setLevelMode}
        //                 filters={props.filters}
        //                 open={props.open}
        //                 setOpen={props.setOpen}
        //                 onClose={props.onClose}
        //                 client={props.client}
        //                 organizationId={props.organizationId}
        //                 userID={props.userID}
        //                 onClear={props.onClear}
        //         />
        //     </Box>
        // </Slide>
        // </Slide>
        // </ClickAwayListener>
    );
}