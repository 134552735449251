import * as fs from 'fs';
import * as cpexcel from 'xlsx';
import {Readable} from 'stream';
import {async} from "@firebase/storage/dist/src/implementation/async";

var XLSX = require("xlsx");
XLSX.set_fs(fs);
XLSX.stream.set_readable(Readable);
XLSX.set_cptable(cpexcel);


let counter = 0

const exportAllExcel = (data: any) => {
    let wb = XLSX.utils.book_new()
    // console.log(data)
    Object.keys(data).map((k, i, v) => {
        let tempRows = []
        if (data[k] && data[k].data) {
            counter++
            Object.keys(data[k].data).map((dk, index) => {
                tempRows.push(data[k].data[dk])
            })
            // console.log("tempRows", tempRows[0])
        }
        if (tempRows[0]) {
            tempRows = tempRows[0].map((_, pageIndex) => tempRows.map(row => row[pageIndex]));
            tempRows.unshift(Object.keys(data[k].data))
            // console.log("tempRows ", tempRows)
            exportToExcel(data[k].name, tempRows)
        }

            function exportToExcel(SheetNames, rows) {
                var processRow = function (row) {
                    var finalVal = '';
                    for (var j = 0; j < row.length; j++) {
                        var innerValue = row[j] === null ? '' : row[j].toString();
                        if (row[j] instanceof Date) {
                            innerValue = row[j].toLocaleString();
                        }
                        ;
                        var result = innerValue.replace(/"/g, '""');
                        if (result.search(/("|,|\n)/g) >= 0)
                            result = '"' + result + '"';
                        if (j > 0)
                            finalVal += ',';
                        finalVal += result;
                    }
                    return finalVal + '\n';
                };

                var excelFile = '';
                for (var i = 0; i < rows.length; i++) {
                    excelFile += processRow(rows);
                }

                let ws = XLSX.utils.aoa_to_sheet(rows);


                // if (SheetNames.length > 31) {


                XLSX.utils.book_append_sheet(wb, ws, (counter.toString() + SheetNames).slice(0, 30));



                // } else {
                //     XLSX.utils.book_append_sheet(wb, ws, SheetNames)
                // }
            }

    })
    console.log("wb", wb)
        if (wb.SheetNames.length > 0) {
            XLSX.writeFile(wb, "Report.xls");
        } else {
            alert("Please select filters")
        }

    counter = 0
}
















export default exportAllExcel