import type { FC } from 'react';
import * as React from "react";

import {WidgetProps} from "../../../WidgetSwitcher";
import QuickStats from "../../../../../../../components/QuickStats";
import BasicWidget from "../BasicWidget";
import QuickStatsDate from "../../../../../../../components/QuickStatsDate";
import {Box, CircularProgress, Grid} from "@mui/material";

const BasicDateDisplayInst: FC<WidgetProps> = React.memo( ({widget, widgetData, widgetVariables}) => {
    const title = widget.arguments && widget.arguments.templates.title &&
        widget.arguments.templates.title.value

    let dateDisplay: number = widget.arguments && widget.arguments.templates.dateDisplay &&
        widget.arguments.templates.dateDisplay.value

    if (widget.arguments && widget.arguments.templates.dateDisplay && widget.arguments.templates.dateDisplay.keyOrIndex ) {
        dateDisplay = widgetData && widgetData[widget.arguments.templates.dateDisplay.keyOrIndex]
    }

    return (
          <div>
              <QuickStatsDate
                title={title ? title : widget.name}
                value={dateDisplay}
              />
          </div>
    );
})

const BasicDateDisplay: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<BasicDateDisplayInst />}/>
};

export default BasicDateDisplay;
