import {FC, useEffect, useState} from "react";
import {Box, Tooltip, Typography} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {GridToolbar} from "@mui/x-data-grid-pro";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {StyledDataGrid} from "../../listview/CustomDataGridTheme";
import * as React from "react";

interface GenericDataGridComponentProps {
    GenericData: any[]
    RowsPerPageOptions?: number[]
    pagination?: boolean
    pageSize?: number
    enableSorting?: boolean
    enableMenu?: boolean
}

/** GenericDataGridComponent :
 *      Receives an array of key[string] value pairs, and creates a generic DataGrid for it
 *      Requires the parent of this element to set the height and width
 * */
const GenericDataGridComponent: FC<GenericDataGridComponentProps> = (props) => {

    const [gridColumns, setGridColumns] = useState<GridColDef[]>(null)
    const [gridRows, setGridRows] = useState<any[]>(null)
    const [pageSize, setPageSize] = useState<number>(props.pageSize != null ? props.pageSize : 10)

    // MakeColumns : uses the key values of the first rows attributes to create columns for the data grid
    const MakeColumns = () => {
        let tempGridColumns: GridColDef[] = []
        for (const [key, value] of Object.entries(props.GenericData[0])) {
            tempGridColumns.push({
                field: key,
                sortingOrder: ['desc', 'asc'],
                sortable: props.enableSorting != null ? props.enableSorting! : false,
                hideSortIcons: props.enableSorting != null ? props.enableSorting! : true,
                flex: 1,
                // disableColumnMenu: props.enableMenu != null ? props.enableMenu! : true,
                // disableReorder: props.enableMenu != null ? props.enableMenu! : true,
                // renderHeader: (params) => <Typography
                //     variant={"h6"}
                //     sx={{
                //         // fontSize: "0.75rem"
                //     }}
                // >
                //     {params.field}
                // </Typography>,
                renderCell: (params) => <Tooltip title={params.value != null ? params.value : "empty"}>
                    <Typography
                        variant={"body2"}
                        sx={{
                            // fontSize: "0.5rem"
                        }}
                    >
                        {params.value != null ? params.value : "-"}
                    </Typography>
                </Tooltip>
            })
        }
        setGridColumns(tempGridColumns)
    }
    const MakeRows = () => {
        if (props.GenericData[0]["id"] == null) {
            let tempRows: any[] = []
            for (let i = 0; i < props.GenericData.length; i++) {
                tempRows.push({...props.GenericData[i], id: i})
            }
            setGridRows(tempRows)
        } else {
            setGridRows(props.GenericData)
        }

    }

    useEffect(() => {
        if (props.GenericData != null) {
            MakeColumns()
            MakeRows()
        }
    }, [props.GenericData]);



    return <Box sx={{
        height: "inherit",
        width: "inherit"
    }}>
        {gridRows != null && gridColumns != null && <StyledDataGrid
            columns={gridColumns}
            rows={gridRows}
            getRowHeight={() => 'auto'}
            disableDensitySelector={true}
            checkboxSelection={false}
            rowsPerPageOptions={ props.RowsPerPageOptions == null ? [10] : props.RowsPerPageOptions}
            pageSize={ pageSize}
            pagination={props.pagination != null ? props.pagination: true}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ Toolbar: GridToolbar }}
        />}
    </Box>
}

export default GenericDataGridComponent