import * as React from 'react';
import {FC, useContext} from 'react';
import Chart from 'react-apexcharts';
import {styled, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {ApexOptions} from "apexcharts";
import SettingsContext from "../../../../contexts/SettingsContext";

const StyledBox = styled('div')(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        minHeight: 500, minWidth: 500
    }, [theme.breakpoints.down('sm')]: {
        minHeight: 400, minWidth: 300
    }
}));

interface dataPoint {
    x: string;
    y: number;
}

interface LandingTreeProps {
    data: {};
}

const TreeMap: FC<LandingTreeProps> = ({data}) => {
    const settingsCtx = useContext(SettingsContext)
    const chartType = "treemap"
    const seriesData = {
        data: data
    }
    
    const series = []
    series.push(seriesData)
    
    const chartOptions: ApexOptions = {
        chart: {
            type: "treemap",
            foreColor: useTheme().palette.text.primary, width: 500, height: 500,
            
        },
        colors: ['#f2bc53'],
        tooltip: {
            y: {
                formatter: function (val) {
                    return val.toString()
                },
                
            },
            theme: settingsCtx.settings.theme === 'LIGHT' ? 'light' : 'dark'
        },
        plotOptions: {
            treemap: {
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
                /*colorScale: {
                    ranges: [
                        {
                            from: 0,
                            to: 1000,
                            color: '#f45c5c'
                        },
                        {
                            from: 1000,
                            to: 100000000,
                            color: '#f2bc53'
                        }
                    ]
                }*/
            }},
        dataLabels: {
            enabled: true, style: {
                fontSize: '14px',
                fontWeight: 'bold'
            }
            , offsetY: -4,
        }, responsive: [{
            breakpoint: 480, options: {
                legend: {
                    // show: false
                },
            }
        }]
    };
    
    if (Object.keys(data).length > 0) {
        return (<StyledBox>
            <Chart
                type={chartType}
                series={series}
                options={chartOptions}
                height={"100%"}
                width={"100%"}
            />
        </StyledBox>);
    } else {
        return (<StyledBox>
            <Typography>
                No Data To Display
            </Typography>
        </StyledBox>);
    }
    
};

export default TreeMap;