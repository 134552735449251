import * as Yup from 'yup';
import { Form, Field, Formik } from "formik";
import type {FC} from 'react';
import {useSnackbar} from 'notistack';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField, Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router";
import WidgetStore from "../../../../../cog/bitool/widget/Store";
import Query from "../../../../../cog/bitool/query/Query";
import QueryStore from "../../../../../cog/bitool/query/Store";

interface WidgetCreatorDialogProps {
  handleDialogClose: () => void
}

const WidgetCreatorDialog: FC<WidgetCreatorDialogProps> = ({handleDialogClose}) => {
  let { dashId} = useParams();
  if (!dashId) {
    return <div> No ID found </div>
  }

  const {enqueueSnackbar} = useSnackbar();
  let navigate = useNavigate();
  const [queries, setQueries] = React.useState<Query[]>([])

  const fetchAllQueries = useCallback(async () => {
    let response = await QueryStore.FindAll({})
    setQueries(response.queries)
  }, [])

  useEffect(() => {
    fetchAllQueries()
  }, [queries, fetchAllQueries])

  return (
    <Card>
      <Formik
        enableReinitialize
        initialValues={{
          name: "New Widget",
          picked: "new query",
          selectedQueryID: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Name is required"),
          picked: Yup.string(),
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ): Promise<void> => {
          try {
            if (values.picked == 'new query') {
              let newQuery = await QueryStore.CreateOne({
                name: "New Query",
                templatedQuery: "Select * from {{A_TABLE}}"
              })

              if (newQuery) {
                let response = await WidgetStore.CreateOne({
                  widget: {
                    name: values.name,
                    dashboardId: dashId,
                    arguments: {type: "BasicTable", location: {h: 3, w: 3, x: 3, y: 11, moved: false, static: false}, executorArgs: {queryId: newQuery.id, connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc"}},
                  }
                })

                if (response) {
                  setStatus({ success: true });
                  enqueueSnackbar("Widget Created", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "success",
                  });
                  navigate('/app/bi/widgetedit/' + dashId + "/" + newQuery.id + "/" + response.id)
                } else {
                  setStatus({ success: false });
                  enqueueSnackbar("Failed to Create Widget!", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "error",
                  });
                }

                resetForm();
                setSubmitting(false);
                handleDialogClose();
              } else {
                console.log("Error")
              }
            } else if (values.picked == 'no query') {
              let response = await WidgetStore.CreateOne({
                widget: {
                  name: values.name,
                  dashboardId: dashId,
                  arguments: {type: "BasicTable", location: {h: 3, w: 3, x: 3, y: 11, moved: false, static: false}, executorArgs: {queryId: '00000000-0000-0000-0000-000000000000', connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc"}},
                }
              })

              if (response) {
                setStatus({ success: true });
                enqueueSnackbar("Widget Created", {
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                  },
                  variant: "success",
                });
                navigate('/app/bi/widgetedit/' + dashId + "/00000000-0000-0000-0000-000000000000/" + response.id)
              } else {
                setStatus({ success: false });
                enqueueSnackbar("Failed to Create Widget!", {
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                  },
                  variant: "error",
                });
              }

              resetForm();
              setSubmitting(false);
              handleDialogClose();
            } else if (values.picked == 'select query') {
              let response = await WidgetStore.CreateOne({
                widget: {
                  name: values.name,
                  dashboardId: dashId,
                  arguments: {type: "BasicTable", location: {h: 3, w: 3, x: 3, y: 11, moved: false, static: false}, executorArgs: {queryId: values.selectedQueryID, connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc"}},
                }
              })

              if (response) {
                setStatus({ success: true });
                enqueueSnackbar("Widget Created", {
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                  },
                  variant: "success",
                });
                navigate('/app/bi/widgetedit/' + dashId + "/" + values.selectedQueryID + "/" + response.id)
              } else {
                setStatus({ success: false });
                enqueueSnackbar("Failed to Create Widget!", {
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                  },
                  variant: "error",
                });
              }

              resetForm();
              setSubmitting(false);
              handleDialogClose();
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader title="Create Widget" />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-evenly"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Box>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item>
                              <label>
                                <Field type="radio" name="picked" value="new query" />
                                <Typography>
                                  New Query
                                </Typography>
                              </label>
                            </Grid>
                            <Grid item>
                              <label>
                                <Field type="radio" name="picked" value="no query" />
                                <Typography>
                                  No Query
                                </Typography>
                              </label>
                            </Grid>
                            <Grid item>
                              <label>
                                <Field type="radio" name="picked" value="select query" />
                                <Typography>
                                  Select Query
                                </Typography>
                              </label>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    {values.picked == "select query" &&
                      <Grid item>
                        <Autocomplete
                          id="selectedQueryID"
                          sx={{height: "100%"}}
                          options={ queries.map((q) => q.name + " | " + q.id)}
                          style={{ width: 300 }}
                          onChange={(e, value) => {
                            console.log(value);

                            let val
                            try {val = value.split(" | ")[1]}
                            catch (err){ val = value}

                            setFieldValue(
                              "selectedQueryID",
                              val !== null ? val : ""
                            );
                          }}
                          renderInput={params => (
                            <TextField
                              margin="normal"
                              label="Search Queries"
                              fullWidth
                              name="queries"
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                    }
                  </Grid>
                  <Grid item md={6} xs={12}>

                  </Grid>
                </Grid>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Create
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    </Card>
  );
}

export default WidgetCreatorDialog;