import type {FC} from 'react';
import React from 'react';
import logoPath from "./WutowLogoIcon.svg"

interface WutowLogoProps {
    [key: string]: any;
}

const WutowLogo: FC<WutowLogoProps> = (props) => (
    <img
        alt="Wutow Logo"
        src={logoPath}
        height={66}
        width={66}
        style={{
            margin: 1
        }}
        {...props}
    />
);

export default WutowLogo;