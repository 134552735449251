/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import History, {CreateHistory} from "./History";

export interface FindOneRequest {
    id:   string
}

export interface FindOneResponse {
    history: History[]
}

export interface CreateOneRequest {
    history: CreateHistory
}

export interface CreateOneResponse {
    id: string
}

const Store = {
    serviceProvider: 'History-Store',
    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                "id": request.id
            }]
        });
        return response.history
    },
    async FindMany(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindMany`,
            request: [{
                "itemId": request.id
            }]
        });
        if (response.history != null) {
            return {
                'history': response.history.map((mem) => (new History(mem)))
            }
        }
        return null;
    },


    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                "history": request.history,
            }]
        })

        if (response.message == "record not found") {
            return null
        }
        return response
    }
};

export default Store;