import type {FC} from 'react';
import React from 'react';
import logoPath from "../ksLogoDark.svg"

interface LogoDarkProps {
    [key: string]: any;
}

const LogoDark: FC<LogoDarkProps> = (props) => (
    <img
        alt="Logo"
        src={logoPath}
        width={180}
        style={{
            marginRight: 29,
            marginLeft: 29,
            marginTop: 1
        }}
        {...props}
    />
);

export default LogoDark;