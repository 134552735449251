import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import { Card as KanbanCardObj, KanbanStore } from "../../../../../../cog/keystone/card";
import { useMainContext } from "../../../../../../contexts/MainContext";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Typography, CircularProgress, Checkbox, CardHeader
} from "@mui/material";
import { indexOf } from "lodash";
import Goal from "../../../../../../cog/keystone/goals/goal/Goal";
import { GoalStore, GoalOpportunityStore } from "../../../../../../cog/keystone/goals";
import {CheckBoxOutlineBlank, CheckBoxOutlined} from "@mui/icons-material";

interface GoalDialogProps {
  loadingGoal: boolean;
  issueIdsToAssign: string[]
  // theIssue: KanbanCardObj[];
  setLoadingGoal: (state: boolean) => void;
  onClick: ()=>void;
  setIssueGoals? : (goals:Goal[]) => void

}

const GoalDialog: FC<GoalDialogProps> = ({loadingGoal, issueIdsToAssign, setLoadingGoal, onClick, setIssueGoals}) => {
  const mainCtx = useMainContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [selectedGoals, setSelectedGoals] = useState<Goal[]>([]);

  const fetchGoals = async () => {
    setIsLoading(true)

    const response = await GoalStore.FindAll({clientName: mainCtx.activeOrganization.name})
    if (response) {
      setGoals(response.goals)
    }

    setIsLoading(false)
  }
  const selectedGoalToStringId = () => {
    let tempGoalIDs :string[] = []
    selectedGoals.forEach( (goal)=> {
      tempGoalIDs.push(goal.id)
    })
    return tempGoalIDs

  }

  useEffect(()=> {
    fetchGoals()
  }, [])

  return (
    <Box>
      <DialogTitle>Add Opportunity to Goal</DialogTitle>
      <DialogContent>
        {isLoading == false &&
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item sx={{
              mt: 1,
              mb: 5,
              flex: 1

            }}>
              {goals  != null ?
                <Autocomplete
                  value={selectedGoals}
                  onChange={(event, newValue) => {
                    let tempGoals: Goal[] = []
                    newValue.forEach((goal)=> tempGoals.push(goal as Goal) )
                    setSelectedGoals(tempGoals);
                  }}
                  multiple={true}
                  id="select-goal-autocomplete"
                  options={goals}
                  limitTags={2}
                  getOptionLabel={(option: Goal) => option.name || ""}
                  renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                            checked={selected}
                        />
                        <CardHeader
                            title={option.name} disableTypography={true}
                            sx={{padding: 0}}
                            key={`GoalOption_${option.id}`}
                        >
                        </CardHeader>
                      </li>
                  )}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Goals" value={selectedGoals} />
                  )}
                /> : <Box>
                  <Typography>No Goals Found</Typography>
                </Box>
              }
            </Grid>
          </Grid>
        }
        <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
          {isLoading? <Box sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              gap: "10px",
              px: 0.2}}>
              <CircularProgress/>
            </Box>
            :
            <ButtonGroup>
              <Button
                onClick={() => {
                  setLoadingGoal(true)
                  setIsLoading(true)
                  console.log("SelectedGoal", selectedGoals)
                  GoalOpportunityStore.LinkMultiple({
                    goalIDs: selectedGoalToStringId(),
                    opportunityIDs: issueIdsToAssign,
                    clientName: mainCtx.activeOrganization.name,

                  }).then(() => {
                      setLoadingGoal(false)
                      setIsLoading(false)
                    if(typeof setIssueGoals != 'undefined'){
                      setIssueGoals(selectedGoals)
                    }
                      onClick()
                    })
                }}
                variant="text"
                disabled={isLoading}
              >
                Save Changes
              </Button>
              <Button variant="text" onClick={onClick}>
                Close
              </Button>
            </ButtonGroup>
          }
        </Box>
      </DialogContent>
    </Box>
  );
}

export default GoalDialog;