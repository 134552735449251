/* eslint-disable */
import React, {useState, useEffect, FC, useCallback, useLayoutEffect} from 'react';
import { Box, Button, Dialog, List, ListItem, ListItemText } from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardStore from "../../../../../cog/bitool/dashboard/Store";
import {useNavigate} from "react-router-dom";
import Dashboard from "../../../../../cog/bitool/dashboard/Dashboard";
import {boolean} from "yup";
import { Helmet } from "react-helmet-async";
import { Title } from "@mui/icons-material";
import DashboardCreatorDialog from "./DashboardCreatorDialog";
import GlobalVariablesForm from "./GlobalVariablesForm";
import { useMainContext } from "../../../../../contexts/MainContext";
import { useParams } from "react-router";

interface DashboardChooserProps {}

let dialogBody = () => {
    return (
      <div>
      </div>
    )
};

const DashboardChooser: FC<DashboardChooserProps> = () => {
    let { orgId } = useParams();
    let navigate = useNavigate()

    const [dashboards, setDashboards] = React.useState<Dashboard[]>([]);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const fetchAllDashboards = useCallback(async () => {
        let response = await DashboardStore.FindAll({organizationId: orgId})
        setDashboards(response.dashboards)
    }, [])

    useLayoutEffect(() => {})

    useEffect(() => {
        fetchAllDashboards()
    }, [fetchAllDashboards])

    const handleClick = (itemId) => {
        console.log(itemId)
        navigate('/app/bi/dashboard/' + itemId)
    }

    const handleNavBack = () => {
        navigate('/app/bi/dashchoose')
    }

    const handleDialogOpen = () => {
        dialogBody = () => {
            return (
              <DashboardCreatorDialog handleDialogClose={handleDialogClose}/>
            );
        }
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSetGlobalVariables = () => {
        dialogBody = () => {
            return (
              <GlobalVariablesForm handleDialogClose={handleDialogClose} organizationId={orgId} />
            );
        }
        setDialogOpen(true);
    };

    return (
        <div style={{height: "100%", width: "100%"}}>
            <Box>
                <Box>
                    <Button variant="text" onClick={() => handleDialogOpen()} >
                        Create New Dashboard
                    </Button>
                    <Button variant="text" onClick={() => handleSetGlobalVariables()}>
                        Set Global Variables
                    </Button>
                    <Button variant="text" onClick={() => handleNavBack()}>
                        Back to Organizations
                    </Button>
                </Box>
                <Box>
                    <List component="nav">
                        { dashboards.map((q) => (
                            <ListItem button key={q.id} onClick={() => handleClick(q.id)}>
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={q.name}
                                    secondary={q.id}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
            <Dialog
              open={dialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="md"
            >
                {dialogBody()}
            </Dialog>
        </div>
    );
};

export default DashboardChooser;









