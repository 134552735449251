import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FC, useCallback, useEffect} from "react";
import Widget, {Location, Template, WidgetArguments} from "../../../../../cog/bitool/widget/Widget";
import {
    Autocomplete,
    Box,
    ButtonGroup,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Typography
} from "@mui/material";
import { ApexColumnCode, ApexPieCode, ApexLineCode, DateRangeCode, SingleDateCode, ApexHeatmapCode, ApexScatterCode } from "./WidgetCode";
import { LabelTemplates, DateLabelTemplates, ImageTemplates } from "./WidgetTemplates";
import { ArgumentStore } from "../../../../../cog/bitool/query";
import { WidgetTypes } from "./WidgetTypes";

interface FormDialogProps {
    label: string;
    onAdd: any;
    allWids: Widget[];
    queryID: string;
    setCode;
}

const WidgetFormDialog: FC<FormDialogProps> = ({label, onAdd, allWids, queryID, setCode}) => {
    const [open, setOpen] = React.useState(false);
    const [numWid, setNumWid] = React.useState<number>(0)
    const [qArgs, setQArgs] = React.useState<string>("")
    const [qID, setQID] = React.useState<string>("")
    const [widgetType, setWidgetType] = React.useState("")

    useEffect(()=>{
        if (queryID && queryID != '00000000-0000-0000-0000-000000000000') {
            fetchQueryArgs(queryID)
        }
    },[])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleQuerySearch = (event) => {
        event.preventDefault()
        fetchQueryArgs(event.target.elements.QueryID.value)
    }

    const fetchQueryArgs = useCallback(async (val) => {
        setQID(val)
        let response
        try { response = await ArgumentStore.FindAll({
            queryId: val
            })}
        catch {response= "No Query Found"}
        if (response.queryArguments) {

            setQArgs(response.queryArguments[0].argument)
        }
        else {
            setQArgs("")
            setNumWid(0)
        }
    }, [])

    useEffect( () => {

        try {
            let len = Object.keys(JSON.parse(qArgs)).length
            setNumWid(len)}
        catch {return}},
        [qArgs])

    const handleWidgetTypeChange = (event) => {
        setWidgetType(event.target.value)
    };

    const handleSubmit = (event)=> {
        event.preventDefault()

        let nWidArgs: WidgetArguments

        switch(widgetType) {
            case 'Table':

                nWidArgs = {
                    type: "BasicTable",
                    code: "",
                    templates: {},
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
            break;

            case 'Autocomplete Filter':
                nWidArgs = {
                    type: "BasicAutoCompleteFilter",
                    code: "",
                    templates: {},
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
            break;

            case 'Single Date':
                nWidArgs = {
                    type: "BasicDate",
                    code: SingleDateCode,
                    templates: {},
                }
                setCode(SingleDateCode)
                break;

            case 'Date Range':
                nWidArgs = {
                    type: "BasicDateRange",
                    code: DateRangeCode,
                    templates: {},
                }
                setCode(DateRangeCode)
            break;

            case 'Date Display':
                nWidArgs = {
                    type: "BasicDate",
                    code: "",
                    templates: DateLabelTemplates,
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
            break;

            case 'Label':
                nWidArgs = {
                    type: "BasicLabel",
                    code: "",
                    templates: LabelTemplates,
                }
            break;

            case 'Single Value Indicator':
                nWidArgs = {
                    type: "BasicQuickStats",
                    code: "",
                    templates: {},
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
            break;

            case 'Widget Group':
                nWidArgs = {
                    type: "BasicWidgetGroup",
                    code: "",
                    templates: {},
                    group: [],
                    orientation: "horizontal"
                }
            break;

            case 'Drilldown':
                nWidArgs = {
                    type: "BasicWidgetGroupStepper",
                    code: "",
                    templates: {},
                    group: [],
                    orientation: "vertical"
                }
            break;

            case 'Apex Column Chart':
                nWidArgs = {
                    type: "BasicApexChart",
                    code: ApexColumnCode,
                    templates: {},
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
                setCode(ApexColumnCode);
            break;

            case 'Apex Pie Chart':
                nWidArgs = {
                    type: "BasicApexChart",
                    code: ApexPieCode,
                    templates: {},
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
                setCode(ApexPieCode);
            break;

            case 'Apex Line Chart':
                nWidArgs = {
                    type: "BasicApexChart",
                    code: ApexLineCode,
                    templates: {},
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
                setCode(ApexLineCode)
            break;

            case 'Apex Scatter Chart':
                nWidArgs = {
                    type: "BasicApexChart",
                    code: ApexScatterCode,
                    templates: {},
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
                setCode(ApexScatterCode)
            break;

            case 'Apex Bullet Chart':
                nWidArgs = {
                    type: "BasicApexChart",
                    code: "",
                    templates: {},
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
            break;

            case 'Apex Heatmap':
                nWidArgs = {
                    type: "BasicApexChart",
                    code: ApexHeatmapCode,
                    templates: {},
                    executorArgs: {connectorId: "25d23fd2-ef01-47e6-b77e-8f65bee34bfc", queryId: qID}
                }
                setCode(ApexHeatmapCode)
            break;

            case 'Line Divider':
                nWidArgs = {
                    type: "BasicLineDivider",
                    code: "",
                    templates: {},
                }
            break;

            case 'Vertical Line Divider':
                nWidArgs = {
                    type: "BasicVerticalLineDivider",
                    code: "",
                    templates: {},
                }
            break;

            case 'Image':
                nWidArgs = {
                    type: "BasicImage",
                    code: "",
                    templates: ImageTemplates,
                    orientation: "horizontal"
                }
            break;
        }

        if (widgetType == "Date Range" || widgetType == "Single Date" || widgetType == "Label" || widgetType == "Widget Group" || widgetType == "Date Display" || widgetType == "Line Divider" || widgetType == "Vertical Line Divider" || widgetType == "Image") {
            nWidArgs.location = {x: 0, y: 0, h: 3, w: 3}
            onAdd(JSON.stringify(nWidArgs, null, "\t"))
            handleClose()
        } else if (widgetType == "Autocomplete Filter") {
            let allTemplates : Record<string, Template> = {}
            let userTemplate: Template = {value: ""}
            let multiTemplate: Template = {value: "true"}
            let nWidTemplate : Template
            let widLoc: Location = {x: 0, y: 0, h: 3, w: 3}

            allTemplates["jwtUser"] = userTemplate
            allTemplates["Multiple"] = multiTemplate

            Array.from({length: numWid}, (_, i) => i ).map((i)=> {
                let wI
                try { wI =event.target.elements["WidgetID" +i].value.split(" | ")[1] }
                catch {}
                let key = event.target.elements["KeyName"+ i].value
                nWidTemplate = {
                    value: event.target.elements["WidValue"+ i].value,
                    widgetId: wI,
                    keyOrIndex: event.target.elements["keyOrIndex"+ i].value,
                    serializerStringFunction: event.target.elements["radioSerialzer" + i].value
                }
                allTemplates[key.toString()] = nWidTemplate
            })

            nWidArgs.templates = allTemplates
            nWidArgs.location = widLoc
            onAdd(JSON.stringify(nWidArgs, null, "\t"))
            handleClose()
        } else if (widgetType == "Single Value Indicator") {
            let allTemplates : Record<string, Template> = {}
            let titleTemplate: Template = {
                value: "Net Sales Value"
            }
            let valueATemplate: Template = {
                value: "",
                keyOrIndex: "ytd_value_2020",
                serializerStringFunction: "$StringSerializer"
            }
            let valueBTemplate: Template = {
                value: "",
                keyOrIndex: "ytd_value_2021",
                serializerStringFunction: "$StringSerializer"
            }
            let nWidTemplate : Template
            let widLoc: Location = {x: 0, y: 0, h: 3, w: 3}

            allTemplates["title"] = titleTemplate
            allTemplates["valueA"] = valueATemplate
            allTemplates["valueB"] = valueBTemplate

            Array.from({length: numWid}, (_, i) => i ).map((i)=> {
                let wI
                try { wI =event.target.elements["WidgetID" +i].value.split(" | ")[1] }
                catch {}
                let key = event.target.elements["KeyName"+ i].value
                nWidTemplate = {
                    value: event.target.elements["WidValue"+ i].value,
                    widgetId: wI,
                    keyOrIndex: event.target.elements["keyOrIndex"+ i].value,
                    serializerStringFunction: event.target.elements["radioSerialzer" + i].value
                }
                allTemplates[key.toString()] = nWidTemplate
            })

            nWidArgs.templates = allTemplates
            nWidArgs.location = widLoc
            onAdd(JSON.stringify(nWidArgs, null, "\t"))
            handleClose()
        } else {
            let allTemplates : Record<string, Template> = {}
            let nWidTemplate : Template
            let widLoc: Location = {x: 0, y: 0, h: 3, w: 3}

            Array.from({length: numWid}, (_, i) => i ).map((i)=> {
                let wI
                try { wI =event.target.elements["WidgetID" +i].value.split(" | ")[1] }
                catch {}
                let key = event.target.elements["KeyName"+ i].value
                nWidTemplate = {
                    value: event.target.elements["WidValue"+ i].value,
                    widgetId: wI,
                    keyOrIndex: event.target.elements["keyOrIndex"+ i].value,
                    serializerStringFunction: event.target.elements["radioSerialzer" + i].value
                }
                allTemplates[key.toString()] = nWidTemplate
            })

            nWidArgs.templates = allTemplates
            nWidArgs.location = widLoc
            onAdd(JSON.stringify(nWidArgs, null, "\t"))
            handleClose()
        }
    }

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen} >
                {label}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Build Widget</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleQuerySearch} style={{ padding: 2, marginBottom: 10, justifyContent: "center", alignItems: "center"}}>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="QueryID"
                            label="Query ID"
                            type="uuid"
                            fullWidth
                            variant="standard"
                            onChange={() => setQID}
                        />
                        <Button style = {{marginLeft: 200}} variant="contained" type={"submit"}>Search Query</Button>
                    </form>
                    <form onSubmit={handleSubmit}>
                        <FormControl  component="fieldset" style={{width: "100%"}}>
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                            >
                                Widget Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={widgetType}
                              onChange={handleWidgetTypeChange}
                              label="Select Organization"
                              variant="standard"
                              sx = {{marginBottom: 2}}
                            >
                                {WidgetTypes.map((widgetType) => {
                                    return <MenuItem
                                      value={widgetType.name}
                                      key={widgetType.id}>
                                        {widgetType.name}
                                    </MenuItem>
                                })}
                            </Select>
                            { qArgs.length > 0 &&
                              <div style={{ margin: 10}}>
                                  <Typography >Templates</Typography>
                              </div>
                            }
                            { qArgs.length > 0 &&
                                Object.entries(JSON.parse(qArgs)).map((k,i) => {
                                    return <Box key={i} sx={{border: 1, padding: 1, borderRadius: 1, marginTop: 1, marginBottom: 1, justifyContent: "space-around", alignItems: "center"}}>
                                        <TextField sx={{margin: 1}} name={"KeyName" +i} label={"Key Name"} defaultValue={k[0]} key={"KN" + i}/>
                                        <TextField sx={{margin: 1}} name={"WidValue" +i} label={"WidValue"} key={"WV" + i}/>
                                        <Autocomplete
                                            disablePortal
                                            id={"WidgetID" + i}
                                            key={"WI" + i}
                                            sx = {{marginTop: 1, marginBottom: 1, width: "100%", height: "100%"}}
                                            options={allWids}
                                            getOptionLabel={(option: Widget) => option.name + " | " + option.id}
                                            renderInput={(params) => <TextField {...params}  name={"WidgetID" + i}  label="Add Widgets ID" />}
                                        />
                                        <TextField sx={{margin: 1}} name= {"keyOrIndex" + i} label={"KeyOrIndex"} defaultValue={k[0]}/>
                                        <RadioGroup
                                            row
                                            aria-label="type"
                                            defaultValue="BasicTable"
                                            key={"radioSerialzer" + i}
                                            name={"radioSerialzer" + i}
                                        >
                                            <FormControlLabel key={"$StringSerializer"+i} value="$StringSerializer" control={<Radio />} label="StringSerializer" />
                                            <FormControlLabel key={"$StringArraySerializer"+i} value="$StringArraySerializer" control={<Radio />} label="StringArraySerializer" />
                                            <FormControlLabel key={"$DateSerializer"+i} value="$DateSerializer" control={<Radio />} label="DateSerializer" />
                                        </RadioGroup>
                                    </Box>})
                            }
                        </FormControl>
                        <ButtonGroup variant="contained" sx={{justifyContent: "center", alignItems: "center", width: "100%"}}>
                            <Button type="submit">Submit</Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </ButtonGroup>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default WidgetFormDialog;