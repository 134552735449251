import React from "react";
import type { FC } from 'react';
import logoPath from "./RandSymbol.svg"
import './currencyStyles.css'
import useWindowDimensions from "../../../../../../utils/useWindowDimensions";

interface RandSymbolProps {
  [key: string]: any;
}

const RandSymbol: FC<RandSymbolProps> = (props) => {

  return (
    <h1
      // component="div"
      // sx={{
      //   fontSize: {
      //     lg: '2rem',
      //     md: '1.5rem',
      //     sm: '1rem',
      //     xs: '1rem'
      //   },
      //   fontWeight: 'bold'
      // }}
    >
      R
    </h1>
  );
};

export default RandSymbol;