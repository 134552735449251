/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import Filter, {ColumnMapDict} from './Filter';
import {TreeFromAPI} from "../../../pages/app/keystone/productstoreuniverse/utility/NodeTypesAndOptions";

export interface GetAllRequest {
    client: string
    supplierList: string[]
    PSU: TreeFromAPI
}

export interface GetAllResponse {
    filterData: {}
}

export interface FindOneRequest {
    userID: string
    organizationID: string
}

export interface FindOneResponse {
    filter: Filter
}

export interface UpsertOneRequest {
    filter: Filter
}

export interface UpsertOneResponse {
    id: string
}

export interface DeleteOneRequest {
    id: string
}

export interface DeleteOneResponse {
}

export interface FetchColumnMapRequest {
    organisation: string
}

export interface FetchColumnMapResponse {
    ColumnMapDict: ColumnMapDict
}

const Store = {
    serviceProvider: 'Filter-Store',

    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                "userID": request.userID,
                "organizationID": request.organizationID
            }],
        });
        if (response == undefined) {
            let tempResponse: FindOneResponse = {filter: new Filter()}
            return { filter: tempResponse.filter }
        }
        return { filter: response.filter }
    },

    async UpsertOne(request: UpsertOneRequest): Promise<UpsertOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpsertOne`,
            request: [{
                "filter": request.filter
            }],
        });
        return {
            'id': response.id,
        }
    },
    async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOne`,
            request: [{
                "id": request.id
            }],
        });
        return {}
    },

    // todo add psu
    async GetAll(request: GetAllRequest): Promise<GetAllResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.GetAll`,
            request: [{
                "client": request.client,
                "supplierList" : request.supplierList,
                "PSU": request.PSU
            }],
            // controller: request.controller
        });

        if (response.columns != null) {
            return {
                // filter: new Filter(
                "filterData": response.filterData
            }
        }
        // console.log("GetAll", response)
        return response;
    },
    async FetchColumnMap(request: FetchColumnMapRequest): Promise<FetchColumnMapResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FetchColumnMap`,
            request: [{
                "organisation": request.organisation
            }],
        });
        // console.log("Map response:", response)
        return response
        // return { ColumnMapDict: response.ColumnMapDict }
    },

};

export default Store;
