import type {FC} from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import {Box, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {ApexOptions} from "apexcharts";
import currencyFormatter from "../../../../../../utils/currencyFormatter";
import currencySwitcher from "../../../../../../utils/currencySwitcher";

interface StatsBarColumnProps {
    data: {};
    org: string;
}

const StatsBarColumn: FC<StatsBarColumnProps> = ({data, org}) => {
    const chartType = "bar"
    const theme = useTheme();
    let formatter = Intl.NumberFormat('en', {notation: 'compact'})

    let series = []
    if (data && data["f0_"]) {
        data["f0_"].map((user, index) => {
            let barDatapoint = {
                name: user,
                data: data && data["Value"] ? [data["Value"][index]] : [0]
            }
            series.push(barDatapoint)
        })
    }

    const chartOptions: ApexOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            stacked: true,
            stackType: '100%',
            // background: 'transparent'
        },
        colors: ["#00e396", "#feb019", "#ff6178", "#775dd0"],
        dataLabels: {
            formatter: function (val, opt) {
                return currencySwitcher(org) + formatter.format(data["Value"][opt.seriesIndex])
            },
        },
        legend: {
            show: false
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        theme: {
            mode: theme.palette.mode
        },
        fill: {
            opacity: 1
        },
        xaxis: {
            categories: ["Week"],
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return currencySwitcher(org) + formatter.format(val)
                }
            }
        },
    };

    return (
      <Box sx={{height: 170}}>
          <Chart
            type={chartType}
            height={170}
            width={400}
            series={series}
            options={chartOptions}

          />
      </Box>
    );
};

export default StatsBarColumn;