const SKUCode =(props) => {
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg id="Business_Icons" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 203 203" height={height}>
            <defs>
                <style dangerouslySetInnerHTML={{__html: "\n            .SKUCodeCls-1{fill:url(#SKUCodeBlob);}.SKUCodeCls-2{fill:url(#SKUCodeLine);}.SKUCodeCls-3{fill:url(#SKUCodeLine-2);}.SKUCodeCls-4{fill:url(#SKUCodeLine-3);}.SKUCodeCls-5{fill:url(#SKUCodeLine-4);}.SKUCodeCls-6{fill:url(#SKUCodeLine-5);}.SKUCodeCls-7{fill:url(#SKUCodeLine-6);}.SKUCodeCls-8{fill:url(#SKUCodeLine-7);}.SKUCodeCls-9{fill:url(#SKUCodeLine-8);}.SKUCodeCls-10{fill:url(#SKUCodeLine-9);}.SKUCodeCls-11{fill:url(#SKUCodeLine-10);}.SKUCodeCls-12{fill:url(#SKUCodeLine-11);}.SKUCodeCls-13{fill:url(#SKUCodeLine-12);}.SKUCodeCls-14{fill:url(#SKUCodeLine-13);}.SKUCodeCls-15{fill:url(#SKUCodeLine-14);}.SKUCodeCls-16{fill:url(#SKUCodeLine-15);}.iconWhite{fill:#fff;}.SKUCodeCls-18{fill:url(#SKUCodeFill);}.SKUCodeCls-19{fill:url(#SKUCodeFill-2);}.SKUCodeCls-20{fill:url(#SKUCodeFill-3);}.SKUCodeCls-21{fill:url(#SKUCodeFill-4);}.SKUCodeCls-22{fill:url(#SKUCodeFill-5);}.SKUCodeCls-23{fill:url(#SKUCodeLine-16);}.SKUCodeCls-24{fill:url(#SKUCodeLine-17);}.SKUCodeCls-25{fill:url(#SKUCodeLine-18);}.SKUCodeCls-26{fill:url(#SKUCodeLine-19);}.SKUCodeCls-27{fill:url(#SKUCodeLine-20);}.SKUCodeCls-28{fill:url(#SKUCodeLine-21);}.SKUCodeCls-29{fill:url(#SKUCodeLine-22);}.SKUCodeCls-30{fill:url(#SKUCodeLine-23);}.SKUCodeCls-31{fill:url(#SKUCodeLine-24);}.SKUCodeCls-32{fill:url(#SKUCodeLine-25);}.SKUCodeCls-33{fill:url(#SKUCodeLine-26);} " }} />
                <linearGradient id="SKUCodeBlob" x1="60.23" y1="150.69" x2="160.42" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="SKUCodeLine" x1="0.91" y1="93.22" x2="5.14" y2="88.99" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient id="SKUCodeLine-2" x1="165.45" y1="26.03" x2="169.68" y2="21.8" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-3" x1="131.65" y1="202.12" x2="135.88" y2="197.89" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-4" x1="56.41" y1="4.59" x2="58.36" y2="2.64" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-5" x1="58.59" y1="2.46" x2="60.5" y2="0.54" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-6" x1="60.78" y1="4.58" x2="62.7" y2="2.66" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-7" x1="58.59" y1="6.69" x2="60.5" y2="4.78" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-8" x1="196.17" y1="124.15" x2="198.12" y2="122.2" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-9" x1="198.35" y1="122.02" x2="200.26" y2="120.1" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-10" x1="200.54" y1="124.14" x2="202.46" y2="122.22" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-11" x1="198.35" y1="126.25" x2="200.26" y2="124.34" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-12" x1="14.85" y1="185.7" x2="16.8" y2="183.75" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-13" x1="17.03" y1="183.56" x2="18.94" y2="181.65" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-14" x1="19.23" y1="185.68" x2="21.14" y2="183.77" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-15" x1="17.03" y1="187.8" x2="18.94" y2="185.88" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeFill" x1="42.73" y1="160.52" x2="153.99" y2="49.26" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient id="SKUCodeFill-2" x1="73.66" y1="52.13" x2="99.01" y2="26.78" xlinkHref="#SKUCodeFill" />
                <linearGradient id="SKUCodeFill-3" x1="44.31" y1="162.61" x2="107.09" y2="99.83" xlinkHref="#SKUCodeFill" />
                <linearGradient id="SKUCodeFill-4" x1="119.03" y1="169.97" x2="169.07" y2="119.94" xlinkHref="#SKUCodeFill" />
                <linearGradient id="SKUCodeFill-5" x1="128.82" y1="144.1" x2="147.76" y2="125.16" xlinkHref="#SKUCodeFill" />
                <linearGradient id="SKUCodeLine-16" x1="112.27" y1="158.05" x2="158.83" y2="111.48" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-17" x1="32.69" y1="166.68" x2="152.85" y2="46.52" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-18" x1="128.11" y1="144.8" x2="148.46" y2="124.45" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-19" x1="89.22" y1="30.88" x2="93.95" y2="26.14" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-20" x1="80.66" y1="73.27" x2="101.74" y2="52.2" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-21" x1="74.06" y1="98.37" x2="109.11" y2="63.33" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-22" x1="63.95" y1="153.18" x2="79.14" y2="137.99" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-23" x1="63.95" y1="146.29" x2="79.14" y2="131.09" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-24" x1="74.06" y1="106.27" x2="109.11" y2="71.23" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-25" x1="84.24" y1="104.23" x2="99.43" y2="89.04" xlinkHref="#SKUCodeLine" />
                <linearGradient id="SKUCodeLine-26" x1="74.06" y1="90.46" x2="109.11" y2="55.42" xlinkHref="#SKUCodeLine" />
            </defs>
            <title>Insurance</title>
            <g id="Icon_24">
                <path id="Contour" className="SKUCodeCls-1" d="M54.21,132.14a2.85,2.85,0,0,0-3-1.47c-5.91,1-22.9,1.52-24.21-24.93-1.6-32.07,37.28-86.84,94.37-75.95,45.85,8.75,11.61,52.37,39.7,63.38,16.45,6.44,31.31,39.47-5.2,68.16C136.11,176.84,85.44,190.55,54.21,132.14Z" />
                <path id="Contour-2" className="SKUCodeCls-2" d="M3,94.1a3,3,0,1,1,3-3A3,3,0,0,1,3,94.1Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,3,90.11Z" />
                <path id="Contour-3" className="SKUCodeCls-3" d="M167.57,26.9a3,3,0,1,1,3-3A3,3,0,0,1,167.57,26.9Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,167.57,22.92Z" />
                <path id="Contour-4" className="SKUCodeCls-4" d="M133.76,203a3,3,0,1,1,3-3A3,3,0,0,1,133.76,203Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,133.76,199Z" />
                <g id="Group">
                    <path id="Contour-5" className="SKUCodeCls-5" d="M58.93,3.62a1,1,0,0,0-1-1H56.85a1,1,0,0,0,0,2h1.08A1,1,0,0,0,58.93,3.62Z" />
                    <path id="Contour-6" className="SKUCodeCls-6" d="M59.54,3a1,1,0,0,0,1-1h0V.92h0a1,1,0,0,0-1-.92,1,1,0,0,0-1,.92h0V2h0A1,1,0,0,0,59.54,3Z" />
                    <path id="Contour-7" className="SKUCodeCls-7" d="M62.24,2.62H61.16a1,1,0,0,0,0,2h1.08a1,1,0,0,0,0-2Z" />
                    <path id="Contour-8" className="SKUCodeCls-8" d="M59.54,4.24a1,1,0,0,0-1,1h0V6.32h0a1,1,0,0,0,1,.92,1,1,0,0,0,1-.92h0V5.24h0A1,1,0,0,0,59.54,4.24Z" />
                </g>
                <g id="Group-2">
                    <path id="Contour-9" className="SKUCodeCls-9" d="M198.69,123.18a1,1,0,0,0-1-1h-1.08a1,1,0,0,0,0,2h1.08A1,1,0,0,0,198.69,123.18Z" />
                    <path id="Contour-10" className="SKUCodeCls-10" d="M199.3,122.56a1,1,0,0,0,1-1h0v-1.08h0a1,1,0,0,0-1-.92,1,1,0,0,0-1,.92h0v1.08h0A1,1,0,0,0,199.3,122.56Z" />
                    <path id="Contour-11" className="SKUCodeCls-11" d="M202,122.18h-1.08a1,1,0,0,0,0,2H202a1,1,0,0,0,0-2Z" />
                    <path id="Contour-12" className="SKUCodeCls-12" d="M199.3,123.8a1,1,0,0,0-1,1h0v1.08h0a1,1,0,0,0,1,.92,1,1,0,0,0,1-.92h0V124.8h0A1,1,0,0,0,199.3,123.8Z" />
                </g>
                <g id="Group-3">
                    <path id="Contour-13" className="SKUCodeCls-13" d="M17.37,184.72a1,1,0,0,0-1-1H15.29a1,1,0,1,0,0,2h1.08A1,1,0,0,0,17.37,184.72Z" />
                    <path id="Contour-14" className="SKUCodeCls-14" d="M18,184.1a1,1,0,0,0,1-1h0V182h0a1,1,0,0,0-1-.93,1,1,0,0,0-1,.93h0v1.07h0A1,1,0,0,0,18,184.1Z" />
                    <path id="Contour-15" className="SKUCodeCls-15" d="M20.68,183.72H19.6a1,1,0,0,0,0,2h1.08a1,1,0,1,0,0-2Z" />
                    <path id="Contour-16" className="SKUCodeCls-16" d="M18,185.34a1,1,0,0,0-1,1h0v1.08h0a1,1,0,0,0,1,.92,1,1,0,0,0,1-.92h0v-1.08h0A1,1,0,0,0,18,185.34Z" />
                </g>
                <path id="Contour-17" className="iconWhite" d="M115.34,36.57h25.6a1.3,1.3,0,0,1,1.3,1.3V171.46a1.3,1.3,0,0,1-1.3,1.3H40.72a1.3,1.3,0,0,1-1.3-1.3V37.87a1.3,1.3,0,0,1,1.3-1.3h74.62Z" />
                <path id="Contour-18" className="SKUCodeCls-18" d="M119.49,36.57l21.15-.34c.63,0,1.72,1.47,1.72,2.17l.86,132.15c0,.71-.74,2.34-1.37,2.34H62.53l-8.05-1a1.22,1.22,0,0,1-1.14-1.29V38.77a1.21,1.21,0,0,1,1.14-1.28l21.75-1.26Z" />
                <path id="Contour-19" className="iconWhite" d="M115.34,43.85h19.72a.49.49,0,0,1,.49.49V165.49H47.19a.5.5,0,0,1-.5-.5V44.34a.49.49,0,0,1,.5-.49h68.15Z" />
                <path id="Contour-20" className="iconWhite" d="M98.69,33.22h13.69a3,3,0,0,1,3,3v8.18a3,3,0,0,1-3,3H69.86a3,3,0,0,1-3-3V36.17a3,3,0,0,1,3-3h14c3.92.1,1.37-2.17,1.37-2.17a4.18,4.18,0,0,1-1.37-3c0-2.85,3.31-5.15,7.39-5.15s7.39,2.3,7.39,5.15a4.21,4.21,0,0,1-1.46,3.07S94.92,33.25,98.69,33.22Z" />
                <path id="Contour-21" className="SKUCodeCls-19" d="M96.78,32.84l5.42,1.41a2.43,2.43,0,0,1,2.3,2.53v7a2.43,2.43,0,0,1-2.3,2.54L69.79,47.63c-1.26,0-2.88-2.45-2.88-3.85v-7c0-1.39,1.26-3.39,2.53-3.39l10.62-.09a19.94,19.94,0,0,0,6-.93c-.67-.72-2.19-3.35-2.19-4.3a5.19,5.19,0,0,1,5.32-4.95c3.17,0,5.42,0,7.44,1.41,1.61,1.09,2.51,4.06,1.8,4.79C98.47,29.32,93.85,32.87,96.78,32.84Z" />
                <path id="Contour-22" className="SKUCodeCls-20" d="M47.64,65.17S49,151.54,94.1,155.62c0,0,35,2.46,43-25.76l-1.57,35.63L46.69,165Z" />
                <path id="Contour-23" className="iconWhite" d="M136.38,100.8a1.3,1.3,0,0,0-1.66,0A75.69,75.69,0,0,1,119,110.62a73.38,73.38,0,0,1-17.33,4.83,1.32,1.32,0,0,0-1.11,1.32c.1,7.72,2.09,52,35,63.79,32.9-11.78,34.89-56.07,35-63.79a1.32,1.32,0,0,0-1.11-1.32,73.38,73.38,0,0,1-17.33-4.83A75.69,75.69,0,0,1,136.38,100.8Z" />
                <path id="Contour-24" className="SKUCodeCls-21" d="M170.36,121.23S164.3,160.17,135.3,166a61.12,61.12,0,0,1-28.68-16.41s8,24.52,28.87,31C135.49,180.56,165.4,174.25,170.36,121.23Z" />
                <path id="Contour-25" className="iconWhite" d="M136.38,106.44a1.32,1.32,0,0,0-1.66,0,65.21,65.21,0,0,1-13.39,8.36,62.86,62.86,0,0,1-14.74,4.12,1.33,1.33,0,0,0-1.11,1.33c.11,7,2,44.19,29.59,54.49a1.44,1.44,0,0,0,1,0c27.59-10.3,29.48-47.44,29.59-54.49a1.33,1.33,0,0,0-1.11-1.33,62.86,62.86,0,0,1-14.74-4.12A65.21,65.21,0,0,1,136.38,106.44Z" />
                <path id="Contour-26" className="SKUCodeCls-22" d="M153.21,130.92a.77.77,0,0,1-.22.53l-17.88,17.88a.76.76,0,0,1-1.06,0l-10.46-10.45a.76.76,0,0,1,0-1.07l3-3a.77.77,0,0,1,1.06,0l6.54,6.51a.51.51,0,0,0,.71,0l13.94-13.92a.75.75,0,0,1,1.07,0l3,3A.73.73,0,0,1,153.21,130.92Z" />
                <circle id="Contour-27" className="iconWhite" cx="91.58" cy="28.51" r="2.35" />
                <g id="Group-4">
                    <path id="Contour-28" className="SKUCodeCls-23" d="M164.67,117.94a62.84,62.84,0,0,1-14.48-4,58.15,58.15,0,0,1-6.95-3.78c-.71-.45-1.38-.89-2-1.31-1.92-1.31-3.39-2.45-4.23-3.13h0a2.22,2.22,0,0,0-.46-.29,2.3,2.3,0,0,0-2,0,1.83,1.83,0,0,0-.46.28,64.46,64.46,0,0,1-13.17,8.22,62.6,62.6,0,0,1-14.48,4,2.32,2.32,0,0,0-2,2.32c.09,5.67,1.29,28.9,14.4,44.23.58.68,1.19,1.35,1.83,2a38.83,38.83,0,0,0,6.34,5.27,35.84,35.84,0,0,0,3.41,2,37.49,37.49,0,0,0,4.26,1.92,2.37,2.37,0,0,0,1.66,0,36.72,36.72,0,0,0,4.26-1.92c.82-.42,1.62-.87,2.39-1.35C164.82,159,166.52,127,166.62,120.26A2.32,2.32,0,0,0,164.67,117.94Zm-21.43,51.95c-.65.44-1.32.86-2,1.26-.36.21-.71.42-1.08.61a35,35,0,0,1-4.38,2l-.1,0a.39.39,0,0,1-.26,0l-.1,0a35,35,0,0,1-4.38-2,35.85,35.85,0,0,1-7.37-5.27c-.7-.65-1.37-1.32-2-2-13.77-14.74-15-38.58-15.08-44.26a.32.32,0,0,1,.27-.32,64,64,0,0,0,15-4.2,65.63,65.63,0,0,0,12.81-7.85l.8-.64a.32.32,0,0,1,.4,0c.22.18.49.4.8.63,1.1.86,2.71,2.06,4.69,3.35.63.41,1.3.84,2,1.26a57.26,57.26,0,0,0,6.12,3.25,64,64,0,0,0,15,4.2.31.31,0,0,1,.27.32C164.52,126.67,163,156.54,143.24,169.89Z" />
                    <path id="Contour-29" className="SKUCodeCls-24" d="M169.58,114.47a72.78,72.78,0,0,1-17.07-4.76,69.22,69.22,0,0,1-9.27-5.15V37.87a2.3,2.3,0,0,0-2.3-2.3H116.28a4,4,0,0,0-3.9-3.35H98.68a4.57,4.57,0,0,1-1-.08,3.2,3.2,0,0,1,.25-.27,5.17,5.17,0,0,0,1.77-3.8c0-3.39-3.76-6.15-8.39-6.15s-8.39,2.76-8.39,6.15a5.15,5.15,0,0,0,1.7,3.72,2.83,2.83,0,0,1,.33.37,5,5,0,0,1-1,.06h-14A3.94,3.94,0,0,0,66,35.57H40.72a2.3,2.3,0,0,0-2.3,2.3V171.46a2.3,2.3,0,0,0,2.3,2.3h81a43.84,43.84,0,0,0,13,7.57,2.37,2.37,0,0,0,1.66,0c32.85-12.2,35.05-55.91,35.16-64.55A2.3,2.3,0,0,0,169.58,114.47Zm-28.34-76.6v65.34c-1.89-1.33-3.35-2.47-4.22-3.19a2.53,2.53,0,0,0-.47-.28V44.34a1.49,1.49,0,0,0-1.49-1.49H116.34V37.57h24.6A.3.3,0,0,1,141.24,37.87Zm-73.33-1.7a2,2,0,0,1,2-2h14c1.11,0,2.63-.07,3.1-1.25s-.68-2.35-1-2.64a3.2,3.2,0,0,1-1.07-2.26c0-2.25,2.93-4.15,6.39-4.15s6.39,1.9,6.39,4.15a3.27,3.27,0,0,1-1.13,2.33c-.24.22-1.39,1.35-.92,2.54s2,1.28,3.06,1.28h13.68a2,2,0,0,1,2,2v8.18a2,2,0,0,1-2,2H69.86a2,2,0,0,1-2-2Zm2,12.14h42.52a4,4,0,0,0,3.91-3.46h18.26V99.74a2.18,2.18,0,0,0-.46.28,74.38,74.38,0,0,1-15.5,9.69,72.65,72.65,0,0,1-17.07,4.76,2.3,2.3,0,0,0-2,2.31c.08,6.15,1.23,30,13.7,47.71H47.69V44.85H66A4,4,0,0,0,69.86,48.31ZM40.72,171.76a.29.29,0,0,1-.3-.3V37.87a.29.29,0,0,1,.3-.3H65.91v5.28H47.19a1.5,1.5,0,0,0-1.5,1.49V165a1.5,1.5,0,0,0,1.5,1.5h67.56a48,48,0,0,0,4.74,5.27Zm95,7.69a.39.39,0,0,1-.26,0,41.85,41.85,0,0,1-10.49-5.69c-.86-.64-1.68-1.31-2.47-2a44.9,44.9,0,0,1-5.15-5.27c-.54-.66-1.07-1.33-1.58-2-12.92-17.26-14.09-41.59-14.17-47.73a.32.32,0,0,1,.27-.32,74.57,74.57,0,0,0,17.59-4.9,76.11,76.11,0,0,0,15.13-9.33c.3-.23.57-.45.8-.64a.32.32,0,0,1,.4,0h0c.23.19.5.41.8.64,1.11.88,2.72,2.09,4.69,3.42.63.43,1.3.86,2,1.31a70.94,70.94,0,0,0,8.44,4.6,74.57,74.57,0,0,0,17.59,4.9.32.32,0,0,1,.27.32C169.43,125.17,167.31,167.71,135.68,179.45Z" />
                    <path id="Contour-30" className="SKUCodeCls-25" d="M150.66,126.64a1.8,1.8,0,0,0-2.48,0l-13.6,13.57-6.18-6.16a1.81,1.81,0,0,0-2.48,0l-3,3a1.74,1.74,0,0,0-.51,1.24,1.77,1.77,0,0,0,.51,1.24L133.34,150a1.76,1.76,0,0,0,2.48,0l17.87-17.87a1.74,1.74,0,0,0,0-2.48Zm-16.08,21.8-10.11-10.1,2.69-2.69,6.37,6.33a1.51,1.51,0,0,0,2.12,0l13.77-13.75,2.68,2.69Z" />
                    <path id="Contour-31" className="SKUCodeCls-26" d="M91.58,31.86a3.35,3.35,0,1,0-3.35-3.35A3.35,3.35,0,0,0,91.58,31.86Zm0-4.7a1.35,1.35,0,1,1-1.35,1.35A1.35,1.35,0,0,1,91.58,27.16Z" />
                    <path id="Contour-32" className="SKUCodeCls-27" d="M71.54,63.73h39.32a1,1,0,0,0,0-2H71.54a1,1,0,0,0,0,2Z" />
                    <path id="Contour-33" className="SKUCodeCls-28" d="M125.21,79.85H58a1,1,0,0,0,0,2h67.26a1,1,0,0,0,1-1A1,1,0,0,0,125.21,79.85Z" />
                    <path id="Contour-34" className="SKUCodeCls-29" d="M85.32,144.59H57.77a1,1,0,0,0,0,2H85.32a1,1,0,0,0,0-2Z" />
                    <path id="Contour-35" className="SKUCodeCls-30" d="M85.32,137.69H57.77a1,1,0,0,0,0,2H85.32a1,1,0,0,0,0-2Z" />
                    <path id="Contour-36" className="SKUCodeCls-31" d="M125.21,87.75H58a1,1,0,1,0,0,2h67.26a1,1,0,0,0,0-2Z" />
                    <path id="Contour-37" className="SKUCodeCls-32" d="M105.61,95.63H78.06a1,1,0,0,0,0,2h27.55a1,1,0,1,0,0-2Z" />
                    <path id="Contour-38" className="SKUCodeCls-33" d="M58,71.94a1,1,0,1,0,0,2h67.26a1,1,0,0,0,0-2H58Z" />
                </g>
            </g>
        </svg>

    )
}
export default SKUCode