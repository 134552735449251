export const ChatMessageStying = ({palette,spacing}) => {

    const radius = spacing(2.5);
    const size = spacing(4);
    const rightBgColor = palette.primary.main;

    return {
        avatar: {
            width: size,
            height: size,
        },
        leftRow: {
            textAlign: 'left',
            paddingLeft: 3
        },
        rightRow: {
            textAlign: 'right',
            paddingRight: 3
        },
        msg: {
            padding: spacing(1, 2),
            borderRadius: 2,
            marginBottom: 2,
            display: 'inline-block',
            wordBreak: 'break-word',
            fontSize: '14px',
        },
        left: {
            borderTopRightRadius: radius,
            borderBottomRightRadius: radius,
            backgroundColor: palette.background.default,
            marginBottom: 1,
        },
        right: {
            borderTopLeftRadius: radius,
            borderBottomLeftRadius: radius,
            backgroundColor: rightBgColor,
            color: palette.common.white,
            marginBottom: 1,
        },
        leftFirst: {
            borderTopLeftRadius: radius
        },
        leftLast: {
            borderBottomLeftRadius: radius,
        },
        rightFirst: {
            borderTopRightRadius: radius,
        },
        rightLast: {
            borderBottomRightRadius: radius,
        },
    };




}