import type { FC } from 'react';
import {
    Avatar,
    Box,
    Card, Chip,
    Grid,
    LinearProgress, Stack,
    Typography
} from '@mui/material';
import CalendarIcon from '../icons/Calendar';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Label from "./Label";

interface QuickStatsDateProps {
    title: string;
    value: number;
    color?: string;
}

const QuickStatsDate: FC<QuickStatsDateProps> = ({title, value, color}) => (
    <Card
        sx={{
            backgroundColor: color,
            color: "white",
            fontSize: 14,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            // height: "80%"
        }}
    >
        <Box sx={{ flexGrow: 1 }}>
            <Typography
                
                gutterBottom
                variant="overline"
                sx={{ color: (theme) => theme.palette.text.primary }}
            >
                { title }
            </Typography>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    p: 1,
                }}
            >
                <Typography
                    sx={{ mr: 1, p: 2, color: (theme) => theme.palette.text.primary }}
                    variant="h4"
                >
                    { value }
                </Typography>
            </Box>
        </Box>
        <Avatar
            sx={{
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
                height: 48,
                width: 48
            }}
        >
            <CalendarIcon fontSize="small" />
        </Avatar>
    </Card>
);

export default QuickStatsDate;
