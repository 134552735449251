import {API, PerformJSONRPCRequest} from "../../../../api/jsonrpc";
import Campaign from "../Campaign";

export interface DeleteOneRequest {
    campaignID: string;
    goalID: string;
    clientName: string;
}

export interface DeleteOneResponse {
    success: boolean;
}

export interface CreateManyRequest {
    campaignID: string;
    goalIDs: string[];
    clientName: string;
}

export interface CreateManyResponse {
    success: string
}

export interface InsertCampaignGoalQueryRequest {
    campaignIDs: Campaign[];
    goalID: string;
    clientName: string;
}

export interface InsertCampaignGoalQueryResponse {
    success: string
}

const Store = {
    serviceProvider: 'Campaign-Goal-Store',
    async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOne`,
            request: [{
                "campaignID": request.campaignID,
                "goalID": request.goalID,
                "clientName": request.clientName
            }]
        });
        return response
    },
    async CreateMany(request: CreateManyRequest): Promise<CreateManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateMany`,
            request: [{
                "campaignID": request.campaignID,
                "goalIDs": request.goalIDs,
                "clientName": request.clientName
            }]
        });
        return response
    },
    async InsertCampaignGoalQuery(request: InsertCampaignGoalQueryRequest): Promise<InsertCampaignGoalQueryResponse> {
        const campaignIDsFinal = request.campaignIDs?.map((campaign) => campaign.id) || []
        console.log('campaignIDsFinal',campaignIDsFinal)
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.InsertCampaignGoals`,
            request: [{
                "campaignIDs": campaignIDsFinal,
                "goalID": request.goalID,
                "clientName": request.clientName
            }]
        });
        return response
    },
};

export default Store;