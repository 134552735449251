// @ts-nocheck
// todo: remove ts-nocheck
import type {FC} from 'react';
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Chart from 'react-apexcharts';
import {Box, Button, Card, CardContent, CardHeader, Fade, Grid, Paper, Popper, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {WidgetProps} from "../../../WidgetSwitcher";
import {useDispatch} from "react-redux";
import BasicWidget from "../BasicWidget";
import SettingsContext from "../../../../../../../contexts/SettingsContext";

const ChartInst: FC<WidgetProps> = React.memo( ({widget, widgetColumns, widgetData, widgetVariables, allWidgetVariables, nextStep, height,
                                                    setVariableOutputs}) => {
    const theme = useTheme();
    const settingsCtx = useContext(SettingsContext)
    const dispatch = useDispatch()
    const code = widget.arguments.code ? unescape(widget.arguments.code) : null
    let url = window.location.pathname.toString()

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    let hasHVTitle = false;
    let hasDateTitle = false;
    let HVTitle = '';
    let dateTitle = '';

    useEffect(() => {
    }, [widgetData])

    if (!code) {
        return (
            <Grid item xs={8}>
                <Box sx={{minHeight: "100"}}>
                    No Code Provided
                </Box>
            </Grid>
        )
    }

    // let setOutputData = (data: any) => {
    //     if (data != null && widgetData != null) {
    //         console.log("setOutputs ------>: ", widget.id, data, nextStep)
    //         if (!widget.arguments.setVariableIds) dispatch(widgetChangeVariable(widget.id, data))
    //         else {
    //             widget.arguments.setVariableIds.map((i) => {
    //                 dispatch(widgetChangeVariable(i, data))
    //             })
    //         }
    //     }
    //     if (nextStep) nextStep()
    // }

    let setOutputData = setVariableOutputs
    // todo: find a better way to do this...
    let chartType = "line"
    let chart = {
        options: {
        },
        series: [{name: "Y", data: []}]
    }

    try {
        let chartCodeObject = eval(code);
        //TODO: This is a temp fix for charts in BITool dark-mode. Really we should offer the ability to customize their own theme
        let chartOptions = chartCodeObject.options.chart;

        //checks if a tooltip prop exists, if not creates it to allow adding of theme colour
        if (!chartCodeObject.options.tooltip) {
            chartCodeObject.options.tooltip = {};
        }
        chartCodeObject.options.tooltip.theme = settingsCtx.settings.theme === 'LIGHT' ? 'light' : 'dark';
        //checks if a chart exists (and it should) but if it doesn't, creates it and add the theme colour

        if (!chartOptions) {
            chartCodeObject.options.chart = {};
            chartOptions = chartCodeObject.options.chart;
        }
        chartOptions.background = 'inherit';
        chartOptions.foreColor = useTheme().palette.text.primary;

    } catch (e) {
        console.log("ChartError: ", e)
        return (
            <Grid item xs={8}>
                <Box sx={{minHeight: "100"}}>
                    <> An Error with Code Execution</>
                </Box>
            </Grid>
        )
    }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  if (widgetData && Object.keys(widgetData) && Object.keys(widgetData).length && widgetData[Object.keys(widgetData)[0]]) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '90%',
          minHeight: '500px',
          boxShadow: 3,
          bgcolor: 'background.default',
          p: 2,
          borderRadius: 1,
        }}
      >
        <div>
          {hasDateTitle && <Box>
            <Typography>
              {dateTitle}
            </Typography>
          </Box>}
        </div>
        <div>
          {hasHVTitle && <Box>
            <Typography>
              {HVTitle}
            </Typography>
          </Box>}
        </div>
        <Chart
          type={chartType}
          width={"100%"}
          height={"100%"}
          {...chart}
        />
        <div>
          {url.includes("/bi/") &&
            <Box>
              <Button variant="text" onClick={handleClick}>
                Widget Output
              </Button>
              <Popper id={id} open={open} anchorEl={anchorEl} placement="right-end" transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography sx={{ p: 2 }}>WidgetOutput: {JSON.stringify(widgetVariables)}</Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Box>
          }
        </div>
      </Box>
    )
  } else {
    return (
      <Card>
        <CardHeader
          title="NO DATA TO DISPLAY"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="body1"
          >
            Query returned no results
          </Typography>

        </CardContent>
      </Card>
    )
  }

})


const BasicApexChart: FC<WidgetProps> = (props) => {
    return  <BasicWidget {...props} children={<ChartInst />}/>
};

export default BasicApexChart;
