/* eslint-disable */

import type {FC} from 'react';
import React, {useEffect, useState} from "react";
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import type {AppBarProps} from '@mui/material';
import {
    AppBar, Box, Divider, FormControl, Hidden, IconButton, InputLabel, MenuItem, Select, Skeleton, Toolbar
} from "@mui/material";
import {experimentalStyled, Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import Logo from '../../components/Logo';
import {Organization} from "../../cog/organization";
import {User} from "../../cog/user";
import {useMainContext} from "../../contexts/MainContext";
import ToolbarContainer from "../toolbar/ToolbarContainer";
import NotificationsPopover from "../../components/NotificationPopover";
// import KanbanContext from "../../contexts/KanbanContext";

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        minWidth: 120,
        fontColor: "white",
        marginLeft: '50px',
        marginTop: '10px'

    },
    select: {
        fontColor: "white"
    },
    selectEmpty: {
    },
    whiteColor: {
        color: "white"
    },
}));

interface DashboardNavbarProps extends AppBarProps {
    onMobileClose: () => void;
    openMobile: boolean;
    onSidebarMobileOpen?: () => void;
    organizations: Organization[] | null,
    activeOrganization: Organization | null;
    isLoading: boolean,
    user: User,
    logout: () => void,
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(

    ({ theme }) => (
        {
            ...(
                theme.palette.mode === 'light' && {
                    backgroundColor: theme.palette.primary.main,
                    boxShadow: 'none',
                    color: theme.palette.primary.contrastText
                }
            ),
            ...(
                theme.palette.mode === 'dark' && {
                    backgroundColor: theme.palette.background.paper,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    boxShadow: 'none'
                }
            ),
            zIndex: theme.zIndex.drawer + 100
        }
    )
);

interface MemberProps {
    organizations: Organization[],
    activeOrganization: Organization | null;
    isLoading: boolean,
}

const MemberSelect = (props: MemberProps) => {
    const mainCtx = useMainContext()
    const classes = useStyles();
    let navigate = useNavigate()
    const [multiUserToAssign, setMultiUserToAssign] = useState([]);

    useEffect(() => {
    }, [props.organizations, props.activeOrganization])

    const handleChange = (event) => {
        navigate('/app/' + props.organizations.find(x => x.id === event.target.value).slug)
        mainCtx.setActiveOrganization(props.organizations.find(x => x.id === event.target.value))
    };

    if (props.organizations == null || props.activeOrganization == null || props.isLoading == true) {
        return <Skeleton variant="rectangular" />
    }

    return (
        <FormControl variant="standard" className={classes.formControl} >
            <InputLabel
                id="demo-simple-select-outlined-label"
                classes={{
                    root: classes.whiteColor
                }}
            >
                Organization
            </InputLabel>
            <Select
                classes={{
                    select: classes.whiteColor,
                    icon: classes.whiteColor
                }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={multiUserToAssign}
                onChange={(event, newValue) => {
                    // setMultiUserToAssign(event.target.value);
                }}
                label="Organization"
            >
                {mainCtx.members.map((mem) => {
                    return <MenuItem
                        value={mem.id}
                        key={mem.id}>
                        {mem.displayName}
                    </MenuItem>
                })
                }
            </Select>
        </FormControl>
    )
}

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
    const { onSidebarMobileOpen, organizations, activeOrganization, isLoading,
        user, logout, onMobileClose, openMobile, ...other } = props;
    const mainCtx = useMainContext();
    const [orgLink, setOrgLink] = useState('/');

    useEffect(() => {
        if (mainCtx.activeOrganization && mainCtx.activeOrganization.name) {
            const org = mainCtx.activeOrganization.name.replace(/ |_/g, '-');
            const orgLink = `${org.toLowerCase()}`;
            setOrgLink(orgLink);
        }
    }, [mainCtx.activeOrganization]);

    return (
        <DashboardNavbarRoot {...other}>
            <Toolbar sx={{
                minHeight: 64,
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Hidden mdUp>
                    <IconButton color="inherit" onClick={openMobile ? onMobileClose : onSidebarMobileOpen} size="large">
                        <MenuIcon fontSize="small" />
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <RouterLink to={`/app/${orgLink}`}>
                        <Logo
                            sx={{
                                height: 40,
                                width: 40
                            }}
                        />
                    </RouterLink>
                </Hidden>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                        marginLeft: 2.1
                    }}
                />
                {/*<MemberSelect*/}
                {/*    organizations={organizations}*/}
                {/*    activeOrganization={activeOrganization}*/}
                {/*    isLoading={isLoading}*/}
                {/*/>*/}
                {/*<MultipleSelectCheckmarks></MultipleSelectCheckmarks>*/}

                {/*<Box>Snail</Box>*/}
                <Box width={"100%"}>
                    <ToolbarContainer></ToolbarContainer>
                </Box>
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        flexGrow: 1,*/}
                {/*        ml: 0*/}
                {/*    }}*/}
                {/*/>*/}
                <Box sx={{ position: 'relative', left: '-10px', ml: 1,  mr: 2 }}>
                    <NotificationsPopover />
                </Box>
                <Box sx={{
                    ml: 0,
                    display: "flex"
                }}>
                    <AccountPopover props={{ user, logout }} />
                </Box>
            </Toolbar>
        </DashboardNavbarRoot>
    );
};

DashboardNavbar.propTypes = {
    onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
