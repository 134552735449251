/* eslint-disable */

import type {FC, ReactNode} from "react";
import React, {useCallback, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {experimentalStyled} from "@mui/material/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import toLowerCase from "../../utils/toLowerCase";
import {useMainContext} from "../../contexts/MainContext";
import Dashboard from "../../cog/bitool/dashboard/Dashboard";
import DashboardStore from "../../cog/bitool/dashboard/Store";
import {useDashboardContext} from "../../contexts/DashboardContext";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import {useParams} from "react-router";
import {Organization} from "../../cog/organization";
import {Helmet} from "react-helmet-async";
import DashboardSkeleton from "../../pages/app/bitool/dashboard/DashboardSkeleton";
import NotFound from "../../components/NotFound";


import {
  Analytics, AppsOutlined, Campaign, Explore, Home, LocalShipping,Storefront, MonetizationOnRounded, Preview, VpnKeyOutlined
} from "@mui/icons-material";
import {AdminStore} from "../../cog/adminManagement";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CogSpeedDial from "../../pages/app/userExperience/CogSpeedDial";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
    ({theme}) => (
        {
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          height: '100%',
          overflow: 'hidden',
          width: '100%'
        }
    )
);

const DashboardLayoutWrapper = experimentalStyled('div')(
    
    ({theme}) => (
        {
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          paddingTop: '81px',
          [theme.breakpoints.up('lg')]: {
            paddingLeft: useMainContext().slider ? '280px' : '85px'
          }
        }
    )
);


const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});
const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});
const baseSection = [
  // {
  //     title: 'BI Tool',
  //     items: [
  //         {
  //             title: 'Create a Connection',
  //             path: '/connectionwizard',
  //             icon: <Upload fontSize="small"/>
  //         },
  //         {
  //             title: 'Create a Query',
  //             path: 'bitool/querycreator',
  //             icon: <Search fontSize="small"/>
  //         },
  //         {
  //             title: 'Kanban',
  //             path: 'keystone',
  //             icon: <Search fontSize="small"/>
  //         }
  //     ]
  // }
];
function GetPath(Title: string, orgSlug: string) {
  let tempT = toLowerCase(Title.replace(" ", ""));
  return "/app/" + orgSlug + "/" + tempT;
  // if (Title == "My Page") {
  //     return '/app/' + orgSlug + '/mypage'
  // }
  // if (Title == "Kanban") {
  //     return '/app/' + orgSlug + '/keystone'
  // }
  // if (Title == "List View") {
  //     return '/app/' + orgSlug + '/keystone'
  // }
  // return '/app/' + orgSlug + '/' + toLowerCase(Title)
}
interface child {
  title: string;
  path: string;
  icon?: any;
}
interface SectionItem {
  title: string;
  path: string;
  icon: any;
  children: child[];
}
interface AppSections {
  title: string;
  items: SectionItem[];
}
const DashboardLayout: FC<DashboardLayoutProps> = () => {
  let { org } = useParams();
  const mainCtx = useMainContext();
  const dashCtx = useDashboardContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
      useState<boolean>(false);
  const [sections, setSections] = useState<Array<AppSections>>(baseSection);
  const [dashboardsLoading, setDashboardsLoading] = useState<boolean>(false);
  
  const generateSections = (dashboards: Dashboard[], activeOrg: Organization | null): Array<any> => {
    let section = Array({
      title: 'Applications',
      items: [
        {
          title: 'Dashboards',
          path: '/app',
          icon: <ChartSquareBarIcon fontSize="small"/>,
          children: []
        },
      ]
    })
    if (activeOrg == null) {
      return null
    }
    if (dashboards == null) {
      return null
    }
    
    let childItems = []
    dashboards.forEach((dash) => {
      childItems.push({
        title: dash.name,
        path: '/app/' + activeOrg.slug + '/' + toLowerCase(dash.name) + '/' + dash.id,
      })
    })
    // let tempPath = ""
    section[0].items[0].children.forEach((c) => {
      c.path = GetPath(c.title, activeOrg.slug)
    })
    section[0].items[0].children = childItems
    AdminStore.CheckAccess({
      resource: {
        object_id: activeOrg.id,
        object_type: "organization",
      },
      permission_type: "edit",
      subject: {
        subject_id: mainCtx.user.id,
        subject_type: "user",
        optional_relation: ""
      }
    }).then(r => { if (r) {
      const managementSection = {
        title: 'Management',
        path: "/app",
        icon: <ContentPasteIcon fontSize="small"/>,
        children: [
          {
            title: 'Admin Portal',
            path: '/app/' + activeOrg.slug + '/manage',
            icon: <AdminPanelSettingsIcon fontSize={"small"}/>
          },
        ]
      }
      section[0].items.unshift(managementSection)
    }})
    return section
  };
  
  const fetchDashboards = useCallback(async () => {
    if (!location.pathname.includes("/bi/")) {
      if (org && mainCtx.organizations && mainCtx.organizations.length > 0) {
        setDashboardsLoading(true);
        await mainCtx.setActiveOrganization(
            mainCtx.organizations.find((o) => o.slug == org)
        );
        const dashboards = await DashboardStore.FindAll({
          organizationId: mainCtx.organizations.find((o) => o.slug == org).id,
        });
        const sections = generateSections(
            dashboards.dashboards,
            mainCtx.organizations.find((o) => o.slug == org)
        );
        /*                setSections(sections)*/
        return sections;
      } else if (mainCtx.organizations) {
        await mainCtx.setActiveOrganization(mainCtx.organizations[0]);
        if (mainCtx.organizations[0]) {
          navigate(mainCtx.organizations[0].slug);
        } else {
          return <NotFound />;
        }
      }
    }
  }, [org, mainCtx.organizations, mainCtx.activeOrganization]);
  
  const fetchKeystoneAccess = async (sects) => {
    let perms;

    if (org && mainCtx.organizations && mainCtx.organizations.length > 0) {
      
      let resp = AdminStore.CheckKeystoneAccess(
          {
            user_id: mainCtx.user.id,
            org_id: mainCtx.organizations.find(o => o.slug == org).id
          }
      )

      perms = await resp

      let sections = generateKeystoneSections(sects, perms)
      return sections
    }
  }
  
  const generateKeystoneSections = (sects, perms) => {
    if (perms.user_permission && perms.admin_permission) {
      const keyStoneSection = {
        title: "Keystone",
        path: "/app",
        icon: <VpnKeyOutlined fontSize="small" />,
        children: [
          {
            title: 'Home',
            path: '/app/' + org + '/home',
            icon: <Home fontSize="small"/>
          },
          {
            title: "Explore",
            path: "/app",
            icon: <Explore fontSize="small" />,
            children: [
              {
                title: "Overview",
                path: "/app/" + org + "/explore/overview",
                icon: <Preview fontSize="small" />,
              },
              {
                title: "Distribution",
                path: "/app/" + org + "/explore/distribution",
                icon: <LocalShipping fontSize="small" />,
              },
              {
                title: "Stores",
                path: "/app/" + org + "/explore/stores",
                icon: <Storefront fontSize="small" />,
              },
            ]
          },
          {
            title: "Opportunities",
            path: "/app/" + org + "/opportunities",
            icon: <AppsOutlined fontSize="small" />,
          },
          // {
          //     title: 'Leader Board',
          //     path: 'leaderboard',
          //     icon: <LeaderboardOutlined fontSize="small"/>
          // },
          {
            title: "Campaigns",
            path: "/app/" + org + "/campaigns",
            icon: <Campaign fontSize="small" />,
          },
          {
            title: "Reporting",
            path: "/app/" + org + "/reporting",
            icon: <Analytics fontSize="small" />,
            children: [
              {
                title: "Financial",
                path: "/app/" + org + "/reporting/financial",
                icon: <MonetizationOnRounded fontSize="small" />,
              },
              {
                title: "Users",
                path: "/app/" + org + "/reporting/users",
                icon: <PeopleAltIcon fontSize="small" />,
              },
            ]
          },
/*          {
            title: 'Settings',
            path: '/app/' + org + '/organization-settings',
            icon: <SettingsIcon fontSize={"small"}/>
          },*/
        
        ],
      };
      sects[0].items.unshift(keyStoneSection);
      return sects;
    } else if (perms.user_permission && !perms.admin_permission) {
      const keyStoneSection = {
        title: "Keystone",
        path: "/app",
        icon: <VpnKeyOutlined fontSize="small" />,
        children: [
          {
            title: "Explore",
            path: "/app/" + org + "/explore",
            icon: <Explore fontSize="small" />,
            children: [
              {
                title: "Overview",
                path: "/app/" + org + "/explore/overview",
                icon: <Preview fontSize="small" />,
              },
              {
                title: "Distribution",
                path: "/app/" + org + "/explore/distribution",
                icon: <LocalShipping fontSize="small" />,
              },
              {
                title: "Distribution",
                path: "/app/" + org + "/explore/stores",
                icon: <Storefront fontSize="small" />,
              },
            ]
          },
          {
            title: "Opportunities",
            path: "/app/" + org + "/opportunities",
            icon: <AppsOutlined fontSize="small" />,
          },
          /*                        {
                                                title: 'Leader Board',
                                                path: 'leaderboard',
                                                icon: <LeaderboardOutlined fontSize="small"/>
                                            },*/
        ],
      };
      sects[0].items.unshift(keyStoneSection);
      return sects;
    } else {
      return sects;
    }
  };
  
  useEffect(() => {
    fetchDashboards().then((r) => {
      fetchKeystoneAccess(r).then((s) => {
        setSections(s);
        setDashboardsLoading(false);
      });
    });
  }, [mainCtx.activeOrganization, mainCtx.organizations, org]);
  
  return (
      <div>
        <Helmet>
          <title>Cognizance Processing</title>
        </Helmet>
        <DashboardLayoutRoot >
          <DashboardNavbar
              onMobileClose={(): void => setIsSidebarMobileOpen(false)}
              openMobile={isSidebarMobileOpen}
              onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
              organizations={mainCtx.organizations}
              activeOrganization={mainCtx.activeOrganization}
              isLoading={mainCtx.isLoading}
              user={mainCtx.user}
              logout={mainCtx.logout}
          />
          <DashboardSidebar
              organizations={mainCtx.organizations}
              activeOrganization={mainCtx.activeOrganization}
              onMobileClose={(): void => setIsSidebarMobileOpen(false)}
              openMobile={isSidebarMobileOpen}
              isLoading={mainCtx.isLoading || dashCtx.isLoading}
              areDashboardsLoading={dashboardsLoading}
              user={mainCtx.user}
              sections={sections}
          />
          {mainCtx.activeOrganization && mainCtx.activeOrganization.name?<CogSpeedDial/>:<div/>}
          <DashboardLayoutWrapper>
            <DashboardLayoutContainer>
              <DashboardLayoutContent>
                { !(mainCtx.isLoading || dashCtx.isLoading) ?
                    <Outlet/> : <DashboardSkeleton />
                }
              </DashboardLayoutContent>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
      </div>
  );
  
};

export default DashboardLayout;