import * as React from 'react';
import {FC, useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputBase,
  styled,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useMainContext} from "../../../../contexts/MainContext";
import {GridColDef} from "@mui/x-data-grid";
import {AddCircleOutline, Delete, ErrorOutline, Search as SearchIcon} from "@mui/icons-material";
import DeleteOppLabelDialog from './DeleteOppLabelDialog';
import {OppLabel} from 'src/cog/keystone/oppLabel/OppLabel';
import {OppLabelStore} from 'src/cog/keystone/oppLabel';
import CreateOppLabelDialog from './CreateOppLabelDialog';
import OrganizationPageSkeleton from './OrganizationPageSkeleton';
import {DataGridPro} from '@mui/x-data-grid-pro';


const OppLabelsDataGrid: FC = () => {
  const mainCtx = useMainContext();
  const mobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [allLabels, setAllLabels] = useState<OppLabel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredLabels, setFilteredLabels] = useState<OppLabel[]>([]);
  const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null);
  const [createLabelDialogOpen, setCreateLabelDialogOpen] = useState<boolean>(false);

  // Function to fetch OppLabels
  const fetchOppLabels = async () => {
    setIsLoading(true)
    try {
      const response = await OppLabelStore.FindAll({ ClientName: mainCtx.activeOrganization.name });

      if (response && response.Labels.length > 0) {
        setAllLabels(response.Labels);
        setFilteredLabels(response.Labels);
      } else {
        setAllLabels([]);
        setFilteredLabels([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching labels', error);
      setIsLoading(false);
    }
  };

  // Fetch data when the organization name changes
  useEffect(() => {
    setIsLoading(true);
    fetchOppLabels();
  }, [mainCtx.activeOrganization.name]);

  // Filter labels based on searchText
  useEffect(() => {
    if (searchText.trim() === '') {
      setFilteredLabels(allLabels);
    } else {
      const filtered = allLabels.filter((label) =>
        label.label.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredLabels(filtered);
    }
  }, [searchText, allLabels]);

  // Function to open the Create Label dialog
  const openCreate = () => {
    setCreateLabelDialogOpen(true);
  };

  // Function to handle closing the Delete Label dialog
  const handleDialogClose = () => {
    setDialogOpen(false)
    setSelectedLabelId(null)
  };

  // Function to handle initiating the Delete Label operation
  const handleDeleteLabel = (ID) => {
    setSelectedLabelId(ID)
    setDialogOpen(true)
    setTimeout(() => {
      const deleteButton = document.activeElement as HTMLElement;
      if (deleteButton) {
        deleteButton.blur();
      }
    }, 100);
  };

  // Blur the Delete button focus when the dialog closes
  useEffect(() => {
    if (!dialogOpen) {
      const deleteButton = document.activeElement as HTMLElement;
      if (deleteButton) {
        deleteButton.blur();
      }
    }
  }, [dialogOpen]);

  // Define columns for the data grid
  const columns: GridColDef[] = [
    {
      field: 'label',
      flex: 1,
      resizable: true,
      align: 'left',
      cellClassName: (params) =>
        params.field === 'label' ? 'labelCell' : 'actionCell',
      renderCell: (cellValue) => {
        const label = cellValue.value;
        const labelTextLength = label.length > 20;
        return (
          <Box>
            {labelTextLength ? (
              <Tooltip title={label}>
                <Typography
                  variant="body2"
                  fontSize="small"
                  sx={{
                    marginLeft: '10px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {label}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                variant="body2"
                fontSize="small"
                sx={{
                  marginLeft: '10px',
                }}
              >
                {label}
              </Typography>
            )}
          </Box>
        );
      },
    },
    {
      field: 'action',
      flex: 0.15,
      resizable: true,
      align: 'center',
      width: 100,
      cellClassName: () => 'actionCell',
      renderCell: (params) => {
        const [isDisabled, setIsDisabled] = useState(false)
        return (
          <Box>
            <Tooltip title={"Delete Label"}>
              <IconButton aria-label="delete"
                onClick={() => handleDeleteLabel(params.row.id)}
                sx={{
                  color: (theme) => (theme.palette.mode === 'dark' ? "#f2bc53" : '#6b778c'),
                }}
                disabled={isDisabled}>
                {isDisabled ? <CircularProgress size={20} /> : <Delete fontSize="small" />}
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    },

  ];

  // Define the styled data grid component
  const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    border: 0,
    margin: 5,

    '& .labelGrid': {
      borderRadius: 10,
    },

    '& .labelCell': {
      borderRadius: '10px 0 0 10px',
      backgroundColor: theme.palette.mode === 'dark' ? '#4c5765' : '#f6f6f6',
      '&:focus': {
        outline: 'none',
      },
    },

    '& .actionCell': {
      borderRadius: '0 10px 10px 0',
      backgroundColor: theme.palette.mode === 'dark' ? '#4c5765' : '#f6f6f6',
      '&:focus': {
        outline: 'none',
      },
    },

    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader": {
      display: "none",
    },

    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },

    '& .MuiDataGrid-footerContainer': {
      borderTop: `0.8px solid ${useTheme().palette.mode === 'dark' ? '#f2bc53' : '#f6f6f6'}`,
      marginBottom: '10px'
    },

    '& .MuiDataGrid-virtualScrollerContent': {
      paddingBottom: `${filteredLabels.length * 10}px`,
      boxSizing: 'content-box',
    },

    '& .MuiDataGrid-row': {
      marginBottom: 10, 
    },
  }));


  // Memoized data grid component for performance optimization
  const StyledDataGridMemo = useMemo(() => {
    if (filteredLabels.length === 0) {
      return (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '60vh',
          zIndex: 99
        }}><Typography variant={"h5"}>No Labels</Typography>
          <ErrorOutline sx={{ marginTop: 0.5 }} />
        </Box>
      );
    }

    return (
      <StyledDataGrid
        columns={columns}
        rows={filteredLabels}
        pageSize={10}
        rowsPerPageOptions={[10]}
        autoHeight={true}
        pagination={true}
        headerHeight={0}
        components={{
          Header: () => null,
        }}
        sx={{
          width: mobileDevice ? "90%": "35%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        getRowClassName={() => `labelGrid`}
        getCellClassName={() => `labelCell`}
        getRowHeight={() => 'auto'}
        disableSelectionOnClick
      />
    );
  }, [filteredLabels, allLabels]);

  
  return (
    <Box sx={{ border: 'none', height: '100%', width: '100%' }}>
      {!isLoading ? (
        <Card>
          <CardHeader
            action={
              <Button title={"Create Label"} onClick={openCreate}>
                <AddCircleOutline fontSize={"large"} />
              </Button>
            }
          />
  
          <Box sx={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '10px' }}>
            <Grid container justifyContent="center">
              <Grid item>
                <IconButton
                  type="button"
                  sx={{
                    color: (theme) => (theme.palette.mode === 'dark' ? "#f2bc53" : '#6b778c'),
                    paddingRight: '10px'
                  }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
  
                <InputBase
                  type="text"
                  placeholder="Search to Filter Labels"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  sx={{
                    '& .MuiInputBase-input': {
                      borderBottom: `1px solid ${
                        theme => (theme.palette.mode === 'dark' ? '#f2bc53' : '#6b778c')
                      }`,
                      padding: '5px',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
  
          {dialogOpen && selectedLabelId && (
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
              <DeleteOppLabelDialog
                ID={selectedLabelId}
                handleDialogClose={handleDialogClose}
                onLabelDeleted={fetchOppLabels}
              />
            </Dialog>
          )}
  
          {createLabelDialogOpen && (
            <Dialog open={createLabelDialogOpen} onClose={() => setCreateLabelDialogOpen(false)}>
              <CreateOppLabelDialog
                open={createLabelDialogOpen}
                setOpen={setCreateLabelDialogOpen}
                onCancel={() => {
                  setCreateLabelDialogOpen(false);
                }}
                onLabelCreated={fetchOppLabels}
              />
            </Dialog>
          )}
  
          {StyledDataGridMemo}
        </Card>
      ) : (
        <OrganizationPageSkeleton />
      )}
    </Box>
  );
};

export default OppLabelsDataGrid;