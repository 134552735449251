/* eslint-disable */
import React, {useEffect, FC, useCallback} from 'react';
import {
  Box,
  Button, ButtonGroup
} from "@mui/material";
import WidgetStore from "../../../../../cog/bitool/widget/Store";
import { WidgetClonerStore } from "../../../../../cog/bitool/widget";
import {useNavigate} from "react-router-dom";
import Widget from "../../../../../cog/bitool/widget/Widget";
import {useParams} from "react-router";
import WidgetEditor from "./WidgetEditor";
import {initBitool, widgetUpdate} from "../../../../../slices/bitool";
import {useDispatch} from "react-redux";
import {useSnackbar, VariantType} from "notistack";
import { QueryStore } from "../../../../../cog/bitool/query";
import { ArgumentStore } from "../../../../../cog/bitool/query";



const WidgetEditorInterface: FC = () => {
  let { dashId} = useParams();
  if (!dashId) {
    return <div> No Dashboard ID found </div>
  }

  let { widgetId } = useParams();
  if (!widgetId) {
    return <div> No Widget ID found </div>
  }

  let { queryId } = useParams();
  if (!queryId) {
    return <div> No Query ID found </div>
  }

  const {enqueueSnackbar} = useSnackbar();
  let dispatch = useDispatch()
  let navigate = useNavigate()
  const [widgets, setWidgets] = React.useState<Widget[]>([]);
  const [widget, setWidget] = React.useState<Widget>(new Widget())

  const fetchWidget = useCallback(async () => {
    let response = await WidgetStore.FindOne({
      id: widgetId
    })
    setWidget(response.widget)
  }, [widgetId])

  const fetchAllWidgets = useCallback(async () => {
    let response = await WidgetStore.FindAll({dashboardId: dashId})
    setWidgets(response.widgets)
  }, [])

  const snackBar = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      variant: variant
    });
  }

  const cloneWidget = useCallback(async (wid: Widget) => {
    let response = await WidgetClonerStore.CloneOne({widgetId: wid.id, targetDashboardId: dashId})

    if (response) {
      snackBar('Widget Cloned!', 'success')
      navigate('/app/bi/dashboard/' + dashId)
    } else {
      snackBar('Failed to Clone Widget!', 'error')
    }
  }, [widgets])

  const setWid = (w: Widget, id: string) => {
    const newWidgetsIdx = widgets.findIndex((d) => d.id == id)
    setWidgets([
      ...widgets.slice(0, newWidgetsIdx),
      w,
      ...widgets.slice(newWidgetsIdx + 1)
    ])
  }

  useEffect(() => {
    fetchWidget()
    fetchAllWidgets()
  }, [fetchWidget, fetchAllWidgets])

  useEffect(() => {
    dispatch(initBitool(dashId))
  }, [dashId])

  const deleteWidget = useCallback(async (wid) => {
    let response = await WidgetStore.DeleteOne({
      id: wid.id
    })
    if (response.deleted) {
      snackBar('Widget Deleted', 'info')
      navigate('/app/bi/dashboard/' + dashId)
    }
  }, [widgets])

  const saveAll = async (wid: Widget, code: string, widArg: string, widName) => {
    let newWidget: Widget = new Widget(wid)
    newWidget.name = widName
    newWidget.arguments = JSON.parse(widArg)
    newWidget.arguments.code = code
    let response = await WidgetStore.UpdateOne({
      widget: newWidget
    })
    if (response.id) {
      await dispatch(widgetUpdate(newWidget.id, newWidget))
      snackBar('Widget Saved', 'info')
    }
  }

  return (
    <div style={{height: "100%", width: "100%"}}>
      {widget && widget.arguments &&
        <Box>
          <Box sx={{marginLeft: 3}}>
            <ButtonGroup>
              <Button variant="contained" onClick={() => {deleteWidget(widget)}}>Delete Widget</Button>
              <Button variant="contained" onClick={() => {cloneWidget(widget)}}>Clone Widget</Button>
            </ButtonGroup>
          </Box>
          <WidgetEditor widget={widget} OnWidSave={saveAll} allWidgets={widgets} setWidget={setWid} queryID={queryId} />
        </Box>
      }
    </div>
  );
};


export default WidgetEditorInterface;