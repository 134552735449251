/* eslint-disable */

// chrome bug
if(typeof String.prototype.replaceAll == "undefined") {
  String.prototype.replaceAll = function(match, replace){
    return this.replace(new RegExp(match, 'g'), () => replace);
  }
}

const toLowerCase = (input: string): string => {
  return input.toLowerCase().trim()
      .replaceAll(' ', '-')
      .replaceAll('_', '-')
}

export default toLowerCase;
