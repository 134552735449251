import * as React from "react";
import {FC, useRef} from "react";
import {alpha, useTheme} from "@mui/material/styles";
import {Box, Card, Tooltip} from "@mui/material";
import {TrendingDownOutlined, TrendingUpOutlined} from "@mui/icons-material";
import CurrencySwitcher from "../../container/currencies/CurrencySwitcher";


interface OppValueDisplay {
    org: string
    mValue: number
    createdOn: string

}

const OppValueDisplay: FC<OppValueDisplay> = (props) => {

    let formatter = Intl.NumberFormat('en', {notation: 'compact'});
    let theme = useTheme()
    const ref = useRef(null)


    return (

        <Box ref={ref} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
            <Tooltip title={"Potential Daily Revenue"}>
                <Card
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        flexDirection: 'column',
                        margin: 1,
                        padding: 1,
                        borderRadius: '10px',
                        backgroundColor: alpha(theme.palette.success.main, 0.08),
                        //border: '2px solid red',
                    }}>



                    <Box sx={{
                        //border: '2px solid blue',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}>

                        <TrendingUpOutlined color={"success"} fontSize={"small"} sx={{
                            //border: '2px solid green',
                            margin:1,
                        }}/>
                        <CurrencySwitcher org={props.org}/>
                        <h1 style={{marginLeft: 10}}> {formatter.format(0 + props.mValue)}</h1>
                    </Box>

                </Card>

            </Tooltip>

            {/*<Divider orientation={"vertical"} flexItem={true} sx={{marginTop: 1}}/>*/}
            <Tooltip title={"Potential Revenue Lost Since Creation"}>
                <Card sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    flexDirection: 'column',
                    margin: 1,
                    padding: 1,
                    borderRadius: '10px',
                    backgroundColor: alpha(theme.palette.error.main, 0.08),
                }}>
                    <Box sx={{
                        //border: '2px solid red',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}>
                        <TrendingDownOutlined color={"error"} fontSize={"small"} sx={{
                            //border: '2px solid green',
                            margin:1,
                        }}/>
                        <CurrencySwitcher org={props.org}/>
                        <h1 style={{marginLeft: 10}}>
                            {formatter.format(
                                dateConverter(new Date(Date.parse(props.createdOn)).toISOString().slice(0, 10),
                                    new Date().toISOString().slice(0, 10)) * props.mValue)}
                        </h1>
                    </Box>
                </Card>
            </Tooltip>


        </Box>

    )



}

const dateConverter = (startDate: string, endDate: string) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    const one_day = 1000 * 60 * 60 * 24;
    // let result = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / (one_day))
    // console.log('date Converter result', result)
    // if (result < 0) {
    //     return 0
    // }
    return Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / (one_day))
}


export default OppValueDisplay

