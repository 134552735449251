import React, {FC, useCallback, useEffect, useState} from "react";
import {
    AppBar,
    Autocomplete,
    Avatar,
    Box,
    Button,
    CardContent,
    CardHeader,
    Checkbox,
    Container,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    outlinedInputClasses,
    Paper,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {
    CheckBoxOutlineBlank, CheckBoxOutlined, CommentOutlined, InfoOutlined, KeyboardBackspace,
} from "@mui/icons-material";
import Card from "../cards/Card";
import {useParams} from "react-router";
import {History, HistoryStore} from "../../../../cog/keystone/history";
import {Member, MemberStore} from "../../../../cog/keystone/member";
import {useMainContext} from "../../../../contexts/MainContext";
import OpportunityCardSkeleton from "./OpportunityCardSkeleton";
import {Card as KanbanCardObj, KanbanStore} from "../../../../cog/keystone/card";
import CardAttribute from "../../../../cog/keystone/card/cardAttribute/CardAttribute";
import {CardAttributeStore} from "../../../../cog/keystone/card/cardAttribute";
import AdditionalInfoSection from "./additionalInfo/AdditionalInfoSection";
import DetailSection from "./details/DetailSection";
import {indexOf, values} from "lodash";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {Theme, useTheme} from "@mui/material/styles";
import {useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import ReactGA from "react-ga4";
import NotFound from "../../../../components/NotFound";
import {NotificationStore} from "../../../../cog/keystone/notifications";
import OpportunityCardSideBar from "./OpportunityCardSideBar";
import CommentCards from "../comments/CommentCards";
import orgChecker from "../../../../utils/orgChecker";
import oldLogoPath from "../../../../logo.svg";
import logoPath from "../../../../ksLogoWhite.svg";

// Setup Props for when using Dialog instead of navigate
export interface ClickThroughIssueProps {
    id?: string,
    onClose?: () => void
}

const Loader: FC = () => {
    return <LinearProgress sx={{
        backgroundColor: "#d6ddde",
        padding: 1,
        border: 1,
        width: "98%",
        borderColor: "#ededed",
        margin: 1,
        borderRadius: 1
    }}/>
}

// Needs an active org from main context but doesn't have one most of the time
const currentOrgId = "1472a9c3-29a6-435c-a231-fbf2dbdc5dd7"; //currently hard coded as default orgid
const currentOrgName = "DKSH";

const OpportunityCard: FC<ClickThroughIssueProps> = (props) => {
    const mainCtx = useMainContext();
    const keystoneCtx = useKeystoneContext();
    let navigate = useNavigate();
    const location = useLocation();
    const doesAnyHistoryEntryExist = location.key
    const [myTheme, setMyTheme] = useState<Theme>(useTheme())

    let counter = 0; // exclusive used to make all the little cards animate in not on the exact same time

    const [theIssue, setIssue] = useState<KanbanCardObj>(null);
    const [currOrgId, setCurrOrgId] = useState<string>(currentOrgId);
    const [currOrgName, setCurrOrgName] = useState<string>(currentOrgName);
    const [history, setHistory] = useState<History[]>(null);
    const [members, setMembers] = useState<Member[]>(null);
    const [found, setFound] = useState<Boolean>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [windowAttributes, setWindowAttributes] = useState<CardAttribute[]>(null)


    const [multiUserToAssign, setMultiUserToAssign] = useState([]);
    const [loadingAssign, setLoadingAssign] = useState<boolean>(true);

    let {id} = useParams();
    let {org} = useParams();

    if (!id) {
        if (keystoneCtx.cardId) {
            id = keystoneCtx.cardId;
        } else {
            return <div> No Card Found </div>;
        }
    }

    const updateHistory = () => {
        // setHUpdated(Math.random() * (1000))
        RefreshHistory()
    }

    async function RefreshHistory() {
        let historyIssue = await HistoryStore.FindMany({id: id});
        if (historyIssue != null) {
            setHistory(null)
            setHistory(historyIssue.history);
        } else {
            setHistory([])
        }
    }

    /*
    * Checks if Main Context has an active org and uses that, otherwise it uses Kimberly Clark
    * */
    const fetchOne = useCallback(async () => {
        setIsLoading(true)
        if (mainCtx.activeOrganization != null) {
            let responseIssue = await KanbanStore.FindOne({id: id, Org: mainCtx.activeOrganization.name});
            // console.log("ResponseIssue: ",responseIssue)
            if (responseIssue.card != null) {setIssue(responseIssue.card);}
            else {setFound(false)}
            let attributes = await CardAttributeStore.FindAll({
                clientName: mainCtx.activeOrganization.name,
                cardId: id
            }).then((r) => {
                return values(r.cardAttributes)
            })
            setWindowAttributes(attributes)
        } else {
            let responseIssue = await KanbanStore.FindOne({id: id, Org: currOrgName});
            setIssue(responseIssue.card);
            let attributes = await CardAttributeStore.FindAll({clientName: currOrgName, cardId: id}).then((r) => {
                return values(r.cardAttributes)
            })
            setWindowAttributes(attributes)
        }

        // setIssue(responseIssue)
        let historyIssue = await HistoryStore.FindMany({id: id});
        // TODO: To do appropriate error handling
        if (historyIssue != null) {
            setHistory(null)
            setHistory(historyIssue.history);
        } else {
            setHistory([])
        }
        setIsLoading(false)
    }, [id]);

    // this needs changes probably, don't know if it works ever
    useEffect(() => {
        keystoneCtx.fetchOppLabels()
        if (mainCtx.activeOrganization != undefined) {
            setCurrOrgId(mainCtx.activeOrganization.id);
            setCurrOrgName(mainCtx.activeOrganization.name);
        } else if (mainCtx.organizations != null) {
            //
        }
        // fetchOne()
    }, [mainCtx.activeOrganization]);

    useEffect(() => {
        if(theIssue && !isLoading){

            ReactGA.event("keystone_view" ,{
                userID: mainCtx.user.id,
                orgID: mainCtx.activeOrganization.id,
                pageName: 'Opportunity',
                oppID: theIssue.id
            });
        }



    }, [theIssue, isLoading])

    useEffect(() => {
        if ( members != null && theIssue != null) {
            let tempUsers = []
            try {
                JSON.parse(`{"AssignedTo": ${theIssue.assignedTo} }`)
            } catch (e) {
                // Data is formatted wrong, make it empty
                theIssue.assignedTo = ""
            }
            if (theIssue.assignedTo != "" && theIssue.assignedTo != null) {
                Object.values(members).map((item) => {
                    if (indexOf(JSON.parse(`{"AssignedTo": ${theIssue.assignedTo} }`)['AssignedTo'], item.id) != -1) {
                        tempUsers.push(item)
                    }
                })
            } else {

            }
            setMultiUserToAssign(tempUsers)
            setLoadingAssign(false)
        }
    }, [members, theIssue]);

    const doMembers = (async () => {
        if (mainCtx.activeOrganization != null) {
            let membersIssue = await MemberStore.FindMany({orgID: mainCtx.activeOrganization.id});
            return membersIssue.members;
        } else {
            let membersIssue = await MemberStore.FindMany({orgID: currOrgId});
            return membersIssue.members;
        }
    });

    useEffect(() => {
        if (mainCtx.activeOrganization != undefined) {
            setCurrOrgId(mainCtx.activeOrganization.id);
        }
        doMembers().then(r => setMembers(r)).then(fetchOne);
    }, [id]);

    const handleHomeReturn = () => {
        if (doesAnyHistoryEntryExist !== "default") {
            navigate(-1)
        } else {
            navigate('/app/' + org + '/opportunities')
        }
    }

    /*
    * todo: timeline doesn't refresh when a new comment is made, and the timeline is sorted as oldest first and newest on the bottom
    * */

    if (found == false) {
        return <NotFound/>
    }

    return (
        <>
            <Helmet>
                <title>Opportunity</title>
            </Helmet>
                <Box
                    sx={{
                        flexGrow: 1,
                        mt: !orgChecker(mainCtx.activeOrganization.name) ? '19px' : '0px'
                    }}
                >
                    <Box>
                        <Tooltip title={"Return to Previous Page"}>
                            <IconButton color="primary" onClick={handleHomeReturn} sx={{ marginLeft: 2}}>
                                <KeyboardBackspace sx={{ color: (theme) => (theme.palette.mode === 'dark' ? "#f2bc53" : '#6b778c') }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Container maxWidth={false}>
                        {keystoneCtx.cardId ?
                          <AppBar sx={{position: "relative"}}>
                              <Toolbar>
                                  {loadingAssign == false &&
                                    <Grid container display={"grid"} gridTemplateColumns={"2fr 0.5fr"} width={"50%"} gridAutoFlow={"row"} gridAutoRows={"auto"} gap={1} p={1} mr={2}>
                                        <Autocomplete
                                          value={multiUserToAssign}
                                          onChange={(event, newValue) => {
                                              setMultiUserToAssign(newValue);
                                          }}
                                          multiple
                                          id="checkboxes-tags-demo"
                                          limitTags={2}
                                          options={Object.values(members)}
                                          defaultValue={theIssue.assignedTo != "" ?
                                            Object.values(members).map((item) => {
                                                if (indexOf(JSON.parse(`{"AssignedTo": ${theIssue.assignedTo} }`)['AssignedTo'], item.id) != -1) {
                                                    return item
                                                }
                                            }): []}
                                          disableCloseOnSelect
                                          getOptionLabel={(option) => option.displayName}
                                          renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                                                  checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                                  // style={{ marginRight: 8 }}
                                                  checked={selected}
                                                />
                                                <CardHeader
                                                  avatar={
                                                      <Avatar
                                                        src={option.avatar}
                                                        alt={option.displayName}
                                                        variant={"circular"}
                                                        sx={{width: 24, height: 24}}
                                                      />
                                                  }
                                                  title={option.displayName} disableTypography={true}
                                                  sx={{padding: 0}}
                                                  key={`AssignedUser_${option.id}`}
                                                >
                                                </CardHeader>
                                            </li>
                                          )}
                                          renderInput={(params) => (
                                            <TextField {...params} label="Assignees" placeholder="Assignees" value={multiUserToAssign}  />
                                          )}
                                        />
                                        <Button onClick={() => {
                                            // console.log("AutoComplete Box", multiUserToAssign)
                                            setLoadingAssign(true)
                                            KanbanStore.AssignMultiUsers({
                                                assignUsers: multiUserToAssign.map((v) => {
                                                    return v.id
                                                }),
                                                cardIDs: [theIssue.id],
                                                client: mainCtx.activeOrganization.name,
                                                userID: mainCtx.user.id
                                            }).then((p) => {
                                                // TODO: When a user is already assigned and a new user is assigned/removed then it still notifys the user
                                                multiUserToAssign.map((card) => {
                                                    NotificationStore.CreateOne({
                                                        ClientName: mainCtx.activeOrganization.name,
                                                        UserId: mainCtx.user.id,
                                                        CardId: card,
                                                        ActionType: "Assigned",
                                                        Description: "",
                                                        Platform: "Internal",
                                                        IsRead: false
                                                    })
                                                })
                                                theIssue.assignedTo = p.toString()
                                                setLoadingAssign(false)
                                                // console.log("p", p)
                                                // refreshRows()
                                            })
                                        }} variant={"contained"}
                                                disabled={loadingAssign}
                                        >
                                            Save Changes
                                        </Button>
                                    </Grid>}
                              </Toolbar>
                          </AppBar> : null}
                        {theIssue && !isLoading ?
                          <Box>
                              <Box sx={{ mb: 2 }}>
                                  <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={3}
                                  >
                                      <Grid item>
                                          <Typography variant={"h4"} >
                                              {theIssue.name}
                                          </Typography>
                                      </Grid>
                                  </Grid>
                              </Box>
                              {/*<Divider sx={{ mb: 2 }} />*/}
                              <Grid container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                              >
                                  <Grid item
                                        md={10}
                                        xs={12}
                                        sx={{
                                      // border:'1px solid red',
                                  }}>
                                      <Box sx={{ padding:2, borderRadius: '16px', }}>
                                          <Grid container spacing={0} columns={12}>
                                              <Grid item xs={12} md={12} xl={12}>
                                                  {history != null ?
                                                    <DetailSection
                                                      org={mainCtx.activeOrganization.name}
                                                      mValue={theIssue.mValue}
                                                      description={theIssue.description}
                                                      oppType={theIssue.opportunityType}
                                                      history={history}
                                                      status={theIssue.status}
                                                      repeat={theIssue.repeat}
                                                    /> : <Loader/>}
                                              </Grid>
                                              <Grid item xs={12} md={12} xl={12}>
                                                  {windowAttributes != null ?
                                                    <AdditionalInfoSection
                                                      cardName={theIssue.name}
                                                      additionalDisplayValue={theIssue.additionalDisplayColumn}
                                                      opportunityType={theIssue.opportunityType}
                                                      displayValue={theIssue.displayValue}
                                                      role={theIssue.role}
                                                      level={theIssue.level}
                                                      levelFilter={theIssue.levelFilter}
                                                      org={mainCtx.activeOrganization.name}
                                                      attributes={windowAttributes}
                                                      v0={theIssue.v0}
                                                    /> : <Loader/>
                                                  }
                                              </Grid>
                                              <Grid item xs={12} md={12} xl={12}>
                                                  <Typography variant={"h3"} sx={{mt: 2, mb: 4}}>
                                                      <Box sx={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          flexWrap: "wrap"
                                                      }}>
                                                          <CommentOutlined sx={{fontSize: "2rem", mr: 2}}/> Comments
                                                      </Box>
                                                  </Typography>
                                                  <Paper>
                                                      <CommentCards cardID={theIssue.id} user={mainCtx.user} orgMembers={members} updateHistory={updateHistory}/>
                                                  </Paper>
                                              </Grid>
                                          </Grid>
                                      </Box>
                                  </Grid>
                                  <Grid item md={2} xs={12} >
                                      <OpportunityCardSideBar
                                        theIssue={theIssue}
                                        multiUserToAssign={multiUserToAssign}
                                        setMultiUserToAssign={setMultiUserToAssign}
                                        setLoadingAssign={setLoadingAssign}
                                        loadingAssign={loadingAssign}
                                      />
                                  </Grid>
                              </Grid>
                          </Box>:  <Box marginRight={1}><OpportunityCardSkeleton/></Box> }
                    </Container>
                </Box>
        </>
    );
};

export default OpportunityCard;