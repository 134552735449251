/* eslint-disable */

export default class Dashboard {
    public id: string = '';
    public name: string = '';
    public organizationId: string = '';

    constructor(dashboard?: Dashboard) {
        if (!dashboard) {
            return;
        }
        this.id = dashboard.id;
        this.name = dashboard.name;
        this.organizationId = dashboard.organizationId;
    }
}
