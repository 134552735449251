import React, {FC, useState} from "react";
import {Box} from "@mui/system";
import {
    Autocomplete, Avatar, Badge, CardHeader, Checkbox, Chip, Dialog, Divider, IconButton, Popover, TextField, Tooltip
} from "@mui/material";
import {
    AddCircleOutline,
    AddTask,
    CalendarMonth,
    CheckBoxOutlineBlank,
    CheckBoxOutlined,
    DoNotDisturb,
    FilterList,
    Flag,
    GroupAdd,
    HighlightOffOutlined,
    Label,
    Person,
    PersonOffOutlined,
    PlaylistAddOutlined,
    QueryStats
} from "@mui/icons-material";
import {KanbanStore} from "../../../cog/keystone/card";
import {useMainContext} from "../../../contexts/MainContext";
import {useKeystoneContext} from "../../../contexts/KeystoneContext";
import ConfirmActionModal from "../../../pages/app/keystone/exlude/ConfirmActionModal";
import {ExcludeStore} from "../../../cog/keystone/exclude";
import {useSnackbar} from "notistack";
import OverviewDateSelector from "../../../pages/app/keystone/explore/overview/widgets/OverviewDateSelector";
import GoalDialog from "../../../pages/app/keystone/opportunity/actions/goals/GoalDialog";
import {NotificationStore} from "../../../cog/keystone/notifications";

let dialogBody = () => {
    return (
        <div>
        </div>
    )
};

export interface TopBarProps {
    // members: Member[],
    // selectCards: boolean,
    // setSelectCards: () => void,
    // RefreshKeystoneData: () => void,
    // selectedCardsIDs: any[]
    // loading: boolean
    // setLoading: (v: boolean) => void,
    // fontSize?: any

}

const TopBar: FC<TopBarProps> = (props) => {
    const mainCtx = useMainContext();
    const keystoneCtx = useKeystoneContext()
    const {enqueueSnackbar} = useSnackbar();
    const autoHideSnackDuration: number = 2500 // time it takes to hide snackbars
    const [multiUserToAssign, setMultiUserToAssign] = useState([]);
    const [multiLabelToAssign, setMultiLabelToAssign] = useState([]);
    const [confirmActionOpen, setConfirmActionOpen] = React.useState(false);
    const [anchorDateSelector, setAnchorDateSelector] = React.useState<HTMLButtonElement | null>(null);
    const [addGoalLoading, setAddGoalLoading] = useState<boolean>(false);
    const [goalDialogOpen, setGoalDialogOpen] = useState<boolean>(false);
    const [labelDialogOpen, setLabelDialogOpen] = useState<boolean>(false);
    const { appliedFiltersCount } = useKeystoneContext();

    const FormatUsersToString = () => {
        let tempAssign = ""
        let tempMultiUsers: string[] = multiUserToAssign.map((v) => {
            return v.id
        })
        // if no users are assigned, just assign the new one
        if (tempMultiUsers == null || tempMultiUsers.length < 1) {
            //No User to assigned, make it a blank new one
            tempAssign = '[""]'
        } else {
            // user assignments already exist, extract, check for duplicates, and add new user assign
            let tempAssigns = JSON.parse(`{"AssignedTo": "${tempMultiUsers.toString()}" }`)['AssignedTo']
            // if (tempAssigns.indexOf(request.userIDToAssign) == -1) {
            //     tempAssigns.push(request.userIDToAssign)
            // }

            tempAssign = '["' + (tempAssigns.toString()).replaceAll(',', '", "') + '"]'
        }
        return tempAssign
    }

    const FormatCardIdsToString = () => {
        if (keystoneCtx.selectedCardsID.length < 1) {
            // No cards to update
            return '[""]'
        }
        let tempCardIDs = "["
        keystoneCtx.selectedCardsID.map((val, index) => {
            if (index > 0) {
                tempCardIDs += ", "
            }
            tempCardIDs += '"' + val + '"'
        })
        tempCardIDs += "]"
        return tempCardIDs
    }

    // RefreshKeystoneData : flips switch for fetching cards in keystone
    const RefreshKeystoneData = () => {
        keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
    }

    // AddCurrentUserToFilter: adds current user to filters
    const AddCurrentUserToFilter = () => {
        let idx = keystoneCtx.filters.findIndex(f => f.values == mainCtx.user.id)
        if (idx == -1) {
            //can add
            keystoneCtx.setFilters([...keystoneCtx.filters,
                {
                    prefix: "AND",
                    header: "assigned_to",
                    operation: "=",
                    values: mainCtx.user.id
                }
            ])
        }
        enqueueSnackbar('Filtered to you', {
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'top'
            },
            variant: 'success',
            autoHideDuration: autoHideSnackDuration,
            preventDuplicate: true
        });
    }

    // RemoveCurrentUserToFilter : Removes current user from filters
    const RemoveCurrentUserToFilter = () => {
        let idx = keystoneCtx.filters.findIndex(f => f.values == mainCtx.user.id)
        if (idx > -1) {
            //can remove
            keystoneCtx.setFilters([
                ...keystoneCtx.filters.slice(0, idx),
                ...keystoneCtx.filters.slice(idx + 1)
            ])
        }
        enqueueSnackbar('Filter Removed', {
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'top'
            },
            variant: 'success',
            autoHideDuration: autoHideSnackDuration,
            preventDuplicate: true
        });

    }

    // this lives somewhere else now
    // handleConfirmDialogue : for excluding multiple issues confirmation
    // const handleConfirmDialogue = () => {
    //     setConfirmActionOpen(true)
    //     dialogBody = () => {
    //         return (
    //             <ConfirmActionModal
    //                 cancelAction={handleConfirmClose}
    //                 confirmAction={handleConfirmUpdate}
    //                 confirmationWarningMessage={"Warning, this will affect all users and exclude future opportunities and issues that might be relevant."}
    //
    //             />
    //         );
    //     }
    //     // setExcDialogOpen(true);
    // }

    // handleConfirmClose : for closing confirmation modal
    const handleConfirmClose = () => {
        setConfirmActionOpen(false);
        setLabelDialogOpen(false)
        setMultiUserToAssign([])
        setMultiLabelToAssign([])
        enqueueSnackbar('Action Cancelled', {
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'top'
            },
            variant: 'warning',
            autoHideDuration: autoHideSnackDuration,
            preventDuplicate: true
        });
    };

    // moved this, just leaving for now in case it moved back, this one is probs wrong also
    // async function handleConfirmUpdate() {
    //     keystoneCtx.setLoadingBar(true)
    //     // keystoneCtx.setIsLoading(true)
    //     // handleConfirmClose()
    //
    //     setConfirmActionOpen(false);
    //     ExcludeStore.UpdateMultiple({
    //         orgName: mainCtx.activeOrganization.name,
    //         valueChange: keystoneCtx.selectedCardsNames,
    //         userID: mainCtx.user.id
    //         // header: props.header
    //     }).then(() => {
    //         keystoneCtx.setLoadingBar(false)
    //         // keystoneCtx.setLoadingBar(false)
    //         // handleConfirmClose()
    //         enqueueSnackbar('Opportunities Excluded', {
    //             anchorOrigin: {
    //                 horizontal: 'center',
    //                 vertical: 'top'
    //             },
    //             variant: 'success',
    //             autoHideDuration: autoHideSnackDuration,
    //             preventDuplicate: true
    //         });
    //     })
    // }

    // Popover for user assign things
    const [assignPopoverAnchorEl, setAssignPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleAssignPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAssignPopoverAnchorEl(event.currentTarget);
    };

    const handleAssignPopoverClose = () => {
        setAssignPopoverAnchorEl(null);
        if (multiUserToAssign.length == 0) {
            return;
        }

        dialogBody = () => {
            return (
                <ConfirmActionModal
                    cancelAction={handleConfirmClose}
                    confirmAction={handleMultiAssignConfirm}
                    confirmationWarningMessage={"Confirm to assign " + multiUserToAssign.length + " selected users to " + keystoneCtx.selectedCardsID.length + " selected opportunities."}

                />
            );
        }
        setConfirmActionOpen(true)
        // setMultiUserToAssign([])
    };

    async function handleMultiAssignConfirm() {
        // console.log("multiUserToAssign", multiUserToAssign)
        keystoneCtx.setLoadingBar(true)
        setConfirmActionOpen(false);

        // Update Local Values
        // keystoneCtx.UpdateMultipleCardValuesLocally(FormatCardIdsToString(), "assigned_to", FormatUsersToString())

        KanbanStore.AssignMultiUsers({
            assignUsers: multiUserToAssign.map((v) => {
                return v.id
            }),
            cardIDs: keystoneCtx.selectedCardsID,
            client: mainCtx.activeOrganization.name,
            userID: mainCtx.user.id
        }).then((p) => {
            multiUserToAssign.map((user) => {
                keystoneCtx.selectedCardsID.map((card) => {
                    NotificationStore.CreateOne({
                        ClientName: mainCtx.activeOrganization.name,
                        UserId: user.id,
                        CardId: card,
                        ActionType: "Assigned",
                        Description: "",
                        Platform: "Internal",
                        IsRead: false
                    })
                })}),
            // theIssue.assignedTo = p.toString()
            keystoneCtx.setLoadingBar(false)
            setMultiUserToAssign([])
            enqueueSnackbar('Users Assigned', {
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'top'
                },
                variant: 'success',
                autoHideDuration: autoHideSnackDuration,
                preventDuplicate: true
            });
            // setMultiUserToAssign([])
            // setConfirmActionOpen(false)
            RefreshKeystoneData()
            // console.log("p", p)
            // refreshRows()
        })

    }

    const open = Boolean(assignPopoverAnchorEl);
    const id = open ? 'simple-popover' : undefined;


    // Popover for Opportunity Label things
    const [labelPopoverAnchorEl, setLabelPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleLabelPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setLabelPopoverAnchorEl(event.currentTarget);
    };
    const handleLabelPopoverClose = () => {
        setLabelPopoverAnchorEl(null);
        if (multiLabelToAssign.length == 0) {
            return;
        }

        dialogBody = () => {
            return (
                <ConfirmActionModal
                    cancelAction={handleConfirmClose}
                    confirmAction={handleMultiLabelConfirm}
                    confirmationWarningMessage={"Confirm to add " + multiLabelToAssign.length + " selected labels to " + keystoneCtx.selectedCardsID.length + " selected opportunities."}

                />
            );
        }
        setLabelDialogOpen(true)
        // setMultiUserToAssign([])
    };

    async function handleMultiLabelConfirm() {

        // console.log("multiLabelToAssign", multiLabelToAssign)
        keystoneCtx.setLoadingBar(true)
        setLabelDialogOpen(false)
        KanbanStore.AssignMultiLabels({
            oppLabelIDs: multiLabelToAssign.map((v: any) => {
                return v.id
            }),
            cardIDs: keystoneCtx.selectedCardsID,
            client: mainCtx.activeOrganization.name,
            userID: mainCtx.user.id
        }).then((r) => {
            // TODO: Needs to look at context to find card assignees
            // keystoneCtx.selectedCardsID.map((card) => {
            //     NotificationStore.CreateOne({
            //         ClientName: mainCtx.activeOrganization.name,
            //         UserId: mainCtx.user.id,
            //         CardId: card,
            //         ActionType: "Updated",
            //         Description: "",
            //         Platform: "Internal",
            //         IsRead: false
            //     })
            // })
            keystoneCtx.setLoadingBar(false)
            setMultiLabelToAssign([])
            enqueueSnackbar('Cards Labeled', {
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'top'
                },
                variant: 'success',
                autoHideDuration: autoHideSnackDuration,
                preventDuplicate: true
            });
            RefreshKeystoneData()
        })

    }
    const openLabelPopover = Boolean(labelPopoverAnchorEl);
    const idLabelPopover = openLabelPopover ? 'simple-popover' : undefined;

    const dateSelOpen = Boolean(anchorDateSelector);
    const dateSelID = open ? 'simple-popover' : undefined;

    const handleDateSelectorPopperOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorDateSelector(event.currentTarget);
    }

    const handleDateSelectorPopperClose = () => {
        setAnchorDateSelector(null);
    }

    // handleConfirmClose : for closing confirmation modal
    const handleGoalDialogClose = () => {
        setGoalDialogOpen(false);
        // setMultiUserToAssign([])
        // enqueueSnackbar('Action Cancelled', {
        //     anchorOrigin: {
        //         horizontal: 'center',
        //         vertical: 'top'
        //     },
        //     variant: 'warning',
        //     autoHideDuration: autoHideSnackDuration,
        //     preventDuplicate: true
        // });
    };

    const handleGoalDialogOpen = () => {
        let tempCardIDs = "("
        keystoneCtx.selectedCardsID.map((val, index) => {
            if (index > 0) {
                tempCardIDs += ", "
            }
            tempCardIDs += "'" + val + "'"
        })
        tempCardIDs += ")"
        dialogBody = () => {
            return (
                <GoalDialog
                    loadingGoal={addGoalLoading}
                    issueIdsToAssign={keystoneCtx.selectedCardsID}
                    setLoadingGoal={setAddGoalLoading}
                    onClick={handleGoalDialogClose}
                />
            );
        }
        setGoalDialogOpen(true);
    }

    return (
        <Box
            sx={{}}>
            <Box sx={{
                fontSize: "small",
                display: 'flex',
                justifyContent: 'space-between',
                px: 1,
                alignItems: "center",
                // width: "100%",
                flexGrow: 10,
                // gap: "10px"
            }}>
                <Box>

                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    px: 0,
                    alignItems: "center",
                    gap: "5px"
                }}>
                    {keystoneCtx.toolBarDisplayOptions.selectOpps &&<Tooltip title={keystoneCtx.selectCards == false
                        ? "Click to select multiple opportunities"
                        : "Click to deselect all opportunities"}>
                        <Badge color="info" badgeContent={keystoneCtx.selectedCardsID.length}>
                            <IconButton
                                disabled={keystoneCtx.loadingBar}
                                onClick={() => {
                                    // console.log("props select", props.selectCards)
                                    keystoneCtx.handleSelectCards()
                                }}
                                // variant={"text"}
                                color={!keystoneCtx.selectCards ? "inherit" : "success"}
                                sx={{
                                    padding: 1,
                                    margin: 0,
                                    animation: keystoneCtx.selectCards == true && "shake 2s cubic-bezier(.36,.07,.19,.97) both",
                                    animationIterationCount: keystoneCtx.selectCards == true && "infinite",
                                    "@keyframes shake": {
                                        "0%, 100%": {transform: "translate(0px, 0px) rotate(0deg)"},
                                        "10%, 90%": {transform: "translate(0px, 0px) rotate(1deg)"},
                                        "20%, 80%": {transform: "translate(0px, 0px) rotate(0deg)"},
                                        "30%, 70%": {transform: "translate(0px, 0px) rotate(-1deg)"},
                                        "40%, 60%": {transform: "translate(0px, 0px) rotate(0deg)"},
                                        "50%": {transform: "translate(0px, 0px) rotate(1deg)"},
                                    }
                                }}
                            >
                                <AddCircleOutline
                                    fontSize={"medium"}
                                    sx={{
                                        padding: 0,
                                        margin: 0
                                    }}
                                />
                            </IconButton>
                        </Badge>
                    </Tooltip>}
                    {keystoneCtx.toolBarDisplayOptions.selectOpps &&
                        <div>
                        <Tooltip title={"Select Users to Assign to Selected Opportunities"}>
                            <div>
                            <Badge color="info" badgeContent={multiUserToAssign.length}>
                                <IconButton
                                    onClick={handleAssignPopoverClick}
                                    color={"inherit"}
                                    disabled={keystoneCtx.loadingBar || !keystoneCtx.selectCards || keystoneCtx.selectedCardsNames.length == 0}
                                >
                                    <GroupAdd/>
                                </IconButton>
                            </Badge>
                            </div>
                        </Tooltip>
                        <Popover
                            //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                            disableEnforceFocus
                            id={id}
                            open={open}
                            anchorEl={assignPopoverAnchorEl}
                            onClose={handleAssignPopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            {mainCtx.members != null &&
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    px: 0.2,
                                    // alignItems: "center",
                                    // flexGrow: 10,
                                    gap: "10px",
                                    margin: 1
                                }}>


                                    <Box color={"inherit"}>
                                        <Autocomplete
                                            sx={{
                                                width: "30vw",
                                                // borderColor: "white",
                                                // '& input': {
                                                //     color: "white",
                                                //     borderColor: "white",
                                                // },
                                                // '& label': {
                                                //     color: "white",
                                                //     borderColor: "white",
                                                // },
                                                // '& label.Mui-focused': {
                                                //     color: 'white',
                                                // },
                                                // '& .MuiInput-underline:after': {
                                                //     borderBottomColor: 'white',
                                                // },
                                                // '& .MuiOutlinedInput-root': {
                                                //     '& fieldset': {
                                                //         borderColor: 'white',
                                                //     },
                                                //     '&:hover fieldset': {
                                                //         borderColor: 'white',
                                                //     },
                                                //     '&.Mui-focused fieldset': {
                                                //         borderColor: 'white',
                                                //     },
                                                // },
                                            }}
                                            // disabled={keystoneCtx.loadingBar}
                                            value={multiUserToAssign}
                                            onChange={(event, newValue) => {
                                                setMultiUserToAssign(newValue);
                                            }}
                                            // color={"inherit"}
                                            multiple
                                            id="Assignee_CheckBox_Autocomplete"
                                            limitTags={2}
                                            options={Object.values(mainCtx.members)}
                                            defaultValue={[]}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.displayName}
                                            renderOption={(props, option, {selected}) => (
                                                <li {...props} >
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                                        checkedIcon={<CheckBoxOutlined fontSize="small"/>}
                                                        // style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    <CardHeader
                                                        avatar={
                                                            <Avatar
                                                                src={option.avatar}
                                                                alt={option.displayName}
                                                                variant={"circular"}
                                                                sx={{width: 24, height: 24}}
                                                            />
                                                        }
                                                        title={option.displayName} disableTypography={true}
                                                        sx={{
                                                            padding: 0
                                                        }}
                                                        key={`AssignedUser_${option.id}`}
                                                    >
                                                    </CardHeader>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        // color: "white",
                                                        // borderColor: "white !important",
                                                        // accentColor: "white"
                                                    }}
                                                    label="Assignees"
                                                    placeholder="Assignees"
                                                    value={multiUserToAssign}
                                                    size={"small"}/>
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option.displayName}
                                                        size="small"
                                                        // color={"default"}
                                                        deleteIcon={<HighlightOffOutlined style={{color: "inherit"}}/>}
                                                        sx={{
                                                            // color: "white",
                                                            // borderColor: "white",
                                                            // accentColor: "white"
                                                        }}
                                                        {...getTagProps({index})}
                                                    />
                                                ))
                                            }
                                        />
                                    </Box>


                                </Box>
                            }
                        </Popover>
                    </div>}

                    {keystoneCtx.toolBarDisplayOptions.selectOpps &&
                        <div>
                            <Tooltip title={"Label Selected Opportunities"}>
                                <div>
                                    <Badge color="info" badgeContent={multiLabelToAssign.length}>
                                        <IconButton
                                            onClick={handleLabelPopoverClick}
                                            color={"inherit"}
                                            disabled={keystoneCtx.loadingBar || !keystoneCtx.selectCards || keystoneCtx.selectedCardsNames.length == 0}
                                        >
                                            <Label />
                                        </IconButton>
                                    </Badge>
                                </div>
                            </Tooltip>
                            <Popover
                                //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                                disableEnforceFocus
                                id={idLabelPopover}
                                open={openLabelPopover}
                                anchorEl={labelPopoverAnchorEl}
                                onClose={handleLabelPopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                {mainCtx.members != null &&
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        px: 0.2,
                                        // alignItems: "center",
                                        // flexGrow: 10,
                                        gap: "10px",
                                        margin: 1
                                    }}>


                                        <Box color={"inherit"}>
                                            <Autocomplete
                                                sx={{
                                                    width: "30vw",

                                                }}

                                                value={multiLabelToAssign}
                                                onChange={(event, newValue) => {
                                                    setMultiLabelToAssign(newValue);
                                                }}
                                                multiple
                                                id="Assignee_CheckBox_Autocomplete"
                                                limitTags={2}
                                                options={Object.values(keystoneCtx.opportunityLabels)}
                                                defaultValue={[]}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.label}
                                                renderOption={(props, option, {selected}) => (
                                                    <li {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                                            checkedIcon={<CheckBoxOutlined fontSize="small"/>}
                                                            // style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        <CardHeader
                                                            title={option.label} disableTypography={true}
                                                            sx={{padding: 0}}
                                                            key={`OppLabel_${option.id}`}
                                                        >

                                                        </CardHeader>

                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Labels"
                                                        placeholder="Labels"
                                                        value={multiLabelToAssign}
                                                        size={"small"}/>
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option.label}
                                                            size="small"
                                                            deleteIcon={<HighlightOffOutlined style={{color: "inherit"}}/>}
                                                            {...getTagProps({index})}
                                                        />
                                                    ))
                                                }
                                            />
                                        </Box>


                                    </Box>
                                }
                            </Popover>

                        </div>}



                    {/*<Tooltip title={"Assign Users: Click to Assign selected users to selected opportunities"}>*/}
                    {/*    <span>*/}
                    {/*    <IconButton*/}
                    {/*        onClick={() => {*/}
                    {/*            // console.log("multiUserToAssign", multiUserToAssign)*/}
                    {/*            keystoneCtx.setLoadingBar(true)*/}
                    {/*            KanbanStore.AssignMultiUsers({*/}
                    {/*                assignUsers: multiUserToAssign.map((v) => {*/}
                    {/*                    return v.id*/}
                    {/*                }),*/}
                    {/*                cardIDs: keystoneCtx.selectedCardsID,*/}
                    {/*                client: mainCtx.activeOrganization.name,*/}
                    {/*                userID: mainCtx.user.id*/}
                    {/*            }).then((p) => {*/}
                    {/*                // theIssue.assignedTo = p.toString()*/}
                    {/*                keystoneCtx.setLoadingBar(false)*/}
                    {/*                RefreshKeystoneData()*/}
                    {/*                // console.log("p", p)*/}
                    {/*                // refreshRows()*/}
                    {/*            })*/}
                    {/*        }}*/}
                    {/*        color={"inherit"}*/}
                    {/*        // variant={"text"}*/}
                    {/*        disabled={keystoneCtx.loadingBar || !keystoneCtx.selectCards || keystoneCtx.selectedCardsNames.length == 0}*/}
                    {/*    >*/}
                    {/*        <Assignment/>*/}
                    {/*    </IconButton>*/}
                    {/*        </span>*/}
                    {/*</Tooltip>*/}
                    {keystoneCtx.toolBarDisplayOptions.selectOpps &&
                        <Tooltip title={"Set ToDo: Click to add selected opportunities to the ToDo list"}>
                        <span>
                        <IconButton
                            onClick={() => {
                                // console.log("multiUserToAssign", multiUserToAssign)
                                keystoneCtx.setLoadingBar(true)
                                let tempCardIDs = "("
                                keystoneCtx.selectedCardsID.map((val, index) => {
                                    if (index > 0) {
                                        tempCardIDs += ", "
                                    }
                                    tempCardIDs += "'" + val + "'"
                                })
                                tempCardIDs += ")"
                                // keystoneCtx.UpdateMultipleCardValuesLocally(
                                //     FormatCardIdsToString(),
                                //     "list_id",
                                //     "3f504149-d481-497e-b7d1-a450ff10d82e")
                                KanbanStore.UpdateMultiple({
                                    columnName: "list_id",
                                    valueChange: "3f504149-d481-497e-b7d1-a450ff10d82e",
                                    organisation: mainCtx.activeOrganization.name,
                                    ID: tempCardIDs,
                                    UserId: mainCtx.user.id

                                }).then((p) => {
                                    // TODO: Needs to look at context to find card assignees
                                    // keystoneCtx.selectedCardsID.map((card) => {
                                    //     NotificationStore.CreateOne({
                                    //         ClientName: mainCtx.activeOrganization.name,
                                    //         UserId: mainCtx.user.id,
                                    //         CardId: card,
                                    //         ActionType: "Updated",
                                    //         Description: "",
                                    //         Platform: "Internal",
                                    //         IsRead: false
                                    //     })
                                    // })
                                    // theIssue.assignedTo = p.toString()
                                    keystoneCtx.setLoadingBar(false)
                                    enqueueSnackbar('Opportunities Updated', {
                                        anchorOrigin: {
                                            horizontal: 'center',
                                            vertical: 'top'
                                        },
                                        variant: 'success',
                                        autoHideDuration: autoHideSnackDuration,
                                        preventDuplicate: true
                                    });
                                    RefreshKeystoneData()
                                    // console.log("p", p)
                                    // refreshRows()
                                })
                            }}
                            color={keystoneCtx.loadingBar || !keystoneCtx.selectCards || keystoneCtx.selectedCardsNames.length == 0 ? "inherit" : "inherit"}
                            // variant={"text"}
                            disabled={keystoneCtx.loadingBar || !keystoneCtx.selectCards || keystoneCtx.selectedCardsNames.length == 0}
                        >
                            <PlaylistAddOutlined></PlaylistAddOutlined>
                        </IconButton>
                            </span>
                    </Tooltip>}
                    {keystoneCtx.toolBarDisplayOptions.selectOpps &&
                        <Tooltip title={"Exclude Opportunities: Click to exclude selected opportunities"}>
                        <Box sx={{}}>
                            <IconButton
                                onClick={() => {
                                    // handleConfirmDialogue()
                                    // handleConfirmUpdate().then()
                                    keystoneCtx.handleExcludeFilterOpen()
                                    if (keystoneCtx.excludeFilterOpen == true) {
                                        enqueueSnackbar('Changes Discarded', {
                                            anchorOrigin: {
                                                horizontal: 'right',
                                                vertical: 'top'
                                            },
                                            variant: 'success',
                                            preventDuplicate: true,
                                            autoHideDuration: autoHideSnackDuration
                                        });
                                    }
                                }}
                                // variant="text"
                                color={"inherit"}
                                disabled={keystoneCtx.loadingBar || keystoneCtx.myRows == null}
                                // disabled={keystoneCtx.loadingBar || !keystoneCtx.selectCards || keystoneCtx.selectedCardsNames.length == 0}
                            >
                                <DoNotDisturb/>
                            </IconButton>
                        </Box>
                    </Tooltip>}
                    {keystoneCtx.toolBarDisplayOptions.selectOpps &&
                        <Tooltip title={"Add Selected to Goal: Click to assign selected opportunities to a goal"}>
                            <Box sx={{}}>
                                <IconButton
                                    onClick={() => {
                                        handleGoalDialogOpen()
                                        // handleConfirmUpdate().then()
                                    }}
                                    // variant="text"
                                    color={"inherit"}
                                    disabled={keystoneCtx.loadingBar || !keystoneCtx.selectCards || keystoneCtx.selectedCardsID.length == 0}
                                    // disabled={false}
                                >
                                    <Flag/>
                                </IconButton>
                            </Box>
                        </Tooltip>}
                    {keystoneCtx.toolBarDisplayOptions.selectOpps &&
                        <Tooltip title={"Create Goal: Click to open create goal dialogue"}>
                            <Box sx={{}}>
                                <IconButton
                                    onClick={() => {
                                        keystoneCtx.toggleGoalOpen()
                                        // handleConfirmUpdate().then()
                                    }}
                                    // variant="text"
                                    color={"inherit"}
                                    disabled={keystoneCtx.loadingBar }
                                    // disabled={false}
                                >
                                    <AddTask/>
                                </IconButton>
                            </Box>
                        </Tooltip>}
                    {keystoneCtx.toolBarDisplayOptions.selectOpps && <Box>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                color: "white",
                                height: "4vh"
                            }}/>
                    </Box>}
                    {keystoneCtx.toolBarDisplayOptions.filterToMe &&
                        <Tooltip
                            title={
                                keystoneCtx.filters.find(f => f.values == mainCtx.user.id) != null
                                    ? "Remove Filter to me: Click to remove filter for opportunities assigned to you"
                                    : "Filter to me: Click to filter to opportunities assigned to you"
                            }>
                        <span>
                        <IconButton
                            // variant={"text"}
                            onClick={() => {
                                if (keystoneCtx.filters.find(f => f.values == mainCtx.user.id) != null) {
                                    RemoveCurrentUserToFilter()
                                } else {
                                    AddCurrentUserToFilter()
                                }
                                keystoneCtx.setLoadingBar(false)
                                RefreshKeystoneData()
                            }}
                            color={keystoneCtx.loadingBar || !keystoneCtx.selectCards || keystoneCtx.selectedCardsNames.length == 0 ? "inherit" : "inherit"}
                            disabled={keystoneCtx.loadingBar}
                        >
                            {
                                keystoneCtx.filters.find(f => f.values == mainCtx.user.id) != null
                                    ? <PersonOffOutlined></PersonOffOutlined>
                                    : <Person></Person>
                            }
                        </IconButton>
                            </span>
                    </Tooltip>}

                    {keystoneCtx.toolBarDisplayOptions.filter && <Tooltip title="Filters">
                        <Badge 
                            badgeContent={appliedFiltersCount} 
                            color= "error"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            overlap="circular">
                            <IconButton
                                size="medium"
                                color={"inherit"}
                                onClick={keystoneCtx.handleFilterOpen}
                            >
                                <FilterList/>
                            </IconButton>
                        </Badge> 
                    </Tooltip>}
                    { keystoneCtx.toolBarDisplayOptions.landingDateSelector &&
                        <Tooltip title={"Select Period"}>
                        <div>
                            <IconButton size="large" color="inherit" onClick={handleDateSelectorPopperOpen}>
                                <CalendarMonth/>
                            </IconButton>
                            <Popover
                                //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                                disableEnforceFocus
                                id={dateSelID}
                                open={dateSelOpen}
                                anchorEl={anchorDateSelector}
                                onClose={handleDateSelectorPopperClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    marginLeft: 0,
                                }}
                            >
                                <OverviewDateSelector dateValue={keystoneCtx.dateValue}
                                                      setDateValue={keystoneCtx.setDateValue}/>
                            </Popover>
                        </div>
                    </Tooltip>}

                    {
                        keystoneCtx.toolBarDisplayOptions.stats &&
                            <Tooltip title="View Stats">
                                <div>
                                <IconButton
                                    size="large"
                                    color={"inherit"}
                                    onClick={keystoneCtx.handleToggleStatsDrawer}
                                    disabled={keystoneCtx.statsLoading}
                                >
                                    <QueryStats/>
                                </IconButton>
                                </div>
                            </Tooltip>
                    }
                    {(keystoneCtx.toolBarDisplayOptions.filter || keystoneCtx.toolBarDisplayOptions.stats || keystoneCtx.toolBarDisplayOptions.landingDateSelector) && <Box>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                color: "white",
                                height: "4vh"
                            }}/>
                    </Box>}
                </Box>
            </Box>
            <Dialog
                open={confirmActionOpen}
                onClose={handleConfirmClose}
                aria-labelledby="card-exclude-dialog"
                fullWidth
                maxWidth="md"
            >
                {dialogBody()}
            </Dialog>
            <Dialog
                open={goalDialogOpen}
                onClose={handleConfirmClose}
                aria-labelledby="card-exclude-dialog"
                fullWidth
                maxWidth="md"
            >
                {dialogBody()}
            </Dialog>
            <Dialog
                open={labelDialogOpen}
                onClose={handleConfirmClose}
                aria-labelledby="card-exclude-dialog"
                fullWidth
                maxWidth="md"
            >
                {dialogBody()}
            </Dialog>
        </Box>
    )
};

export default TopBar;