import React, {FC, useEffect} from "react";
import {Avatar, Box, Card, CircularProgress, Tooltip, Typography} from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import {processUserForDisplay} from "../../../../../utils/processForDisplay";
import {useMainContext} from "../../../../../contexts/MainContext";
import {alpha, useTheme} from "@mui/material/styles";
import PersonIcon from '@mui/icons-material/Person';

interface StatusAndAssignee {
    assignees : any
    status: string
}

const StatusAndAssignee : FC<StatusAndAssignee> = (props: StatusAndAssignee) => {
    const ctx = useMainContext()
    const [targetLoading, setTargetLoading] = React.useState<boolean>(true);

    let userDetails

    function getColour(status: string){
        let colour
        switch (status) {
            case "Completed": {
                colour = useTheme().palette.success.main
                break;
            }
            case "To Do": {
                colour = useTheme().palette.error.main
                break;
            }
            case "In Progress": {
                colour = useTheme().palette.warning.main
                break;
            }
            case "Ready to Action": {
                colour = useTheme().palette.primary.main
                break;
            }
            default:{
                colour = useTheme().palette.primary.main
                break;
            }
        }
        return colour
    }

    useEffect(() => {
        if (ctx.members) {
            setTargetLoading(false)
        }
    }, [ctx.members])

    return(
        <Box
            sx={{
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                position: 'relative',
                alignItems: 'center',
                // border: '1px solid red',
            }}
        >
            <Tooltip title={"Opportunity Status"}>
                <Card
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '80%',
                        flexDirection: 'column',
                        margin: 1,
                        padding: 1,
                        borderRadius: '10px',
                        backgroundColor: alpha(getColour(props.status), 0.08),
                        // border: '2px solid red',
                    }}>
                    <Box sx={{
                        //border: '2px solid blue',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        width: '100%'
                    }}>
                        <Typography variant="h6" >
                            {props.status}
                        </Typography>
                    </Box>
                </Card>
            </Tooltip>
            <Card sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '80%',
                flexDirection: 'column',
                margin: 1,
                padding: 1,
                borderRadius: '10px',
                backgroundColor: alpha(useTheme().palette.info.main, 0.08),
                //border: '2px solid red',
            }}>
                <Box
                    // onClick={addAssignees}
                    sx={{
                    // border: '2px solid blue',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent : 'center',
                    width: '100%'
                }}>
                    <AvatarGroup
                        max={3}
                        spacing={"medium"}
                        sx={{
                            '& .MuiAvatar-root': {
                                border: '1px solid grey',
                                width: 28,
                                height: 28,
                                backgroundColor: 'grey',
                            },
                        }}>
                        {(props.assignees && props.assignees !== "" && props.assignees !== null && props.assignees.length > 0 && ctx.members) ? props.assignees.map((user) =>
                                (userDetails = processUserForDisplay(user, ctx.members)) ?
                                    <Tooltip title={userDetails.displayName} key={userDetails.id}>
                                        <Avatar key={userDetails.id} src={userDetails.avatar}/>
                                    </Tooltip>
                                    : <Tooltip title={"No Users Assigned"} key={"No Assignees Tooltip"}>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </Tooltip>
                            )
                            : <Tooltip title={"No Users Assigned"} key={"No Assignees Tooltip"}>
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            </Tooltip>}
                    </AvatarGroup>
                </Box>
            </Card>
        </Box>
    )
}

export default StatusAndAssignee