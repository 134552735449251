import * as Yup from 'yup';
import {Formik} from 'formik';
import type {FC} from 'react';
import {useSnackbar} from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import DashboardStore from "../../../../../cog/bitool/dashboard/Store";
import { useMainContext } from "../../../../../contexts/MainContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import Dashboard from "../../../../../cog/bitool/dashboard/Dashboard";

interface DashboardCreatorDialogProps {
  handleDialogClose: () => void;
  dashboard: Dashboard;
}

const useStyles = makeStyles((theme:Theme) => ({
  formControl: {
    minWidth: 140
  }
}));

const DashboardUpdateDialog: FC<DashboardCreatorDialogProps> = ({handleDialogClose, dashboard}) => {
  const {enqueueSnackbar} = useSnackbar();
  let navigate = useNavigate();
  const mainCtx = useMainContext();
  const classes = useStyles();
  const [organization, setOrganization] = useState(dashboard.organizationId);

  const oldDash = dashboard
  const handleOrgChange = (event) => {
    setOrganization(event.target.value)
  };

  console.log("Dashboard ID: ", dashboard.id)

  return (
    <Card>
      <Formik
        enableReinitialize
        initialValues={{
          name: dashboard.name,
          organizationId: dashboard.organizationId,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Name is required"),
          organizationId: Yup.string(),
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ): Promise<void> => {
          try {
            let newDashboard: Dashboard = new Dashboard(dashboard)
            newDashboard.name = values.name
            newDashboard.organizationId = organization

            let response = await DashboardStore.UpdateOne({
              dashboard: newDashboard,
              oldDashboard:  oldDash,
            });
            if (response.id) {
              setStatus({ success: true });
              enqueueSnackbar("Dashboard Updated", {
                anchorOrigin: {
                  horizontal: "right",
                  vertical: "top",
                },
                variant: "success",
              });
            } else {
              setStatus({ success: false });
              enqueueSnackbar("Failed to Update Dashboard!", {
                anchorOrigin: {
                  horizontal: "right",
                  vertical: "top",
                },
                variant: "error",
              });
            }
            resetForm();
            setSubmitting(false);
            handleDialogClose();
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader title="Update Dashboard" />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="New Dashboard Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                      >
                        Organization
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={organization}
                        onChange={handleOrgChange}
                        label="Select Organization"
                      >
                        {mainCtx.organizations.map((org) => {
                          return <MenuItem
                            value={org.id}
                            key={org.id}>
                            {org.name}
                          </MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    </Card>
  );
}

export default DashboardUpdateDialog;