const orgChecker = (org: string) => {
  switch (org) {
    case "WUTOW":
      return true
    case "WUTOW UNILEVER":
      return true
    case "WUTOW_UNILEVER":
      return true
    case "WUTOW KC":
      return true
    case "WUTOW_KC":
      return true
    case "CASALES":
      return true
    case "CASALESPNP":
      return true
    case "CASALESPNP_UNILEVER":
      return true
    case "CASALESPNP_UNILEVER_TOP10STORES100SKUS":
      return true
    case "CASALES_SEFALANA":
      return true
    case "CASALES_SEFALANA_UNILEVER_PERSONAL_CARE":
      return true
    case "CHECKERS_KC":
      return true
    case "CHECKERS_RB":
      return true
    case "CASALES UNILEVER":
      return true
    case "CASALES_UNILEVER":
      return true
    case "CASALES KC":
      return true
    case "CASALES_KC":
      return true
    case "CLOVER":
      return true
    case "LOGICO":
      return true
    case "LIPTON":
      return true
    case "SMC":
      return true
    case "BR DISTRIBUTION":
      return true
    case "UNILEVER":
      return true
    case "KIMBERLY_CLARK":
      return true
    case "KIMBERLY CLARK":
      return true
    case "KIMBERLY_CLARK_EA":
      return true
    case "MAKRO_KC":
      return true
    case "MASSCASH_KC":
      return true
    case "MASS_DISCOUNTERS_KC":
      return true
    case "MONDELEZ":
      return true
    case "Management":
      return true
    case "NESTLE":
      return true
    case "PNP_KC":
      return true
    case "PNS":
      return true
    case "TIGER BRANDS":
      return true
    default :
      return false
  }
}

export default orgChecker;