import React, { FC, useEffect, useState } from "react";
import { KanbanStore } from "../../../../../cog/keystone/card";
import { useMainContext } from "../../../../../contexts/MainContext";
import { Avatar, Box, Card, Container, CardHeader, CardContent, Divider, Grid, LinearProgress, Typography, Tooltip } from "@mui/material";
import StatsBarColumn from "./statsBarWidgets/StatsBarColumn";
import StatsBarProgress from "./statsBarWidgets/StatsBarProgress";
import StatsBarArea from "./statsBarWidgets/StatsBarArea";
import { useTheme } from "@mui/material/styles";

interface KeystoneStatsBarProps {
  statData: any;
}

const KeystoneStatsBar: FC<KeystoneStatsBarProps> = ({statData}) => {
  const mainCtx = useMainContext()

  const fakeAreaData = {
    Number_of_Opportunities: [2, 8, 10, 4, 1, 0, 0, 3, 6, 7, 7, 2, 0, 0, 4, 12, 8, 1, 16, 0, 0, 5, 7, 15, 8, 12, 0, 0],
    day: ["2023-02-10", "2023-02-11", "2023-02-12", "2023-02-13", "2023-02-14", "2023-02-15", "2023-02-16", "2023-02-17", "2023-02-18", "2023-02-19", "2023-02-20", "2023-02-21", "2023-02-22", "2023-02-23", "2023-02-24", "2023-02-25", "2023-02-26", "2023-02-27", "2023-02-28", "2023-03-01", "2023-03-02", "2023-03-03", "2023-03-04", "2023-03-05", "2023-03-06", "2023-03-07", "2023-03-08", "2023-03-09"]
  }

  return (
    <Box sx={{
      flexGrow: 1,
      maxWidth: 500,
      marginTop: 4,
      minHeight: 1100,
      "&::-webkit-scrollbar": {
        width: 10,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: useTheme().palette.background.default,
        borderRadius: 1,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: useTheme().palette.text.secondary,
        borderRadius: 2,
      }
    }}>
      {statData &&
        <Container
          maxWidth={false}
          sx={{
          minHeight: 1100,
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: useTheme().palette.background.default,
            borderRadius: 1,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: useTheme().palette.text.secondary,
            borderRadius: 2,
          }}}>
          <Box sx={{ height: "100%"}}>
            <Grid container direction="column" justifyContent="space-evenly" alignItems="center" spacing={2} sx={{ height: "100%", maxHeight: "100%"}}>
              <Grid item>
                <Card>
                  <CardHeader title={"Goal Progress"} />
                  <Divider />
                  <CardContent>
                    {statData["StatsGoalProgress"] &&
                      <StatsBarProgress
                        actual={statData["StatsGoalProgress"]["m_value"]}
                        target={50000}
                        org={mainCtx.activeOrganization.name} />
                    }
                  </CardContent>
                </Card>
              </Grid>
              <Grid item >
                <Card>
                  <CardHeader title={"User Contributions"} />
                  <Divider />
                  <CardContent>
                    {statData["StatsUserContributions"] &&
                      <StatsBarColumn
                        data={statData["StatsUserContributions"]}
                        org={mainCtx.activeOrganization.name} />}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader title={"Rate of Closure"} />
                  <Divider />
                  <CardContent>
                    {statData["StatsRateOfClosure"] &&
                      // <StatsBarArea
                      //   data={statData["StatsRateOfClosure"]}
                      //   labelValue={"day"}
                      //   org={mainCtx.activeOrganization.name} />
                      <StatsBarArea
                      data={fakeAreaData}
                      labelValue={"day"}
                      org={mainCtx.activeOrganization.name} />
                    }
                  </CardContent>
                </Card>
              </Grid>
              <Grid item >
                <Card>
                  <CardHeader title={"Opportunities Actioned"} />
                  <Divider />
                  <CardContent>
                    {statData["StatsNumOpportunitiesActioned"] &&
                      <Box sx={{ display:"flex", justifyContent:"center", width: 400}}>
                        <Typography variant={"h2"} sx={{ color: "#00e396" }}>{statData["StatsNumOpportunitiesActioned"]["opportunities_actioned"][0]}</Typography>
                      </Box>
                    }
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>}
    </Box>
  );
}

export default KeystoneStatsBar