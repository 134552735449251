const IsCustomerActive = (props)=>{
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Icon_SMM" data-name="Icon SMM" viewBox="0 0 437 402.7" height={height} >
            <defs>
                <linearGradient id="isCustomerActiveBlob" x1="44.4" x2="396.9" y1="207.3" y2="207.3" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="isCustomerActiveLine" x1="57.9" x2="80.6" y1="50.9" y2="50.9" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient id="isCustomerActiveFill" x1="57.9" x2="80.6" y1="50.9" y2="50.9" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-2" x1={0} x2="24.5" y1="233.4" y2="233.4" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-3" x1={208} x2="223.5" y1="356.6" y2="356.6" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-4" x1="90.9" x2="132.7" y1="20.9" y2="20.9" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-5" x1="401.8" x2={437} y1="139.9" y2="139.9" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-6" x1="281.8" x2="339.6" y1="373.8" y2="373.8" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-7" x1="38.9" x2="387.1" y1="206.8" y2="206.8" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-8" x1="65.4" x2="359.7" y1="206.8" y2="206.8" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-9" x1="159.8" x2="286.9" y1={264} y2={264} />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-11" x1="203.5" x2="243.2" y1="233.5" y2="233.5" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-12" x1="138.4" x2="315.5" y1="125.3" y2="125.3" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-13" x1="163.5" x2="283.9" y1={185} y2={185} />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-14" x1="182.5" x2="212.1" y1="154.7" y2="154.7" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-15" x1="235.6" x2="265.1" y1="154.7" y2="154.7" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-16" x1="201.7" x2="245.7" y1="194.1" y2="194.1" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-17" x1="157.5" x2="288.3" y1="138.3" y2="138.3" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-18" x1="101.5" x2="170.1" y1="214.2" y2="214.2" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-19" x1={117} x2="141.3" y1="186.8" y2="186.8" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-20" x1="109.2" x2={132} y1="193.3" y2="193.3" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-21" x1="125.8" x2="181.7" y1="266.6" y2="266.6" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-23" x1={90} x2="330.1" y1="289.2" y2="289.2" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-24" x1={50} x2="55.5" y1="206.8" y2="206.8" />
                <linearGradient xlinkHref="#isCustomerActiveLine" id="isCustomerActiveLine-25" x1="363.3" x2="378.6" y1="206.8" y2="206.8" />
                <style dangerouslySetInnerHTML={{__html: "\n            .iconWhite{fill:#fff}.isCustomerActiveCls-10{fill:url(#isCustomerActiveFill)}.isCustomerActiveCls-12{fill:url(#isCustomerActiveLine-9)}.isCustomerActiveCls-23{fill:url(#isCustomerActiveLine-21)}\n        " }} />
            </defs>
            <g id="Group">
                <path id="Contour" d="M396.8 160.7a141.7 141.7 0 0 0-136.6-136A141.3 141.3 0 0 0 129 103.6a81.6 81.6 0 0 1-25.4 30.3A140.7 140.7 0 0 0 44.5 254 141.6 141.6 0 0 0 181 389.9a141.5 141.5 0 0 0 133.5-83.6 52.8 52.8 0 0 1 19.2-22.8 141.3 141.3 0 0 0 63-122.8Z" style={{fill: 'url(#isCustomerActiveBlob)'}} />
                <path id="Contour-2" d="m65.2 46.9-7.3 3.9 7.3 4.1 3.9 7.3 4.1-7.3 7.4-4.1-7.4-3.9-4.1-7.4-3.9 7.4z" style={{fill: 'url(#isCustomerActiveLine)'}} />
                <path id="Contour-3" d="M7.8 229.1 0 233.4l7.8 4.4 4.4 7.8 4.3-7.8 8-4.4-8-4.3-4.3-8-4.4 8z" style={{fill: 'url(#isCustomerActiveLine-2)'}} />
                <path id="Contour-4" d="m213 353.8-5 2.9 5 2.7 2.7 5 2.7-5 5.1-2.7-5.1-2.9-2.7-4.9-2.7 4.9z" style={{fill: 'url(#isCustomerActiveLine-3)'}} />
                <path id="Contour-5" d="M107.6 26.3a3.6 3.6 0 0 0-1-1l-8-4.4 8-4.4a3.7 3.7 0 0 0 1-1l4.4-8 4.3 8a3.4 3.4 0 0 0 1.1 1l8 4.4-8 4.4a3.4 3.4 0 0 0-1 1l-4.4 8Zm2.1-25-6.1 11.3-11.3 6.2a2.1 2.1 0 0 0-1.4 2 2.5 2.5 0 0 0 1.4 2.2l11.3 6.2 6.1 11.2a2.1 2.1 0 0 0 2.1 1.4 2.5 2.5 0 0 0 2.1-1.4l6.2-11.2 11.3-6.2a2.1 2.1 0 0 0 1.3-2.1 2.5 2.5 0 0 0-1.3-2.1L120 12.6l-6.2-11.3a2.1 2.1 0 0 0-2-1.3 2.5 2.5 0 0 0-2.2 1.4Z" style={{fill: 'url(#isCustomerActiveLine-4)'}} />
                <path id="Contour-6" d="M416.2 144.2a3.5 3.5 0 0 0-1-1.1l-5.9-3.2 5.9-3.1a3.5 3.5 0 0 0 1-1l3.2-6 3.1 6a3.6 3.6 0 0 0 1 1l6 3.1-6 3.2a3.5 3.5 0 0 0-1 1l-3.1 5.9Zm1-20.5-5 9.2-9.1 5a2.1 2.1 0 0 0-1.4 2 2.6 2.6 0 0 0 1.4 2.2l9.1 5 5 9.2a2.1 2.1 0 0 0 2.1 1.3 2.3 2.3 0 0 0 2.1-1.3l5-9.2 9.2-5a2.1 2.1 0 0 0 1.4-2.1 2.5 2.5 0 0 0-1.4-2.1l-9.2-5-5-9.2a2.1 2.1 0 0 0-2-1.4 2.5 2.5 0 0 0-2.2 1.4Z" style={{fill: 'url(#isCustomerActiveLine-5)'}} />
                <path id="Contour-7" d="M303.5 382.1a3.5 3.5 0 0 0-1-1l-13.1-7.3 12.9-7.2a3.4 3.4 0 0 0 1-1l7.3-13.1 7.2 13a3.2 3.2 0 0 0 1 1.1l13.2 7.2-13 7.3a3.7 3.7 0 0 0-1 1l-7.3 13Zm5.1-35.8-9 16.4-16.4 9a2.1 2.1 0 0 0-1.3 2.1 2.6 2.6 0 0 0 1.3 2.1l16.4 9 9 16.4a2.1 2.1 0 0 0 2.1 1.4 2.3 2.3 0 0 0 2.1-1.4l9-16.4 16.4-9a2.1 2.1 0 0 0 1.3-2 2.5 2.5 0 0 0-1.3-2.2l-16.4-9-9-16.4a2.1 2.1 0 0 0-2.1-1.3 2.5 2.5 0 0 0-2.1 1.3Z" style={{fill: 'url(#isCustomerActiveLine-6)'}} />
                <path id="Contour-8" d="M384.6 100.7V313a19.2 19.2 0 0 1-19 19.3H60.4a19.1 19.1 0 0 1-19-19.3V100.7a19.2 19.2 0 0 1 19-19.2h305.2a19.2 19.2 0 0 1 19 19.2Z" className="iconWhite" />
                <path id="Contour-9" d="M43.9 313V100.7A16.6 16.6 0 0 1 60.4 84h305.2a16.6 16.6 0 0 1 16.5 16.7V313a16.6 16.6 0 0 1-16.5 16.7H60.4A16.6 16.6 0 0 1 44 313ZM60.4 79A21.6 21.6 0 0 0 39 100.8V313a21.6 21.6 0 0 0 21.5 21.6h305.2A21.5 21.5 0 0 0 387 313V100.7a21.6 21.6 0 0 0-21.5-21.6Z" style={{fill: 'url(#isCustomerActiveLine-7)'}} />
                <path id="Contour-10" d="M357.3 113.6v186.6c0 9.3-7.2 16.8-16 16.8H83.8c-8.9 0-16.1-7.5-16.1-16.8V113.6c0-9.3 7.2-16.8 16-16.8h257.4c8.9-.2 16.1 7.5 16.1 16.8Z" className="isCustomerActiveCls-10" />
                <path id="Contour-11" d="M70.3 300.2V113.6c0-8 6-14.4 13.6-14.4h257.3c7.4 0 13.5 6.5 13.5 14.4v186.6c0 8-6 14.4-13.5 14.4H84c-7.4 0-13.6-6.5-13.6-14.4ZM84 94.2a19 19 0 0 0-18.5 19.4v186.6a19 19 0 0 0 18.5 19.2h257.3c10.2 0 18.6-8.7 18.5-19.2V113.6a19 19 0 0 0-18.5-19.4Z" style={{fill: 'url(#isCustomerActiveLine-8)'}} />
                <path id="Contour-12" d="M316.4 299H103.3A11.4 11.4 0 0 1 92 287.7V125.8a11.4 11.4 0 0 1 11.3-11.3h213.1a11.4 11.4 0 0 1 11.3 11.3v161.9a11.3 11.3 0 0 1-11.3 11.3Z" className="iconWhite" />
                <path id="Contour-13" d="M313.1 184.1c0 9.5-3 20.8-15.6 25.9-9 3.6-22.7 4-43.8-1.5l-.7-.2h-58.6a79.4 79.4 0 0 1-26 5.3c-12.4 0-19.1-5.1-23-11a26.3 26.3 0 0 1-2.9-22.7c2.4-7 6.6-17 6-35.8-1-39.7 10.2-72.1 25.4-90.5 10.4-11.5 17.6-8.4 21.2-5.4a3.8 3.8 0 0 0 4 1 4 4 0 0 0 1.3-.8 47 47 0 0 1 58-3 70.2 70.2 0 0 1 25.2 28.8A131 131 0 0 1 297 122c1.2 17.8 8 34.5 11.6 42.3A47.5 47.5 0 0 1 313 184Z" className="isCustomerActiveCls-10" />
                <path id="Contour-14" d="M284.4 277.2v17.7H162.2v-17.7a44 44 0 0 1 43.9-44h34.5a44 44 0 0 1 43.8 44Z" className="isCustomerActiveCls-10" />
                <path id="Contour-15" d="M164.8 277.2a41.3 41.3 0 0 1 41.3-41.4h34.5a41.3 41.3 0 0 1 41.4 41.4v15.3H164.7Zm41.3-46.5a46.3 46.3 0 0 0-46.3 46.3v20.3H287V277a46.3 46.3 0 0 0-46.3-46.3Z" className="isCustomerActiveCls-12" />
                <g id="Group-2">
                    <path id="Contour-16" d="m283.2 277.3-.2 15.5-10.5.2c1.5-45.9-32.6-50.2-32.6-50.2l.7-1.4a22.4 22.4 0 0 0 2.1-7.5v-.2a45.3 45.3 0 0 1 40.5 43.6Z" className="iconWhite" />
                    <path id="Contour-17" d="M266 194.4c36.3-3 19.2-62.9-19.6-66.8S204 82.2 204 82.2s-8 10-5.2 30.7c2.2 16.4-20.3 28.6-29.8 32.8-6.8 3-11.3 9.1-8 15.7a48.2 48.2 0 0 0 11.1 13.4Z" className="iconWhite" />
                </g>
                <path id="Contour-18" d="M164.8 277.2a41.3 41.3 0 0 1 41.3-41.4h34.5a41.3 41.3 0 0 1 41.4 41.4v15.3H164.7Zm41.3-46.5a46.3 46.3 0 0 0-46.3 46.3v20.3H287V277a46.3 46.3 0 0 0-46.3-46.3Z" className="isCustomerActiveCls-12" />
                <path id="Contour-19" d="M240.8 217v16.1a17.4 17.4 0 0 1-34.9 0v-16.7Z" className="iconWhite" />
                <path id="Contour-20" d="M203.5 216.4v16.7a19.8 19.8 0 0 0 39.7 0v-16a2.4 2.4 0 0 0-4.8 0v16a15 15 0 0 1-30 0v-16.7a2.4 2.4 0 0 0-4.9 0Z" style={{fill: 'url(#isCustomerActiveLine-11)'}} />
                <g id="Group-3">
                    <path id="Contour-21" d="M278.8 160.2c-1.3 6-2.7 12.2-5 18a7.2 7.2 0 0 0-.4 3.4 37.7 37.7 0 0 1-5.6 8.3c-6.1 7.2 4.4 17.7 10.5 10.5a60.9 60.9 0 0 0 15-38.2c.4-8.3-12.6-10.2-14.5-2Z" className="isCustomerActiveCls-10" />
                    <path id="Contour-22" d="M169.9 170.3c-.6-3.3-2.9-10.2-3.5-13.5v-4.5a7.4 7.4 0 0 0-13.8-3.8 10.1 10.1 0 0 0-1 7.5v1.7a11.6 11.6 0 0 0 .3 2.2l2 11c.7 3.7 5.5 6.3 9.1 5.1 4.2-1.3 7.7-1.5 7-5.7Z" className="isCustomerActiveCls-10" />
                    <path id="Contour-23" d="M199.8 51.6s-34.8-.8-42.1 96.3a100.6 100.6 0 0 1-4.2 22c-3.6 11.4-7 29.3 8.4 41.4-5.3-2-10.5-3.9-14.4-9.7a23.3 23.3 0 0 1-2.6-21.5c2.4-7 5.9-17.2 5.4-36-1-39.6 10.7-71.2 25.7-89.5 8.6-10.1 13.7-8.3 17.3-5.3 1.2.9 5 2.6 6.4 2.3Z" className="iconWhite" />
                </g>
                <path id="Contour-24" d="M199.6 51.3A5.2 5.2 0 0 0 202 50a44.6 44.6 0 0 1 55.2-2.9A67.6 67.6 0 0 1 281.5 75c5.5 11 12 28.1 13.3 46.9 1.2 17.9 8 34.7 11.7 43.1a45.5 45.5 0 0 1 4.2 19c0 11.7-4.7 19.7-13.7 23.4l-.4.2c-9.5 3.8-23.8 3.3-42.3-1.6h-.3l-.5-.2H194l-.5.1c-9.6 3.5-18 5.2-25 5.2-9.7 0-16.7-3.5-21-10a23 23 0 0 1-2.6-20.4l.4-1.3a85 85 0 0 0 5.7-35.2c-.9-35.8 8.5-69 24.9-88.9 3.2-3.7 7.6-7.3 12-7.3a9.3 9.3 0 0 1 5.6 2 6.6 6.6 0 0 0 6.2 1.3Zm-.7-4.5-.6.3a1.7 1.7 0 0 1-1.7-.5c-3.3-2.9-12.3-8.1-24.6 5.7-17.3 20.7-27.1 55.3-26 92.2a82.8 82.8 0 0 1-5.4 33.3l-.5 1.4a28 28 0 0 0 3 24.8c5.4 8 13.8 12 25.3 12a82 82 0 0 0 26.3-5.3h57.8l.5.2c19.2 5 34.3 5.7 44.8 1.6l.4-.1c11.3-4.5 17.2-14.1 17.3-28.1a49.6 49.6 0 0 0-4.6-21c-3.7-8-10.1-24.3-11.3-41.3a133.5 133.5 0 0 0-13.7-48.7 72 72 0 0 0-26.2-29.7 52.6 52.6 0 0 0-28.6-9.1A51.6 51.6 0 0 0 199 46.7Z" style={{fill: 'url(#isCustomerActiveLine-12)'}} />
                <path id="Contour-25" d="M166 147.5c4.7 41.3 29 72.7 58.2 72.7 27.6 0 51-28.4 57.2-66.6Z" className="iconWhite" />
                <path id="Contour-26" d="M163.5 147.8c5 43.4 30.4 75 60.6 75 28.4 0 53-28.3 59.8-68.9l-4.8-.7c-6.3 38-28.8 64.6-54.8 64.6-27.7 0-51.1-29.8-55.8-70.7Z" style={{fill: 'url(#isCustomerActiveLine-13)'}} />
                <path id="Contour-27" d="M183.2 155.4a2.4 2.4 0 1 0 3.5 3.5c.4-.5 9.5-9.5 21.3.1a2 2 0 0 0 1.5.6 2.5 2.5 0 0 0 1.7-4.3 22 22 0 0 0-14-5.5 21.4 21.4 0 0 0-14 5.6Z" style={{fill: 'url(#isCustomerActiveLine-14)'}} />
                <path id="Contour-28" d="M236.3 155.4a2.4 2.4 0 1 0 3.4 3.5c.5-.5 9.5-9.5 21.4.1a2 2 0 0 0 1.5.6 2.4 2.4 0 0 0 2-.9 2.4 2.4 0 0 0-.4-3.4 22 22 0 0 0-14-5.5 21.4 21.4 0 0 0-14 5.6Z" style={{fill: 'url(#isCustomerActiveLine-15)'}} />
                <path id="Contour-29" d="M207.3 188.3h32.4a17.2 17.2 0 0 1-32.4 0Zm-3.2-5a2.4 2.4 0 0 0-2 1 2.6 2.6 0 0 0-.4 2 22.3 22.3 0 0 0 43.9 0 2.6 2.6 0 0 0-.5-2 2.4 2.4 0 0 0-2-1Z" style={{fill: 'url(#isCustomerActiveLine-16)'}} />
                <path id="Contour-30" d="M202.2 80.6c-.5.3-8.8 10.8-5.9 32.4 2.1 15.2-21 27-28.3 30.2a18 18 0 0 0-9.7 9.7 11.7 11.7 0 0 0 .6 9.4A42.7 42.7 0 0 0 170 176a2.5 2.5 0 0 0 3.1-3.8 37.9 37.9 0 0 1-9.7-11.8 7.7 7.7 0 0 1-.5-5.8c1-2.6 3.6-5 7.3-6.7 10-4.5 33.5-17.3 31-35.3-1.3-10.4.2-18 1.9-22.7 3.1 12.6 13 37.3 43.1 40.3 21.5 2 37.4 24.2 37.3 42.3 0 11.5-6.5 18.5-17.7 19.4a2.5 2.5 0 0 0-2.3 2.7 2.3 2.3 0 0 0 2.4 2.3.1.1 0 0 0 .2-.2c13.8-1.2 22-10.2 22.2-24.3.1-20.4-17-44.8-41.8-47.4-36-3.6-39.8-41.4-40-43.1a2.4 2.4 0 0 0-1.6-2.1 2.9 2.9 0 0 0-.8-.1 2.4 2.4 0 0 0-2 .9Z" style={{fill: 'url(#isCustomerActiveLine-17)'}} />
                <path id="Contour-31" d="m139.2 202.3-8.3-23.7a4.8 4.8 0 0 1 2.9-6l.6-.2a4.7 4.7 0 0 1 6 2.9s11.3 32.3 12 34.7l5.4-11.4c.7-2.4 2.8-4.7 5.4-4.4a6.3 6.3 0 0 1 3.6 2.1c1 1.7.5 4.8 0 6.2-.7 2.2-5.7 17.9-10.8 32.7l3.4 10.3-28.5 9.9-3-8.6s-7.8-6.4-10.5-15L104.2 195a4.7 4.7 0 0 1 2.8-6l.6-.1a4.7 4.7 0 0 1 6 2.8l5.7 16Z" className="iconWhite" />
                <path id="Contour-32" d="m134 170.3-.7.2a7.2 7.2 0 0 0-4.5 9.1l8.3 23.8 4.7-1.7-8.3-23.7a2.5 2.5 0 0 1 .1-1.8 2.1 2.1 0 0 1 1.4-1.2l.6-.2a2.2 2.2 0 0 1 2.9 1.4s11.2 32.5 12 34.7l2 5.7 8-17 .1-.3c.5-1.3 1.5-2.7 2.7-2.5a4 4 0 0 1 1.8.9c.3.6.2 2.5-.3 4l-.7 2.4a1756 1756 0 0 1-10 30.4l-.3.8 3 8.5-24 8.4-2.4-6.9-.6-.4s-7.2-6-9.8-13.9l-13.2-36.8a2.2 2.2 0 0 1 1.3-2.8l.6-.2a2.2 2.2 0 0 1 2.9 1.4l5.7 16 4.6-1.6-5.7-16a6.8 6.8 0 0 0-3.6-4.1 8 8 0 0 0-5.6-.3l-.6.1a7.2 7.2 0 0 0-4.5 9.2l13.1 36.8c2.5 7.8 8.9 14 10.8 15.6l3.6 10.2 33.6-11.2-4.1-11.8 9.8-29.6.7-2.4c.3-.9 1.7-5.4-.3-8.4a9.2 9.2 0 0 0-5.2-3c-3.3-.4-6.7 1.8-8 5.9l-2.8 5.8-10-29a7.3 7.3 0 0 0-6.9-4.9 7 7 0 0 0-2.3.4Z" style={{fill: 'url(#isCustomerActiveLine-18)'}} />
                <path id="Contour-33" d="m129.4 204.9-9.6-28a4.8 4.8 0 0 1 2.9-6l.7-.3a4.8 4.8 0 0 1 6 2.8l9.8 28.7Z" className="iconWhite" />
                <path id="Contour-34" d="m122.5 168.3-.7.3a7 7 0 0 0-4.1 3.7 7.4 7.4 0 0 0-.3 5.4l9.6 28 4.7-1.5-9.6-28a2.1 2.1 0 0 1 .1-1.7 1.6 1.6 0 0 1 1.2-1.2l.8-.3a3.1 3.1 0 0 1 1.6.2 1.7 1.7 0 0 1 1 1.2l9.8 28.7 4.7-1.7-9.8-28.7a7.5 7.5 0 0 0-3.6-4 7.7 7.7 0 0 0-3-.7 7.3 7.3 0 0 0-2.4.4Z" style={{fill: 'url(#isCustomerActiveLine-19)'}} />
                <path id="Contour-35" d="m120 209-8-23a4.7 4.7 0 0 1 2.9-6l.7-.2a4.7 4.7 0 0 1 6 2.8l8.1 22.9Z" className="iconWhite" />
                <path id="Contour-36" d="m114.7 177.4-.8.3a7.3 7.3 0 0 0-4.3 9.1l8 22.9 4.6-1.8-8-22.9a2.2 2.2 0 0 1 1.4-2.8l.7-.3a2.2 2.2 0 0 1 2.9 1.3l8.1 22.9 4.7-1.7-8.1-22.7a7.4 7.4 0 0 0-6.9-4.7 7 7 0 0 0-2.4.4Z" style={{fill: 'url(#isCustomerActiveLine-20)'}} />
                <path id="Contour-37" d="m178.1 287.9-41.2.4-7.6-25-1-2.8a4.6 4.6 0 0 1 3-5.9l27-9.3a4.7 4.7 0 0 1 5.8 3l11 31.3Z" className="isCustomerActiveCls-10" />
                <path id="Contour-38" d="M130.8 259.7a2.2 2.2 0 0 1 1.2-2.8l27-9.3a.9.9 0 0 1 .6-.2 2.6 2.6 0 0 1 2.3 1.5l12.7 36.4-35.9.5Zm26.7-16.8-27 9.3a7.2 7.2 0 0 0-4.4 9l9 29.5 46.6-.5-15.2-42.8a7.3 7.3 0 0 0-6.8-4.9 6.9 6.9 0 0 0-2.2.4Z" className="isCustomerActiveCls-23" />
                <path id="Contour-39" d="M175.1 279.4h-9.3l-7.4-19a5.7 5.7 0 0 0-7-3.5l-19.9 6.2a17.5 17.5 0 0 1-2.5.1l-1-2.9a4.6 4.6 0 0 1 3-5.8l27-9.3a4.7 4.7 0 0 1 5.9 3Z" className="iconWhite" />
                <path id="Contour-40" d="M130.8 259.7a2.2 2.2 0 0 1 1.2-2.8l27-9.3a.9.9 0 0 1 .6-.2 2.6 2.6 0 0 1 2.3 1.5l12.7 36.4-35.9.5Zm26.7-16.8-27 9.3a7.2 7.2 0 0 0-4.4 9l9 29.5 46.6-.5-15.2-42.8a7.3 7.3 0 0 0-6.8-4.9 6.9 6.9 0 0 0-2.2.4Z" className="isCustomerActiveCls-23" />
                <path id="Contour-41" d="M317.2 299h-214a10.7 10.7 0 0 1-10.8-10.7v-8.9h235.3v8.9a10.6 10.6 0 0 1-10.5 10.7Z" className="iconWhite" />
                <path id="Contour-42" d="M94.8 288.3V282h230.3v6.3a8 8 0 0 1-8 8.1H103a8 8 0 0 1-8.1-8ZM92.4 277a2.4 2.4 0 0 0-2.4 2.4v8.9a13 13 0 0 0 13.1 13h214a12.9 12.9 0 0 0 13-13v-8.9a2.4 2.4 0 0 0-2.4-2.4Z" style={{fill: 'url(#isCustomerActiveLine-23)'}} />
                <g id="Group-4">
                    <path id="Contour-43" d="M316.1 291.6H169.2a2.4 2.4 0 0 1 0-4.8h147a2.4 2.4 0 1 1 0 4.8Z" className="isCustomerActiveCls-10" />
                    <path id="Contour-44" d="M158 291.6h-40.3a2.4 2.4 0 1 1 0-4.8H158a2.4 2.4 0 1 1 0 4.8Z" className="isCustomerActiveCls-10" />
                    <path id="Contour-45" d="m107.6 288.8-5-3.7a.6.6 0 0 0-.9.5v7.2a.6.6 0 0 0 1 .5l5-3.6c.2-.3.2-.8 0-1Z" className="isCustomerActiveCls-10" />
                </g>
                <path id="Contour-46" d="M52 190.9a2 2 0 0 0-2 2v27.8a1.8 1.8 0 0 0 2 2h1.5a2 2 0 0 0 2-2v-27.8a2 2 0 0 0-2-2Z" style={{fill: 'url(#isCustomerActiveLine-24)'}} />
                <path id="Contour-47" d="M363.3 206.8a7.7 7.7 0 1 0 7.7-7.6 7.7 7.7 0 0 0-7.7 7.6Z" style={{fill: 'url(#isCustomerActiveLine-25)'}} />
            </g>
        </svg>

    )
}
export default IsCustomerActive