import * as React from 'react';
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Box, Chip } from "@mui/material";
import { useMainContext } from "../../../../../../contexts/MainContext";
import { FC } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {useKeystoneContext} from "../../../../../../contexts/KeystoneContext";

const OverviewSwitchTable = ({ chartName, statRows }) => {
    const mainCtx = useMainContext();
    const keystoneCtx = useKeystoneContext();
    const navigate = useNavigate();
    let rows = [];

    const formatCol = (k: string) => {
        return {
            field: k,
            headerName: k,
            width: 150,
            flex: 1
        }
    };

    const cols = Object.keys(statRows).map((k) => {
        return formatCol(k)
    })

    if (Object.keys(statRows).length === 0) {
        rows = [{ id: 0, NoData: 'Query returned no results' }];
    } else {
        _.range(statRows[Object.keys(statRows)[0]].length).map((idx) => {
            let row = { id: idx + 1 };
            Object.keys(statRows).map((k) => {
                row[k] = statRows[k][idx];
            });
            rows.push(row);
        });
    }

    const updateFilter = (org, chartName, cellValue, column, params) => {
        const formattedValue = cellValue.toString().replace(/_/g, ' ');
        const formattedColumn = column.toString().replace(/_/g, ' ');
        let filters = [];

        let CategoryHeader, AreaHeader, BuyingGroupHeader, FranchiseHeader; // Declare the variables here

        switch (chartName) {
            case 'TotalOpportunityValues':
                const oppTypeFilter = {
                    header: 'opportunity_type',
                    operation: '=',
                    prefix: 'AND',
                    values: formattedValue,
                };
                filters.push(oppTypeFilter);
                break;

            case 'OpportunitiesByCategory':
                CategoryHeader = keystoneCtx.colMap.Product_Hierarchy_1;

                if (params && params.row && params.row[CategoryHeader]) {
                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: formattedColumn,
                    };

                    const categoryFilter = {
                        header: CategoryHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[CategoryHeader],
                    };

                    if (column.toString() === CategoryHeader) {
                        filters.push(categoryFilter);
                    } else {
                        filters.push(oppTypeFilter, categoryFilter);
                    }
                }
                break;

            case 'OpportunitiesByArea':
                AreaHeader = keystoneCtx.colMap.Area_Hierarchy_1;

                if (params && params.row && params.row[AreaHeader]) {
                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: formattedColumn,
                    };

                    const areaFilter = {
                        header: AreaHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[AreaHeader],
                    };

                    if (column.toString() === AreaHeader) {
                        filters.push(areaFilter);
                    } else {
                        filters.push(oppTypeFilter, areaFilter);
                    }
                }
                break;

            case 'OpportunitiesByBuyingGroup':
                BuyingGroupHeader = keystoneCtx.colMap.Customer_Hierarchy_1;

                if (params && params.row && params.row[BuyingGroupHeader]) {
                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: formattedColumn,
                    };

                    const categoryFilter = {
                        header: BuyingGroupHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[BuyingGroupHeader],
                    };

                    if (column.toString() === BuyingGroupHeader) {
                        filters.push(categoryFilter);
                    } else {
                        filters.push(oppTypeFilter, categoryFilter);
                    }
                }
                break;

            case 'OpportunitiesByFranchiseGroup':
                FranchiseHeader = keystoneCtx.colMap.Customer_Hierarchy_2;

                if (params && params.row && params.row[FranchiseHeader]) {
                    const oppTypeFilter = {
                        header: 'opportunity_type',
                        operation: '=',
                        prefix: 'AND',
                        values: formattedColumn,
                    };

                    const categoryFilter = {
                        header: FranchiseHeader,
                        operation: '=',
                        prefix: 'AND',
                        values: params.row[FranchiseHeader],
                    };

                    if (column.toString() === FranchiseHeader) {
                        filters.push(categoryFilter);
                    } else {
                        filters.push(oppTypeFilter, categoryFilter);
                    }
                }
                break;

            default:
                filters = [];
                break;
        }

        console.log('Filters Array:', filters);

        keystoneCtx.setClickThroughFilters(filters);
        keystoneCtx.RefreshCards();
        navigate("/app/" + org.toLowerCase() + "/opportunities");
    };


    const handleCellDoubleClick = (params) => {
        const cellValue = params.value;
        const org = mainCtx.activeOrganization.name.replace(/ |_/g, '-');

        if (cellValue) {
            const column = params.colDef.field; // Get the field or column name that was clicked
            updateFilter(org, chartName, cellValue, column, params);
        }
    };

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <DataGridPro
                columns={cols}
                rows={rows}
                pageSize={5}
                rowsPerPageOptions={[5]}
                pagination={true}
                onCellDoubleClick={handleCellDoubleClick}
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </Box>
    );
};

export default OverviewSwitchTable;
