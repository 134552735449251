import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Box, Container, Divider, Grid, Tab, Tabs, Tooltip, Typography} from "@mui/material";
import useSettings from "src/hooks/useSettings";
import gtm from "src/lib/gtm";
import SettingsIcon from '@mui/icons-material/Settings';
import OppLabelsDataGrid from "./OppLabelsDataGrid"; // Assuming this component is in a separate file


const tabs = [
    { label: "Labels", value: "labels", tooltip: "Opportunity Labeling Settings" },
];

const OrganizationSettingsPage: FC = () => {
    const { settings } = useSettings();
    const [selectedTab, setSelectedTab] = useState<string>("labels");

    useEffect(() => {
        gtm.push({ event: "page_view" });
    }, []);
    
    // Handles tab change events and updates the "currentTab"
    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setSelectedTab(value);
    };

    
    return (
        <>
            <Helmet>
                <title>Keystone Settings</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    height: "100%",
                    py: 2
                }}
            >
                <Container maxWidth={settings.compact ? "xl" : false}>
                    <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item container alignItems="center">
                            <SettingsIcon sx={{ marginRight: "0.5rem" }} />
                            <Typography color="textPrimary" variant="h4">
                                Keystone Settings
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 2 }}>
                        <Tabs
                            indicatorColor="primary"
                            onChange={handleTabsChange}
                            scrollButtons="auto"
                            textColor="primary"
                            value={selectedTab}
                            variant="scrollable"
                        >
                            {tabs.map((tab) => (
                                <Tooltip title={tab.tooltip} ><Tab key={tab.value} label={tab.label} value={tab.value} /></Tooltip>
                            ))}
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 3 }}>
                        {selectedTab === "labels" && <OppLabelsDataGrid />}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default OrganizationSettingsPage;