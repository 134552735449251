/* eslint-disable */

import React, {createContext, useContext, useEffect, useState} from 'react';
import {useMainContext} from "./MainContext";
import Dashboard from "../cog/bitool/dashboard/Dashboard";
import DashboardStore from "../cog/bitool/dashboard/Store";

interface DashboardContext {
  isLoading: boolean;
  dashboards: Dashboard[] | null;
}

const Context = createContext({} as DashboardContext);
Context.displayName = "DashboardContext";

export const DashboardContext: React.FC = ({children}: {children?: React.ReactNode}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dashboards, setDashboards] = useState<Dashboard[]>(null);
  const ctx = useMainContext()

  useEffect( () => {
    setIsLoading(true)
    getDashboards().then(() => setIsLoading(false))
  }, [ctx.activeOrganization])

  const getDashboards = async (): Promise<any> => {
    setIsLoading(true);
    try {
      let dashes = (await DashboardStore.FindAll(
        {organizationId: ctx.activeOrganization.id}))
      setDashboards(dashes.dashboards);
    } catch (e) {
      console.error(`${e}`);
    }
  }

  return (
    <Context.Provider
      value={{
        isLoading: isLoading,
        dashboards: dashboards,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useDashboardContext = () => useContext(Context);

export {
  useDashboardContext,
};

export default DashboardContext