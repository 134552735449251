import type { FC } from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import {
  Box
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from "apexcharts";
import {InsightWidgetProps} from "../InsightWidgetSwitcher";

const GenericColumnChart: FC<InsightWidgetProps> = ({chartName, chartData, labelValue}) => {
  const theme = useTheme();

  const chartType = "bar"
  const columnName = labelValue
  const seriesCols = Object.keys(chartData).filter((k) => k != columnName)
  const series = seriesCols ? seriesCols.map((c) => {
    return {
      name: c,
      data: chartData && chartData[c] ? chartData[c] : []
    }
  }) : [{name: "y", data: []}]

  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      stacked: false,
      stackType: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        // endingShape: 'rounded'
      }
    },
    xaxis: {
      labels: {
        formatter: function (val) {
          return val
        },
        rotateAlways: true,
        show: true,
        rotate: -60,
        trim: true,
      },
      tickPlacement: 'on',
      categories: chartData &&  chartData[columnName] ? chartData[columnName] : []
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toString()
        }
      },
    },
    theme: {
      mode: theme.palette.mode
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    title: {
      text: chartName,
      align: 'left'
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      position: 'top',
    },
    grid: {
      padding: {
        bottom: 20
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toString()
        }
      }
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '90%',
        minHeight: '500px',
        boxShadow: 3,
        p: 2,
        borderRadius: 1,
      }}
    >
      <Chart
        type={chartType}
        width={"100%"}
        height={"100%"}
        series={series}
        options={chartOptions}
      />
    </Box>
  );
}

export default GenericColumnChart;