import React from 'react';
import type { FC } from 'react';
import logoPath from "./KimberlyClarkLogo.svg"

interface KimberlyClarkLogoProps {
  [key: string]: any;
}

const KimberlyClarkLogo: FC<KimberlyClarkLogoProps> = (props) => (
  <img
    alt="Kimberly Clark Logo"
    src={logoPath}
    height={100}
    width={200}
    style={{
      margin: 1
    }}
    {...props}
  />
);

export default KimberlyClarkLogo;