import * as React from 'react';
import {FC, useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, Chip, Divider, Typography} from "@mui/material";
import {Schedule} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import getStyle from "./KanbanGetStyle";

import {compareDesc, format} from 'date-fns'
import clsx from 'clsx';
import {Theme, useTheme} from "@mui/material/styles";
import {useMainContext} from "../../../../../contexts/MainContext";

import {Card as KanbanCardObj} from "../../../../../cog/keystone/card";
import {useNavigate} from "react-router-dom";
import {Member} from "../../../../../cog/keystone/member";
import abbrNum from "../../../../../utils/abbreviateNumbers";
import currencySwitcher from "../../../../../utils/currencySwitcher";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {indexOf} from "lodash";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";
import FormatLabels from "../../cards/IssueCard";

interface KanbanCardProps {
    card: KanbanCardObj;
    dragging?: boolean;
    index?: number;
    style?: any;
    provided: any;
    members: Member[]
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: string, vName: string) => void
    allLabels:OppLabel[]
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        outline: 'none',
    },
    card: {
        background: theme.palette.mode === 'dark' ? "#3d4d6a" : "#ffffff", //  useTheme().palette.background.default
    },
    dragging: {},
    cover: {
        height: 200
    },
    badge: {},
    small: {},
    mValue: {
        color: 'red'
    },
    issueType: {},
    smallAvatars: {},
    repeats: {
        background: 'rgb(255 0 0 / 5%)'
    },
    overdue: {
        color: '#ff0000',
        border: '1px solid #ff0000'
    },
    disabled: {
        color: '#888888',
        background: theme.palette.mode === 'dark' ? "#4c8859" : "#daffe9", //#7bbd80 //#4caf50
    },
    disabledText: {
        color: theme.palette.mode === 'dark' ? "#ffffff" : '#888888'
    },
    disabledTextChip: {
        color: theme.palette.mode === 'dark' ? "#ffffff" : '#888888',
        border: '1px solid #828282'
    },
    title: {
        minHeight: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '1',
        color: theme.palette.mode === 'dark' ? "#ffffff" : '#546e7a'
    }
}));

const KanbanCard: FC<KanbanCardProps> = ((props) => {
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const currencySymbol = currencySwitcher(mainCtx.activeOrganization.name)
    // const {
    //     card,
    //     dragging,
    //     provided,
    //     style
    // } = props;
    // const ctx = useKanbanContext() // no longer need her it seems
    const classes = useStyles();
    // const memberData = JSON.parse(JSON.stringify(jsonMembers.result.members))
    // const [members, setMembers] = useState<Record<string, Member>>(objFromArray(memberData))

    const navigate = useNavigate()


    // const openCard = (cardid) => {
    //     navigate("/keystone/opportunity/" + cardid)
    // }

    const cardFinal = props.card

    const disabled = () => {
        return cardFinal.listId == 'bf2e242d-525c-4218-a2e3-19d8602494c3'
    }

    const [cardDisabled, setCardDisabled] = useState(disabled)

    const checkIfRepeat = () => {
        return (Number(cardFinal.repeat) > 0)
    }

    const dueDatesStyle = (d) => {
        if (cardDisabled) {
            return {
                color: '#828282'
            }
        }
    }

    const dueDates = (d) => {
        return !!compareDesc(new Date, new Date(d));
    }

    const quickAndDirtyHack = (str: string) => {
        if (str.includes(':')) {
            let tS = str.split(':')
            if (tS[0] == 'Customer') {
                return false
            }
        } else {
            return false
        }
        return true
    }

    // useLayoutEffect(() => {
    //     // Need to do anything here?
    //     // console.log("Card Props:", props)
    //     // console.log("provided:", props.provided)
    //     // console.log(open)
    // }, [open])

    return (
        <Box
            {...props.provided.draggableProps}
            {...props.provided.dragHandleProps}
            ref={props.provided.innerRef}
            style={getStyle({
                draggableStyle: props.provided.draggableProps.style,
                virtualStyle: props.style,
                isDragging: props.dragging
            })}
            sx={{
                outline: 'none',
                py: 1,
                borderRadius: useTheme().shape.borderRadius,
                // border: props.selectCards == false ? `1px solid ${useTheme().palette.divider}` : `1px solid ${useTheme().palette.success.main}`,
                animation: indexOf(props.selectedCardsIDs, props.card.id) != -1 && "shake 2s cubic-bezier(.36,.07,.19,.97) both",
                animationIterationCount: indexOf(props.selectedCardsIDs, props.card.id) != -1 && "infinite",
                "@keyframes shake": {
                    "0%, 100%": {transform: "translate(0px, 0px) rotate(0deg)"},
                    "10%, 90%": {transform: "translate(0px, 0px) rotate(1deg)"},
                    "20%, 80%": {transform: "translate(0px, 0px) rotate(0deg)"},
                    "30%, 70%": {transform: "translate(0px, 0px) rotate(-1deg)"},
                    "40%, 60%": {transform: "translate(0px, 0px) rotate(0deg)"},
                    "50%": {transform: "translate(0px, 0px) rotate(1deg)"},
                }
            }}
        >

            <Card
                className={clsx(
                    classes.card,
                    {
                        [classes.dragging]: props.dragging,
                        // [classes.repeats]: checkIfRepeat(),
                        [classes.disabled]: cardDisabled,
                    }
                )}
                raised={props.dragging}
                // variant={'outlined'}
                key={cardFinal.id}
                onClick={(e) => {
                    if (props.selectCards == false) {
                        const org = mainCtx.activeOrganization.name.replace(/ |_/g,'-');
                        navigate('/app/' + org.toLowerCase() + '/opportunity/' + cardFinal.id)
                        // keystoneCtx.handleCardId(cardFinal.id)
                    } else {
                        e.stopPropagation()
                        props.updateSelectedCards(props.card.id, props.card.name)
                    }
                }}
                sx={{
                    border: indexOf(props.selectedCardsIDs, props.card.id) == -1 ? `1px solid ${useTheme().palette.divider}` : `1px solid ${useTheme().palette.success.main}`,
                }}
            >
                <CardContent>
                    <Box>
                        <Box sx={{
                            mt: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',

                        }}>
                            <Typography
                                variant='h6'
                                color='textPrimary'
                                sx={{
                                    fontSize: '0.9rem'
                                }}
                            >
                                <span
                                    className={clsx(
                                        classes.issueType,
                                        {
                                            [classes.disabledText]: cardDisabled,
                                        }
                                    )}
                                >{cardFinal.opportunityType != "" ? cardFinal.opportunityType : cardFinal.roleType + ' Issue'}</span>
                                <br/>
                                <span
                                    className={clsx(
                                        {
                                            [classes.disabledText]: cardDisabled,
                                            [classes.title]: true,
                                        }
                                    )}>{cardFinal.name}</span>
                            </Typography>
                            {/*<br/>*/}
                            {/*<Typography*/}
                            {/*    variant='h6'*/}
                            {/*    color='textSecondary'*/}
                            {/*>*/}
                            {/*    R {cardFinal.mValue}*/}
                            {/*</Typography>*/}

                        </Box>
                        <Box justifyContent='flex-start' flexDirection='column'>

                            <Divider variant="middle" sx={{
                                mt: 1,
                                mb: 1
                            }}/>
                            <Box
                                sx={{
                                    mt: 1,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >

                                {
                                    quickAndDirtyHack(cardFinal.displayValue) ? (
                                        <Chip label={currencySymbol + " " + cardFinal.displayValue}
                                              size='medium'
                                            // color='warning'
                                              variant='outlined'
                                              sx={{
                                                  fontWeight: 'bold',
                                                  fontSize: '0.9rem',
                                              }}
                                              className={clsx(
                                                  {
                                                      [classes.disabledText]: cardDisabled,
                                                  }
                                              )}
                                        />) : (<Chip label={currencySymbol + " " + abbrNum(cardFinal.mValue, 2)}
                                                     size='medium'
                                        // color='warning'
                                                     variant='outlined'
                                                     sx={{
                                                         fontWeight: 'bold',
                                                         fontSize: '0.9rem',
                                                     }}
                                                     className={clsx(
                                                         {
                                                             [classes.disabledText]: cardDisabled,
                                                         }
                                                     )}
                                    />)
                                }
                            </Box>
                            <Divider variant="middle" sx={{
                                mt: 1,
                                mb: 1
                            }}/>
                            <Box
                                sx={{
                                    mt: 1,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >

                                <Chip
                                    label={cardFinal.displayValue ? (cardFinal.displayValue) : (cardFinal.region ? 'Region - ' + cardFinal.region : 'Customer - ' + cardFinal.customer_name)}
                                    size='small' className={clsx(
                                    classes.issueType,
                                    {
                                        [classes.disabledText]: cardDisabled,
                                    }
                                )} style={{textTransform: 'uppercase'}}/>
                            </Box>
                            <Divider variant="middle" sx={{
                                mt: 1,
                                mb: 1
                            }}/>
                            <Box
                                sx={{
                                    mt: 1,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <Chip label={'Created - ' + format(new Date(cardFinal.createdOn), 'yyy-MM-dd')}
                                      size='small'
                                    // color='secondary'
                                      variant='outlined'
                                      icon={<Schedule style={dueDatesStyle(cardFinal.due)}/>}
                                      className={clsx(
                                          {[classes.disabledTextChip]: cardDisabled}
                                      )}
                                />
                            </Box>
                            {/*<Divider variant="middle" sx={{*/}
                            {/*    mt: 1,*/}
                            {/*    mb: 1*/}
                            {/*}}/>*/}
                            {/*<Box*/}
                            {/*    sx={{*/}
                            {/*        mt: 1,*/}
                            {/*        display: 'flex',*/}
                            {/*        justifyContent: 'center'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <FormatLabels oppLabels={props.allLabels} value={cardFinal.opportunityLabels} theme={useTheme()}/>*/}
                            {/*</Box>*/}
                        </Box>

                        {/*<Box flexGrow={1} display="flex" justifyContent="flex-end">*/}
                        {/*    /!* This is for the little icons in the opportunity cards, get members in keystone and pass it down, tricky to pass down, doesn't wanna work proper*/}
                        {/*    *** Maybe we still need it to show members assigned to an opportunity, we can use member store*/}
                        {/*    *!/*/}
                        {/*    {cardFinal.assignedTo && cardFinal.assignedTo.length > 0 ?*/}
                        {/*        (<AvatarGroup max={2} className={classes.small}>*/}
                        {/*            {Object.values(cardFinal.assignedTo).map((member) => {*/}
                        {/*                return (*/}
                        {/*                    <Avatar*/}
                        {/*                        key={member}*/}
                        {/*                        src={props.members[member].avatar}*/}

                        {/*                    />*/}
                        {/*                )*/}
                        {/*            })}*/}
                        {/*        </AvatarGroup>) : (<PeopleAltOutlined style={{color: "rgb(76 175 80 / 0%)", minHeight: "44px"}}/>)}*/}
                        {/*</Box>*/}
                        {/*<Box flexGrow={1} display="flex" justifyContent="flex-end">*/}
                        {/*    {cardFinal.assignedTo && cardFinal.assignedTo.length > 0 && (*/}

                        {/*    )}*/}
                        {/*</Box>*/}
                    </Box>
                </CardContent>

            </Card>
        </Box>
    );
});

KanbanCard.propTypes = {
    dragging: PropTypes.bool,
    index: PropTypes.number,
    // @ts-ignore
    style: PropTypes.object
};

KanbanCard.defaultProps = {
    dragging: false
};

export default KanbanCard;
