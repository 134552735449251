/* eslint-disable */

export default class Query {
    public id: string = '';
    public name: string = '';
    public templatedQuery: string = '';
    public queryArgumentIds: number[] = [];

    constructor(query?: Query) {
        if (!query) {
            return;
        }
        this.id = query.id;
        this.name = query.name;
        this.templatedQuery = query.templatedQuery;
        this.queryArgumentIds = query.queryArgumentIds;
    }
}
