/* eslint-disable */

import {PerformJSONRPCRequest, API} from '../../api/jsonrpc';
import GlobalVariable from "./GlobalVariable";
import Template from "../dashboard/cloner/Template";

export interface CreateOneRequest {
    name: string;
    value: string;
    organizationId: string;
}

export interface CreateOneResponse {
    id: string;
}

export interface CreateManyRequest {
    variables: GlobalVariable[];
}

export interface CreateManyResponse {
    ids: string[];
}

export interface UpdateManyRequest {
    variables: GlobalVariable[];
}

export interface UpdateManyResponse {
    ids: string[];
}

export interface DeleteManyRequest {
    ids: string[];
}

export interface DeleteManyResponse {
    ids: string[];
}

export interface FindAllRequest {
    organizationId: string;
}

export interface FindAllResponse {
    variables: GlobalVariable[];
}

export interface FindAndGetAllRequest {
    organizationId: string
    dashboardId: string;
}

export interface FindAndGetAllResponse {
    variables: Template[];
}

const Store = {
    serviceProvider: 'Global-Variables-Store',
    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                'variable': request
            }]
        });
        return {
            id: response.id,
        };
    },

    async CreateMany(request: CreateManyRequest): Promise<CreateManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateMany`,
            request: [{
                'variables': request
            }]
        });
        return {
          ids: response.ids
        }
    },

    async UpdateMany(request: UpdateManyRequest): Promise<UpdateManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateMany`,
            request: [{
                'variable': request.variables
            }]
        });
        return {
            ids: response.ids
        }
    },

    async DeleteMany(request: DeleteManyRequest): Promise<DeleteManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteMany`,
            request: [{
               'ids': request.ids
            }]
        });
        return {
           ids: response.ids
        }
    },

    async FindAll(request: FindAllRequest): Promise<FindAllResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAll`,
            request: [{
                'organizationId': request.organizationId
            }]
        });
        return {
            variables: response.variables.map((mem) => (new GlobalVariable(mem))),
        };
    },

    async FindAndGetAll(request: FindAndGetAllRequest): Promise<FindAndGetAllResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAndGetAll`,
            request: [{
                'organizationId': request.organizationId,
                'dashboardId': request.dashboardId
            }]
        });
        return (response.variables) ?
            {
            variables: response.variables.map((mem) => (new Template(mem))),
        } : {variables: []}

    }
}

export default Store;