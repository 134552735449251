import {API, PerformJSONRPCRequest} from "../../api/jsonrpc";
import {
    PSUFilterValue,
    TreeForUseByPSU,
    TreeFromAPI
} from "../../../pages/app/keystone/productstoreuniverse/utility/NodeTypesAndOptions";

export interface SaveOneRequest {
    PSU: TreeFromAPI
    ClientName: string
}

export interface SaveOneResponse {

}

export interface RemoveOneRequest {
    ClientName: string
    ID: string
}

export interface RemoveOneResponse {

}

export interface FetchAllByUserRequest {
    ClientName: string
    User_ID: string
}

// export interface FetchAllByUserResponse {
//     PSUTrees: TreeFromAPI[]
// }

export interface FetchAllByUserResponse {
    PSUTrees: TreeForUseByPSU[]
}

export interface FetchFilterValuesRequest {
    ClientName: string
}

export interface FetchFilterValuesResponse {
    FilterValues: PSUFilterValue[]
}

export interface MakeSQLRequest {
    PSU: TreeFromAPI
    ClientName: string
}

export interface MakeSqlResponse {

}

/** FetchSalesViewWithPSURequest : {
 *      PSU: TreeFromAPI
 *      ClientName: string
 *  }
 * */
export interface FetchSalesViewWithPSURequest {
    PSU: TreeFromAPI
    ClientName: string
    // Product
    ProductCode: string
    Product: string
    ProductHierarchy1: string
    ProductHierarchy2: string
    ProductHierarchy3: string
    ProductHierarchy4: string
    // Customer
    AreaHierarchy3Code: string
    AreaHierarchy3: string
    CustomerHierarchy1: string
    CustomerHierarchy2: string
    CustomerHierarchy3: string
}

/** FetchSalesViewWithPSUResponse : {
 *      SalesViewGenericObject: any[]
 *   }
 * */
export interface FetchSalesViewWithPSUResponse {
    ProductSalesViewGenericObject: any[]
    CustomerSalesViewGenericObject: any[]
}

export interface FetchActivePSUByUserIDRequest {
    ClientName: string
    UserID: string
}

export interface FetchActivePSUByUserIDResponse {
    PSU_ID: string
}

export interface SaveActivePSUForUserRequest {
    ClientName: string
    User_ID: string
    PSU_ID: string
}

export interface SaveActivePSUForUserResponse {

}

export interface CheckIfPsuReturnsDataRequest {
    PSU: TreeFromAPI
    ClientName: string
}

export interface CheckIfPsuReturnsDataResponse {
    ReturnsData: boolean
}

const Store = {
    serviceProvider: "PSU-Store",
    async SaveOne(request: SaveOneRequest): Promise<SaveOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.SaveOnePSU`,
            request: [{
                "PSU": request.PSU,
                "ClientName": request.ClientName
            }]
        });
        return response
    },

    async FetchAllByUser(request: FetchAllByUserRequest): Promise<FetchAllByUserResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FetchAllByUser`,
            request: [{
                "ClientName": request.ClientName,
                "User_ID": request.User_ID
            }]
        });
        return response
    },

    async RemoveOne(request: RemoveOneRequest): Promise<RemoveOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.RemoveOne`,
            request: [{
                "ClientName": request.ClientName,
                "ID": request.ID
            }]
        });
        return response
    },

    async FetchFilterValues(request: FetchFilterValuesRequest): Promise<FetchFilterValuesResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FetchFilterValues`,
            request: [{
                "ClientName": request.ClientName
            }]
        });
        return response
    },

    async MakeSQL(request: MakeSQLRequest): Promise<MakeSqlResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.MakeSQL`,
            request: [{
                "ClientName": request.ClientName,
                "PSU": request.PSU
            }]
        });
        return response
    },

    async FetchSalesViewWithPSU(request: FetchSalesViewWithPSURequest): Promise<FetchSalesViewWithPSUResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FetchSalesViewWithPSU`,
            request: [{
                "ClientName": request.ClientName,
                "PSU": request.PSU,
                "ProductCode": request.ProductCode,
                "Product": request.Product,
                "ProductHierarchy1": request.ProductHierarchy1,
                "ProductHierarchy2": request.ProductHierarchy2,
                "ProductHierarchy3": request.ProductHierarchy3,
                "ProductHierarchy4": request.ProductHierarchy4,
                "AreaHierarchy3Code": request.AreaHierarchy3Code,
                "AreaHierarchy3": request.AreaHierarchy3,
                "CustomerHierarchy1": request.CustomerHierarchy1,
                "CustomerHierarchy2": request.CustomerHierarchy2,
                "CustomerHierarchy3": request.CustomerHierarchy3,
            }]
        });
        return response
    },

    async FetchActivePSUByUserID(request: FetchActivePSUByUserIDRequest): Promise<FetchActivePSUByUserIDResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FetchActivePSUByUserID`,
            request: [{
                "ClientName": request.ClientName,
                "UserID": request.UserID
            }]
        });
        return response
    },

    async SaveActivePSUForUser(request: SaveActivePSUForUserRequest): Promise<SaveActivePSUForUserResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.SaveActivePSUForUser`,
            request: [{
                "ClientName": request.ClientName,
                "User_ID": request.User_ID,
                "PSU_ID": request.PSU_ID != null ? request.PSU_ID : ""
            }]
        });
        return response
    },

    async CheckIfPsuReturnsData(request: CheckIfPsuReturnsDataRequest): Promise<CheckIfPsuReturnsDataResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CheckIfPsuReturnsData`,
            request: [{
                "ClientName": request.ClientName,
                "PSU": request.PSU
            }]
        });
        return response
    },
}

export default Store