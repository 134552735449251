import { API, PerformJSONRPCRequest } from "../../../../api/jsonrpc";

export interface CreateOneRequest {
  userID: string;
  goalID: string;
  clientName: string;
}

export interface CreateOneResponse {
  success: boolean;
}

export interface DeleteOneRequest {
  userID: string;
  goalID: string;
  clientName: string;
}

export interface DeleteOneResponse {
  success: boolean;
}

export interface InsertAssigneesRequest {
  userIDs: string[];
  goalID: string;
  clientName: string;
}

export interface InsertAssigneesResponse {
  success: boolean;
}

export interface InsertMultipleAssigneesRequest {
  userIDs: string[];
  goalIDs: string[];
  clientName: string;
}

export interface InsertMultipleAssigneesResponse {
  success: boolean;
}

const Store = {
  serviceProvider: 'Goal-Assignee-Store',
  async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CreateOne`,
      request: [{
        "userID": request.userID,
        "goalID": request.goalID,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.DeleteOne`,
      request: [{
        "userID": request.userID,
        "goalID": request.goalID,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async InsertAssignees(request: InsertAssigneesRequest): Promise<InsertAssigneesResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.InsertAssignees`,
      request: [{
        "userIDs": request.userIDs,
        "goalID": request.goalID,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async InsertMultipleAssignees(request: InsertMultipleAssigneesRequest): Promise<InsertMultipleAssigneesResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.InsertMultipleAssignees`,
      request: [{
        "userIDs": request.userIDs,
        "goalIDs": request.goalIDs,
        "clientName": request.clientName
      }]
    });
    return response
  },
}

export default Store;