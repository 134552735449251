import * as React from "react";
import {FC, useEffect, useLayoutEffect, useState} from "react";
import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    LinearProgress,
    Typography
} from "@mui/material";
import {KanbanStore} from "../../../../cog/keystone/card";
import StatsTable from "./StatsTable";
import {GridColDef} from "@mui/x-data-grid";

import {Dayjs} from 'dayjs';
import {DateRange} from '@mui/x-date-pickers-pro/DateRangePicker';
import PieChartStat from "./PieChart";
import ColourPickerPreviewIndicator from "./SingleValueIndicator";
import LineGraphStat from "./LineGraph";
import {useMainContext} from "../../../../contexts/MainContext";
import _ from "lodash";
import SingleValueIndicator from "./SingleValueIndicator";

import Tab from "@mui/material/Tab";
import {AppsOutlined, ViewKanbanOutlined} from "@mui/icons-material";
import Tabs from "@mui/material/Tabs";
import UserReportingView from "./user reporting/UserReportingView";
import HomeIssueSkeleton from "../summary/Skeleton";
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import SyncAlt from '@mui/icons-material/SyncAlt';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';                         
import Draft from "draft-js";
import {Member, MemberStore} from "../../../../cog/keystone/member";
import StatsPageDateSelector from "./StatsPageDateSelector";
import ReactGA from "react-ga4";
import { useKeystoneContext } from "src/contexts/KeystoneContext";
import currencyFormatter from "src/utils/currencyFormatter";

interface StatsViewProps {
}
const StatsPage: FC<StatsViewProps> = (props) => {
    const keystoneCtx = useKeystoneContext()
    const [value, setValue] = React.useState<DateRange<Dayjs>>([null, null]);
    const now = new Date(Date.now());
    const last2Weeks = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14);
    const [loading, setLoading] = useState<boolean>(false)
    const [productHierarchy1, setProductHierarchy1] = useState<string>("Principal_Category")
    const [areaHierarchy1, setAreaHierarchy1] = useState<string>("Region")
    const [customerHierarchy1, setCustomerHierarchy1] = useState<string>("Buying_Group")
    const [customerHierarchy3, setCustomerHierarchy3] = useState<string>("Franchise_Group")
    const [members, setMembers] = useState<Member[]>([])
    const [statData, setStatData] = useState<any>({})
    const [userStatData, setUserStatData] = useState<Record<string,any>>({})
    const mainCtx = useMainContext()
    const [displayTabLabel, setDisplayTabLabel] = useState<boolean>(true)
    const [displayPage, setDisplayPage] = useState<string>('Home')
    const [loadingBar, setLoadingBar] = useState<boolean>(false)
    const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
        setDisplayPage(newValue);
    };

    const [tableDataCollection, setTableDataCollection] = useState<Collection[]>([])
    

    // Sets which options to display in the toolbar
    useEffect(() => {
    if (keystoneCtx.toolBarDisplayOptions.selectOpps != false ||
        keystoneCtx.toolBarDisplayOptions.filterToMe != false ||
        keystoneCtx.toolBarDisplayOptions.stats != false ||
        keystoneCtx.toolBarDisplayOptions.filter != true) {
      keystoneCtx.setToolBarDisplayOptions({filter: false, stats: false, selectOpps: false, filterToMe: false, landingDateSelector: true})
    }
  }, [keystoneCtx.toolBarDisplayOptions])


    const fetchStatData = async () => {
        setLoading(true)

        if (mainCtx.activeOrganization.name == "DKSH") {
            setProductHierarchy1("MATGRP4_TEXT")
            setAreaHierarchy1("REGION_TEXT")
            setCustomerHierarchy1("CHANNEL")
            setCustomerHierarchy3("CUST_GROUP_TXT")
        } else if (mainCtx.activeOrganization.name == "CASALES") {
            setProductHierarchy1("MAIN_CATEGORY")
            setAreaHierarchy1("Region")
            setCustomerHierarchy1("BuyingGroup")
            setCustomerHierarchy3("FranchiseGroup")
        }

        const dateSelectionStart = keystoneCtx.dateValue[0].toISOString().slice(0, 10)
        const dateSelectionEnd = keystoneCtx.dateValue[1].toISOString().slice(0, 10)
        const response = await KanbanStore.ArchivedPageStats({clientName: mainCtx.activeOrganization.name, dateSelectionStart: dateSelectionStart, dateSelectionEnd: dateSelectionEnd})
        if (response) {
            console.log(response)
            setStatData(response)
        }
        const userResponse = await KanbanStore.UserPageStats({clientName: mainCtx.activeOrganization.name, dateSelectionStart: dateSelectionStart, dateSelectionEnd: dateSelectionEnd})
        if (userResponse) {
            setUserStatData(userResponse)
        }
        const memberResponse = await MemberStore.FindMany({orgID: mainCtx.activeOrganization.id})
        if (memberResponse) {
            setMembers(memberResponse.members)
        }

        setLoading(false)
        return response
    }

    const combineArrays = (first, second) => {
        try {        return first.reduce((acc, val, ind) => {
            acc[val] = second[ind];
            return acc;
        }, {});}
        catch  {
            console.log("error combining", first,second)}


    };



    useEffect(() => {
        setLoadingBar(true)
        setTableDataCollection([])
        fetchStatData()
        .then((stats) => {
            setStatData(stats)
            setTableDataCollection( prevState => [...prevState,
                ({division: "Areas", topData: stats["Top3Area"], bottomData: stats["Bottom3Area"]}),
                ({division: "Brands", topData: stats["Top3Brand"], bottomData: stats["Bottom3Brand"]}),
                ({division: "Categories", topData: stats["Top3Category"], bottomData: stats["Bottom3Category"]}),
                ({division: "Performers", topData: stats["Top3Performer"], bottomData: stats["Bottom3Performer"]}),
                ({division: "Products", topData: stats["Top3Product"], bottomData: stats["Bottom3Product"]}),
                ({division: "Regions", topData: stats["Top3Region"], bottomData: stats["Bottom3Region"]}),
                ({division: "Stores", topData: stats["Top3Store"], bottomData: stats["Bottom3Store"]})
            ])
            setLoadingBar(false)
        })

    }, [mainCtx.activeOrganization.name, keystoneCtx.dateValue])

    useEffect(() => {

        ReactGA.event("keystone_view" ,{
            userID: mainCtx.user.id,
            orgID: mainCtx.activeOrganization.id,
            pageName: 'Reporting'
        });


    }, [mainCtx.activeOrganization])


    interface Collection {
        division: string
        topData: object
        bottomData: object
    }
    const handleResize = () => {
        if (window.innerWidth <= 800) {
            setDisplayTabLabel(false)
            return;
        }
        if (window.innerWidth > 800) {
            setDisplayTabLabel(true)
            return;
        }
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        handleResize()
    })

    const getUserName = (value: any) => {
        const assignedMembers = []
        let tempAssigns = []
        if (value) {
            tempAssigns = JSON.parse(value)
        }


        if (members.length > 0 && tempAssigns.length > 0)  {
            for (let i = 0; i < tempAssigns.length; i++) {
                assignedMembers.push(members.find(mem => mem.id == tempAssigns[0]).displayName)
            }
        }



        return assignedMembers.join(", ")
    }

    const getUserNameArray = (value: any) => {
        const assignedMembers = []
        let tempAssigns = []
        if (value) {
            tempAssigns = JSON.parse(value)
        }



        if (members.length > 0 && tempAssigns.length > 0)  {
            for (let i = 0; i < tempAssigns.length; i++) {
                assignedMembers.push(members.find(mem => mem.id == tempAssigns[0]).displayName)
            }
        }



        return assignedMembers
    }

    const genTableColumns = (data: object, division:string) => {

        if (data) {
            let tempRecord = []
            if(division != 'Performers'){
                Object.keys(data).forEach((columnKey) => {
                    switch (columnKey){
                        case 'Ranking':
                            tempRecord[0] = columnKey
                            break
                        case 'opportunity_type':
                            tempRecord[4] = columnKey
                            break
                        case 'opportunity_gained':
                            tempRecord[2] = columnKey
                            break
                        case 'Revenue_at_risk':
                            tempRecord[3] = columnKey
                            break
                        default:
                            tempRecord[1] = columnKey
                    }
                })

            }else {
                Object.keys(data).forEach((columnKey,index) => {
                    tempRecord[index] = columnKey
                }
                )
            }
            let columns: GridColDef[] =
                tempRecord.map((d) => {
                    if (d == "assigned_to") {
                        return {
                            field: d,
                            headerName: "Assigned To",
                            //width: 120,
                            valueFormatter: ({ value }) => getUserName(value),
                            flex:1,
                            editable: true,
                        }
                    } else {
                        return {
                            field: d,
                            headerName: d.split("_").map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(' '),
                            //width: 120,
                            valueFormatter: ({ value }) => (typeof value === "number" && d != "Ranking") ? currencyFormatter(mainCtx.activeOrganization.name, (Math.round(value * 100) / 100).toFixed(2)) : null ,
                            flex:1,
                            editable: true,
                        }
                    }
                })
            return columns
        }

    }
    const mapTableRows = (data: object ) => {
        let tempRows= []
        if (data && Object.keys(data) && Object.keys(data).length)  _.range(data[Object.keys(data)[0]].length).map((idx) => {
                let row = {}
                row['id'] = idx + 1
                Object.keys(data).map((k) => {
                    if (Number.isFinite(data[k][idx]) && data[k][idx] !== null) {
                        let temp = data[k][idx]
                        row[k] = temp
                    } else {
                        row[k] = data[k][idx]
                    }
                })
                tempRows.push(row)
        })
        return tempRows
    }
    const genTableDataCollection =(col: Collection) => {

            return {
                category: col.division,

                dataTop: {
                    columns: genTableColumns(col.topData,col.division),
                    rows: mapTableRows(col.topData)
                },
                dataBottom: {
                    columns: genTableColumns(col.bottomData,col.division),
                    rows: mapTableRows(col.bottomData)
                }
            }
        }

    const genBarSeries = (data :object) =>{
        let tempSeries: object[] = []
        if(data != null && data['Customer_Name']) {
            data['Customer_Name'].forEach((k, index) => {
                let tempObj = {
                    name: k,
                    data: [data['opportunity_gained'][index]]

                }
                tempSeries.push(tempObj)
            })
        } else if (data != null && data['CUSTOMER_TEXT']) {
            data['CUSTOMER_TEXT'].forEach((k, index) => {
                let tempObj = {
                    name: k,
                    data: [data['opportunity_gained'][index]]

                }

                tempSeries.push(tempObj)
            })
        } else if (data != null && data['CardName']) {
            data['CardName'].forEach((k, index) => {
                let tempObj = {
                    name: k,
                    data: [data['opportunity_gained'][index]]

                }
                tempSeries.push(tempObj)
            })
        }
        return tempSeries

    }

    const removeObject = (ObjFilter,oKey): object => {
        if (ObjFilter != null) {
            ObjFilter = Object.keys(ObjFilter).filter(key =>
                key !== oKey).reduce((obj, key) => {
                    obj[key] = ObjFilter[key];
                    return obj;
                }, {}
            );
            return ObjFilter
        }
    }

    return (
        <Box>
            <Box sx={{mt: 1}} >

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: "center"
                }}>

                    <Tabs value={displayPage} onChange={handleTabsChange} sx={{
                        // minHeight: "1rem",
                        flexWrap: "wrap",
                        '& .MuiTabs-flexContainer': {
                            flexWrap: "nowrap",
                            padding: 0.1,
                            justifyContent: 'space-evenly',
                        },
                        '& .MuiButtonBase-root': {
                            paddingY: 0,
                            minHeight: "50px",
                            // width: "10rem"
                        }
                    }}>
                        <Tab value={'Home'} iconPosition="start" icon={<AppsOutlined fontSize="small"/>}
                             label={displayTabLabel ? "Opportunity Reporting" : ""}/>
                        <Tab value={'UserReport'} iconPosition="start" disabled={false} icon={<ViewKanbanOutlined fontSize="small"/>}
                             label={displayTabLabel ? "User Report" : ""}/>
                    </Tabs>

                </Box>

            </Box>
            {loadingBar && <LinearProgress
                sx={{
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: 2,
                    justifySelf: "center"
                }}/>}
            <Box sx={{
                flexGrow:1,
                py:8,
                height:'100%'

            }}>
                <Container maxWidth={false}>
                    {!loading ?
                        (displayPage == 'Home' ?
                            (
                            <Grid container spacing={4} height={'100%'}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title={"Keystone Impact: NSV With & Without Keystone for Selected Period"}/>
                                        <Divider/>
                                            <CardContent>
                                                    { (statData && statData['NSVWithKeystone'] && Object.keys(statData['NSVWithKeystone']).length > 0)  ?
                                                        <LineGraphStat data={{
                                                            series: removeObject(statData['NSVWithKeystone'], 'Date'),
                                                            labels: (statData['NSVWithKeystone']) ? statData['NSVWithKeystone'].Date : []}}/>
                                                        : <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center"}}>No Data Available for Selected Period</Alert> }
                                            </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={8}>
                                    <Card sx={{height: '100%'}}>
                                        <CardHeader title={"Value of Opportunities"}/>
                                        <Divider/>
                                        <CardContent>
                                            <PieChartStat data={(statData['OpportunitiesByType']) ? combineArrays(statData['OpportunitiesByType']['opportunity_type'], statData['OpportunitiesByType']['count_opp']) : <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center"}}>No Opportunities Gained for Selected Period</Alert>}/>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item alignItems="stretch" xs={12} md={6} lg={4}>
                                    <Box   display="flex"
                                           justifyContent="center"
                                           alignItems="center">
                                        <SingleValueIndicator  symbol={<KeyboardDoubleArrowRight/>} title={'Conversion Ratio'} value={(statData['ConversionRatio']) ? statData['ConversionRatio']['Conversion_Ratio'] : <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center"}}>No Data Available for Selected Period</Alert> }/></Box>
                                </Grid>
                                <Grid item xs={12} height={'100%'}>
                                    {(tableDataCollection.find(({division}) => division === "Performers")) ? <StatsTable
                                        data={genTableDataCollection(tableDataCollection.find(({division}) => division === "Performers"))}/> : null}
                                </Grid>
                                <Grid item xs={12} height={'100%'}>
                                    {(tableDataCollection.find(({division}) => division === "Categories")) ? <StatsTable
                                        data={genTableDataCollection(tableDataCollection.find(({division}) => division === "Categories"))}/> : null}
                                </Grid>
                                <Grid item xs={12} height={'100%'}>
                                    {(tableDataCollection.find(({division}) => division === "Brands")) ? <StatsTable
                                        data={genTableDataCollection(tableDataCollection.find(({division}) => division === "Brands"))}/> : null}
                                </Grid>
                                {(mainCtx.activeOrganization.name !== "DKSH_HK" && mainCtx.activeOrganization.name !== "DKSH" && mainCtx.activeOrganization.name !== "DKSH_MO")? <Grid item xs={12} height={'100%'}>
                                    {(tableDataCollection.find(({division}) => division === "Areas")) ? <StatsTable
                                        data={genTableDataCollection(tableDataCollection.find(({division}) => division === "Areas"))}/> : null}
                                </Grid> : null}
                                <Grid item xs={12} height={'100%'}>
                                    {(tableDataCollection.find(({division}) => division === "Regions")) ? <StatsTable
                                        data={genTableDataCollection(tableDataCollection.find(({division}) => division === "Regions"))}/> : null}
                                </Grid>
                                <Grid item xs={12} height={'100%'}>
                                    {(tableDataCollection.find(({division}) => division === "Stores")) ? <StatsTable
                                        data={genTableDataCollection(tableDataCollection.find(({division}) => division === "Stores"))}/> : null}
                                </Grid>
                                <Grid item xs={12} height={'100%'}>
                                    {(tableDataCollection.find(({division}) => division === "Products")) ? <StatsTable
                                        data={genTableDataCollection(tableDataCollection.find(({division}) => division === "Products"))}/> : null}
                                </Grid>


                            </Grid>

                            )
                            : (displayPage == 'UserReport' && Object.keys(userStatData).length > 0) ? <UserReportingView Top5Pie={combineArrays((userStatData['Bottom5UsersPie']['Username']), userStatData['Top5UsersPie']['Contribution'] )}
                                                                                 Bottom5Pie={combineArrays((userStatData['Bottom5UsersPie']['Username']), userStatData['Bottom5UsersPie']['Contribution'] )}
                                                                                 Top5TableRows={mapTableRows(userStatData['Top5UsersTable'])}
                                                                                 Bottom5TableRows={mapTableRows(userStatData['Top5UsersTable'])}
                                                                                 UserDropDown={mapTableRows(userStatData['UserDropdown'])}
                                                                                 UserBarDataAvailable={genBarSeries(userStatData['OpportunityAvailableBar'])}
                                                                                 UserBarDataGained={genBarSeries(userStatData['OpportunityGainedBar'])}
                                                                                 AllUsersTable={mapTableRows(userStatData['AllUsersTable'])}
                                                                                 AllUsersResolvedOpps={mapTableRows(userStatData['AllUsersResolvedOpps'])}
                                                                                 AllUsersPendingOpps={mapTableRows(userStatData['AllUsersPendingOpps'])}
                                                                                 DateSelectionStart={keystoneCtx.dateValue[0].toISOString().slice(0, 10)}
                                                                                 DateSelectionEnd={keystoneCtx.dateValue[1].toISOString().slice(0, 10)}
                                                                                 UserStatsData={userStatData}/> : <HomeIssueSkeleton/>
                        ):  <HomeIssueSkeleton/> }
                </Container>

            </Box>
        </Box>
    )
}

export default StatsPage;