import React, { useContext, useEffect, useState } from "react";
import DistributionPie from "./widgets/DistributionPie";
import DistributionColumn from "./widgets/DistributionColumn";
import DistributionTree from "./widgets/DistributionTree";
import DistributionHeat from "./widgets/DistributionHeat";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  LinearProgress,
  Alert,
  useTheme, ToggleButton, ToggleButtonGroup
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useMainContext } from "../../../../../contexts/MainContext";
import HomeIssueSkeleton from "../../summary/Skeleton";
import { useKeystoneContext } from "../../../../../contexts/KeystoneContext";
import FilterAsDrawer from "../../filter/FilterAsDrawer";
import ReactGA from "react-ga4";
import { ExploreDistributionStore } from "../../../../../cog/keystone/explore";
import {BubbleChart, TableChart} from "@mui/icons-material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import OverviewSwitchTable from "../overview/widgets/OverviewSwitchTable";
import DistributionSwitchTable from "./widgets/DistributionSwitchTable";
import {
  ConvertTreeFromBeingUsedToApiSave,
  GenerateNewPsuTree
} from "../../productstoreuniverse/utility/NodeTypesAndOptions";

const ExploreDistributionPage = () => {
  const mainCtx = useMainContext()
  const keystoneCtx = useKeystoneContext()
  const firstName = mainCtx.user.name.split(/\s+/)
  const timeElapsed = Date.now();
  const now = new Date(timeElapsed);
  const curHr = now.getHours()
  const [distributionStatData, setDistributionStatData] = useState<any>({})
  const [distributionData, setDistributionData] = useState(null);
  const [loading, setLoading] = useState<boolean>(false)
  const [apiData, setApiData] = useState([]);
  const [tableMode1, setTableMode1] = React.useState<string | null>('graphic');
  const [tableMode2, setTableMode2] = React.useState<string | null>('graphic');
  const [tableMode3, setTableMode3] = React.useState<string | null>('graphic');
  const [tableMode4, setTableMode4] = React.useState<string | null>('graphic');
  const [tableMode5, setTableMode5] = React.useState<string | null>('graphic');
  const [tableMode6, setTableMode6] = React.useState<string | null>('graphic');
  const [tableMode7, setTableMode7] = React.useState<string | null>('graphic');

  // Sets which options to display in the toolbar
  useEffect(() => {
    if (keystoneCtx.toolBarDisplayOptions.selectOpps != false ||
      keystoneCtx.toolBarDisplayOptions.filterToMe != false ||
      keystoneCtx.toolBarDisplayOptions.stats != false ||
      keystoneCtx.toolBarDisplayOptions.filter != true) {
      keystoneCtx.setToolBarDisplayOptions({filter: true, stats: false, selectOpps: false, filterToMe: false, landingDateSelector: true})

    }
  }, [keystoneCtx.toolBarDisplayOptions])

  let greetingMessage: string

  if (curHr < 12) {
    greetingMessage = "Good Morning "
  } else if (curHr < 18) {
    greetingMessage = "Good Afternoon "
  } else {
    greetingMessage = "Good Evening "
  }

  const fetchDistributionStatData = async () => {
    setLoading(true)

    const dateSelectionStart = keystoneCtx.dateValue[0].toISOString().slice(0, 10)
    const dateSelectionEnd = keystoneCtx.dateValue[1].toISOString().slice(0, 10)
    let limit = "15"

    if (window.innerWidth <= 800) {
      limit = "5"
    }

    // const tempStartDate = "2021-10-02"
    // const tempEndDate = "2024-05-27"

    const response = await ExploreDistributionStore.DistributionStats(
        {
          clientName: mainCtx.activeOrganization.name,
          // dateSelectionStart: tempStartDate,
          dateSelectionStart: dateSelectionStart,
          // dateSelectionEnd: tempEndDate,
          dateSelectionEnd: dateSelectionEnd,
          limit: limit,
          filters: keystoneCtx.filters,
          defaultFilters: keystoneCtx.defaultFilters,
          PSU: keystoneCtx.activePSU != null
              ? ConvertTreeFromBeingUsedToApiSave(keystoneCtx.activePSU)
              : ConvertTreeFromBeingUsedToApiSave(GenerateNewPsuTree(mainCtx.activeOrganization.name))
        })

    if (response) {
      setDistributionStatData(response)
    }
    setLoading(false)
  }

  // Checks if the filter is ready to use and then fetches cards with filter
  // useEffect(() => {
  //   if (keystoneCtx.filtersReady == true) {
  //     keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
  //   }
  // }, [keystoneCtx.filtersReady])


  useEffect(() => {
    if (keystoneCtx.filtersReady == true && keystoneCtx.activePsuReady == true) {
      fetchDistributionStatData()
    }
  }, [mainCtx.activeOrganization.name, keystoneCtx.dateValue, keystoneCtx.flipSwitchForCards])

  useEffect(() => {

    ReactGA.event("keystone_view" ,{
      userID: mainCtx.user.id,
      orgID: mainCtx.activeOrganization.id,
      pageName: 'Explore Distribution Page'
    });
  }, [mainCtx.activeOrganization])

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode1(newMode);
  };
  const handleAlignment2 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode2(newMode);
  };
  const handleAlignment3 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode3(newMode);
  };
  const handleAlignment4 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode4(newMode);
  };
  const handleAlignment5 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode5(newMode);
  };
  const handleAlignment6 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode6(newMode);
  };
  const handleAlignment7 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode7(newMode);
  };

  return (
    <>
      <Helmet>
        <title>Explore Distribution</title>
      </Helmet>
      <Box sx={{mt: 1}}>
        <Box
          sx={{
            flexGrow: 1,
            py: 1
          }}
        >
          <Container maxWidth={false}>
            <Box marginLeft={0}>
              <FilterAsDrawer
                setFilters={keystoneCtx.setFilters}
                setLevelMode={keystoneCtx.setLevelMode}
                filters={keystoneCtx.filters}
                open={keystoneCtx.filterOpen}
                setOpen={keystoneCtx.setFilterOpen}
                onClose={keystoneCtx.handleFilterClose}
                client={mainCtx.activeOrganization.name}
                organizationId={mainCtx.activeOrganization.id}
                userID={mainCtx.user.id}
                onClear={keystoneCtx.handleFilterClear}
              />
            </Box>
            {!loading && keystoneCtx.filtersReady ?
              <Box>
                <Box sx={{ mb: 4 }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography variant="h4">{greetingMessage + firstName[0] + ", Here Is Your Distribution Summary"}</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 3 }} />
                </Box>
                <Grid
                  container
                  spacing={4}
                >
                  <Grid item lg={12} xs={12}>
                    <Card>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <CardHeader
                        title={"Distribution Opportunities Available (Top 20 SKU's)"}
                      />
                      <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <ToggleButtonGroup
                            value={tableMode1}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="text alignment"
                            sx={{ mr: 1 }}
                        >
                          <ToggleButton value="graphic"
                                        sx={{padding: '8px', width: '40px', height: '40px'}}>
                            <BubbleChart fontSize="small"/>
                          </ToggleButton>
                          <ToggleButton value="table"
                                        sx={{padding: '8px', width: '40px', height: '40px'}}>
                            <TableChart fontSize="small"/>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      </Box>
                      <Divider />
                      <CardContent>
                        {tableMode1 === "graphic" ? (
                                distributionStatData["DistributionOfTopSKUs"] && Object.keys(distributionStatData["DistributionOfTopSKUs"]).length > 0 ? (
                                    <DistributionTree
                                        data={distributionStatData["DistributionOfTopSKUs"]}
                                        org={mainCtx.activeOrganization.name}
                                        productHeader={keystoneCtx.colMap.Product}
                                    />
                                ) : (
                                    <Alert
                                        severity="warning"
                                        sx={{
                                          height: 165,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginBottom: '15px',
                                          backgroundColor: (theme) =>
                                              theme.palette.mode === 'dark'
                                                  ? useTheme().palette.background.default
                                                  : "warning",
                                        }}
                                    >
                                      No Data Available for Selected Period & Filters
                                    </Alert>
                                )
                        ) : (
                            <Box sx={{ height: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                              {distributionStatData["DistributionOfTopSKUs"] && Object.keys(distributionStatData["DistributionOfTopSKUs"]).length > 0 ? (
                                  <DistributionSwitchTable
                                      statRows={distributionStatData["DistributionOfTopSKUs"]}
                                      chartName = 'DistributionOfTopSKUs'
                                  />
                              ) : (
                                  <Alert
                                      severity="warning"
                                      sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? useTheme().palette.background.default
                                                : "warning",
                                      }}
                                  >
                                    No Data Available for Selected Period & Filters
                                  </Alert>
                              )}
                            </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Card>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                      <CardHeader
                        title={"Regional Numeric Distribution per SKU (Top 20 SKU's)"}
                      />
                      <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <ToggleButtonGroup
                            value={tableMode2}
                            exclusive
                            onChange={handleAlignment2}
                            aria-label="text alignment"
                            sx={{ mr: 1 }}
                        >
                          <ToggleButton value="graphic"
                                        sx={{padding: '8px', width: '40px', height: '40px'}}>
                            <BubbleChart fontSize="small"/>
                          </ToggleButton>
                          <ToggleButton value="table"
                                        sx={{padding: '8px', width: '40px', height: '40px'}}>
                            <TableChart fontSize="small"/>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      </Box>
                      <Divider />
                      <CardContent>
                        {tableMode2 === "graphic" ? (
                            distributionStatData["RegionalProductDistribution"] && Object.keys(distributionStatData["RegionalProductDistribution"]).length > 0 ? (
                                <DistributionHeat
                                    data={distributionStatData["RegionalProductDistribution"]}
                                    xAxisColumnName={keystoneCtx.colMap.Product}
                                    yAxisColumnName={keystoneCtx.colMap.Area_Hierarchy_1}
                                    valueColumnName={"Distribution"}
                                    org={mainCtx.activeOrganization.name}
                                />
                            ) : (
                                <Alert
                                    severity="warning"
                                    sx={{
                                      height: 165,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: '15px',
                                      backgroundColor: (theme) =>
                                          theme.palette.mode === 'dark'
                                              ? useTheme().palette.background.default
                                              : "warning",
                                    }}
                                >
                                  No Data Available for Selected Period & Filters
                                </Alert>
                            )
                        ) : (
                            <Box sx={{ height: 500, width: '100%', alignItems: 'center', overflowY: 'auto', overflowX: 'auto' }}>
                              {distributionStatData["RegionalProductDistribution"] && Object.keys(distributionStatData["RegionalProductDistribution"]).length > 0 ? (
                                  <DistributionSwitchTable
                                      statRows={distributionStatData["RegionalProductDistribution"]}
                                      chartName = 'RegionalProductDistribution'
                                  />
                              ) : (
                                  <Alert
                                      severity="warning"
                                      sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? useTheme().palette.background.default
                                                : "warning",
                                      }}
                                  >
                                    No Data Available for Selected Period & Filters
                                  </Alert>
                              )}
                            </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Card>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <CardHeader
                            title={"Regional Numeric Distribution per Category (Top 20 SKU's)"}
                        />
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <ToggleButtonGroup
                              value={tableMode3}
                              exclusive
                              onChange={handleAlignment3}
                              aria-label="text alignment"
                              sx={{ mr: 1 }}
                          >
                            <ToggleButton value="graphic"
                                          sx={{padding: '8px', width: '40px', height: '40px'}}>
                              <BubbleChart fontSize="small"/>
                            </ToggleButton>
                            <ToggleButton value="table"
                                          sx={{padding: '8px', width: '40px', height: '40px'}}>
                              <TableChart fontSize="small"/>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>
                      <Divider />
                      <CardContent>
                        {tableMode3 === "graphic" ? (
                            distributionStatData["RegionalCategoryDistribution"] && Object.keys(distributionStatData["RegionalCategoryDistribution"]).length > 0 ? (
                                <DistributionHeat
                                    data={distributionStatData["RegionalCategoryDistribution"]}
                                    xAxisColumnName={keystoneCtx.colMap.Product_Hierarchy_1}
                                    yAxisColumnName={keystoneCtx.colMap.Area_Hierarchy_1}
                                    valueColumnName={"Distribution"}
                                    org={mainCtx.activeOrganization.name}
                                />
                            ) : (
                                <Alert
                                    severity="warning"
                                    sx={{
                                      height: 165,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: '15px',
                                      backgroundColor: (theme) =>
                                          theme.palette.mode === 'dark'
                                              ? useTheme().palette.background.default
                                              : "warning",
                                    }}
                                >
                                  No Data Available for Selected Period & Filters
                                </Alert>
                            )
                        ) : (
                            <Box sx={{ height: 500, width: '100%', alignItems: 'center', overflowY: 'auto', overflowX: 'auto' }}>
                              {distributionStatData["RegionalCategoryDistribution"] && Object.keys(distributionStatData["RegionalCategoryDistribution"]).length > 0 ? (
                                  <DistributionSwitchTable
                                      statRows={distributionStatData["RegionalCategoryDistribution"]}
                                      chartName = 'RegionalCategoryDistribution'
                                  />
                              ) : (
                                  <Alert
                                      severity="warning"
                                      sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? useTheme().palette.background.default
                                                : "warning",
                                      }}
                                  >
                                    No Data Available for Selected Period & Filters
                                  </Alert>
                              )}
                            </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Card>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                      <CardHeader
                        title={"Distribution of Categories in Top 10 Customers (Top 20 SKU's)"}
                      />
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <ToggleButtonGroup
                              value={tableMode4}
                              exclusive
                              onChange={handleAlignment4}
                              aria-label="text alignment"
                              sx={{ mr: 1 }}
                          >
                            <ToggleButton value="graphic"
                                          sx={{padding: '8px', width: '40px', height: '40px'}}>
                              <BubbleChart fontSize="small"/>
                            </ToggleButton>
                            <ToggleButton value="table"
                                          sx={{padding: '8px', width: '40px', height: '40px'}}>
                              <TableChart fontSize="small"/>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>
                      <Divider />
                      <CardContent>
                        {tableMode4 === "graphic" ? (
                            distributionStatData["DistributionPerCategory"] && Object.keys(distributionStatData["DistributionPerCategory"]).length > 0 ? (
                                <DistributionColumn
                                    key={3}
                                    header={keystoneCtx.colMap.Area_Hierarchy_3}
                                    data={distributionStatData["DistributionPerCategory"]}
                                    labelValue={keystoneCtx.colMap.Area_Hierarchy_3}
                                    org={mainCtx.activeOrganization.name}
                                    seriesValue={keystoneCtx.colMap.Product_Hierarchy_1}
                                />
                            ) : (
                                <Alert
                                    severity="warning"
                                    sx={{
                                      height: 165,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: '15px',
                                      backgroundColor: (theme) =>
                                          theme.palette.mode === 'dark'
                                              ? useTheme().palette.background.default
                                              : "warning",
                                    }}
                                >
                                  No Data Available for Selected Period & Filters
                                </Alert>
                            )
                        ) : (
                            <Box sx={{ height: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                              {distributionStatData["DistributionPerCategory"] && Object.keys(distributionStatData["DistributionPerCategory"]).length > 0 ? (
                                  <DistributionSwitchTable
                                      statRows={distributionStatData["DistributionPerCategory"]}
                                      chartName = 'DistributionPerCategory'
                                  />
                              ) : (
                                  <Alert
                                      severity="warning"
                                      sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? useTheme().palette.background.default
                                                : "warning",
                                      }}
                                  >
                                    No Data Available for Selected Period & Filters
                                  </Alert>
                              )}
                            </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  {mainCtx.activeOrganization.name !== "KIMBERLY_CLARK" && mainCtx.activeOrganization.name !== "CASALESPNP" && mainCtx.activeOrganization.name !== "CASALESPNP_UNILEVER" && mainCtx.activeOrganization.name !== "CASALESPNP_UNILEVER_TOP10STORES100SKUS" ?
                    <Grid item lg={6} xs={12}>
                      <Card>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                        <CardHeader
                          title={"Most Distributed SKU's by Trade Channel"}
                        />
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <ToggleButtonGroup
                                value={tableMode5}
                                exclusive
                                onChange={handleAlignment5}
                                aria-label="text alignment"
                                sx={{ mr: 1 }}
                            >
                              <ToggleButton value="graphic"
                                            sx={{padding: '8px', width: '40px', height: '40px'}}>
                                <BubbleChart fontSize="small"/>
                              </ToggleButton>
                              <ToggleButton value="table"
                                            sx={{padding: '8px', width: '40px', height: '40px'}}>
                                <TableChart fontSize="small"/>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                        <Divider />
                        <CardContent>
                          {tableMode5 === "graphic" ? (
                              distributionStatData["MostDistributedProducts"] && Object.keys(distributionStatData["MostDistributedProducts"]).length > 0 ? (
                                  <DistributionColumn
                                      key={3}
                                      header={keystoneCtx.colMap.Product}
                                      data={distributionStatData["MostDistributedProducts"]}
                                      labelValue={keystoneCtx.colMap.Product}
                                      org={mainCtx.activeOrganization.name}
                                      seriesValue={keystoneCtx.colMap.Customer_Hierarchy_1}
                                  />
                              ) : (
                                  <Alert
                                      severity="warning"
                                      sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? useTheme().palette.background.default
                                                : "warning",
                                      }}
                                  >
                                    No Data Available for Selected Period & Filters
                                  </Alert>
                              )
                          ) : (
                              <Box sx={{ height: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                                {distributionStatData["MostDistributedProducts"] && Object.keys(distributionStatData["MostDistributedProducts"]).length > 0 ? (
                                    <DistributionSwitchTable
                                        statRows={distributionStatData["MostDistributedProducts"]}
                                        chartName = 'MostDistributedProducts'
                                    />
                                ) : (
                                    <Alert
                                        severity="warning"
                                        sx={{
                                          height: 165,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginBottom: '15px',
                                          backgroundColor: (theme) =>
                                              theme.palette.mode === 'dark'
                                                  ? useTheme().palette.background.default
                                                  : "warning",
                                        }}
                                    >
                                      No Data Available for Selected Period & Filters
                                    </Alert>
                                )}
                              </Box>
                          )}
                        </CardContent>
                      </Card>
                    </Grid> : null}
                  {mainCtx.activeOrganization.name !== "KIMBERLY_CLARK" && mainCtx.activeOrganization.name !== "CASALESPNP" && mainCtx.activeOrganization.name !== "CASALESPNP_UNILEVER" && mainCtx.activeOrganization.name !== "CASALESPNP_UNILEVER_TOP10STORES100SKUS" ?
                    <Grid item lg={6} xs={12}>
                      <Card>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                        <CardHeader
                          title={"Least Distributed SKU's by Trade Channel"}
                        />
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <ToggleButtonGroup
                                value={tableMode6}
                                exclusive
                                onChange={handleAlignment6}
                                aria-label="text alignment"
                                sx={{ mr: 1 }}
                            >
                              <ToggleButton value="graphic"
                                            sx={{padding: '8px', width: '40px', height: '40px'}}>
                                <BubbleChart fontSize="small"/>
                              </ToggleButton>
                              <ToggleButton value="table"
                                            sx={{padding: '8px', width: '40px', height: '40px'}}>
                                <TableChart fontSize="small"/>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                        <Divider />
                        <CardContent>
                          {tableMode6 === "graphic" ? (
                              distributionStatData["LeastDistributedProducts"] && Object.keys(distributionStatData["LeastDistributedProducts"]).length > 0 ? (
                                  <DistributionColumn
                                      key={3}
                                      header={keystoneCtx.colMap.Product}
                                      data={distributionStatData["LeastDistributedProducts"]}
                                      labelValue={keystoneCtx.colMap.Product}
                                      org={mainCtx.activeOrganization.name}
                                      seriesValue={keystoneCtx.colMap.Customer_Hierarchy_1}
                                  />
                              ) : (
                                  <Alert
                                      severity="warning"
                                      sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? useTheme().palette.background.default
                                                : "warning",
                                      }}
                                  >
                                    No Data Available for Selected Period & Filters
                                  </Alert>
                              )
                          ) : (
                              <Box sx={{ height: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                                {distributionStatData["LeastDistributedProducts"] && Object.keys(distributionStatData["LeastDistributedProducts"]).length > 0 ? (
                                    <DistributionSwitchTable
                                        statRows={distributionStatData["LeastDistributedProducts"]}
                                        chartName = 'LeastDistributedProducts'
                                    />
                                ) : (
                                    <Alert
                                        severity="warning"
                                        sx={{
                                          height: 165,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginBottom: '15px',
                                          backgroundColor: (theme) =>
                                              theme.palette.mode === 'dark'
                                                  ? useTheme().palette.background.default
                                                  : "warning",
                                        }}
                                    >
                                      No Data Available for Selected Period & Filters
                                    </Alert>
                                )}
                              </Box>
                          )}
                        </CardContent>
                      </Card>
                    </Grid> : null}
                  <Grid item lg={12} xs={12}>
                    <Card>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <CardHeader
                            title={"Available Opportunities by Customer"}
                        />
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <ToggleButtonGroup
                              value={tableMode7}
                              exclusive
                              onChange={handleAlignment7}
                              aria-label="text alignment"
                              sx={{ mr: 1 }}
                          >
                            <ToggleButton value="graphic"
                                          sx={{padding: '8px', width: '40px', height: '40px'}}>
                              <BubbleChart fontSize="small"/>
                            </ToggleButton>
                            <ToggleButton value="table"
                                          sx={{padding: '8px', width: '40px', height: '40px'}}>
                              <TableChart fontSize="small"/>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>
                      <Divider />
                      <CardContent>
                        {tableMode7 === "graphic" ? (
                            distributionStatData["Top10PercStores"] && Object.keys(distributionStatData["Top10PercStores"]).length > 0 ? (
                                <DistributionPie
                                    series={distributionStatData["Top10PercStores"]["opps"]}
                                    labels={distributionStatData["Top10PercStores"][keystoneCtx.colMap.Area_Hierarchy_3]}
                                    header={keystoneCtx.colMap.Area_Hierarchy_3}
                                />
                            ) : (
                                <Alert
                                    severity="warning"
                                    sx={{
                                      height: 165,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: '15px',
                                      backgroundColor: (theme) =>
                                          theme.palette.mode === 'dark'
                                              ? useTheme().palette.background.default
                                              : "warning",
                                    }}
                                >
                                  No Data Available for Selected Period & Filters
                                </Alert>
                            )
                        ) : (
                            <Box sx={{ height: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                              {distributionStatData["Top10PercStores"] && Object.keys(distributionStatData["Top10PercStores"]).length > 0 ? (
                                  <DistributionSwitchTable
                                      statRows={distributionStatData["Top10PercStores"]}
                                      chartName = 'Top10PercStores'
                                  />
                              ) : (
                                  <Alert
                                      severity="warning"
                                      sx={{
                                        height: 165,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: '15px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? useTheme().palette.background.default
                                                : "warning",
                                      }}
                                  >
                                    No Data Available for Selected Period & Filters
                                  </Alert>
                              )}
                            </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              :
              <Box>
                <LinearProgress
                  sx={{
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: 2,
                    justifySelf: "center"
                  }} />
                <HomeIssueSkeleton />
              </Box>
            }
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default ExploreDistributionPage;