import type { FC } from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import {
  Box
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from "apexcharts";
import {InsightWidgetProps} from "../InsightWidgetSwitcher";

const GenericLineChart: FC<InsightWidgetProps> = ({chartName, chartData, labelValue}) => {
  const theme = useTheme();

  const chartType = "line"
  const columnName = labelValue
  const seriesCols = Object.keys(chartData).filter((k) => k != columnName)
  const series = seriesCols ? seriesCols.map((c) => {
    return {
      name: c,
      data: chartData && chartData[c] ? chartData[c] : []
    }
  }) : [{name: "y", data: []}]

  const numberWithCommas = (x) => {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const chartOptions: ApexOptions = {
    chart: {
      type: "line",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight"
    },
    title: {
      text: chartName,
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
      padding: {
        bottom: 20
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          let num = Math.round((val + Number.EPSILON) * 100) / 100
          return numberWithCommas(num)
        }
      }
    },
    xaxis: {
      labels: {
        rotateAlways: true,
        show: true,
        rotate: -60,
        trim: true,
      },
      categories: chartData && chartData[columnName] ? chartData[columnName] : [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          if (val == 0) {
            return "R " + val
          } else if (val >= 1000000) {
            return "R " + (val / 1000000) + " M"
          } else if (val < 1000000 && val > 1000) {
            return "R " + (val / 1000) + " K"
          } else if (val < -1000 && val > -1000000) {
            return "R " + (val / 1000) + " K"
          } else if (val <= -1000000) {
            return "R " + (val / 1000000) + " M"
          } else {
            return "R " + val
          }
        }
      }

    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '90%',
        minHeight: '500px',
        boxShadow: 3,
        p: 2,
        borderRadius: 1,
      }}
    >
      <Chart
        type={chartType}
        width={"100%"}
        height={"100%"}
        series={series}
        options={chartOptions}
      />
    </Box>
  );
}

export default GenericLineChart;