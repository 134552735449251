/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../../api/jsonrpc';
import CardAttribute from "./CardAttribute";

export interface FindAllRequest {
  clientName: string,
  cardId: string
}

export interface FindAllResponse {
  cardAttributes: CardAttribute[];
}

export interface FindOneRequest {
  clientName: string,
  id: string
}

export interface FindOneResponse {
  cardAttribute: CardAttribute;
}

const Store = {
  serviceProvider: 'CardAttribute-Store',
  async FindAll(request: FindAllRequest): Promise<FindAllResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.FindAll`,
      request: [{
        'client': request.clientName,
        'cardId': request.cardId,
      }]
    });

    return response;
  },
  async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.FindOne`,
      request: [{
        'id': request.id
      }]
    });

    return response;
  },
};

export default Store;