import {FC, useContext, useState} from "react";
import {useMainContext} from "../../../../../../contexts/MainContext";
import {useKeystoneContext} from "../../../../../../contexts/KeystoneContext";
import SettingsContext from "../../../../../../contexts/SettingsContext";
import {useNavigate} from "react-router-dom";
import {ApexOptions} from "apexcharts";
import {useTheme} from "@mui/material/styles";
import {FilterItem} from "../../../../../../cog/keystone/filter/Filter";
import {Box} from "@mui/material";
import Chart from "react-apexcharts";
import * as React from "react";
import currencySwitcher from "../../../../../../utils/currencySwitcher";
import {User} from "../../../../../../cog/user";
import {Member} from "../../../../../../cog/keystone/member";
import {calcSVGTransformOrigin} from "framer-motion/types/render/svg/utils/transform-origin";

interface StoreColumn {
    data: {};
    labelValue: string;
    header :string;
    org: string;
    currentMembers?:Member[];
    category: string;
    statusColours: boolean;
}
const StoreColumn:FC<StoreColumn> = ({ data, labelValue,header, org, currentMembers, category, statusColours}) => {
    const chartType = "bar";
    let formatter = Intl.NumberFormat('en', {notation: 'compact'})
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const settingsCtx = useContext(SettingsContext)
    const navigate = useNavigate()

    const removeNull = (array) => {
        return array.map((value) => {
            if (value === null) {
                return 0;
            }
            return value;
        });
    }

    const columnName = labelValue
    const seriesCols = Object.keys(data).filter((k) => k != columnName)

    const series = seriesCols ? seriesCols.map((c) => {

        return {
            name: c,
            data: data && data[c] ? removeNull(data[c]) : []
        }
    }) : [{name: "y", data: []}]

    const getYAxisTitle = () => {

        if(category.includes("Count")){
           return "Store count"
        }
        if(category.includes("User")){
            return "User m-Value"
        }
        return "Store m-Value"

    }

    const navigateToOpportunities = async () => {
        keystoneCtx.RefreshCards()
        const org = mainCtx.activeOrganization.name.replace(/ |_/g,'-');
        navigate("/app/" + org.toLowerCase() + "/opportunities")
    }

    const generateFilters = (config, event, chartContext) => {

        const dataSeries = Object.keys(data).filter(item => item !== 'Store' && item !== 'Username');
        const filters: FilterItem[] = []

        if(currentMembers) {
            filters.push({
                header: header,
                operation: "=",
                prefix: "AND",
                values: currentMembers[config.dataPointIndex].id
            })
        }else {
            filters.push({
                header: header,
                operation: "=",
                prefix: "AND",
                values: data['Store'][config.dataPointIndex]
            })

        }
        if(dataSeries && dataSeries.length > 1){

            let tempFormatted = dataSeries[config.seriesIndex].replaceAll("_", " ")
            let statusID = keystoneCtx.statusCardOptions[tempFormatted]

            filters.push({
                header: 'list_id',
                operation: "=",
                prefix: "AND",
                values: statusID
            })
        }



        const filteredFilter = filters.filter((f) => !f.level)
        const filteredWithDefault = filteredFilter.concat(keystoneCtx.filters)

        keystoneCtx.setClickThroughFilters(filteredWithDefault)
        // keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)

    }


    const chartOptions: ApexOptions = {
        chart: {
            type: "bar",
            stacked: false,
            background: useTheme().palette.background.paper,
            foreColor: useTheme().palette.text.primary,
            events: {
                dataPointSelection(event, chartContext, config) {
                    generateFilters(config, event, chartContext)
                    navigateToOpportunities()
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
            }
        },
        xaxis: {
            type: 'category',
            tickAmount: 'dataPoints',
            labels: {
                rotateAlways: true,
                rotate: -60,
                trim: true,
                formatter: function (val) {
                    if (val === '"Z9zJK0MFpkQ3Nxie9JfwSS9pyRd2"') {
                        return "Pending Assignment"
                    } else if (currentMembers && val) {
                       let tempMember = currentMembers.find(mem => mem && mem["id"].includes(val.replaceAll("\"", "")))
                        // let tempPerson = currentMembers.find(mem => mem["id"].includes(val.replaceAll("\"", " ")))
                       if(tempMember){
                            return tempMember['displayName']
                       } else {
                            return "Username Not Found"
                       }
                    }
                    return val
                }

            },
            tickPlacement: 'on',
            categories: data && data[columnName] ? data[columnName] : [],
            title: {
                text: labelValue,

            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    if(category.includes("Count")){
                        return val + ""
                    }
                    return currencySwitcher(org) + formatter.format(val)
                }
            },
            title: {
                text: getYAxisTitle(),
            },
        },
        stroke: {
            show: true,
            width: 3,
            colors: ['transparent']
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: true,
            position: 'bottom',
        },
        grid: {
            padding: {
                bottom: 20,
                left: 80,
            }
        },
        colors: statusColours? ['#4caf50', '#f2bc53', '#f45c5c'] : ["#3aa9a6", "#4b4b4b", "#f2bc53", "#f45c5c", "#e1d5c6", "#8c9494"],
        tooltip: {
            y: {
                formatter: function (val) {
                    return currencySwitcher(org) + formatter.format(val)
                }
            },
            theme: settingsCtx.settings.theme === 'LIGHT' ? 'light' : 'dark'
        },
    };

    return (
        <Box sx={{ minHeight: 500, minWidth: 300 }}>
            <Chart
                type={chartType}
                options={chartOptions}
                height={"90%"}
                width={"90%"}
                series={series}
            />
        </Box>
    );
}

export default StoreColumn