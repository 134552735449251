import { API, PerformJSONRPCRequest } from "../../api/jsonrpc";
import { StatsData } from "../card/Card";

export interface HomePageStatsRequest {
  clientName: string
  dateSelectionStart: string
  dateSelectionEnd: string
  limit: string
  userID: string
}

export interface HomePageStatsResponse {
  homePageStats: Record<string, StatsData>
}

const Store = {
  serviceProvider: 'Home-Store',
  async HomePageStats(request: HomePageStatsRequest): Promise<HomePageStatsResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.HomePageStats`,
      request: [{
        "client": request.clientName,
        "dateSelectionStart": request.dateSelectionStart,
        "dateSelectionEnd": request.dateSelectionEnd,
        "limit": request.limit,
        "userID": request.userID,
      }]
    })
    return response.homePageStats
  },
}

export default Store;