const authorizedApiUrl = 'api/authorised'
const publicApiUrl = 'api/public'

export const localConfig = {
    baseUrl: 'http://localhost:3000/',
    apiUrl: 'http://localhost:8080/' + publicApiUrl,
    authorizedApiUrl: 'http://localhost:8080/' + authorizedApiUrl,
    auth0: {
        client_id: 'LJMFxyGhjedIbXdGGcenA8z9fCU5dA8M',
        domain: 'cognizancedotvision.us.auth0.com',
        redirect_uri: 'http://localhost:3000/',
        audience: '/api/test',
        scope: 'openid',
    },
    auth0Logout: {
        returnTo: 'http://localhost:3000/'
    },
    firebase: {
        apiKey: "AIzaSyAp2-6rZcamQoCgrwAfBCd0zBNcM9DrDIA",
        authDomain: "dev-cog-frontend.firebaseapp.com",
        databaseURL: "https://dev-cog-frontend.firebaseio.com",
        projectId: "dev-cog-frontend",
        storageBucket: "dev-cog-frontend.appspot.com",
        messagingSenderId: "1090706948299",
        appId: "1:1090706948299:web:6c46158a812603de439b4e",
        measurementId: "G-J59PE1E8J2"
    },
    analytics : 'UA-217029476-3',
    analytics4 : 'G-LRPSMW75MJ',
    gtm: {
        containerId: ''
    },
}

export const devConfig = {
    baseUrl: 'https://dev-cog-frontend.web.app/',
    apiUrl: 'https://dev-cog-analytics-engine-go-ozbksvuw7a-uc.a.run.app/',
    authorizedApiUrl: 'https://dev-cog-analytics-engine-go-ozbksvuw7a-uc.a.run.app/' + authorizedApiUrl,
    auth0: {
        client_id: 'LJMFxyGhjedIbXdGGcenA8z9fCU5dA8M',
        domain: 'cognizancedotvision.us.auth0.com',
        redirect_uri: 'https://dev-cog-frontend.web.app/',
        audience: '/api/cog-dev',
        scope: 'openid',
    },
    auth0Logout: {
        returnTo: 'https://dev-cog-frontend.web.app/'
    },
    firebase: {
        apiKey: "AIzaSyAp2-6rZcamQoCgrwAfBCd0zBNcM9DrDIA",
        authDomain: "dev-cog-frontend.firebaseapp.com",
        databaseURL: "https://dev-cog-frontend.firebaseio.com",
        projectId: "dev-cog-frontend",
        storageBucket: "dev-cog-frontend.appspot.com",
        messagingSenderId: "1090706948299",
        appId: "1:1090706948299:web:6c46158a812603de439b4e",
        measurementId: "G-J59PE1E8J2"
    },
    analytics : 'UA-217029476-3',
    analytics4 : 'G-LRPSMW75MJ',
    gtm: {
        containerId: ''
    },
}

export const qaConfig = {
    baseUrl: 'https://qa-cog-frontend.web.app/',
    apiUrl: 'https://qa-cog-analytics-engine-go-ozbksvuw7a-uc.a.run.app/',
    authorizedApiUrl: 'https://qa-cog-analytics-engine-go-ozbksvuw7a-uc.a.run.app/' + authorizedApiUrl,
    auth0: {
        client_id: 'LJMFxyGhjedIbXdGGcenA8z9fCU5dA8M',
        domain: 'cognizancedotvision.us.auth0.com',
        redirect_uri: 'https://qa-cog-frontend.web.app/',
        audience: '/api/cog-qa',
        scope: 'openid',
    },
    auth0Logout: {
        returnTo: 'https://qa-cog-frontend.web.app/'
    },
    firebase: {
        apiKey: "AIzaSyBs1FEorEihWck_VmVp425MS-MdnBXp474",
        authDomain: "qa-cog-frontend.firebaseapp.com",
        databaseURL: "https://qa-cog-frontend.firebaseio.com",
        projectId: "qa-cog-frontend",
        storageBucket: "qa-cog-frontend.appspot.com",
        messagingSenderId: "1041181853913",
        appId: "1:1041181853913:web:407f1e9e02c2dc128e96c8",
        measurementId: "G-5SHJP5WYSV"
    },
    analytics : 'UA-217029476-3',
    analytics4 : 'G-LRPSMW75MJ',
    gtm: {
        containerId: ''
    },
}

export const mainConfig = {
    baseUrl: 'https://main-cog-frontend.web.app/',
    apiUrl: 'https://main-cog-analytics-engine-go-ozbksvuw7a-uc.a.run.app/',
    authorizedApiUrl: 'https://main-cog-analytics-engine-go-ozbksvuw7a-uc.a.run.app/' + authorizedApiUrl,
    auth0: {
        client_id: 'LJMFxyGhjedIbXdGGcenA8z9fCU5dA8M',
        domain: 'cognizancedotvision.us.auth0.com',
        redirect_uri: 'https://main-cog-frontend.web.app/',
        audience: '/api/cog-prod',
        scope: 'openid',
    },
    auth0Logout: {
        returnTo: 'https://main-cog-frontend.web.app/'
    },
    firebase: {
        apiKey: "AIzaSyDv_sXXuc4eM3TrghDwRjMQFa77rjvOeIc",
        authDomain: "main-cog-frontend.firebaseapp.com",
        projectId: "main-cog-frontend",
        storageBucket: "main-cog-frontend.appspot.com",
        messagingSenderId: "107692733301",
        appId: "1:107692733301:web:2ff03124b09c1f2fe7993c",
        measurementId: "G-KM19C6J5TD"
    },
    analytics : 'UA-217029476-2',
    analytics4 : 'G-P8RVRTRZBM',
    gtm: {
        containerId: ''
    },
}