import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css'
import React, {useCallback, useEffect, useState} from "react";
import {CircularProgress, Switch, TextField, Typography} from "@mui/material";
import Button from '@mui/material/Button';
import {useParams} from "react-router";
import Query from "../../../../cog/bitool/query/Query";
import Argument from "../../../../cog/bitool/query/argument/Argument";
import {ArgumentStore, QueryStore} from "../../../../cog/bitool/query";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Executor} from "../../../../cog/bitool/executor";
import AceCodeEditor from "../../../../components/AceCodeEditor";
import {DataGrid} from "@mui/x-data-grid";
import _ from "lodash";
import {useSnackbar, VariantType} from "notistack";
import {useErrorBoundary} from "use-error-boundary";
import Widget from "../../../../cog/bitool/widget/Widget";
import WidgetStore from "../../../../cog/bitool/widget/Store";


const ResponsiveGridLayout = WidthProvider(Responsive);

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const QueryEditor = () => {
  let { queryId, widgetId } = useParams();

  if (!queryId) {
    return <div> No Query ID found </div>
  } else if (queryId == '00000000-0000-0000-0000-000000000000') {
    return <div> No Query Attached </div>
  }

  if (!widgetId) {
    return <div> No Widget ID found </div>
  }

  const { ErrorBoundary, didCatch, error, reset } = useErrorBoundary()
  const {enqueueSnackbar} = useSnackbar();
  const [query, setQuery] = React.useState<Query>(new Query());
  const [queryArguments, setQueryArguments] = React.useState<Argument[]>();
  const [executedResults, setExecutedResults] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [layout, setLayout] = React.useState<{}>({
    lg: [
      {
        "w": 9,
        "h": 2,
        "x": 0,
        "y": 0,
        "i": "0",
        "moved": false,
        "static": false
      },
      {
        "w": 11,
        "h": 2,
        "x": 0,
        "y": 2,
        "i": "1",
        "moved": false,
        "static": false
      },
      {
        "w": 1,
        "h": 3,
        "x": 11,
        "y": 2,
        "i": "2",
        "moved": false,
        "static": false
      },
      {
        "w": 6,
        "h": 30,
        "x": 0,
        "y": 5,
        "i": "3",
        "moved": false,
        "static": false
      },
      {
        "w": 6,
        "h": 13,
        "x": 6,
        "y": 5,
        "i": "4",
        "moved": false,
        "static": false
      },
      {
        "w": 1,
        "h": 2,
        "x": 11,
        "y": 0,
        "i": "5",
        "moved": false,
        "static": false
      },
      {
        "w": 2,
        "h": 2,
        "x": 0,
        "y": 7,
        "i": "6",
        "moved": false,
        "static": false
      },
      {
        "w": 12,
        "h": 14,
        "x": 0,
        "y": 7,
        "i": "7",
        "moved": false,
        "static": false
      },
      {
        "w": 1,
        "h": 3,
        "x": 10,
        "y": 2,
        "i": "8",
        "moved": false,
        "static": false
      }
    ]
  });

  const snackBar = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      variant: variant
    });
  }

  const fetchQuery = useCallback(async () => {
    let response = await QueryStore.FindOne({
      id: queryId
    })
    setQuery(response.query)
  }, [queryId])

  const fetchQueryArgs = useCallback(async () => {
    let response = await ArgumentStore.FindAll({
      queryId: queryId
    })
    setQueryArguments(response.queryArguments)
  }, [queryId])

  const executeOne = useCallback(async () => {
    setIsLoading(true)
    let widgetResponse = await WidgetStore.FindOne({id: widgetId})

    if (widgetResponse && widgetResponse.widget) {
      let response = await Executor.ExecuteOne({
        queryId: queryId,
        connectorId: widgetResponse.widget.arguments.executorArgs.connectorId,
        templates: JSON.parse(queryArguments[selectedIndex].argument)
      })
      if (typeof response == "string") {
        setExecutedResults(response)
      }
      if (!response.data) {
        setExecutedResults(response.Errors)
      } else {
        setExecutedResults(response.data)
      }
    } else {
      enqueueSnackbar("Connector Not Found!", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: "error"
      });
    }

    setIsLoading(false)
  }, [queryId, queryArguments, selectedIndex])

  const saveArgs = useCallback(async () => {
    let response = await ArgumentStore.UpdateOne({
      id: queryArguments[selectedIndex].id,
      name: queryArguments[selectedIndex].name,
      queryId: queryArguments[selectedIndex].queryId,
      argument: JSON.parse(queryArguments[selectedIndex].argument)
    })
    snackBar('Query Args Saved', 'info')
  }, [queryId, queryArguments])


  const saveQuery = useCallback(async () => {
    let response = await QueryStore.UpdateOne({
      id: query.id,
      name: query.name,
      templatedQuery: query.templatedQuery,
    })
    snackBar('Query Saved', 'info')
  }, [queryId, query])

  const addArg = useCallback(async () => {
    let newNum: number


    if (queryArguments.length > 0) {
      newNum = queryArguments.length+1
      /*            const lastName = queryArguments.slice(-1)[0].name
                  if (lastName) {
                      const name = lastName.replace("arg", "")
                      if (name) {
                          const lastInt = parseInt(name)
                          newNum = lastInt + 1
                      }
                  }*/
    } else {
      newNum = 1
    }
    await ArgumentStore.CreateOne({
      name: `arg${newNum.toString()}`,
      queryId: queryId,
      argument: {},
    })
    await fetchQueryArgs()
    snackBar('Query Arg Created', 'info')
  }, [queryId, queryArguments])

  const delArg = useCallback(async () => {
    let response = await ArgumentStore.DeleteOne({
      query_arg_id: queryArguments[selectedIndex].id,
    })
    await fetchQueryArgs()
    snackBar('QueryArg Deleted :)', 'info')
  }, [queryId, queryArguments])

  useEffect(() => {
    Promise.resolve()
      .then(() => setIsLoading(true))
      .then(() => fetchQuery())
      .then(() => fetchQueryArgs())
      .then(() => setIsLoading(false))
  }, [queryId])

  const [draggable, setDraggable] = React.useState(false);
  const handleChange = (event) => {
    setDraggable(event.target.checked);
  };

  const setQueryFunc = (q: string) => {
    setQuery(prevState => ({
      ...prevState,
      templatedQuery: q
    }));
  }

  const setQueryName = (name: string) => {
    setQuery(prevState => ({
      ...prevState,
      name: name
    }));
  }

  const setQueryArgumentsFunc = (a: string) => {
    let arg = [...queryArguments]
    arg[selectedIndex] = new Argument({
      id: queryArguments[selectedIndex].id,
      name: queryArguments[selectedIndex].name,
      queryId: queryArguments[selectedIndex].queryId,
      argument: a
    })
    setQueryArguments(arg)
  }

  let formatData = (data) => {
    interface tableData {
      columns: any[],
      rows: any[]
    }
    let tb: tableData
    if (data == null|| data =="") {
      return tb = {columns: [], rows: []}
    }
    else {
      const cols = Object.keys(data).map((k) => {
        return {
          field: k,
          headerName: k,
          width: 200,
          // cellClassName: (params) =>
          //     clsx('super-app', {
          //         negative: params.value < 0,
          //         positive: params.value > 0,
          //     }),
        }
      })
      let rows = []
      try {
        _.range(data[Object.keys(data)[0]].length).map((idx) => {
          let row = {}
          row['id'] = idx
          Object.keys(data).map((k) => {
            row[k] = data[k][idx]
          })
          rows.push(row)
        })
        tb = {
          columns: cols,
          rows: rows
        }
        return tb
      }
      catch(err) {
        tb = {
          columns: [],
          rows: []
        };
        return tb
      }

    }}

  const uneditable = (string) => {}

  const handleChangeTabs = (event, newValue) => {
    setSelectedIndex(newValue)
  }

  const handleChangeTextField = (event) => {
    setQueryName(event.target.value)
  }
  const queryArgNameChange = (event) => {
    queryArguments[selectedIndex].name = event.target.value


  }
  return (
    <>
      {didCatch ? (
        <Box>
          <p>An error has been caught: {error.message}</p>
          <Button onClick={reset} variant="contained">Reset</Button>
        </Box>
      ) : (
        <ErrorBoundary>
          <ResponsiveGridLayout className="layout"
                                layouts={layout}
                                rowHeight={15}
                                isDraggable={draggable}
                                onLayoutChange={(_, layouts) => {
                                  /*console.log("Layouts: ", layouts)*/
                                  // saveToLS("layouts", layouts)
                                }}
                                isResizable={true}
                                compactType={null}
                                breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
            <div key={"0"}>
              <Typography variant="h5" component="div" gutterBottom>
                {query.id}
              </Typography>
            </div>
            <div key={"1"}>
              <Box>
                <TextField id="outlined-basic" label={query.name} style={{height: "100%", width: "100%"}} onChange={handleChangeTextField} variant="outlined" />
              </Box>
            </div>
            <div key={"2"}>
              <Button onClick={saveQuery} sx={{ flexGrow: 1, display: 'flex', height: "90%", width: "100%" }} variant="contained">Save</Button>
            </div>
            <div key={"3"}>
              <Typography> QUERY TEMPLATE:</Typography>
              { query.templatedQuery != null &&
                <AceCodeEditor code={query.templatedQuery} setCode={setQueryFunc} language={'sql'}/>
              }
            </div>
            <div key={"4"}>
              <Typography> QUERY ARGUMENTS:</Typography>
              { queryArguments &&
                <Box
                  sx={{ flexGrow: 1, display: 'flex', height: "100%", maxWidth: "100%" }}
                >
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={ selectedIndex }
                    onChange={ handleChangeTabs }
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider', width: "20%" }}
                  >
                    { queryArguments.map((a, i) => <Tab key={i}  label= {<TextField style= {{height: "1%", width: "80%"}} id="outlined-basic" label={a.name} variant="outlined" onChange={queryArgNameChange}/>} {...a11yProps(i)}/>) }

                  </Tabs>
                  <Box sx={{ flexGrow: 1,display: 'flex', height: "100%", maxWidth: "100%" }}
                  >
                    {queryArguments[selectedIndex] && queryArguments[selectedIndex].hasOwnProperty('argument') &&
                      <AceCodeEditor code={queryArguments[selectedIndex].argument} setCode={setQueryArgumentsFunc} language={'json'}/>
                    }
                    <Box sx={{height: "100%", maxWidth: "100%" }}>
                      <Button sx={{height: "20%", width: "100%", marginTop: "15%"}} onClick={saveArgs} variant="contained">Save</Button>
                      <Button sx={{height: "20%", width: "100%", marginTop: "5%" }} onClick={addArg} variant="contained">New Arg</Button>
                      <Button sx={{height: "20%", width: "100%", marginTop: "5%" }} onClick={delArg} variant="contained">Delete Arg</Button>
                    </Box>

                  </Box>
                </Box>
              }
            </div>
            <div key={"5"}>
              <Switch
                checked={draggable}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <div key={"6"}>
              { isLoading ?
                <CircularProgress /> :
                <Button sx={{height: "100%", width: "100%"}} onClick={executeOne} variant="contained">Execute</Button>
              }
            </div>
            <div key={"7"}>
              <Typography> RESPONSE:</Typography>
              <DataGrid columns={formatData(executedResults).columns} rows={formatData(executedResults).rows}/>
              <AceCodeEditor code={JSON.stringify(executedResults)} setCode={uneditable} language={'json'}/>
            </div>
          </ResponsiveGridLayout>
        </ErrorBoundary>
      )}


    </>
  );
}

export default QueryEditor;