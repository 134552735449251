import type { FC } from 'react';
import * as React from "react";

import {WidgetProps} from "../../../WidgetSwitcher";
import QuickStats from "../../../../../../../components/QuickStats";
import BasicWidget from "../BasicWidget";
import {Box, CircularProgress, Grid} from "@mui/material";
import {isArray} from "lodash";

const QuickStatsInst: FC<WidgetProps> = React.memo( ({widget, widgetData, widgetVariables}) => {
    const title = widget.arguments && widget.arguments.templates.title &&
        widget.arguments.templates.title.value

    let valueA: number = widget.arguments && widget.arguments.templates.valueA &&
        widget.arguments.templates.valueA.value
    let valueB: number = widget.arguments && widget.arguments.templates.valueB &&
        widget.arguments.templates.valueB.value
    let prefix: string = widget.arguments && widget.arguments.templates.prefix &&
        widget.arguments.templates.prefix.value
    let suffix: string = widget.arguments && widget.arguments.templates.suffix &&
        widget.arguments.templates.suffix.value


    if (widgetData && widget.arguments && widget.arguments.templates.valueA && widget.arguments.templates.valueB
        && widget.arguments.templates.valueA.keyOrIndex && widget.arguments.templates.valueB.keyOrIndex) {

        valueA = widgetData && widgetData[widget.arguments.templates.valueA.keyOrIndex] &&
            isArray(widgetData[widget.arguments.templates.valueA.keyOrIndex]) ?
            widgetData[widget.arguments.templates.valueA.keyOrIndex][0] :
            widgetData[widget.arguments.templates.valueA.keyOrIndex]

        valueB = widgetData && widgetData[widget.arguments.templates.valueB.keyOrIndex] &&
            isArray(widgetData[widget.arguments.templates.valueB.keyOrIndex]) ?
            widgetData[widget.arguments.templates.valueB.keyOrIndex][0] :
            widgetData[widget.arguments.templates.valueB.keyOrIndex]
    }

    let percent = valueA > 0 ? ((valueB - valueA) / valueA) * 100 : NaN



    //console.log(valueA, valueB, widget, widgetData)

    return (
        <div>
            <QuickStats
              title={title ? title : widget.name}
              value={valueB ? valueB : 0}
              prefix={prefix ? prefix: 'ZAR'}
              suffix = {suffix ? suffix: null}
              percentage={ percent }
            />
        </div>
    );
})

const BasicQuickStats: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<QuickStatsInst />}/>
};

export default BasicQuickStats;
