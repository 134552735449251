import React, { FC } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import ConnectGoalOpp from "./ConnectGoalOpp";
import { Card as KanbanCardObj } from "../../../../cog/keystone/card";
import { useKeystoneContext } from "../../../../contexts/KeystoneContext";

interface CreateOppsDialogProps {
  connectOpportunityOpen: boolean;
  handleConnectOppClose: () => void;
  opportunities: Record<string, KanbanCardObj>;
  selectedOpportunites: any[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CreateOppsDialog: FC<CreateOppsDialogProps> = (props) => {
  const keystoneCtx = useKeystoneContext()

  return (
    <Dialog
        //disableEnforceFocus required to open chat if Dialog opened first
        disableEnforceFocus
        onClose={() => {
        props.handleConnectOppClose()
      }}
      open={props.connectOpportunityOpen}
      maxWidth={"xl"}
    >
      <DialogTitle>
        <Box sx={{
          // fontSize: "small",
          display: 'flex',
          justifyContent: 'space-between',
          px: 1,
          alignItems: "center",
          // width: "100%",
          flexGrow: 10,
          // gap: "10px"
        }}>
          <span>Connect Opportunities</span>
          <Button
            // type="submit"
            // fullWidth
            variant="contained"
            // sx={{mt: 3, mb: 2}}
            onClick={() => {
              props.handleConnectOppClose()
            }}
          >
            Save and Close
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ConnectGoalOpp
          Opportunities={props.opportunities}
          SelectedOpps={props.selectedOpportunites}
          setFieldValue={props.setFieldValue}
          updateSelectedCards={keystoneCtx.setSelectedCardsID}
        />
      </DialogContent>
    </Dialog>
  );
}

export default CreateOppsDialog;