import type {FC} from 'react';
import React from 'react';
import logoPath from "./CloverLogo.svg"

interface CloverLogoProps extends LogoSwitcherProps{
    [key: string]: any;
}

interface LogoSwitcherProps {
    org?: string;
    icon?: boolean;
}

const CloverLogo: FC<CloverLogoProps> = (icon, props) => (
    <img
        alt="Clover Logo"
        src={logoPath}
        height={props.icon ? 100 : 66}
        width={props.icon ? 200 : 66}
        style={{
            margin: 1
        }}
        {...props}
    />
);
export default CloverLogo;