import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker, DateRange as MUIDateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import Box from '@mui/material/Box';
import {FC} from "react";
import {FilterItem} from "../../../../../cog/keystone/filter/Filter";
import {FilterItemProps} from "../Filter";
import {InputAdornment, Typography} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DateRangeIcon from '@mui/icons-material/DateRange';
import slice from "../../../../../slices/bitool";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
    customIcon: {
      "& .MuiSvgIcon-root": {
        color: useTheme().palette.mode === 'dark' ? "#f2bc53" : '#6b778c',
      },
    },
  }));

const DateRange: FC<FilterItemProps> = ({ name, header, filterData, filters, setFilters }) => {
    const classes = useStyles();

    const handleChange = (newValue: MUIDateRange<Date>) => {
        const idx = filters.findIndex(fil => fil.header === header)
        const dateStrings = (dates: Date[]): string[] => dates.map((d) => {if (d != null) return d.toISOString().substring(0, 10)})
        let newItem = {prefix: "AND", operation: "BETWEEN", values: [], header: header} as
          FilterItem

        let dateStart: Date
        let dateEnd: Date
        if (newValue[0] != null) {
          dateStart = new Date(newValue[0])
          const offset1 = dateStart.getTimezoneOffset()
          dateStart = new Date(dateStart.getTime() - (offset1*60*1000))
        } else {
          dateStart = null
        }
        if (newValue[1] != null) {
          dateEnd = new Date(newValue[1])
          const offset2 = dateEnd.getTimezoneOffset()
          dateEnd = new Date(dateEnd.getTime() - (offset2*60*1000))
        } else {
          dateEnd = null
        }
        if (dateStart != null && dateEnd != null) {
          newItem = {prefix: "AND", operation: "BETWEEN", values: dateStrings([dateStart, dateEnd]), header: header} as
            FilterItem
        }

        if (idx > -1) {
            setFilters([
                ...filters.slice(0, idx),
                newItem,
                ...filters.slice(idx + 1)
            ]);
            // setFilters([...filters].splice(idx, 1, newItem));
        } else {
            setFilters([...filters, newItem])
        }
    }

    const getValue = (): MUIDateRange<Date> => {
        const idx = filters.findIndex(fil => fil.header === header)
        if (idx > -1) return [filters[idx].values[0], filters[idx].values[1]]
        return [new Date(filterData[0]), new Date(filterData[1])]
    }


    return (
        <Box sx={{mb: 2}}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={{ start: 'Start Date', end: 'End Date' }}
            >
                <Typography variant="h6" gutterBottom>
                    { name }
                </Typography>
                <DateRangePicker
                    className={classes.customIcon}
                    value={getValue()}
                    onChange={(newValue) => {
                        handleChange(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <TextField 
                            className={classes.customIcon}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <DateRangeIcon 
                                        />
                                    </InputAdornment>
                                ),
                            }} {...startProps} />
                            <Box sx={{ m: 2 }}> <ArrowForwardIcon /> </Box>
                            <TextField 
                            className={classes.customIcon}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <DateRangeIcon />
                                    </InputAdornment>
                                ),
                            }} {...endProps} />
                        </React.Fragment>
                    )}
                />
            </LocalizationProvider>
        </Box>
    );
}

export default DateRange