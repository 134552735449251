import * as React from 'react';
import {gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector,} from '@mui/x-data-grid';
import {useDemoData} from '@mui/x-data-grid-generator';
import {styled, useTheme} from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {DataGridPro} from "@mui/x-data-grid-pro";

// function customCheckbox(theme: Theme) {
//     return {
//         '& .MuiCheckbox-root svg': {
//             width: 16,
//             height: 16,
//             backgroundColor: 'transparent',
//             border: `1px solid ${
//                 theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
//             }`,
//             borderRadius: 2,
//         },
//         '& .MuiCheckbox-root svg path': {
//             display: 'none',
//         },
//         '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
//             backgroundColor: '#1890ff',
//             borderColor: '#1890ff',
//         },
//         '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
//             position: 'absolute',
//             display: 'table',
//             border: '2px solid #fff',
//             borderTop: 0,
//             borderLeft: 0,
//             transform: 'rotate(45deg) translate(-50%,-50%)',
//             opacity: 1,
//             transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
//             content: '""',
//             top: '50%',
//             left: '39%',
//             width: 5.71428571,
//             height: 9.14285714,
//         },
//         '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
//             width: 8,
//             height: 8,
//             backgroundColor: '#1890ff',
//             transform: 'none',
//             top: '39%',
//             border: 0,
//         },
//     };
// }

const primaryColor = '#000000'
const secondaryColor = '#074682'
const primaryBGColor = '#074682'
const SecondaryBGColor = '#074682'
const lightThemeText = 'rgba(0,0,0,.85)'
const darkThemeText = 'rgba(255,255,255,0.65)'
const lightThemeBG = '#fafafa'
const darkThemeBG = '#1d1d1d'
const lightThemeBorder = `black`//'#f0f0f0'
const darkThemeBorder = '#303030'

export const StyledToolbar = (({
    panel: {
        sx: {
            // border: `1px solid red`,
            '& .MuiDataGrid-panelContent': {
                overflowX: "hidden",
                paddingRight: 2,
                "&::-webkit-scrollbar": {
                    width: 10
                },
                "&::-webkit-scrollbar-track": {
                    // backgroundColor: 'green'
                },
                "&::-webkit-scrollbar-thumb": {
                    // backgroundColor: 'red',
                    borderRadius: 2,
                }
            },
            '& .MuiTypography-root': {
                color: 'error',
                // fontSize: 20,
            },
            '& .MuiDataGrid-filterForm': {
                // bgcolor: 'lightblue',
            },
            // '& .MuiDataGrid-toolbarContainer': {
            //     bgcolor: 'red',
            //     height: '500px'
            // },

        }
    },
    toolbar: {
        printOptions: {
            hideFooter: true,
            hideToolbar: true,
        }
    },
    // container: {
    //     justifyItems: 'space between',
    //     // justifyContent: 'space between',
    //     // alignItems: 'center',
    //     // backgroundColor: theme.palette.background.default,
    //     display: "flex",
    //     flexContainer: true,
    //     flexFlow: 'row wrap',
    //     alignItems: 'center',
    //     // justifyContent: 'center',
    //     minHeight: '20rem',
    //     backgroundColor: '#666',
    // }
    // sx: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    // }
}))

export const StyledDataGrid = styled(DataGridPro)(({theme}) => ({
    width: '100%',
    paddingRight: 1,
    // border: 0,
    color: theme.palette.text.primary,
    // backgroundColor: theme.palette.background.paper,
    '& .MuiDataGrid-root': {
        color: theme.palette.text.primary,
        // backgroundColor: theme.palette.background.paper,
    },
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-toolbarContainer': {
        // class: "flex-container",
        // minWidth: '500px',
        maxWidth: '100vw',
        // display: 'flex',
        flexDirection: 'row',
        // flex: '1 1',
        // justifyItems: 'space-between',
        justifyContent: 'space-between',
        // alignItems: 'center',
        // backgroundColor: theme.palette.background.default,
        // display: "flex",
        // flexContainer: true,
        // flexFlow: 'row wrap',
        // alignItems: 'center',
        // justifyContent: 'center',
        // minHeight: '10rem',
        // backgroundColor: '#666',
        '& .MuiButton-text': {

            // display: 'flex',
            fontSize: '1rem',
            // color: theme.palette.warning.main,
        },
        '& .MuiButtonBase-root': {
            // backgroundColor:  theme.palette.background.default,

            // display: 'flex',
            color: theme.palette.info.main,
            padding: 2,
            // width: '30%',
            margin: 5,
            flex: 1
            // justifySelf: 'center'
        },
        '& .MuiButtonBase-root:hover': {
            backgroundColor:  theme.palette.background.default,
        },
        '& .MuiBox-root': {width: '100%', flex: 0}

    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${
            theme.palette.divider
        }`,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 400,
        fontSize: '1rem'
    },
    // '& .MuiDataGrid-iconSeparator': {
    //     display: 'none',
    // },

    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `0px solid ${
            theme.palette.background.default
        }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderTop: `1px solid ${theme.palette.grey}`,
    },
    '& .MuiDataGrid-cell': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: 5,
    },
    '& .MuiDataGrid-footerContainer': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
        borderRadius: 0,
    },
    '& .MuiDataGrid-detailPanelToggleCell': {
        // border: '0px solid !important',
        padding: 2,
        center: true,
        width: "100%",
        color: theme.palette.text.primary,
        fontSize: "2rem",
        // backgroundColor: theme.palette.background.default

    },
    '& .MuiDataGrid-detailPanelToggleCell:hover': {
        // border: '0px solid !important',
        // padding: 2,
        // center: true,
        // width: "100%",
        // color: theme.palette.text.primary,
        // fontSize: "2rem",
        backgroundColor: theme.palette.background.default

    },
    '& .MuiDataGrid-virtualScroller': {
        overflowX: "auto",
        paddingRight: 2,
        "&::-webkit-scrollbar": {
            width: 10,
            height: 10
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.background.default,
            borderRadius: 1,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.text.secondary,
            borderRadius: 2,
        }
    }
    // ...customCheckbox(theme),
}));

export function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            renderItem={(props2) => <PaginationItem {...props2} disableRipple/>}
            onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                apiRef.current.setPage(value - 1)
            }
        />
    );
}

export default function AntDesignGrid() {
    const {data} = useDemoData({
        dataSet: 'Commodity',
        rowLength: 10,
        maxColumns: 10,
    });
    return (
        <div style={{height: 400, width: '100%'}}>
            {/*@ts-ignore*/}
            <StyledDataGrid
                checkboxSelection
                pageSize={5}
                rowsPerPageOptions={[5]}
                components={{
                    Pagination: CustomPagination,
                }}
                {...data}
            />
        </div>
    );
}