import React, {FC, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";
import {GoalOpportunityStore} from "../../../../../../cog/keystone/goals/index";
import {useSnackbar} from "notistack";
import Goal from "../../../../../../cog/keystone/goals/goal/Goal";

interface ConfirmDialogOppsProps {
    handleDialogClose: () => void
    loadingMethod: (v: boolean) => void;
    admin: boolean;
    goalID: string
    clientName: string
    oppID: string
    setGoal: (goal: (prev) => Goal) => void;
    goal: Goal
    type?: string
}

const ConfirmDialogOpps: FC<ConfirmDialogOppsProps> = ({
                                                           handleDialogClose,
                                                           loadingMethod,
                                                           admin,
                                                           goalID,
                                                           clientName,
                                                           setGoal,
                                                           oppID,
                                                           goal,
                                                           type
                                                       }) => {
    const {enqueueSnackbar} = useSnackbar();
    const [isDisabled, setIsDisabled] = useState(false)

    const handleAddOpps = async () => {
        loadingMethod(true)
        setIsDisabled(true)

        if (admin) {
            const response = await GoalOpportunityStore.CreateMany({
                clientName: clientName,
                goalID: goalID,
                opportunityIDs: [oppID]
            })
            if (response) {
                setIsDisabled(false)
                enqueueSnackbar('Opp Added', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setGoal((prev) => {
                    return {...prev, ...goal} as Goal;
                });
            } else {
                setIsDisabled(false)
                enqueueSnackbar('Failed to Add Opp', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        } else {
            setIsDisabled(false)
            enqueueSnackbar('Cannot Add Opp, Admin Permissions Required.  Please Contact an Administrator.', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        loadingMethod(false)
        handleDialogClose()
    }

    const handleRemoveOpps = async () => {
        loadingMethod(true)
        setIsDisabled(true)

        if (admin) {
            const response = await GoalOpportunityStore.DeleteOne({
                clientName: clientName,
                goalID: goalID,
                opportunityID: oppID
            })
            if (response) {
                setIsDisabled(false)
                enqueueSnackbar('Opp Removed', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setGoal((prev) => {
                    return {...prev, ...goal} as Goal;
                });
            } else {
                setIsDisabled(false)
                enqueueSnackbar('Failed to Remove Opp', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        } else {
            setIsDisabled(false)
            enqueueSnackbar('Cannot Remove Opp, Admin Permissions Required.  Please Contact an Administrator.', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        loadingMethod(false)
        handleDialogClose()
    }

    return (
        <Box sx={{m: 2}}>
            <DialogTitle>{type + " Opportunity"}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridAutoFlow={"row"}
                    gridAutoRows={"auto"}
                    gap={1}
                >
                    <Box>Are you sure you want to {type} the Opportunity?</Box>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonGroup variant={"text"}>
                    <Button
                        onClick={type == "Add" ? handleAddOpps : handleRemoveOpps}
                        disabled={isDisabled}
                    >
                        {isDisabled ? <CircularProgress size={20}/> : "Confirm"}
                    </Button>
                    <Button
                        onClick={() => handleDialogClose()}
                        disabled={isDisabled}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Box>
    );
}

export default ConfirmDialogOpps;