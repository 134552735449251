/* eslint-disable */

import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from '../../pages/authentication/Login';
import { useMainContext } from '../../contexts/MainContext';
import {BiStore} from "../../cog/bitool";
import AuthorizationRequired from "./AuthorizationRequired";

interface BiGuardProps {
    children: ReactNode;
}

const BiGuard: FC<BiGuardProps> = (props) => {
    const { children } = props;
    const ctx = useMainContext();
    const location = useLocation();
    const [authorised, setAuthorised] = useState<boolean>(false)
    const checkAccess = async (): Promise<any> => {
        //TODO: This page uses multiple orgIds, should create new paramater in schema for broad access or do .map of each org in context to check access
        try {

            return (await BiStore.CheckAccess(
                //TODO: Get actual dashboard id to check editing perms, currently using casales dash
                {resource: {object_id: "e220f498-dd56-4455-a9ce-8ed46f52eb38", object_type: "dashboard"},
                    subject: {subject_id: ctx.user.id, subject_type: "user", optional_relation: ""},
                    permission_type: "edit"}))
        } catch (e) {
            console.error(`${e}`);
        }
    }

    checkAccess()
        .then((r)=> {
            console.log(r)
            if (r == true) {
                setAuthorised(true)
        } else {
            setAuthorised(false)}
        })

    if (authorised) {
        return <>{children}</>
    }
    else return <AuthorizationRequired/>
};

BiGuard.propTypes = {
    children: PropTypes.node
};

export default BiGuard;
