/* eslint-disable */

export interface Currency {
    symbol: string
    name: string
}
export default class User {
    public id?: string;
    public name: string = '';
    public avatar: string = '';
    public email?: string = '';
    public phone_number?: string = '';
    public currency?: Currency = {symbol: "", name: ""};

    constructor(user?: User) {
        if (!user) {
            return;
        }
        this.id = user.id
        this.name = user.name;
        this.avatar = user.avatar;
        this.email = user.email;
        this.phone_number = user.phone_number
    }
}
