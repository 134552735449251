/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import {FC} from "react";
import WidgetSwitcher, {WidgetProps} from "../../../WidgetSwitcher";
import {Box, Paper, Typography, Grid, GridSize} from "@mui/material";
import BasicWidget from "../BasicWidget";
// import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from "@mui/system";
import { makeStyles } from "@mui/styles";
import { grid } from "@mui/system";

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridAutoRows: '2fr',
    rowGap: '1em',
    columnGap: '1em'
  }
});

const WidgetGroup: FC<WidgetProps> = ({items, nextStep, widgetVariables, widget}) => {
    let url = window.location.pathname.toString()
    const theme = useTheme();
    const classes = useStyles(theme);
    // const matches = useMediaQuery(theme.breakpoints.up('sm'));

    // const code1 = widget.arguments.code ? unescape(widget.arguments.code) : null
    // const code = `
    //     parentProps = {
    //         height: "100%",
    //         width: '100%',
    //         display: 'grid',
    //         gridTemplateRows: 'repeat(2, 1fr)',
    //         gridTemplateColumns: 'repeat(2, 1fr)',
    //         gap: 1,
    //     }
    // `
    //
    // let parentProps = {}

    // try {
    //     eval(code);
    // } catch (e) {
    //     console.log("ChartError: ", e)
    //     return (
    //         <Grid item xs={8}>
    //             <Box sx={{minHeight: "100"}}>
    //                 <> An Error with Code Execution</>
    //             </Box>
    //         </Grid>
    //     )
    // }

    let numberOfItems = Object.keys(items).length

    return (
        <Grid
            // className={classes.container}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          wrap="nowrap"
          // columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
          style={{ height: "100%", maxWidth: "100%" }}
        >
            {items && Object.keys(items).map((k, index) => {
                let height = "" + 100 / numberOfItems + "%"
                // return items[k].widget && <WidgetSwitcher widget={items[k].widget} nextStep={nextStep}/>

                return items[k].widget &&
                    <Grid
                      item
                      key={index}
                      sx={{
                        minHeight: '33vh',
                        width: '100%',
                        maxWidth: '100%'
                      }}
                    >
                        <Typography
                            sx={{
                                width: '100%',
                                height: '40px',
                                fontSize: 20,
                                padding: '10px',
                            }}
                            color="text.primary"
                            gutterBottom
                        >
                            {items[k].label}
                        </Typography>
                        <WidgetSwitcher widget={items[k].widget} nextStep={nextStep}/>
                    </Grid>
            })}
        </Grid>

    )
}

const BasicWidgetGroup: FC<WidgetProps> = (props) => {
    return  <BasicWidget {...props} children={<WidgetGroup />}/>
}

export default BasicWidgetGroup
