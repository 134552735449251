import React, { FC, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useMainContext } from "../../../../contexts/MainContext";
import { OppLabelStore } from "src/cog/keystone/oppLabel";


interface DeleteOppLabelDialogProps {
  ID: string;
  handleDialogClose: () => void;
  onLabelDeleted: () => Promise<void>;
}

const DeleteOppLabelDialog: FC<DeleteOppLabelDialogProps> = (props) => {
  const mainCtx = useMainContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  // Function to delete a label
  const deleteOppLabel = async () => {
    setIsDisabled(true);

    try {
      const response = await OppLabelStore.DeleteOne({
        ClientName: mainCtx.activeOrganization.name,
        ID: props.ID,
      });

      if (response) {
        setIsDisabled(false);
        enqueueSnackbar("Label Deleted", {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "success",
        });
        props.onLabelDeleted();
      } else {
        setIsDisabled(false);
        enqueueSnackbar("Failed to Delete Label", {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting label:", error);
      setIsDisabled(false);
    }

    props.handleDialogClose();
  };


  return (
    <Box sx={{ m: 2 }}>
      <DialogTitle>Delete Label</DialogTitle>
      <DialogContent>
        <Grid container display="grid" gridTemplateColumns="1fr" gridAutoFlow="row" gridAutoRows="auto" gap={1}>
          <Box>Are you sure you want to delete this label?</Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant={"text"}>
          <Button onClick={deleteOppLabel} disabled={isDisabled}>
            {isDisabled ? <CircularProgress size={20} /> : "Confirm"}
          </Button>
          <Button onClick={() => props.handleDialogClose()}>Cancel</Button>
        </ButtonGroup>
      </DialogActions>
    </Box>
  );
};

export default DeleteOppLabelDialog;