import {Box, Grid, Grow, Skeleton, Typography} from "@mui/material";
import React from "react";

const HomeIssueSkeleton = () => {

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grow in={true}>
                        <Box sx={{
                            mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <Skeleton variant="rectangular" width={"100%"} height={230}
                                      sx={{padding: 5, borderRadius: "16px"}}/>
                        </Box>
                    </Grow>
                </Grid>
                <Grid item xs={12}>
                    <Grow in={true}>
                        <Box sx={{
                            mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <Skeleton variant="rectangular" width={"100%"} height={230}
                                      sx={{padding: 5, borderRadius: "16px"}}/>
                        </Box>
                    </Grow>
                </Grid>
                <Grid item xs={12}>
                    <Grow in={true}>
                        <Box sx={{
                            mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <Skeleton variant="rectangular" width={"100%"} height={230}
                                      sx={{padding: 5, borderRadius: "16px"}}/>
                        </Box>
                    </Grow>
                </Grid>
                <Grid item xs={12}>
                    <Grow in={true}>
                        <Box sx={{
                            mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <Skeleton variant="rectangular" width={"100%"} height={230}
                                      sx={{padding: 5, borderRadius: "16px"}}/>
                        </Box>
                    </Grow>
                </Grid>
            </Grid>
        </Box>
    )
}

export default HomeIssueSkeleton