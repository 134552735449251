import {DashboardState} from "./bitool";

const initialState: DashboardState = {
    dashboard: {
        initialized: false,

        reportMode: false,
        embedMode: false,
        dashboard: {id: "", name: "", organizationId: ""},

        widgets: {},

        widgetData: {},
        widgetColumns: {},
        // widgetOutputs: {},
        widgetTemplates: {},

        widgetVariables: {},

        widgetSelectedGroupMemberIndex: {},
        widgetSelectedGroupMemberMaxIndex: {},

        widgetIsLoading: {},
        widgetExecutorControllers: {},
    }
};


export default initialState
