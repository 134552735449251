import { API, PerformJSONRPCRequest } from "../../../../api/jsonrpc";

export interface CreateManyRequest {
  tags: string[];
  goalIDs: string[];
  clientName: string;
}

export interface CreateManyResponse {
  success: boolean;
}

const Store = {
  serviceProvider: 'Goal-Tags-Store',
  async CreateMany(request: CreateManyRequest): Promise<CreateManyResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CreateMany`,
      request: [{
        "tags": request.tags,
        "goalIDs": request.goalIDs,
        "clientName": request.clientName
      }]
    });
    return response
  },
};

export default Store;