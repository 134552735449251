import * as React from 'react';
import Box from '@mui/material/Box';
import {FC} from "react";
import Chart from 'react-apexcharts';
import {FilterItem} from "../../../../cog/keystone/filter/Filter";
import {ApexOptions} from "apexcharts";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {useNavigate} from "react-router-dom";
import {Filter as FilterObject, FilterStore} from "../../../../cog/keystone/filter";
import {useMainContext} from "../../../../contexts/MainContext";
import currencySwitcher from "../../../../utils/currencySwitcher";
import { Member } from "../../../../cog/keystone/member";
import { Alert } from '@mui/material';

interface ChartProps {
    data: object
    members?: Member[]
}



const PieChartStat: FC<ChartProps> = (props) => {
    
    const keystoneCtx = useKeystoneContext()
    const ctx = useMainContext()
    let formatter = Intl.NumberFormat('en', {notation: 'compact'})
    const navigate = useNavigate()

    const updateFilter = async (filters: FilterItem[]) => {
        const filter = new FilterObject(
            {userID: ctx.user.id, organizationID: ctx.activeOrganization.id, filters: filters}
        )
        let response = await FilterStore.UpsertOne({
            filter: filter,
        })
        if (response) {
            navigate("/app/" + ctx.activeOrganization.name.toLowerCase() + "/opportunities")
        }
    }

    const options: ApexOptions = {
            chart: {
                width: 500,
                height: 500,/*
                events: {
                    dataPointSelection(event, chartContext, config) {
                        const formatted = Object.keys(props.data)[config.dataPointIndex].replaceAll("_", " ")

                        const oppTypeFilter: FilterItem = {
                            header: "opportunity_type",
                            operation: "=",
                            prefix: "AND",
                            values: formatted
                        }

                        const filters: FilterItem[] = []
                        filters.push(oppTypeFilter)

                        const filteredFilter = filters.filter((f) => !f.level)

                        keystoneCtx.setFilters(filteredFilter)
                        updateFilter(filteredFilter)
                    }
                }*/
            },
            labels: (props.data) ? Object.keys(props.data) : [],
            tooltip: {
                y: {
                  formatter: function (val) {
                    return currencySwitcher(ctx.activeOrganization.name) + formatter.format(val)
                  }
               }
            },
            responsive: [{
                breakpoint: 400,
                options: {
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }

    return (
        (props.data) ?
            <Box sx={{
            marginLeft: {lg: '20%', md: '10%', sm: '0%', xs: '0px'},
            alignContent: 'center',
            justifyItems: 'center',
            alignItems: 'center',
            alignText: 'center',
            height: {lg: '90%', md: '90%', sm: '80%', xs: '70%'},
            width: {lg: '550px', md: '450px', xs: '320px'},
            margin: '0px',
            marginBottom: '20px',
            xs: {overflow: 'scroll'},
        }}>
            <Chart options={options} series={Object.values(props.data)} type="pie"/>
        </Box> : <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", marginBottom: '15px'}}>No Data Available for Selected Period</Alert>

    );

}

export default PieChartStat;

