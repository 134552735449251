import {useMainContext} from "../../../../../contexts/MainContext";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import React, {useEffect, useState} from "react";
import {ExploreDistributionStore, ExploreStoresStore} from "../../../../../cog/keystone/explore";
import ReactGA from "react-ga4";
import {Helmet} from "react-helmet-async";
import {
    Alert,
    Box,
    Card, CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    LinearProgress, ToggleButton,
    ToggleButtonGroup,
    Typography, useTheme
} from "@mui/material";
import FilterAsDrawer from "../../filter/FilterAsDrawer";
import HomeIssueSkeleton from "../../summary/Skeleton";
import {BubbleChart, TableChart} from "@mui/icons-material";
import ExploreStoreSwitchTable from "../stores/graphs/ExploreStoreSwitchTable";
import StoreColumn from "./graphs/StoreColumn";
import {Member} from "../../../../../cog/keystone/member";
import {
    ConvertTreeFromBeingUsedToApiSave,
    GenerateNewPsuTree
} from "../../productstoreuniverse/utility/NodeTypesAndOptions";

const ExploreStoresPage = () => {
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const firstName = mainCtx.user.name.split(/\s+/)
    const timeElapsed = Date.now();
    const now = new Date(timeElapsed);
    const curHr = now.getHours()

    const [storeStatData, setStoreStatData] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [categories, setCategories] = useState<string[]>([])

    const [tableMode1, setTableMode1] = React.useState<string | null>('graphic');
    const [tableMode2, setTableMode2] = React.useState<string | null>('graphic');
    const [tableMode3, setTableMode3] = React.useState<string | null>('graphic');
    const [tableMode4, setTableMode4] = React.useState<string | null>('graphic');

    const handleAlignment = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {

        setTableMode1(newMode);
    };
    const handleAlignment2 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {

        setTableMode2(newMode);
    };
    const handleAlignment3 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {

        setTableMode3(newMode);
    };
    const handleAlignment4 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {

            setTableMode4(newMode);
    }


    // Sets which options to display in the toolbar
    useEffect(() => {
        if (keystoneCtx.toolBarDisplayOptions.selectOpps != false ||
            keystoneCtx.toolBarDisplayOptions.filterToMe != false ||
            keystoneCtx.toolBarDisplayOptions.stats != false ||
            keystoneCtx.toolBarDisplayOptions.filter != true) {
            keystoneCtx.setToolBarDisplayOptions({filter: true, stats: false, selectOpps: false, filterToMe: false, landingDateSelector: true})

        }
    }, [keystoneCtx.toolBarDisplayOptions])

    let greetingMessage: string

    if (curHr < 12) {
        greetingMessage = "Good Morning "
    } else if (curHr < 18) {
        greetingMessage = "Good Afternoon "
    } else {
        greetingMessage = "Good Evening "
    }

    let limit = "20"
    if (window.innerWidth <= 800) {
        limit = "5"

    }

    const fetchStoreStatData = async () => {
        setLoading(true)


        const dateSelectionStart = keystoneCtx.dateValue[0].toISOString().slice(0, 10)
        const dateSelectionEnd = keystoneCtx.dateValue[1].toISOString().slice(0, 10)
        // const tempStartDate = "2021-10-02"
        // const tempEndDate = "2024-05-27"

        const response = await ExploreStoresStore.StoreStats(
            {
                clientName: mainCtx.activeOrganization.name,
                // dateSelectionStart: tempStartDate,
                dateSelectionStart: dateSelectionStart,
                // dateSelectionEnd: tempEndDate,
                dateSelectionEnd: dateSelectionEnd,
                defaultFilters: keystoneCtx.defaultFilters,
                filters: keystoneCtx.filters,
                limit:limit,
                PSU: keystoneCtx.activePSU != null
                    ? ConvertTreeFromBeingUsedToApiSave(keystoneCtx.activePSU)
                    : ConvertTreeFromBeingUsedToApiSave(GenerateNewPsuTree(mainCtx.activeOrganization.name))
            })
         if (response) {
            setCategories(Object.keys(response))
            setStoreStatData(response)
        }
        setLoading(false)
    }

    const getGraphUsers = () =>{
        let currentUsers : Member[]= []
        let tempMember :Member
        if(storeStatData['TopUsersStatusHistogram'] && mainCtx.members && mainCtx.members.length > 0){
            Object.values(storeStatData['TopUsersStatusHistogram']['Username']).forEach((userID:string)=>{
                let formatedMemberID = userID.replaceAll("\"", "").trim()
                tempMember = mainCtx.members.find((member)=> member.id == formatedMemberID)
                currentUsers.push(tempMember)
            })
        }
        // console.log(currentUsers)
        return currentUsers
    }

    const getHeader = (category:string) =>{
        if(category == "TopUsersStatusHistogram"){
            return "assigned_to"
        }
        return keystoneCtx.colMap.Area_Hierarchy_3
    }
    const getLabelValue = (category:string) =>{
        if(category == "TopUsersStatusHistogram"){
            return "Username"
        }
        return "Store"
    }

    const getTableTitle = (category:string) => {
        if(category.includes("Count")){
           return "Top " + limit + " Stores by number of Opportunities per Opportunity Status"

        }
        if(category.includes("User")){
            return "Top " + limit + " Users by m-Value per Opportunity Status"
        }

        if(category == 'TopStoresStatusHistogramValue'){
            return "Top " + limit + " Stores by m-Value per Opportunity Status"
        }

        return "Top " + limit + " Stores by m-Value"
    }


    // Keystone Context is already doing this
    // useEffect(() => {
    //     if (keystoneCtx.filtersReady == true) {
    //         keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
    //     }
    // }, [keystoneCtx.filtersReady])

    useEffect(() => {
        if (keystoneCtx.filtersReady == true && keystoneCtx.activePsuReady == true) {
            fetchStoreStatData()
        }
    }, [mainCtx.activeOrganization.name, keystoneCtx.dateValue, keystoneCtx.flipSwitchForCards])


    useEffect(() => {

        ReactGA.event("keystone_view" ,{
            userID: mainCtx.user.id,
            orgID: mainCtx.activeOrganization.id,
            pageName: 'Explore Stores Page'
        });
    }, [mainCtx.activeOrganization])

    return(
        <>
            <Helmet>
                <title>Explore Stores</title>
            </Helmet>
            <Box sx={{mt: 1}}>
                <Box
                    sx={{
                        flexGrow: 1,
                        py: 1
                    }}
                >
                    <Container maxWidth={false}>
                        <Box marginLeft={0}>
                            <FilterAsDrawer
                                setFilters={keystoneCtx.setFilters}
                                setLevelMode={keystoneCtx.setLevelMode}
                                filters={keystoneCtx.filters}
                                open={keystoneCtx.filterOpen}
                                setOpen={keystoneCtx.setFilterOpen}
                                onClose={keystoneCtx.handleFilterClose}
                                client={mainCtx.activeOrganization.name}
                                organizationId={mainCtx.activeOrganization.id}
                                userID={mainCtx.user.id}
                                onClear={keystoneCtx.handleFilterClear}
                            />
                        </Box>
                        {!loading  &&  keystoneCtx.filtersReady ?
                            <Box>
                                <Box sx={{ mb: 4 }}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item>
                                            <Typography variant="h4">{greetingMessage + firstName[0] + ", Here Is Your Store Summary"}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ marginTop: 3 }} />
                                </Box>
                                <Grid
                                    container
                                    spacing={4}
                                >
                                    <Grid item lg={12} xs={12}>
                                        <Card>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <CardHeader
                                                    title={'Stores With The Most Overall Opportunity Available (Value)'}
                                                />
                                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                    <ToggleButtonGroup
                                                        value={tableMode1}
                                                        exclusive
                                                        onChange={handleAlignment}
                                                        aria-label="text alignment"
                                                        sx={{ mr: 1 }}
                                                    >
                                                        <ToggleButton value="graphic"
                                                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                                                            <BubbleChart fontSize="small"/>
                                                        </ToggleButton>
                                                        <ToggleButton value="table"
                                                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                                                            <TableChart fontSize="small"/>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Box>
                                            </Box>
                                            <Divider />
                                            <CardContent>
                                                {storeStatData['TopStoresBar'] && Object.keys(storeStatData['TopStoresBar']).length > 0 ? (
                                                    tableMode1 === "graphic" ? (
                                                        <StoreColumn
                                                            key={storeStatData['TopStoresBar']+ "Key"}
                                                            data={storeStatData['TopStoresBar']}
                                                            labelValue={getLabelValue('TopStoresBar')}
                                                            header={getHeader('TopStoresBar')}
                                                            org={mainCtx.activeOrganization.name}
                                                            currentMembers={null}
                                                            category={'TopStoresBar'}
                                                            statusColours={false}
                                                        />
                                                    ) : (
                                                        <ExploreStoreSwitchTable
                                                            statRows={storeStatData['TopStoresBar']}
                                                            chartName = 'TopStoresBar'
                                                        />
                                                    )
                                                ) : (
                                                    <Alert
                                                        severity="warning"
                                                        sx={{
                                                            height: 165,
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            marginBottom: '15px',
                                                            backgroundColor: (theme) =>
                                                                theme.palette.mode === 'dark'
                                                                    ? useTheme().palette.background.default
                                                                    : "warning",
                                                        }}
                                                    >
                                                        No Data Available for Selected Period & Filters
                                                    </Alert>
                                                )}

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <Card>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <CardHeader
                                                    title={'Stores With The Most Opportunity Available (Quantity)'}
                                                />
                                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                    <ToggleButtonGroup
                                                        value={tableMode2}
                                                        exclusive
                                                        onChange={handleAlignment2}
                                                        aria-label="text alignment"
                                                        sx={{ mr: 1 }}
                                                    >
                                                        <ToggleButton value="graphic"
                                                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                                                            <BubbleChart fontSize="small"/>
                                                        </ToggleButton>
                                                        <ToggleButton value="table"
                                                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                                                            <TableChart fontSize="small"/>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Box>
                                            </Box>
                                            <Divider />
                                            <CardContent>
                                                {storeStatData['TopStoresStatusHistogramCount'] && Object.keys(storeStatData['TopStoresStatusHistogramCount']).length > 0 ? (
                                                    tableMode2 === "graphic" ? (
                                                        <StoreColumn
                                                            key={storeStatData['TopStoresStatusHistogramCount']+ "Key"}
                                                            data={storeStatData['TopStoresStatusHistogramCount']}
                                                            labelValue={getLabelValue('TopStoresStatusHistogramCount')}
                                                            header={getHeader('TopStoresStatusHistogramCount')}
                                                            org={mainCtx.activeOrganization.name}
                                                            currentMembers={null}
                                                            category={'TopStoresStatusHistogramCount'}
                                                            statusColours={true}
                                                        />
                                                    ) : (
                                                        <ExploreStoreSwitchTable
                                                            statRows={storeStatData['TopStoresStatusHistogramCount']}
                                                            chartName = 'TopStoresStatusHistogramCount'
                                                        />
                                                    )
                                                ) : (
                                                    <Alert
                                                        severity="warning"
                                                        sx={{
                                                            height: 165,
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            marginBottom: '15px',
                                                            backgroundColor: (theme) =>
                                                                theme.palette.mode === 'dark'
                                                                    ? useTheme().palette.background.default
                                                                    : "warning",
                                                        }}
                                                    >
                                                        No Data Available for Selected Period & Filters
                                                    </Alert>
                                                )}

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <Card>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <CardHeader
                                                    title={'Stores With The Most Opportunity Available (Value)'}
                                                />
                                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                    <ToggleButtonGroup
                                                        value={tableMode3}
                                                        exclusive
                                                        onChange={handleAlignment3}
                                                        aria-label="text alignment"
                                                        sx={{ mr: 1 }}
                                                    >
                                                        <ToggleButton value="graphic"
                                                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                                                            <BubbleChart fontSize="small"/>
                                                        </ToggleButton>
                                                        <ToggleButton value="table"
                                                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                                                            <TableChart fontSize="small"/>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Box>
                                            </Box>
                                            <Divider />
                                            <CardContent>
                                                {storeStatData['TopStoresStatusHistogramValue'] && Object.keys(storeStatData['TopStoresStatusHistogramValue']).length > 0 ? (
                                                    tableMode3 === "graphic" ? (
                                                        <StoreColumn
                                                            key={storeStatData['TopStoresStatusHistogramValue']+ "Key"}
                                                            data={storeStatData['TopStoresStatusHistogramValue']}
                                                            labelValue={getLabelValue('TopStoresStatusHistogramValue')}
                                                            header={getHeader('TopStoresStatusHistogramValue')}
                                                            org={mainCtx.activeOrganization.name}
                                                            currentMembers={null}
                                                            category={'TopStoresStatusHistogramValue'}
                                                            statusColours={true}
                                                        />
                                                    ) : (
                                                        <ExploreStoreSwitchTable
                                                            statRows={storeStatData['TopStoresStatusHistogramValue']}
                                                            chartName = 'TopStoresStatusHistogramValue'
                                                        />
                                                    )
                                                ) : (
                                                    <Alert
                                                        severity="warning"
                                                        sx={{
                                                            height: 165,
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            marginBottom: '15px',
                                                            backgroundColor: (theme) =>
                                                                theme.palette.mode === 'dark'
                                                                    ? useTheme().palette.background.default
                                                                    : "warning",
                                                        }}
                                                    >
                                                        No Data Available for Selected Period & Filters
                                                    </Alert>
                                                )}

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <Card>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <CardHeader
                                                    title={'Users With The Most Opportunities (Value)'}
                                                />
                                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                    <ToggleButtonGroup
                                                        value={tableMode4}
                                                        exclusive
                                                        onChange={handleAlignment4}
                                                        aria-label="text alignment"
                                                        sx={{ mr: 1 }}
                                                    >
                                                        <ToggleButton value="graphic"
                                                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                                                            <BubbleChart fontSize="small"/>
                                                        </ToggleButton>
                                                        <ToggleButton value="table"
                                                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                                                            <TableChart fontSize="small"/>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Box>
                                            </Box>
                                            <Divider />
                                            <CardContent>
                                                {storeStatData['TopUsersStatusHistogram'] && Object.keys(storeStatData['TopUsersStatusHistogram']).length > 0 ? (
                                                    tableMode4=== "graphic" ? (
                                                        <StoreColumn
                                                            key={storeStatData['TopUsersStatusHistogram']+ "Key"}
                                                            data={storeStatData['TopUsersStatusHistogram']}
                                                            labelValue={getLabelValue('TopUsersStatusHistogram')}
                                                            header={getHeader('TopUsersStatusHistogram')}
                                                            org={mainCtx.activeOrganization.name}
                                                            currentMembers={getGraphUsers()}
                                                            category={'TopUsersStatusHistogram'}
                                                            statusColours={true}
                                                        />
                                                    ) : (
                                                        <ExploreStoreSwitchTable
                                                            statRows={storeStatData['TopUsersStatusHistogram']}
                                                            chartName = 'TopUsersStatusHistogram'
                                                            currentMembers={getGraphUsers()}
                                                        />
                                                    )
                                                ) : (
                                                    <Alert
                                                        severity="warning"
                                                        sx={{
                                                            height: 165,
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            marginBottom: '15px',
                                                            backgroundColor: (theme) =>
                                                                theme.palette.mode === 'dark'
                                                                    ? useTheme().palette.background.default
                                                                    : "warning",
                                                        }}
                                                    >
                                                        No Data Available for Selected Period & Filters
                                                    </Alert>
                                                )}

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    )
                                </Grid>
                            </Box>
                            :
                            <Box>
                                <LinearProgress
                                    sx={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                        marginTop: 2,
                                        justifySelf: "center"
                                    }} />
                                <HomeIssueSkeleton />
                            </Box>
                        }
                    </Container>
                </Box>
            </Box>
        </>
    )
}

export default ExploreStoresPage