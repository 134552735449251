import type {FC} from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import {Draggable} from 'react-beautiful-dnd';
import {Box, Chip, Divider, Paper, Typography} from '@mui/material';
import KanbanItemList from "./KanbanItemList";
import abbrNum from "../../../../../utils/abbreviateNumbers";
import {KanbanColumnObj} from "../../../../../cog/keystone/card";
import {Member} from "../../../../../cog/keystone/member";
import currencySwitcher from "../../../../../utils/currencySwitcher";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";

interface KanbanColumnProps {
    columnId: string;
    org: string;
    column: KanbanColumnObj;
    index: number;
    members: Member[]
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: string, vName: string) => void
    allLabels:OppLabel[]
}

const KanbanColumn: FC<KanbanColumnProps> = React.memo((props) => {
    const currencySymbol = currencySwitcher(props.org)

    // think this was used for disabling the columns when a card is archived, doesn't seem like it is used though
    const disabled = (columnText: string) => {
        if (columnText == 'QA' || columnText == 'Archive') {
            return true
        } else {
            return false
        }
    }

    const ColumnMvalue = () => {
        if (props.column.cards) {
            let count = 0
            for (let cardsKey in props.column.cards) {
                count += Number(props.column.cards[cardsKey].mValue)
            }
            return Math.round(count * 100) / 100
        } else {
            return 0
        }
    }

    return (
        <Draggable draggableId={props.columnId}
                   index={props.index}
            // isDragDisabled={disabled(props.column.text)
                   isDragDisabled={props.selectCards == false ? disabled(props.column.text) : true}
        >
            {provided => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: '80vh',
                            px: 0,
                            overflowX: 'hidden',
                            overflowY: 'hidden',
                            width: {
                                xs: 300,
                                sm: 360
                            }
                        }}
                    >
                        <Box
                            py={1}
                            px={2}
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                        >
                            <Typography
                                color="inherit"
                                variant="h6"
                                display="flex"
                                justifyContent={"space-between"}
                                width="100%"
                                {...provided.dragHandleProps}
                            >
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "baseline"
                                }}><Chip label={props.column.cards ? props.column.cards.length : 0} variant="outlined"
                                         size="small"/> {props.column.text} <Chip
                                    label={currencySymbol + " " + abbrNum(ColumnMvalue(), 0)} variant="outlined"
                                    size="small"/></div>
                            </Typography>
                            <Box flexGrow={1}/>
                        </Box>
                        <Divider/>
                        <KanbanItemList org={props.org} columnId={props.columnId} column={props.column}
                                        index={props.index} members={props.members} selectCards={props.selectCards}
                                        selectedCardsIDs={props.selectedCardsIDs}
                                        updateSelectedCards={props.updateSelectedCards}
                                        allLabels={props.allLabels}/>
                    </Paper>
                </div>)}
        </Draggable>
    );
});

KanbanColumn.propTypes = {
    column: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired
};

export default KanbanColumn;
