import type {FC} from 'react';
import * as React from "react";
import {useContext} from "react";
import Chart from 'react-apexcharts';
import {Box} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {ApexOptions} from "apexcharts";
import currencySwitcher from "../../../../utils/currencySwitcher";
import {useMainContext} from "../../../../contexts/MainContext";
import SettingsContext from "../../../../contexts/SettingsContext";

interface ColumnProps {
    data: any[]
    categories: any[]
    xLabel: string
}

const ColumnBar: FC<ColumnProps> = ({data, categories, xLabel}) => {
    const theme = useTheme();
    const chartType = "bar";
    const ctx = useMainContext()
    const settingsCtx = useContext(SettingsContext)
    
    
    //TODO: Not entirely happy with the data manipulation here as well as the decisions made to render views, esp on tooltip
    function zipArrays<T, U>(array1: T[], array2: U[]): { x: T; y: U }[] {
        const zippedArray: { x: T; y: U }[] = [];
        
        const minLength = Math.min(array1.length, array2.length);
        
        for (let i = 0; i < minLength; i++) {
            zippedArray.push({ x: array1[i], y: array2[i] });
        }
        
        return zippedArray;
    }
    const chartOptions: ApexOptions = {
        chart: {
            type: "bar",
            stacked: false,
            background: useTheme().palette.background.paper,
            foreColor: useTheme().palette.text.primary,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        series: [{
            data: zipArrays(categories, data)
        }],
        colors: [ "#f45c5c","#3aa9a6", "#4b4b4b",  "#f2bc53", "#e1d5c6", "#8c9494"],
        xaxis: {
            //type: 'category',
            tickAmount: 'dataPoints',
            labels: {
                rotateAlways: true,
                rotate: -60,
                trim: true,
            },
            tickPlacement: 'on',
            categories: categories,
            title: {
                text: xLabel,
            }},
        yaxis: {
            title: {
                text: "VALUE IN " + currencySwitcher(ctx.activeOrganization.name),
            },
        },
        fill: {
            opacity: 1
            
        },
        grid: {
            padding: {
                bottom: 20,
                left: 80,
            }
        },
        tooltip: {
            theme: settingsCtx.settings.theme === 'LIGHT' ? 'light' : 'dark',
            onDatasetHover: {
                highlightDataSeries: false,
            },
            y: {
                title: {
                    formatter: (seriesName) => "",
                },
            }
        },
    };
    
    return (
        <Box sx={{ minHeight: 500, minWidth: 300 }}>
            <Chart
                type={chartType}
                options={chartOptions}
                height={"90%"}
                width={"90%"}
                series={chartOptions.series}
            />
        </Box>
    );
}

export default ColumnBar;