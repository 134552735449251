import {FC, Fragment} from "react";
import * as React from "react";
import {useDispatch} from "react-redux";

import {
    Box, Button, CircularProgress, Grid, Typography, Stepper, Step, StepButton, StepContent, StepLabel, Tabs, Tab
} from "@mui/material";

import WidgetSwitcher, {WidgetProps} from "../../../WidgetSwitcher";
import slice from "../../../../../../../slices/bitool";
import BasicWidget from "../BasicWidget";



const WidgetGroupStepperInst: FC<WidgetProps> = ({widget,
                                                     items ,
                                                     index,
                                                     maxIndex,
                                                     maxItemsIndex}) => {
    let dispatch = useDispatch()

    const handleChange = (event, newValue: number) => {
        dispatch(slice.actions.widgetChangeSelectedGroupMemberIndex({
            id: widget.id,
            index: newValue
        }))
    };

    const nextStepInst = (id: string, index: number, maxIndex: number, maxItemsIndex: number) => {
        let newMax = index + 1
        newMax = newMax > maxItemsIndex ? maxItemsIndex : newMax

        dispatch(slice.actions.widgetChangeSelectedGroupMemberIndex({
            id: id,
            index: index + 1
        }))
        dispatch(slice.actions.widgetChangeSelectedGroupMemberMaxIndex({
            id: id,
            index: newMax
        }))
    }

    const nextStep = () => {
        console.log("triggered")
        nextStepInst(widget.id, index, maxIndex, maxItemsIndex)
    }
    console.log("items: ", items, index, maxIndex)

    if (!items || index == undefined || maxIndex == undefined) {
        return (
            <Grid item xs={8}>
                <Box sx={{minHeight: "100"}}>
                    <CircularProgress />
                </Box>
            </Grid>
        )
    }
    console.log("StepperNextStep: ", nextStep)
    console.log("index", index, maxIndex)

    return (
        <Box
            sx={{
                bgcolor: 'background.default',
                border: 2,
                p: 2,
                borderRadius: 1,
                borderColor: 'primary.main',
                height: "100%",
                width: "100%",
            }}
        >
            <Typography sx={{ fontSize: 21 }} color="text.secondary" gutterBottom>
                { widget.name }
            </Typography>
            {Object.keys(items).map((k, idx) => (
                <Box
                    sx={{
                        p: 2,
                    }}
                >
                    <WidgetSwitcher
                        widget={items[k].widget}
                    />
                </Box>
            ))}
        </Box>
    )
}

const BasicWidgetGroupStepper: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<WidgetGroupStepperInst />}/>
}

export default BasicWidgetGroupStepper;



