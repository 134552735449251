import {FC, useEffect, useLayoutEffect, useState} from "react";
import {GridColDef} from "@mui/x-data-grid";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {useMainContext} from "../../../../../contexts/MainContext";
import currencySwitcher from "../../../../../utils/currencySwitcher";
import Stack from "@mui/material/Stack";
import Loader from "../../kanban/util/Loader";
import Paper from "@mui/material/Paper";
import {Typography} from "@mui/material";
import {StyledDataGrid} from "../../listview/CustomDataGridTheme";
import * as React from "react";
import UserStatsPage from "./UserReportingView";
import {Card as KanbanCardObj} from "../../../../../cog/keystone/card";
import {Member} from "../../../../../cog/keystone/member";

interface UserStatsTableDetailProps {
    myRows: any[]
    myCol: GridColDef[]
}

const UserStatsTableDetail : FC<UserStatsTableDetailProps> = (props) =>  {
    // For loading things
    const [loading, setLoading] = useState<boolean>(true);
    // Contexts
    const keystoneCtx = useKeystoneContext()
    const mainCtx = useMainContext();
    // For currency displaying
    const currencySymbol = currencySwitcher(mainCtx.activeOrganization.name)
    console.log("Props: ", props)

    useEffect(() => {
        if (props.myCol != null && loading == true) {
            setLoading(false)
        }
    }, [props.myCol])

    return (
        <Stack
            sx={{p: 2, height: 'fit-content', boxSizing: 'border-box'}}
            direction="column"
        >
            {loading ? <Loader/>
                :
                <Paper sx={{flex: 1, mx: 'auto', width: '90%', p: 1}}>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color="textPrimary">
                            Additional Client Information
                        </Typography>
                        <StyledDataGrid
                            density="compact"
                            columns={props.myCol}
                            rows={props.myRows}
                            sx={{flex: 1}}
                            autoHeight={true}
                            hideFooter
                            getEstimatedRowHeight={() => 300}
                        />
                    </Stack>
                </Paper>
            }
        </Stack>
    );
}


export default UserStatsTableDetail;