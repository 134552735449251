import {API, PerformJSONRPCRequest} from '../../../api/jsonrpc';
import Goal, {
    BurnDownGoalResult,
    CreateGoal,
    CreateGoalCriteria,
    CreateGoalTag,
    GoalStatsData,
    GoalTargetDetails,
    UpdateGoal
} from "./Goal";
import { GoalCriteria } from "./goalTarget/SVTarget";

export interface FindAllRequest {
    clientName: string;
}

export interface FindAllResponse {
    goals: Goal[];
}

export interface FindManyRequest {
    campaignID: string;
    clientName: string;
}

export interface FindManyResponse {
    goals: Goal[];
}

export interface FindUnattachedRequest {
    clientName: string;
}

export interface FindUnattachedResponse {
    goals: Goal[];
}

export interface GoalStatsRequest {
    clientName: string;
    goalID: string;
    goalCriteria: GoalCriteria[];
}

export interface GoalStatsResponse {
    goalStats: Record<string, GoalStatsData>;
}

export interface FindOneRequest {
    id: string;
    clientName: string;
}

export interface FindOneResponse {
    goal: Goal;
}

export interface UpdateOneRequest {
    goal: UpdateGoal;
    clientName: string;
}

export interface UpdateOneResponse {
    success: boolean;
}

export interface DeleteOneRequest {
    id: string;
    clientName: string;
}

export interface DeleteOneResponse {
    success: boolean;
}

export interface DeleteManyRequest {
    IDs: string[];
    clientName: string;
}

export interface DeleteManyResponse {
    success: boolean;
}

export interface CreateOneRequest {
    goal: CreateGoal;
    goalCriteria: CreateGoalCriteria[];
    goalTags: CreateGoalTag[];
    opportunityIDs: string[];
    userIDs: string[];
    targetDetails: GoalTargetDetails;
    clientName: string;
}

export interface CreateOneResponse {
    id: string;
}

function convertToGoalInterface(input: any) {
    const headers = Object.keys(input);
    const values = Object.values(input);
    const result = headers.map((header, index) => ({
        header,
        values: values[index],
    }));
    return {result};
}

export interface FindGoalsAndOppsByUserIDRequest {
    userID: string
    clientName: string
}

export interface FindGoalsAndOppsByUserIDResponse {
    goals: Goal[]
}

export interface FetchBurnDownGoalsRequest {
    goalID: string;
    clientName: string;
    goalType: string;
    startDate: string;
    dueDate: string;
    criteriaFilters: any;
    productHeader: string;
}

export interface FetchBurnDownGoalsResponse {
    burnDownResults: BurnDownGoalResult[]
}

export interface CalculateGoalValueRequest {
    goalID: string;
    clientName: string;
}

export interface CalculateGoalValueResponse {
    value: number;
}

const Store = {
    serviceProvider: 'Goal-Store',
    async FindAll(request: FindAllRequest): Promise<FindAllResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAll`,
            request: [{
                "clientName": request.clientName
            }]
        });
        if (response && response.goals) {
            return {
                goals: response.goals.map((mem) => {
                    let temp = new Goal()
                    temp = {...mem}
                    return temp
                })
            };
        } else {
            return {goals: []}
        }
    },
    async FindMany(request: FindManyRequest): Promise<FindManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindMany`,
            request: [{
                "campaignID": request.campaignID,
                "clientName": request.clientName,
            }]
        });
        if (response && response.goals) {
            return {
                goals: response.goals.map((mem) => {
                    let temp = new Goal()
                    temp = {...mem}
                    return temp
                })
            };
        } else {
            return {goals: []}
        }

    },
    async FindUnattached(request: FindUnattachedRequest): Promise<FindUnattachedResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindUnattached`,
            request: [{
                "clientName": request.clientName
            }]
        });
        if (response.goals && response.goals.length > 0) {
            return {
                goals: response.goals.map((mem) => {
                    let temp = new Goal()
                    temp = {...mem}
                    return temp
                })
            }
        } else {
            return {
                goals: [],
            };
        }
        // return {
        //     goals: response.goals.map((mem) => {
        //         let temp = new Goal()
        //         temp = {...mem}
        //         return temp
        //     })
        // };
    },
    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                "id": request.id,
                "clientName": request.clientName
            }]
        });
        return {
            goal: new Goal(response.goal),
        };
    },
    async GoalStats(request: GoalStatsRequest): Promise<GoalStatsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.GoalStats`,
            request: [{
                "clientName": request.clientName,
                "goalID": request.goalID,
                "goalCriteria": convertToGoalInterface(request.goalCriteria).result,
            }]
        });
        return response.goalStats
    },
    async UpdateOne(request: UpdateOneRequest): Promise<UpdateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateOne`,
            request: [{
                "goal": {
                    "id": request.goal.id,
                    "name": request.goal.name,
                    "type": request.goal.type,
                    "owner": request.goal.owner,
                    "description": request.goal.description,
                    "priority": request.goal.priority,
                    "dueDate": request.goal.dueDate,
                    "status": request.goal.status
                },
                "clientName": request.clientName
            }]
        });
        return response
    },
    async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOne`,
            request: [{
                "id": request.id,
                "clientName": request.clientName
            }]
        });
        return response
    },
    async DeleteMany(request: DeleteManyRequest): Promise<DeleteManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteMany`,
            request: [{
                "IDs": request.IDs,
                "clientName": request.clientName
            }]
        });
        return response
    },
    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                "goal": request.goal,
                "goalCriteria": request.goalCriteria,
                "goalTags": request.goalTags,
                "opportunityIDs": request.opportunityIDs,
                "userIDs": request.userIDs,
                "goalTargetDetails": request.targetDetails,
                "clientName": request.clientName
            }]
        });
        return response
    },
    async FindGoalsAndOppsByUserID(request: FindGoalsAndOppsByUserIDRequest): Promise<FindGoalsAndOppsByUserIDResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindGoalsAndOppsByUserID`,
            request: [{
                "clientName": request.clientName,
                "userID": request.userID
            }]
        });
        return response
    },
    async FetchBurnDownGoals(request: FetchBurnDownGoalsRequest): Promise<FetchBurnDownGoalsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FetchBurnDownGoals`,
            request: [{
                "clientName": request.clientName,
                "goalID": request.goalID,
                "goalType" : request.goalType,
                "startDate" : request.startDate,
                "dueDate" : request.dueDate,
                "criteriaFilters": request.criteriaFilters,
                "productHeader" : request.productHeader
            }]
        });
        return response
    },
    async CalculateGoalValue(request: CalculateGoalValueRequest): Promise<CalculateGoalValueResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CalculateGoalValue`,
            request: [{
                "goalID": request.goalID,
                "clientName": request.clientName
            }]
        })
        return response
    }
};

export default Store;