import React, {FC, useState} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress
} from "@mui/material";
import { GoalStore } from "../../../../cog/keystone/goals";
import { useSnackbar } from "notistack";
import { useKeystoneContext } from "../../../../contexts/KeystoneContext";
import { useMainContext } from "../../../../contexts/MainContext";

interface DeleteMultipleGoalsDialogProps {
  goalIDs: string[];
  handleDialogClose: () => void
}

const DeleteMultipleGoalsDialog: FC<DeleteMultipleGoalsDialogProps> = ({goalIDs, handleDialogClose}) => {
  const {enqueueSnackbar} = useSnackbar();
  const keystoneCtx = useKeystoneContext();
  const mainCtx = useMainContext();

  const [isDisabled, setIsDisabled] = useState(false)

  const deleteGoals = async () => {
    setIsDisabled(true)
    const response = await GoalStore.DeleteMany({IDs: goalIDs, clientName: mainCtx.activeOrganization.name})
    if (response) {
      setIsDisabled(false)
      enqueueSnackbar('Goals Deleted', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
    } else {
      setIsDisabled(false)
      enqueueSnackbar('Failed to Delete Goals', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
    handleDialogClose();
    keystoneCtx.setFlipSwitchForGoals(!keystoneCtx.flipSwitchForGoals)
  }

  return (
    <Box sx={{ m: 2 }}>
      <DialogTitle>Delete Goals</DialogTitle>
      <DialogContent>
        <Grid container display={"grid"} gridTemplateColumns={"1fr"}
              gridAutoFlow={"row"} gridAutoRows={"auto"} gap={1} >
          <Box>Are you sure you want to delete these goals?</Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant={"text"} >
          <Button
            onClick={deleteGoals}
            disabled={isDisabled}
          >
            {isDisabled ? <CircularProgress size={20}/> : "Confirm"}
          </Button>

          <Button
            onClick={() => handleDialogClose()}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Box>
  );
}

export default DeleteMultipleGoalsDialog;