/* eslint-disable */
import React, {useEffect, FC, useCallback} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    TextField
} from "@mui/material";
import WidgetStore from "../../../../../cog/bitool/widget/Store";
import { WidgetClonerStore } from "../../../../../cog/bitool/widget";
import {useNavigate} from "react-router-dom";
import Widget from "../../../../../cog/bitool/widget/Widget";
import {useParams} from "react-router";
import Tab from "@mui/material/Tab";
import OldWidgetEditor from "./OldWidgetEditor";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {initBitool, widgetUpdate} from "../../../../../slices/bitool";
import {useDispatch} from "react-redux";
import {useSnackbar, VariantType} from "notistack";
import {ErrorBoundary} from "use-error-boundary/lib/ErrorBoundary";
import {useErrorBoundary} from "use-error-boundary";

interface WidgetChooserProps {}

const WidgetChooser: FC<WidgetChooserProps> = () => {
    let { dashId} = useParams();
    if (!dashId) {
        return <div> No ID found </div>
    }
    let widgetTemplates = require('../../../../../slices/WidgetTemplates.json')
    const { ErrorBoundary, didCatch, error, reset } = useErrorBoundary()
    const [selectedWidgetIndex, setSelectedWidgetIndex] = React.useState(0);
    const {enqueueSnackbar} = useSnackbar();
    let dispatch = useDispatch()
    let navigate = useNavigate()
    const [widgets, setWidgets] = React.useState<Widget[]>([]);
    const [newID, setNewID] = React.useState<string>("");

    const fetchAllWidgets = useCallback(async () => {
        let response = await WidgetStore.FindAll({dashboardId: dashId})
        setWidgets(response.widgets)
    }, [])

    const snackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
            },
            variant: variant
        });
    }
    //TODO: CREATE ONE FUNCTION FOR CREATING WIDGETS WITH GENERIC INPUT
    const createOneWidget = useCallback(async () => {
        let response = await WidgetStore.CreateOne({
            widget: {
                name: "a widget",
                dashboardId: dashId,
                arguments: {type: "atype"},
            }
        })
        if (response) {
            await fetchAllWidgets()
            setNewID(response.id)

            //handleTabChange(null, widgets[response.id].indexOf)
        }

    }, [])

    const cloneWidget = useCallback(async (wid: Widget) => {
        let response = await WidgetClonerStore.CloneOne({widgetId: wid.id, targetDashboardId: dashId})

        if (response) {
            snackBar('Widget Cloned!', 'success')
            await fetchAllWidgets()
            setSelectedWidgetIndex(0)
            setNewID(response.widgetId)
        } else {
            snackBar('Failed to Clone Widget!', 'error')
        }
    }, [widgets])

    const findIndexOf = (ID) => {
        let idx = widgets.findIndex((w) => w.id == ID)
        if (idx == -1) {
            idx = 0
        }
        handleTabChange(null, idx)
    }

    const handleTabChange = (event, newValue) => {
        const newValueInt = parseInt(newValue)
        setSelectedWidgetIndex(newValueInt);
    };

    const setWid = (w: Widget, id: string) => {
        const newWidgetsIdx = widgets.findIndex((d) => d.id == id)
        setWidgets([
            ...widgets.slice(0, newWidgetsIdx),
            w,
            ...widgets.slice(newWidgetsIdx + 1)
        ])
    }

    useEffect(() => {
        fetchAllWidgets()
    }, [fetchAllWidgets])

    useEffect(() => {
        if (newID) {
        findIndexOf(newID) }
    }, [newID])

    useEffect(() => {
        dispatch(initBitool(dashId))
    }, [dashId])

    const onAutocompleteChange = (event, value) => {
        if (value == null || undefined || "") {return}
        let val
        try {val = value.split(" | ")[0]}
        catch (err){ val = value}

        const idx = widgets.findIndex((w) => w.name == val)
        setSelectedWidgetIndex(idx)
    }
    const deleteWidget = useCallback(async (wid) => {
        let response = await WidgetStore.DeleteOne({
            id: wid.id
        })
        if (response.deleted) {
            await fetchAllWidgets()
            setNewID(widgets[0].id)
            snackBar('Widget Deleted', 'info')
            if (widgets) {
                //TODO: DISGUSTING WAY, DO BETTER
                try {
                    setNewID(widgets[0].id)
                }
                catch {(setNewID(widgets[1].id))}

            }
        }
    }, [widgets])

    const saveAll = async (wid: Widget, code: string, widArg: string) => {
        let newWidget: Widget = new Widget(wid)
        newWidget.arguments = JSON.parse(widArg)
        newWidget.arguments.code = code
        let response = await WidgetStore.UpdateOne({
            widget: newWidget
        })
        if (response.id) {
            await dispatch(widgetUpdate(newWidget.id, newWidget))
            const newWidgetsIdx = widgets.findIndex((d) => d.id == newWidget.id)
            if (newWidgetsIdx) {
                setWidgets([
                    ...widgets.slice(0, newWidgetsIdx),
                    newWidget,
                    ...widgets.slice(newWidgetsIdx + 1)
                ])
            }
            snackBar('Widget Saved', 'info')
            await fetchAllWidgets()
        }

    }

    const openQuery = (queryId) => {
        //TODO: DO THIS CORRECTLY FOR MULTIPLE URLS
        window.open("https://main-cog-frontend.web.app/app/bi/queryedit/" + queryId)

    }

    const handleReturn = () => {
        navigate("/app/bi/dashchoose/")
    }
    return (
        <div style={{height: "100%", width: "100%"}}>
            <Box>
                <Button onClick={handleReturn}>Back to Dashboards</Button>
            <Autocomplete
                sx={{height: "100%", margin: "5px"}}
                disablePortal
                id="combo-box-demo"
                options={ widgets.map((w) => w.name + " | "+ w.id)}
                onChange={onAutocompleteChange}
                renderInput={(params) => <TextField {...params} label="Search Widgets" />}
            />
            </Box>
            <Box>
                <Button variant="text" onClick={() => createOneWidget()} sx={{height: "100%", margin: "5px"}} >
                    Create New Widget
                </Button>
{/*                <Button variant="text" onClick={() => null} sx={{height: "100%", margin: "5px"}} >
                    All Widgets
                </Button>*/}

            </Box>
            <TabContext key={selectedWidgetIndex} value={selectedWidgetIndex.toString()}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange}
                             variant="scrollable"
                             scrollButtons
                             allowScrollButtonsMobile
                             aria-label="scrollable force tabs example"
                    >
                        { widgets.map((w, i) => <Tab key = {w.id} label={w.name} value={i.toString()}/>) }
                    </TabList>


                </Box>
                { widgets.map((w, i) => <TabPanel value={i.toString()} key={w.id}>
                    <>
                        {didCatch ? (
                            <Box>
                                <p>An error has been caught: {error.message}</p>
                            </Box>
                        ) : (

                            <ErrorBoundary>{!(w.arguments.executorArgs == undefined) ? (
                                <Button onClick={() =>openQuery(w.arguments.executorArgs.queryId)}>
                                    Open Query in new Tab
                                </Button>
                            ): null}</ErrorBoundary>
                        )}
                    </>
                    <Button onClick={() => {deleteWidget(w)} }>Delete Widget</Button>
                    <Button onClick={() => {cloneWidget(w)} }>Clone Widget</Button>

                    <OldWidgetEditor widget={w} OnWidSave={saveAll} allWidgets={widgets} setWidget={setWid}/>





                    </TabPanel>
                )}
            </TabContext>
        </div>
    );
};


export default WidgetChooser;

