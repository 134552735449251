import * as Yup from 'yup';
import {Formik} from 'formik';
import type {FC} from 'react';
import {useSnackbar} from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';
import { ReportStore } from "../../../../../cog/bitool/report";

interface NewReportFormProps {
  dashId: string;
  widgetVariables: Record<string, any>;
  handleDialogClose: () => void;
}

const NewReportForm: FC<NewReportFormProps> = ({dashId, widgetVariables, handleDialogClose}) => {
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Card>
            <Formik
                enableReinitialize
                initialValues={{
                    name: 'New Report',
                    dashboardId: dashId || '',
                    cronString: '0 10 * * *',
                    emailList: '',
                    submit: null
                }}
                validationSchema={
                    Yup
                        .object()
                        .shape({
                            name: Yup.string().max(255).required('Name is required'),
                            dashboardId: Yup.string(),
                            cronString: Yup.string(),
                            emailList: Yup.string()
                    })
                }
                onSubmit={async (values, {
                    resetForm,
                    setErrors,
                    setStatus,
                    setSubmitting
                }): Promise<void> => {
                    try {
                        const response = ReportStore.CreateOne({name: values.name, dashboardId: values.dashboardId, variableData: widgetVariables, cronString: values.cronString, emailList: values.emailList});
                        if (response) {
                            setStatus({success: true});
                            enqueueSnackbar('Report Created', {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'success'
                            });
                        } else {
                            setStatus({success: false});
                            enqueueSnackbar('Failed to Create Report!', {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                        resetForm();
                        setSubmitting(false);
                        handleDialogClose();
                    } catch (err) {
                        console.error(err);
                        setStatus({success: false});
                        setErrors({submit: err.message});
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }): JSX.Element => (
                       <form onSubmit={handleSubmit}>
                            <Card>
                                <CardHeader title="Create Report"/>
                                <Divider/>
                                <CardContent>
                                    <Grid
                                      container
                                      spacing={4}
                                    >
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                error={Boolean(touched.name && errors.name)}
                                                fullWidth
                                                helperText={touched.name && errors.name}
                                                label="Name"
                                                name="name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                          item
                                          md={6}
                                          xs={12}
                                        >
                                            <TextField
                                                error={Boolean(touched.dashboardId && errors.dashboardId)}
                                                fullWidth
                                                helperText={touched.dashboardId && errors.dashboardId}
                                                label="Dashboard ID"
                                                name="dashboardId"
                                                onBlur={handleBlur}
                                                type="dashboardId"
                                                value={values.dashboardId}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                error={Boolean(touched.cronString && errors.cronString)}
                                                fullWidth
                                                helperText={touched.cronString && errors.cronString}
                                                label="Cron String"
                                                name="cronString"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.cronString}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                error={Boolean(touched.emailList && errors.emailList)}
                                                fullWidth
                                                helperText={touched.emailList && errors.emailList}
                                                label="Email List"
                                                name="emailList"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.emailList}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{mt: 3}}>
                                            <FormHelperText error>
                                                {errors.submit}
                                            </FormHelperText>
                                        </Box>
                                    )}
                                </CardContent>
                                <Divider/>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        p: 2
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Card>
                       </form>
                )}
            </Formik>
        </Card>
    );
};

export default NewReportForm;