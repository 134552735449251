import { Box, CircularProgress, Skeleton } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import {GoalStore} from "../../../../../cog/keystone/goals";
import Goal, {BurnDownGoalResult} from "../../../../../cog/keystone/goals/goal/Goal";
import Chart from "react-apexcharts";
import {useMainContext} from "../../../../../contexts/MainContext";
import { useKeystoneContext } from "../../../../../contexts/KeystoneContext";
import { useTheme } from "@mui/material/styles";
import SettingsContext from "../../../../../contexts/SettingsContext";

interface HomeBurnDownChartComponentProps {
    goal: Goal
    minimalist: boolean
    size: number
}

const HomeBurnDownChartComponent: FC<HomeBurnDownChartComponentProps> = (props) => {
    const mainCtx = useMainContext()
    const keystoneContext = useKeystoneContext()
    const settingsCtx = useContext(SettingsContext)
    const theme = useTheme()
    const [burnDownResults, setBurnDownResults] = useState<BurnDownGoalResult[]>(null);
    const [idealBurndownData, setIdealBurndownData] = useState<any[]>(null);
    const [burnDownOptions, setBurnDownOptions] = useState<any>(null)

    const criteriaSerializer = (source: any) => {
        let sourceMap = source ? JSON.parse(source) : null;
        let ar = [];
        if (sourceMap) {
            ar = Object.keys(sourceMap).map((key) => {
                return {
                    header: key,
                    values: sourceMap[key]
                };
            });
        }
        return ar;
    };

    // fetchBurnDown this one is for NPD/NPS and Static goals
    const fetchBurnDown = async (): Promise<any> => {
        try {
            return await GoalStore.FetchBurnDownGoals({
                clientName: mainCtx.activeOrganization.name,
                criteriaFilters: criteriaSerializer(props.goal.criteria),
                dueDate: props.goal.dueDate,
                goalID: props.goal.id,
                goalType: props.goal.type,
                startDate: props.goal.startDate,
                productHeader: keystoneContext?.colMap["Product"] ?? keystoneContext.colMap["Product"]
            })

        } catch (e) {
            console.error(`${e}`);
        }
    }

    function calculateIdealBurndown(startDate, endDate, startingValue: number) {
        const daysTotal = ((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1
        const dailyRate = (startingValue / daysTotal)
        let startTime = new Date(startDate).getTime()
        let idealBurndownData = [];
        let currentValue = startingValue;

        for (let i = 0; i < daysTotal; i++) {
            const innerArr = [startTime, Math.round(currentValue)]
            idealBurndownData.push(innerArr);
            currentValue -= dailyRate;
            startTime += 86400000;
        }

        // console.log("Stats for Goal", props.goal.name, ": ", "Total Days: ", daysTotal, "Daily Rate: ", dailyRate, "Starting Value: ", startingValue, "Ideal Burndown Data: ", idealBurndownData)
        // console.log("Times for Goal", props.goal.name, ": ", "Starting Time: ", new Date(props.goal.startDate).getTime(), "Ending Time: ", new Date(props.goal.dueDate).getTime(),)
        return idealBurndownData;
    }

    useEffect(() => {
        Promise.resolve().then(() => {
            fetchBurnDown().then(r => {
                setBurnDownResults(r["burnDownResults"])
                if (r["burnDownResults"] == null)
                {
                    setBurnDownResults([{"day": (new Date()).toString(), value: 0}])
                }
            })
        })
    }, []);

    useEffect(() => {
        if (burnDownResults != null) {
            var tempIdealBurndownData = calculateIdealBurndown(new Date(props.goal.startDate), new Date(props.goal.dueDate), burnDownResults[0].value)
            setIdealBurndownData(tempIdealBurndownData)
        }
    }, [burnDownResults]);

    useEffect(() => {
        if (idealBurndownData != null) {
            var options = {
                series: [{
                    name: 'Achieved',
                    type: 'area',
                    data: burnDownResults.map((d) => {
                        return [new Date(d.day).getTime(), d.value]
                    }),
                }, {
                    name: 'Ideal',
                    type: 'area',
                    data: idealBurndownData
                }],
                options: {
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: !props.minimalist
                    },
                    chart: {
                        height: props.size,
                        type: 'line',
                        background: theme.palette.background.paper,
                        foreColor: theme.palette.text.primary,
                        toolbar: {
                            show: !props.minimalist
                        },
                        zoom: {
                            enabled: false
                        },
                    },
                    stroke: {
                        curve: 'smooth',
                        // dashArray: [0, 5]
                    },
                    fill: {
                        type: ['gradient', 'gradient'],
                        // opacity: [0.35, 0.35],
                        gradient: {
                            opacityFrom: 0.5,
                            opacityTo: 0.2,
                            stops: [0, 100],
                            type: 'vertical'
                        },
                    },
                    labels: burnDownResults.map((d) => {
                        return d.day
                    }),
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'datetime',
                        min: new Date(props.goal.startDate).getTime(),
                        max: new Date(props.goal.dueDate).getTime(),
                        labels: {
                            show: !props.minimalist,
                            format: 'dd-MM-yyyy',
                        },
                        axisBorder: {
                            show: !props.minimalist
                        },
                        axisTicks: {
                            show: !props.minimalist
                        },
                    },
                    yaxis: {
                        labels: {
                            show: !props.minimalist,
                        },
                        title: {
                            text: !props.minimalist ?'Burn Down Value' : "",
                        },
                        axisBorder: {
                            show: !props.minimalist
                        },
                        axisTicks: {
                            show: !props.minimalist
                        },
                    },
                    grid: {
                        show: !props.minimalist,
                        padding: {
                            bottom: 0,
                            // left: 0,
                            // right: 0,
                            top: 0
                        }
                    },
                    // yaxis: [
                    //     {
                    //         title: {
                    //             text: 'Actionable Opportunities',
                    //         },
                    //     },
                    //     // {
                    //     //     opposite: true,
                    //     //     title: {
                    //     //         text: 'Series B',
                    //     //     },
                    //     // },
                    // ],
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== "undefined") {
                                    return y.toFixed(0) + "";
                                }
                                return y;
                            }
                        },
                        theme: settingsCtx.settings.theme === 'LIGHT' ? 'light' : 'dark'
                    }
                },


            };
            setBurnDownOptions(options)
        }
    }, [idealBurndownData, theme]);

    return <Box onClick={(e) => {
        e.stopPropagation()
    }}>
        {burnDownResults != null && idealBurndownData != null && burnDownOptions != null
            ? <Box>
                <Chart
                    options={burnDownOptions.options}
                    series={burnDownOptions.series}
                    type="line"
                    height={props.size}/>
            </Box>
            : <Skeleton sx={{ width: "28vw", height: "20vh" }} />}
    </Box>
}

export default HomeBurnDownChartComponent;