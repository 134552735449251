import {FC} from "react";
import * as React from "react";
import {useDispatch} from "react-redux";
import {
    Box,
    CircularProgress,
    Grid,
    Typography,
    Stepper,
    Step,
    StepButton,
} from "@mui/material";
import WidgetSwitcher, {WidgetProps} from "../../../WidgetSwitcher";
import slice from "../../../../../../../slices/bitool";
import BasicWidget from "../BasicWidget";

const WidgetGroupStepperInst: FC<WidgetProps> = ({
                                                     widget,
                                                     items,
                                                     index,
                                                     maxIndex,
                                                     maxItemsIndex
                                                 }) => {
    let dispatch = useDispatch();

    const handleChange = (event, newValue: number) => {
        dispatch(slice.actions.widgetChangeSelectedGroupMemberIndex({
            id: widget.id,
            index: newValue
        }))
    };

    const nextStepInst = (id: string, index: number, maxIndex: number, maxItemsIndex: number) => {
        let newMax = index + 1
        newMax = newMax > maxItemsIndex ? maxItemsIndex : newMax

        dispatch(slice.actions.widgetChangeSelectedGroupMemberIndex({
            id: id,
            index: index + 1
        }))
        dispatch(slice.actions.widgetChangeSelectedGroupMemberMaxIndex({
            id: id,
            index: newMax
        }))
    }

    const nextStep = () => {
        console.log("triggered")
        nextStepInst(widget.id, index, maxIndex, maxItemsIndex)
    }
    console.log("items: ", items, index, maxIndex)

    if (!items || index == undefined || maxIndex == undefined) {
        return (
            <Grid item xs={8}>
                <Box sx={{minHeight: "100"}}>
                    <CircularProgress/>
                </Box>
            </Grid>
        )
    }
    console.log("StepperNextStep: ", nextStep)
    console.log("index", index, maxIndex)

    return (
        <Box
            sx={{
                height: '100%',
                bgcolor: 'background.default',
                border: 2,
                p: 2,
                borderRadius: 1,
                borderColor: 'primary.main',
                display: 'flex',
                flexDirection: 'column',
                // display: 'grid',
                // gridTemplateRows: 'repeat(100, 1fr)'
            }}
        >
            <Typography sx={{width: '100%', height: '40px', fontSize: 21}} color="text.secondary" gutterBottom>
                {widget.name}
            </Typography>

            <Stepper nonLinear alternativeLabel activeStep={index} sx={{width: '100%', height: '80px'}}>
                {Object.keys(items).map((k, idx) => (
                    <Step disabled={!!(idx && (idx > maxIndex))} key={idx}>
                        <StepButton style={{wordBreak: "break-all"}} color="inherit"
                                    onClick={() => handleChange(null, idx)}>
                            {items[k].label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{width: '100%', flexGrow: 1}}>
                <WidgetSwitcher
                    widget={items[Object.keys(items)[index]].widget}
                    nextStep={nextStep}
                />
            </Box>
        </Box>
    )
}

const BasicWidgetGroupStepper: FC<WidgetProps> = ({widget}) => {
    return <BasicWidget widget={widget} children={<WidgetGroupStepperInst/>}/>
}

export default BasicWidgetGroupStepper;



