import React, {FC, useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import {GoalTargetStore} from "../../../../../../cog/keystone/goals";
import ProgressBar from "../../ProgressBar";
import {useMainContext} from "../../../../../../contexts/MainContext";
import {useKeystoneContext} from "../../../../../../contexts/KeystoneContext";
import moment from "moment/moment";

interface TargetComponentProps {
    value: any;
    row?: any;
    source?: string
}

const TargetComponent: FC<TargetComponentProps> = (props: TargetComponentProps) => {
        const {row, source, value} = props;
        const [targetLoading, setTargetLoading] = React.useState<boolean>(true);
        const [targetResponse, setTargetResponse] = React.useState<any>(null);
        const ctx = useMainContext()
        const keystoneContext = useKeystoneContext()

        const criteriaSerializer = (source: any) => {
            let sourceMap = source ? JSON.parse(source) : null;
            let ar = [];
            if (sourceMap) {
                ar = Object.keys(sourceMap).map((key) => {
                    return {
                        header: key,
                        values: sourceMap[key]
                    };
                });
            }
            return ar;
        };
        useEffect(() => {
            let isMounted = true;
            if (row?.row?.type !== 'SV' && row?.row?.type !== 'NPD') {
                GoalTargetStore.CalculateStaticProgress({
                    clientName: ctx.activeOrganization.name,
                    goalID: row?.row?.id ?? row.id
                }).then((r) => {
                    if (isMounted) {
                        setTargetResponse(r.progress && r.progress.progress ? r.progress.progress : 0)
                        setTargetLoading(false)
                    }
                })
            }

            if (!source && (row?.row?.type || row.type) == 'SV') {

                GoalTargetStore.CalculateSVProgress({
                    clientName: ctx.activeOrganization.name,
                    criteriaFilters: criteriaSerializer(row?.row?.criteria || row.criteria),
                    dueDate: moment(row?.row?.dueDate ?? row.dueDate).format('YYYY-MM-DD'),
                    startDate: moment(row?.row?.startDate ?? row.startDate).format('YYYY-MM-DD'),
                    target: row?.row?.target ?? row.target,
                    type: row?.row?.type ?? row.type,
                }).then((r) => {
                    if (isMounted) {
                        setTargetResponse(r.progress && r.progress.progress ? r.progress.progress : 0)
                        setTargetLoading(false)
                    }
                })
            }
            if (!source && (row?.row?.type || row.type) == 'NPD') {
                GoalTargetStore.CalculateNPDProgress({
                    clientName: ctx.activeOrganization.name,
                    criteriaFilters: criteriaSerializer(row?.row?.criteria || row.criteria),
                  productHeader: keystoneContext?.colMap["Product"] ?? keystoneContext.colMap["Product"],
                    goalID: row?.row?.id ?? row.id
                }).then((r) => {
                    if (isMounted) {
                        setTargetResponse(r.progress && r.progress.progress ? r.progress.progress : 0)
                        setTargetLoading(false)
                    }
                })
            }
          if (!source && (row?.row?.type || row.type) == 'NPS') {
            GoalTargetStore.CalculateNPSProgress({
              clientName: ctx.activeOrganization.name,
              criteriaFilters: criteriaSerializer(row?.row?.criteria || row.criteria),
              productHeader: keystoneContext?.colMap["Product"] ?? keystoneContext.colMap["Product"],
              goalID: row?.row?.id ?? row.id
            }).then((r) => {
              if (isMounted) {
                setTargetResponse(r.progress && r.progress.progress ? r.progress.progress : 0)
                setTargetLoading(false)
              }
            })
          }

            return () => {
                isMounted = false;
            };
        }, []);

        return (
            <Box sx={{
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                position: 'relative',
                alignItems: 'center'
            }}>
                <Box>

                </Box>
                {targetLoading ? (<Skeleton sx={{ width: "35vw" }} />
                ) : (
                    <ProgressBar value={targetResponse}/>
                )}
            </Box>
        )
    }
;

export default TargetComponent