import React, { FC, useLayoutEffect } from "react";
import { Avatar, Box, Card, CardContent, CardHeader, Chip, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { useMainContext } from "../../../../../../contexts/MainContext";
import { GridColDef } from "@mui/x-data-grid";
import { CheckCircleOutline, ListAlt } from "@mui/icons-material";
import currencyFormatter from "../../../../../../utils/currencyFormatter";
import _ from "lodash";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Member, MemberStore } from "../../../../../../cog/keystone/member";

interface HistoryDataGridProps {
  historyData: {};
}

const HistoryDataGrid: FC<HistoryDataGridProps> = ({historyData}) => {
  const mainCtx = useMainContext()
  let columns: GridColDef[] = []
  let rows = []

  const processUserForDisplay = (id) => {
    if (mainCtx.members != null) {
      return mainCtx.members.find((obj) => {
        return obj.id === id;
      })
    }
  };

  if (Object.keys(historyData).length === 0) {
    columns = [{"field": "NoData", "headerName": "NO DATA TO DISPLAY", "width": 400}]
  } else {
    columns = [
      { field: 'Timestamp', headerName: 'Date', minWidth: 200, flex: 1},
      { field: 'Action_Type', headerName: 'Action', minWidth: 200, flex: 1},
      { field: 'user_id', headerName: 'User', minWidth: 200, flex: 1, renderCell: (params) => {
        let user
        if (params.value != null && params.value != "") {
          user = processUserForDisplay(params.value)
        }
          return <Box sx={{display: 'flex', flexDirection: 'row'}}>
            {params.value != null && params.value != "" && user && user.displayName && user.avatar ? <Tooltip title={user.displayName} key={user.id}><Avatar src={user.avatar}/></Tooltip> : null}
          </Box>}},
    ]
  }

  if (Object.keys(historyData).length === 0) {
    rows = [{id: 0, NoData: "Query returned no results"}]
  } else {
    _.range(historyData[Object.keys(historyData)[0]].length).map((idx) => {
      let row = {}
      row['id'] = idx + 1
      Object.keys(historyData).map((k) => {
        row[k] = historyData[k][idx]
      })
      rows.push(row)
    })
  }

  return (<Box sx={{ height: 500, width: '100%' }}>
    <DataGridPro
      columns={columns}
      rows={rows}
      pageSize={5}
      rowsPerPageOptions={[5]}
      pagination={true}
      components={{
        Toolbar: GridToolbar
      }}
    />
  </Box>)
}

export default HistoryDataGrid;