/* eslint-disable */

import config from 'react-global-configuration';
import {getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app";

export enum API {
    Public,
    Authorised
}

interface JSONRPCRequestProps {
    api: API;
    method: string;
    request: any;
    verbose?: boolean;
    controller?: AbortController;
}

const controller = new AbortController();
const signal = controller.signal;

export async function PerformJSONRPCRequest({api, method, request, verbose, controller}: JSONRPCRequestProps) {
    const header = new Headers({
        'Content-Type': 'application/json'
    });
    const app = initializeApp(config.get('firebase'));
    const auth = getAuth(app);

    let apiURL = '';
    let jwt: string | null = null;
    let apiToken: string | null = null;

    switch (api) {
        case API.Authorised:
            jwt = await auth.currentUser.getIdToken()
            if (!jwt || jwt === 'null' || jwt === '' ) {
                apiToken = localStorage.getItem('apiToken');
                if (!apiToken || apiToken === 'null' || apiToken === '') {
                    throw new Error('jwt not set');
                } else {
                    apiURL = config.get('apiUrl')
                    header.append('Authorization', `${apiToken}`);
                    break;
                }
            } else {
                apiURL = config.get('authorizedApiUrl')
                header.append('Authorization', `Bearer ${jwt}`);
                break;
            }
        case API.Public:
            apiURL = config.get('apiUrl')
            apiToken = localStorage.getItem('apiToken');
            if (!apiToken || apiToken === 'null' || apiToken === '') {
                throw new Error('jwt not set');
            } else {
                apiURL = config.get('apiUrl')
                header.append('Authorization', `${apiToken}`);
                break;
            }

        default:
            throw new Error('invalid api')
    }
    if (!apiURL) {
        throw new Error('api url not set')
    }
    const body = {
        jsonrpc: '2.0',
        method,
        params: request,
        id: 1234
    };
    console.debug(`API Request: ${body.method} -->\n`, body.params);
    if (verbose) {
        try {
            console.debug('\n', JSON.parse(JSON.stringify(body.params)));
            console.debug('\n', JSON.stringify(body.params));
        } catch (e) {
            console.error('error parsing stringified body.params');
            return
        }
    }
    let responseObjectJson;
    try {
        const responseObject = await fetch(
            apiURL,
            controller ? {
                method: 'POST',
                headers: header,
                mode: 'cors',
                body: JSON.stringify(body),
                signal: controller.signal
            } : {
                method: 'POST',
                headers: header,
                mode: 'cors',
                body: JSON.stringify(body),
            },
        );
        responseObjectJson = await responseObject.json();
    } catch (e) {
        console.log(e)
        if (e.name === 'AbortError') {
            return {
                columns: [],
                data: {},
                Errors: 'finished early'
            }
        } else {
            console.error(`API Failed: ${body.method} -->\n`, e);
        }
        // throw new Error('failed to contact server')
    }
    //TODO: Dont believe these if statements are functioning as originally intended, error messages are getting lost.
    //TODO: A rework which returns appropriate results OR errors -but will have to go over all APIs to ensure we dont introduce breaking changes.
    if (!responseObjectJson || !responseObjectJson.result) {
        return {}
    }
    if (responseObjectJson.result.result == null && responseObjectJson.result.error != null) {
        return responseObjectJson.result.error
    }
    else if (responseObjectJson.result) {
        console.debug(`API Response Success: ${body.method}-->\n`, responseObjectJson.result);
        return responseObjectJson.result;
    }
    else if (responseObjectJson.error && responseObjectJson.error.message== "record not found") {
        return responseObjectJson.error
    }
    else if (responseObjectJson.error && responseObjectJson.error.message) {
        throw new Error(responseObjectJson.error.message)
    }

    else {
        throw new Error('unknown thing went wrong')
    }
}
