import React, {FC} from "react";
import {Box, Grid, Tooltip, Typography} from "@mui/material";
import Goal from "../../../../cog/keystone/goals/goal/Goal";
import HomeAssigneeComponent from "./components/HomeAsigneesComponent";
import {useTheme} from "@mui/material/styles";
import HomeGoalTagComponent from "./components/HomeGoalTagComponent";
import TargetComponent from "../goals/goalDataGrid/components/TargetComponent";
import {Event, Flag} from "@mui/icons-material";
import moment from "moment/moment";
import HomeBurnDownChartComponent from "./components/HomeBurnDownChartComponent";

interface HomeGoalProps {
    goal: Goal
}

interface FakeRowForTargetComponent {
    row: Goal
}

// Todo: ask charl about the usermap json.parse thingy and if it should just be straight in the assignee component thing

const HomeGoal: FC<HomeGoalProps> = (props) => {
    const fakeRow: FakeRowForTargetComponent = {row: props.goal}

    const goalTypeMap = {
        "SV": "Sales & Volume",
        "NPD": "New Product Distribution",
        "NPS": "New Pack Size"
    }

    return <Box
        sx={{
            p: 1,
            m: 1,
            boxShadow: useTheme().shadows[3],
            backgroundColor: useTheme().palette.background.paper,
            // backgroundColor: useTheme().palette.background.default,
            borderRadius: 1,
            // borderRadius: useTheme().shape.borderRadius,
            border: `1px solid ${useTheme().palette.divider}`,
        }}
        className={"goalSlide"}
    >

        {/*First half*/}
        <Box>
            <Box sx={{
                justifyContent: 'left',
                display: 'flex',
                // width: '100%',
                gap: 0,
                flexFlow: "column"
            }}>
                <Tooltip title={"Name"}>
                    <Typography variant={"h5"}>{props.goal.name}</Typography>
                </Tooltip>
                <Tooltip title={"Description"}>
                    <Typography variant={"inherit"}>{props.goal.description}</Typography>
                </Tooltip>
            </Box>
            <Grid container display={"grid"} gridTemplateColumns={"0.75fr 2.5fr"}>
                <Box>

                    {/*    Goal Type*/}
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        p: 1,
                        pt: 3
                    }}>
                        <Tooltip title={"Goal Type"}>
                            <Flag fontSize={"large"}/>
                        </Tooltip>
                        {/*<Tooltip title={"Goal type"}>*/}
                        <Typography align={"left"}>{goalTypeMap[props.goal.type]}</Typography>
                        {/*</Tooltip>*/}
                    </Box>
                    {/*Assignee Components */}
                    <Box sx={{
                        justifyContent: 'flex-start',
                        display: 'flex',
                        width: '100%',
                    }}>
                        {/*<AssigneeComponent assignees={props.goal.userID && props.goal.userID ? JSON.parse(props.goal.userID) : []}/>*/}
                        <HomeAssigneeComponent assignees={props.goal.userID}/>
                    </Box>
                    {/*Tags component*/}

                    <Box sx={{
                        justifyContent: 'flex-start',
                        display: 'flex',
                        width: '100%',
                        m: 0
                    }}>
                        {/*<Typography align={"left"} variant={"inherit"}>Tags</Typography>*/}
                        <HomeGoalTagComponent TagSting={props.goal.tag}/>
                    </Box>

                    {/*    Date Component */}
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        p: 1
                    }}>
                        <Tooltip title={"Goal Due Date"}>
                            <Event fontSize={"large"}/>
                        </Tooltip>
                        <Typography align={"left"}>{moment(props.goal.dueDate).format('DD-MM-YYYY')}</Typography>

                    </Box>
                </Box>
                <Box>
                    <HomeBurnDownChartComponent goal={props.goal} minimalist={true} size={250}/>
                </Box>
            </Grid>
        </Box>

        <Box sx={{
            m: 1
        }}>
            <TargetComponent value={7} row={fakeRow}/>
        </Box>
    </Box>
}


export default HomeGoal