import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress
} from "@mui/material";
import { GoalStore } from "../../../../../../cog/keystone/goals";
import { useSnackbar } from "notistack";
import { useKeystoneContext } from "../../../../../../contexts/KeystoneContext";
import { useMainContext } from "../../../../../../contexts/MainContext";

interface DeleteGoalDialogProps {
  goalID: string;
  handleDialogClose: () => void
}

const DeleteGoalDialog: FC<DeleteGoalDialogProps> = ({goalID, handleDialogClose}) => {
  const {enqueueSnackbar} = useSnackbar();
  const keystoneCtx = useKeystoneContext();
  const mainCtx = useMainContext();

  const [isDisabled, setIsDisabled] = useState(false)

  const deleteGoal = async () => {
    setIsDisabled(true)
    const response = await GoalStore.DeleteOne({id: goalID, clientName: mainCtx.activeOrganization.name})
    if (response) {
      setIsDisabled(false)
      enqueueSnackbar('Goal Deleted', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
    } else {
      setIsDisabled(false)
      enqueueSnackbar('Failed to Delete Goal', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
    handleDialogClose();
    keystoneCtx.setFlipSwitchForGoals(!keystoneCtx.flipSwitchForGoals)
  }

  return (
    <Box sx={{ m: 2 }}>
      <DialogTitle>Delete Goal</DialogTitle>
      <DialogContent>
        <Grid container display={"grid"} gridTemplateColumns={"1fr"}
              gridAutoFlow={"row"} gridAutoRows={"auto"} gap={1} >
          <Box>Are you sure you want to delete this goal?</Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant={"text"} >
          <Button
            onClick={deleteGoal}
            disabled={isDisabled}
          >
            {isDisabled ? <CircularProgress size={20}/> : "Confirm"}
          </Button>

        <Button
          onClick={() => handleDialogClose()}>
          Cancel
        </Button>
      </ButtonGroup>
    </DialogActions>
    </Box>
  );
}

export default DeleteGoalDialog;