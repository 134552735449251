const currencyFormatter = (org : string, value : string) => {
  let formatter : any
  let formattedValue : any
  
  switch (org) {
    case "UNILEVER_KENYA":
      formatter = new Intl.NumberFormat('en-KE', {style: 'currency', currency: 'KES'})
      formattedValue = formatter.format(value as unknown as number)
      formattedValue = formattedValue
      break;    
    case "WUTOW":
      formatter = new Intl.NumberFormat('en-NA', {style: 'currency', currency: 'NAD'})
      formattedValue = formatter.format(value as unknown as number)
      formattedValue = "N" + formattedValue
      break;
    case "WUTOW UNILEVER":
      formatter = new Intl.NumberFormat('en-NA', {style: 'currency', currency: 'NAD'})
      formattedValue = formatter.format(value as unknown as number)
      formattedValue = "N" + formattedValue
      break;
    case "WUTOW_UNILEVER":
      formatter = new Intl.NumberFormat('en-NA', {style: 'currency', currency: 'NAD'})
      formattedValue = formatter.format(value as unknown as number)
      formattedValue = "N" + formattedValue
      break;
    case "WUTOW KC":
      formatter = new Intl.NumberFormat('en-NA', {style: 'currency', currency: 'NAD'})
      formattedValue = formatter.format(value as unknown as number)
      formattedValue = "N" + formattedValue
      break;
    case "WUTOW_KC":
      formatter = new Intl.NumberFormat('en-NA', {style: 'currency', currency: 'NAD'})
      formattedValue = formatter.format(value as unknown as number)
      formattedValue = "N" + formattedValue
      break;
    case "KIMBERLY CLARK":
      formatter = new Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "CASALESPNP":
      formatter = new Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "CASALESPNP_UNILEVER":
      formatter = new Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "DKSH":
      formatter = new Intl.NumberFormat('en-CH', {style: 'currency', currency: 'CHF'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "DKSH MO":
      formatter = new Intl.NumberFormat('ta-MY', {style: 'currency', currency: 'MYR'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "DKSH HK":
      formatter = new Intl.NumberFormat('en-HK', {style: 'currency', currency: 'HKD'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "DKSH_MO":
      formatter = new Intl.NumberFormat('ta-MY', {style: 'currency', currency: 'MYR'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "DKSH_HK":
      formatter = new Intl.NumberFormat('en-HK', {style: 'currency', currency: 'HKD'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "CASALES":
      formatter = new Intl.NumberFormat('en-BW', {style: 'currency', currency: 'BWP'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "CASALES UNILEVER":
      formatter = new Intl.NumberFormat('en-BW', {style: 'currency', currency: 'BWP'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "CASALES_UNILEVER":
      formatter = new Intl.NumberFormat('en-BW', {style: 'currency', currency: 'BWP'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "CASALES KC":
      formatter = new Intl.NumberFormat('en-BW', {style: 'currency', currency: 'BWP'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "CASALES_KC":
      formatter = new Intl.NumberFormat('en-BW', {style: 'currency', currency: 'BWP'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "LOGICO":
      formatter = new Intl.NumberFormat('en-SL', {style: 'currency', currency: 'SZL'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "PEPSICO TANZANIA":
      formatter = new Intl.NumberFormat('en-TZ', {style: 'currency', currency: 'TZS'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "PEPSICO_TANZANIA":
      formatter = new Intl.NumberFormat('en-TZ', {style: 'currency', currency: 'TZS'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "PEPSICO NIGERIA":
      formatter = new Intl.NumberFormat('en-NG', {style: 'currency', currency: 'NGN'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "PEPSICO_NIGERIA":
      formatter = new Intl.NumberFormat('en-NG', {style: 'currency', currency: 'NGN'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "PEPSICO MOZAMBIQUE":
      formatter = new Intl.NumberFormat('pt-MZ', {style: 'currency', currency: 'MZN'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "PEPSICO_MOZAMBIQUE":
      formatter = new Intl.NumberFormat('pt-MZ', {style: 'currency', currency: 'MZN'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    case "UNILEVER":
      formatter = new Intl.NumberFormat('om-ET', {style: 'currency', currency: 'ETB'})
      formattedValue = formatter.format(value as unknown as number)
      break;
    default :
      formatter = new Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR'})
      formattedValue = formatter.format(value as unknown as number)
  }

  return formattedValue
}

export default currencyFormatter;