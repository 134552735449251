import * as React from "react";
import {FC, useEffect, useState} from "react";
import {
  Alert, Box, Card, CardContent, CardHeader, Divider, Grid, Step, StepButton, Stepper, Typography,
} from "@mui/material";
import {useMainContext} from "../../../../../contexts/MainContext";
import PieChartStat from "../PieChart";
import SingleValueIndicator from "../SingleValueIndicator";
import {GridColDef, GridEventListener} from "@mui/x-data-grid";
import {DataGridPro, DataGridProProps} from "@mui/x-data-grid-pro";
import BarGraphStat from "./bargraph";
import {StyledDataGrid} from "../../listview/CustomDataGridTheme";
import {useTheme} from "@mui/material/styles";
import UserStatsTableDetail from "./UserStatsTableDetail";
import {KanbanStore} from "../../../../../cog/keystone/card";
import HeatMapStat from "../HeatMap";
import currencyFormatter from "src/utils/currencyFormatter";

const steps = ["All Users Contribution", "Selected User Contribution"];
interface StatsViewProps {
  DateSelectionStart: string;
  DateSelectionEnd: string;

  Top5Pie: Record<string, any>;
  Bottom5Pie: Record<string, any>;
  Top5TableRows: any[];
  Bottom5TableRows: any[];
  UserDropDown: any[];
  UserStatsData: Record<string, any>;
  UserBarDataAvailable: any[];
  UserBarDataGained: any[];
  AllUsersTable: any[];
  AllUsersResolvedOpps: any[];
  AllUsersPendingOpps: any[];
}

const UserStatsPage: FC<StatsViewProps> = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const mainCtx = useMainContext();
  const [userStats, setUserStats] = useState<Record<string, any>>(null);
  const [activeUser, setActiveUser] = useState<string>(null);
  const [field, setField] = useState<string>("assigned_to");
  // Theme Object
  const tempTheme = useTheme();
  const [myTheme, setMyTheme] = useState(tempTheme);
  const getDetailPanelHeight = React.useCallback(() => 400, []);
  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(
    ({ row }) => (
      <UserStatsTableDetail
        myRows={props.UserDropDown}
        myCol={columnsUserDropDown}
      />
    ),
    []
  );

  const fetchUser = async () => {
    const user_response = await KanbanStore.SingleUserStats({
      clientName: mainCtx.activeOrganization.name,
      userName: activeUser,
      dateSelectionStart: props.DateSelectionStart,
      dateSelectionEnd: props.DateSelectionEnd,
    });
    if (user_response) {
      setUserStats(user_response);
    }
  };

  useEffect(() => {
    activeUser != null ? fetchUser() : null;
  }, [activeUser]);

  const edit = (field: string, params: string) => {
    let assignedMembers: string;
    let tempAssigns = [];
    if (params) {
      tempAssigns = JSON.parse(params);
    }
    let findMembers;

    if (
      mainCtx.members.length > 0 &&
      tempAssigns.length > 0 &&
      mainCtx.members.find((mem) => mem.id == tempAssigns[0])
    ) {
      findMembers = mainCtx.members.find(
        (mem) => mem.id == tempAssigns[0]
      ).displayName;
      if (mainCtx.members.length > 0 && tempAssigns.length > 0) {
        findMembers =
          mainCtx.members.find((mem) => mem.id == tempAssigns[0]) &&
          mainCtx.members.length > 0
            ? (findMembers = mainCtx.members.find(
                (mem) => mem.id == tempAssigns[0]
              ).displayName)
            : [];
      } else {
        findMembers = [];
      }

      for (let i = 0; i < tempAssigns.length; i++) {
        // userMap[members[i].id] = members[i]
        mainCtx.members.length > 0 &&
        mainCtx.members.find((mem) => mem.id == tempAssigns[i])
          ? (findMembers = mainCtx.members.find(
              (mem) => mem.id == tempAssigns[i]
            ).displayName)
          : [];
        assignedMembers = findMembers + " ";
      }

      return assignedMembers;
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "#", width: 10 },
    {
      field: "Username",
      valueFormatter: (params) => {
        return edit("Username", params.value);
      },
      headerName: "User",
      flex: 1,
      editable: true,
    },
    {
      field: "Efficiency_Rate",
      headerName: "Efficiency Rate",
      flex: 1,
      editable: true,
    },
    {
      field: "Total_Contribution",
      headerName: "Total_Contribution",
      type: "number",
      valueFormatter: ({ value }) =>
        typeof value === "number"
          ? currencyFormatter(
              mainCtx.activeOrganization.name,
              (Math.round(value * 100) / 100).toFixed(2)
            )
          : null,
      flex: 1,
      editable: true,
    },
  ];
  const columnsUserDropDown: GridColDef[] = [
    { field: "id", headerName: "#", width: 10 },
    {
      field: "Username",
      valueFormatter: (params) => {
        return edit("Username", params.value);
      },
      headerName: "Username",
      flex: 1,
      editable: true,
    },
    {
      field: "Contribution",
      headerName: "Contribution",
      type: "number",
      valueFormatter: ({ value }) =>
        typeof value === "number"
          ? currencyFormatter(
              mainCtx.activeOrganization.name,
              (Math.round(value * 100) / 100).toFixed(2)
            )
          : null,
      flex: 1,
      editable: true,
    },
    {
      field: "Store_Perc_Ratio",
      headerName: "Store % Ratio",
      type: "number",
      flex: 1,
      editable: true,
    },
  ];
  const oppsColumns: GridColDef[] = [
    { field: "id", headerName: "#", width: 10 },
    {
      field: "assigned_to",
      valueFormatter: (params) => {
        return edit("assigned_to", params.value);
      },
      headerName: "User",
      flex: 1,
      editable: true,
    },
    {
      field: "opportunity_type",
      headerName: "Opportunity Type",
      flex: 1,
      editable: true,
    },
    {
      field: "value",
      headerName: "Value",
      type: "number",
      valueFormatter: ({ value }) =>
        typeof value === "number"
          ? currencyFormatter(
              mainCtx.activeOrganization.name,
              (Math.round(value * 100) / 100).toFixed(2)
            )
          : null,
      flex: 1,
      editable: true,
    },
  ];

  useEffect(() => {}, [mainCtx.activeOrganization.name]);

  const handleChange = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    setActiveUser(
      params.row.Username ? params.row.Username : params.row.assigned_to
    );
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const combineArrays = (first, second) => {
    try {
      return first.reduce((acc, val, ind) => {
        acc[val] = second[ind];
        return acc;
      }, {});
    } catch {
      console.log("error combining", first, second);
    }
  };
  return (
    <>
      <Box>
        <Grid container spacing={4} height={"100%"}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={"Top 5 Contributing Users"} />
              <Divider />
              <CardContent>
                <PieChartStat data={props.Top5Pie} members={mainCtx.members} />
                <StyledDataGrid
                  autoHeight
                  rows={props.Top5TableRows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                  // experimentalFeatures={{ newEditingApi: true }}
                  /*                                components={{
                                    Toolbar: GridToolbar,
                                    DetailPanelExpandIcon: ExpandMore,
                                    DetailPanelCollapseIcon: ExpandLess,
                                }}*/
                  /*componentsProps={{
                                    panel: {
                                        sx: {
                                            '& .MuiDataGrid-panelWrapper': {
                                                backgroundColor: myTheme.palette.background.default,
                                                margin: 0.2,
                                                padding: 0.2
                                            },
                                            // replaces scroll bar
                                            '& .MuiDataGrid-panelContent': {
                                                overflowX: "hidden",
                                                paddingRight: 2,
                                                "&::-webkit-scrollbar": {
                                                    width: 10
                                                },
                                                "&::-webkit-scrollbar-track": {
                                                    backgroundColor: myTheme.palette.background.paper,
                                                    borderRadius: 1,
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                    backgroundColor: myTheme.palette.text.secondary,
                                                    borderRadius: 2,
                                                }
                                            }
                                        }
                                    }
                                }}*/
                  disableDensitySelector={true}
                  //getDetailPanelHeight={getDetailPanelHeight}
                  //getDetailPanelContent={getDetailPanelContent}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={"Bottom 5 Contributing Users"} />
              <Divider />
              <CardContent>
                <PieChartStat
                  data={props.Bottom5Pie}
                  members={mainCtx.members}
                />
                <StyledDataGrid
                  autoHeight
                  rows={props.Bottom5TableRows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                  // experimentalFeatures={{ newEditingApi: true }}
                  /*                                components={{
                                    Toolbar: GridToolbar,
                                    DetailPanelExpandIcon: ExpandMore,
                                    DetailPanelCollapseIcon: ExpandLess,
                                }}*/
                  /* componentsProps={{
                                    panel: {
                                        sx: {
                                            '& .MuiDataGrid-panelWrapper': {
                                                backgroundColor: myTheme.palette.background.default,
                                                margin: 0.2,
                                                padding: 0.2
                                            },
                                            // replaces scroll bar
                                            '& .MuiDataGrid-panelContent': {
                                                overflowX: "hidden",
                                                paddingRight: 2,
                                                "&::-webkit-scrollbar": {
                                                    width: 10
                                                },
                                                "&::-webkit-scrollbar-track": {
                                                    backgroundColor: myTheme.palette.background.paper,
                                                    borderRadius: 1,
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                    backgroundColor: myTheme.palette.text.secondary,
                                                    borderRadius: 2,
                                                }
                                            }
                                        }
                                    }
                                }}
                                disableDensitySelector={true}
                                getDetailPanelHeight={getDetailPanelHeight}
                                getDetailPanelContent={getDetailPanelContent}*/
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={"Opportunities Gained for Selected Period"} />
              <Divider />
              <CardContent>
                <BarGraphStat Series={props.UserBarDataGained} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title={"Opportunities Available for Selected Period"}
              />
              <Divider />
              <CardContent>
                <BarGraphStat Series={props.UserBarDataAvailable} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={24} md={12}>
            <Card
              sx={{
                width: "100%",
                mt: 3,
                px: 0.2,
                // alignItems: 'left'
              }}
            >
              <Box>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  sx={{ padding: "16px", marginX: "10%", paddingLeft: "0px" }}
                >
                  {steps.map((k, idx) => {
                    return (
                      <Step
                        key={k}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "12px",
                        }}
                      >
                        <StepButton
                          style={{ wordBreak: "break-all" }}
                          color="inherit"
                          onClick={handleStep(idx)}
                        >
                          {k}
                        </StepButton>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              <Box>
                {activeStep == 0 ? (
                  <CardContent sx={{ padding: "10px" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Grid container>
                        <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                          <Card>
                            <CardHeader title={"Users Contributions"} />
                            <Divider />
                            <CardContent sx={{ padding: "10px" }}>
                              <DataGridPro
                                autoHeight
                                rows={props.AllUsersTable}
                                columns={columns}
                                pagination
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                disableSelectionOnClick
                                onRowClick={handleRowClick}
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                          <Card>
                            <CardHeader
                              title={"Users Resolved Opportunities"}
                            />
                            <Divider />
                            <CardContent>
                              <DataGridPro
                                autoHeight
                                rows={props.AllUsersResolvedOpps}
                                columns={oppsColumns}
                                pagination
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                disableSelectionOnClick
                                onRowClick={handleRowClick}
                                // experimentalFeatures={{ newEditingApi: true }}
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                          <Card>
                            <CardHeader title={"Users Pending Opportunities"} />
                            <Divider />
                            <CardContent>
                              <DataGridPro
                                autoHeight
                                rows={props.AllUsersPendingOpps}
                                columns={oppsColumns}
                                pagination
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                disableSelectionOnClick
                                onRowClick={handleRowClick}
                                // experimentalFeatures={{ newEditingApi: true }}
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                ) : (
                  <Box>
                    {userStats ? (
                      <Typography></Typography>
                    ) : (
                      <Typography>No Data</Typography>
                    )}
                  </Box>
                )}
                {activeStep == 1 ? (
                  <CardContent
                    sx={{
                      // display: 'flex',
                      // flexDirection: 'column',
                      pt: 2,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <SingleValueIndicator
                          title={"User Outstanding"}
                          value={
                            userStats &&
                            userStats["UserOutstandingIndicator"][
                              "pending_sum_opp"
                            ]
                              ? userStats["UserOutstandingIndicator"][
                                  "pending_sum_opp"
                                ]
                              : [0]
                          }
                          symbol={
                            mainCtx.activeOrganization.name == "DKSH_HK"
                              ? "HK$"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <SingleValueIndicator
                          title={"User Contribution"}
                          value={
                            userStats &&
                            userStats["UserContributionIndicator"][
                              "resolved_sum_opp"
                            ]
                              ? userStats["UserContributionIndicator"][
                                  "resolved_sum_opp"
                                ]
                              : [0]
                          }
                          symbol={
                            mainCtx.activeOrganization.name == "DKSH_HK"
                              ? "HK$"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Card
                          sx={{
                            width: "100%",
                            padding: "16px",
                            height: "100%",
                          }}
                        >
                          {/*<CardHeader title={'Opportunities by type of selected user'} />*/}
                          {userStats && userStats["UserOppsByTypePie"] ? (
                            <Box sx={{ paddingTop: "10%" }}>
                              <PieChartStat
                                data={combineArrays(
                                  userStats["UserOppsByTypePie"][
                                    "opportunity_type"
                                  ],
                                  userStats["UserOppsByTypePie"]["sum_opp"]
                                )}
                              />
                            </Box>
                          ) : (
                            <Alert
                              severity="warning"
                              sx={{
                                height: 165,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              No Opportunities Pending for User
                            </Alert>
                          )}
                        </Card>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <Card sx={{ width: "100%" }}>
                          <CardHeader
                            title={
                              "Contribution of user in past 4 weeks grouped by opportunity type"
                            }
                          />
                          {userStats &&
                          Object.keys(userStats["UserValsHeatMap"]).length >
                            0 &&
                          userStats["UserValsHeatMap"] ? (
                            <HeatMapStat
                              series={userStats["UserValsHeatMap"]}
                            />
                          ) : (
                            <Alert
                              severity="warning"
                              sx={{
                                height: 123,
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "18px",
                                marginLeft: "17px",
                                marginRight: "17px",
                              }}
                            >
                              No User Contribution
                            </Alert>
                          )}
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                        <Card>
                          <CardHeader title={"User Resolved Opportunities"} />
                          <Divider />
                          <CardContent>
                            <DataGridPro
                              initialState={{
                                filter: {
                                  filterModel: {
                                    items: [
                                      {
                                        columnField: "assigned_to",
                                        operatorValue: "contains",
                                        value: activeUser,
                                      },
                                    ],
                                  },
                                },
                              }}
                              autoHeight
                              rows={props.AllUsersResolvedOpps}
                              columns={oppsColumns}
                              pagination
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              disableSelectionOnClick
                              onRowClick={handleRowClick}
                              experimentalFeatures={{ newEditingApi: true }}
                            />
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                        <Card>
                          <CardHeader title={"User Pending Opportunities"} />
                          <Divider />
                          <CardContent>
                            <DataGridPro
                              initialState={{
                                filter: {
                                  filterModel: {
                                    items: [
                                      {
                                        columnField: "assigned_to",
                                        operatorValue: "contains",
                                        value: activeUser,
                                      },
                                    ],
                                  },
                                },
                              }}
                              autoHeight
                              rows={props.AllUsersPendingOpps}
                              columns={oppsColumns}
                              pagination
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              disableSelectionOnClick
                              onRowClick={handleRowClick}
                              experimentalFeatures={{ newEditingApi: true }}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                ) : null}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserStatsPage;
