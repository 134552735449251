import React, { FC, useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import {useMainContext} from "../../../../../contexts/MainContext";
import { CampaignStore } from "../../../../../cog/keystone/goals/campaigns";
import currencySwitcher from "../../../../../utils/currencySwitcher";

interface CampaignValueComponentProps {
  campaignID: string
}

const CampaignValueComponent: FC<CampaignValueComponentProps> = ({campaignID}) => {
  const mainCtx = useMainContext()
  let formatter = Intl.NumberFormat('en', {notation: 'compact'});
  const [loading, setLoading] = useState<boolean>(false)
  const [value, setValue] = useState<number>(0)

  const getGoalValue = async () => {
    setLoading(true)

    const response = await CampaignStore.CalculateCampaignValue({campaignID: campaignID, clientName: mainCtx.activeOrganization.name})
    if (response && response.value) {
      return response.value
    }
  }

  useEffect(() => {
    let isMounted = true;
    getGoalValue().then((r) => {
      if (isMounted && r) {
        setValue(Object.values(r)[0])
        setLoading(false)
      }
    })

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
        position: 'relative',
        alignItems: 'center'
      }}
    >
      {!loading ? <Typography>{currencySwitcher(mainCtx.activeOrganization.name) + " " + formatter.format(value)}</Typography> : <Skeleton sx={{ width: "35vw" }} />}
    </Box>
  );
}

export default CampaignValueComponent;