const DateLastSold = (props)=>{
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Business_Icons" data-name="Business Icons" version="1.1" viewBox="0 0 203 203" height={height}>
            <defs id="defs63">
                <linearGradient id="dateLastSoldBlob" x1="60.1" x2="160.3" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop id="stop4" offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="dateLastSoldLine" x1=".9" x2="5.1" y1="153.9" y2="149.6" gradientUnits="userSpaceOnUse">
                    <stop id="stop9" offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-2" x1="131.8" x2={136} y1="5.2" y2=".9" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-3" x1="197.9" x2="202.2" y1="190.2" y2={186} />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-4" x1="14.7" x2="16.7" y1="28.5" y2="26.6" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-5" x1="16.9" x2="18.8" y1="26.4" y2="24.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-6" x1="19.1" x2={21} y1="28.5" y2="26.6" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-7" x1="16.9" x2="18.8" y1="30.6" y2="28.7" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-8" x1="182.9" x2="184.9" y1="82.9" y2={81} />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-9" x1="185.1" x2={187} y1="80.8" y2="78.9" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-10" x1="187.3" x2="189.2" y1="82.9" y2={81} />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-11" x1="185.1" x2={187} y1={85} y2="83.1" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-12" x1="90.1" x2={92} y1="200.4" y2="198.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-13" x1="92.2" x2="94.2" y1="198.2" y2="196.3" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-14" x1="94.4" x2="96.3" y1="200.3" y2="198.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-15" x1="92.2" x2="94.2" y1="202.5" y2="200.5" />
                <linearGradient id="dateLastSoldFill" x1="42.5" x2={122} y1="147.9" y2="68.4" gradientUnits="userSpaceOnUse">
                    <stop id="stop28" offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-2" x1="67.6" x2="121.1" y1="80.3" y2="26.8" />
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-3" x1={119} x2="164.9" y1="153.6" y2="107.8" />
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-4" x1="43.6" x2="51.5" y1="47.3" y2="39.3" />
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-5" x1="63.5" x2="71.7" y1="52.8" y2="44.5" />
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-6" x1="104.2" x2="111.7" y1="52.1" y2="44.5" />
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-7" x1="83.5" x2="91.4" y1="47.1" y2="39.2" />
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-8" x1="123.6" x2="131.7" y1="47.3" y2="39.1" />
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-9" x1="139.7" x2="145.4" y1="133.7" y2={128} />
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-10" x1="92.5" x2="107.8" y1="108.4" y2="93.1" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-16" x1="46.5" x2={148} y1="153.4" y2="51.9" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-17" x1="172.8" x2={176} y1="113.9" y2="110.7" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-18" x1="165.6" x2="181.3" y1="156.2" y2="140.5" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-19" x1="121.9" x2={162} y1="150.8" y2="110.6" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-20" x1="141.6" x2="161.5" y1="137.6" y2="117.6" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-21" x1={44} x2="60.7" y1="84.1" y2="67.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-22" x1="67.9" x2="84.6" y1="84.1" y2="67.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-23" x1="91.8" x2="108.5" y1="84.1" y2="67.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-24" x1="115.6" x2="132.4" y1="84.1" y2="67.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-25" x1={44} x2="60.7" y1="109.1" y2="92.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-26" x1="67.9" x2="84.6" y1="109.1" y2="92.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-27" x1={44} x2="60.7" y1="133.1" y2="116.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-28" x1="67.9" x2="84.6" y1="133.1" y2="116.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-29" x1="91.8" x2="108.5" y1="109.1" y2="92.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-30" x1="96.8" x2="104.2" y1="103.1" y2="95.6" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-31" x1="139.8" x2="144.5" y1="112.1" y2="107.4" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-32" x1="157.2" x2="162.5" y1="101.9" y2="96.6" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-33" x1="160.5" x2="165.2" y1="133.2" y2="128.5" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-34" x1="139.4" x2="144.1" y1={154} y2="149.3" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-35" x1="118.7" x2="123.4" y1="132.9" y2="128.2" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-36" x1="156.5" x2="164.4" y1="166.8" y2={159} />
                <linearGradient xlinkHref="#dateLastSoldFill" id="dateLastSoldFill-2-6" x1="81.1" x2="117.6" y1="81.3" y2="44.7" gradientTransform="translate(35.5 72.7)" />
                <linearGradient xlinkHref="#dateLastSoldFill" id="linearGradient2058" x1="94.5" x2="115.8" y1="67.9" y2="46.7" gradientTransform="translate(35.5 72.7)" gradientUnits="userSpaceOnUse" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-18-7" x1="84.8" x2="124.2" y1="77.6" y2="38.2" gradientTransform="translate(35.5 72.7)" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-17-9" x1="94.2" x2="116.2" y1="68.5" y2="46.5" gradientTransform="translate(35.5 72.7)" />
                <linearGradient xlinkHref="#dateLastSoldLine" id="dateLastSoldLine-16-0" x1="80.3" x2="128.4" y1="82.1" y2={34} gradientTransform="translate(35.5 72.7)" />
                <style id="style2" dangerouslySetInnerHTML={{__html: "\n            .iconWhite{fill:#fff}.dateLastSoldCls-18{fill:url(#dateLastSoldFill)}.dateLastSoldCls-19{fill:url(#dateLastSoldFill-2)}.dateLastSoldCls-23{fill:url(#dateLastSoldFill-6)}.dateLastSoldCls-24{fill:url(#dateLastSoldFill-7)}.dateLastSoldCls-25{fill:url(#dateLastSoldFill-8)}\n        " }} />
            </defs>
            <g id="Icon_19">
                <path id="Contour" d="M54 132.1a2.9 2.9 0 0 0-3-1.4c-5.8 1-22.8 1.5-24.1-25-1.6-32 37.2-86.8 94.3-76 45.9 8.8 11.6 52.5 39.7 63.5 16.5 6.4 31.3 39.4-5.2 68.1-19.7 15.5-70.4 29.3-101.6-29.2Z" style={{fill: 'url(#dateLastSoldBlob)'}} />
                <path id="Contour-2" d="M3 154.7a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" style={{fill: 'url(#dateLastSoldLine)'}} />
                <path id="Contour-3" d="M133.9 6a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" style={{fill: 'url(#dateLastSoldLine-2)'}} />
                <path id="Contour-4" d="M200 191a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" style={{fill: 'url(#dateLastSoldLine-3)'}} />
                <g id="Group">
                    <path id="Contour-5" d="M17.2 27.5a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#dateLastSoldLine-4)'}} />
                    <path id="Contour-6" d="M17.8 27a1 1 0 0 0 1-1v-1.2a1 1 0 0 0-1-.9 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#dateLastSoldLine-5)'}} />
                    <path id="Contour-7" d="M20.5 26.5h-1a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#dateLastSoldLine-6)'}} />
                    <path id="Contour-8" d="M17.8 28.1a1 1 0 0 0-1 1v1.1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#dateLastSoldLine-7)'}} />
                </g>
                <g id="Group-2">
                    <path id="Contour-9" d="M185.4 82a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#dateLastSoldLine-8)'}} />
                    <path id="Contour-10" d="M186 81.3a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#dateLastSoldLine-9)'}} />
                    <path id="Contour-11" d="M188.8 81h-1.1a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#dateLastSoldLine-10)'}} />
                    <path id="Contour-12" d="M186 82.5a1 1 0 0 0-1 1v1.1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#dateLastSoldLine-11)'}} />
                </g>
                <g id="Group-3">
                    <path id="Contour-13" d="M92.6 199.4a1 1 0 0 0-1-1h-1.1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#dateLastSoldLine-12)'}} />
                    <path id="Contour-14" d="M93.2 198.8a1 1 0 0 0 1-1v-1.1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#dateLastSoldLine-13)'}} />
                    <path id="Contour-15" d="M95.9 198.4h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#dateLastSoldLine-14)'}} />
                    <path id="Contour-16" d="M93.2 200a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#dateLastSoldLine-15)'}} />
                </g>
                <path id="Contour-17" d="m43.3 45.7-7.5.2a33.8 33.8 0 0 0-2 4.7V140h76.8s-6.6-17.6 9.7-33.6c0 0 10.7-10 21.6-8.2l.2-49.6-3-3Z" className="iconWhite" />
                <path id="Contour-18" d="M34.9 65.3s.2 58.8 54.3 65.4c0 0 45.5 8.8 52.8-42.3l-1.6 51.6H34.7l-1.2-2.1Z" className="dateLastSoldCls-18" />
                <path id="Contour-19" d="M47.7 52.9v7.6h94.1l.1-12.8-2.8-2-91.4.2v7z" className="dateLastSoldCls-19" />
                <circle id="Contour-20" cx="141.9" cy="130.7" r="32.4" style={{fill: 'url(#dateLastSoldFill-3)'}} />
                <circle id="Contour-21" cx="144.5" cy="128.2" r={30} className="iconWhite" />
                <rect id="Contour-22" width="8.8" height={14} x="43.3" y="38.9" className="iconWhite" rx="1.3" />
                <path id="Contour-23" d="M50.2 48.5h-5a1 1 0 0 1-1-1L43 39.7c0-.6 1.7-.9 2.2-.9h5c.5 0 2 .8 2 1.4l-1 7.4a1 1 0 0 1-1 1Z" style={{fill: 'url(#dateLastSoldFill-4)'}} />
                <rect id="Contour-24" width="8.8" height={14} x="63.3" y="38.9" className="iconWhite" rx="1.3" />
                <rect id="Contour-25" width="8.8" height={14} x="83.3" y="38.9" className="iconWhite" rx="1.3" />
                <rect id="Contour-26" width="8.8" height={14} x="103.3" y="38.9" className="iconWhite" rx="1.3" />
                <rect id="Contour-27" width="8.8" height={14} x="123.3" y="38.9" className="iconWhite" rx="1.3" />
                <path id="Contour-28" d="M65.3 43.4h4.9a1 1 0 0 1 1 1l1 7.5c0 .6-1.5 1.2-2 1.2h-5c-.5 0-2.5-.6-2.5-1.2l1.6-7.5a1 1 0 0 1 1-1Z" style={{fill: 'url(#dateLastSoldFill-5)'}} />
                <path id="Contour-29" d="M105.3 43.4h4.9a1 1 0 0 1 1 1l1 7.5c0 .6-1.4 1.2-2 1.2h-4.9c-.5 0-2.2-2.8-2.2-3.3l1.3-5.4a1 1 0 0 1 .9-1Z" className="dateLastSoldCls-23" />
                <path id="Contour-30" d="M90.2 48.5h-5a1 1 0 0 1-1-1l-1.4-8.2c0-.5 2-.5 2.4-.5h5c.5 0 2 .8 2 1.3l-1 7.4a1 1 0 0 1-1 1Z" className="dateLastSoldCls-24" />
                <path id="Contour-31" d="M130.2 48.5h-5a1 1 0 0 1-1-1l-1.4-7.7c0-.5 2-1 2.4-1h5c.5 0 2 .5 2 1l-1.1 7.7a1 1 0 0 1-1 1Z" className="dateLastSoldCls-25" />
                <rect id="Contour-33" width="16.3" height="15.9" x={92} y="92.8" rx="1.3" style={{fill: 'url(#dateLastSoldFill-10)'}} />
                <rect id="Contour-34" width="16.3" height="15.9" x="44.2" y="67.8" className="iconWhite" rx="1.3" />
                <rect id="Contour-35" width="16.3" height="15.9" x="68.2" y="67.8" className="iconWhite" rx="1.3" />
                <rect id="Contour-36" width="16.3" height="15.9" x="92.2" y="67.8" className="iconWhite" rx="1.3" />
                <rect id="Contour-37" width="16.3" height="15.9" x="116.2" y="67.8" className="iconWhite" rx="1.3" />
                <rect id="Contour-38" width="16.3" height="15.9" x="116.2" y="68.8" className="iconWhite" rx="1.3" />
                <rect id="Contour-39" width="16.3" height="15.9" x="68.2" y="92.8" className="iconWhite" rx="1.3" />
                <rect id="Contour-40" width="16.3" height="15.9" x="68.2" y="116.8" className="iconWhite" rx="1.3" />
                <rect id="Contour-41" width="16.3" height="15.9" x="44.2" y="92.8" className="iconWhite" rx="1.3" />
                <rect id="Contour-42" width="16.3" height="15.9" x="44.2" y="116.8" className="iconWhite" rx="1.3" />
                <path id="Contour-43" d="M101 122.4a.4.4 0 0 0-.4.3c-4.1 22.7 12.5 39.5 35.2 45.5-27.6 0-42.3-23-40.2-46.3a.4.4 0 0 0-.3-.4l-4-1a.4.4 0 0 1-.1-.6l7.7-5.6a.4.4 0 0 1 .5 0l5.6 7.7a.4.4 0 0 1-.4.7Z" className="iconWhite" />
                <g id="Group-4">
                    <path id="Contour-44" d="M142 164.1a33.4 33.4 0 0 0 1.1-66.8V48.7a4 4 0 0 0-4-4h-6v-4.5a2.3 2.3 0 0 0-2.3-2.4h-6.2a2.3 2.3 0 0 0-2.3 2.4v4.5H113v-4.5a2.3 2.3 0 0 0-2.3-2.4h-6.2a2.3 2.3 0 0 0-2.3 2.4v4.5h-9.1v-4.5a2.3 2.3 0 0 0-2.4-2.4h-6.2a2.3 2.3 0 0 0-2.3 2.4v4.5h-9.1v-4.5a2.3 2.3 0 0 0-2.4-2.4h-6.2a2.3 2.3 0 0 0-2.3 2.4v4.5h-9.1v-4.5a2.3 2.3 0 0 0-2.4-2.4h-6.2a2.3 2.3 0 0 0-2.3 2.4v4.5h-5.4a4 4 0 0 0-4 4v90a2.3 2.3 0 0 0 2.3 2.3h61.5a39.5 39.5 0 0 0 7.3 13.1 46 46 0 0 0 32.7 15.1 1 1 0 0 0 .2-2 44.5 44.5 0 0 1-33.7-26.2h7a33.5 33.5 0 0 0 31.7 23.1Zm31.4-33.4a31.4 31.4 0 1 1-31.5-31.4 31.5 31.5 0 0 1 31.5 31.4Zm-49.1-90.5a.3.3 0 0 1 .3-.4h6.2a.3.3 0 0 1 .3.4v11.4a.3.3 0 0 1-.3.3h-6.2a.3.3 0 0 1-.3-.3V40.2Zm-20 0a.3.3 0 0 1 .3-.4h6.2a.3.3 0 0 1 .3.4v11.4a.3.3 0 0 1-.3.3h-6.2a.3.3 0 0 1-.3-.3V40.2Zm-20 0a.3.3 0 0 1 .3-.4h6.2a.3.3 0 0 1 .3.4v11.4a.3.3 0 0 1-.3.3h-6.2a.3.3 0 0 1-.3-.3V40.2Zm-20 0a.3.3 0 0 1 .3-.4h6.2a.3.3 0 0 1 .3.4v11.4a.3.3 0 0 1-.3.3h-6.2a.3.3 0 0 1-.3-.3V40.2Zm-20 0a.3.3 0 0 1 .3-.4h6.2a.3.3 0 0 1 .4.4v11.4a.3.3 0 0 1-.4.3h-6.2a.3.3 0 0 1-.3-.3V40.2Zm-9.4 8.5a2 2 0 0 1 2-2h5.4v4.9a2.3 2.3 0 0 0 2.3 2.3h6.2a2.3 2.3 0 0 0 2.4-2.3v-5h9.1v5a2.3 2.3 0 0 0 2.3 2.3h6.2a2.3 2.3 0 0 0 2.3-2.3v-5h9.2v5a2.3 2.3 0 0 0 2.3 2.3h6.2a2.3 2.3 0 0 0 2.3-2.3v-5h9.2v5a2.3 2.3 0 0 0 2.3 2.3h6.2a2.3 2.3 0 0 0 2.3-2.3v-5h9.2v5a2.3 2.3 0 0 0 2.3 2.3h6.2a2.3 2.3 0 0 0 2.3-2.3v-5h6a2 2 0 0 1 2 2v10.9H35Zm87 115.4a39 39 0 0 1-16.4-11.3 37.5 37.5 0 0 1-6.7-11.8l-.6-2a45.5 45.5 0 0 1-1.6-17 1.4 1.4 0 0 0-1-1.5l-2.7-.6 6.1-4.5 4.4 6.2-2.3-.2a1.4 1.4 0 0 0-1.5 1.1 36 36 0 0 0 .8 16.5l.7 2c3.5 10 11.3 18 20.8 23ZM102.5 139a33.8 33.8 0 0 1-1-15.6l3 .2a1.4 1.4 0 0 0 1.4-.7 1.4 1.4 0 0 0 0-1.5l-5.6-7.7a1.4 1.4 0 0 0-2-.3l-7.7 5.6a1.4 1.4 0 0 0-.5 1.4 1.4 1.4 0 0 0 1 1.1l3.4.8a47.8 47.8 0 0 0 1.6 16.7H35a.3.3 0 0 1-.2-.3V61.5H141v35.8a33.5 33.5 0 0 0-31.5 41.7Z" style={{fill: 'url(#dateLastSoldLine-16)'}} />
                    <path id="Contour-45" d="M171.3 108.8a36.7 36.7 0 0 1 4.5 8 1 1 0 0 0 2-.4 1.2 1.2 0 0 0-.1-.4 38.7 38.7 0 0 0-4.8-8.4 1 1 0 0 0-.8-.4 1 1 0 0 0-.8 1.5Z" style={{fill: 'url(#dateLastSoldLine-17)'}} />
                    <path id="Contour-46" d="M179.6 139.5a.9.9 0 0 0 0-.2 1 1 0 0 0-2-.3 36.5 36.5 0 0 1-10 17.7 1 1 0 1 0 1.4 1.5 38.5 38.5 0 0 0 10.6-18.7Z" style={{fill: 'url(#dateLastSoldLine-18)'}} />
                    <path id="Contour-47" d="M113.5 130.7a28.4 28.4 0 1 0 28.4-28.4 28.4 28.4 0 0 0-28.4 28.4Zm54.9 0a26.4 26.4 0 1 1-26.5-26.4 26.4 26.4 0 0 1 26.4 26.4Z" style={{fill: 'url(#dateLastSoldLine-19)'}} />
                    <path id="Contour-49" d="M59.2 66.8H45.5a2.3 2.3 0 0 0-2.3 2.3v13.3a2.3 2.3 0 0 0 2.3 2.3h13.7a2.3 2.3 0 0 0 2.3-2.3V69a2.3 2.3 0 0 0-2.3-2.3Zm.3 15.6a.3.3 0 0 1-.3.3H45.5a.3.3 0 0 1-.3-.3V69a.3.3 0 0 1 .3-.3h13.7a.3.3 0 0 1 .3.3Z" style={{fill: 'url(#dateLastSoldLine-21)'}} />
                    <path id="Contour-50" d="M83 66.8H69.5a2.3 2.3 0 0 0-2.3 2.3v13.3a2.3 2.3 0 0 0 2.3 2.3h13.7a2.3 2.3 0 0 0 2.3-2.3V69a2.3 2.3 0 0 0-2.3-2.3Zm.4 15.6a.3.3 0 0 1-.3.3H69.4a.3.3 0 0 1-.3-.3V69a.3.3 0 0 1 .3-.3h13.7a.3.3 0 0 1 .3.3Z" style={{fill: 'url(#dateLastSoldLine-22)'}} />
                    <path id="Contour-51" d="M93.3 84.7H107a2.3 2.3 0 0 0 2.3-2.3V69a2.3 2.3 0 0 0-2.3-2.3H93.3a2.3 2.3 0 0 0-2.3 2.3v13.3a2.3 2.3 0 0 0 2.3 2.3ZM93 69a.3.3 0 0 1 .3-.3H107a.3.3 0 0 1 .3.3v13.3a.3.3 0 0 1-.3.3H93.3a.3.3 0 0 1-.3-.3Z" style={{fill: 'url(#dateLastSoldLine-23)'}} />
                    <path id="Contour-52" d="M117.2 84.7h13.6a2.3 2.3 0 0 0 2.4-2.3V69a2.3 2.3 0 0 0-2.4-2.3h-13.6a2.3 2.3 0 0 0-2.4 2.3v13.3a2.3 2.3 0 0 0 2.4 2.3Zm-.4-15.6a.3.3 0 0 1 .4-.3h13.6a.3.3 0 0 1 .4.3v13.3a.3.3 0 0 1-.4.3h-13.6a.3.3 0 0 1-.4-.3Z" style={{fill: 'url(#dateLastSoldLine-24)'}} />
                    <path id="Contour-53" d="M59.2 91.8H45.5a2.3 2.3 0 0 0-2.3 2.3v13.3a2.3 2.3 0 0 0 2.3 2.3h13.7a2.3 2.3 0 0 0 2.3-2.3V94a2.3 2.3 0 0 0-2.3-2.3Zm.3 15.6a.3.3 0 0 1-.3.3H45.5a.3.3 0 0 1-.3-.3V94a.3.3 0 0 1 .3-.3h13.7a.3.3 0 0 1 .3.3Z" style={{fill: 'url(#dateLastSoldLine-25)'}} />
                    <path id="Contour-54" d="M83 91.8H69.5a2.3 2.3 0 0 0-2.3 2.3v13.3a2.3 2.3 0 0 0 2.3 2.3h13.7a2.3 2.3 0 0 0 2.3-2.3V94a2.3 2.3 0 0 0-2.3-2.3Zm.4 15.6a.3.3 0 0 1-.3.3H69.4a.3.3 0 0 1-.3-.3V94a.3.3 0 0 1 .3-.3h13.7a.3.3 0 0 1 .3.3Z" style={{fill: 'url(#dateLastSoldLine-26)'}} />
                    <path id="Contour-55" d="M59.2 115.8H45.5a2.3 2.3 0 0 0-2.3 2.3v13.3a2.3 2.3 0 0 0 2.3 2.3h13.7a2.3 2.3 0 0 0 2.3-2.3V118a2.3 2.3 0 0 0-2.3-2.3Zm.3 15.6a.3.3 0 0 1-.3.3H45.5a.3.3 0 0 1-.3-.3V118a.3.3 0 0 1 .3-.3h13.7a.3.3 0 0 1 .3.3Z" style={{fill: 'url(#dateLastSoldLine-27)'}} />
                    <path id="Contour-56" d="M83 115.8H69.5a2.3 2.3 0 0 0-2.3 2.3v13.3a2.3 2.3 0 0 0 2.3 2.3h13.7a2.3 2.3 0 0 0 2.3-2.3V118a2.3 2.3 0 0 0-2.3-2.3Zm.4 15.6a.3.3 0 0 1-.3.3H69.4a.3.3 0 0 1-.3-.3V118a.3.3 0 0 1 .3-.3h13.7a.3.3 0 0 1 .3.3Z" style={{fill: 'url(#dateLastSoldLine-28)'}} />
                    <path id="Contour-57" d="M109.3 107.4V94a2.3 2.3 0 0 0-2.3-2.3H93.3a2.3 2.3 0 0 0-2.3 2.3v13.3a2.3 2.3 0 0 0 2.3 2.3H107a2.3 2.3 0 0 0 2.3-2.3Zm-2 0a.3.3 0 0 1-.3.3H93.3a.3.3 0 0 1-.3-.3V94a.3.3 0 0 1 .3-.3H107a.3.3 0 0 1 .3.3Z" style={{fill: 'url(#dateLastSoldLine-29)'}} />
                    <path id="Contour-58" d="M105 96.8a1 1 0 0 0-.7.3l-5.2 5.2-2.4-2.2a1 1 0 0 0-.7-.3 1 1 0 0 0-1 1 1 1 0 0 0 .4.8l2.8 2.6a1.3 1.3 0 0 0 1.8 0l5.7-5.6a1 1 0 0 0-.7-1.7Z" style={{fill: 'url(#dateLastSoldLine-30)'}} />
                    <path id="Contour-60" d="M150.6 95a36.6 36.6 0 0 1 17.6 10.2 1 1 0 0 0 1.5-1.4A38.6 38.6 0 0 0 151 93a1 1 0 0 0-.2 0 1 1 0 0 0-1 1 1 1 0 0 0 .8 1Z" style={{fill: 'url(#dateLastSoldLine-32)'}} />
                    <path id="Contour-64" d="M164.6 159.6a1 1 0 0 0-.6.2 36.7 36.7 0 0 1-8 4.5 1 1 0 0 0 .4 2 1 1 0 0 0 .4-.1 38.3 38.3 0 0 0 8.4-4.8 1 1 0 0 0-.6-1.8Z" style={{fill: 'url(#dateLastSoldLine-36)'}} />
                </g>
                <circle id="Contour-17-2" cx="139.6" cy="130.3" r="32.9" className="iconWhite" style={{fill: '#fff'}} />
                <path id="Contour-18-0" d="M138.2 111v5s-8.8 4-5.4 11c2.7 5.4 10.5 6.3 10.5 6.3s2.2 6.3-2.5 6c-4.7-.3-4.5-3.2-4.5-3.2h-5s1.5 6.6 6.1 8.2l.8 4.4 4.7.4.7-5s5.7-2.8 5.5-7.7c-.2-4.4-3-8.2-7.2-9.6-3.7-1.3-5.6-4.7-1.1-5.8 0 0 2.7.8 3 2.7l5-.2s-.5-5.6-3.6-6.6c-3-1-2.2-5.8-2.2-5.8z" className="dateLastSoldCls-18" style={{fill: 'url(#dateLastSoldLinearGradient2058)'}} />
                <path id="Contour-19-2" d="M172.6 136.9s-19 25.5-40.4 18.5a26.9 26.9 0 0 1-18.3-26.2 45 45 0 0 1 12.7-28.2 30.5 30.5 0 0 0-11.3 7.6 30.8 30.8 0 0 0-2.6 40.4c19.3 29 59.8 8.1 59.9-12.1z" className="dateLastSoldCls-19" style={{fill: 'url(#dateLastSoldFill-2-6)'}} />
                <path id="Contour-24-3" d="M139.8 164.7a34 34 0 1 0 0-68 34 34 0 0 0 0 68zm0-66a32 32 0 1 1 0 63.8 32 32 0 0 1 0-63.8z" className="dateLastSoldCls-23" style={{fill: 'url(#dateLastSoldLine-16-0)'}} />
                <path id="Contour-25-7" d="M133.4 142.2c1.1 1 2.4 1.8 3.8 2.3v3.6c0 .9.8 1.7 1.7 1.7h3.6c1 0 1.7-.8 1.7-1.7v-3.8a9 9 0 0 0 6-8.7c0-2.2-.6-4.2-2-5.9a13 13 0 0 0-6-3.4c-1-.3-1.8-.7-2.7-1.3-.3-.3-.5-.7-.4-1.2 0-.4.1-.8.4-1 .4-.4.8-.6 1.3-.5.4 0 .8 0 1 .3.3.2.4.5.5.8.2.7.9 1.2 1.6 1.2h3.6c1 0 1.8-.9 1.7-1.9a8.1 8.1 0 0 0-5-6.6v-3.7c0-1-.8-1.7-1.7-1.7H139c-1 0-1.8 1-1.7 2v3.4c-1.1.4-2.1 1-3 1.8a8.4 8.4 0 0 0-2.4 6.3c0 2 .6 3.9 1.9 5.4 1.6 1.6 3.6 2.7 5.7 3.3 1 .2 2 .7 3 1.3.3.3.5.7.4 1.2 0 .7-.2 1.4-.7 1.9-.4.5-1.1.7-1.8.7s-1.3-.2-1.8-.6c-.4-.4-.6-.9-.7-1.3 0-.9-.8-1.5-1.6-1.5h-3.8c-.5 0-1 .2-1.3.6-.3.3-.4.8-.4 1.3.2 1.5 1 4.3 2.6 5.7zm2.6-5.6c.2.8.6 1.6 1.2 2.2.9.8 2 1.2 3.2 1.2a4.4 4.4 0 0 0 4.6-4.6c0-1-.5-2-1.2-2.7-1.1-.8-2.4-1.4-3.6-1.7-2-.5-3.6-1.4-5-2.7a5.8 5.8 0 0 1-1.4-4.1 6.4 6.4 0 0 1 4.3-6.3c.6-.3 1-.9 1-1.6v-3.6h3v3.7c0 .7.5 1.3 1.2 1.6a6 6 0 0 1 3.8 4.6h-3a3.5 3.5 0 0 0-3.4-2.4 3.5 3.5 0 0 0-3.7 3.6c0 1 .4 2 1.1 2.7 1 .8 2.2 1.4 3.5 1.7 1.9.5 3.7 1.5 5 2.9 1 1.2 1.6 2.8 1.5 4.5a7 7 0 0 1-4.8 6.9c-.7.2-1.2.8-1.2 1.6v3.7h-2.9v-3.6c0-.8-.5-1.4-1.3-1.6-1.2-.4-2.3-1-3.2-1.8a8.8 8.8 0 0 1-1.9-4.2z" className="dateLastSoldCls-24" style={{fill: 'url(#dateLastSoldLine-17-9)'}} />
                <path id="Contour-26-5" d="M140 158.5a27.9 27.9 0 1 0 0-55.8 27.9 27.9 0 0 0 0 55.8zm0-53.8a25.9 25.9 0 1 1 0 51.9 25.9 25.9 0 0 1 0-51.9z" className="dateLastSoldCls-25" style={{fill: 'url(#dateLastSoldLine-18-7)'}} />
            </g>
        </svg>

    )
}
export default DateLastSold