import { Box, Grid, LinearProgress, Skeleton, Typography } from "@mui/material";
import React from "react";


const OrganizationPageSkeleton = () => {

  return (
    <Box>
      {/* Linear Progress */}
      <Box sx={{ m: 1 }}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <div style={{ marginLeft: 4, marginRight: 4, marginTop: 4, justifySelf: "center" }}>
              <LinearProgress />
            </div>
          </Grid>
        </Grid>
      </Box>

      {/* Skeleton */}
      <Box>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height="70vh" width="100%" sx={{ borderRadius: "16px" }} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrganizationPageSkeleton;