import React, {FC, useEffect, useState} from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    LinearProgress,
    Tooltip
} from "@mui/material";
import {ExcludeStore} from "../../../../cog/keystone/exclude";
import {useMainContext} from "../../../../contexts/MainContext";
import {CalendarTodayOutlined} from "@mui/icons-material";
import {values} from "lodash";
import EFLog from "../../../../cog/keystone/exclude/EFLog";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {Member} from "../../../../cog/keystone/member";
import {GridAlignment} from "@mui/x-data-grid-pro";
import {useTheme} from "@mui/material/styles";
import {RemoveOneRequest} from "../../../../cog/keystone/exclude/Store";
import ConfirmActionModal from "./ConfirmActionModal";
import ExcludeManagerLogsComponent from "./components/ExcludeManagerLogsComponent";
import ExcludedManagerCurrentExcludesComponent from "./components/ExcludedManagerCurrentExcludesComponent";
import {ColumnMappingDictionaryWithData} from "./components/ColumnMappingDictionaryWithData";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";

const ExcludeManager: FC = (props) => {
    const mainctx = useMainContext()// Theme Object
    const keystoneCtx = useKeystoneContext()
    const tempTheme = useTheme() // fetches current theme being used
    const [myTheme, setMyTheme] = useState(tempTheme)
    const [excludeContent, setExcludeContent] = useState<any[]>(null);
    const [excludeLogs, setExcludeLogs] = useState<EFLog[]>(null);
    const [excludeLogRows, setExcludeLogRows] = useState<any[]>(null);
    const [excludeRows, setExcludeRows] = useState<any[]>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [colMapWithDataReference, setColMapWithDataReference] = useState<ColumnMappingDictionaryWithData>(null)

    // confrim actions
    const [confirmActionOpen, setConfirmActionOpen] = React.useState(false);

    // todo : make a new this maybe
    const [removeOneRequest, setRemoveOneRequest] = useState<RemoveOneRequest>({
        header: "",
        orgName: "",
        userID: "",
        valueChange: ""
    })

    const [currentExcludesColumns, setCurrentExcludesColumns] = useState<GridColDef[]>(null);


    const handleConfirmDialogue = () => {
        setConfirmActionOpen(true)
        dialogBody = () => {
            return (
                <ConfirmActionModal
                    cancelAction={handleConfirmClose}
                    confirmAction={handleConfirmUpdate}
                    confirmationWarningMessage={"Warning, this will affect all users and exclude future opportunities and issues that might be relevant."}
                    // clientName={mainCtx.activeOrganization.name}
                    // issueName={card.name}
                    // onClick={handleExcDialogClose}
                />
            );
        }
        // setExcDialogOpen(true);
    }

    const handleConfirmClose = () => {
        setConfirmActionOpen(false);
    };

    // handleConfirmUpdate : Handles confirmation for removing an exclude filter
    async function handleConfirmUpdate() {
        setIsLoading(true)
        handleConfirmClose()
        let tempRequest = removeOneRequest
        tempRequest.userID = mainctx.user.id
        // todo : removeOneTwo might change if luke changes changes tables
        ExcludeStore.RemoveOne({...tempRequest}).then(() => FetchExclude()).then(() => {
            FetchExcludeLog()
        })

    }

    // todo : This the new one
    const FetchExclude = async (): Promise<any> => {
        if (mainctx.activeOrganization != null) {
            try {
                // todo : FindOneTwo might change if luke changes changes tables
                let response = await ExcludeStore.FindOne({
                    orgName: mainctx.activeOrganization.name,
                })
                // console.log("\t\t FetchExclude response", response.exclude)
                let tempBoi = JSON.parse(response.exclude.filter_dict)
                if (tempBoi != null) {
                    setExcludeContent(tempBoi)
                } else {
                    setExcludeContent([])
                }
                return response
            } catch (e) {
                console.error(e)
            }
        }
    }

    const FetchExcludeLog = async (): Promise<any> => {
        // setIsLoading(true)
        if (mainctx.activeOrganization != null) {
            try {
                // todo : FindLogTwo might change if luke changes changes tables
                let response = await ExcludeStore.FindLogs({
                    orgName: mainctx.activeOrganization.name,
                })
                if (response.exclude_logs == null) {
                    setExcludeLogs([])
                    return []
                }
                setExcludeLogs(response.exclude_logs)
                return response.exclude_logs
            } catch (e) {
                console.error(e)
            }
        }
        // setIsLoading(false)
    }

    useEffect(() => {
        if (keystoneCtx.colMap != null && colMapWithDataReference == null) {
            let tempMapRef = new ColumnMappingDictionaryWithData()
            tempMapRef.PopulateWithColMap(keystoneCtx.colMap)
            setColMapWithDataReference(tempMapRef)
        }
    }, [keystoneCtx.colMap]);

    useEffect(() => {
        Promise.resolve().then(() => FetchExclude()).then(() => FetchExcludeLog())
    }, [mainctx])

    useEffect(() => {
        if (excludeContent != null && excludeLogs != null && colMapWithDataReference != null) {
            makeLogColumns()
        }
    }, [excludeLogs, colMapWithDataReference])

    // When clicking delete the removeOneRequest is set, so we handle the confirmation dialogue before deleting it
    useEffect(() => {
        if (removeOneRequest.orgName != "") {
            handleConfirmDialogue()
        }

    }, [removeOneRequest])


    // makeColumns: Initializes the GridColDef array for the DataGrid to use for organizing the data
    const makeLogColumns = () => {
        let gridColumns: GridColDef[] = []

        // Add an id to each log for the datagrid
        let tempRows = values(Object.values(excludeLogs)).map((
            value: EFLog, index: number,
        ) => ({
            id: index,
            ...value,
        }))

        setExcludeLogRows(tempRows)

        /*
        * TempExcludes: Rows used for the currently excluded items
        * ExcludeContent: Excluded items from api
        * tempRows: Rows used for the logs of the excluded items
        * */

        // used to create the rows of currently excluded items
        let tempExclude: any[] = []
        // counter used to set ID for the dataGrid
        let counter = 0

        let tempColMap = colMapWithDataReference
        // Loop through the excluded content
        Object.values(excludeContent).forEach((element) => {
            tempColMap.PopulateWithString(JSON.stringify(element))
            let tempLog: EFLog = new EFLog()
            tempLog.filter = tempColMap.GimmeJsonString()
            // Loop through the logs
            for (let i = 0; i < tempRows.length; i++) {
                if (tempLog.filter == tempRows[i].filter) {
                    // if the templog is empty or the first one, then we assign it
                    if (i == 0 || tempLog.date_actioned == "") {
                        tempLog = tempRows[i]
                        // else we check for the newest dated log and extract that instead
                    } else if (dateConverter(tempLog.date_actioned, tempRows[i].date_actioned) > 0) {
                        tempLog = tempRows[i]
                    }
                }
            }
            if (tempLog.org_name != "") {
                tempExclude.push({
                    id: counter++,
                    org_name: tempLog.org_name,
                    date_actioned: tempLog.date_actioned,
                    filter: tempLog.filter,
                    user_id: tempLog.user_id,
                    action: "CanDelete"

                })
            } else {
                tempExclude.push({
                    id: counter++,
                    org_name: mainctx.activeOrganization.name,
                    date_actioned: "",
                    filter: tempLog.filter,
                    user_id: "",
                    action: "CanDelete"

                })
            }
        })

        if (tempExclude.length == 1 && tempExclude[0].org_name == "") {
            setExcludeRows([])
        } else {
            setExcludeRows(tempExclude)
        }


        if (currentExcludesColumns == null) {
            gridColumns.push(BuildColumnObject("id",
                "12",
                [],
                false,
                true,
                "left",
                setRemoveOneRequest,
                handleConfirmDialogue,
                colMapWithDataReference))
            gridColumns.push(BuildColumnObject("filter",
                `{"Something":"Something else"}`,
                [],
                false,
                false,
                "left",
                setRemoveOneRequest,
                handleConfirmDialogue,
                colMapWithDataReference))
            // The user ID one we can make better for display things
            gridColumns.push(BuildColumnObject("user_id", "Logged User ID", [], false, false, "left", setRemoveOneRequest, handleConfirmDialogue, colMapWithDataReference))
            gridColumns.push(BuildColumnObject("date_actioned", "", [], false, false, "left", setRemoveOneRequest, handleConfirmDialogue, colMapWithDataReference))
            gridColumns.push(BuildColumnObject("action", `Added`, [], false, false, "left", setRemoveOneRequest, handleConfirmDialogue, colMapWithDataReference))
            gridColumns.push(BuildColumnObject("org_name", "Logged Org Name", [], false, false, "left", setRemoveOneRequest, handleConfirmDialogue, colMapWithDataReference))

            setCurrentExcludesColumns(gridColumns)
        }


        setIsLoading(false)
        // setMyColumns(tempColumns)
    }

    return <Box>
        <Card>
            <CardHeader title="Excluded Items"/>
            <Divider/>
            <CardContent>
                {isLoading == true && <LinearProgress
                    sx={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginTop: 2,
                        justifySelf: "center"
                    }}/>}
                {excludeRows != null && isLoading == false && currentExcludesColumns != null &&
                    <ExcludedManagerCurrentExcludesComponent excludeRows={excludeRows} isLoading={isLoading}
                                                             currentExcludesColumns={currentExcludesColumns}/>}

            </CardContent>
        </Card>
        <Box sx={{marginTop: 3}}/>
        <Card>
            <CardHeader title="Logs"/>
            <Divider/>
            <CardContent>
                {isLoading == true && <LinearProgress
                    sx={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginTop: 2,
                        justifySelf: "center"
                    }}/>}
                {excludeRows != null && isLoading == false && currentExcludesColumns != null &&
                    <ExcludeManagerLogsComponent
                        excludeLogRows={excludeLogRows}
                        isLoading={isLoading}
                        currentExcludesColumns={currentExcludesColumns}
                    />
                }

            </CardContent>
        </Card>
        <Dialog
            //disableEnforceFocus required to open chat if Dialog opened first
            disableEnforceFocus
            open={confirmActionOpen}
            onClose={handleConfirmClose}
            aria-labelledby="card-exclude-dialog"
            fullWidth
            maxWidth="md"
        >
            {dialogBody()}
        </Dialog>
    </Box>
}

let dialogBody = () => {
    return (
        <div>
        </div>
    )
};

// Just some utility functions below here

// BuildColumnObject: Uses passed parameters to create and then return a GridColDef object
function BuildColumnObject(key: string,
                           value: any,
                           members: Member[],
                           hideSortIcons: boolean,
                           hideByDefault: boolean,
                           contentAlign: GridAlignment,
                           setRemoveOneRequest: (request: RemoveOneRequest) => void,
                           handleConfirmDialogue: () => void,
                           colMapWithRef: ColumnMappingDictionaryWithData): GridColDef {
    return {
        field: key,
        type: GetColType(key, value.toString()),
        headerName: makeHeaderString(key),
        description: makeHeaderString(key),
        flex: key == "filter" ? 3 : 1,
        // flex: GetColFlex(key, value.toString()),
        sortingOrder: ['desc', 'asc'],
        align: contentAlign != null ? contentAlign : "left",
        hideSortIcons: hideSortIcons,
        hide: hideByDefault,
        renderCell: (params) => <RenderCell params={params} members={members} setRemoveOneRequest={setRemoveOneRequest}
                                            handleConfirmDialogue={handleConfirmDialogue}
                                            colMapReference={colMapWithRef}/>
    }
}

// GetColType: Returns whether the column is a date, number or string
const GetColType = (k: string, v: string) => {
    if (k == "date_actioned") {
        return "date"
    }
    return "string"
}

// makeHeaderString: Formats the field header to display without camelCase or snake_case
// Can also change what is displayed based on specific field names
const makeHeaderString = (s: string) => {
    if (s == "user_id") {
        return "User"
    }

    s = s.replace(/([A-Z])/g, ' $1').trim()
    s = s.replaceAll("_", " ")
    s = s.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
    return s
}

interface RendCellProps {
    params: GridRenderCellParams
    members: Member[]
    setRemoveOneRequest: (request: RemoveOneRequest) => void
    handleConfirmDialogue: () => void
    colMapReference: ColumnMappingDictionaryWithData
}

// RenderCell: Handles the rendering of the DataGrid cells based on passed values
const RenderCell: FC<RendCellProps> = (props) => {
    // console.log("props", props)
    const mainCtx = useMainContext()
    if (props.params.value == "" || props.params.value == null) {
        return <Tooltip title={"empty"}>
            <div>-</div>
        </Tooltip>
    }

    if (props.params.field == "action" && props.params.value == "CanDelete") {

        return <Button key={props.params.id + "_deleteButton"}
                       sx={{
                           width: "50%",
                       }}
                       variant={"outlined"}
                       onClick={() => {

                           // set the request
                           props.setRemoveOneRequest({
                               orgName: props.params.row.org_name,
                               valueChange: props.params.row.filter,
                               header: "Can Remove this field i think",
                               userID: props.params.row.user_id // this is probs wrong
                           })
                       }
                       }>Delete</Button>
    }


    if (props.params.field == "filter") {
        let tempFilter = JSON.parse(props.params.value)
        let tempMap = props.colMapReference
        tempMap.PopulateWithString(props.params.value)
        return <Tooltip title={PrettyPrintColMapWithData(tempMap)}>
            <Box sx={{display: "flex", whiteSpace: "pre-wrap"}}>
                <div> {tempMap.PrettyPrint()} </div>
            </Box>
        </Tooltip>
    }


    // assignTo custom cell
    if (props.params.field == "user_id" && mainCtx.members) {
        // const mainCtx = useMainContext()

        // console.log(mainCtx.members.find(o => o.id == props.params.value))
        let displayMember = mainCtx.members.find(o => o.id == props.params.value)
        if (displayMember == undefined) {
            return <Tooltip title={"UserID:" + props.params.value}>
                <Box>User not found.</Box>
            </Tooltip>
        }

        return <Tooltip
            title={<div>Name: {displayMember.displayName}<br/>
                Email: {displayMember.email}</div>}
            key={`Tooltip_${displayMember.id}`}
        >
            <div>
                <CardHeader
                    avatar={
                        <Avatar
                            src={displayMember.avatar}
                            alt={displayMember.displayName}
                            variant={"circular"}
                            sx={{width: 24, height: 24}}
                        />
                    }
                    title={displayMember.displayName} disableTypography={true}
                    sx={{padding: 0}}
                    key={`AssignedUser_${displayMember.id}`}
                >
                </CardHeader>
            </div>
        </Tooltip>
    }

    // Custom Cell for dates
    if (props.params.field == "date_actioned") {
        return <Tooltip title={GetCellTooltip(props.params, props.members)}>
            <Box sx={{display: "flex"}}>
                <CalendarTodayOutlined fontSize={"small"} color={"info"}
                                       sx={{mr: 1}}/>
                <div> {props.params.value} </div>
            </Box>
        </Tooltip>
    }

    // Basic Cell
    return <Tooltip title={GetCellTooltip(props.params, props.members)}>
        <div> {props.params.value} </div>
    </Tooltip>
}

// GetCellTooltip: Creates custom or default tooltip titles based on passed props
const GetCellTooltip = (props, members?: Member[], currencySymbol?: string) => {
    return <div>{makeHeaderString(props.field)}:<br/>{props.value}</div>
}

// dateConverter: Returns amount of minutes that has passed from the start date to the end date
const dateConverter = (startDate, timeEnd) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    const one_day = 1000 * 60;
    let result
    result = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / (one_day))
    // console.log('date Converter result', result)
    // if (result < 0) {
    //     return 0
    // }
    return result
}

// PrettyPrintColMapWithData : Prints the passed object for the table
const PrettyPrintColMapWithData: FC<ColumnMappingDictionaryWithData> = (PrintMe: ColumnMappingDictionaryWithData) => {
    return <Box sx={{
        whiteSpace: "pre-wrap",
        // height: "10vh",
        // overflowY: "scroll"
    }}>
        {PrintMe.PrettyPrint()}
    </Box>
}

export default ExcludeManager;