import {FC, useEffect} from "react";
import {Box, Fade, Grid, Skeleton} from "@mui/material";

const Loader: FC = () => {

    useEffect(() => {
    }, []);


    return (
        <Box
            sx={{
                display: "flex",
                flexGrow: 1,
                flexShrink: 1,
                overflowX: "auto",
                overflowY: "hidden",
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    px: 1,
                    py: 3
                }}
            >

                <Grid container spacing={3}>
                    <Grid item xs={3} sx={{
                        textAlign: 'center',
                        display: 'block'
                    }}>
                        <div style={{
                            width: '200px',
                            height: '400px'
                        }}>
                            <Skeleton variant="rectangular" animation="wave" width="100%" height="100%"/>
                        </div>
                    </Grid>
                    <Grid item xs={3} sx={{
                        textAlign: 'center',
                        display: 'block'
                    }}>
                        <div style={{
                            width: '200px',
                            height: '400px'
                        }}>
                            <Skeleton variant="rectangular" animation="wave" width="100%" height="100%"/>
                        </div>
                    </Grid>
                    <Grid item xs={3} sx={{
                        textAlign: 'center',
                        display: 'block'
                    }}>
                        <div style={{
                            width: '200px',
                            height: '400px'
                        }}>
                            <Skeleton variant="rectangular" animation="wave" width="100%" height="100%"/>
                        </div>
                    </Grid>
                    <Grid item xs={3} sx={{
                        textAlign: 'center',
                        display: 'block'
                    }}>
                        <div style={{
                            width: '200px',
                            height: '400px'
                        }}>
                            <Skeleton variant="rectangular" animation="wave" width="100%" height="100%"/>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Loader;
