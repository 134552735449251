import type {FC} from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import {
  Alert,
  Box,
  Slider,
  Grid,
  Card,
  CardHeader,
  CardContent, Divider
} from "@mui/material";
import { useTheme, ThemeProvider, Theme } from "@mui/material/styles";
import currencySwitcher from "../../../../../../utils/currencySwitcher";
import { Member } from "../../../../../../cog/keystone/member";

interface LandingGoalsProps {
  actual: number;
  target?: number;
  areaData: {};
  areaLabelValue: string;
  barData: {};
  org: string;
  members: Member[];
}

const LandingPageGoals: FC<LandingGoalsProps> = ({actual,  org, areaData, areaLabelValue, barData, members}) => {
  const theme = useTheme();
  const target = 50000;
  const percentage = Math.round(actual * 100 / target);
  let formatter = Intl.NumberFormat('en', {notation: 'compact'})

  const areaColumnName = areaLabelValue
  const areaSeriesCols = Object.keys(areaData).filter((k) => k != areaColumnName)
  const areaSeries = areaSeriesCols ? areaSeriesCols.map((c) => {
    return {
      name: c,
      data: areaData && areaData[c] ? areaData[c] : []
    }
  }) : [{name: "y", data: []}]


  let barSeries = []
  if (barData && barData["f0_"]) {
    barData["f0_"].map((user, index) => {
      let barDatapoint = {
        name: user,
        data: barData && barData["Value"] ? [barData["Value"][index]] : [0]
      }
      barSeries.push(barDatapoint)
    })
  }

  areaSeries.unshift(areaSeries.pop())

  const barChartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      stacked: true,
      stackType: '100%',
      // background: 'transparent'
    },
    colors: ["#00e396", "#feb019", "#ff6178", "#775dd0"],
    dataLabels: {
      formatter: function (val, opt) {
        return currencySwitcher(org) + formatter.format(barData["Value"][opt.seriesIndex])
      },
    },
    legend: {
      show: false
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    theme: {
      mode: theme.palette.mode
    },
    fill: {
      opacity: 1
    },
    xaxis: {
      categories: ["Week"],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      show: false
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return currencySwitcher(org) + formatter.format(val)
        }
      }
    },
  };

  const areaChartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ["#008ffb"],
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'bottom',
    },
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100]
      },
      type: 'gradient'
    },
    grid: {
      // show: false,
      padding: {
        bottom: 0,
        left: 20,
        right: 0,
        top: 0
      }
    },
    stroke: {
      width: 2
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      title: {
        text: "Days Last Month",
      },
    },
    yaxis: {
      title: {
        text: "Opportunities Closed",
      },
    }
  };

  let color = ''
  if ( percentage >= 80) {
    color = '#00e396'
  } else if ( percentage < 80 && percentage >= 50 ) {
    color = '#feb019'
  } else {
    color = '#ff4560'
  }

  const marks = [
    {
      value: 0,
      label: currencySwitcher(org) + formatter.format(0),
    },
    {
      value: 50,
      label: currencySwitcher(org) + formatter.format(target * 0.5),
    },
    {
      value: 100,
      label: currencySwitcher(org) + formatter.format(target),
    },
  ];

  return (
    <Box sx={{
      flexGrow: 1,
      marginBottom: 4,
    }}>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        justifyContent="center"
        spacing={2}
      >
        <Grid item lg={4} sm={12}>
          <Card>
            <CardHeader title={"Goal Progress"} />
            <Divider />
            <CardContent>
              <Box sx={{ height: 165, alignItems: "center", justifyContent: "center"}}>
                <Slider
                  sx={{
                    width: "80%",
                    color: color,
                    marginLeft: 4,
                    marginTop: 10,
                    '&.Mui-disabled': {
                      color: color,
                    },
                    '& .MuiSlider-markLabel': {
                      fontSize: 20
                    },
                    '& .MuiSlider-valueLabel': {
                      lineHeight: 1.2,
                      fontSize: 12,
                      fontWeight: 700,
                      background: 'unset',
                      padding: 0,
                      width: 60,
                      height: 60,
                      borderRadius: '50% 50% 50% 0',
                      backgroundColor: color,
                      transformOrigin: 'bottom left',
                      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                      '&:before': { display: 'none' },
                      '&.MuiSlider-valueLabelOpen': {
                        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                      },
                      '& > *': {
                        transform: 'rotate(45deg)',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: color,
                      },
                    },
                  }}
                  defaultValue={percentage}
                  valueLabelDisplay="on"
                  valueLabelFormat={currencySwitcher(org) + formatter.format(actual)}
                  marks={marks}
                  disabled
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} sm={12}>
          <Card>
            <CardHeader title={"Opportunity Closure Rate"} />
            <Divider />
            <CardContent>
              {Object.keys(areaData).length > 0 && areaSeries.length > 0 && areaData["Number_of_Opportunities"][0] !== 0 && !areaData["Number_of_Opportunities"].every((val, i, arr) => val === arr[0]) ?
                <Chart
                  type="area"
                  options={areaChartOptions}
                  height={150}
                  series={areaSeries}
                /> : <Alert severity="info" sx={{ height: 165, alignItems: "center", justifyContent: "center"}}>
                  No Opportunities Closed
                </Alert>
              }
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} sm={12}>
          <Card>
            <CardHeader title={"Weekly Contribution"} />
            <Divider />
            <CardContent>
              {barSeries.length > 0 ?
                <Chart
                  type="bar"
                  options={barChartOptions}
                  height={150}
                  width={"100%"}
                  series={barSeries}
                /> : <Alert severity="success" sx={{ height: 165, alignItems: "center", justifyContent: "center"}}>
                  No User Contributions
                </Alert>
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPageGoals;