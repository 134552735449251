import * as React from 'react';
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { useMainContext } from "../../../../../../contexts/MainContext";
import _ from "lodash";
import {useKeystoneContext} from "../../../../../../contexts/KeystoneContext";
import {useNavigate} from "react-router-dom";
import {FC} from "react";
import {Member} from "../../../../../../cog/keystone/member";

interface ExploreStoreSwitchTable {
    chartName:string
    statRows:any
    currentMembers? : Member[]
}
const ExploreStoreSwitchTable: FC<ExploreStoreSwitchTable> = ({ chartName, statRows, currentMembers}) => {
    const mainCtx = useMainContext();
    const keystoneCtx = useKeystoneContext();
    const navigate = useNavigate();
    let rows = [];

    const formatCol = (k: string) => {
        return {
            field: k,
            headerName: k.replaceAll("_", " "),
            width: 150,
            flex: 1
        }
    };

    const cols = Object.keys(statRows).map((k) => {
        return formatCol(k)
    })

    const getUserDisplay = (userIndex:number) => {

        if(currentMembers && currentMembers.length>0){
            let tempMember = currentMembers.at(userIndex)
            if(tempMember){
                return tempMember['displayName']
            }else {
                return "InvalidUser"
            }
        }


    }

    if (Object.keys(statRows).length === 0) {
        rows = [{ id: 0, NoData: 'Query returned no results' }];
    } else {
        _.range(statRows[Object.keys(statRows)[0]].length).map((idx) => {
            let row: { [key: string]: any } = { id: idx + 1 };
            Object.keys(statRows).map((k) => {
                if(k == 'Username'){
                    row[k] = getUserDisplay(idx)
                }else{
                    row[k] = statRows[k][idx];
                }
            });
            rows.push(row);
        });
    }
    const updateFilter = (org: string, chartName: string, column: string, params: any) => {

        const formattedColumn = column.toString().replace(/_/g, ' ');
        const statusID = keystoneCtx.statusCardOptions[formattedColumn]
        const area3Header =  keystoneCtx.colMap.Area_Hierarchy_3;

        const dataSeries = Object.keys(statRows).filter(item => item !== 'Store' && item !== 'Username');

        let filters = [];

        if(currentMembers) {
            if(currentMembers[params.id-1]){
                filters.push({
                    header: "assigned_to",
                    operation: "=",
                    prefix: "AND",
                    values: currentMembers[params.id-1].id
                })
            }

        }else {
            filters.push({
                header: area3Header,
                operation: "=",
                prefix: "AND",
                values: params.row['Store']
            })

        }
        if(dataSeries && dataSeries.length > 1 && params.field != 'Username' && params.field != 'Store'){

            filters.push({
                header: 'list_id',
                operation: "=",
                prefix: "AND",
                values: statusID
            })
        }


        keystoneCtx.setClickThroughFilters(filters);
        keystoneCtx.RefreshCards();
        navigate("/app/" + org.toLowerCase() + "/opportunities");
    };

    const handleCellDoubleClick = (params: any) => {
        const cellValue = params.value;
        const org = mainCtx.activeOrganization.name.replace(/ |_/g, '-');

        if (cellValue) {
            const column = params.colDef.field;// Get the field or column name that was clicked
            updateFilter(org, chartName, column, params);
        }
    };

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <DataGridPro
                columns={cols}
                rows={rows}
                pageSize={5}
                rowsPerPageOptions={[5]}
                pagination={true}
                onCellDoubleClick={handleCellDoubleClick}
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </Box>
    );
};

export default ExploreStoreSwitchTable;