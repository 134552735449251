const StoreRanking = (props) => {
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Business_Icons" data-name="Business Icons" version="1.1" viewBox="0 0 203 203" height={height}>
            <defs id="defs42">
                <linearGradient id="StoreRankingBlob" x1={60} x2="160.2" y1="150.6" y2="50.4" gradientUnits="userSpaceOnUse">
                    <stop id="stop4" offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="StoreRankingLine" x1="1.1" x2="5.3" y1="117.8" y2="113.6" gradientUnits="userSpaceOnUse">
                    <stop id="stop9" offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-2" x1="121.7" x2="125.9" y1="5.1" y2=".9" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-3" x1="197.8" x2="202.1" y1="174.7" y2="170.5" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-4" x1="29.6" x2="31.6" y1="29.4" y2="27.5" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-5" x1="31.8" x2="33.7" y1="27.3" y2="25.4" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-6" x1={34} x2="35.9" y1="29.4" y2="27.5" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-7" x1="31.8" x2="33.7" y1="31.5" y2="29.6" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-8" x1="180.1" x2={182} y1="64.4" y2="62.4" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-9" x1="182.3" x2="184.2" y1="62.2" y2="60.3" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-10" x1="184.4" x2="186.4" y1="64.3" y2="62.4" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-11" x1="182.3" x2="184.2" y1="66.5" y2="64.5" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-12" x1="74.9" x2="76.9" y1="200.4" y2="198.4" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-13" x1="77.1" x2={79} y1="198.2" y2="196.3" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-14" x1="79.3" x2="81.2" y1="200.3" y2="198.4" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-15" x1="77.1" x2={79} y1="202.5" y2="200.5" />
                <linearGradient id="storeRankingFill" x1="84.1" x2="130.3" y1="139.1" y2="92.9" gradientUnits="userSpaceOnUse">
                    <stop id="stop28" offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#storeRankingFill" id="storeRankingFill-2" x1="96.4" x2="106.7" y1="67.8" y2="57.4" />
                <linearGradient xlinkHref="#storeRankingFill" id="storeRankingFill-3" x1="64.9" x2={89} y1={71} y2="46.9" />
                <linearGradient xlinkHref="#storeRankingFill" id="storeRankingFill-4" x1={75} x2="108.7" y1="176.8" y2="143.1" />
                <linearGradient xlinkHref="#storeRankingFill" id="storeRankingFill-5" x1="85.3" x2={123} y1="167.8" y2="130.2" />
                <linearGradient xlinkHref="#storeRankingFill" id="storeRankingFill-6" x1="34.5" x2="62.9" y1={113} y2="84.5" />
                <linearGradient xlinkHref="#storeRankingFill" id="storeRankingFill-7" x1="117.7" x2="137.9" y1="69.1" y2="48.9" />
                <linearGradient xlinkHref="#storeRankingFill" id="storeRankingFill-8" x1="141.9" x2="170.3" y1={113} y2="84.5" />
                <linearGradient xlinkHref="#storeRankingFill" id="storeRankingFill-9" x1="97.7" x2="107.2" y1="58.4" y2="48.9" />
                <linearGradient xlinkHref="#StoreRankingLine" id="StoreRankingLine-16" x1={42} x2="162.8" y1="162.5" y2="41.7" />
                <style id="style2" dangerouslySetInnerHTML={{__html: "\n            .storeRankingStroke1,.storeRankingStroke2{fill:url(#storeRankingFill);stroke:url(#StoreRankingLine);stroke-width:3;stroke-miterlimit:10}.storeRankingStroke2{fill:none;stroke-linecap:round;stroke-linejoin:round}.storeRankingStroke3{fill:#fff}.storeRankingStroke4,.storeRankingStroke8{fill:#fff;stroke:url(#StoreRankingLine);stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.storeRankingStroke8{fill:url(#storeRankingFill)}.storeRankingStroke9{fill:url(#StoreRankingBlob);stroke-width:.52992}\n        " }} />
            </defs>
            <switch id="switch4272" transform="translate(-3583 -4137.5)">
                <g id="g4270">
                    <g id="Expert_service">
                        <path id="Icon_background_41_-3" d="M3784.3 4207.8c3.7-16-6.3-32-22.3-35.6h-.6c-1.7-.6-3.5-.8-5.3-1a26.5 26.5 0 0 0-15.6 4.2c-1.5.8-2.9 1.8-4.1 2.9-4.7 3-10.3 4.5-15.8 4.4h-.2c-4.6-.2-9-2.3-11.7-5.9a51.7 51.7 0 0 0-39.2-19.8 52.7 52.7 0 0 0-53.8 51.5v.5c-.1 7.2-4 13.7-10.2 17.2a42.7 42.7 0 0 0 20.4 79.7c6.4 0 12.5-1.5 18-4 6.4-3.1 14.1-1.4 18.7 4 7.2 8.5 17.8 14 29.7 14 21.4.3 39.2-16.9 40-38.5l.1-2.3c2-16.4 5-26.7 9.4-34.3a31.7 31.7 0 0 1 20.3-14.8 29.6 29.6 0 0 0 22.2-22.2z" className="storeRankingStroke9" />
                        <g id="Main_icon_14_-6" transform="matrix(.53 0 0 .53 2269 3044.8)">
                            <g id="group_78_-7">
                                <g id="group_80_-5">
                                    <path id="contour_754_-3" d="M2756.6 2134.8v202.4l-49.4 52.6h-150.9c-6.8 0-12.3-5.5-12.3-12.3v-242.7c0-6.8 5.5-12.3 12.3-12.3h187.9c6.9 0 12.4 5.5 12.4 12.3z" className="storeRankingStroke8" />
                                    <path id="contour_753_-5" d="M2756.6 2134.8v202.4c-4.2 4.5-10.2 11.1-18.6 19.8-226.4 0-154.8-234.4-154.8-234.4h161.1c6.8-.1 12.3 5.4 12.3 12.2z" className="storeRankingStroke3" />
                                    <path id="contour_752_-6" d="M2756.6 2134.8v202.4l-49.4 52.6h-150.9c-6.8 0-12.3-5.5-12.3-12.3v-242.7c0-6.8 5.5-12.3 12.3-12.3h187.9c6.9 0 12.4 5.5 12.4 12.3z" className="storeRankingStroke2" />
                                    <path id="contour_751_-2" d="M2707.2 2389.8v-45.1c0-4.2 3.4-7.5 7.5-7.5h41.8z" className="storeRankingStroke4" />
                                </g>
                                <g id="group_79_-9">
                                    <path id="contour_750_-1" d="M2625.8 2163H2571a3.1 3.1 0 0 1-3.1-3.1v-8.2c0-1.7 1.4-3.1 3.1-3.1h54.8c1.7 0 3.1 1.4 3.1 3.1v8.2c0 1.7-1.4 3.1-3.1 3.1z" className="storeRankingStroke2" />
                                    <path id="contour_749_-2" d="M2625.8 2191.7H2571a3.1 3.1 0 0 1-3.1-3.1v-8.2c0-1.7 1.4-3.1 3.1-3.1h54.8c1.7 0 3.1 1.4 3.1 3.1v8.2c0 1.7-1.4 3.1-3.1 3.1z" className="storeRankingStroke2" />
                                    <path id="contour_748_-7" d="M2617.6 2220.4H2571a3.1 3.1 0 0 1-3.1-3.1v-8.2c0-1.7 1.4-3.1 3.1-3.1h46.7c1.7 0 3.1 1.4 3.1 3.1v8.2a3.2 3.2 0 0 1-3.2 3.1z" className="storeRankingStroke2" />
                                    <path id="contour_747_-0" d="M2625.8 2249.2H2571a3.1 3.1 0 0 1-3.1-3.1v-8.2c0-1.7 1.4-3.1 3.1-3.1h54.8c1.7 0 3.1 1.4 3.1 3.1v8.2c0 1.7-1.4 3.1-3.1 3.1z" className="storeRankingStroke2" />
                                    <path id="contour_746_-9" d="M2608.5 2277.9H2571a3.1 3.1 0 0 1-3.1-3.1v-8.2c0-1.7 1.4-3.1 3.1-3.1h37.5c1.7 0 3.1 1.4 3.1 3.1v8.2c0 1.7-1.4 3.1-3.1 3.1z" className="storeRankingStroke2" />
                                    <path id="contour_745_-3" d="M2731.6 2306.7h-46.9a2.6 2.6 0 0 1-2.6-2.6v-9.1c0-1.5 1.2-2.6 2.6-2.6h46.9c1.5 0 2.6 1.2 2.6 2.6v9.1c.1 1.4-1.1 2.6-2.6 2.6z" className="storeRankingStroke2" />
                                    <path id="contour_744_-6" d="M2669.7 2277.9h-46.9a3.1 3.1 0 0 1-3.1-3.1v-8.2c0-1.7 1.4-3.1 3.1-3.1h46.9c1.7 0 3.1 1.4 3.1 3.1v8.2c-.1 1.7-1.4 3.1-3.1 3.1z" className="storeRankingStroke2" />
                                    <path id="contour_743_-0" d="M2669.4 2306.7h-98.2a3.3 3.3 0 0 1-3.3-3.3v-7.6c0-1.8 1.5-3.3 3.3-3.3h98.2c1.8 0 3.3 1.5 3.3 3.3v7.6c0 1.8-1.5 3.3-3.3 3.3z" className="storeRankingStroke2" />
                                    <path id="contour_742_-6" d="M2687.5 2335.4h-116.9a2.7 2.7 0 0 1-2.7-2.7v-8.9c0-1.5 1.2-2.7 2.7-2.7h116.9c1.5 0 2.7 1.2 2.7 2.7v8.9c0 1.5-1.2 2.7-2.7 2.7z" className="storeRankingStroke2" />
                                    <path id="contour_741_-2" d="M2599.5 2364.1H2571a3.1 3.1 0 0 1-3.1-3.1v-8.2c0-1.7 1.4-3.1 3.1-3.1h28.5c1.7 0 3.1 1.4 3.1 3.1v8.2c-.1 1.8-1.4 3.1-3.1 3.1z" className="storeRankingStroke2" />
                                    <path id="contour_740_-6" d="M2687.1 2364.1h-72a3.1 3.1 0 0 1-3.1-3.1v-8.2c0-1.7 1.4-3.1 3.1-3.1h72c1.7 0 3.1 1.4 3.1 3.1v8.2a3 3 0 0 1-3.1 3.1z" className="storeRankingStroke2" />
                                </g>
                            </g>
                            <g id="group_77_-1">
                                <path id="contour_739_-8" d="m2716 2201.4 2.2.8-.5 1.6-1.7 4.4-18.6 50.3-.1.1-14.4-14.6-21.5 1.2 20.9-56.4z" className="storeRankingStroke1" />
                                <path id="contour_738_-7" d="m2714.7 2204.6-.9-2.4 2.2-.8 33.8-12.6 3.3 8.9 16.9 45.6.7 1.9-21.6-1.2-14.4 14.6-7.3-19.6-11.4-30.8z" className="storeRankingStroke8" />
                                <path id="contour_737_-9" d="M2770 2243.3c-29.9-6.3-27.8-34-27.8-34l-1.8-6.7 12.3-4.9h.3z" className="storeRankingStroke3" />
                                <path id="contour_736_-2" d="m2717.7 2203.8-1.6 4.4-18.6 50.3c-16.7-19 1.2-49.6 1.2-49.6l16.1-4.3z" className="storeRankingStroke3" />
                                <path id="contour_735_-0" d="m2716 2201.4 2.2.8-.5 1.6-1.7 4.4-18.6 50.3-.1.1-14.4-14.6-21.5 1.2 20.9-56.4z" style={{fill: 'none', stroke: 'url(#StoreRankingLine)', strokeWidth: 3, strokeMiterlimit: 10}} />
                                <path id="contour_734_-2" d="m2714.7 2204.6-.9-2.4 2.2-.8 33.8-12.6 3.3 8.9 16.9 45.6.7 1.9-21.6-1.2-14.4 14.6-7.3-19.6-11.4-30.8z" className="storeRankingStroke2" />
                                <path id="contour_733_-3" d="M2692.3 2213.9s-2.8-10.4-15.8-9.5c-13 .9-11.2-5.8-10.6-12.5.6-6.7-2.4-12.1-9.1-14.7-6.7-2.6-9.7-6-4.1-14.1 5.6-8.2 1.1-17.5-1.7-22s1.1-8.7 7.1-11c5.1-1.9 7.8-5.9 8.2-12.8.1-1.6 0-3.3-.2-4.9-1.2-8.4 7.9-8 10.8-7.6 1.9.2 3.8.2 5.6-.3 3.2-.9 7.3-3.3 9.9-9.3 4.8-11.2 14-3.5 14-3.5s8.7 6.7 18.1.2c9.4-6.5 14 .9 15.6 4.5 1.7 3.5 3.9 8.9 15.1 7.8 11.2-1.1 11.9 8.4 11.2 13a14.5 14.5 0 0 0 8.7 14.5c6.5 2.6 9.1 6.5 2.6 15.6-2.2 3-2.4 6.3-1.9 9.1a15 15 0 0 0 3.7 7.2c2.7 3 6.6 9.6-4.6 15.2-6.3 3.3-8.9 6-9.1 14.5-.2 8.6 1.1 11.7-12.7 11.7-6.5-.4-10.2 2.4-13.6 8.9-3.3 6.5-8 8-16.7 2.4s-16.9 1.9-16.9 1.9-10.1 7.2-13.6-4.3z" className="storeRankingStroke1" />
                                <circle id="contour_732_-7" cx="2715.6" cy="2153.8" r="48.9" className="storeRankingStroke4" />
                                <path id="contour_731_-5" d="m2777.7 2101.1-7.5-5.8a6.5 6.5 0 0 0-9.2 1.2l-44.7 58.4-18.3-19.5a7.2 7.2 0 0 0-10.3-.4l-5.9 5.4a7.2 7.2 0 0 0-.4 10.3l27.3 29.2a7.2 7.2 0 0 0 5.9 2.3h6.2a7 7 0 0 0 5.1-2.6l53.1-69.5a6.4 6.4 0 0 0-1.3-9z" className="storeRankingStroke8" />
                            </g>
                        </g>
                        <g id="Icon_backgroun_element-9" transform="matrix(.53 0 0 .53 2269 3044.8)">
                            <path id="contour_730_-2" d="m2586.3 2417.3 4.4 2.4 2.4 4.4 2.4-4.4 4.4-2.4-4.4-2.4-2.4-4.4-2.4 4.4z" className="storeRankingStroke2" />
                            <path id="contour_729_-2" d="m2565.6 2086.3 7.1 3.9 3.8 7.1 3.9-7.1 7.1-3.9-7.1-3.8-3.9-7.1-3.8 7.1z" className="storeRankingStroke2" />
                            <path id="contour_728_-8" d="m2620.1 2071.2 4.4 2.4 2.4 4.4 2.4-4.4 4.4-2.4-4.4-2.4-2.4-4.5-2.4 4.5z" className="storeRankingStroke2" />
                            <path id="contour_727_-9" d="m2796.8 2290.6 7.1 3.9 3.9 7 3.8-7 7.1-3.9-7.1-3.9-3.8-7-3.9 7z" className="storeRankingStroke2" />
                            <path id="contour_726_-7" d="m2498.8 2194.4 7.4 4 4 7.3 4-7.3 7.3-4-7.3-4-4-7.4-4 7.4z" className="storeRankingStroke2" />
                            <path id="contour_725_-3" d="m2819.6 2197.4 4.5 2.4 2.4 4.4 2.4-4.4 4.4-2.4-4.4-2.4-2.4-4.4-2.4 4.4z" className="storeRankingStroke2" />
                        </g>
                    </g>
                </g>
            </switch>
        </svg>

    )

}
export default StoreRanking