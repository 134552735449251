import {FC, useContext} from "react";

import * as React from "react";
import {Box, Grid} from "@mui/material";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import SettingsContext from "../../../../contexts/SettingsContext";



export interface DashboardSkeletonProps {

}

const DashboardSkeleton: FC<DashboardSkeletonProps> = ({}) => {
    const ctx = useContext(SettingsContext)
    return (
        <Grid item xs={8}>
            <Stack spacing={1}>
                <div>
                    <Box
                        sx={{
                            textAlign: 'center',
                            display: 'flex',
                            px: 'center',
                            m: 5,
                            flexDirection: {xs: 'column', md:'row'}
                        }}>

                        <Skeleton variant="rectangular" width={450} height={150} sx={{mx: 5, borderRadius: '16px'}}/>
                        <Skeleton variant="rectangular" width={450} height={150} sx={{mx: 5, borderRadius: '16px'}}/>
                        <Skeleton variant="rectangular" width={450} height={150} sx={{mx: 5, borderRadius: '16px'}}/>

                    </Box>
                    <Box
                        sx={{
                            textAlign: 'center',
                            display: 'flex',
                            px: 'center',
                            m: 5,
                            flexDirection: {xs: 'column', md:'row'},
                        }}>

                        <Skeleton variant="rectangular" width={450} height={150} sx={{mx: 5, borderRadius: '16px'}}/>
                        <Skeleton variant="rectangular" width={450} height={150} sx={{mx: 5, borderRadius: '16px'}}/>
                        <Skeleton variant="rectangular" width={450} height={150} sx={{mx: 5, borderRadius: '16px'}}/>

                    </Box>
                    <Skeleton variant="rectangular" width={1500} height={450}
                              sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mx:3,
                                  flexDirection: {xs: 'column', md:'row'},
                                  maxWidth:'auto',
                                  borderRadius: '16px',
                              }}/>
                </div>
            </Stack>
            {/*<Skeleton animation="wave"/>*/}

        </Grid>
    )
}
export default DashboardSkeleton