import React, {FC} from "react";
import {Box} from "@mui/material";
import TopBar from "./topbar/TopBar";
import {useKeystoneContext} from "../../contexts/KeystoneContext";

export interface ToolbarProps {

}

const ToolbarContainer: FC<ToolbarProps> = (props) => {
    const keystoneCtx = useKeystoneContext()
    return <Box
        sx={{
            // border: "1px red solid",
            // height: "5vh",
            // width: "50vw",
            marginX: 1,
            // padding: 1
        }}
    >
        {keystoneCtx.toolBarDisplayOptions != null && ((window.location.pathname).includes('opportunities') || (window.location.pathname).includes('explore') || (window.location.pathname).includes('reporting'))&& //!keystoneCtx.loadingBar &&
            <TopBar></TopBar>
        }

    </Box>
}

export default ToolbarContainer;