import type {FC} from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import {Box, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {ApexOptions} from "apexcharts";
import currencyFormatter from "../../../../../../utils/currencyFormatter";

interface StatsBarAreaProps {
  data: {};
  labelValue: string;
  org: string;
}

const StatsBarArea: FC<StatsBarAreaProps> = ({data, org, labelValue}) => {
  const chartType = "area"
  const theme = useTheme();

  const columnName = labelValue
  const seriesCols = Object.keys(data).filter((k) => k != columnName)
  const series = seriesCols ? seriesCols.map((c) => {
    return {
      name: c,
      data: data && data[c] ? data[c] : []
    }
  }) : [{name: "y", data: []}]

  series.unshift(series.pop())

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ["#008ffb"],
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'bottom',
    },
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100]
      },
      type: 'gradient'
    },
    grid: {
      // show: false,
      padding: {
        bottom: 0,
        left: 20,
        right: 0,
        top: 0
      }
    },
    stroke: {
      width: 2
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      title: {
        text: "Days Last Month",
      },
    },
    yaxis: {
      title: {
        text: "Opportunities Closed",
      },
    }
  };

  return (
    <Box sx={{height: 170}}>
      <Chart
        type={chartType}
        height={170}
        width={400}
        series={series}
        options={chartOptions}

      />
    </Box>
  );
};

export default StatsBarArea;