import React, {useEffect, useState} from "react";
import {
    CardHeader,
    Card,
    Container,
    Box,
    Grid,
    Button,
    ThemeProvider, Typography, Tooltip, IconButton
} from "@mui/material";
import GoalsDataGrid from "./goalDataGrid/GoalsDataGrid";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {GoalStore} from "../../../../cog/keystone/goals";
import {CampaignStore} from "../../../../cog/keystone/goals/campaigns";
import {useMainContext} from "../../../../contexts/MainContext";
import Goal from "../../../../cog/keystone/goals/goal/Goal";
import CreateGoalDialog from "./goalDataGrid/dialogs/CreateGoalDialog";
import {Helmet} from "react-helmet-async";
import {Theme, useTheme} from "@mui/material/styles";
import GoalsPageSkeleton from "./GoalsPageSkeleton";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {useParams} from "react-router";
import {KeyboardBackspace} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const GoalsPage = () => {
    const ctx = useMainContext()
    const keystoneCtx = useKeystoneContext();
    let {campaignId, org} = useParams();
    let navigate = useNavigate();


    const [myTheme, setMyTheme] = useState<Theme>(useTheme())
    const [allGoals, setAllGoals] = useState<Goal[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [titleLoading, setTitleLoading] = useState<boolean>(true)
    const [title, setTitle] = useState<string>()

    let response
    const getGoals = async () => {
        setIsLoading(true)
        if (campaignId && campaignId != 'd2057666-6ec6-4682-9f1f-101c28dd3c2a') {
            response = await GoalStore.FindMany({campaignID: campaignId, clientName: ctx.activeOrganization.name})
        } else {
            response = await GoalStore.FindUnattached({clientName: ctx.activeOrganization.name})
        }
        if (response) {
            setAllGoals(response.goals)
        }
        setIsLoading(false)

    }

    const getCampaign = async () => {
        return await CampaignStore.FindOne({clientName: ctx.activeOrganization.name, id: campaignId})
    }

    const handleCampaignName = () => {
        if (campaignId && campaignId !== 'd2057666-6ec6-4682-9f1f-101c28dd3c2a') {
            return getCampaign().then(r => {
                setTitle(r.campaign.name + " Goals")
                setTitleLoading(false)
            })
        } else {
            setTitle("Unattached Goals")
            setTitleLoading(false)
            return
        }
    }

    const handleCampaignReturn = () => {
        navigate('/app/' + org + '/campaigns/')
    }

    useEffect(() => {
        getGoals()
        handleCampaignName()
    }, [keystoneCtx.flipSwitchForGoals])


    return (
        <>
            <Helmet>
                <title>Goals</title>
            </Helmet>
            {/*<ThemeProvider theme={myTheme}>*/}
                <Box sx={{flexGrow: 1}}>
                    <Container maxWidth={false}>
                        {!isLoading && ctx.members ? <Box sx={{mb: 2, mt: 2}}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >

                                <Grid item
                                      sm={12}>

                                    <Typography variant={"h4"}>
                                        <Tooltip title="Back To Campaigns">
                                            <IconButton color="primary"
                                                        onClick={handleCampaignReturn}>
                                                <KeyboardBackspace sx={{ color: (theme) => (theme.palette.mode === 'dark' ? "#f2bc53" : '#6b778c') }}/>
                                            </IconButton>
                                        </Tooltip>
                                        {!titleLoading && title}
                                    </Typography>
                                </Grid>
                                <Grid item
                                      sm={12}>
                                    <GoalsDataGrid goals={allGoals}
                                                   source={'direct'}/>
                                </Grid>
                            </Grid>
                        </Box> : <GoalsPageSkeleton/>}
                    </Container>
                </Box>
            {/*</ThemeProvider>*/}
        </>
    )
}

const Goals = () => {
    return <GoalsPage/>

}

export default Goals