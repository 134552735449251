import React, { FC } from "react";
import InsightWidgetSwitcher, { InsightWidgetProps} from "./InsightWidgetSwitcher";
import { Alert, AlertTitle, Box, Card, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface InsightCardProps {
  insightCardProps: InsightWidgetProps[]
}

const gridContainer = {
  gap: 1,
  display: "grid",
  gridAutoColumns: "1fr",
  gridAutoFlow: "row"
};

const gridItem = {
  // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
  // color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
  // border: '1px solid',
  // borderColor: (theme) =>
  //   theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
  p: 1,
  borderRadius: 2,
};

const InsightsCard: FC<InsightCardProps> = ({insightCardProps}) => {
  console.log("Insight configs: ", insightCardProps)
  console.log("Number of insights: ", insightCardProps.length.toString())

  const alertBox = () => {

    return (
      <Alert severity="error" variant={"filled"}
             sx={{
               marginTop: "5%",
               marginLeft: "35%",
               marginRight: "35%",
               whiteSpace: 'pre-wrap',
               display: "flex",
               textAlign: "justify",
               color: "white",
               // color: (theme) => alpha(theme.palette.primary.main,0.5),
               // backgroundColor: useTheme().palette.primary.main,
               backgroundColor: "#4889e3",
               // backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.7),
               // text: useTheme().palette.primary.contrastText
             }}>
        <Typography variant={"h6"} color={useTheme().palette.primary.contrastText}>There is no data for this insight</Typography>
      </Alert>
    );
  }

  return (
    <Box sx={gridContainer}>
      {insightCardProps.length > 0 ? insightCardProps.map((insightWidgetConfig, index) => {
        return (
          <Box key={index} sx={gridItem}>
            <InsightWidgetSwitcher {...insightWidgetConfig}/>
          </Box>
        );
      }) : alertBox()}
    </Box>
  );
}

export default InsightsCard;