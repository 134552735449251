import React, { FC } from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Title } from "@mui/icons-material";
import { useMainContext } from "../../../../../contexts/MainContext";
import OrgCard from "./OrgCard";

const DashboardChooserInterface: FC = () => {
  const mainCtx = useMainContext()
  const organizations = mainCtx.organizations

  return (
    <div style={{height: "100%", width: "100%"}}>
      <Box m={2}>
        <Typography variant="h3">
          Select an Organization:
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        m={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{height: "85vh", width: "100%"}}
      >
        {organizations.map((o) => (
          <OrgCard name={o.name} orgId={o.id} />
        ))}
      </Grid>
    </div>
  );
};

export default DashboardChooserInterface;