import React, {useLayoutEffect, useState} from "react";
import {Box, Container, Grid, Typography} from "@mui/material";
import GoalsDataGrid from "./goalDataGrid/GoalsDataGrid";
import {useMainContext} from "../../../../contexts/MainContext";
import {Helmet} from "react-helmet-async";
import {FindManyResponse} from "../../../../cog/keystone/goals/campaigns/Store";
import {CampaignStore} from "../../../../cog/keystone/goals/campaigns";
import CampaignDataGridDetail from "./campaigns/CampaignDataGridEmbed";
import {GoalStore} from "../../../../cog/keystone/goals";
import Goal from "../../../../cog/keystone/goals/goal/Goal";
import {Campaign, Flag} from "@mui/icons-material";
import {KeystoneAccessResponse} from "../../../../cog/adminManagement/Store";
import {AdminStore} from "../../../../cog/adminManagement";
import CampaignPageSkeleton from "./CampaignPageSkeleton";

const CampaignPage = () => {
    const ctx = useMainContext()
    const [campaigns, setCampaigns] = useState<FindManyResponse>({campaigns: []})
    const [unattachedGoals, setUnattachedGoals] = useState<Goal[]>([])
    const [isCampaignGoalsLoading, setIsCampaignGoalsLoading] = useState<boolean>(false)
    const [isUnattachedGoalsLoading, setIsUnattachedGoalsLoading] = useState<boolean>(false)
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [createOpen, setCreateOpen] = React.useState(false);

    const getCampaignGoals = async () => {
        setIsCampaignGoalsLoading(true)
        const response = await CampaignStore.FindMany({clientName: ctx.activeOrganization.name})
        if (response) {
            setCampaigns({...response})
        }
    }

    const handleUpdateLoaders = () => {
        setIsUnattachedGoalsLoading(true)
        setIsCampaignGoalsLoading(true)
    }

    const handleUpdate = () => {
        handleUpdateLoaders()
        getUnattachedGoals().then(() => setIsUnattachedGoalsLoading(false))
        getCampaignGoals().then(() => setIsCampaignGoalsLoading(false))
    }

    const getUnattachedGoals = async () => {
        setIsUnattachedGoalsLoading(true)
        const response = await GoalStore.FindUnattached({clientName: ctx.activeOrganization.name})
        if (response) {
            setUnattachedGoals((prevState) => [...response.goals])
        }
    }

    const checkAccess = async (): Promise<KeystoneAccessResponse> => {
        try {
            return AdminStore.CheckKeystoneAccess(
                {
                    user_id: ctx.user.id,
                    org_id: ctx.activeOrganization.id
                }
            )
        } catch (e) {
            console.error(`${e}`);
        }
    }

    useLayoutEffect(() => {
        getUnattachedGoals().then(() => setIsUnattachedGoalsLoading(false))
        getCampaignGoals().then(() => setIsCampaignGoalsLoading(false))
        checkAccess()
            .then((r) => {
                if (r && r.admin_permission && r.user_permission) {
                    setIsAdmin(true)
                }
            })
    }, [])

    return (
        <>
            <Helmet>
                <title>Campaigns</title>
            </Helmet>
            <Box sx={{flexGrow: 1}}>
                <Container maxWidth={false}>
                    <Box sx={{mb: 2, mt: 2}}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >
                            <Grid item sm={12}>
                                <Typography
                                  variant={"h4"}
                                  sx={{
                                      display: 'flex',
                                      justifyItems: 'center'
                                  }}
                                >
                                  <Campaign sx={{mr: 1}}/>
                                    Campaigns
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Box sx={{
                                    height: "50vh",
                                    width: "100%"
                                }}>
                                    {!isCampaignGoalsLoading ?
                                      <CampaignDataGridDetail
                                        campaigns={campaigns.campaigns}
                                        update={handleUpdate}
                                        createOpen={createOpen}
                                        setCreateOpen={setCreateOpen}
                                        isAdmin={isAdmin}
                                      /> :
                                      <CampaignPageSkeleton />}
                                </Box>
                            </Grid>
                            <Grid item sm={12} sx={{marginTop: "15vh"}}>
                                <Typography
                                  variant={"h4"}
                                  sx={{
                                      display: 'flex',
                                      justifyItems: 'center'
                                  }}
                                >
                                    <Flag sx={{mr: 1}}/>
                                    Unattached Goals
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Box>
                                    {!isUnattachedGoalsLoading ?
                                      <GoalsDataGrid
                                        goals={unattachedGoals}
                                        source={'campaign'}
                                        update={handleUpdate}
                                        campaigns={campaigns.campaigns}
                                        isAdmin={isAdmin}/> :
                                      <CampaignPageSkeleton />}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

const CampaignGoals = () => {
    return <CampaignPage/>

}

export default CampaignGoals