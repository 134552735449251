import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css'
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";

import {
    initializedSelector, renderWidgetsToLayoutExportSelectorFunc,
    renderWidgetsToLayoutSelectorFunc,
    widgetDependencyIdsFunc
} from "../../../../../slices/selectors";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, CircularProgress, Grid, Typography} from "@mui/material";
import WidgetSwitcher from "../../widget/WidgetSwitcher";
import slice, {initBitool, widgetsInit} from "../../../../../slices/bitool";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import Widget, {GridLocation} from "../../../../../cog/bitool/widget/Widget";
import WidgetStore from "../../../../../cog/bitool/widget/Store";
import {ReportStore} from "../../../../../cog/bitool/report";
import {Executor} from "../../../../../cog/bitool/executor";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayout {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number
}

interface GridLayouts {
    lg?: GridLayout[];
    md?: GridLayout[];
    sm?: GridLayout[];
    xl?: GridLayout[];
}

interface WidgetLayout {
    lg?: GridLayout;
    md?: GridLayout;
    sm?: GridLayout;
    xl?: GridLayout;
}
//

const Report = () => {
    let { id, token } = useParams();
    localStorage.setItem('apiToken', token);
    let dispatch = useDispatch()

    const selectRenderWidgetsToLayoutSelector = useMemo(renderWidgetsToLayoutExportSelectorFunc, [])

    const renderWidgetsToLayout = useSelector((state) =>
        selectRenderWidgetsToLayoutSelector(state)
    )
    const initSelector = useSelector( (state) =>
        initializedSelector(state)
    )

    const [layouts, setLayouts] = useState(null)
    const [dashboardId, setDashboardId] = useState(null)
    const [variableData, setVariableData] = useState(null)

    const getReport = useCallback(async () => {
        let response = await ReportStore.FindOne({
            id: id,
        })
        console.log(response)
        setDashboardId(response.report.dashboardId)
        dispatch(slice.actions.widgetChangeVariableData(response.report.variableData))

    }, [id])

    useEffect(() => {
        getReport()
    }, [id])

    useEffect(() => {
        if (dashboardId) {
            dispatch(initBitool(dashboardId, true))
        }
    }, [dashboardId])

    useEffect(() => {
        console.log("here1: ", renderWidgetsToLayout.layouts)
        setLayouts(renderWidgetsToLayout.layouts)
    }, [renderWidgetsToLayout])

    const layoutsToWidgets = (layouts: GridLayouts) => {
        let newWidgets = { ...renderWidgetsToLayout.widgets }
        Object.keys(layouts).map((k) => {
            layouts[k].map((layout, index) => {
                let currWidget = newWidgets[index]
                if (currWidget && currWidget.arguments && currWidget.arguments.location) {
                    newWidgets[index] = { ...currWidget,
                        arguments: { ...currWidget.arguments, location: { [k]: { ...layout } } }
                    }
                }
            })
        })
        return newWidgets
    }

    if (!id) {
        return <div> No ID found </div>
    }

    if (!initSelector) {
        return (
            <Grid item xs={8}>
                <Box sx={{minHeight: "100"}}>
                    <CircularProgress />
                </Box>
            </Grid>
        )
    }

    if (renderWidgetsToLayout) {
        return (
            <Box>
                <Box>
                    <ResponsiveGridLayout className="layout"
                                          layouts={renderWidgetsToLayout.layouts}
                                          rowHeight={15}
                                          isDraggable={true}
                                          isResizable={true}
                                          compactType={null}
                                          onLayoutChange={(_, _layouts) => {
                                              console.log("Layouts: ", _layouts)
                                              setLayouts(_layouts)
                                              // layoutsToWidgetsRequest(_layouts)
                                          }}
                                          breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                          cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
                        {renderWidgetsToLayout.widgets.map((w) => {
                            return <div key={w.id.toString()}>
                                <WidgetSwitcher widget={w} report={true}/>
                            </div>
                        })}
                    </ResponsiveGridLayout>
                </Box>
            </Box>

        )
    } else {
        return <CircularProgress />
    }
}

export default Report