import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import {
    Alert,
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Paper,
    Stack,
    Toolbar,
    Typography
} from '@mui/material';
// import {useCustomThemeContext} from "../util/Context";
import {useTheme} from "@mui/system";
import {ThemeProvider} from "@mui/material/styles";
// import {createCogTheme} from "../../../../../theme";
import {SingleBed} from "@mui/icons-material";
// import QuickStats from "../../../../../components/QuickStats";
// import QuickStats from "../../../../components/QuickStats";
import initialState from "../../../../slices/initialState";
import QuickStats from "../../../../components/QuickStats";
import * as querystring from "querystring";
import BlurLinearIcon from "@mui/icons-material/BlurLinear";
import {useMainContext} from "../../../../contexts/MainContext";

interface SingleValueIndicatorProps {
    title: string
    value: any[],
    symbol?: any
}

const SingleValueIndicator: FC<SingleValueIndicatorProps> = (props) => {
    // const [value, setValue] = useState<string[]>([])
    // const [title, setTitle] = useState<string>('Title')

    const theme = useTheme();
    const [loading, setLoading] = useState(false)
    const [symbol, setSymbol] = useState<string>((props.symbol) ? props.symbol: "")
    const color = '#FFF'
    return (
        <Card
            sx={{
                backgroundColor: color,
                color: "black",
                width: '100%',
                height: '100%'
                // marginX: {sm:'10%', md: '20%',}
                // height: "80%"
            }}
        >
            <CardHeader title={props.title} sx={{textAlign: 'left'}}/>
            <Divider/>
            <CardContent sx={{flexGrow: 1}}>
                <Box
                    sx={{
                        alignItems: 'center',
                        alignContent: 'center',
                        textAlign: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                    }}
                >{/*TODO: DISPLAY CURRENCY OR SYMBOL?*/}
                    <Typography
                        color="black"
                        //sx={{p: 2, alignContent: 'center', textAlign: 'center', alignItems: 'center', marginLeft:'20px', display: 'inline'}}
                        variant="h1"
                    >

                        {symbol}
                        {(props.value[0] == null) ? <Alert severity="warning">No ratio</Alert> : " " + props.value[0].toFixed(2)}

                    </Typography>

                </Box>
            </CardContent>
        </Card>
    )
}

export default SingleValueIndicator;