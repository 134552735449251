import {
    Autocomplete,
    Avatar,
    Badge,
    Button,
    CircularProgress,
    createFilterOptions,
    CssBaseline,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup, Slider,
    Step,
    StepButton,
    Stepper,
    TextField,
    Typography
} from "@mui/material";
import {Box, Container} from "@mui/system";
import React, {FC, useEffect, useState} from "react";
import GoalPriorityRating from "../../GoalPriorityRating";
import * as Yup from "yup";
import { GoalAssigneeStore, GoalOpportunityStore, GoalStore, GoalTargetStore } from "../../../../../../cog/keystone/goals";
import {useSnackbar} from "notistack";
import {Formik} from "formik";
import {useMainContext} from "../../../../../../contexts/MainContext";
import moment from "moment";
import {useKeystoneContext} from "../../../../../../contexts/KeystoneContext";
import {Card as KanbanCardObj, KanbanStore} from "../../../../../../cog/keystone/card";
import Goal, { CreateGoalCriteria, CreateGoalTag, GoalTargetDetails } from "../../../../../../cog/keystone/goals/goal/Goal";
import AddOppsDialog from "../../AddOppsDialog";
import AddCriteriaDialog from "../../AddCriteriaDialog";
import { GoalCriteria } from "../../../../../../cog/keystone/goals/goal/goalTarget/SVTarget";
import { Member } from "../../../../../../cog/keystone/member";
import { CreateOneRequest } from "../../../../../../cog/keystone/goals/goal/Store";


interface CreateGoalDialogProps {
    open: boolean
    setOpen: (v) => void
    update?: () => void
    source?: string
    handleGoalCreation: (goal: CreateOneRequest) => void
}

const CreateGoalDialog: FC<CreateGoalDialogProps> = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const ctx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const [goalType, setGoalType] = useState<string>(null)
    const [tags, setTags] = useState<CreateGoalTag[]>([{description: "testing"}, {description: "failed recently"}, {description: "first"}, {description: "recurring"}])
    const [priority, setPriority] = useState(0);
    const [criteria, setCriteria] = useState<GoalCriteria[]>([])
    const [activeStep, setActiveStep] = useState(0);
    const [initialOpps, setInitialOpps] = useState([])
    const [productNames, setProductNames] = useState<string[]>(null)
    const [productHeader, setProductHeader] = useState<string>(null)
    const [percentageDistribution, setPercentageDistribution] = useState<number>(1);
    const [opportunities, setOpportunities] = useState<Record<string, KanbanCardObj>>(null)
    const [connectOpportunityOpen, setConnectOpportunityOpen] = useState<boolean>(false);
    const [connectCriteriaOpen, setConnectCriteriaOpen] = useState<boolean>(false);

    const fetchAllProducts = async () => {
        const response = await GoalTargetStore.FetchProductNames({clientName: ctx.activeOrganization.name})

        if (response && response.productNames) {
            return response.productNames
            // setProductNames(Object.values(response.productNames)[0])
            // setProductHeader(Object.keys(response.productNames)[0])
        }
    }

    // handleCardOpen: Handles opening and closing of opportunity card
    const handleConnectOppOpen = () => {
        setConnectOpportunityOpen(true)
    }

    // handleCardClose: Handles closing of Opportunity card
    const handleConnectOppClose = () => {
        setConnectOpportunityOpen(false)
    }

    const handleConnectCriteriaOpen = () => {
        setConnectCriteriaOpen(true)
    }

    const handleConnectCriteriaClose = () => {
        setConnectCriteriaOpen(false)
    }

    const handleSliderChange = (event: Event, newValue: number) => {
        setPercentageDistribution(newValue);
    };

    // const formatSliderLabel = (event: Event, newValue: number) => {
    //     return newValue.toString() + "%"
    // }

    // TODO Check if this is needed as is:
    // - Opps already exist in context
    // - this looks like it wants all of em no filter, might cause slowing down with all that data
    const getAllOpps = async (): Promise<any> => {
        try {
            if (keystoneCtx.filters != null) {
                return await KanbanStore.FindAll({
                    clientName: ctx.activeOrganization.name,
                    filter: [],
                    PSU: {ID: "", Name: "", Tree: "", Org_Level: false, User_ID: ""}
                }).then((r) => {
                    return r
                })
            } else {
                return await KanbanStore.FindAll({
                    clientName: ctx.activeOrganization.name,
                    filter: [],
                    PSU: {ID: "", Name: "", Tree: "", Org_Level: false, User_ID: ""}
                }).then((r) => {
                    return r
                })
            }

        } catch (e) {
            console.error(`${e}`);
        }
    };

    useEffect(() => {
        getAllOpps().then(r => setOpportunities(r.allCards))
    }, [])

    useEffect(() => {
        let isMounted = true;
        fetchAllProducts().then((r) => {
            if (isMounted && r) {
                setProductNames(Object.values(r)[0])
                setProductHeader(Object.keys(r)[0])
            }
        })

        return () => {
            isMounted = false;
        };
    }, [])

    // This one is used to set the default value for the opps to be linked when cards are already selected in keystone
    useEffect(() => {
        if (keystoneCtx.selectCards == true && keystoneCtx.selectedCardsID.length > 0 && opportunities != null) {
            let tempDefault: KanbanCardObj[] = []

            Object.values(opportunities).forEach((a) => {
                if (keystoneCtx.selectedCardsID.findIndex(cid => cid == a.id) != -1) {
                    tempDefault.push(a)
                }
            })
            setInitialOpps(tempDefault)
        } else {
            setInitialOpps([])
        }
    }, [keystoneCtx.selectCards, keystoneCtx.createGoalOpen])

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 100,
    });

    const formatGoalCriteria = (goalCriteria: GoalCriteria[], productName: string, newPackSize: string): CreateGoalCriteria[] => {
        const formattedGoalCriteria: CreateGoalCriteria[] = [];

        goalCriteria.forEach(obj => {
            obj.values.forEach(value => {
                formattedGoalCriteria.push({
                    header: obj.header,
                    value: value
                });
            });
        });

        if (productNames != null && productName !== "") {
            const productGoalCriteria: CreateGoalCriteria = {
                header: productHeader,
                value: productName
            }

            formattedGoalCriteria.push(productGoalCriteria)
        }

        if (productNames != null && newPackSize !== "") {
            const productGoalCriteria: CreateGoalCriteria = {
                header: productHeader,
                value: newPackSize
            }

            formattedGoalCriteria.push(productGoalCriteria)
        }
        return formattedGoalCriteria;
    }

    const formatGoalAssignees = (assignees: Member[]) => {
        const userIDs: string[] = []

        if (assignees.length > 0) {
            assignees.forEach(assignee => {
                userIDs.push(assignee.id)
            })
        }

        return userIDs
    }

    const formatGoalType = (goalType: string) => {
        let type : string

        switch (goalType) {
            case "sales":
                type = "SV"
                break;
            case "volume":
                type = "SV"
                break;
            case "npd":
                type = "NPD"
                break;
            case "nps":
                type = "NPS"
                break;
            case "static":
                type = "Static"
                break;
            default :
                type = "Static"
        }

        return type
    }

    const formatTargetType = (targetType: string) => {
        let type : string

        switch (targetType) {
            case "sales":
                type = "Value"
                break;
            case "volume":
                type = "Volume"
                break;
            case "npd":
                type = "NPD"
                break;
            case "nps":
                type = "NPS"
                break;
            case "static":
                type = "Static"
                break;
            default :
                type = "Static"
        }

        return type
    }

    // const handlePriorityChange = (event, newValue) => {
    //     setPriority(newValue)
    // }

    const steps = ['Goal Type', 'Goal Details'];

    const marks = [
        {
            value: 0,
            label: '1%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    const isProductNotNew = (target: string): boolean => {
        if (productNames.length > 0) {
            if (target.length !== 0) {
                return productNames.includes(target);
            } else {
                return true
            }
        } else {
            return false
        }
    }


    return (
        <Box>
            <Dialog
                //disableEnforceFocus required to open chat if Dialog opened first
                disableEnforceFocus
              open={props.open}
              onClose={() => {
                props.setOpen(false)
                setGoalType(null)
                setActiveStep(0)
                  if (props.source && props.source == 'campaign'){
                      props.update()
                  }
              }}
            >
                <DialogTitle textAlign="center">Create A Goal</DialogTitle>
                <DialogContent>
                    <Container component="main" maxWidth="xs">
                        <Stepper alternativeLabel activeStep={activeStep}
                                 sx={{padding: '16px', marginX: '10%', paddingLeft: '0px'}}>
                            {steps.map((k, idx) => {
                                return (
                                    <Step key={k}
                                          sx={{display: 'flex', flexDirection: 'column',}}>
                                        <StepButton color="inherit"
                                                    onClick={handleStep(idx)}>
                                            {k}
                                        </StepButton>
                                    </Step>)
                            })}
                        </Stepper>
                        <CssBaseline/>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                name: "",
                                type: goalType,
                                dueDate: moment().format("YYYY-MM-DD"),
                                description: "",
                                owner: "",
                                productName: "",
                                pps: "",
                                tag: [],
                                priority: 0,
                                userIds: [],
                                target: 0,
                                percentageDistribution: 0,
                                opportunities: initialOpps,
                                status: "Active",
                                newPackSize: "",
                                submit: null,
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().max(255).required("Name is required"),
                                dueDate: Yup.string(),
                                description: Yup.string(),
                                owner: Yup.string(),
                                productName: Yup.string(),
                                pps: Yup.string(),
                                priority: Yup.number(),
                                userIds: Yup.array(),
                                target: Yup.number().required("Target is required"),
                                percentageDistribution: Yup.number().required("Percentage distribution target required"),
                                opportunities: Yup.array(),
                                newPackSize: Yup.string(),
                            })}
                            onSubmit={async (
                                values,
                                {resetForm, setErrors, setStatus, setSubmitting}
                            ): Promise<void> => {
                                setSubmitting(true)
                                enqueueSnackbar("Goal Creation in Progress", {
                                    anchorOrigin: {
                                        horizontal: "right",
                                        vertical: "top",
                                    },
                                    variant: "info",
                                });

                                if (!isProductNotNew(values.productName)) {
                                    enqueueSnackbar("Note:  The Duration of the Goal Creation Process Will Be Extended in Order to Generate New Product Opportunities", {
                                        anchorOrigin: {
                                            horizontal: "right",
                                            vertical: "top",
                                        },
                                        variant: "warning",
                                    });
                                }

                                const formattedGoalCriteria = formatGoalCriteria(criteria, values.productName, values.newPackSize)
                                const formattedUserIDs = formatGoalAssignees(values.userIds)
                                const formattedGoalType = formatGoalType(values.type)
                                const formattedTargetType = formatTargetType(values.type)
                                const formattedTargetDetails: GoalTargetDetails = {
                                    type: formattedTargetType,
                                    target: values.target,
                                    percentageDistribution: percentageDistribution,
                                    pps: values.pps,
                                    np: !isProductNotNew(values.productName)
                                }

                                const goal: CreateOneRequest= {
                                    goal: {
                                        name: values.name,
                                        type: formattedGoalType,
                                        owner: ctx.user.id,
                                        description: values.description,
                                        priority: values.priority,
                                        dueDate: values.dueDate,
                                        status: isProductNotNew(values.productName) ? "Active" : "Generating"
                                    },
                                    goalTags: values.tag,
                                    goalCriteria: formattedGoalCriteria,
                                    opportunityIDs: values.opportunities,
                                    userIDs: formattedUserIDs,
                                    targetDetails: formattedTargetDetails,
                                    clientName: ctx.activeOrganization.name
                                }

                                props.handleGoalCreation(goal)
                                props.setOpen(false)
                                setSubmitting(false);
                                resetForm();
                                setGoalType(null)
                                setActiveStep(0)

                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  touched,
                                  values,
                              }): JSX.Element => (

                                <form onSubmit={handleSubmit}>
                                    <Box
                                        sx={{
                                            marginTop: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {(activeStep == 0) ?
                                          <Box>
                                              <Typography variant="h5" sx={{marginBottom: "2%"}}>
                                                  Select A Goal Type
                                              </Typography>
                                              <FormControl>
                                                  <RadioGroup
                                                    aria-labelledby="GoalTypeLabel"
                                                    defaultValue="static"
                                                    name="GoalType"
                                                    value={goalType}
                                                    onChange={(event, value) => {
                                                        setActiveStep(1)
                                                        setGoalType(value)
                                                        handleChange(event)
                                                    }}
                                                  >
                                                      <FormControlLabel value="sales" control={<Radio/>} label="Sales"/>
                                                      <FormControlLabel value="volume" control={<Radio/>} label="Volume"/>
                                                      <FormControlLabel value="static" control={<Radio/>} label="Static"/>
                                                      <FormControlLabel value="npd" control={<Radio/>} label="New Product Distribution"/>
                                                      <FormControlLabel value="nps" control={<Radio/>} label="New Pack Size"/>
                                                  </RadioGroup>
                                              </FormControl>
                                          </Box> :
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        name="name"
                                                        required
                                                        fullWidth
                                                        id="name"
                                                        label="Name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        autoFocus
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="dueDate"
                                                        label="Due Date"
                                                        name="dueDate"
                                                        type={"date"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.dueDate}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="description"
                                                        label="Description"
                                                        name="description"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.description}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Autocomplete
                                                        disablePortal
                                                        fullWidth
                                                        multiple={true}
                                                        id="tag"
                                                        options={tags}
                                                        onBlur={handleBlur}
                                                        getOptionLabel={(option: CreateGoalTag) => option.description}
                                                        onChange={(e, v) => {setFieldValue("tag", v || "");}}
                                                        value={values.tag.map((a) => a)}
                                                        renderInput={(params) => <TextField {...params} label="Attatch Tags"/>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={12} md={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Box sx={{marginLeft: 1}}>
                                                                <GoalPriorityRating
                                                                  rating={priority}
                                                                  handleChange={(e, v) => {
                                                                      setFieldValue("priority", v || "");
                                                                  }}
                                                                  readOnly={false}/>
                                                            </Box>}
                                                        label="Priority"
                                                        labelPlacement={"start"}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        disablePortal
                                                        multiple={true}
                                                        id="assignMembers"
                                                        options={ctx.members != null ? ctx.members : []}
                                                        onBlur={handleBlur}
                                                        disableCloseOnSelect
                                                        onChange={(e, v) => {setFieldValue("userIds", [...v] || "");}}
                                                        getOptionLabel={(option) => option.displayName}
                                                        value={values.userIds.map((a) => a)}
                                                        renderInput={(params) => <TextField {...params} label="Assign Members"/>}
                                                        renderOption={(props, option, {selected}) => (
                                                            <Box component="li"
                                                                 sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                                <Avatar alt={option.displayName} src={option.avatar}/>
                                                                <Typography
                                                                    component="div"
                                                                    sx={{margin: "auto"}}
                                                                >
                                                                    {option.displayName}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    />
                                                </Grid>
                                                {goalType === "sales" &&
                                                  <Grid item xs={12}>
                                                      <TextField
                                                        required
                                                        fullWidth
                                                        id="target"
                                                        label="Target"
                                                        name="target"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.target}
                                                      />
                                                  </Grid>
                                                }
                                                {goalType === "volume" &&
                                                  <Grid item xs={12}>
                                                      <TextField
                                                        required
                                                        fullWidth
                                                        id="target"
                                                        label="Target"
                                                        name="target"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.target}
                                                      />
                                                  </Grid>
                                                }
                                                {goalType === "npd" &&
                                                  <Grid item xs={12}>
                                                      <Autocomplete
                                                        disablePortal
                                                        multiple={false}
                                                        freeSolo={true}
                                                        autoSelect
                                                        forcePopupIcon={true}
                                                        getOptionLabel={(option: string) => option || ""}
                                                        id="product"
                                                        options={productNames != null ? productNames : []}
                                                        onBlur={handleBlur}
                                                        onChange={(e, v) => {setFieldValue("productName", v || "");}}
                                                        value={values.productName}
                                                        renderInput={(params) => <TextField {...params} required label="Select Product For Distribution" helperText={"Can't find your product listed? Enter your new product name"}/>}
                                                        filterOptions={filterOptions}
                                                      />
                                                  </Grid>
                                                }
                                                {goalType === "npd" &&
                                                  <Grid item xs={12}>
                                                      <Box>
                                                          <Typography id="percentage-distribution-slider" gutterBottom>
                                                              Percentage Distribution
                                                          </Typography>
                                                          <Slider
                                                            value={typeof percentageDistribution === 'number' ? percentageDistribution : 1}
                                                            onChange={handleSliderChange}
                                                            aria-labelledby="percentage-distribution-slider"
                                                            valueLabelDisplay="auto"
                                                            marks={marks}
                                                            // valueLabelFormat={formatSliderLabel}
                                                            valueLabelFormat={(value: number) => {return value.toString() + "%"}}
                                                          />
                                                      </Box>
                                                  </Grid>
                                                }
                                                {goalType === "nps" &&
                                                  <Grid item xs={12}>
                                                      <Autocomplete
                                                        disablePortal
                                                        multiple={false}
                                                        freeSolo={true}
                                                        forcePopupIcon={true}
                                                        getOptionLabel={(option: string) => option || ""}
                                                        id="product"
                                                        options={productNames != null ? productNames : []}
                                                        onBlur={handleBlur}
                                                        onChange={(e, v) => {
                                                            setFieldValue("pps", v || "");
                                                        }}
                                                        value={values.pps}
                                                        renderInput={(params) => <TextField {...params} required label="Select Previous Pack Size"/>}
                                                        filterOptions={filterOptions}
                                                      />
                                                  </Grid>
                                                }
                                                {goalType === "nps" &&
                                                  <Grid item xs={12}>
                                                      <Autocomplete
                                                        disablePortal
                                                        multiple={false}
                                                        freeSolo={true}
                                                        autoSelect
                                                        forcePopupIcon={true}
                                                        getOptionLabel={(option: string) => option || ""}
                                                        id="newPackSize"
                                                        options={productNames != null ? productNames : []}
                                                        onBlur={handleBlur}
                                                        onChange={(e, v) => {
                                                            setFieldValue("newPackSize", v || "");
                                                        }}
                                                        value={values.newPackSize}
                                                        renderInput={(params) => <TextField {...params} required label="New Pack Size" helperText={"Can't find your product listed? Enter your new product name"}/>}
                                                        filterOptions={filterOptions}
                                                      />
                                                  </Grid>
                                                }
                                                {goalType === "nps" &&
                                                  <Grid item xs={12}>
                                                      <Box>
                                                          <Typography id="percentage-distribution-slider" gutterBottom>
                                                              Percentage Distribution
                                                          </Typography>
                                                          <Slider
                                                            value={typeof percentageDistribution === 'number' ? percentageDistribution : 0}
                                                            onChange={handleSliderChange}
                                                            aria-labelledby="percentage-distribution-slider"
                                                            valueLabelDisplay="auto"
                                                            marks={marks}
                                                            // valueLabelFormat={formatSliderLabel}
                                                            valueLabelFormat={(value: number) => {return value.toString() + "%"}}
                                                          />
                                                      </Box>
                                                  </Grid>
                                                }
                                                {/* Todo attach goals page */}
                                                <Grid item xs={12}>
                                                    <Badge color="info"
                                                           badgeContent={opportunities != null ? values.opportunities.length : 0}
                                                           sx={{width: "100%"}}>
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            sx={{my: 1}}
                                                            onClick={handleConnectOppOpen}
                                                        >
                                                            Attach Opportunities
                                                        </Button>
                                                    </Badge>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Badge color="info"
                                                           badgeContent={criteria != null ? criteria.length : 0}
                                                           sx={{width: "100%"}}>
                                                        <Button
                                                          fullWidth
                                                          variant="contained"
                                                          sx={{my: 1}}
                                                          onClick={handleConnectCriteriaOpen}
                                                        >
                                                            Set Goal Criteria
                                                        </Button>
                                                    </Badge>
                                                </Grid>
                                            </Grid>}
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            disabled={isSubmitting}
                                            sx={{mt: 3, mb: 2}}
                                        >
                                            {isSubmitting ? <CircularProgress size={20} /> : 'Create'}
                                        </Button>
                                        <AddOppsDialog connectOpportunityOpen={connectOpportunityOpen} handleConnectOppClose={handleConnectOppClose} opportunities={opportunities} selectedOpportunites={values.opportunities} setFieldValue={setFieldValue} />
                                        <AddCriteriaDialog connectCriteriaOpen={connectCriteriaOpen} handleConnectCriteriaClose={handleConnectCriteriaClose} criteria={criteria} setCriteria={setCriteria} />
                                    </Box>
                                </form>)}
                        </Formik>
                    </Container>
                </DialogContent>
            </Dialog>

        </Box>
    )
}
export default CreateGoalDialog;