import type {FC} from 'react';
import {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Box, Button, Container, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import gtm from '../lib/gtm';

const NotFound: FC = () => {
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        gtm.push({event: 'page_view'});
    }, []);

    return (
        <>
            <Helmet>
                <title>Error: Not Found</title>
            </Helmet>
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    minHeight: '100%',
                    px: 3,
                    py: '80px'
                }}
            >
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant={mobileDevice ? 'h4' : 'h1'}
                    >
                        404: The page you are looking for isn’t here
                    </Typography>
                    <Typography
                        align="center"
                        color="textSecondary"
                        sx={{mt: 0.5}}
                        variant="subtitle2"
                    >
                        You either tried some shady route or you
                        came here by mistake. Whichever it is, try using the
                        navigation.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6
                        }}
                    >
                        <Box
                            alt="Under development"
                            component="img"
                            src={`/static/error/error404_${theme.palette.mode}.svg`}
                            sx={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: 400
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6
                        }}
                    >
                        <Button
                            color="primary"
                            component={RouterLink}
                            to="/"
                            variant="outlined"
                        >
                            Back to Home
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default NotFound;
