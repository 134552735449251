import React, {FC} from "react";
import {Card, CardContent, CardHeader, Divider, Grid, Typography} from "@mui/material";
import {MonetizationOnRounded} from "@mui/icons-material";
import AbbreviateNumbers from "../../../../utils/abbreviateNumbers";

interface DataProps {
    [key: string]: (string[] | number[]);
}

interface ValueAndCountIndicatorProps {
    title: string
    currency: string
    value: number
    count: number
    countLabel: string
}
const ValueAndCountIndicator: FC<ValueAndCountIndicatorProps> = ({...props}) => {
    
    
    
    return (
        <Card>
            <CardHeader
                title={props.title}
            />
            <Divider/>
            <CardContent>
                <Grid container justifyContent="center">
                    <Grid item xs={6} container alignItems="center">
                        <Typography variant="h2"
                                    sx={{paddingLeft: "40%", textAlign: "left"}}>
                            <MonetizationOnRounded color={'success'}/>
                            {props.currency + " " + AbbreviateNumbers(props.value, 1)}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} container direction="row"
                          justifyContent="center">
                        <Divider
                        orientation={"vertical"}/>
                    </Grid>
                    <Grid item xs={4} container
                          direction="column"
                          justifyContent="center"
                          sx={{pr: "10%"}}
                    >
                        <Typography variant="h2"
                                    sx={{
                                        // paddingRight: "45%",
                                        textAlign: "center"}}>
                            {props.count}
                        </Typography>
                        <Typography variant="h2"
                                    sx={{textAlign: "center"}}>
                            {props.countLabel}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
)
}

export default ValueAndCountIndicator