/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import Leaderboard from "./Leaderboard";
import PeriodSelector from "../../../pages/app/keystone/leaderboard/PeriodSelector";
import periodSelector from "../../../pages/app/keystone/leaderboard/PeriodSelector";

export interface FindOneRequest {
    StartDate: string
    EndDate: string
}

export interface FindOneResponse {
    leaderboard: Leaderboard[];

}

const Store = {
    serviceProvider: 'Leaderboard-Store',
    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        return await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                "start_date": request.StartDate,
                "end_date": request.EndDate,
            }]
        })
    }
};

export default Store;