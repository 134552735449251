import React, {FC} from "react";
import RandSymbol from "./symbols/RandSymbol";
import HKDollarSymbol from "./symbols/HKDollarSymbol";
import RinggitSymbol from "./symbols/RinggitSymbol";
import PulaSymbol from "./symbols/PulaSymbol";
import NDollarSymbol from "./symbols/NDollarSymbol";
import LilangeniSymbol from "./symbols/LilangeniSymbol";

interface CurrencySwitcherProps {
  org: string
}

const CurrencySwitcher: FC<CurrencySwitcherProps> = ({org}) => {
  switch (org) {
    case "WUTOW":
      return <NDollarSymbol />
    case "WUTOW UNILEVER":
      return <NDollarSymbol />
    case "WUTOW_UNILEVER":
      return <NDollarSymbol />
    case "WUTOW KC":
      return <NDollarSymbol />
    case "WUTOW_KC":
      return <NDollarSymbol />
    case "DKSH":
      return <h1>SFr</h1>
    case "DKSH HK":
      return <HKDollarSymbol />
    case "DKSH MO":
      return <RinggitSymbol />
    case "DKSH_HK":
      return <HKDollarSymbol />
    case "DKSH_MO":
      return <RinggitSymbol />
    case "KIMBERLY CLARK":
      return <RandSymbol />
    case "CASALES":
      return <PulaSymbol />
    case "CASALESPNP":
      return <RandSymbol />
    case "CASALESPNP_UNILEVER":
      return <RandSymbol />
    case "CASALES UNILEVER":
      return <PulaSymbol />
    case "CASALES_UNILEVER":
      return <PulaSymbol />
    case "CASALES KC":
      return <PulaSymbol />
    case "CASALES_KC":
      return <PulaSymbol />
    case "LOGICO":
      return <LilangeniSymbol />
    case "PEPSICO NIGERIA":
      return <h1>₦</h1>
    case "PEPSICO_NIGERIA":
      return <h1>₦</h1>
    case "PEPSICO TANZANIA":
      return <h1>TSh</h1>
    case "PEPSICO_TANZANIA":
      return <h1>TSh</h1>
    case "PEPSICO MOZAMBIQUE":
      return <h1>MTn</h1>
    case "PEPSICO_MOZAMBIQUE":
      return <h1>MTn</h1>
    case "UNILEVER":
      return <h1>Br</h1>
    case "UNILEVER_KENYA":
      return <h1>KSh</h1>
    default:
      return <RandSymbol />
  }
}

export default CurrencySwitcher;