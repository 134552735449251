/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../../api/jsonrpc';


export interface CloneOneRequest {
  widgetId: string;
  targetDashboardId: string;
}

export interface CloneOneResponse {
  widgetId: string;
}

const Store = {
  serviceProvider: 'Widget-Cloner',
  async CloneOne(request: CloneOneRequest): Promise<CloneOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CloneOne`,
      request: [{
        'widgetId': request.widgetId,
        'targetDashboardId': request.targetDashboardId
      }]
    });
    return {
      'widgetId': response.widgetId
    };
  }
}

export default Store;