const PotentialGain = (props) => {
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="PotentialGain" data-name="Business Icons" viewBox="0 0 203 203" height={height}>
            <defs>
                <linearGradient id="potentialGainBlob" x1={60} x2="160.2" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop id="potGainBlob" offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="potentialGainLine" x1=".9" x2="5.1" y1="138.9" y2="134.6" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-2" x1="197.9" x2="202.1" y1="68.7" y2="64.5" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-3" x1="77.6" x2="79.6" y1="4.6" y2="2.6" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-4" x1="79.8" x2="81.7" y1="2.5" y2=".5" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-5" x1={82} x2="83.9" y1="4.6" y2="2.7" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-6" x1="79.8" x2="81.7" y1="6.7" y2="4.8" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-7" x1={133} x2="134.9" y1="200.4" y2="198.4" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-8" x1="135.2" x2="137.1" y1="198.2" y2="196.3" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-9" x1="137.4" x2="139.3" y1="200.3" y2="198.4" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-10" x1="135.2" x2="137.1" y1="202.5" y2="200.5" />
                <linearGradient id="potentialGainFill" x1={116} x2="166.9" y1="90.7" y2="39.8" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#potentialGainFill" id="potentialGainFill-2" x1="42.4" x2="61.1" y1="140.5" y2="121.8" />
                <linearGradient xlinkHref="#potentialGainFill" id="potentialGainFill-3" x1="66.8" x2="88.2" y1="129.5" y2="108.1" />
                <linearGradient xlinkHref="#potentialGainFill" id="potentialGainFill-4" x1={92} x2="116.4" y1="137.6" y2="113.2" />
                <linearGradient xlinkHref="#potentialGainFill" id="potentialGainFill-5" x1="116.8" x2="145.3" y1={126} y2="97.6" />
                <linearGradient xlinkHref="#potentialGainFill" id="potentialGainFill-6" x1="140.2" x2="175.2" y1="132.3" y2="97.3" />
                <linearGradient xlinkHref="#potentialGainFill" id="potentialGainFill-7" x1="94.5" x2="104.4" y1="169.3" y2="159.4" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-11" x1="38.9" x2="64.5" y1="139.1" y2="113.5" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-12" x1="63.3" x2="91.7" y1="137.7" y2="109.4" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-13" x1="88.6" x2="119.9" y1="136.2" y2="104.9" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-14" x1="113.3" x2="148.7" y1="134.2" y2="98.8" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-15" x1="136.5" x2={179} y1="130.6" y2="88.1" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-16" x1="64.6" x2="152.3" y1="111.2" y2="23.5" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-17" x1="37.5" x2="111.1" y1="160.6" y2={87} />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-18" x1="115.8" x2="125.1" y1="166.5" y2="157.2" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-19" x1="115.8" x2="125.1" y1="171.5" y2="162.2" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-20" x1="67.8" x2="77.1" y1="166.5" y2="157.2" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-21" x1="67.8" x2="77.1" y1="171.5" y2="162.2" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-22" x1="46.1" x2="57.4" y1={170} y2="158.7" />
                <linearGradient xlinkHref="#potentialGainLine" id="potentialGainLine-23" x1="93.8" x2="105.1" y1={170} y2="158.7" />
                <style dangerouslySetInnerHTML={{__html: ".iconWhite{fill:#fff}" }} />
            </defs>
            <g id="Icon_18">
                <path id="Contour" d="M54 132.1a2.9 2.9 0 0 0-3-1.4c-5.9 1-22.9 1.5-24.2-25-1.6-32 37.3-86.8 94.4-76 45.8 8.8 11.6 52.5 39.7 63.5 16.4 6.4 31.3 39.4-5.2 68.1-19.8 15.5-70.5 29.3-101.7-29.2Z" style={{fill: 'url(#potentialGainBlob)'}} />
                <path id="Contour-2" d="M3 139.7a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" style={{fill: 'url(#potentialGainLine)'}} />
                <path id="Contour-3" d="M200 69.6a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" style={{fill: 'url(#potentialGainLine-2)'}} />
                <g id="Group">
                    <path id="Contour-4" d="M80.1 3.6a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#potentialGainLine-3)'}} />
                    <path id="Contour-5" d="M80.7 3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#potentialGainLine-4)'}} />
                    <path id="Contour-6" d="M83.4 2.6h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#potentialGainLine-5)'}} />
                    <path id="Contour-7" d="M80.7 4.2a1 1 0 0 0-1 1v1.1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#potentialGainLine-6)'}} />
                </g>
                <g id="Group-2">
                    <path id="Contour-8" d="M135.5 199.4a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#potentialGainLine-7)'}} />
                    <path id="Contour-9" d="M136.1 198.8a1 1 0 0 0 1-1v-1.1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#potentialGainLine-8)'}} />
                    <path id="Contour-10" d="M138.8 198.4h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#potentialGainLine-9)'}} />
                    <path id="Contour-11" d="M136.1 200a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#potentialGainLine-10)'}} />
                </g>
                <path id="Contour-12" d="M158.3 51.8a.5.5 0 0 1 .7 0l5.7 4.2a.5.5 0 0 0 .8-.5l-.7-17.8a.5.5 0 0 0-.6-.5l-17.8.7a.5.5 0 0 0-.3 1l5.3 5.7a.5.5 0 0 1 0 .8l.2-.2s-41.2 46-109 42.6c0 0 73.3 15.8 115.7-36Z" className="iconWhite" />
                <path id="Contour-13" d="M108.8 83.6s43.8-18.6 48.9-46.2l6.6-.2 1.2 18.3-6.5-3.8S139 77 108.8 83.6Z" style={{fill: 'url(#potentialGainFill)'}} />
                <rect id="Contour-14" width="16.1" height="35.5" x="43.7" y="108.5" className="iconWhite" rx="2.7" />
                <rect id="Contour-15" width="14.1" height="25.8" x="44.7" y="118.3" rx="2.2" style={{fill: 'url(#potentialGainFill-2)'}} />
                <rect id="Contour-16" width="16.1" height={41} x="69.7" y="103.1" className="iconWhite" rx="2.9" />
                <rect id="Contour-17" width="14.1" height="31.5" x="70.5" y="103.1" rx="2.4" style={{fill: 'url(#potentialGainFill-3)'}} />
                <rect id="Contour-18" width="16.1" height={47} x="96.2" y={97} className="iconWhite" rx="2.7" />
                <rect id="Contour-19" width="14.1" height="37.3" x="97.2" y="106.8" rx="2.3" style={{fill: 'url(#potentialGainFill-4)'}} />
                <rect id="Contour-20" width="16.1" height={55} x={123} y={89} className="iconWhite" rx="2.7" />
                <rect id="Contour-21" width={14} height="45.5" x={124} y={89} rx="2.3" style={{fill: 'url(#potentialGainFill-5)'}} />
                <rect id="Contour-22" width="16.1" height="69.4" x="149.7" y="74.6" className="iconWhite" rx="2.7" />
                <rect id="Contour-23" width="14.1" height="58.5" x="150.6" y="85.5" rx="2.3" style={{fill: 'url(#potentialGainFill-6)'}} />
                <rect id="Contour-24" width="10.7" height="10.7" x="94.1" y={159} rx="1.3" style={{fill: 'url(#potentialGainFill-7)'}} />
                <rect id="Contour-25" width="10.7" height="10.7" x="46.4" y={159} className="iconWhite" rx="1.3" />
                <g id="Group-3">
                    <path id="Contour-26" d="M46.4 107.5a3.7 3.7 0 0 0-3.7 3.7v30.1a3.7 3.7 0 0 0 3.7 3.7h10.7a3.7 3.7 0 0 0 3.7-3.7v-30a3.7 3.7 0 0 0-3.7-3.8Zm12.4 3.7v30.1A1.7 1.7 0 0 1 57 143H46.4a1.7 1.7 0 0 1-1.7-1.7v-30a1.7 1.7 0 0 1 1.7-1.8h10.7a1.7 1.7 0 0 1 1.7 1.7Z" style={{fill: 'url(#potentialGainLine-11)'}} />
                    <path id="Contour-27" d="M72.2 102a3.7 3.7 0 0 0-3.7 3.8v35.5a3.7 3.7 0 0 0 3.7 3.7h10.6a3.7 3.7 0 0 0 3.7-3.7v-35.5a3.7 3.7 0 0 0-3.7-3.7Zm12.3 3.8v35.5a1.7 1.7 0 0 1-1.7 1.7H72.2a1.7 1.7 0 0 1-1.7-1.7v-35.5a1.7 1.7 0 0 1 1.7-1.7h10.6a1.7 1.7 0 0 1 1.7 1.7Z" style={{fill: 'url(#potentialGainLine-12)'}} />
                    <path id="Contour-28" d="M99 96a3.7 3.7 0 0 0-3.8 3.7v41.6A3.7 3.7 0 0 0 99 145h10.7a3.7 3.7 0 0 0 3.7-3.7V99.7a3.7 3.7 0 0 0-3.7-3.7Zm12.3 3.7v41.6a1.7 1.7 0 0 1-1.7 1.7H98.9a1.7 1.7 0 0 1-1.7-1.7V99.7A1.7 1.7 0 0 1 99 98h10.7a1.7 1.7 0 0 1 1.7 1.8Z" style={{fill: 'url(#potentialGainLine-13)'}} />
                    <path id="Contour-29" d="M125.7 88a3.7 3.7 0 0 0-3.7 3.7v49.6a3.7 3.7 0 0 0 3.7 3.7h10.6a3.7 3.7 0 0 0 3.7-3.7V91.7a3.7 3.7 0 0 0-3.7-3.7Zm12.3 3.7v49.6a1.7 1.7 0 0 1-1.7 1.7h-10.6a1.7 1.7 0 0 1-1.7-1.7V91.7a1.7 1.7 0 0 1 1.7-1.7h10.6a1.7 1.7 0 0 1 1.7 1.7Z" style={{fill: 'url(#potentialGainLine-14)'}} />
                    <path id="Contour-30" d="M163 73.6h-10.6a3.7 3.7 0 0 0-3.7 3.7v64a3.7 3.7 0 0 0 3.7 3.7h10.7a3.7 3.7 0 0 0 3.7-3.7v-64a3.7 3.7 0 0 0-3.7-3.7Zm1.8 67.7a1.7 1.7 0 0 1-1.7 1.7h-10.7a1.7 1.7 0 0 1-1.7-1.7v-64a1.7 1.7 0 0 1 1.7-1.7h10.7a1.7 1.7 0 0 1 1.7 1.7Z" style={{fill: 'url(#potentialGainLine-15)'}} />
                    <path id="Contour-31" d="M42.4 88.8A139 139 0 0 0 68.6 91a150.4 150.4 0 0 0 16-.9c21.7-2.3 52-10.7 74.2-37.4l5.2 4a1.5 1.5 0 0 0 2.5-1.4l-.7-17.8a1.6 1.6 0 0 0-.5-1 1.6 1.6 0 0 0-1.1-.5l-17.8.8a1.5 1.5 0 0 0-1 2.6l5 5.4c-4.7 4.9-45 45-107.8 41.9a1 1 0 0 0-1 1 1 1 0 0 0 .8 1Zm110-43a1.5 1.5 0 0 0 .2-.8 1.6 1.6 0 0 0-.4-1.1l-4.7-5 16.4-.7.6 16.4-4.8-3.6a1.5 1.5 0 0 0-2 .2 107.5 107.5 0 0 1-73.2 37 152.5 152.5 0 0 1-26.4.5c58.2-3.5 93.9-42.5 94.2-43Zm-1.8-1a.7.7 0 0 1 .1-.3Z" style={{fill: 'url(#potentialGainLine-16)'}} />
                    <path id="Contour-32" d="M174.3 150.5H35V36.4a1 1 0 0 0-2 0v114.1h-3.2a1 1 0 0 0 0 2H33v3.2a1 1 0 0 0 2 0v-3.2h139.3a1 1 0 0 0 0-2Z" style={{fill: 'url(#potentialGainLine-17)'}} />
                    <path id="Contour-33" d="M128.3 160.8h-15.8a1 1 0 0 0 0 2h15.8a1 1 0 0 0 0-2Z" style={{fill: 'url(#potentialGainLine-18)'}} />
                    <path id="Contour-34" d="M128.3 165.8h-15.8a1 1 0 0 0 0 2h15.8a1 1 0 0 0 0-2Z" style={{fill: 'url(#potentialGainLine-19)'}} />
                    <path id="Contour-35" d="M80.3 160.8H64.5a1 1 0 0 0 0 2h15.8a1 1 0 0 0 0-2Z" style={{fill: 'url(#potentialGainLine-20)'}} />
                    <path id="Contour-36" d="M80.3 165.8H64.5a1 1 0 0 0 0 2h15.8a1 1 0 0 0 0-2Z" style={{fill: 'url(#potentialGainLine-21)'}} />
                    <path id="Contour-37" d="M55.8 158h-8a2.3 2.3 0 0 0-2.4 2.3v8a2.3 2.3 0 0 0 2.4 2.3h8a2.3 2.3 0 0 0 2.3-2.3v-8a2.3 2.3 0 0 0-2.3-2.3Zm.3 10.4a.3.3 0 0 1-.3.3h-8a.3.3 0 0 1-.4-.3v-8a.3.3 0 0 1 .4-.4h8a.3.3 0 0 1 .3.4Z" style={{fill: 'url(#potentialGainLine-22)'}} />
                    <path id="Contour-38" d="M103.5 158h-8a2.3 2.3 0 0 0-2.3 2.3v8a2.3 2.3 0 0 0 2.3 2.3h8a2.3 2.3 0 0 0 2.3-2.3v-8a2.3 2.3 0 0 0-2.3-2.3Zm.3 10.4a.3.3 0 0 1-.3.3h-8a.3.3 0 0 1-.3-.3v-8a.3.3 0 0 1 .3-.4h8a.3.3 0 0 1 .3.4Z" style={{fill: 'url(#potentialGainLine-23)'}} />
                </g>
            </g>
        </svg>

    )

}

export default PotentialGain