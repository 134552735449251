import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import {Box, Grid, Paper} from '@mui/material';
// import {useCustomThemeContext} from "../util/Context";
import {ApexOptions} from "apexcharts";
import Chart from "react-apexcharts";
import {InsightWidgetProps} from "../opportunity/additionalInfo/windowAttributes/insights/InsightWidgetSwitcher";
import {useTheme} from "@mui/material/styles";

// const ColourPickerPreviewApexCharts: FC = () => {

interface data {
    labels: string[]
    series: object
}
interface LineGraphProps {
 data: data
}
const LineGraphStat: FC<LineGraphProps> = (props) => {
    const [series, setSeries] = useState<any[]>((props.data.series) ? Object.keys(props.data.series).map((entry)=> ({name: entry, data: props.data.series[entry]})): [])
    const chartType = 'line'

    // @ts-ignore
    const chartOptions: (ApexOptions) = {
        chart: {
            type: "line",
            stacked: false,
            stackType: '100%',
            offsetX: -30,
            offsetY: -20,
            background: '#FFFFFF'
        },
/*        annotations: {
            yaxis: [
                {
                    y: 4000000,
                    borderColor: "#00E396",
                    label: {
                        borderColor: "#00E396",
                        style: {
                            color: "#fff",
                            background: "#00E396"
                        },
                        text: "CURRENT TARGET"
                    }
                }
            ]},*/
        dataLabels: {
            style: {
                colors: ['#000']
            }
        },
        markers: {
            colors: ['fff']
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent']
            },
            // column: {
            //     colors: ['#9C27B0']
            // },
            padding: {
            }
        },
        // plotOptions: {
        //     bar: {
        //         horizontal: false,
        //         columnWidth: '100%',
        //
        //         // endingShape: 'rounded'
        //     }
        // },
        xaxis: {
            labels: {
                formatter: function (val) {
                    return val
                },
                rotateAlways: true,
                show: true,
                rotate: -45
            },
            //tickPlacement: 'on',
            categories: props.data.labels,
        },
        yaxis: {
            labels: {
                formatter: function(value) {
                    let val: string | number = Math.abs(Number(value))
                    if (val >= 10 ** 3 && val < 10 ** 6) {
                        val = (val / 1000).toFixed(0) + ' K'
                    } else if (val >= 10 ** 6) {
                        val = (val / 1000000).toFixed(0) + ' M'
                    } else {
                        val = val;
                    }
                    return (val) ? val.toString(): ""
                }
            },
        },
        theme: {
            mode: useTheme().palette.mode
        },
        stroke: {
            show: true,
            width: 4,
        },
        title: {
            text: "Chart",
            align: 'left'
        },
        legend: {
            show: true,
            position: 'top',
            formatter(legendName: string, opts?: any): string {
                return legendName.split("_").map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return (val) ? val.toString(): ""
                }
            }
        }
    };

    return (

        // <Box
        //     sx={{
        //         alignItems: 'center',
        //         background: '#F4F8F9',
        //         borderTop: '10px',
        //         margin: '10px',
        //         // paddingLeft: {xs: '25px'},
        //         // paddingTop: {xs: '40px'},
        //         width: '100%',
        //         height: '90%',
        //         minHeight: {md:'500px', xs:''},
        //         boxShadow: 0,
        //         borderRadius: 1,
        //         padding: {md:'50px', xs: '10px'}
        //     }}
        //     key={"orgThemeColourPreviewApexCharts"}
        // >


        <Box
            sx={{
                width: '100%',
                height: '90%',
                minHeight: {md:'400px', xs: '300'},
                // boxShadow: 3,
                p: 2,
                borderRadius: 1,
            }}
        >
                    <Chart
                        type={chartType}
                        width={"100%"}
                        height={"150%"}

                        series={series}
                        options={chartOptions}
                    />



        </Box>

    );
}

export default LineGraphStat;