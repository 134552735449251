import type {FC} from 'react';
import React from 'react';
import logoPath from "./UnileverLogo.svg"

interface UnileverLogoProps extends  LogoSwitcherProps{
    [key: string]: any;
}

interface LogoSwitcherProps {
    org?: string;
    icon?: boolean;
}

const UnileverLogo: FC<UnileverLogoProps> = (icon, props) => (
    <img
        alt="Unilever Logo"
        src={logoPath}
        height={props.icon ? 100 : 70}
        width={props.icon ? 100 : 70}
        style={{
            margin: 1
        }}
        {...props}
    />
);
export default UnileverLogo;