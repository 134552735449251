import Tree, {Orientation, PathFunctionOption, RawNodeDatum, TreeNodeDatum} from "react-d3-tree";
import React, {FC, useCallback, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import {Box, Divider, Snackbar, Tooltip, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    ConvertHPNtoRND, ConvertNodeToEnglish,
    ConvertTreeFromRootNodeToString, ConvertTreeFromValueLimitToFilterLimit,
    GenerateGenericPSUNode,
    PSUArrayToStringValue,
    PSUArrayToStringValueButPretty,
    PSUValueStringToArray, SplitHerePattern, TreeForUseByPSU
} from "./utility/NodeTypesAndOptions";
import {
    AddOutlined,
    ArrowDropDownOutlined,
    ArrowDropUpOutlined,
    InfoOutlined,
    QuestionMark,
    ViewListOutlined
} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {ColumnMappingDictionaryWithData} from "../exlude/components/ColumnMappingDictionaryWithData";
import PSUSettingsComponent, {PSUinsideShadows} from "./components/PSUSettingsComponent";
import {useMainContext} from "../../../../contexts/MainContext";
import SalesViewDataPreviewComponent from "./components/SalesViewDataPreviewComponent";

const containerStyle = {
    width: '100%',
    height: '99%',
    // border: "1px solid red"
}

interface PSUTreeComponentProps {
    // TreeStructureData: RawNodeDatum
    UpdateTrees: (tree) => void
    setNeedsSaving: (needsSaving: boolean) => void
    colMapWithDataReference: ColumnMappingDictionaryWithData
    selectedNode: TreeNodeDatum
    setSelectedNode: (hpn: TreeNodeDatum) => void

    psuTree: TreeForUseByPSU
    handleTreeNameChange: (e) => void

    loadingBar: boolean

    needsSaving: boolean
    SaveTree: () => void
    DeleteTree: () => void

    orgLevelSwitch: boolean
    handleOrgLevelChange: (event, checked) => void
    TreeOrGrid: boolean
    setTreeOrGrid: (tog: boolean) => void
}

// PSUTreeComponent : Generates a tree
const PSUTreeComponent: FC<PSUTreeComponentProps> = (props) => {
    const mainCtx = useMainContext()
    const {enqueueSnackbar} = useSnackbar();
    const cogTheme = useTheme()

    const [treeState, setTreeState] = useState<RawNodeDatum>(props.psuTree.Tree)

    const [treeOrientation, setTreeOrientation] = useState<Orientation>("vertical")
    const [treePathShape, setTreePathShape] = useState<PathFunctionOption>("straight")
    // const [translate, containerRef] = useCenteredTree();
    // const translate = useCenteredTree();
    const translate = {x: 500, y: 150};
    const nodeDepthFactor = 300

    const nodeSize = {x: nodeDepthFactor, y: nodeDepthFactor};
    const foreignObjectProps = {
        width: treeOrientation == "vertical" ? nodeDepthFactor : nodeDepthFactor,
        height: treeOrientation == "vertical" ? nodeDepthFactor : nodeDepthFactor,
        x: treeOrientation == "vertical" ? 15 : -20,
        y: treeOrientation == "vertical" ? 15 : 20
    }

    const [count, setCount] = useState<number>(0)

    // controls the minimization of the logic preview
    const [minimizePseudoSnack, setMinimizePseudoSnack] = useState<boolean>(true)
    const [infoPage, setInfoPage] = useState<string>("grid") // Basically works like tabs

    // TODO :
    // This is here just to make sure the trees have the right kinda nodes, can be deleted once everyone that used the psu
    // has used and saved their trees again
    useEffect(() => {
        setTreeState(ConvertTreeFromValueLimitToFilterLimit(props.psuTree.Tree))
    }, []);

    const setTreeFromNode = (hpn) => {
        setTreeState(ConvertHPNtoRND(hpn))
        props.UpdateTrees(ConvertHPNtoRND(hpn))
    }

    const addChildNodeAtNode = (hpn) => {
        var nextData = hpn.data;
        if (nextData.children == null) {
            nextData = {
                ...nextData,
                children: []
            }
        }
        setCount(count + 1)
        nextData.children.push(GenerateGenericPSUNode());
        setTreeState(ConvertHPNtoRND(hpn));
        props.UpdateTrees(ConvertHPNtoRND(hpn))
        props.setNeedsSaving(true)
    };

    // Should there be a confirmation thing for removing stuff?
    const removeChildNodeAtNode = (hpn) => {
        if (hpn.parent == null) {
            // not allowed for now
            enqueueSnackbar("Hey stop tryna delete the root node!", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
            return
        }
        // Okii so first we have the node that needs removing, will delete its children too
        let forDeleteNodeID = hpn.data.__rd3t.id
        let parentNode = hpn.parent
        if (parentNode.data.children.length > 1) {
            let tempKids = []
            for (let i = 0; i < parentNode.data.children.length; i++) {
                if (parentNode.data.children[i].__rd3t.id != forDeleteNodeID) {
                    tempKids.push(parentNode.data.children[i])
                }
            }
            parentNode.data.children = tempKids
        } else {
            parentNode.data.children = []
        }
        // ConvertHPNtoRND(hpn)
        setTreeState(ConvertHPNtoRND(parentNode));
        props.setNeedsSaving(true)
        props.UpdateTrees(ConvertHPNtoRND(hpn))
        props.setSelectedNode(null)
    };

    return <Box id="treeWrapper"
                style={containerStyle}
    >
        {props.TreeOrGrid == false && <Tree
            data={treeState}
            orientation={treeOrientation}
            pathFunc={treePathShape}
            translate={translate}
            // hasInteractiveNodes={true}
            separation={{siblings: nodeDepthFactor / 100, nonSiblings: nodeDepthFactor / 100}}
            depthFactor={nodeDepthFactor}
            renderCustomNodeElement={(rd3tProps) =>
                renderForeignObjectNode(
                    {
                        ...rd3tProps,
                        addChildNodeAtNode,
                        removeChildNodeAtNode,
                        setTreeFromNode,
                        foreignObjectProps
                    }, props.colMapWithDataReference,
                    props.selectedNode,
                    props.setSelectedNode,
                    cogTheme
                )
            }
        ></Tree>}
        {
            props.TreeOrGrid == true &&
            <Box sx={{
                // height: "80vh",
                // width: "85vw",
                // backgroundColor: cogTheme.palette.background.default
            }}>
                <SalesViewDataPreviewComponent
                    tree={props.psuTree}
                    ClientName={mainCtx.activeOrganization.name}
                    styling={{
                        height: "79vh",
                        width: "85vw",
                    }}
                />
            </Box>
        }
        <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            open={true}
            sx={{
                translate: "14vw",
                zIndex: 2 // this gets it above other snackbars, specifically the settings
            }}
        >
            <Box
                sx={{
                    border: `1px solid ${cogTheme.palette.divider}`,
                    borderRadius: 2,
                    px: minimizePseudoSnack ? 0 : 2,
                    py: minimizePseudoSnack ? 0 : 1,
                    backgroundColor: cogTheme.palette.background.paper,
                    boxShadow: cogTheme.shadows[3],
                    whiteSpace: "pre-wrap",
                }}
            >
                {minimizePseudoSnack == false ? <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 1
                                }}
                            >
                                <Tooltip title={"Data Grid"}>
                                    <Box
                                        onClick={() => {
                                            // FetchSalesViewPreview()
                                            setInfoPage(("grid"))
                                        }}
                                        sx={{
                                            border: `1px solid ${cogTheme.palette.divider}`,
                                            borderRadius: 2,
                                            // p: 1,
                                            backgroundColor: cogTheme.palette.background.paper,
                                            boxShadow: PSUinsideShadows,
                                            display: "flex",
                                        }}
                                    >
                                        <ViewListOutlined fontSize={"large"}/>
                                    </Box>
                                </Tooltip>
                                <Tooltip title={"Query"}>
                                    <Box
                                        onClick={() => setInfoPage("sql")}
                                        sx={{
                                            border: `1px solid ${cogTheme.palette.divider}`,
                                            borderRadius: 2,
                                            // p: 1,
                                            backgroundColor: cogTheme.palette.background.paper,
                                            boxShadow: PSUinsideShadows,
                                            display: "flex",
                                        }}
                                    >
                                        <QuestionMark fontSize={"large"}/>
                                    </Box>
                                </Tooltip>
                            </Box>
                            <Tooltip title={"Click to Minimize"}>
                                <Box
                                    sx={{
                                        border: `1px solid ${cogTheme.palette.divider}`,
                                        borderRadius: 2,
                                        // p: 1,
                                        backgroundColor: cogTheme.palette.background.paper,
                                        boxShadow: PSUinsideShadows,
                                        display: "flex",
                                        // justifySelf: "flex-end",
                                        // alignSelf: "flex-end",
                                        // flexGrow: 0
                                    }}
                                    onClick={() => {
                                        setMinimizePseudoSnack(!minimizePseudoSnack)
                                    }}
                                >
                                    <InfoOutlined fontSize={"large"}/>
                                    <ArrowDropDownOutlined fontSize={"large"}/>
                                </Box>
                            </Tooltip>
                        </Box>
                        <Divider sx={{
                            m: 1
                        }}/>
                        <Box
                            sx={{
                                whiteSpace: "pre-wrap",
                                maxHeight: "70vh",
                                overflowY: "auto",
                                p: 1,
                                // border: `1px solid ${cogTheme.palette.divider}`,
                                // borderRadius: 2,
                                // boxShadow: PSUinsideShadows,
                                "&::-webkit-scrollbar": {
                                    width: 5,
                                    height: "5vh"
                                },
                                "&::-webkit-scrollbar-track": {
                                    backgroundColor: cogTheme.palette.background.default,
                                    borderRadius: 1,
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: cogTheme.palette.text.secondary,
                                    borderRadius: 2,
                                },

                            }}
                        >
                            {infoPage == "sql" && ConvertTreeFromRootNodeToString(treeState)}
                            {infoPage == "grid" && <Box sx={{
                                // height: "64vh",
                                // width: "70vw",
                                backgroundColor: cogTheme.palette.background.default
                            }}>
                                <SalesViewDataPreviewComponent
                                    tree={props.psuTree}
                                    ClientName={mainCtx.activeOrganization.name}
                                    styling={{
                                        height: "64vh",
                                        width: "70vw",
                                    }}
                                />
                            </Box>}
                        </Box>
                    </Box>
                    : <Tooltip title={"Click for additional info"}>
                        <Box
                            sx={{
                                border: `1px solid ${cogTheme.palette.divider}`,
                                borderRadius: 2,
                                // p: 1,
                                backgroundColor: cogTheme.palette.background.paper,
                                boxShadow: PSUinsideShadows,
                                display: "flex",
                            }}
                            onClick={() => {
                                setMinimizePseudoSnack(!minimizePseudoSnack)
                            }}
                        >
                            <InfoOutlined fontSize={"large"}/>
                            <ArrowDropUpOutlined fontSize={"large"}/>
                        </Box>
                    </Tooltip>
                }
            </Box>
            {/*<Tooltip title={minimizePseudoSnack == false ? "Click to minimize"*/}
            {/*    : "Click to Expand Logic Preview"}>*/}
            {/*    */}
            {/*</Tooltip>*/}
        </Snackbar>
        {
            props.loadingBar == false &&
            mainCtx.user != null &&
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={true}
                sx={{
                    translate: "0 7vh",
                    zIndex: 1
                }}
            >
                <Box>
                    {/* This goes in this file, so we can update the tree in this file as well as the container
                    putting it in the container makes this tree file values not update right
                    */}
                    <PSUSettingsComponent
                        needsSaving={props.needsSaving}
                        setNeedsSaving={props.setNeedsSaving}
                        selectedNode={props.selectedNode}
                        setSelectedNode={props.setSelectedNode}
                        SaveTree={props.SaveTree}
                        DeleteTree={props.DeleteTree}
                        treeOrGrid={props.TreeOrGrid}
                        setTreeOrGrid={props.setTreeOrGrid}
                        handleTreeNameChange={props.handleTreeNameChange}
                        orgLevelSwitch={props.orgLevelSwitch}
                        handleOrgLevelChange={props.handleOrgLevelChange}
                        colMapWithDataReference={props.colMapWithDataReference}
                        UpdateTrees={setTreeFromNode}
                        // treeUserID={props.psuTree.User_ID}
                        psuTree={props.psuTree}
                        treeOrientation={treeOrientation}
                        setTreeOrientation={setTreeOrientation}
                        treePathShape={treePathShape}
                        setTreePathShape={setTreePathShape}
                    />
                </Box>
            </Snackbar>
        }

    </Box>

}

// Here we're using `renderCustomNodeElement` render a component that uses
// both SVG and HTML tags side-by-side.
// This is made possible by `foreignObject`, which wraps the HTML tags to
// allow for them to be injected into the SVG namespace.
const renderForeignObjectNode = ({
                                     nodeDatum,
                                     hierarchyPointNode,
                                     toggleNode,
                                     addChildNodeAtNode,
                                     removeChildNodeAtNode,
                                     setTreeFromNode,
                                     foreignObjectProps
                                 }, colMapWithDataReference: ColumnMappingDictionaryWithData,
                                 selectedNode,
                                 setSelectedNode,
                                 cogTheme
) => {

    return (
        <g>
            <circle r={15}
                    style={{
                        fill: nodeDatum.children.length == 0 ? cogTheme.palette.background.default : cogTheme.palette.background.paper,
                        strokeWidth: nodeDatum.children.length == 0 ? 0.5 : 1,
                        stroke: (selectedNode != null && nodeDatum.__rd3t.id == selectedNode.data.__rd3t.id)
                        || (selectedNode != null && nodeDatum.attributes["tempID"] != null && nodeDatum.attributes["tempID"] == selectedNode.data.__rd3t.id)
                            ? "green" : cogTheme.palette.divider,
                        // boxShadow: cogTheme.shadows[3],
                    }}
            ></circle>
            {/* `foreignObject` requires width & height to be explicitly set. */}
            <foreignObject {...foreignObjectProps}
                           style={{
                               // border: "2px solid red"
                           }}
            >
                {RenderGenericNode(
                    nodeDatum,
                    hierarchyPointNode,
                    addChildNodeAtNode,
                    removeChildNodeAtNode,
                    toggleNode,
                    setTreeFromNode,
                    colMapWithDataReference,
                    selectedNode,
                    setSelectedNode,
                    cogTheme)}
            </foreignObject>
        </g>
    )
};

// RenderGenericNode : receives node data, and creates a basic interface
const RenderGenericNode = (
    nodeDatum,
    hierarchyPointNode,
    addChildNodeAtNode,
    removeChildNodeAtNode,
    toggleNode,
    setTreeFromNode,
    colMapWithDataReference,
    selectedNode,
    setSelectedNode,
    cogTheme
) => {
    let clicked: boolean = false
    let buttonStyle = {
        display: "flex",
        border: `1px solid ${cogTheme.palette.divider}`,
        boxShadow: PSUinsideShadows,
        borderRadius: 1,
        p: 0.8
    }
    //
    const GenerateBody = () => {
        let tempValAsArray: string[] = []
        // this one seems unnecessary, but its important because the json parse
        // and unparse things gets rid of some of the escape characters, and it is saved
        // without the escape characters, then fetching the tree makes the things crash
        if (nodeDatum.attributes["value"] != null) {
            tempValAsArray = PSUValueStringToArray(nodeDatum.attributes["value"].toString())
            if (nodeDatum.attributes["value"] != null && nodeDatum.attributes["value"] != PSUArrayToStringValue(tempValAsArray)) {
                hierarchyPointNode.data.attributes["value"] = PSUArrayToStringValue(tempValAsArray)
                setTreeFromNode(hierarchyPointNode)
            }
        }

        // There is definitely a better and/or more readable way to do this
        return <Typography sx={{whiteSpace: "pre-wrap", textAlign: "center"}}>
            {
                nodeDatum.attributes["nodeType"] != null &&
                ConvertNodeToEnglish(nodeDatum)
            }
        </Typography>

    }
    if (nodeDatum.name == "Generic Node") {
        return <Box
            sx={{
                boxShadow: `2px 2px 2px rgb(0, 0, 0, 0.2), 4px 4px 4px 4px rgb(0, ${
                    (selectedNode != null && nodeDatum.__rd3t.id == selectedNode.data.__rd3t.id)
                    || (selectedNode != null && nodeDatum.attributes["tempID"] != null && nodeDatum.attributes["tempID"] == selectedNode.data.__rd3t.id)
                        ? "100" : `1`
                }, 0, ${
                    (selectedNode != null && nodeDatum.__rd3t.id == selectedNode.data.__rd3t.id)
                    || (selectedNode != null && nodeDatum.attributes["tempID"] != null && nodeDatum.attributes["tempID"] == selectedNode.data.__rd3t.id)
                        ? "0.25" : `0.15`
                })`,
                mr: 1,
                // height: "95%",
                border: (selectedNode != null && nodeDatum.__rd3t.id == selectedNode.data.__rd3t.id)
                || (selectedNode != null && nodeDatum.attributes["tempID"] != null && nodeDatum.attributes["tempID"] == selectedNode.data.__rd3t.id)
                    ? "1px solid green" : `0px solid ${cogTheme.palette.divider}`,
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                // border: `1px solid ${useTheme().palette.divider}`,
                borderRadius: 2,
                backgroundColor: cogTheme.palette.background.paper,
                // boxShadow: useTheme().shadows[3],
                // minWidth: "15vw",
                // maxWidth: "19vw"
                // overflowY: "auto"
            }}
            onClick={() => {
                // if node is selected deselect it, else select it
                if ((selectedNode != null && selectedNode.data.__rd3t.id == nodeDatum.__rd3t.id)
                    || (nodeDatum.attributes["tempID"] != null && selectedNode != null
                        && nodeDatum.attributes["tempID"] == selectedNode.data.__rd3t.id)) {
                    setSelectedNode(null)
                } else {
                    setSelectedNode(null)
                    setSelectedNode(hierarchyPointNode)
                }
            }}
        >
            <Box sx={{
                // border: "1px solid black",
                // backgroundColor: "#dedede",
                // height: "100%",
            }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 0,
                        alignItems: "center",
                    }}>
                    <Tooltip title={"Add child node"}>
                        <Box sx={buttonStyle}
                        >
                            <AddOutlined
                                onClick={(e) => {
                                    e.stopPropagation() // to prevent the node from being selected
                                    addChildNodeAtNode(hierarchyPointNode)
                                }}
                            />
                        </Box>
                    </Tooltip>
                    <Box
                        sx={{
                            whiteSpace: "pre-wrap",
                            // lineHeight: 0.8,
                            // border: "1px solid red"
                        }}
                    >
                        <Typography variant={"h6"} sx={{
                            lineHeight: 1
                        }}>
                            {
                                nodeDatum.attributes["field"] != null && nodeDatum.attributes["field"]
                            }
                            {
                                "\n"
                                + nodeDatum.attributes["nodeType"]
                            }
                        </Typography>
                    </Box>
                    <Tooltip title={"Remove node and all children"}>
                        <Box sx={buttonStyle}
                        >
                            <DeleteIcon
                                onClick={(event) => {
                                    event.stopPropagation() // to prevent the node from being selected when deleted
                                    removeChildNodeAtNode(hierarchyPointNode)
                                }}
                            />
                        </Box>
                    </Tooltip>
                </Box>
                <Box sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 0
                }}>
                    {
                        nodeDatum.attributes != null &&
                        <GenerateBody/>
                    }
                </Box>
                {/* This here is for the expand and collapse functionality*/}
                {nodeDatum.children && nodeDatum.children.length > 0 &&
                    <Box
                        sx={{
                            p: 0,
                            mx: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            border: `1px solid ${cogTheme.palette.divider}`,
                            borderRadius: 3,
                            backgroundColor: cogTheme.palette.background.paper,
                            boxShadow: PSUinsideShadows,
                        }}
                    >
                        <Button
                            // style={{}}
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleNode()

                            }}
                            variant={"outlined"}
                            sx={{
                                p: 0,
                                m: 0,
                                display: "flex",
                                width: "100%",
                                alignSelf: "flex-end",
                                // flexDirection: "column",
                                // gap: 1,
                                // border: `1px solid ${cogTheme.palette.divider}`,
                                // borderRadius: 3,
                                // backgroundColor: cogTheme.palette.background.paper,
                                // boxShadow: PSUinsideShadows,
                            }}
                        >
                            {nodeDatum.__rd3t.collapsed ? "Expand" : "Collapse"}
                        </Button>
                    </Box>
                }
            </Box>

        </Box>
    }

    if (nodeDatum.name == "BaseNode") {
        return <Box>
            <Box sx={{
                boxShadow: `2px 2px 2px rgb(0, 0, 0, 0.2), 4px 4px 4px 4px rgb(0, 1, 0, 0.15)`,
                mr: 1,
                // height: "95%",
                border: `0px solid ${cogTheme.palette.divider}`,
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                // border: `1px solid ${useTheme().palette.divider}`,
                borderRadius: 2,
                backgroundColor: cogTheme.palette.background.paper,
                // boxShadow: useTheme().shadows[3],
                // minWidth: "15vw",
                // maxWidth: "19vw",
                // overflowY: "auto"
            }}>
                {/* This box contains the title and delete button*/}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 0,
                        alignItems: "center",
                    }}>
                    <Tooltip title={"Add child node"}>
                        <Box sx={buttonStyle}
                        >
                            <AddOutlined
                                onClick={(e) => {
                                    e.stopPropagation() // to prevent the node from being selected
                                    addChildNodeAtNode(hierarchyPointNode)
                                }}
                            />
                        </Box>
                    </Tooltip>
                    <Box
                        sx={{
                            whiteSpace: "pre-wrap",
                            // lineHeight: 0.8,
                            // border: "1px solid red"
                        }}
                    >
                        <Typography variant={"h5"}>{"All " + nodeDatum.attributes["client"] + " Data"}</Typography>
                    </Box>
                    {/*<Tooltip title={"Remove node and all children"}>*/}
                    {/*Keep the box for layout? either ay can't remove le root*/}
                    <Box
                    >
                        {/*        <DeleteIcon*/}
                        {/*            onClick={(event) => {*/}
                        {/*                event.stopPropagation() // to prevent the node from being selected when deleted*/}
                        {/*                removeChildNodeAtNode(hierarchyPointNode)*/}
                        {/*            }}*/}
                        {/*        />*/}
                    </Box>
                    {/*</Tooltip>*/}
                </Box>
                {/* This here is for the expand and collapse functionality*/}
                {nodeDatum.children && nodeDatum.children.length > 0 &&
                    <Box
                        sx={{
                            p: 0,
                            mx: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            border: `1px solid ${cogTheme.palette.divider}`,
                            borderRadius: 3,
                            backgroundColor: cogTheme.palette.background.paper,
                            boxShadow: PSUinsideShadows,
                        }}
                    >
                        <Button
                            // style={{}}
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleNode()

                            }}
                            variant={"outlined"}
                            sx={{
                                p: 0,
                                m: 0,
                                display: "flex",
                                width: "100%",
                                alignSelf: "flex-end",
                                // flexDirection: "column",
                                // gap: 1,
                                // border: `1px solid ${cogTheme.palette.divider}`,
                                // borderRadius: 3,
                                // backgroundColor: cogTheme.palette.background.paper,
                                // boxShadow: PSUinsideShadows,
                            }}
                        >
                            {nodeDatum.__rd3t.collapsed ? "Expand" : "Collapse"}
                        </Button>
                    </Box>
                }
            </Box>
        </Box>
    }
}


export default PSUTreeComponent;