import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    Typography,
} from "@mui/material";
import ConfirmActionModal from "./ConfirmActionModal";
import {Card as KanbanCardObj} from "../../../../cog/keystone/card";
import {useMainContext} from "../../../../contexts/MainContext";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {ColumnMappingDictionaryWithData} from "./components/ColumnMappingDictionaryWithData";
import {ActionLogStore} from "../../../../cog/keystone/actionLog";
import {ExcludeStore} from "../../../../cog/keystone/exclude";
import {useSnackbar} from "notistack";
import {useTheme} from "@mui/material/styles";

interface IgnoreDialogueProps {
    issueName: string,
    clientName: string
    // header: string
    onClick: () => void;
    card: KanbanCardObj
}

let dialogBody = () => {
    return (
        <div>
        </div>
    )
};

 /** IgnoreDialogue :
  * Handles the creation of an excludeFilter for a single Opportunity
  * */
const IgnoreDialogue: FC<IgnoreDialogueProps> = (props) => {
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext();
    const {enqueueSnackbar} = useSnackbar();
    const [confirmActionOpen, setConfirmActionOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [checkedState, setCheckedState] = useState<Record<string, boolean>>(null);
    const [colMapWithDataReference, setColMapWithDataReference] = useState<ColumnMappingDictionaryWithData>(null)

    const prepCheckBoxes = () => {
        let tempBoxes = {}
        Object.keys(JSON.parse(props.card.levelFilter)).map((k, i) => {
            tempBoxes = {
                ...tempBoxes,
                [k]: false
            }
        })
        // console.log("tempBoxes", tempBoxes)
        // console.log("props.card", props.card)
        setCheckedState(tempBoxes)
    }

    // Make filters happen on page load
    useLayoutEffect(() => {
        prepCheckBoxes()
    }, [])

    useEffect(() => {
        if (keystoneCtx.colMap != null && colMapWithDataReference == null) {
            let tempMapRef = new ColumnMappingDictionaryWithData()
            tempMapRef.PopulateWithColMap(keystoneCtx.colMap)
            tempMapRef.PopulateWithLevelFilter(props.card.levelFilter)
            // console.log("tempMapRef", tempMapRef)
            setColMapWithDataReference(tempMapRef)
        }
    }, [keystoneCtx.colMap]);


    function PrintEverything(moreInfo: string) {
    }

    const handleConfirmDialogue = () => {
        setConfirmActionOpen(true)
        dialogBody = () => {
            return (
                <ConfirmActionModal
                    cancelAction={handleConfirmClose}
                    confirmAction={handleConfirmUpdate}
                    confirmationWarningMessage={"Warning, This will exclude all items that match at least one value under each header, for all users, continue?"}
                />
            );
        }
        // setExcDialogOpen(true);
    }

    const handleConfirmClose = () => {
        setConfirmActionOpen(false);
    };

    // handleConfirmUpdate : Confirms the creating of an exclude filter
    async function handleConfirmUpdate() {
        setIsLoading(true)
        let tempMap = new ColumnMappingDictionaryWithData(colMapWithDataReference)
        // console.log("tempMap", tempMap)
        tempMap.CleanWithBoolRecord(checkedState)
        // console.log("tempMap", tempMap)
        handleConfirmClose()
        ActionLogStore.InsertOne({
            userID: mainCtx.user.id,
            orgID: mainCtx.activeOrganization.id,
            cardID: props.card.id,
            actionType: "Opportunity Excluded",
            timezone: "",
            clientName: mainCtx.activeOrganization.name
        })
        // filter checks for null in the colMapWithDataReference, because if that is null the tempMap will be wrong as well
        ExcludeStore.AddToExclude({
            client: mainCtx.activeOrganization.name,
            filter: colMapWithDataReference != null ? tempMap.GimmeJsonString() : "",
            userID: mainCtx.user.id
        }).then(() => {
            enqueueSnackbar('Exclude Filter Updated', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success',
                autoHideDuration: 2500,
                preventDuplicate: true
            });
            setIsLoading(false)
            props.onClick()
        })
    }

    //
    useLayoutEffect(() => {
        setIsLoading(false)
    }, []);

    // behaviour is weird without this refresh
    useEffect(() => {
    }, [])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("event", event)
        setCheckedState({
            ...checkedState,
            [event.target.name]: event.target.checked,
        });
    };

    const boxStyle = {
        border: `1px solid ${useTheme().palette.divider}`,
        borderRadius: 0.5,
        boxShadow: useTheme().shadows[4],
        px: 1
    }

    const formContrStyle = {
        // border: "1px solid red",
        width: "100%",
        ml: 0.5,
    }

    return (
        <Box>
            <DialogTitle>Create Exclude Filter</DialogTitle>
            <Divider/>
            <Grid container
                  display={"grid"}
                  gridTemplateColumns={"1fr"}
                  width={"98%"}
                  gridAutoFlow={"row"}
                  gridAutoRows={"auto"}
                  gap={1}
                  p={1}
                  m={1}>
                { // Overall_Hierarchy_1
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Overall_Hierarchy_1 != ""
                    && colMapWithDataReference.Overall_Hierarchy_1_Data.length > 0
                    && checkedState[colMapWithDataReference.Overall_Hierarchy_1] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Overall_Hierarchy_1] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Overall_Hierarchy_1 + " : " + colMapWithDataReference.Overall_Hierarchy_1_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Overall_Hierarchy_1]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Overall_Hierarchy_1}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Overall_Hierarchy_2 todo : maybe make these generic functional components
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Overall_Hierarchy_2 != ""
                    && colMapWithDataReference.Overall_Hierarchy_2_Data.length > 0
                    && checkedState[colMapWithDataReference.Overall_Hierarchy_2] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Overall_Hierarchy_2] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Overall_Hierarchy_2 + " : " + colMapWithDataReference.Overall_Hierarchy_2_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Overall_Hierarchy_2]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Overall_Hierarchy_2}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Product_Hierarchy_1
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Product_Hierarchy_1 != ""
                    && colMapWithDataReference.Product_Hierarchy_1_Data.length > 0
                    && checkedState[colMapWithDataReference.Product_Hierarchy_1] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Product_Hierarchy_1] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Product_Hierarchy_1 + " : " + colMapWithDataReference.Product_Hierarchy_1_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Product_Hierarchy_1]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Product_Hierarchy_1}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Product_Hierarchy_2
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Product_Hierarchy_2 != ""
                    && colMapWithDataReference.Product_Hierarchy_2_Data.length > 0
                    && checkedState[colMapWithDataReference.Product_Hierarchy_2] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Product_Hierarchy_2] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Product_Hierarchy_2 + " : " + colMapWithDataReference.Product_Hierarchy_2_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Product_Hierarchy_2]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Product_Hierarchy_2}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Product_Hierarchy_3
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Product_Hierarchy_3 != ""
                    && colMapWithDataReference.Product_Hierarchy_3_Data.length > 0
                    && checkedState[colMapWithDataReference.Product_Hierarchy_3] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Product_Hierarchy_3] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Product_Hierarchy_3 + " : " + colMapWithDataReference.Product_Hierarchy_3_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Product_Hierarchy_3]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Product_Hierarchy_3}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Product_Hierarchy_4
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Product_Hierarchy_4 != ""
                    && colMapWithDataReference.Product_Hierarchy_4_Data.length > 0
                    && checkedState[colMapWithDataReference.Product_Hierarchy_4] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Product_Hierarchy_4] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Product_Hierarchy_4 + " : " + colMapWithDataReference.Product_Hierarchy_4_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Product_Hierarchy_4]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Product_Hierarchy_4}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Product
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Product != ""
                    && colMapWithDataReference.Product_Data.length > 0
                    && checkedState[colMapWithDataReference.Product] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Product] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Product + " : " + colMapWithDataReference.Product_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Product]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Product}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Customer_Hierarchy_1
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Customer_Hierarchy_1 != ""
                    && colMapWithDataReference.Customer_Hierarchy_1_Data.length > 0
                    && checkedState[colMapWithDataReference.Customer_Hierarchy_1] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Customer_Hierarchy_1] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Customer_Hierarchy_1 + " : " + colMapWithDataReference.Customer_Hierarchy_1_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Customer_Hierarchy_1]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Customer_Hierarchy_1}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Customer_Hierarchy_2
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Customer_Hierarchy_2 != ""
                    && colMapWithDataReference.Customer_Hierarchy_2_Data.length > 0
                    && checkedState[colMapWithDataReference.Customer_Hierarchy_2] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Customer_Hierarchy_2] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Customer_Hierarchy_2 + " : " + colMapWithDataReference.Customer_Hierarchy_2_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Customer_Hierarchy_2]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Customer_Hierarchy_2}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Customer_Hierarchy_3
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Customer_Hierarchy_3 != ""
                    && colMapWithDataReference.Customer_Hierarchy_3_Data.length > 0
                    && checkedState[colMapWithDataReference.Customer_Hierarchy_3] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Customer_Hierarchy_3] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Customer_Hierarchy_3 + " : " + colMapWithDataReference.Customer_Hierarchy_3_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Customer_Hierarchy_3]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Customer_Hierarchy_3}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Area_Hierarchy_1
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Area_Hierarchy_1 != ""
                    && colMapWithDataReference.Area_Hierarchy_1_Data.length > 0
                    && checkedState[colMapWithDataReference.Area_Hierarchy_1] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Area_Hierarchy_1] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Area_Hierarchy_1 + " : " + colMapWithDataReference.Area_Hierarchy_1_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Area_Hierarchy_1]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Area_Hierarchy_1}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Area_Hierarchy_2
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Area_Hierarchy_2 != ""
                    && colMapWithDataReference.Area_Hierarchy_2_Data.length > 0
                    && checkedState[colMapWithDataReference.Area_Hierarchy_2] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Area_Hierarchy_2] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Area_Hierarchy_2 + " : " + colMapWithDataReference.Area_Hierarchy_2_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Area_Hierarchy_2]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Area_Hierarchy_2}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
                { // Area_Hierarchy_3
                    checkedState != null
                    && colMapWithDataReference != null
                    && colMapWithDataReference.Area_Hierarchy_3 != ""
                    && colMapWithDataReference.Area_Hierarchy_3_Data.length > 0
                    && checkedState[colMapWithDataReference.Area_Hierarchy_3] != null &&
                    <Box sx={{
                        backgroundColor: checkedState[colMapWithDataReference.Area_Hierarchy_3] == true
                            ? useTheme().palette.background.default
                            : useTheme().palette.background.paper,
                        ...boxStyle}}>
                        <FormControlLabel
                            sx={formContrStyle}
                            label={colMapWithDataReference.Area_Hierarchy_3 + " : " + colMapWithDataReference.Area_Hierarchy_3_Data}
                            control={<Checkbox
                                checked={checkedState[colMapWithDataReference.Area_Hierarchy_3]}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                name={colMapWithDataReference.Area_Hierarchy_3}
                                disabled={isLoading}
                            />}
                        />
                    </Box>
                }
            </Grid>


            {/*Maybe look at this if you wanna make the above generic, this one is from a lost version though
            so it wont work here */}
            {/*<FormGroup>*/}
            {/*    {props.card != null && state != null && Object.entries(props.card).map(([k, v], i) => {*/}
            {/*        return <FormControlLabel*/}
            {/*            control={*/}
            {/*                <Checkbox checked={state[k]} onChange={handleChange} name={k} />*/}
            {/*            }*/}
            {/*            label={k + ": " + v}*/}
            {/*        />*/}
            {/*    })}*/}
            {/*</FormGroup>*/}


            <Divider/>
            <DialogContent>
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    {isLoading ? <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            gap: "10px",
                            px: 0.2
                        }}>
                            <Typography sx={{pt: 1}}>Creating Exclude Filter</Typography>
                            <CircularProgress/>
                        </Box>
                        :
                        <ButtonGroup>
                            <Button
                                onClick={() => {
                                    handleConfirmDialogue()
                                    // handleConfirmUpdate().then()
                                }}
                                variant="outlined"
                                disabled={isLoading}
                            >
                                Ignore Card
                            </Button>
                            <Button variant="outlined" onClick={props.onClick} disabled={isLoading}>
                                Close
                            </Button>
                        </ButtonGroup>
                    }
                </Box>
            </DialogContent>
            <Dialog
                //disableEnforceFocus required to open chat if Dialog opened first
                disableEnforceFocus
                open={confirmActionOpen}
                onClose={handleConfirmClose}
                aria-labelledby="card-exclude-dialog"
                fullWidth
                maxWidth="md"
            >
                {dialogBody()}
            </Dialog>
        </Box>
    );
}

export default IgnoreDialogue;