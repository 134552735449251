import * as React from "react";
import {FC} from "react";
import {Box, Chip, Grid, Tooltip} from "@mui/material";
import {Label, LocalOffer} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";

interface HomeGoalTagComponentProps {
    TagSting: string
}

const HomeGoalTagComponent: FC<HomeGoalTagComponentProps> = (props) => {
    try {
        let test = JSON.parse(`{"tag": ${props.TagSting} }`)['tag']
    } catch (e) {
        // console.log(props.v)
        return <Tooltip title={"empty"}>
            <div></div>
        </Tooltip>
    }

    let tempOppLabels = JSON.parse(`{"opportunityLabels": ${props.TagSting} }`)['opportunityLabels']

    const DisplayOppLabels = () => {
        if (tempOppLabels.length == 0) {
            return <Chip variant="outlined"
                         size="small"
                         icon={<Box/>}
                         label={"No Tags"}
                         sx={{
                             borderColor: useTheme().palette.primary.main,
                             padding: 1,
                         }}

            />
        }
        let tooltipTags: string = ""
        for (let i = 0; i < tempOppLabels.length; i++) {
            if (i > 0) {
                if (i > 1) {
                    tooltipTags += ", "
                }
                tooltipTags += tempOppLabels[i]
            }
        }
        return <Box sx={{
        }}>
            <Chip variant="outlined"
                  size="small"
                  icon={<Label style={{color: useTheme().palette.primary.main}}/>}
                  label={tempOppLabels[0]}
                  sx={{
                      borderColor: useTheme().palette.primary.main,
                      padding: 1,
                  }}

            />
            {tempOppLabels.length > 1 && <Tooltip title={tooltipTags}>
                <Chip variant="outlined"
                      size="small"
                      icon={<Box/>}
                      label={" + " + (tempOppLabels.length - 1) + " others"}
                      sx={{
                          borderColor: useTheme().palette.primary.main,
                          padding: 1,
                          mt: 0.5
                      }}

                />
            </Tooltip>}
        </Box>
    }

    return <Box sx={{p: 1}}>
        <Box sx={{
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            gap: 1,
            alignItems: "center"
        }}>
            <Tooltip title={"Goal Tags"}>
                <LocalOffer fontSize={"large"}/>
            </Tooltip>
            <DisplayOppLabels/>
        </Box>
    </Box>
}

export default HomeGoalTagComponent;