import { FC } from "react";
import BullRedLogo from "../../../../../icons/logos/BullRedLogo";
import WutowLogo from "../../../../../icons/logos/WutowLogo";
import CASalesLogo from "../../../../../icons/logos/CASalesLogo";
import LogicoLogo from "../../../../../icons/logos/LogicoLogo";
import UnileverLogo from "../../../../../icons/logos/UnileverLogo";
import KimberlyClarkLogo from "../../../../../icons/logos/KimberlyClarkLogo";
import RBLogo from "../../../../../icons/logos/RBLogo";
import EditLogo from "../../../../../icons/logos/EditLogo";
import CloverLogo from "../../../../../icons/logos/CloverLogo";
import RCLLogo from "../../../../../icons/logos/RCLLogo";
import SMCLogo from "../../../../../icons/logos/SMCLogo";
import HEDLogo from "../../../../../icons/logos/HEDLogo";
import MondelezLogo from "../../../../../icons/logos/Mondelez";
import KimberlyClarkLogoIcon from "../../../../../icons/logos/KimberlyClarkLogoIcon";
import BullRedLogoIcon from "../../../../../icons/logos/BullRedLogoIcon";
import RainbowChickensLogo_Icon from "../../../../../icons/logos/Rainbow-Chickens-Logo_Icon";
import WutowLogoIcon from "../../../../../icons/logos/WutowLogoIcon";
import PNSLogo from "../../../../../icons/logos/PNSLogo";
import PNSLogoIcon from "../../../../../icons/logos/PNSLogoIcon";

interface LogoSwitcherProps {
  org: string;
  icon?: boolean;
}

const LogoSwitcher: FC<LogoSwitcherProps> = ({ icon, org }) => {
  //added an optional "icon": boolean added additional styling inside certain logo.tsx to adapt them into more suitable icons
  // in other cases I've added other logos which are edited to get better fitting icons for OrgSelect on the compressed sidebar
  switch (org) {
    case "casales":
      return <CASalesLogo icon={icon} />;
    case "management":
      return <CASalesLogo icon={icon} />;
    case "logico":
      return <LogicoLogo icon={icon} />;
    case "br-distribution":
      if (icon) {
        return <BullRedLogo />;
      } else {
        return <BullRedLogo />;
      }
    case "wutow":
      if (icon) {
        return <WutowLogoIcon />;
      } else {
        return <WutowLogo />;
      }
    case "unilever":
      return <UnileverLogo />;
    case "hed":
      return <HEDLogo />;
    case "kimberly-clark":
      if (icon) {
        return <KimberlyClarkLogoIcon />;
      } else {
        return <KimberlyClarkLogo />;
      }
    case "kimberly-clark-ea":
      if (icon) {
        return <KimberlyClarkLogoIcon />;
      } else {
        return <KimberlyClarkLogo />;
      }
    case "checkers-kc":
      if (icon) {
        return <KimberlyClarkLogoIcon />;
      } else {
        return <KimberlyClarkLogo />;
      }
    case "pnp-kc":
      if (icon) {
        return <KimberlyClarkLogoIcon />;
      } else {
        return <KimberlyClarkLogo />;
      }
    case "masscash-kc":
      if (icon) {
        return <KimberlyClarkLogoIcon />;
      } else {
        return <KimberlyClarkLogo />;
      }
    case "mass-discounters-kc":
      if (icon) {
        return <KimberlyClarkLogoIcon />;
      } else {
        return <KimberlyClarkLogo />;
      }
    case "makro-kc":
      if (icon) {
        return <KimberlyClarkLogoIcon />;
      } else {
        return <KimberlyClarkLogo />;
      }
    case "checkers-rb":
      return <RBLogo />;
    case "rcl":
      return <RCLLogo />;
    case "rainbow":
      if (icon) {
        return <RainbowChickensLogo_Icon />;
      } else {
        return <RCLLogo />;
      }
    case "clover":
      return <CloverLogo />;
    case "mondelez":
      return <MondelezLogo />;
    case "smc":
      return <SMCLogo />;
    case "editing":
      return <EditLogo />;
    case "NO LOGO":
      return null;
    case "pns":
      if (icon) {
        return <PNSLogoIcon />;
      } else {
        return <PNSLogo />;
      }
    default:
      console.log("No Org Set");
      return <EditLogo />;
  }
};
export default LogoSwitcher;
