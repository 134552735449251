/* eslint-disable */
import toLowerCase from "../../utils/toLowerCase";

export default class Organization {
    public id: string = '';
    public name: string = '';
    public slug: string = '';

    constructor(organization?: Organization) {
        if (!organization) {
            return;
        }
        this.id = organization.id;
        this.name = organization.name;
        this.slug = toLowerCase(organization.name);
    }
}
