/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../api/jsonrpc';
import {OrgDetails, Permission, Resource, SubjectFilter, User, UserDoc} from "../../types/authz";
import {Subject} from "../bitool/store";

export interface ReadRelationshipRequest {
    ResourceType: string
    OptionalResourceID?: string
    OptionalRelation?: string
    OptionalSubjectFilter?: SubjectFilter
}

export interface ReadRelationshipsResponse {
    zed_token: string
    relationship: string[]
}

export interface LoadOrgPermsRequest {
    orgIds: string[]
}
export interface LoadUserNamesRequest {
    users: User[]
}
export interface UpdateRoleRequest {
    id: string
    name: string
    organization_id?: string
    description: string
}
export interface UpdateRoleResponse {

}
export interface UpdateRoleResponse {

}
export interface LoadUserStatsRequest {
    users: User[]
}
export interface LoadUserStatsResponse {
    UserDoc: UserDoc[]
}
export interface LoadUserNamesResponse {
    users: User[]
}



interface LookUpPermissionsRequest {
    ResourceType: string
    Permission:   string
    Subject:      SubjectFilter
}
interface WriteRelationshipRequest {
    resource: Resource
    relation: string
    subject: SubjectFilter

}
interface WriteRelationshipResponse {
    ZedToken: string
    Success: boolean
}
interface DeleteRelationshipRequest {
    resource: Resource
    relation: string
    subject: SubjectFilter
}
interface DeleteRelationshipResponse {
    ZedToken: string
    Success: boolean
}

interface LookUpPermissionsResponse {
    Permissions: Permission[]
}
export interface LoadOrgPermsResponse {
    OrganizationPermissions: OrgDetails[]
}

export interface  Resource2 {
    object_type: string
    object_id:   string
}

export interface AccessRequest {
    resource:       Resource2
    permission_type: string
    subject:        Subject

}
export interface KeystoneAccessRequest {
    user_id: string
    org_id: string

}

export interface KeystoneAccessResponse {
    user_permission: boolean
    admin_permission: boolean
}
export interface CreateRoleRequest {
    name: string
    organization_id: string
    users: string[]
    dashboards: string[]
    keystoneAccess: string []
    description: string
}

export interface CreateRoleResponse {
    id: string
}

export interface DeleteRoleRequest {
    id: string
    orgID: string
    dashboards: string[]
    users: string[]
}

export interface DeleteRoleResponse {
    success: boolean
}
export interface ImportUserRequest {
    display_name: string
    email: string
    avatar: string
    organization_id: string
}
export interface ImportUserResponse {
    id:             string
    Name:          string
    Avatar:        string
    Email:         string
    LastLoggedIn:  string | Date
    LastLoggedOut: string | Date
    LastAccessed:   string | Date
    SessionLength: string
    DaysLogged: string[]
}
export interface DeleteUserRequest {
    uid: string
}
export interface DeleteUserResponse {

}

const Store = {
    async CheckAccess(request: AccessRequest): Promise<boolean> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Authz-Store.CheckPermissions`,
            request: [
                {
                    resource: request.resource,
                    permission_type: request.permission_type,
                    subject: request.subject
                }
            ]
        })
        let nResponse = await response;
        return nResponse.Permission;
    },
    async CheckKeystoneAccess(request: KeystoneAccessRequest): Promise<KeystoneAccessResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Authz-Store.CheckKeystoneAccess`,
            request: [
                {
                    user_id: request.user_id,
                    org_id: request.org_id
                }
            ]
        })
        return await response;
    },
    async LoadOrgPerms(request: LoadOrgPermsRequest): Promise<LoadOrgPermsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Admin-Portal-Store.LoadOrgPermissions`,
            request: [{
                "organizations": request.orgIds
            }]
        })
        return response
    },
    async UpdateRole(request: UpdateRoleRequest): Promise<UpdateRoleResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Admin-Portal-Store.UpdateRole`,
            request: [{
                "role" :{
                "id": request.id,
                "name": request.name,
                "description": request.description

            }}]
        })
        return response
    },

    async LoadUserNames(request: LoadUserNamesRequest): Promise<LoadUserNamesResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Admin-Portal-Store.LoadUserNames`,
            request: [{
                "users": request.users
            }]
        })
        return response
    },
    async LoadUserStats(request: LoadUserStatsRequest): Promise<LoadUserStatsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Admin-Portal-Store.LoadUserStats`,
            request: [{
                "users": request.users
            }]
        })
        return response.UserDoc ? response : response.UserDoc = []
    },
    async LookUpPermissions(request: LookUpPermissionsRequest): Promise<LookUpPermissionsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Authz-Store.LookUpPerms`,
            request: [{
                "subject": {
                    "subject_type": request.Subject.SubjectType,
                    "subject_id": request.Subject.OptionalSubjectID,
                    "optional_relation": request.Subject.OptionalRelation
                },
                "permission": request.Permission,
                "resource_type": request.ResourceType
            }]
        })
        return response
    },
    async WriteRelationship(request: WriteRelationshipRequest): Promise<WriteRelationshipResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Authz-Store.WriteRelationships`,
            request: [{
                "resource": {
                    "object_type": request.resource.resourceType,
                    "object_id": request.resource.resourceID
                },
                "relation": request.relation,
                "subject": {
                    "subject_type": request.subject.SubjectType,
                    "subject_id": request.subject.OptionalSubjectID,
                    "optional_relation": request.subject.OptionalRelation
                }
            }]
        })
        return response
    },
    async DeleteRelationship(request: DeleteRelationshipRequest): Promise<DeleteRelationshipResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Authz-Store.DeleteRelationship`,
            request: [{
                "relationship_filter": {
                    "resource_type": request.resource.resourceType,
                    "optional_resource_id": request.resource.resourceID,
                    "optional_relation": request.relation,
                    "optional_subject_filter": {
                        "subject_type": request.subject.SubjectType,
                        "optional_subject_id": request.subject.OptionalSubjectID,
                        "optional_relation": request.subject.OptionalRelation
                    }
                },
                "optional_preconditions": [{}]
            }]
        })
        return response
    },
    async CreateRole(request: CreateRoleRequest): Promise<CreateRoleResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Admin-Portal-Store.CreateRole`,
            request: [{
                "policy_group": {
                    "name": request.name,
                    "organization_id": request.organization_id,
                    "description": request.description
                },
                "dashboards": request.dashboards,
                "users": request.users,
                "keystoneAccess" : request.keystoneAccess
            }]
        })
        return response
    },
    async DeleteRole(request: DeleteRoleRequest): Promise<DeleteRoleResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Admin-Portal-Store.DeleteRole`,
            request: [{
                "id": request.id,
                "org_id": request.orgID,
                "dashboards": request.dashboards,
                "users": request.users,
            }]
        })
        return response
    },
    async ImportUser(request: ImportUserRequest): Promise<ImportUserResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Admin-Portal-Store.ImportUser`,
            request: [{
                "display_name": request.display_name,
                "email": request.email,
                "avatar": request.avatar,
                "organization_id": request.organization_id
            }]
        })
        return response
    },
    async DeleteUser(request: DeleteUserRequest): Promise<DeleteUserResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Admin-Portal-Store.DeleteUser2`,
            request: [{
                "uid": request.uid
            }]
        })
        return response
        },
    async ReadRelationship(request: ReadRelationshipRequest) : Promise<ReadRelationshipsResponse>{
        //console.log(request)

        // ResourceType: string
        //     OptionalResourceID?: string
        //     OptionalRelation?: string
        //     OptionalSubjectFilter?: SubjectFilter
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Authz-Store.ReadRelationships`,
            request: [{
                "resource_type": request.ResourceType,
                "optional_resource_id": request.OptionalResourceID,
                "optional_relation": request.OptionalRelation,
                "optional_subject_filter": {
                    "subject_type" : request.OptionalSubjectFilter.SubjectType,
                    "optional_subject_id" : request.OptionalSubjectFilter.OptionalSubjectID,
                    "optional_relation" : request.OptionalSubjectFilter.OptionalRelation,

                }
            }]
        })
        //console.log("AdminManagementStore Read Relationship request", request)
        //console.log("AdminManagementStore Read Relationship respone", response)
        return response





    }
}
export default Store;