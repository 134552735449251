import React, {FC, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";
import {CampaignGoalStore} from "../../../../../../cog/keystone/goals/campaigns";
import {useSnackbar} from "notistack";
import Goal from "../../../../../../cog/keystone/goals/goal/Goal";
import {useMainContext} from "../../../../../../contexts/MainContext";
import Campaign from "../../../../../../cog/keystone/goals/campaigns/Campaign";
import {Remove} from "@mui/icons-material";

interface ConfirmCampaignSettingsDialogProps {
    confirmMessage: string;
    type: string;
    goal: Goal;
    handleDialogClose: () => void
    loadingMethod: (v: boolean) => void;
    setGoal?: (g: Goal) => void;
    admin: boolean;
    campaigns: Campaign[];
}

const ConfirmCampaignSettingsDialog: FC<ConfirmCampaignSettingsDialogProps> = ({
                                                                                                   confirmMessage,
                                                                                                   goal,
                                                                                                   handleDialogClose,
                                                                                                   loadingMethod,
                                                                                                   setGoal,
                                                                                                   admin,
                                                                                                   campaigns
                                                                                               }) => {
    const mainCtx = useMainContext();
    const {enqueueSnackbar} = useSnackbar();

    const [isDisabled, setIsDisabled] = useState(false)
    const handleUpdateCampaign = async () => {
        loadingMethod(true)
        setIsDisabled(true)
        if (admin) {
            const response = await CampaignGoalStore.InsertCampaignGoalQuery({
                campaignIDs: campaigns,
                clientName: mainCtx.activeOrganization.name,
                goalID: goal.id
            })
            if (response) {
                setIsDisabled(false)
                enqueueSnackbar('Campaign Goals Updated', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
            } else {
                setIsDisabled(false)
                enqueueSnackbar('Failed to Update Campaign Goals', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }

        } else {
            setIsDisabled(false)
            enqueueSnackbar('Cannot Update Campaign Goals, Admin Permissions Required.  Please Contact an Administrator.', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        loadingMethod(false)
        handleDialogClose()
    }

    return (
        <Box sx={{m: 2}}>
            <DialogTitle>{"Update Campaign Goals"}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridAutoFlow={"row"}
                    gridAutoRows={"auto"}
                    gap={1}
                >
                    <Box>{confirmMessage}</Box>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonGroup variant={"text"}>
                    <Button
                        onClick={handleUpdateCampaign}
                        disabled={isDisabled}
                    >
                        {isDisabled ? <CircularProgress size={20}/> : "Confirm"}
                    </Button>
                    <Button
                        onClick={() => handleDialogClose()}
                        disabled={isDisabled}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Box>
    );
}

export default ConfirmCampaignSettingsDialog;