/* eslint-disable */

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';
import {Provider as ReduxProvider} from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import App from './App';
import {SettingsProvider} from './contexts/SettingsContext';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import store from './store';
import {MainContext} from './contexts/MainContext';
import {KeystoneContext} from './contexts/KeystoneContext'
import config from 'react-global-configuration';
import {devConfig, localConfig, mainConfig, qaConfig} from "./config";

//Before merge
let subdomain = 'localhost';
try {
    subdomain = window.location.hostname.split('.')[0];
} catch (e) {
    console.error('error determining subdomain', e);
}

const localUrl = 'http://localhost:3000/'
const devUrl = 'https://dev-cog-frontend.web.app/'
const qaUrl = 'https://qa-cog-frontend.web.app/'
const mainUrl = 'https://main-cog-frontend.web.app/'
const betaUrl = 'https://beta.cognizance.vision/'
const appUrl = 'https://app.cognizance.vision/'

const localApi = 'http://localhost:8080/'
const devApi = 'https://dev-cog-analytics-engine-go-ozbksvuw7a-uc.a.run.app/'
const qaApi = 'https://qa-cog-analytics-engine-go-ozbksvuw7a-uc.a.run.app/'
const mainApi = 'https://main-cog-analytics-engine-go-ozbksvuw7a-uc.a.run.app/'

const authorizedApiUrl = 'api/authorised'
const publicApiUrl = 'api/token'

let baseUrl
let baseApi
let baseFirebase
let analyticsID
let analytics4ID

/*let baseUrl = subdomain === 'localhost' ? localUrl : devUrl
let baseApi = subdomain === 'localhost' ? localApi : devApi*/
switch (subdomain) {
    case "dev-cog-frontend":
        baseUrl = devUrl
        baseApi = devApi
        baseFirebase = devConfig.firebase
        analyticsID =  devConfig.analytics
        analytics4ID =  devConfig.analytics4
        break
    case "localhost":
        baseUrl = localUrl
        baseApi = localApi
        baseFirebase = localConfig.firebase
        analyticsID =  localConfig.analytics
        analytics4ID =  localConfig.analytics4
        break
    case "main-cog-frontend":
        baseUrl = mainUrl
        baseApi = mainApi
        baseFirebase = mainConfig.firebase
        analyticsID =  mainConfig.analytics
        analytics4ID =  mainConfig.analytics4
        break
    case "beta":
        baseUrl = betaUrl
        baseApi = mainApi
        baseFirebase = mainConfig.firebase
        analyticsID =  mainConfig.analytics
        analytics4ID =  mainConfig.analytics4
        break
    case "app":
        baseUrl = appUrl
        baseApi = mainApi
        baseFirebase = mainConfig.firebase
        analyticsID =  mainConfig.analytics
        analytics4ID =  mainConfig.analytics4
        break
    case "qa-cog-frontend":
        baseUrl = qaUrl
        baseApi = qaApi
        baseFirebase = qaConfig.firebase
        analyticsID =  qaConfig.analytics
        analytics4ID =  qaConfig.analytics4
        break
}

config.set({
    baseUrl: baseUrl,
    apiUrl: baseApi + publicApiUrl,
    authorizedApiUrl: baseApi + authorizedApiUrl,
    auth0: {
        client_id: 'LJMFxyGhjedIbXdGGcenA8z9fCU5dA8M',
        domain: 'cognizancedotvision.us.auth0.com',
        redirect_uri: baseUrl,
        audience: '/api/test',
        scope: 'openid',
    },
    auth0Logout: {
        returnTo: baseUrl
    },
    firebase: baseFirebase,
    analyticsID : analyticsID,
    analytics4ID : analytics4ID,
    gtm: {
        containerId: ''
    },
});

ReactDOM.render(
    <StrictMode>
        <HelmetProvider>
            <ReduxProvider store={store}>
                <StyledEngineProvider injectFirst>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <SettingsProvider>
                            <MainContext>
                                <KeystoneContext>
                                    <BrowserRouter>
                                        <App/>
                                    </BrowserRouter>
                                </KeystoneContext>
                            </MainContext>
                        </SettingsProvider>
                    </LocalizationProvider>
                </StyledEngineProvider>
            </ReduxProvider>
        </HelmetProvider>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
