import {Card} from "../../card";

export interface GoalStatResults {
    columns: string[];
    data: any;
}

export interface GoalStatsData {
    statName: string;
    statResults: GoalStatResults;
}

export interface GoalTargetDetails {
    type: string;
    target: number;
    percentageDistribution: number;
    pps: string;
    np: boolean;
}

export interface CreateGoal {
    name: string;
    type: string;
    owner: string;
    description: string;
    priority: number;
    dueDate: string;
    status: string;
}

export interface UpdateGoal {
    id: string;
    name: string;
    type: string;
    owner: string;
    description: string;
    priority: number;
    dueDate: string;
    status: string;
}

export interface CreateGoalCriteria {
    header: string;
    value: string;
}

export interface CreateGoalTag {
    description: string;
}

export default class Goal {
    public id?: string = '';
    public name: string = '';
    public type: string = '';
    public owner: string = '';
    public description: string = '';
    public priority: number = 0;
    public startDate: string = '';
    public dueDate: string = '';
    public status: string = '';
    public userID: string = '';
    public criteria: string = '';
    public tag: string = '';
    public campaignID: string = '';
    public target: number = 0;
    public cards: Card[] = [];

    constructor(TempName?: Goal) {
        if (!TempName) {
            return;
        }
        this.id = TempName.id;
        this.name = TempName.name;
        this.type = TempName.type;
        this.owner = TempName.owner;
        this.description = TempName.description;
        this.priority = TempName.priority;
        this.startDate = TempName.startDate;
        this.dueDate = TempName.dueDate;
        this.status = TempName.status;
        this.userID = TempName.userID;
        this.criteria = TempName.criteria;
        this.tag = TempName.tag;
        this.campaignID = TempName.campaignID;
        this.target = TempName.target;
        this.cards = TempName.cards;
    }
}

export interface BurnDownGoalResult {
    day: string
    value: number
}