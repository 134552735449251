import React, {FC, useContext, useLayoutEffect, useState} from 'react';
import {Box, useTheme} from '@mui/material';
// import {useCustomThemeContext} from "../util/Context";
import {ApexOptions} from "apexcharts";
import Chart from "react-apexcharts";
import SettingsContext from "../../../../../contexts/SettingsContext";

interface HeatMapStatProps {
    series: any
    // setYes: (value: boolean) => void;
    exp? : boolean
    setExp?: (exp: boolean) => void
}
interface HeatmapEntry {
    x: string;
    y: number;
    value: number;
}

interface HeatmapData {
    data: HeatmapEntry[];
    name: string;
}
const HeatMapStat: FC<HeatMapStatProps> = (props) => {
    const chartType = 'heatmap'
    const settingsCtx = useContext(SettingsContext)
    const [series, setSeries] = useState<any[]>([])
    const [exp, setExp] = useState<boolean>(false)

    const convertToHeatmapFormat = (rawData: any): HeatmapData[] => {
        const uniqueOpportunityTypes = [...new Set(rawData.opportunity_type)];
        
        return uniqueOpportunityTypes.map((type) => {
            const filteredData: HeatmapEntry[] = rawData.Date_Resolved.reduce((acc, date, index) => {
                if (rawData.opportunity_type[index] === type) {
                    acc.push({ x: date, y: rawData.Value[index], value: 1 });
                }
                return acc;
            }, [] as HeatmapEntry[]);
            
            return { data: filteredData, name: String(type) };
        });
    };
    
    //TODO: set this up to map over the 6 weeks of data incomming, currently hard referencing for data display
    useLayoutEffect(() => {
        props.series &&  setSeries(convertToHeatmapFormat(props.series))
    }, [])
    
    const chartOptions: ApexOptions = {
        chart: {
            background: useTheme().palette.background.paper,
            foreColor: useTheme().palette.text.primary,
        },
        colors: ['#f2bc53', '#f45c5c'],
        plotOptions: {
            heatmap: {
                enableShades: true,
                shadeIntensity: 0.7,
                colorScale: {
                    ranges: [
                        {
                            from: 0,     // Adjust this based on your data
                            to: 2000,   // Adjust this based on your data
                            color: '#f45c5c',
                            name: 'low',
                        },
                        {
                            from: 2001,  // Adjust this based on your data
                            to: 10000, // Adjust this based on your data
                            color: '#f2bc53',
                            name: 'med',
                        },
                        {
                            from: 10000,  // Adjust this based on your data
                            to: 100000000, // Adjust this based on your data
                            color: '#4caf50',
                            name: 'high',
                        },
                    ],
                },
            },
        },
        //background: useTheme().palette.background.paper,
        //foreColor: useTheme().palette.text.primary,
        dataLabels: {
            enabled: false, // Disable data labels by default
        },
        legend: {
            show: false, // Hide the legend
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: '14px',
            },
            theme: 'dark'
        },
    };
    
    // const chartOptions: (ApexOptions) = {
    //     plotOptions: {
    //         heatmap: {
    //             colorScale: {
    //                 ranges: [{
    //                     from: 0,
    //                     to: 2000,
    //                     color: '#FF0000',
    //                     name: 'low',
    //                 },
    //                     {
    //                         from: 2001,
    //                         to: 10000,
    //                         color: '#FFB200',
    //                         name: 'medium',
    //                     },
    //                     {
    //                         from: 10000,
    //                         to: 100000,
    //                         color: '#00A100',
    //                         name: 'high',
    //                     }
    //                 ]
    //             }
    //         }
    //     }
    // }
    
    return (
        <Box sx={{
            color: '#F4F8F9',
            width: '100%',
            height: '90%',
            minHeight: {md:'400px', xs: '300'},
            // boxShadow: 3,
            p: 2,
            borderRadius: 1,
        }}>
            <Chart
                type={chartType}
                width={"100%"}
                height={"100%"}
                series={series}
                options={chartOptions}
            />
        </Box>
        
        // <Grid container spacing={1}>
        //     {series.map((rowData, rowIndex) => (
        //         <Grid container item xs={2} key={rowIndex}>
        //             {rowData.map((value, colIndex) => (
        //                 <Grid item xs={2} key={colIndex}>
        //                     <Paper>
        //                         <Chart
        //                             type={chartType}
        //                             width="100%"
        //                             height="100%"
        //                             series={[
        //                                 {
        //                                     name: 'Value',
        //                                     data: [{ x: 'Value', y: value }],
        //                                 },
        //                             ]}
        //                             options={chartOptions}
        //                         />
        //                     </Paper>
        //                 </Grid>
        //             ))}
        //         </Grid>
        //     ))}
        // </Grid>
    
    );
}

export default HeatMapStat;