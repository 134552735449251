import {PerformJSONRPCRequest, API} from '../api/jsonrpc';

export interface Subject {
    subject_type: string
    subject_id: string
    optional_relation: string
}
export interface  Resource {
    object_type: string
    object_id:   string
}
export interface AccessRequest {
    resource:       Resource
    permission_type: string
    subject:        Subject

}

const Store = {
    async CheckAccess(request: AccessRequest): Promise<boolean> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Authz-Store.CheckPermissions`,
            request: [
                {
                    resource:     request.resource,
                    permission_type: "edit",
                    subject:        request.subject
                }
            ]
        })
        let nResponse = await response;
        return nResponse.Permission;
    }
}

export default Store;