import React, {FC, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";
import {CampaignStore, CampaignGoalStore} from "../../../../../cog/keystone/goals/campaigns";
import {useSnackbar} from "notistack";

interface ConfirmDialogDeleteCampaignGoalProps {
    handleDialogClose: () => void
    loadingMethod: (v: boolean) => void;
    admin: boolean;
    goalID: string
    clientName: string
    updateCampaigns: () => void
    changed: () => void
    campaignID: string
}

const ConfirmDialogDeleteCampaignGoalDialog: FC<ConfirmDialogDeleteCampaignGoalProps> = ({
                                                                                             handleDialogClose,
                                                                                             loadingMethod,
                                                                                             admin,
                                                                                             goalID,
                                                                                             clientName,
                                                                                             updateCampaigns,
                                                                                             changed,
                                                                                             campaignID
                                                                                         }) => {
    const {enqueueSnackbar} = useSnackbar();
    const [isDisabled, setIsDisabled] = useState(false)

    const handleUpdateDelete = async () => {
        loadingMethod(true)
        setIsDisabled(true)

        if (admin) {
            const response = await CampaignGoalStore.DeleteOne({
                campaignID: campaignID,
                clientName: clientName,
                goalID: goalID

            })
            if (response) {
                changed()
                setIsDisabled(false)
                enqueueSnackbar('Campaign Goal Removed', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                updateCampaigns()
            } else {
                setIsDisabled(false)
                enqueueSnackbar('Failed to Remove Campaign Goal', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        } else {
            setIsDisabled(false)
            enqueueSnackbar('Cannot Remove Campaign Goal, Admin Permissions Required.  Please Contact an Administrator.', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        loadingMethod(false)
        handleDialogClose()
    }

    return (
        <Box sx={{m: 2}}>
            <DialogTitle>{"Remove Campaign Goal(s)"}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridAutoFlow={"row"}
                    gridAutoRows={"auto"}
                    gap={1}
                >
                    <Box>Are you sure you want to remove the Campaign Goal(s)?</Box>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonGroup variant={"text"}>
                    <Button
                        onClick={handleUpdateDelete}
                        disabled={isDisabled}
                    >
                        {isDisabled ? <CircularProgress size={20}/> : "Confirm"}
                    </Button>
                    <Button
                        onClick={() => handleDialogClose()}
                        disabled={isDisabled}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Box>
    );
}

export default ConfirmDialogDeleteCampaignGoalDialog;