const AverageReplenishmentCycle = (props) => {

    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Icon_SMM" data-name="Icon SMM" viewBox="0 0 409.6 404.1" height={height} >
            <defs>
                <linearGradient id="avgRepCycleBlob" x2="389.8" y1={202} y2={202} gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="avgRepCycleLine" x1="12.6" x2="37.7" y1="77.9" y2="77.9" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient id="avgRepCycleFill" x1="12.6" x2="37.7" y1="77.9" y2="77.9" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-2" x1="357.1" x2={373} y1="303.1" y2="303.1" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-3" x1="9.9" x2={27} y1="360.5" y2="360.5" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-4" x1="42.5" x2="88.8" y1="30.4" y2="30.4" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-5" x1="370.2" x2="409.6" y1="109.5" y2="109.5" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-6" x1={318} x2="353.5" y1="356.8" y2="356.8" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-7" x1="61.3" x2="331.3" y1={202} y2={202} />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-8" x1="83.1" x2="309.4" y1="201.9" y2="201.9" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-9" x1="116.6" x2="243.2" y1="177.2" y2="177.2" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-10" x1="185.2" x2="254.6" y1="178.9" y2="178.9" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-11" x1="188.2" x2="204.4" y1="201.9" y2="201.9" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-12" x1="98.1" x2={114} y1="232.7" y2="232.7" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-13" x1="92.8" x2="109.1" y1="201.9" y2="201.9" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-14" x1="98.3" x2="114.2" y1="173.1" y2="173.1" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-15" x1="112.7" x2={126} y1="146.7" y2="146.7" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-16" x1="134.7" x2="146.3" y1="125.5" y2="125.5" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-17" x1={163} x2="170.8" y1="112.2" y2="112.2" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-18" x1="193.4" x2={199} y1={107} y2={107} />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-19" x1="220.3" x2="231.1" y1="112.2" y2="112.2" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-20" x1={245} x2="258.6" y1="126.1" y2="126.1" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-21" x1="265.5" x2="279.6" y1="146.8" y2="146.8" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-22" x1="278.3" x2="294.6" y1="173.2" y2="173.2" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-23" x1="281.8" x2="297.9" y1="203.2" y2="203.2" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-24" x1="278.7" x2="294.2" y1="232.1" y2="232.1" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-25" x1="177.1" x2={215} y1="161.9" y2="161.9" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-26" x1="137.7" x2="231.3" y1={164} y2={164} />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-27" x1="182.7" x2="367.1" y1="90.3" y2="90.3" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-28" x1="20.4" x2="204.7" y1="312.8" y2="312.8" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-29" x1="222.8" x2="387.5" y1="282.7" y2="282.7" />
                <linearGradient xlinkHref="#avgRepCycleLine" id="avgRepCycleLine-30" x1=".1" x2="166.9" y1="120.3" y2="120.3" />
                <style dangerouslySetInnerHTML={{__html: "\n      .iconWhite{fill:#fff}\n    " }} />
            </defs>
            <g id="Group">
                <path id="Contour" d="M389.7 150.4A156.5 156.5 0 0 0 238.7 0 156.3 156.3 0 0 0 93.5 87.3a92.5 92.5 0 0 1-28.2 33.6A155.7 155.7 0 0 0 .1 253.7a156.5 156.5 0 0 0 151 150.3 156 156 0 0 0 147.7-92.4 58.8 58.8 0 0 1 21.2-25.3 155.9 155.9 0 0 0 69.8-136Z" style={{fill: 'url(#avgRepCycleBlob)'}} />
                <path id="Contour-2" d="m25.2 65.3-4.5 8.1-8.1 4.5 8.1 4.4 4.5 8.1 4.4-8.1 8.1-4.4-8.1-4.5-4.4-8.1z" style={{fill: 'url(#avgRepCycleLine)'}} />
                <path id="Contour-3" d="m362.2 300.3-5.1 2.8 5.1 2.9 2.9 5.1 2.8-5.1 5.1-2.9-5.1-2.8-2.8-5.2-2.9 5.2z" style={{fill: 'url(#avgRepCycleLine-2)'}} />
                <path id="Contour-4" d="m15.4 357.5-5.5 3 5.5 3 3 5.6 3.1-5.6 5.5-3-5.5-3-3.1-5.5-3 5.5z" style={{fill: 'url(#avgRepCycleLine-3)'}} />
                <path id="Contour-5" d="M60.8 36.3a2.4 2.4 0 0 0-1-1l-8.7-4.8 8.7-4.8a2.4 2.4 0 0 0 1-1l4.8-8.8 4.9 8.7a2.4 2.4 0 0 0 1 1l8.7 4.9-8.7 4.8a2.4 2.4 0 0 0-1 1L65.6 45Zm2.4-27.5-6.7 12.5L44 28a2.7 2.7 0 0 0 0 4.8l12.5 6.6L63.2 52a2.7 2.7 0 0 0 4.8 0l6.8-12.3 12.5-6.8a2.7 2.7 0 0 0 0-4.8l-12.5-6.7L68 8.8a2.7 2.7 0 0 0-4.8 0Z" style={{fill: 'url(#avgRepCycleLine-4)'}} />
                <path id="Contour-6" d="M386.3 114.2a2.3 2.3 0 0 0-1-1l-6.6-3.6 6.5-3.4a2.3 2.3 0 0 0 1-1l3.5-6.5 3.5 6.4a2.3 2.3 0 0 0 1 1.1l6.5 3.5-6.5 3.4a2.3 2.3 0 0 0-1 1l-3.5 6.5Zm1.2-22.9-5.6 10.2-10.2 5.6a2.7 2.7 0 0 0 0 4.8l10.2 5.6 5.6 10.2a2.7 2.7 0 0 0 2.4 1.5 2.9 2.9 0 0 0 2.4-1.5l5.5-10.2 10.3-5.6a2.7 2.7 0 0 0 0-4.8l-10.3-5.6-5.5-10.2a2.7 2.7 0 0 0-4.8 0Z" style={{fill: 'url(#avgRepCycleLine-5)'}} />
                <path id="Contour-7" d="M332.9 360.8a2.4 2.4 0 0 0-1-1l-5.3-2.9 5.3-2.8a2.3 2.3 0 0 0 1-1l2.9-5.4 2.8 5.3a2.3 2.3 0 0 0 1 1l5.3 3-5.2 2.8a2.4 2.4 0 0 0-1 1l-3 5.3Zm.5-20.4-5 9-9 5a2.8 2.8 0 0 0-1.4 2.4 2.7 2.7 0 0 0 1.5 2.4l9 5 5 9a2.7 2.7 0 0 0 2.4 1.5 2.3 2.3 0 0 0 2.1-1.5l5-9 9-5a2.7 2.7 0 0 0 1.5-2.4 2.5 2.5 0 0 0-1.3-2.4l-9-5-5-9a2.7 2.7 0 0 0-4.9 0Z" style={{fill: 'url(#avgRepCycleLine-6)'}} />
                <path id="Contour-8" d="M328.5 202A132.3 132.3 0 1 1 189 70l7.4-.1A132.2 132.2 0 0 1 328.5 202Z" style={{fill: 'url(#avgRepCycleFill)'}} />
                <path id="Contour-9" d="M66.7 202A129.5 129.5 0 0 1 189 72.7c2.2-.2 4.5-.2 7.2-.2 71.4 0 129.4 58 129.2 129.4a129.4 129.4 0 0 1-258.8 0Zm122-134.7A134.9 134.9 0 0 0 61.3 202a135 135 0 0 0 270 .2c0-74.4-60.6-135-135-135l-7.5.1Z" style={{fill: 'url(#avgRepCycleLine-7)'}} />
                <path id="Contour-10" d="M196.3 91.5A110.5 110.5 0 1 1 85.8 202 110.5 110.5 0 0 1 196.3 91.5Z" className="iconWhite" />
                <path id="Contour-11" d="M88.5 202.1a107.8 107.8 0 1 1 107.8 107.8A107.9 107.9 0 0 1 88.5 202ZM83 202A113.2 113.2 0 1 0 196.3 88.8 113.3 113.3 0 0 0 83 202Z" style={{fill: 'url(#avgRepCycleLine-8)'}} />
                <path id="Contour-12" d="M116.6 202.8a79.5 79.5 0 0 0 4.5 26.5 2.6 2.6 0 0 0 2.6 1.8 1.9 1.9 0 0 0 .9-.1 2.7 2.7 0 0 0 1.6-3.5 71.9 71.9 0 0 1-4.2-24.7 74 74 0 0 1 117-60.4 2.7 2.7 0 1 0 3.2-4.3 78.4 78.4 0 0 0-46-14.8 79.5 79.5 0 0 0-79.5 79.5Z" style={{fill: 'url(#avgRepCycleLine-9)'}} />
                <path id="Contour-13" d="M239.7 270.8h-86.9a15.6 15.6 0 1 1 0-31.3h86.9a15.6 15.6 0 1 1 0 31.3Z" style={{fill: 'url(#avgRepCycleFill)'}} />
                <path id="Contour-14" d="m248.6 145.3-62.4 62.4a3.5 3.5 0 0 0 5 5l62.4-62.4a3.5 3.5 0 0 0 0-5 3.5 3.5 0 0 0-5 0Z" style={{fill: 'url(#avgRepCycleLine-10)'}} />
                <path id="Contour-15" d="M188.2 202a8.1 8.1 0 1 0 8-8.1 8.1 8.1 0 0 0-8 8.1Z" style={{fill: 'url(#avgRepCycleLine-11)'}} />
                <path id="Contour-16" d="m110.6 228.7-10.4 2.7a2.6 2.6 0 0 0-2 3.3 2.7 2.7 0 0 0 2.8 2.1.9.9 0 0 0 .6-.1l10.3-2.7a2.7 2.7 0 0 0-.7-5.4 3.7 3.7 0 0 0-.6.1Z" style={{fill: 'url(#avgRepCycleLine-12)'}} />
                <path id="Contour-17" d="M95.5 199.4a2.7 2.7 0 0 0-2.7 2.8 2.7 2.7 0 0 0 2.7 2.8l10.9-.5a2.8 2.8 0 1 0-.2-5.6Z" style={{fill: 'url(#avgRepCycleLine-13)'}} />
                <path id="Contour-18" d="M98.4 171.1a2.6 2.6 0 0 0 2 3.3l10.3 2.6a1 1 0 0 0 .6.1 3 3 0 0 0 2.7-2.1 2.6 2.6 0 0 0-2-3.3l-10.4-2.6a2.6 2.6 0 0 0-.8 0 2.6 2.6 0 0 0-2.5 2Z" style={{fill: 'url(#avgRepCycleLine-14)'}} />
                <path id="Contour-19" d="M113.4 141a2.8 2.8 0 0 0 .2 4l7.8 7.3a2.7 2.7 0 0 0 1.8.8 3 3 0 0 0 2.1-1 2.8 2.8 0 0 0-.1-3.8l-7.9-7.4a2.8 2.8 0 0 0-1.8-.7 3 3 0 0 0-2 .9Z" style={{fill: 'url(#avgRepCycleLine-15)'}} />
                <path id="Contour-20" d="M135.8 119a2.6 2.6 0 0 0-.6 3.8l6.2 8.7a2.9 2.9 0 0 0 2.2 1.2 3.2 3.2 0 0 0 1.6-.6 2.6 2.6 0 0 0 .6-3.8l-6.2-8.7a2.5 2.5 0 0 0-2.2-1.1 2.7 2.7 0 0 0-1.6.5Z" style={{fill: 'url(#avgRepCycleLine-16)'}} />
                <path id="Contour-21" d="M165.2 104.2a2.8 2.8 0 0 0-2.2 3.3l2.3 10.6a2.9 2.9 0 0 0 2.7 2h.6a2.8 2.8 0 0 0 2.1-3.2l-2.3-10.6a2.7 2.7 0 0 0-2.7-2.1 2.8 2.8 0 0 0-.5 0Z" style={{fill: 'url(#avgRepCycleLine-17)'}} />
                <path id="Contour-22" d="m193.4 101.7.2 10.7a2.7 2.7 0 0 0 2.7 2.7 2.7 2.7 0 0 0 2.7-2.9l-.2-10.7a2.7 2.7 0 0 0-2.7-2.7 3 3 0 0 0-2.7 2.9Z" style={{fill: 'url(#avgRepCycleLine-18)'}} />
                <path id="Contour-23" d="m225.9 106.2-5.3 9.3a2.7 2.7 0 0 0 1 3.8 4.4 4.4 0 0 0 1.4.3 2.5 2.5 0 0 0 2.4-1.4l5.3-9.3a2.7 2.7 0 0 0-1-3.8 2.9 2.9 0 0 0-1.4-.3 2.7 2.7 0 0 0-2.4 1.4Z" style={{fill: 'url(#avgRepCycleLine-19)'}} />
                <path id="Contour-24" d="m254 120.5-8 7a2.7 2.7 0 0 0-.3 4 3 3 0 0 0 2.1.9 4.3 4.3 0 0 0 1.8-.6l8-7.1a2.7 2.7 0 0 0 .3-4 2.8 2.8 0 0 0-2.1-1 2.7 2.7 0 0 0-1.8.8Z" style={{fill: 'url(#avgRepCycleLine-20)'}} />
                <path id="Contour-25" d="m275.3 141.5-8.7 6.2a2.7 2.7 0 0 0-.6 3.7 2.9 2.9 0 0 0 2.3 1.2 2 2 0 0 0 1.5-.6l8.7-6.1a2.6 2.6 0 0 0 .6-3.8 2.5 2.5 0 0 0-2.2-1.1 2.7 2.7 0 0 0-1.6.5Z" style={{fill: 'url(#avgRepCycleLine-21)'}} />
                <path id="Contour-26" d="M278.3 173a2.7 2.7 0 0 0 2.7 3h11a2.7 2.7 0 0 0 2.7-2.6 2.7 2.7 0 0 0-2.7-2.9l-10.7-.1a2.8 2.8 0 0 0-2.9 2.7Z" style={{fill: 'url(#avgRepCycleLine-22)'}} />
                <path id="Contour-27" d="M281.8 203a2.7 2.7 0 0 0 2.7 2.9l10.8.1a2.5 2.5 0 0 0 2.6-2.7 2.7 2.7 0 0 0-2.7-2.9l-10.7-.1a2.7 2.7 0 0 0-2.7 2.7Z" style={{fill: 'url(#avgRepCycleLine-23)'}} />
                <path id="Contour-28" d="M279 229a2.8 2.8 0 0 0 1.4 3.6l10 4a2.5 2.5 0 0 0 1 .2 2.7 2.7 0 0 0 1-5.2l-10-4.1a2.9 2.9 0 0 0-1-.2 2.8 2.8 0 0 0-2.5 1.7Z" style={{fill: 'url(#avgRepCycleLine-24)'}} />
                <path id="Contour-29" d="M178.8 161a2.8 2.8 0 1 0 2.1 5 40 40 0 0 1 30.4 0 2.6 2.6 0 0 0 1 .2 2.6 2.6 0 0 0 2.5-1.5 2.8 2.8 0 0 0-1.5-3.6 45.6 45.6 0 0 0-34.5-.2Z" style={{fill: 'url(#avgRepCycleLine-25)'}} />
                <path id="Contour-30" d="M138 183a2.6 2.6 0 0 0 1.6 3.5 2 2 0 0 0 .9.1 2.9 2.9 0 0 0 2.7-2 56 56 0 0 1 83.9-28.5 2.7 2.7 0 1 0 3-4.6 60.6 60.6 0 0 0-34-10.2 61.7 61.7 0 0 0-58.2 41.7Z" style={{fill: 'url(#avgRepCycleLine-26)'}} />
                <path id="Contour-31" d="M344.6 113.6a172.2 172.2 0 0 1 19.7 53.8s-12.5 3-13.4 3.1c-11.2-63.3-70.8-121.4-138.1-129.2v18.3l-27-24.4 27-25.2.2 18.2c56.8 6.3 105 38.5 131.7 85.4Z" className="iconWhite" />
                <path id="Contour-32" d="M367 167a174.7 174.7 0 0 0-20-54.8A174 174 0 0 0 215.5 26l-.1-15.7a2.6 2.6 0 0 0-1.7-2.6 3 3 0 0 0-1.2-.3 2.5 2.5 0 0 0-1.8.7l-27 25.3a3 3 0 0 0-1 2.1 2.4 2.4 0 0 0 1 2l27 24.3a2.8 2.8 0 0 0 3 .5 2.8 2.8 0 0 0 1.6-2.6V44.3c63.8 9 121.6 64 132.8 126.7a3 3 0 0 0 1.2 1.8 2.6 2.6 0 0 0 1.5.4h.7l13.4-3.1a2.6 2.6 0 0 0 2.1-3.2Zm-14.1 0C340 102.5 279 46.3 213 38.5h-.3a2.4 2.4 0 0 0-1.7.8 3 3 0 0 0-.9 2v12.2l-20.3-18.1 20.1-19.1.2 12a2.8 2.8 0 0 0 2.4 2.7 168.6 168.6 0 0 1 129.7 83.9A169.4 169.4 0 0 1 361 165Z" style={{fill: 'url(#avgRepCycleLine-27)'}} />
                <path id="Contour-33" d="M43.8 289.6a172.3 172.3 0 0 1-19.6-53.8s12.4-3 13.3-3.2C48.8 296 108.3 354.1 175.7 362v-18.4l27 24.4-27 25.3-.2-18.2a173.2 173.2 0 0 1-131.7-85.4Z" className="iconWhite" />
                <path id="Contour-34" d="M177.4 374.7a2.8 2.8 0 0 0-2.4-2.7 168.5 168.5 0 0 1-129.7-83.9 169.5 169.5 0 0 1-18.8-50.3l8.1-2c13 64.6 73.8 120.9 140 128.7a2.7 2.7 0 0 0 2-.6 3 3 0 0 0 1-2.1v-12.2l20.3 18.1-20.3 19ZM36 229.9l-13.6 3.2a2.6 2.6 0 0 0-2 3.1 174.3 174.3 0 0 0 20 54.7 174 174 0 0 0 131.5 86.3l.1 15.8a2.6 2.6 0 0 0 1.7 2.5 2.5 2.5 0 0 0 1 .2 3.3 3.3 0 0 0 2-.6l27-25.3a3 3 0 0 0 1-2 2.4 2.4 0 0 0-1-2l-27-24.4a2.8 2.8 0 0 0-4.7 2.1V359c-63.7-9-121.5-64-132.7-126.7a3 3 0 0 0-1.2-1.8 2.5 2.5 0 0 0-1.5-.6 2.7 2.7 0 0 0-.6.1Z" style={{fill: 'url(#avgRepCycleLine-28)'}} />
                <path id="Contour-35" d="M282.5 352.3a174.6 174.6 0 0 1-53.6 20.1s-3-12.3-3.3-13.4c63.1-11.7 120.8-71.8 128.2-139l-18.3.2 24-27.2 25.4 26.9-18.2.3a171.8 171.8 0 0 1-84.2 132Z" className="iconWhite" />
                <path id="Contour-36" d="m359.6 196.7 19.1 20.3-12 .1a2.8 2.8 0 0 0-2.7 2.5 168.7 168.7 0 0 1-82.8 130.3A168 168 0 0 1 231 369l-2-8.1c64.5-13.4 120.4-74.7 127.6-141.1a3.6 3.6 0 0 0-.7-2.1 3 3 0 0 0-2.1-1l-12.2.2Zm-2.4-5.7-24 27.2a2.8 2.8 0 0 0 2.1 4.6l15.3-.1c-8.5 63.7-63.1 122-125.6 133.6a3 3 0 0 0-1.8 1.2 3.3 3.3 0 0 0-.3 2.1l3.3 13.4a2.9 2.9 0 0 0 2.7 2.1c.3 0 .4-.1.1.3a174.8 174.8 0 0 0 54.6-20.4A174 174 0 0 0 369 222.9c2.2-.2 6.9-.2 15.8-.3a2.6 2.6 0 0 0 2.5-1.7 3 3 0 0 0-.6-3L361.3 191a3 3 0 0 0-2.1-1 2.4 2.4 0 0 0-2 1Z" style={{fill: 'url(#avgRepCycleLine-29)'}} />
                <path id="Contour-37" d="M107.1 50.7a171.3 171.3 0 0 1 54-19.4s2.9 12.5 3.2 13.6C101 55.7 42.5 115 34.3 182.4l18.3.2L28 209.5 3 182h18.1c6.7-57 39.1-104.8 86-131.4Z" className="iconWhite" />
                <path id="Contour-38" d="M163.6 30.7a2.6 2.6 0 0 0-2.5-2.1 2.8 2.8 0 0 0-.6 0 171.6 171.6 0 0 0-54.7 19.7 174 174 0 0 0-87 131.1H3a2.6 2.6 0 0 0-2.6 1.7 2.7 2.7 0 0 0 .4 3l25.3 27a2.4 2.4 0 0 0 2 1 3 3 0 0 0 2-1l24.6-26.9a2.8 2.8 0 0 0-2.1-4.7H37.2C46.5 116 102 58.4 164.6 47.5a3 3 0 0 0 1.8-1.1 2.6 2.6 0 0 0 .4-2.1Zm-132 151.4a2.7 2.7 0 0 0 .5 2.1 3 3 0 0 0 2.1 1h12.2l-18.3 20L9.3 185h12a2.8 2.8 0 0 0 2.7-2.4 168.6 168.6 0 0 1 84.5-129.3A165.8 165.8 0 0 1 159 34.7l2 8C96.2 55.5 39.6 116 31.5 182.2Z" style={{fill: 'url(#avgRepCycleLine-30)'}} />
            </g>
        </svg>



    )

}
export default AverageReplenishmentCycle