import React, {FC, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";
import {GoalStore} from "../../../../../../cog/keystone/goals";
import {useSnackbar} from "notistack";
import {useKeystoneContext} from "../../../../../../contexts/KeystoneContext";
import Goal, { UpdateGoal } from "../../../../../../cog/keystone/goals/goal/Goal";
import {useMainContext} from "../../../../../../contexts/MainContext";

interface ConfirmSettingsDialogProps {
    confirmMessage: string;
    type: string;
    goal: Goal;
    goalRequest: UpdateGoal;
    handleDialogClose: () => void
    loadingMethod: (v: boolean) => void;
    setGoal: (g: Goal) => void;
    admin: boolean;
}

const ConfirmSettingsDialog: FC<ConfirmSettingsDialogProps> = ({ confirmMessage, goal, goalRequest, type, handleDialogClose, loadingMethod, setGoal, admin }) => {
    const mainCtx = useMainContext();
    const {enqueueSnackbar} = useSnackbar();
    const [isDisabled, setIsDisabled] = useState(false)
    const handleUpdateGoal = async () => {
        loadingMethod(true)
        setIsDisabled(true)
        if (admin) {
            const response = await GoalStore.UpdateOne({goal: goalRequest, clientName: mainCtx.activeOrganization.name})
            if (response) {
                setIsDisabled(false)
                enqueueSnackbar('Goal' + type + ' Updated', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
            } else {
                setIsDisabled(false)
                enqueueSnackbar('Failed to Update Goal ' + type, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }

            setGoal(goal)
        } else {
            setIsDisabled(false)
            enqueueSnackbar('Cannot Update Goal, Admin Permissions Required.  Please Contact an Administrator.' + type, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }

        loadingMethod(false)
        handleDialogClose()
    }

    return (
        <Box sx={{m: 2}}>
            <DialogTitle>{"Update " + type}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridAutoFlow={"row"}
                    gridAutoRows={"auto"}
                    gap={1}
                >
                    <Box>{confirmMessage}</Box>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonGroup variant={"text"}>
                    <Button
                        disabled={isDisabled}
                        onClick={handleUpdateGoal}
                    >
                        {isDisabled ? <CircularProgress size={20}/> : "Confirm"}
                    </Button>
                    <Button
                        disabled={isDisabled}
                        onClick={() => handleDialogClose()}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Box>
    );
}

export default ConfirmSettingsDialog;