/* eslint-disable */

import type {FC, ReactNode} from 'react';
import React, {useState} from "react";
import PropTypes from 'prop-types';
import {matchPath} from 'react-router-dom';
import type {ListProps} from '@mui/material';
import {List, ListSubheader, Popover} from '@mui/material';
import NavItem from './NavItem';

interface Item {
    path?: string;
    icon?: ReactNode;
    info?: ReactNode;
    children?: Item[];
    title?: string;
}

interface NavSectionCompProps extends ListProps {
    items?: Item[];
    pathname: string;
    title: string;
}

const renderNavItems = ({
                            depth = 0,
                            items,
                            pathname
                        }: {
    items: Item[];
    pathname: string;
    depth?: number;
}): JSX.Element => (
    <List disablePadding>
        {items.reduce(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
            (acc, item) => reduceChildRoutes({
                acc,
                item,
                pathname,
                depth
            }),
            []
        )}
    </List>
);

const reduceChildRoutes = ({
                               acc,
                               pathname,
                               item,
                               depth,
                           }: {
    acc: JSX.Element[];
    pathname: string;
    item: Item;
    depth: number;
}): Array<JSX.Element> => {
    const key = `${item.title}-${depth}`;
    const exactMatch = item.path ? !!matchPath({
        path: item.path,
        end: true
    }, pathname) : false;
    
    // so we first separate Dashboards because we want its children to render on a popover because it can have multiple dashboards
    // everything we just render the children's icons and path, both NavSectionComp and NavSectionComp push to the same NavItem
    if (item.title === 'Dashboards') {
        const childKey = `${item.title}-${depth}`;
        // const childExactMatch = child.path
        //     ? !!matchPath({path: child.path, end: true}, pathname)
        //     : false;
        const childExactMatch = item.path ? !!matchPath({
            path: item.path,
            end: false
        }, pathname) : false;
        acc.push(
            <NavItem
                active={childExactMatch}
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={exactMatch}
                path={item.path}
                // title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    items: item.children,
                    pathname
                })}
            </NavItem>);
    } else if (item.children) {
        // console.log("item.children", item)
        const [banList, setBanList] = useState<string[]>([])
        // Function to get the nested children of a node
        function getNestedChildren(node: typeof item): typeof item[] {
            return node.children || [];
        }
        // let banList = []
        let nestedChildren = []
        
        // Iterate through the top-level children and retrieve their nested children if they have any
        
        for (const topLevelChild of item.children || []) {
            nestedChildren = getNestedChildren(topLevelChild)
            
            if (nestedChildren.length > 0) {
                banList.push(topLevelChild.title)
            }
            
            nestedChildren.forEach((child) => {
                const childKey = `${child.title}-${depth}`;
                const childExactMatch = child.path
                    ? !!matchPath({path: child.path, end: true}, pathname)
                    : false;
                acc.push(
                    <NavItem
                        active={childExactMatch}
                        depth={depth}
                        icon={child.icon}
                        info={child.info}
                        key={childKey}
                        path={child.path}
                        // title={child.title}
                    />
                );
            });
        }
        if (item.children) {
            
            item.children.forEach((child) => {
                if (!banList.some((word) => child.title.includes(word))) {
                    const childKey = `${child.title}-${depth}`;
                    const childExactMatch = child.path
                        ? !!matchPath({path: child.path, end: true}, pathname)
                        : false;
                    acc.push(
                        <NavItem
                            active={childExactMatch}
                            depth={depth}
                            icon={child.icon}
                            info={child.info}
                            key={childKey}
                            path={child.path}
                            // title={child.title}
                        />
                    ); }
            });
            
        }
    }
    return acc;
};

const NavSectionComp: FC<NavSectionCompProps> = (props) => {
    const {
        items,
        pathname,
        title,
        ...other
    } = props;
    
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    
    if (props.items && props.items[2]?.title === "Dashboards") {
        return(<>
                <List
                    subheader={
                        <ListSubheader
                            disableGutters
                            disableSticky
                            sx={{
                                color: 'text.primary',
                                fontSize: '0.75rem',
                                lineHeight: 2.5,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                            }}
                        >
                            {/*{title}*/}
                        </ListSubheader>
                    }
                    {...other}
                >
                    <div onClick={handlePopoverOpen}>
                        {renderNavItems({
                            items,
                            pathname,
                        })}
                    </div>
                </List>
                <Popover
                    //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                    disableEnforceFocus
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {items?.map((item) => {
                        if (item.title === "Dashboards" && item.children) {
                            return (
                                <List key={item.title}>
                                    {item.children.map((child, index) => (
                                        <NavItem
                                            key={index}
                                            active={false}
                                            depth={1}
                                            icon={child.icon}
                                            info={child.info}
                                            path={child.path}
                                            title={child.title}
                                        />
                                    ))}
                                </List>
                            );
                        }
                        return null;
                    })}
                </Popover>
            </>
        );
    } else {
        return (
            <List
                subheader={(
                    <ListSubheader
                        disableGutters
                        disableSticky
                        sx={{
                            color: 'text.primary',
                            fontSize: '0.75rem',
                            lineHeight: 2.5,
                            fontWeight: 700,
                            textTransform: 'uppercase'
                        }}
                    >
                        {/*{title}*/}
                    </ListSubheader>
                )}
                {...other}
            >
                {renderNavItems({
                    items,
                    pathname
                })}
            </List>
        );
    }
    
};

NavSectionComp.propTypes = {
    items: PropTypes.array,
    pathname: PropTypes.string,
    title: PropTypes.string
};

export default NavSectionComp;