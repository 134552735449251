/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import {FC} from "react";
import {Box, Paper, Typography, Grid} from "@mui/material";
import BasicWidget from "../BasicWidget";
import {WidgetProps} from "../../../WidgetSwitcher";


const ImageInst: FC<WidgetProps> = ({widget}) => {
    console.log(widget.arguments.templates.imageUrl)
    const imageUrl = widget.arguments && widget.arguments.templates.imageUrl &&
        widget.arguments.templates.imageUrl.value
    const borderRadius = widget.arguments && widget.arguments.templates.borderRadius &&
      widget.arguments.templates.borderRadius.value
    return (
        <img style={{ height: "100%", width: "100%", padding: "15px", borderRadius: borderRadius}} src={imageUrl} />
    );
}

const BasicImage: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<ImageInst />}/>
}

export default BasicImage
