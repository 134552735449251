/* eslint-disable */

import {lazy, Suspense} from 'react';
import type {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import AuthGuard from '../components/guards/AuthGuard';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import GuestGuard from '../components/guards/GuestGuard';
import LoadingScreen from '../components/guards/LoadingScreen';
import MainLayout from '../layouts/main/MainLayout';
import Dashboard from "../pages/app/bitool/dashboard/Dashboard";
import DashboardEdit from "../pages/app/bitool/dashboard/DashboardEdit";
import OldQueryEditor from "../pages/app/bitool/query/OldQueryEditor";
import QueryChooser from "../pages/app/bitool/query/QueryChooser";
import DashboardChooser from "../pages/app/bitool/dashboard/edittingFeatures/DashboardChooser";
import DashboardChooserInterface from "../pages/app/bitool/dashboard/edittingFeatures/DashboardChooserInterface";
import WidgetChooser from "../pages/app/bitool/widget/editor/WidgetChooser";
import WidgetEditorLayout from "../pages/app/bitool/widget/editor/WidgetEditorLayout";
import Reports from "../pages/app/bitool/dashboard/reportsAndExports/Reports";
import BiGuard from "../components/guards/BIGuard";
import KanbanGuard from "../components/guards/KanbanGuard";
import Leaderboard from "../pages/app/keystone/leaderboard/Leaderboard";
import Container from "../pages/app/keystone/container/Container";
import ExploreOverviewPage from "../pages/app/keystone/explore/overview/ExploreOverviewPage";
import OpportunityCard from "../pages/app/keystone/opportunity/OpportunityCard";
import StatsPageView from "../pages/app/keystone/stats/StatsPageView";
import ExcludeManager from "../pages/app/keystone/exlude/ExcludeManager";
import DashGuard from "../components/guards/DashGuard";
import Goals from "../pages/app/keystone/goals/Goals";
import GoalPage from "../pages/app/keystone/goals/goal/GoalPage";
import CampaignGoals from "../pages/app/keystone/goals/Campaigns";
import ExploreDistributionPage from "../pages/app/keystone/explore/distribution/ExploreDistributionPage";
import ExploreStoresPage from "../pages/app/keystone/explore/stores/ExploreStoresPage";
import OrganizationSettingsPage from '../pages/app/management/orgSettings/OrganizationSettings';
import KeystoneHome from "../pages/app/keystone/home/KeystoneHome";
import DashboardEmbed from "../pages/app/bitool/dashboard/DashboardEmbedded";
import ReportingFinancialPage from "../pages/app/keystone/reporting/financial/ReportingFinancialPage";
import ReportingUserPage from "../pages/app/keystone/reporting/user/ReportingUserPage";
import PSUTreeComponent from "../pages/app/keystone/productstoreuniverse/PSUTreeComponent";
import ContainerPSU from "../pages/app/keystone/productstoreuniverse/ContainerPSU";


const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const AuthAction = Loadable(lazy(() => import('../pages/authentication/AuthAction')));

// Dashboard pages


const Account = Loadable(lazy(() => import('../pages/app/account/Account')));
// const Chartio = Loadable(lazy( ()=> import('../pages/app/chartio/Chartio')))
const Notifications = Loadable(lazy(() => import('../pages/app/notifications/Notifications')));
const SplashDashboard = Loadable(lazy(() => import('../pages/app/SplashDashboard')));
const Management = Loadable(lazy(() => import('../pages/app/management/ManagementPage')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('../components/guards/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('../components/NotFound')));
const ServerError = Loadable(lazy(() => import('../components/ServerError')));

const dashboardRoutes  = [
    {
        path: '/account/',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: ':org',
                element: <Account />
            },
        ]
    },
    {
        path: '/notifications/',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: ':org',
                element: <Notifications />
            },
        ]
    },
/*    {
        path: '/orgsettings/',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: ':org',
                element: <EmbeddingSettings />
            },
        ]
    },*/
    {
        path: 'reports/dashboard/:id/:token',
        element: <Reports />
    },
    {
        path: 'keystone/leaderboard',
        element: <Leaderboard />
    },
    // {
    //   path: 'keystone/opportunity',
    //   element: <KeystoneIssue />
    // },
    {
        path: 'keystone/excludemanager',
        element: <ExcludeManager />,
    },
    // {
    //   path: 'keystone/filter',
    //   element: <Filter />,
    // },
    {
        path: '/app/',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <SplashDashboard />
            },
            {
                path: 'bi/queryedit/:id',
                element: (
                    <BiGuard>
                        <OldQueryEditor />
                    </BiGuard>
                ),
            },
            {
                path: 'bi/querychoose',
                element: (
                    <BiGuard>
                        <QueryChooser />
                    </BiGuard>
                ),
            },
            {
                path: 'bi/widgetchoose/:dashId',
                element: (
                    <BiGuard>
                        <WidgetChooser />
                    </BiGuard>
                )
            },
            {
                path: 'bi/dashchoose',
                element: ( <BiGuard>
                    <DashboardChooserInterface />
                </BiGuard> )
            },
            {
                path: 'bi/dashchoose/:orgId',
                element: (
                    <BiGuard>
                        <DashboardChooser />
                    </BiGuard>
                ),
            },
            {
                path: 'bi/dashboard/:dashId',
                element: (
                    <BiGuard>
                        <DashboardEdit />
                    </BiGuard>
                )
            },
            {
                path: 'bi/widgetedit/:dashId/:queryId/:widgetId',
                element: (
                    <BiGuard>
                        <WidgetEditorLayout />
                    </BiGuard>
                )
            },
        ]
    },
    {
        path: '/app/:org/',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <SplashDashboard />
            },
            {
                path: 'manage',
                element: <Management />
            },
            {
                path: 'organization-settings',
                element: <KanbanGuard><OrganizationSettingsPage /></KanbanGuard>
            },
            {
                path: ':dashName/:dashId',
                element: (
                    <DashGuard>
                        <Dashboard />
                    </DashGuard>
                )
            },
            {
                path: 'keystone/:cardId',
                element: (
                    <KanbanGuard>
                        <Container/>
                    </KanbanGuard>
                )
            },
            {
                path: 'home',
                element: <KanbanGuard><KeystoneHome /></KanbanGuard>,
            },
          {
            path: 'explore/overview',
            element: <KanbanGuard><ExploreOverviewPage /></KanbanGuard>,
          },
          {
            path: 'explore/distribution',
            element: <KanbanGuard><ExploreDistributionPage /></KanbanGuard>
          },
            {
            path: 'explore/stores',
            element: <KanbanGuard><ExploreStoresPage /></KanbanGuard>
          },
          {
              path: 'opportunities',
              element: <KanbanGuard><Container /></KanbanGuard>,
          },
            {
                path: 'psuManagerAlpha',
                element: <ContainerPSU />,
            },
        //   {
        //       path: 'goals',
        //       element: <KanbanGuard><Goals /></KanbanGuard>,
        //   },
          {
              path: 'goal/:campaignId/:goalId',
              element: <KanbanGuard><GoalPage/></KanbanGuard>,
          },
          {
              path: 'campaigns',
              element: <KanbanGuard><CampaignGoals/></KanbanGuard>,
          },
          {
              path: 'campaigns/:campaignId',
              element: <KanbanGuard><Goals/></KanbanGuard>,
          },
            {
                path: 'reporting/financial',
                element: <KanbanGuard><ReportingFinancialPage /></KanbanGuard>,
            },
            {
                path: 'reporting/users',
                element: <KanbanGuard><ReportingUserPage /></KanbanGuard>,
            },
          {
              path: 'leaderboard',
              element: <KanbanGuard><Leaderboard /></KanbanGuard>,
          },
          {
              path: 'reporting',
              element: <KanbanGuard><StatsPageView /></KanbanGuard>,
          },
          {
              path: 'opportunity/:id',
              element: <OpportunityCard />,
              exact: true
          },

        ]
    },
]

const baseRoutes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'authAction',
        element: <AuthAction/>
      }
    ]
  },
    {path: 'embedding/',
        children: [
            {path: ':token',
            element: <DashboardEmbed />}
        ]},
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Navigate
            to="app"
            replace
        />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export {
    baseRoutes,
    dashboardRoutes
};