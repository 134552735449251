import type { FC } from 'react';
import React, {useCallback, useEffect, useRef, useState} from "react";
import CardAttribute, {
  AdditionalInsightConfig,
  InsightConfig
} from "../../../../../../cog/keystone/card/cardAttribute/CardAttribute";
import {Executor} from "../../../../../../cog/bitool/executor";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  Avatar, Tooltip
} from "@mui/material";
import {InsightWidgetProps} from "./insights/InsightWidgetSwitcher";
import InsightsCard from "./insights/InsightsCard";
import {InfoOutlined} from "@mui/icons-material";
import KeystoneCustomIcon from "../../../../../../icons/KeystoneCustomIcon";
import currencyFormatter from "../../../../../../utils/currencyFormatter";
import {useMainContext} from "../../../../../../contexts/MainContext";

interface AttributeCardProps {
  cardName: string;
  opportunityType: string;
  displayValue: string;
  additionalDisplayValue: string;
  role: number;
  level: number;
  levelFilter: string;
  v0: string;
  attribute: CardAttribute;
  org: string;
  gridNumber:number
}

let myIcon = () => {
  return (
      <div>

      </div>
  )

}

let dialogBody = () => {
  return (
    <div>
    </div>
  )
};

const AttributeCard: FC<AttributeCardProps> = ({cardName, org, attribute, opportunityType, displayValue, additionalDisplayValue, role, level, levelFilter, v0, gridNumber}) => {
  const mainCtx = useMainContext();
  const [insightConfigs, setInsightConfigs] = useState<InsightWidgetProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [refHeight, setRefHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    setRefHeight(ref.current.clientHeight)
  })


  const additionalInsightsConfig = JSON.parse(attribute.additionalInsightsConfig) as AdditionalInsightConfig;

  const executeQueries = useCallback(async () => {
    setIsLoading(true)
    const extraInsightsConfig = JSON.parse(attribute.extraInsightsConfig) as InsightConfig[]
    let inCons : InsightWidgetProps[] = []

    for (let i = 0; i < extraInsightsConfig.length; i++) {
      let templates = {}

      if (extraInsightsConfig[i].templates != null) {
        templates = extraInsightsConfig[i].templates
        templates["name"] = "'''" + cardName + "'''";
        templates["opportunity_type"] = "'''" + opportunityType + "'''";
        templates["display_value"] = "'''" + displayValue + "'''";
        templates["additional_display_column"] = "'''" + additionalDisplayValue + "'''";
        templates["role"] = "'" + role + "'";
        templates["level"] = "'" + level + "'";
        templates["level_filter"] = "'''" + levelFilter + "'''";
        templates["v0"] = "'''" + v0 + "'''";
      }

      let queryResult = await Executor.ExecuteOne({
        queryId: extraInsightsConfig[i].queryId,
        connectorId: extraInsightsConfig[i].connectorId,
        templates: templates
      })

      if (!queryResult.data) {
        // console.log("Insight Query Error: ", queryResult.Errors)
      } else {
        // console.log(queryResult)
        let insightConfig: InsightWidgetProps = {
          chartName: extraInsightsConfig[i].name,
          chartType: extraInsightsConfig[i].chartType,
          chartData: queryResult.data,
          chartColumns: queryResult.columns,
          labelValue: extraInsightsConfig[i].labelValue
        }

        inCons.push(insightConfig)
      }
    }

    setInsightConfigs(inCons)
    setIsLoading(false)
  }, [])

  const formatCurrency = (inputString: string): string => {
    // Regular expression to find '%$' followed by a number
    const regex = /\%\$(\d+(\.\d+)?)/g;

    // Replace matches with formatted currency
    const resultString = inputString.replace(regex, (_, match) => {
      const number = parseFloat(match);
      if (!isNaN(number)) {
        const formattedCurrency = currencyFormatter(mainCtx.activeOrganization.name, number as unknown as string)
        return formattedCurrency;
      } else {
        return _; // return unchanged if the matched part is not a valid number
      }
    });

    return resultString;
  }

  useEffect(() => {
    if (attribute.extraInsights == true) {
      executeQueries()
    }
  }, [])

  const handleDialogOpen = () => {
    dialogBody = () => {
      return (
        <InsightsCard insightCardProps={insightConfigs} />
      );
    }
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const getIcon = () => {
    myIcon = () => {
      return(
        <KeystoneCustomIcon key={attribute.name} name={attribute.name.replace(/\s/g,'')
            .replace('(Days)', '')
            .replace('-Sufficient', '')
            .replace('-Insufficient', '')}
                            position={gridNumber}
                            height={100}
        />
      )
    }
    return myIcon()
  }

  return(
    <Box sx={{
      // border:'1px solid red',
      height: '100%'
    }}>
      <Card
        sx={{
          border: '1px solid #d1d1d1',
          fontSize: '0.875rem',
          fontWeight: '700',
          fontFamily: 'Roboto',
          backgroundColor: 'f9f9f9',
          minHeight: 200,
          height: '100%',
          boxShadow: 2,
        }}
      >
        <CardContent sx={{
          height:'100%',
          // border: '1px solid blue',
          display: 'flex',
          flexDirection: 'column',
        }}>

          {attribute.extraInsights?
              <Box sx={{
                // border: '1px solid red',
                // height:'100%',
                display:'flex',
                justifyContent: 'flex-end'
              }}>
                    <Tooltip title={"View Insights"}>
                      <InfoOutlined onClick={() => handleDialogOpen()}
                                    aria-disabled={isLoading}
                                    sx={{
                                      marginRight:1,
                                    }}

                      />
                    </Tooltip>
              </Box>:<Box>
                <br/>
              </Box>

          }
          <Box sx={{
            // border: '1px solid blue',
            display: 'flex',
            flexDirection:'row',
            justifyContent: 'center',
            alignItems: 'center',
            height:'100%',

          }}>
              {getIcon()}

            <Box ref={ref} sx={{
              // border: '1px solid red',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Typography variant='body2'>
                {attribute.name}
              </Typography>
              <Typography variant='h6'
                  sx={{
                    whiteSpace: 'pre-wrap',
                    fontWeight: 'fontWeightBold',
              }} >
                {formatCurrency(attribute.value)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Dialog
          //disableEnforceFocus required to open chat if Dialog opened first
          disableEnforceFocus
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        {dialogBody()}
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default AttributeCard;