import React from 'react';

function getStyle({ draggableStyle, virtualStyle, isDragging }) {
    const combined = {
        ...virtualStyle,
        ...draggableStyle
    };

    const grid = 8;

    const result = {
        ...combined,
        height: isDragging ? combined.height- grid : combined.height - grid,
        left: isDragging ? combined.left+ grid : combined.left + grid,
        width: isDragging
            ? `calc(${combined.width} - ${grid * 20}px)`
            : `calc(${combined.width} - ${grid * 2}px)`,
        marginBottom: grid
    };

    return result;
}

export default getStyle;
