import React, {ChangeEvent, FC, useCallback, useLayoutEffect, useState} from "react";
import {Box, Card, CardContent, CardHeader, Divider, Grid, Tab, Tabs} from "@mui/material";
import ContributionsDataGrid from "./ContributionsDataGrid";
import HistoryDataGrid from "./HistoryDataGrid";
import SuggestedOppsDataGrid from "./SuggestedOppsDataGrid";
import ProgressBar from "../../ProgressBar";
import Goal from "../../../../../../cog/keystone/goals/goal/Goal";
import {GoalTargetStore} from "../../../../../../cog/keystone/goals";
import GoalChart from "./GoalChartSelector";
import {useMainContext} from "../../../../../../contexts/MainContext";
import TargetComponent from "../../goalDataGrid/components/TargetComponent";
import HomeBurnDownChartComponent from "../../../home/components/HomeBurnDownChartComponent";

interface GoalDetailsProps {
    goalStatData: any;
    goal: Goal;
    setGoal: (goal: (prev) => Goal) => void;
    update?: () => void
}

const tabs = [
    {label: 'Contributions', value: 'contributions'},
    {label: 'Suggested Opportunities', value: 'suggested opportunities'},
    {label: 'History', value: 'history'}
];

const GoalDetails: FC<GoalDetailsProps> = ({goalStatData, goal, setGoal, update}) => {
    const [currentTab, setCurrentTab] = useState<string>('contributions');
    const ctx = useMainContext()

    const handleTabsChange = useCallback(
        (event: ChangeEvent<{}>, value: string): void => {
            setCurrentTab(value);
        },
        []
    );

    return (<Box sx={{mb: 2}}>
        <Grid container
              direction={"column"}
              alignItems={"stretch"}
              justifyContent={"center"}
              spacing={2}>
            <Grid item
                  lg={12}
                  xs={12}>
                <Card>
                    {goal.type ? <GoalChart type={goal.type}
                                            goal={goal}/> : <></>}
                </Card>
            </Grid>
            <Grid item
                  lg={12}
                  xs={12}>
                <Card>
                    <CardHeader title={"Goal Progress"}/>
                    <Divider/>
                    <CardContent>
                        <TargetComponent value={0}
                                         row={goal}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item
                  lg={12}
                  xs={12}>
                <Card>
                    <CardHeader title={"Goal Burndown"}/>
                    <Divider/>
                    <CardContent>
                        <HomeBurnDownChartComponent goal={goal} minimalist={false} size={500}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item
                  lg={12}
                  xs={12}>
                <Card>
                    <Box>
                        <Tabs
                            indicatorColor="primary"
                            onChange={handleTabsChange}
                            scrollButtons="auto"
                            sx={{mt: 3}}
                            textColor="primary"
                            value={currentTab}
                            variant="scrollable"
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                        </Tabs>
                        <Divider/>
                    </Box>
                    {currentTab === 'contributions' &&
                        <ContributionsDataGrid contributionData={goalStatData["Contributions"]}
                                               goal={goal}
                                               setGoal={setGoal}
                                               update={update}/>}
                    {currentTab === 'suggested opportunities' &&
                        <SuggestedOppsDataGrid suggestionsData={goalStatData["SuggestedOpportunities"]}
                                               goal={goal}
                                               setGoal={setGoal}
                                               update={update}/>}
                    {currentTab === 'history' && <HistoryDataGrid historyData={goalStatData["History"]}/>}
                </Card>
            </Grid>
        </Grid>
    </Box>)
}

export default GoalDetails;