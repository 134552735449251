import React, {FC, useEffect, useRef, useState} from "react";
import {Autocomplete, Box, IconButton, InputBase, Popover, styled, TextField, Tooltip, Typography} from "@mui/material";
import {Theme, useTheme} from "@mui/material/styles";
import {useMainContext} from "../../../../../contexts/MainContext";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {ConvertTreeFromBeingUsedToApiSave, TreeForUseByPSU} from "../utility/NodeTypesAndOptions";
import {PSUStore} from "../../../../../cog/keystone/productStoreUniverse";
import {useSnackbar} from "notistack";
import makeStyles from "@mui/styles/makeStyles";
import BusinessIcon from "@mui/icons-material/Business";
import PolylineIcon from '@mui/icons-material/Polyline';

const ActivePSUSelector: FC = () => {
    const cogTheme = useTheme()
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const {enqueueSnackbar} = useSnackbar();
    const [psuName, setPsuName] = useState<string>(keystoneCtx.activePSU && keystoneCtx.activePSU.Name ? keystoneCtx.activePSU.Name : null)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    // const [selectedPSU, setSelectedPSU] = useState<TreeForUseByPSU>(null)

    async function SaveActivePSU(psu: TreeForUseByPSU) {
        let response = await PSUStore.SaveActivePSUForUser({
            ClientName: mainCtx.activeOrganization.name,
            PSU_ID: psu != null ? psu.ID : "",
            User_ID: mainCtx.user.id
        })
    }

    const handleChangeSelected = (e, value: TreeForUseByPSU) => {
        // setSelectedPSU(value)
        keystoneCtx.setActivePSU(value)
        SaveActivePSU(value)
        CheckPSUForData(value)
    }

    // CheckPSUForData : Checks if a psu returns data, and lets the user know if it doesn't
    async function CheckPSUForData(psu: TreeForUseByPSU) {
        let tempPSU = ConvertTreeFromBeingUsedToApiSave(psu)
        let response = await PSUStore.CheckIfPsuReturnsData({
            ClientName: mainCtx.activeOrganization.name,
            PSU: tempPSU
        })
        if (response.ReturnsData == false) {
            // let em know
            enqueueSnackbar(psu.Name + ' Returns no data', {
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'top'
                },
                variant: 'warning',
                autoHideDuration: 2500,
                preventDuplicate: true
            });
        }
    }

    const [inputValue, setInputValue] = React.useState('');

    // this is used to disable the borders of the Autocomplete textField
    const useStyles = makeStyles({
        root: {
            '& .MuiAutocomplete-inputRoot[class*="Mui-focused"] input': {
                borderBottom: 'none',
            },
            // so we're using placeholder to avoid the shrinking from the autocomplete and we set label to empty
            '& .MuiInputBase-input::placeholder': {
                font: "Segue UI",
                fontWeight: "300",
                fontSize: "0.80rem",
                color: cogTheme.palette.text.secondary,
                opacity: 1,
            },
            '& .MuiInputLabel-shrink': {
                display: 'block', // Show the label text when shrunk
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: 'none',
                },
            },
            '& .MuiInputBase-input': {
                '&:focus': {
                    borderRadius: 0, borderColor: '#fff', boxShadow: '0 0 0 0 rgba(0,123,255, 0)',
                },
                position: 'relative',
                backgroundColor: cogTheme.palette.background.paper,
                padding: mainCtx.slider ? '5px 0px 10px 0px' : '10px 0px 10px 0px',
                font: "Segue UI",
                fontWeight: "300",
                fontSize: "0.80rem",
            },
        },
        dynamicWidth: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    });

    const classes = useStyles();

    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    // using this because we want the tooltip to have the PSU name on the chance the page refreshed but using intialState was not working
    useEffect(() => {
        keystoneCtx.activePSU && keystoneCtx.activePSU.Name ? setPsuName(keystoneCtx.activePSU.Name) : null
    }, [keystoneCtx.activePSU]);

    // we're using this for the autocomplete in the return just to make things neater and avoid repetition, repetition, repetition
    const auto = () => {
        return <Autocomplete
            className={classes.root}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        marginRight: 1,
                        marginTop: "3px",
                        border: '0px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        font: "Segue UI",
                        fontWeight: "300",
                        fontSize: "0.80rem",
                    }}
                    InputLabelProps={{
                        // Shrink label when value is selected. Set to false because we want the PSU Selector to disappear
                        shrink: false,
                    }}
                    variant={"outlined"}
                    label={""}
                    placeholder="PSU Selector"
                    value={keystoneCtx.activePSU != null ? keystoneCtx.activePSU.Name : null}
                />
            )}
            disableClearable={keystoneCtx.activePSU == null}
            value={keystoneCtx.activePSU}
            options={keystoneCtx.psuTrees}
            getOptionLabel={(option: TreeForUseByPSU) => option.Name}
            renderOption={(params, option) => {
                return<li
                    {...params}
                >
                    {/*{option.Name}*/}
                    {option.Name + (option.User_ID == mainCtx.user.id ? " (personal)" : "")}
                </li>
            }}
            onChange={(_, value: TreeForUseByPSU) => {
                // setSelectedPSU(value)
                handleChangeSelected(_, value)
                setPsuName(value && value.Name ? value.Name : null)
            }}
            sx={{
                border: "0px",
                width: "400px",
                font: "Segue UI",
                fontWeight: "300",
                fontSize: "0.80rem",
            }}
        />
    }

    if (mainCtx.slider) {
    // if the slider is open (big mode)
    return <Box sx={{px: "16px", paddingTop: "16px"}}>
                { keystoneCtx.psuTrees != null &&
                    <>
                        <Typography variant={"body1"} fontSize={"0.75rem"} fontWeight={"700"} lineHeight={"2.5"}>
                            PSU SELECTOR
                        </Typography>
                        <Tooltip title={psuName ? psuName : "PSU Selector"} placement={'top'}>
                            <Box sx={{ display: 'inline-flex' }}>
                                <PolylineIcon sx={{ color: `"${useTheme().palette.text.secondary}"`, marginTop: "14px", marginLeft: "18px" }} />
                                {auto()}
                            </Box>
                        </Tooltip>
                    </>
                }
            </Box>
    } else {
        // if slider is closed (small mode)
        return <Box>
            { keystoneCtx.psuTrees != null &&
                <>
                    <Tooltip title={psuName ? psuName : "PSU Selector"} placement={'top'}>
                        <Box sx={{display: 'inline-flex',  paddingLeft: "16px", paddingTop: "5px"}}>
                            <IconButton onClick={handleButtonClick}
                                sx={{color: useTheme().palette.text.secondary,
                                    paddingLeft: "16px",
                                    paddingRight: "32px",
                                    paddingY: "12px",
                                    marginLeft: "4px",
                                    borderRadius: "16px",
                            }}>
                            <PolylineIcon sx={{
                                color: `"${cogTheme.palette.text.secondary}"`,
                                height: "15px",
                                width: "15px",
                            }}/>
                            </IconButton>
                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {auto()}
                            </Popover>
                        </Box>
                    </Tooltip>
                </>
            }
        </Box>
    }
}

export default ActivePSUSelector