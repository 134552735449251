import React, {FC, useCallback, useEffect, useMemo} from "react";
import Box from "@mui/material/Box";
import Widget, {Template, WidgetArguments, WidgetGroupItem} from "../../../../../cog/bitool/widget/Widget";
import { Responsive, WidthProvider } from 'react-grid-layout';
import WidgetSwitcher from "../WidgetSwitcher";
import Button from "@mui/material/Button";

import AceCodeEditor from "../../../../../components/AceCodeEditor";
import {Autocomplete, CircularProgress, Grid, Paper, Switch, TextField, Typography} from "@mui/material";

import 'react-grid-layout/css/styles.css'
import {useDispatch, useSelector} from "react-redux";
import {
    initializedSelector,
    widgetDependencyIdsFunc, widgetDependencyVariablesSelector, widgetIsLoadingSelectorFunc, widgetTemplatesSelectorFunc
} from "../../../../../slices/selectors";
import ArrayToObject from "../../../../../utils/ArraysToObjectsByIndex";
import objFromArray from "../../../../../utils/objFromArray";
import {ArgumentStore} from "../../../../../cog/bitool/query";
import Argument from "../../../../../cog/bitool/query/argument/Argument";
import {DefaultLayout} from "./editorLayout";
import WidgetFormDialog from "./WidgetFormDialog";
import {widgetChangeVariable} from "../../../../../slices/bitool";
import _ from "lodash";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface WidgetEditorProps {
    widget: Widget;
    setWidget: (Widget, string) => void;
    allWidgets: Widget[]
    OnWidSave: any;
}

const OldWidgetEditor: FC<WidgetEditorProps> = ({ widget , setWidget, OnWidSave, allWidgets}) => {
    let dispatch = useDispatch()
    const selectWidgetDependencyIdsFunc = useMemo(widgetDependencyIdsFunc, [])
    const selectWidgetDependencyOutputs = useMemo(widgetDependencyVariablesSelector, [])

    //TODO: CLEAN UP
    const initSelector = useSelector( (state) =>
        initializedSelector(state)
    ), widgets = useSelector((state) =>
        selectWidgetDependencyIdsFunc(state, widget.id)
    ), widgetDependencyOutputs = useSelector((state) =>
        selectWidgetDependencyOutputs(state, widget.id)
    )
    const [queryArguments, setQueryArguments] = React.useState<Argument[]>();
    const [draggable, setDraggable] = React.useState(false);
    const [widArg, setWidArg] = React.useState<string>(JSON.stringify(widget.arguments,  null, "\t"));
    const [code, setCode] = React.useState<string>(widget.arguments.code ? widget.arguments.code : "");
    const [layout, setLayout] = React.useState<{}>(DefaultLayout);

    const isGroupOrStepper = widget.arguments.type == "BasicWidgetGroupStepper" ||
        widget.arguments.type == "BasicWidgetGroupTabs" ||
        widget.arguments.type == "BasicWidgetGroup"

    const handleChange = (event) => {
        setDraggable(event.target.checked);
    };

    const handleChangeTextField = (event) => {
        let newWidget: Widget = new Widget(widget)
        newWidget.name = event.target.value
        setWidget(newWidget, newWidget.id)
    }

    const handleAddWidgetToGroup = (event, val:Widget) => {
        if (val == undefined || null) {
            return;
        }
        let group: WidgetGroupItem = {id: val.id, label: val.name}
        setWidArg((prevState => (JSON.stringify({
            group: [...JSON.parse(prevState).group, group],
            type: JSON.parse(prevState).type,
            templates: {...JSON.parse(prevState).templates}
        }, null, "\t"))))
    }

    const fetchQueryArgs = useCallback(async () => {
        if (widget.arguments.executorArgs && widget.arguments.executorArgs.queryId) {
            let response = await ArgumentStore.FindAll({
                queryId: widget.arguments.executorArgs.queryId
            })
            setQueryArguments(response.queryArguments)
        } else {
            setQueryArguments(null)
        }
    }, [widget])

    const setDepOutputs = useCallback(async () => {
        if (queryArguments && queryArguments.length > 0 && widget && widget.arguments.templates) {
            const arg = JSON.parse(queryArguments[0].argument)
            console.log("QueryArgs: ",arg)

            let outputDataDict: Record<string, Array<any>> = {}
            Object.keys(widget.arguments.templates).map((k) => {
                if (arg[k]) {
                    let val = arg[k]
                    if (widget.arguments.templates[k].serializerStringFunction == "$StringSerializer" ||
                        widget.arguments.templates[k].serializerStringFunction == "$SqlStringSerializer" ) {
                        val = val.replaceAll("'", "")
                    }
                    if (widget.arguments.templates[k].serializerStringFunction == "$DateSerializer") {
                        val = val.replaceAll("'", "")
                        val = new Date(val)
                    }
                    if (widget.arguments.templates[k].serializerStringFunction == "$StringArraySerializer") {
                        val = val.replaceAll("'", "")
                        val = val.replaceAll("(", "")
                        val = val.replaceAll(")", "")
                        val = val.split(",")
                        val = val.map((v) => v.trim())
                    }
                    let tmp = outputDataDict[widget.arguments.templates[k].widgetId]
                    if (tmp == null) tmp = []
                    tmp.push({
                        [widget.arguments.templates[k].keyOrIndex]: val
                    })
                    outputDataDict[widget.arguments.templates[k].widgetId] = tmp
                }
            })

            Object.keys(outputDataDict).map((id) => {
                dispatch(widgetChangeVariable(id, Object.assign({}, ...outputDataDict[id])))
            })
        }
    }, [queryArguments, widget])

    useEffect(() => {
        fetchQueryArgs()
    }, [fetchQueryArgs])

    useEffect(() => {
        setDepOutputs()
    }, [setDepOutputs])

    useEffect(() => {
    }, [widgetDependencyOutputs, initSelector])

    if (!widget) {
        return <Box />
    }

    if (!initSelector) {
        return (
            <Grid item xs={8}>
                <Box sx={{minHeight: "100"}}>
                    <CircularProgress />
                </Box>
            </Grid>
        )
    }

    return (
        <Box>
            <ResponsiveGridLayout className="layout"
                                  layouts={layout}
                                  rowHeight={15}
                                  isDraggable={draggable}
                                  isResizable={true}
                                  compactType={null}
                                  onLayoutChange={(_, layouts) => {
                                      console.log("Layouts: ", layouts)
                                      // saveToLS("layouts", layouts)
                                  }}
                                  breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                  cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
                <div key={"0"}>{(widget.arguments.type == "BasicWidgetGroup" || widget.arguments.type =="BasicWidgetGroupStepper") ? (
                    <Autocomplete
                        disablePortal
                        id="combo-box"
                        sx = {{width: "100%", height: "100%"}}
                        options={allWidgets}
                        getOptionLabel={(option: Widget) => option.name}
                        onChange={handleAddWidgetToGroup}
                        renderInput={(params) => <TextField {...params} label="Add Widgets to Group" />}
                    />): null}</div>
                <div key={"1"}>
                    <Typography variant="h5" component="div" gutterBottom>
                        {widget.id}
                    </Typography>
                </div>
                <div key={"2"}>
                    <div style={{height: "100%", width: "100%"}}>
                        <TextField
                            style={{height: "100%", width: "100%"}}
                            defaultValue ={widget.name}
                            id="outlined-basic" label={"Widget Name"}
                            key={widget.id}
                            onChange={handleChangeTextField}
                            variant="outlined"
                        />
                    </div>
                </div>
                <div key={"3"}>
                    <Switch
                        checked={draggable}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                <div key={"4"}>
                    <AceCodeEditor setCode={currentCode => setWidArg(currentCode)} code={widArg} language={'json'}/>
                </div>
                <div key={"5"}>
                    <AceCodeEditor code={code} setCode={setCode} language={'javascript'}/>
                </div>
                <div key={"6"}>
                    <Button onClick={()=> {OnWidSave(widget, code, widArg)}} variant="contained">Save & Update</Button>
                </div>
                <div key={"7"}>
                    <WidgetSwitcher widget={widget}/>
                </div>
                <div key={"8"}>
                    <WidgetFormDialog label = "Build Widget" key={ widget.id} setCode={setCode} onAdd={setWidArg} queryID={widget.arguments.executorArgs ? widget.arguments.executorArgs.queryId : ""} allWids={allWidgets}/>
                </div>
            </ResponsiveGridLayout>

            <Paper sx={{ display: 'none' }} >
                { isGroupOrStepper && widgets.map((widg) => {
                    return <WidgetSwitcher widget={widg} key={widg}/> })
                }
            </Paper>
        </Box>



    );
}

export default OldWidgetEditor