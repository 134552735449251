import type {FC} from 'react';
import React from "react";
import GenericColumnChart from "./genericWidgets/GenericColumnChart";
import GenericLineChart from "./genericWidgets/GenericLineChart";
import GenericDonutChart from "./genericWidgets/GenericDonutChart";
import GenericTable from "./genericWidgets/GenericTable";
import { useErrorBoundary } from "use-error-boundary";
import { Box, Button } from "@mui/material";

export interface InsightWidgetProps {
  chartName: string;
  chartType: string;
  chartData: {};
  chartColumns?: string[];
  labelValue: string;
}

const InsightWidgetSwitcherInst: FC<InsightWidgetProps> = (insightWidgetProps) => {
  if (!insightWidgetProps.chartType) {
    return <div> Insight Widget not valid </div>
  }

  return {
    'ColumnChart': <GenericColumnChart {...insightWidgetProps} />,
    'LineChart': <GenericLineChart {...insightWidgetProps} />,
    'DonutChart': <GenericDonutChart {...insightWidgetProps} />,
    'Table': <GenericTable {...insightWidgetProps} />
  }[insightWidgetProps.chartType] || <div>Insight Widget Type Not Supported</div>
}

const InsightWidgetSwitcher: FC<InsightWidgetProps> = (props) => {
  const { ErrorBoundary, didCatch, error, reset } = useErrorBoundary()

  return (
    <div>
      {didCatch ? (
        <Box>
          <p>An error has been caught: {error.message}</p>
          <Button onClick={reset} variant="contained">Reset</Button>
        </Box>
      ) : (
        <ErrorBoundary>
          <InsightWidgetSwitcherInst {...props} />
        </ErrorBoundary>
      )}
    </div>
  );
}

export default InsightWidgetSwitcher;