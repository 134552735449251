import type {FC} from 'react';
import {useSnackbar} from 'notistack';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  Skeleton,
  TextField
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Store } from "../../../../../cog/bitool/globalVariable"
import { DashboardCloner } from "../../../../../cog/bitool/dashboard";
import Template from "../../../../../cog/bitool/dashboard/cloner/Template";
import { useNavigate } from "react-router-dom";
import GlobalVariable from "../../../../../cog/bitool/globalVariable/GlobalVariable";

interface CloningVariablesFormProps {
  handleDialogClose: () => void;
  dashboardId: string;
  organizationId: string;
}

const useStyles = makeStyles((theme:Theme) => ({
  formControl: {
    minWidth: 140,
    width: "100%"
  }
}));

const CloningVariablesForm: FC<CloningVariablesFormProps> = ({handleDialogClose, dashboardId, organizationId}) => {
  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();
  let navigate = useNavigate();

  const [variables, setVariables] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchVariables = () => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => Store.FindAndGetAll({organizationId: organizationId, dashboardId: dashboardId}))
      .then((r) => setVariables(r.variables))
      .then(() => setLoading(false))
  }

  useEffect(() => {
    fetchVariables()
  }, [organizationId]);

  const handleSubmit = async (event) => {
    event.preventDefault()

    setLoading(true);

    let templateMap : Template[] = [];
    let template : Template

    for (let i = 0; i < variables.length; i++) {
      let name = event.target.elements["VariableName" + i].value
      let value = event.target.elements["VariableValue" + i].value

      template = {
        name: name,
        value: value,
      }

      templateMap.push(template)
    }

    const response = await DashboardCloner.CloneOne({dashboardId: dashboardId, template: templateMap})
    if (response) {
      enqueueSnackbar("Cloned Dashboard!", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "success",
      });
      navigate('/app/bi/dashchoose/')
    } else {
      enqueueSnackbar("Error Cloning Dashboard!", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }

    setLoading(false);

    handleDialogClose();
  };

  return (
    <Card sx={{ overflowY: "scroll"}}>
      {!loading ?
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Dashboard Global Variables" sx={{ textAlign: 'center' }} titleTypographyProps={{variant:'h3' }}/>
            <Divider />
            <CardContent>
              <FormControl variant="outlined" className={classes.formControl}>
                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="stretch">
                  {variables != null && variables.map((variable, i) => {
                    return (
                      <Grid container item spacing={3} direction="row" justifyContent="space-evenly" alignItems="center" key={"inner container " + i}>
                        <Grid item md={6} xs={12} sx={{ width: "100%"}} key={"Grid Item VN " + i}>
                          <TextField key={"VN" + i} name={"VariableName" + i} label={variable.name} defaultValue={variable.name} fullWidth/>
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ width: "100%"}} key={"Grid Item VV " + i}>
                          <TextField key={"VV" + i} name={"VariableValue" + i} label={variable.value} defaultValue={variable.value} fullWidth/>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </FormControl>
            </CardContent>
            <Divider />
            <Box sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}>
              <ButtonGroup variant="contained">
                <Button type="submit">Clone Dashboard</Button>
                <Button onClick={handleDialogClose}>Cancel</Button>
              </ButtonGroup>
            </Box>
          </Card>
        </form> : <Card>
          <CardHeader title="Dashboard Global Variables" sx={{ textAlign: 'center' }} titleTypographyProps={{variant:'h3' }}/>
          <Divider />
          <CardContent>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 1}}/>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 1}}/>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 1}}/>
          </CardContent>
        </Card>
      }
    </Card>
  );
}

export default CloningVariablesForm;