export const WidgetTypes = [
  {
    "id": 1,
    "name": "Table"
  },
  {
    "id": 2,
    "name": "Autocomplete Filter"
  },
  {
    "id": 3,
    "name": "Single Date"
  },
  {
    "id": 4,
    "name": "Date Range"
  },
  {
    "id": 5,
    "name": "Date Display"
  },
  {
    "id": 6,
    "name": "Label"
  },
  {
    "id": 7,
    "name": "Single Value Indicator"
  },
  {
    "id": 8,
    "name": "Widget Group"
  },
  {
    "id": 9,
    "name": "Drilldown"
  },
  {
    "id": 10,
    "name": "Apex Column Chart"
  },
  {
    "id": 11,
    "name": "Apex Pie Chart"
  },
  {
    "id": 12,
    "name": "Apex Line Chart"
  },
  {
    "id": 13,
    "name": "Apex Scatter Chart"
  },
  {
    "id": 14,
    "name": "Apex Bullet Chart"
  },
  {
    "id": 15,
    "name": "Apex Heatmap"
  },
  {
    "id": 16,
    "name": "Line Divider"
  },
  {
    "id": 17,
    "name": "Vertical Line Divider"
  },
  {
    "id": 18,
    "name": "Image"
  },
]