import * as React from 'react';
import {FC, useEffect, useLayoutEffect} from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import {styled} from '@mui/material/styles';
import {ErrorRounded} from "@mui/icons-material";

interface PriorityRatingProps {
    rating: number
    handleChange?: (e, v) => void
    readOnly: boolean;
    keyId?: any
    source?: string
}

const labels: { [index: string]: string } = {
    0.5: '',
    1: '',
    1.5: '',
    2: '',
    2.5: '',
    3: '',
    3.5: '',
    4: '',
    4.5: '',
    5: '',
};

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : 's'}`;
}
const StyledRating = styled(Rating)(({theme}) => ({

    '& .MuiRating-icon': {
        color :  (theme.palette.mode === 'dark' ? "#d7d7d7" : '#bcbcbc'),
    },
    '& .MuiRating-iconFilled': {
        color :  (theme.palette.mode === 'dark' ? "#fd473e" : '#f05c59'),
    },
    '& .MuiRating-iconHover': {
        color :  (theme.palette.mode === 'dark' ? "#9f0902" : '#a8403e'),
    },

}));
const PriorityRating: FC<PriorityRatingProps> = (props) => {
    const [initialValue, setInitialValue] = React.useState(props.rating)
    const [value, setValue] = React.useState<number | null>(0);
    const [hover, setHover] = React.useState(-1);
    const id = Math.floor(Math.random() * 1000)
    let count = 0

    const handleNewValue = (event, newValue) => {
        setValue(newValue)
        props.handleChange(event, newValue)
    }

    useEffect(() => {
        if (props.source && props.source == 'Create') {
            setValue(props.rating)
        } else {
            const interval = setInterval(() => {

                if (count < initialValue) {
                    setValue(count + 0.5);
                    count = count + 0.5
                } else {
                    clearInterval(interval);
                }
            }, 100);

            return () => clearInterval(interval);
        }

    }, [count]);

    useLayoutEffect(()=> {
        if (props.source && props.source == 'Create') {
            setValue(props.rating)
        }
    },[])

    return (
        <Box
        >
            <StyledRating
                key={id}
                name="hover-feedback"
                value={value}
                precision={0.5}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                    handleNewValue(event,newValue)
                    if (props.source && props.source == 'Create') {
                        setValue(newValue)
                    }
                }}
                onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }}
                icon={<ErrorRounded fontSize="inherit"/>}
                emptyIcon={<ErrorOutlineRoundedIcon fontSize="inherit"/>}
                readOnly={props.readOnly}
                size="small"
                sx={{
                    display: 'flex',
                    alignItems: props.source && props.source == "Create" ? 'left' : 'center',
                    width: '100%',
                    justifyContent: props.source && props.source == "Create" ? 'left' : 'center',
                    // justifyItems: 'center'
                }}
            />
            {value !== null && (
                <Box sx={{ml: 2}}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
        </Box>
    );
}

export default PriorityRating;