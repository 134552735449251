import {Box, Grid, LinearProgress, Skeleton, Typography} from "@mui/material";
import React from "react";

const CampaignPageSkeleton = () => {

  return (
    <Box >
      <Box >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item sm={12}>
            <Typography variant={'h4'} sx={{m: 1}}>
              <LinearProgress
                sx={{
                  marginLeft: 4,
                  marginRight: 4,
                  marginTop: 4,
                  justifySelf: "center"
                }} />
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item sm={12} >
            <Skeleton variant="rectangular" height={"50vh"} width={"100%"} sx={{borderRadius: "16px"}}/>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CampaignPageSkeleton