import {FC} from "react";
import {Box, LinearProgress, Typography} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {StyledDataGrid} from "../../listview/CustomDataGridTheme";
import * as React from "react";

const GenericDataGridComponentLoader: FC = () => {
    const LoaderColumns: GridColDef = {
        field: "Loading",
        colSpan: 1,
        hideSortIcons: true,
        flex: 1,
        renderCell: (params) => <RenderLoadingBar/>,
        renderHeader: (params) => <RenderLoadingBar/>
    }
    const LoaderRows = {
        id: 0,
        Loading: "loading",
    }

    return <Box sx={{
        height: "inherit",
        width: "inherit"
    }}>
        <StyledDataGrid
            columns={[LoaderColumns]}
            rows={[LoaderRows]}
            getRowHeight={() => 'auto'}
            disableDensitySelector={true}
            checkboxSelection={false}
            rowsPerPageOptions={[10]}
            pageSize={10}
            pagination={true}
        />

    </Box>
}

const RenderLoadingBar: FC = () => {
    return <Box
        sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            width: "100%"
        }}
    >
        <Typography variant={"body1"} >Loading</Typography>
        <LinearProgress
            sx={{
                marginLeft: 5,
                marginRight: 5,
                marginTop: 2,
                justifySelf: "center",
                minWidth: "90%"
            }}
        />
    </Box>
}

export default GenericDataGridComponentLoader