import _ from "lodash";
import {Template} from "../../../../../cog/bitool/widget/Widget";

function iterate (obj, key) {
    var result;

    for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (property === key) {
                return obj[key]; // returns the value
            }
            else if (typeof obj[property] === "object") {
                // in case it is an object
                result = iterate(obj[property], key);

                if (typeof result !== "undefined") {
                    return result;
                }
            }
        }
    }
}

const iterate2 = (obj, key) => {
    const iter = iterate(obj, key)
    // if (iter && Object.keys(iter) && Object.keys(iter).length && (Object.keys(iter).length == 1)){
    //     return iter[Object.keys(iter)[0]]
    // }
    return iter
}

export const serializeOutputData = (outputData: any, template: Template): any => {
    let od: any = undefined
    if (((outputData && outputData.length > template.keyOrIndex) || // an array
        (outputData && Object.keys(outputData).length)) ||
        (template.serializerStringFunction == "$DateSerializer")) { // a record
        // if ((typeof template.keyOrIndex === 'number') || (typeof template.keyOrIndex === 'string')) {
        if (outputData && template.keyOrIndex) {
            od = iterate2(outputData, template.keyOrIndex)
            // od = outputData[template.keyOrIndex]
        } else {
            return ''
        }
    } else {
        return ''
    }
    try {
        switch(template.serializerStringFunction){
            case "$StringArraySerializer":
                return sqlJoinerMulti(od)
            case "$SqlStringSerializer":
                return sqlStringSerializer(od)
            case "$RawSqlSerializer":
                return rawSqlSerializer(od)
            case "$RawSqlStringSerializer":
                return rawSqlStringSerializer(od)
            case "$StringSerializer":
                return singleStringSerializer(od)
            case "$DateSerializer":
                return sqlDateSerializer(od)
            case "$DateYearSerializer":
                return sqlDateYearSerializer(od)
            case "$RawSqlSingleStringSerializer":
                return rawSqlSingleStringSerializer(od)
            default:
                console.log('default, use eval on string');
                break;
        }
    } catch (e) {
        console.error(`${e}`);
    }

}

const sqlJoinerMulti = (arr: any): string => {
    const flat = _.flatten(arr)
    if (Array.isArray(flat) && flat.length > 0) return '(\'\'\'' + flat.join('\'\'\',\'\'\'') + '\'\'\')'
    return ''
}

const sqlSingleString = (arr: any): string => {
    if (!arr) return ''
    if (typeof arr === 'string') return arr
    if (typeof arr == 'number') return arr.toString()
    if (Array.isArray(arr) && arr.length == 0) return ''
    if (Array.isArray(arr) && arr.length == 1) return sqlSingleString(arr[0])
    if (Object.keys(arr)) {
        if (arr[Object.keys(arr)[0]].length > 1) return sqlSingleString(arr[Object.keys(arr)[0]][0])
        return sqlSingleString(arr[Object.keys(arr)[0]])
    }
    return ''
}

const singleStringSerializer = (arr: any): string => {
    const str = sqlSingleString(arr)
    if (str) return '(\'\'\'' + str + '\'\'\')'
    else return ''
}

const sqlStringSerializer = (arr: any): string => {
    const str = sqlSingleString(arr)
    if (str) return '\'\'\'' + str + '\'\'\''
    else return ''
}

const rawSqlSerializer = (arr: any): string => {
    if (!arr) return ''
    if (Array.isArray(arr) && arr.length == 1)  {
        // console.log("Raw SQL Serializer: ", arr[0])
        return arr[0]
    }
    else {
        // console.log("Raw SQL Serializer: ", arr)
        return arr
    }
}

const rawSqlSingleStringSerializer = (arr: any): any => {
    if (!arr) return ''
    if (Array.isArray(arr) && arr.length == 1) {
        console.log("Raw SQL Single String Serializer: ", arr)
        return arr
    }
}

const rawSqlStringSerializer = (arr: any): string => {
    // console.log("Raw SQL String Serializer: ", rawSqlSerializer(arr).toString())
    return rawSqlSerializer(arr).toString()
}


const sqlDateSerializer = (arr: any): string => {
    return "'" + new Date(arr).toISOString().slice(0,10) + "'"
}

const sqlDateYearSerializer = (arr: any): string => {
    return "'" + new Date(arr).getFullYear() + "'"
}