/* eslint-disable */
import React, {createContext, useContext, useEffect, useState} from "react";

import {ColumnMapDict, FilterItem} from "../cog/keystone/filter/Filter";
import {Card, KanbanColumnObj, KanbanStore} from "../cog/keystone/card";
import {useMainContext} from "./MainContext";
import {indexOf, values} from "lodash";
import {DefaultFilterStore} from "../cog/keystone/defaultFilter";
import {Filter as FilterObject, FilterStore} from "../cog/keystone/filter";
import TopBarDisplayOptions from "../layouts/toolbar/topbar/TopBarDisplayOptions";
import {DateRange} from "@mui/x-date-pickers-pro/DateRangePicker";
import {ExcludeStore} from "../cog/keystone/exclude";
import {GetComparator, StableSort} from "../utils/stableSort";
import {OppLabelStore} from "../cog/keystone/oppLabel";
import {OppLabel} from "src/cog/keystone/oppLabel/OppLabel";
import {
    ConvertTreeFromApiForUse, ConvertTreeFromBeingUsedToApiSave, GenerateNewPsuTree,
    TreeForUseByPSU,
    TreeFromAPI
} from "../pages/app/keystone/productstoreuniverse/utility/NodeTypesAndOptions";
import {PSUStore} from "../cog/keystone/productStoreUniverse";

interface KeystoneContext {
    setKanbanColumns: (kanbanColumns: Record<string, KanbanColumnObj>) => void;
    kanbanColumns: Record<string, KanbanColumnObj>;

    levelMode: boolean;
    filters: FilterItem[];
    setFilters: (filter: FilterItem[]) => void;
    clickThroughFilters: FilterItem[];
    setClickThroughFilters: (filter: FilterItem[]) => void;
    setDefaultFilters: (filter: FilterItem[]) => void;
    defaultFilters: FilterItem[];
    defaultFiltersLoading: boolean;
    setStatusCardOptions: (statusCardOptions: Record<string, string>) => void;
    statusCardOptions: Record<string, string>;
    setLevelMode: (boolean) => void;
    appliedFiltersCount: number;
    setAppliedFiltersCount: (value: number) => void;

    cardId: string,
    cardOpen: boolean,
    handleCardId: (CardId) => void,
    handleCardOpen: () => void,
    handleCardClose: () => void,

    suppliers: string[],
    setSuppliers: (suppliers: string[]) => void;
    handleSupplierChange: (supplier: string, addTo: boolean, refreshSearch: boolean) => void;

    colMap: ColumnMapDict,
    setColMap: (colMap: ColumnMapDict) => void;

    filterableFields: Record<string, FilterableItem>,
    setFilterableFields: (filterableFields: Record<string, FilterableItem>) => void;

    getFilterableFields: () => Promise<void>;
    fetchMap: () => Promise<void>;
    fetchSavedFilters: () => Promise<void>;
    updateSavedFilter: () => Promise<void>;
    saveEmptyFilter: () => Promise<void>;

    filtersReady: boolean
    setFiltersReady: (filtersReady: boolean) => void;
    filterableSearching: boolean
    setFilterableSearching: (filterableSearching: boolean) => void;
    filterOpen: boolean
    excludeFilterOpen: boolean
    setFilterOpen: (filterOpen: boolean) => void;
    setExcludeFilterOpen: (excludeFilterOpen: boolean) => void;
    handleFilterOpen: () => void;
    handleExcludeFilterOpen: () => void;
    statsDrawerOpen: boolean
    setStatsDrawerOpen: (statsDrawerOpen: boolean) => void;
    handleToggleStatsDrawer: () => void;
    statsLoading: boolean
    setStatsLoading: (statsLoading: boolean) => void;

    selectCards: boolean
    setSelectCards: (selectCards: boolean) => void;
    selectedCardsID: []
    setSelectedCardsID: (selectCards: []) => void;
    updateSelectedCards: (val: string, valName: string) => void;
    handleSelectCards: () => void;
    selectedCardsNames: any[]
    setSelectedCardsNames: (selectCards: any[]) => void;
    handleFilterClear: () => void;
    handleExcludeFilterClear: () => void;
    handleFilterClose: () => void;
    handleExcludeFilterClose: () => void;
    fetchStatData: () => void;
    statData: any
    setStatData: (statData: any) => void;

    flipSwitchForCards: boolean
    setFlipSwitchForCards: (flip: boolean) => void;
    flipSwitchForGoals: boolean
    setFlipSwitchForGoals: (flip: boolean) => void;
    filterHasChanged: boolean
    setFilterHasChanged: (val: boolean) => void;

    loadingBar: boolean
    setLoadingBar: (loading: boolean) => void;

    toolBarDisplayOptions: TopBarDisplayOptions
    setToolBarDisplayOptions: (toolbarOptions: TopBarDisplayOptions) => void

    dateValue: DateRange<Date>
    setDateValue: (dateValue: DateRange<Date>) => void
    createGoalOpen: boolean
    setCreateGoalOpen: (createOpen: boolean) => void
    toggleGoalOpen: () => void

    opportunityLabels : OppLabel[]
    fetchOppLabels : () => Promise<void>
    flipSwitchForLabels: boolean
    setFlipSwitchForLabels: (flip: boolean) => void;
    completedOptions: OppLabel[];

    allCards : Record<string, Card>
    setAllCards: (allCards: Record<string, Card>) => void;

    myRows : any[]
    setMyRows : (myRows: any[]) => void
    excluded : any
    setExcluded : (excluded: any) => void

    RefreshCards : () => void

    psuTrees : TreeForUseByPSU[]
    setPsuTrees : (psuTrees: TreeForUseByPSU[]) => void
    activePSU: TreeForUseByPSU
    setActivePSU: (psu: TreeForUseByPSU) => void
    activePsuReady: boolean
    setActivePsuReady: (ready: boolean) => void

    psuTreesUpdatedSwitch: boolean
    setPsuTreesUpdatedSwitch: (s: boolean) => void

    FetchPSUTrees : () => void
}

const Context = createContext({} as KeystoneContext);
Context.displayName = "KeystoneContext"

interface FilterableItem {
    name: string,
    header: string;
    type: string,
    data: any[]
}

export const KeystoneContext: React.FC = ({children}: { children?: React.ReactNode }) => {
    const ctx = useMainContext()
    const [levelMode, setLevelMode] = useState<boolean>(false);
    const [kanbanColumns, setKanbanColumns] = useState<Record<string, KanbanColumnObj>>(null);
    const [filters, setFilters] = useState<FilterItem[]>([])
    const [clickThroughFilters, setClickThroughFilters] = useState<FilterItem[]>([])
    const [defaultFilters, setDefaultFilters] = useState<FilterItem[]>([])
    const [defaultFiltersLoading, setDefaultFiltersLoading] = useState<boolean>(false);
    const [statusCardOptions, setStatusCardOptions] = useState<Record<string, string>>(null)
    const [cardId, setCardId] = useState<string>(null);
    const [cardOpen, setCardOpen] = useState<boolean>(false);
    const [suppliers, setSuppliers] = useState<string[]>([]);
    // Map of generic column names to organisation specific column names
    const [colMap, setColMap] = useState<ColumnMapDict>(null)
    const [filterableFields, setFilterableFields] = useState<Record<string, FilterableItem>>({})
    const [filtersReady, setFiltersReady] = useState<boolean>(false); // For letting the container know the filters are ready for use
    const [filterableSearching, setFilterableSearching] = useState<boolean>(true); // For letting the filter know when the filterable is searching
    const [filterOpen, setFilterOpen] = useState(false); // For tracking if the filter is opened or closed
    const [excludeFilterOpen, setExcludeFilterOpen] = useState(false); // For tracking if the exclude filter drawer is opened or closed
    const [statsDrawerOpen, setStatsDrawerOpen] = useState<boolean>(false)// For tracking if the stats drawer is open
    const [statsLoading, setStatsLoading] = useState<boolean>(true)
    const [statData, setStatData] = useState<any>({})
    const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

    // For the userAssign Toolbar
    const [selectCards, setSelectCards] = useState<boolean>(false)
    const [selectedCardsID, setSelectedCardsID] = useState<any>([])
    const [selectedCardsNames, setSelectedCardsNames] = useState<any>([])

    // flip this boolean switch to make keystone fetch new cards when the container is open
    const [flipSwitchForCards, setFlipSwitchForCards] = useState<boolean>(false)
    const [flipSwitchForGoals, setFlipSwitchForGoals] = useState<boolean>(false)
    const [flipSwitchForLabels, setFlipSwitchForLabels] = useState<boolean>(false)

    // This one is basically just for when the filter closes so that we know if we should reload the data
    const [filterHasChanged, setFilterHasChanged] = useState<boolean>(false)

    // Used to decide if the loading bar for the keystone pages is visible or not
    const [loadingBar, setLoadingBar] = useState<boolean>(false)

    // For tracking which icons to display in the toolbar
    const [toolBarDisplayOptions, setToolBarDisplayOptions] = useState<TopBarDisplayOptions>({
        filter: false,
        stats: false,
        selectOpps: false,
        filterToMe: false,
        landingDateSelector: false
    })

    // Create Goal Dialogue stuff
    const [createGoalOpen, setCreateGoalOpen] = React.useState<boolean>(false)

    // Date Selector for Landing Page
    const timeElapsed = Date.now();
    const now = new Date(timeElapsed);
    const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    const [dateValue, setDateValue] = React.useState<DateRange<Date>>([lastWeek, now]);

    const [oppLabels, setOppLabels] = React.useState<OppLabel[]>([])
    const [labelsLoading, setLabelsLoading] = React.useState<boolean>(false)

    // Was being used by the datagrid, currently used to create the rows that datagrid and homepage uses,
    // also keystone uses it
    const [allCards, setAllCards] = useState<Record<string, Card>>(null);
    // Rows with extracted v0 and levelFilter fields, for use by the DataGrid
    const [myRows, setMyRows] = useState<any[]>(null);

    //Exclude Params
    const [excluded, setExcluded] = useState<any>(null);

    // For the PSU
    const [psuTrees, setPsuTrees] = useState<TreeForUseByPSU[]>(null)
    const [psuTreesUpdatedSwitch, setPsuTreesUpdatedSwitch] = useState<boolean>(false)
    // The active psu for use by queries and what not
    const [activePSU, setActivePSU] = useState<TreeForUseByPSU>(null)
    const [activePsuReady, setActivePsuReady] = useState<boolean>(false)

    async function FetchUserActivePSU() {
        setActivePsuReady(false)
        let response = await PSUStore.FetchActivePSUByUserID({
            UserID: ctx.user.id,
            ClientName: ctx.activeOrganization.name
        })
        let tempPSU = psuTrees.find(element => element.ID == response.PSU_ID)
        if (tempPSU != null) {
            setActivePSU(tempPSU)
        }else {
            setActivePSU(null)
        }
        setActivePsuReady(true)
    }

    useEffect(() => {
        if (psuTrees != null && activePSU == null){
            Promise.resolve().then(() => FetchUserActivePSU())
        }

    }, [psuTrees, psuTreesUpdatedSwitch]);

    async function FetchPSUTrees() {
        // setLoadingBar(true)
        let response = await PSUStore.FetchAllByUser({
            ClientName: ctx.activeOrganization.name,
            User_ID: ctx.user.id
        })
        let tempPSUTrees: TreeForUseByPSU[] = []
        if (response.PSUTrees != null) {
            tempPSUTrees = response.PSUTrees.sort((a,b) => 0 - (a.Name < b.Name ? 1 : -1))
        }
        // this is from when the api returned the trees as strings
        // let resultingUsableTree: TreeForUseByPSU[] = []
        // if (tempPSUTrees != null) {
        //     for (let i = 0; i < tempPSUTrees.length; i++) {
        //         // let tempUseTree = {
        //         //     ID: tempPSUTrees[i].ID,
        //         //     Name: tempPSUTrees[i].Name,
        //         //     Org_Level: tempPSUTrees[i].Org_Level,
        //         //     // Tree: tempTree,
        //         //     Tree: await JSON.parse(tempPSUTrees[i].Tree),
        //         //     User_ID: tempPSUTrees[i].User_ID
        //         // }
        //         let tempUseTree = await ConvertTreeFromApiForUse(tempPSUTrees[i])
        //         console.log("help ", i)
        //         console.log(tempUseTree)
        //         resultingUsableTree.push(tempUseTree)
        //     }
        //     console.log("resultingUsableTree", resultingUsableTree)
        // }
        // resultingUsableTree = resultingUsableTree.sort((a,b) => 0 - (a.Name < b.Name ? 1 : -1))
        setActivePSU(null) // this one prevents the autocomplete from saying the value is invalid
        setPsuTrees(null)
        setPsuTrees(tempPSUTrees)
        setPsuTreesUpdatedSwitch(psuTreesUpdatedSwitch!)
    }

    useEffect(() => {
        if (ctx.user != null && ctx.activeOrganization != null) {
            Promise.resolve().then(r => FetchPSUTrees())
        }
    }, [ctx.user, ctx.activeOrganization]);

    // updateSelectedCards : updates array of card ID whenever a card is selected or deselected for user assign
    const updateSelectedCards = (val: string, valName: string) => {
        if (indexOf(selectedCardsID, val) == -1) {
            setSelectedCardsID([...selectedCardsID, val])
            setSelectedCardsNames([...selectedCardsNames, valName])
        } else {
            let tempSelected = []
            let tempSelectedNames = []
            selectedCardsID.map((v, i) => {
                if (v != val) {
                    tempSelected.push(v)
                    tempSelectedNames.push(selectedCardsNames[i])
                }

            })
            setSelectedCardsID(tempSelected)
            setSelectedCardsNames(tempSelectedNames)
            if (tempSelected.length == 0) {
                setSelectCards(false)
            }
        }
    }

    // handleSelectCards: Switches between selecting cards and normal behaviour
    const handleSelectCards = () => {
        if (selectCards == true) {
            setSelectedCardsID([])
            setSelectedCardsNames([])
        }
        setSelectCards(!selectCards)
    };
    const [completedOptions, setCompletedOptions] = useState<OppLabel[]>([])

    // getDefaultFilters: fetches and sets default filters for user/org combo
    const getDefaultFilters = async (): Promise<any> => {
        setDefaultFiltersLoading(true)
        setFiltersReady(false)
        try {
            let response = await DefaultFilterStore.FindOne({
                userID: ctx.user.id,
                organizationID: ctx.activeOrganization.id
            })

            if (response.defaultFilter && response.defaultFilter.filters) {
                setDefaultFilters(response.defaultFilter.filters);
            } else {
                setDefaultFilters([])
                setSuppliers([])
            }
        } catch (e) {
            console.error(`${e}`);
        }
        setDefaultFiltersLoading(false)
    }
    
    // Update the state with the transformed options
    // setStatusOptions(transformedOptions);
    const getStatusCard = async (): Promise<any> => {
        // setDefaultFiltersLoading(true)
        // setFiltersReady(false)
        try {
            let response = await KanbanStore.FindStatusAllCards({
                userID: ctx.user.id,
                organizationID: ctx.activeOrganization.id,
            })
            
            if (response && response.statusCard) {
                // setStatusCardOptions(response.statusCard)
                const transformedOptions: Record<string, string> = {};
                response.statusCard.forEach((option) => {
                    // transformedOptions[option.id] = option.name;
                    transformedOptions[option.name] = option.id;
                });
                setStatusCardOptions(transformedOptions);
                // setStatusCardOptions(response);
            } else {
                setStatusCardOptions({})
                // setSuppliers([])
            }
        } catch (e) {
            console.error(`${e}`);
        }
        // setDefaultFiltersLoading(false)
    }
    
    // handleCardOpen: Handles opening and closing of opportunity card
    const handleCardOpen = () => {
        setCardOpen(true)
    }

    // handleCardClose: Handles closing of Opportunity card
    const handleCardClose = () => {
        setCardId(null)
        setCardOpen(false)
    }

    // handleCardId: stores id of currently open opportunity card
    const handleCardId = (cardId) => {
        setCardId(cardId)
        handleCardOpen()
    }

    /* handleSupplierChange :
    * Adds or removes a string to the supplier array.
    * Also refreshes page of filterable items
    * */
    const handleSupplierChange = (supplier: string, addTo: boolean, refreshSearch: boolean) => {
        if (addTo) {
            if (suppliers.includes(supplier) == false) {
                let tempSuppliers: string[] = suppliers
                tempSuppliers.push(supplier)
                setSuppliers(tempSuppliers)
            }
        } else {
            let tempSuppliers: string[] = []
            suppliers.map((value, index, array) => {
                if (value != supplier) {
                    tempSuppliers.push(value)
                }
            })
            setSuppliers(tempSuppliers)
        }
        if (refreshSearch) {
            setFilterableSearching(true)
            // refresh filterable items
            Promise.resolve().then(() => getFilterableFields())

        }
    }

    // fetchMap : fetches the column mapping for filter dictionary of current organisation
    async function fetchMap() {
        setColMap(null)
        // Fetch the map
        let colMapResponse = await FilterStore.FetchColumnMap({
            organisation: ctx.activeOrganization.name,
        })
        if (colMapResponse.ColumnMapDict) {
            setColMap(colMapResponse.ColumnMapDict)
        }
    }

    // TODO add psu
    // getFilterableFields : fetches all the filterable fields
    async function getFilterableFields() {
        setFilterableSearching(true)
        let response = await FilterStore.GetAll({
            client: ctx.activeOrganization.name,
            supplierList: suppliers,
            PSU: activePSU != null
                ? ConvertTreeFromBeingUsedToApiSave(activePSU)
                : ConvertTreeFromBeingUsedToApiSave(GenerateNewPsuTree(ctx.activeOrganization.name))
        })
        if (response.filterData) {
            if (response.filterData["assigned_to"] && response.filterData["assigned_to"].data) {
                let tempData = []
                let alreadyFound = false
                for (let i = 0; i < response.filterData["assigned_to"].data.length; i++) {
                    alreadyFound = false
                    for (let j = 0; j < tempData.length; j++) {
                        if (tempData[j] == response.filterData["assigned_to"].data[i]) {
                            alreadyFound = true
                        }
                    }
                    if (!alreadyFound) {
                        tempData.push(response.filterData["assigned_to"].data[i])
                    }
                }
                response.filterData["assigned_to"].data = tempData
            }
            setFilterableFields(response.filterData)
        }
        setFilterableSearching(false)
    }

    // function resetSuppliers() {
    //     setSuppliers([])
    // }

    // fetchSavedFilters : Fetches the saved filters, and concatenates it with the default filter
    async function fetchSavedFilters() {
        setFiltersReady(false)
        if (suppliers.length > 0) {
            setSuppliers([])
        }
        let savedFiltersResponse = await FilterStore.FindOne({
            userID: ctx.user.id,
            organizationID: ctx.activeOrganization.id
        })
        if (savedFiltersResponse != null) {
            if (savedFiltersResponse.filter != null) {
                const fetchedFilters = savedFiltersResponse.filter.filters.concat(defaultFilters)
                const filterValues = fetchedFilters.map(f => f.values)
                const uniqueFilters = fetchedFilters.filter(({values}, index) => !filterValues.includes(values, index + 1))
                setFilters(uniqueFilters)
                if (suppliers.length > 0) {
                    setSuppliers([])
                } else {
                    for (let i = 0; i < uniqueFilters.length; i++) {
                        if (uniqueFilters[i].header == colMap.Overall_Hierarchy_1) {
                            // console.log(uniqueFilters[i].header)
                            // console.log(uniqueFilters[i].values)
                            handleSupplierChange(uniqueFilters[i].values, true, false)
                        }
                    }
                }
            } else {
                setSuppliers([])
                setFilters(defaultFilters)
            }
        } else {
            setSuppliers([])
            setFilters(defaultFilters)
        }
        setFiltersReady(true)
    }

    async function updateSavedFilter() {
        const filteredFilter = filters.filter((f) => !f.level)
        const filterValues = filteredFilter.map(f => f.values)
        const uniqueFilteredFilter = filteredFilter.filter(({values}, index) => !filterValues.includes(values, index + 1))


        const filter = new FilterObject(
            {userID: ctx.user.id, organizationID: ctx.activeOrganization.id, filters: uniqueFilteredFilter}
        )
        let response = await FilterStore.UpsertOne({
            filter: filter,
        })
    }

    async function saveEmptyFilter() {
        const emptyFilter = new FilterObject(
            {userID: ctx.user.id, organizationID: ctx.activeOrganization.id, filters: []}
        )
        let response = await FilterStore.UpsertOne({
            filter: emptyFilter,
        })
    }

    const handleFilterOpen = () => {
        if (filterOpen == true) {
            handleFilterClose()
        } else {
            setFilterOpen(!filterOpen)
        }
    };

    // handleExcludeFilterOpen : Toggles the excludeFilterDrawer open or closed
    const handleExcludeFilterOpen = () => {
        if (filterOpen == true) {
            handleExcludeFilterClose()
        } else {
            setExcludeFilterOpen(!excludeFilterOpen)
        }
    };

    // handleExcludeFilterClose : Closes the excludeFilter drawer, with no other actions
    const handleExcludeFilterClose = () => {
        setExcludeFilterOpen(false)
        // updateSavedFilter().then((() => {
        //     setFlipSwitchForCards(!flipSwitchForCards)
        //     setFilterOpen(false)
        // }))
    }

    // todo, maybe just delete, doesn't seem necessary
    // handleExcludeFilterClear :
    const handleExcludeFilterClear = () => {
        // setFilters(defaultFilters)
        // saveEmptyFilter().then((() => {
        //     getFilterableFields
        //     // keystoneCtx.resetSuppliers
        //     fetchSavedFilters
        //     setFlipSwitchForCards(!flipSwitchForCards)
        //     setFilterOpen(false)
        // })).then(() => {
        //     setSuppliers([])
        // })
    }

    const handleFilterClear = () => {
        setFilters(defaultFilters)
        saveEmptyFilter().then((() => {
            getFilterableFields
            // keystoneCtx.resetSuppliers
            fetchSavedFilters
            setFlipSwitchForCards(!flipSwitchForCards)
            setFilterOpen(false)
        })).then(() => {
            setSuppliers([])
        })
    }

    // todo : this one has to change in order for the selective reload thingies to work
    const handleFilterClose = () => {
        setFilterOpen(false)
        if (filterHasChanged == true) {
            setFilterHasChanged(false)
            updateSavedFilter().then((() => {
                setFlipSwitchForCards(!flipSwitchForCards)
                setFilterOpen(false)
            }))
        }
    }

    // handleToggleStatsDrawer : Toggles the stats drawer open and closed
    const handleToggleStatsDrawer = () => {
        setStatsDrawerOpen(!statsDrawerOpen)
    }

    // fetchStatData: Fetches data for the stats
    const fetchStatData = async () => {
        setStatsLoading(true)
        const response = await KanbanStore.Stats({clientName: ctx.activeOrganization.name})

        if (response) {
            setStatData(response)
        }
        setStatsLoading(false)
    }

    const fetchOppLabels = async() => {

        setLabelsLoading(true)

        const labelsResponse = await OppLabelStore.FindAll({ClientName: ctx.activeOrganization.name})

        if(labelsResponse && labelsResponse.Labels){
            setOppLabels(labelsResponse.Labels.filter((label) => label.action == 'todo'))
            setCompletedOptions(labelsResponse.Labels.filter((label) => label.action == 'completed'))
        }
        setLabelsLoading(false)
    }

    // refreshes data when current org or user changes
    useEffect(() => {
        // sets all the loading states to be loading, and empties fields that need emptying from previous org
        setFiltersReady(false)
        setFilterableSearching(true)
        setDefaultFiltersLoading(true)
        setColMap(null)
        setFilterableFields({})
        setSuppliers([])
        if (ctx.user && ctx.user.id && ctx.activeOrganization && ctx.activeOrganization.id) {
            // map runs first, so that when default filters are changed, the map is already set
            // Promise.resolve().then()
            fetchMap().then(() => getDefaultFilters())
            fetchMap().then(() => getStatusCard()) //TODO: See why this isnt nested in one .then
        }
    }, [ctx.user, ctx.activeOrganization])

    // refreshes filterable fields when the colMap is updated
    useEffect(() => {
        if (colMap != null) {
            Promise.resolve().then(() => getFilterableFields())
        }
    }, [colMap])

    // Goal of this one is to refill the suppliers list whenever it is emptied. Mainly for clear filter button
    useEffect(() => {
        if (suppliers.length == 0 && filtersReady) {
            for (let i = 0; i < filters.length; i++) {
                if (filters[i].header == colMap.Overall_Hierarchy_1) {
                    handleSupplierChange(filters[i].values, true, false)
                }
            }
        }
    }, [suppliers])

    // refreshes data when current org or user changes
    useEffect(() => {
        setFiltersReady(false)
        if (ctx.user && ctx.user.id && ctx.activeOrganization && ctx.activeOrganization.id) {
            Promise.resolve().then(() => fetchSavedFilters())

        }
    }, [defaultFilters])

    // Clears Toolbar display options when page url changes
    useEffect(() => {
        setToolBarDisplayOptions({
            filter: false,
            stats: false,
            selectOpps: false,
            filterToMe: false,
            landingDateSelector: false
        })
        if (selectCards == true) {
            handleSelectCards()
        }
    }, [(window.location.pathname)])

    const toggleGoalOpen = () => {
        setCreateGoalOpen((prevState) => {
            return !prevState
        })
    }

    // FetchExclude : Fetches exclude filters
    const FetchExclude = async (): Promise<any> => {
        if (ctx.activeOrganization != null) {
            try {
                return await ExcludeStore.FindOne({
                    orgName: ctx.activeOrganization.name,
                });
            } catch (e) {
                console.error(e);
            }
        }
    };


    // getAll: Gets all cards given an org name and filter for filtering
    const getAll = async (): Promise<any> => {
        try {
            if (filters != null) {
                return await KanbanStore.FindAll({
                    clientName: ctx.activeOrganization.name,
                    filter: filters.concat(clickThroughFilters),
                    PSU: ConvertTreeFromBeingUsedToApiSave(activePSU)
                }).then((r) => {
                    setClickThroughFilters([])
                    return r;
                });
            } else {
                return await KanbanStore.FindAll({
                    clientName: ctx.activeOrganization.name,
                    filter: [].concat(clickThroughFilters),
                    PSU: ConvertTreeFromBeingUsedToApiSave(activePSU)
                }).then((r) => {
                    setClickThroughFilters([])
                    return r;
                });
            }
        } catch (e) {
            console.error(`${e}`);
        }
    };

    // Checks if the filter is ready to use and then fetches cards with filter
    useEffect(() => {
        if (filtersReady == true && activePsuReady == true) {
            setFlipSwitchForCards(!flipSwitchForCards);
            setFlipSwitchForLabels(!flipSwitchForLabels);

            // fetchStatData()
        }
    }, [filtersReady, activePsuReady]);

    // MakeRows: Creates an array of cards with the V0 and levelFilter data extracted and added to the rows.
    // This is for the DataGrid, also excludes opps based on exclude filter
    const MakeRows = (cards: Record<string, Card>, excl: any) => {
        let empRows = [];
        // Loop through cards
        values(Object.values(cards)).map((value: Card, index: number, array: Card[]) => {
            // if excl ilters not empty
            if (excl.length > 0) {
                // make a lil boolean array to check if the opp matches any of the filters, can be optimised
                let isMatchArr: boolean[] = new Array(excl.length)
                // loop through exclude filters
                for (let i = 0; i < excl.length; i++) {
                    isMatchArr[i] = true
                    Object.keys(excl[i]).map((key) =>
                        excl[i][key].includes(JSON.parse(value.levelFilter)[key]) ? null : isMatchArr[i] = false
                    )
                }
                if (isMatchArr.includes(true)) {
                    // to be excluded
                } else {
                    empRows.push({
                        id: value.id,
                        ...value,
                        ...JSON.parse(value.levelFilter),
                        ...JSON.parse(value.v0),
                    })
                }
            } else {
                empRows.push({
                    id: value.id,
                    ...value,
                    ...JSON.parse(value.levelFilter),
                    ...JSON.parse(value.v0),
                })
            }
            }
        )

        setMyRows(null); // done to reload data
        // setMyRows(tempRows) // no sorting
        setMyRows(StableSort(empRows.flat(1), GetComparator("desc", "mValue"))); // sorted by mValue
    };

    // When a filter exists, use it to fetch from api
    useEffect(() => {
        setLoadingBar(true);
        if (filtersReady == true && activePsuReady == true) {
            getAll().then((r) => {
                setAllCards(null);
                setAllCards({...r.allCards});
                setKanbanColumns(null);
                setKanbanColumns(r.columns);
                if (selectCards == true) {
                    handleSelectCards()
                }
                FetchExclude().then((ex) => {
                    let tempParsedExcl = null
                    if (ex.exclude.filter_dict != "") {
                        tempParsedExcl = JSON.parse(ex.exclude.filter_dict)
                    }

                    if (tempParsedExcl != null) {
                        setExcluded(JSON.parse(ex.exclude.filter_dict));
                        MakeRows({...r.allCards}, tempParsedExcl);
                    } else {
                        setExcluded([])
                        MakeRows({...r.allCards}, []);
                    }

                    setLoadingBar(false);
                });
            });
        }
    }, [flipSwitchForCards]);

    // This one is for labels me thinks
    useEffect(() => {
        setLoadingBar(true);
        if (filtersReady == true) {
            fetchOppLabels().then(() => {
                setLoadingBar(false);
                // console.log(
                //     "Container flipswitch for Labels ",
                //     Object.values(oppLabels)
                // );
            });
        }
    }, [flipSwitchForLabels]);

    // todo Unsure about this one, the comment mentions filters but it does label things?
    // Make filters happen on page load
    // useLayoutEffect(() => {
    //     // doMembers().then(r => setMembers(r))
    //     fetchLabels().then((r) => setAllLabels(r));
    // }, []);

    // Clears the cards and then flips the switch. mainly used for the click through filters
    const RefreshCards = () => {
        setAllCards(null);
        setKanbanColumns(null);
        // setLoadingBar(true);
        setFlipSwitchForCards(!flipSwitchForCards)
    }


    return (
        <Context.Provider
            value={{
                cardOpen: cardOpen,
                cardId: cardId,
                handleCardId: (cardId) => handleCardId(cardId),
                handleCardOpen: handleCardOpen,
                handleCardClose: handleCardClose,

                levelMode: levelMode,
                filters: filters,
                setFilters: setFilters,
                clickThroughFilters: clickThroughFilters,
                setClickThroughFilters: setClickThroughFilters,
                defaultFilters: defaultFilters,
                defaultFiltersLoading: defaultFiltersLoading,
                setDefaultFilters: setDefaultFilters,
                statusCardOptions: statusCardOptions,
                setStatusCardOptions: setStatusCardOptions,
                setLevelMode: setLevelMode,
                appliedFiltersCount: appliedFiltersCount,
                setAppliedFiltersCount: setAppliedFiltersCount,

                kanbanColumns: kanbanColumns,
                setKanbanColumns: setKanbanColumns,

                suppliers: suppliers,
                setSuppliers: setSuppliers,
                handleSupplierChange: handleSupplierChange,

                colMap: colMap,
                setColMap: setColMap,

                filterableFields: filterableFields,
                setFilterableFields: setFilterableFields,

                getFilterableFields: getFilterableFields,
                fetchMap: fetchMap,
                fetchSavedFilters: fetchSavedFilters,
                updateSavedFilter: updateSavedFilter,
                saveEmptyFilter: saveEmptyFilter,
                // resetSuppliers: resetSuppliers,

                filtersReady: filtersReady,
                setFiltersReady: setFiltersReady,
                filterableSearching: filterableSearching,
                setFilterableSearching: setFilterableSearching,
                filterOpen: filterOpen,
                excludeFilterOpen: excludeFilterOpen,
                setFilterOpen: setFilterOpen,
                setExcludeFilterOpen: setExcludeFilterOpen,
                handleFilterOpen: handleFilterOpen,
                handleExcludeFilterOpen: handleExcludeFilterOpen,
                handleFilterClear: handleFilterClear,
                handleExcludeFilterClear: handleExcludeFilterClear,
                handleFilterClose: handleFilterClose,
                handleExcludeFilterClose: handleExcludeFilterClose,
                statsDrawerOpen: statsDrawerOpen,
                setStatsDrawerOpen: setStatsDrawerOpen,
                handleToggleStatsDrawer: handleToggleStatsDrawer,
                statsLoading: statsLoading,
                setStatsLoading: setStatsLoading,
                fetchStatData: fetchStatData,
                statData: statData,
                setStatData: setStatData,

                selectCards: selectCards,
                selectedCardsID: selectedCardsID,
                setSelectedCardsID: setSelectedCardsID,
                setSelectCards: setSelectCards,
                selectedCardsNames: selectedCardsNames,
                setSelectedCardsNames: setSelectedCardsNames,
                updateSelectedCards: updateSelectedCards,
                handleSelectCards: handleSelectCards,

                flipSwitchForCards: flipSwitchForCards,
                setFlipSwitchForCards: setFlipSwitchForCards,
                flipSwitchForGoals: flipSwitchForGoals,
                setFlipSwitchForGoals: setFlipSwitchForGoals,
                filterHasChanged: filterHasChanged,
                setFilterHasChanged : setFilterHasChanged,

                loadingBar: loadingBar,
                setLoadingBar: setLoadingBar,

                toolBarDisplayOptions: toolBarDisplayOptions,
                setToolBarDisplayOptions: setToolBarDisplayOptions,

                dateValue: dateValue,
                setDateValue: setDateValue,

                createGoalOpen: createGoalOpen,
                setCreateGoalOpen: setCreateGoalOpen,
                toggleGoalOpen: toggleGoalOpen,

                opportunityLabels : oppLabels,
                fetchOppLabels : fetchOppLabels,
                flipSwitchForLabels : flipSwitchForLabels,
                setFlipSwitchForLabels: setFlipSwitchForLabels,
                completedOptions : completedOptions,

                allCards : allCards,
                setAllCards : setAllCards,
                myRows : myRows,
                setMyRows : setMyRows,
                excluded : excluded,
                setExcluded : setExcluded,
                RefreshCards : RefreshCards,

                psuTrees : psuTrees,
                setPsuTrees : setPsuTrees,
                activePSU: activePSU,
                setActivePSU: setActivePSU,
                activePsuReady: activePsuReady,
                setActivePsuReady: setActivePsuReady,
                psuTreesUpdatedSwitch : psuTreesUpdatedSwitch,
                setPsuTreesUpdatedSwitch : setPsuTreesUpdatedSwitch,
                FetchPSUTrees : FetchPSUTrees

            }}
        >
            {children}
        </Context.Provider>
    );
};

const useKeystoneContext = () => useContext(Context);
export {
    useKeystoneContext,
};
export default KeystoneContext