import type {FC} from 'react';
import React, {useState} from 'react';
import {Alert, Box, Card, CardContent, CardHeader, Divider, Typography, useTheme} from '@mui/material';
// import {useCustomThemeContext} from "../util/Context";

// import {createCogTheme} from "../../../../../theme";

interface SingleValueIndicatorProps {
    title: string
    value: any[],
    symbol?: any
}

const SingleValueIndicator: FC<SingleValueIndicatorProps> = (props) => {
    const [symbol, setSymbol] = useState<string>((props.symbol) ? props.symbol: "")
    const color = '#FFF'
    return (
        <Card
            sx={{
                backgroundColor: useTheme().palette.background.paper,
                width: '100%',
                height: '100%',
                // marginX: {sm:'10%', md: '20%',}
                // height: "80%"
            }}
        >
            <CardHeader title={props.title} sx={{textAlign: 'left'}}/>
            <Divider/>
            <CardContent sx={{flexGrow: 1}}>
                <Box
                    sx={{
                        alignItems: 'center',
                        alignContent: 'center',
                        textAlign: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                    }}
                >{/*TODO: DISPLAY CURRENCY OR SYMBOL?*/}
                    <Typography
                        color="textPrimary"
                        //sx={{p: 2, alignContent: 'center', textAlign: 'center', alignItems: 'center', marginLeft:'20px', display: 'inline'}}
                        variant="h1"
                    >
                        
                        {symbol}
                        {(props.value[0] == null) ? <Alert severity="warning">No ratio</Alert> : " " + props.value[0].toFixed(2)}
                    
                    </Typography>
                
                </Box>
            </CardContent>
        </Card>
    )
}

export default SingleValueIndicator;