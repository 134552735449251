import type {FC} from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import { Box, styled, Typography } from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {ApexOptions} from "apexcharts";
import currencySwitcher from "../../../../../../utils/currencySwitcher";
import { useMainContext } from "../../../../../../contexts/MainContext";
import { useKeystoneContext } from "../../../../../../contexts/KeystoneContext";
import SettingsContext from "../../../../../../contexts/SettingsContext";
import { useNavigate } from "react-router-dom";
import { FilterItem } from "../../../../../../cog/keystone/filter/Filter";
import { Filter as FilterObject, FilterStore } from "../../../../../../cog/keystone/filter";
import { useContext } from "react";

const StyledBox = styled('div')(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    minHeight: 500,
    minWidth: 500
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 400,
    minWidth: 300
  }
}));

interface LandingHeatProps {
  data: {};
  xAxisColumnName: string;
  yAxisColumnName: string;
  valueColumnName: string;
  org: string;
}

const LandingHeat: FC<LandingHeatProps> = ({data, org, xAxisColumnName, yAxisColumnName, valueColumnName}) => {
  const chartType = "heatmap"
  const mainCtx = useMainContext()
  const keystoneCtx = useKeystoneContext()
  const settingsCtx = useContext(SettingsContext)
  const navigate = useNavigate()

  const removeNullString = (array) => {
    return array.map((value) => {
      if (value === null) {
        return "";
      }
      return value;
    });
  }

  const updateFilter = async () => {
    keystoneCtx.RefreshCards()
    const org = mainCtx.activeOrganization.name.replace(/ |_/g,'-');
    navigate("/app/" + org.toLowerCase() + "/opportunities")
  }

  let xAxis = data && data[xAxisColumnName] ? [...new Set(data[xAxisColumnName])] : []
  let yAxis = data && data[yAxisColumnName] ? [...new Set(data[yAxisColumnName])] : []
  let values = data && data[valueColumnName] ? data[valueColumnName] : []

  const formattedXAxis = xAxis ? removeNullString(xAxis) : []

  const getAllIndexes = (arr, val) => {
    const indexes = [];
    let i = -1;

    while ((i = arr.indexOf(val, i+1)) != -1){
      indexes.push(i);
    }

    return indexes;
  }

  let series: any[] = yAxis.map((d) => {
    return {
      name: d,
      data: formattedXAxis ? formattedXAxis.map((r) => {
        const yAxisIDs = getAllIndexes(data[yAxisColumnName], d)
        const xAxisIDs = getAllIndexes(data[xAxisColumnName], r)
        const filteredArray = xAxisIDs.filter(value => yAxisIDs.includes(value));
        return values[filteredArray[0]] ? values[filteredArray[0]] : 0
      }) : []
    }
  })

  const chartOptions: ApexOptions = {
    chart: {
      height: 450,
      type: 'heatmap',
      background: useTheme().palette.background.paper,
      foreColor: useTheme().palette.text.primary,
      events: {
        dataPointSelection(event, chartContext, config) {
          const OppTypeFilter: FilterItem = {
            header: "opportunity_type",
            operation: "=",
            prefix: "AND",
            values: "Distribution"
          }

          const xAxisFilter: FilterItem = {
            header: xAxisColumnName,
            operation: "=",
            prefix: "AND",
            values: xAxis[config.dataPointIndex]
          }

          const yAxisFilter: FilterItem = {
            header: yAxisColumnName,
            operation: "=",
            prefix: "AND",
            values: yAxis[config.seriesIndex]
          }

          const filters: FilterItem[] = []
          filters.push(OppTypeFilter, xAxisFilter, yAxisFilter)

          const filteredFilter = filters.filter((f) => !f.level)
          const filteredWithDefault = filteredFilter.concat(keystoneCtx.filters)

          keystoneCtx.setClickThroughFilters(filteredWithDefault)
          // keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
          updateFilter()
        }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        colors: ['#0e2940']
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: true,
        distributed: false,
        shadeIntensity: 1,
        colorScale: {
          inverse: true
        }
      },
    },
    colors: useTheme().palette.mode === 'dark' ? ["#1aa6c3", "#a5bc3f", "#ffbc53", "#eb8446", "#c8305a", "#9577f5"] : ["#3aa9a6", "#4b4b4b", "#f2bc53", "#f45c5c", "#e1d5c6", "#8c9494"],
    xaxis: {
      type: 'category',
      categories: formattedXAxis,
      labels: {
        rotateAlways: true,
        rotate: -60,
        trim: true,
      },
      title: {
        text: xAxisColumnName,
      },
    },
    yaxis: {
      title: {
        text: yAxisColumnName,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%"
        },
      },
      theme: settingsCtx.settings.theme === 'LIGHT' ? 'light' : 'dark'
    },
    grid: {
      padding: {
        right: 20,
        bottom: 10
      }
    }
  };

  if (Object.keys(data).length > 0) {
    return (
      <StyledBox>
        <Chart
          type={chartType}
          series={series}
          options={chartOptions}
          height={"100%"}
          width={"100%"}
        />
      </StyledBox>
    );
  } else {
    return (
      <StyledBox>
        <Typography>
          No Data To Display
        </Typography>
      </StyledBox>
    );
  }

};

export default LandingHeat;