import {
    OpportunityCriteria,
    OpportunityOption,
    CustomOpportunityType,
    OpportunityType,
    ListedOpportunityType
} from "./Opportunity";
import {API, PerformJSONRPCRequest} from "../../api/jsonrpc";

export interface FindAllOppTypesRequest {
    clientName: string;
}

export interface FindAllOppTypesResponse {
    opportunityTypes: OpportunityType[]
}

export interface FindAllListedOppTypesRequest {
    clientName: string;
}

export interface FindAllListedOppTypesResponse {
    listedOpportunityTypes: ListedOpportunityType[]
}

export interface FindAllOppOptionsRequest {
    clientName: string;
    opportunityTypeID: string;
}

export interface FindAllOppOptionsResponse {
    opportunityOptions: OpportunityOption[]
}

export interface FindAllCustomOpportunityTypesRequest {
    clientName: string;
    opportunityTypeID: string;
}

export interface FindAllCustomOpportunityTypesResponse {
    customOpportunityTypes: CustomOpportunityType[]
}

export interface FindAllOpCriteriaRequest {
    clientName: string;
    customOpportunityTypeID: string;
}

export interface FindAllOppCriteriaResponse {
    opportunityCriteria: OpportunityCriteria[]
}

export interface CreateOneCustomOpportunityTypeRequest {
    clientName: string;
    opportunityTypeID: string;
    PSUIDs: string[];
    name: string;
    description: string;
}

export interface CreateOneCustomOpportunityTypeResponse {
    ID: string;
}

export interface CreateOneCustomOpportunityTypePSURequest {
    clientName: string;
    customOpportunityTypeID: string;
    PSUIDs: string[];
}

export interface CreateOneCustomOpportunityTypePSUResponse {
    success: boolean;
}

export interface CreateOneOppCriteriaRequest {
    clientName: string;
    customOpportunityTypeID: string;
    opportunityOptionID: string;
    name: string;
    description: string;
    operator: string;
    value: string;
}

export interface CreateOneOppCriteriaResponse {
    ID: string;
}

export interface UpdateOneCustomOpportunityTypeRequest {
    clientName: string;
    customOpportunityType: CustomOpportunityType;
}

export interface UpdateOneCustomOpportunityTypeResponse {
    success: boolean;
}

export interface UpdateOneOppCriteriaRequest {
    clientName: string;
    opportunityCriteria: OpportunityCriteria
}

export interface UpdateOneOppCriteriaResponse {
    success: boolean;
}

export interface DeleteOneCustomOpportunityTypeRequest {
    clientName: string;
    customOpportunityTypeID: string;
}

export interface DeleteOneCustomOpportunityTypeResponse {
    success: boolean;
}

export interface DeleteOneOppCriteriaRequest {
    clientName: string;
    opportunityCriteriaID: string;
}

export interface DeleteOneOppCriteriaResponse {
    success: boolean;
}

export interface GenerateCustomOppsRequest {
    clientName: string;
    customOpportunityTypeID: string;
}

export interface GenerateCustomOppsResponse {
    success: boolean;
}

const Store = {
    serviceProvider: 'Opportunity-Store',
    async FindAllOppTypes(request: FindAllOppTypesRequest): Promise<FindAllOppTypesResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAllOppTypes`,
            request: [{
                "clientName": request.clientName
            }]
        });
        return {
            opportunityTypes: response.opportunityTypes,
        };
    },
    async FindAllListedOppTypes(request: FindAllListedOppTypesRequest): Promise<FindAllListedOppTypesResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAllListedOppTypes`,
            request: [{
                "clientName": request.clientName
            }]
        });
        return {
            listedOpportunityTypes: response.listedOpportunityTypes,
        };
    },
    async FindAllOppOptions(request: FindAllOppOptionsRequest): Promise<FindAllOppOptionsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAllOppOptions`,
            request: [{
                "clientName": request.clientName,
                "opportunityTypeID": request.opportunityTypeID
            }]
        });
        return {
            opportunityOptions: response.opportunityOptions,
        };
    },
    async FindAllCustomOpportunityTypes(request: FindAllCustomOpportunityTypesRequest): Promise<FindAllCustomOpportunityTypesResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAllCustomOpportunityTypes`,
            request: [{
                "clientName": request.clientName,
                "opportunityTypeID": request.opportunityTypeID
            }]
        });
        return {
            customOpportunityTypes: response.customOpportunityTypes,
        };
    },
    async FindAllOppCriteria(request: FindAllOpCriteriaRequest): Promise<FindAllOppCriteriaResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAllOppCriteria`,
            request: [{
                "clientName": request.clientName,
                "customOpportunityTypeID": request.customOpportunityTypeID
            }]
        });
        return {
            opportunityCriteria: response.opportunityCriteria,
        };
    },
    async CreateOneCustomOpportunityType(request: CreateOneCustomOpportunityTypeRequest): Promise<CreateOneCustomOpportunityTypeResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOneCustomOpportunityType`,
            request: [{
                "clientName": request.clientName,
                "opportunityTypeID": request.opportunityTypeID,
                "name": request.name,
                "description": request.description,
                "PSUIDs": request.PSUIDs
            }]
        });
        return {
            ID: response,
        };
    },
    async CreateOneCustomOpportunityTypePSU(request: CreateOneCustomOpportunityTypePSURequest): Promise<CreateOneCustomOpportunityTypePSUResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOneCustomOpportunityTypePSU`,
            request: [{
                "clientName": request.clientName,
                "customOpportunityTypeID": request.customOpportunityTypeID,
                "PSUIDs": request.PSUIDs,
            }]
        });
        return {
            success: response,
        };
    },
    async CreateOneOppCriteria(request: CreateOneOppCriteriaRequest): Promise<CreateOneOppCriteriaResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOneOppCriteria`,
            request: [{
                "clientName": request.clientName,
                "customOpportunityTypeID": request.customOpportunityTypeID,
                "opportunityOptionID": request.opportunityOptionID,
                "name": request.name,
                "description": request.description,
                "operator": request.operator,
                "value": request.value,
            }]
        });
        return {
            ID: response,
        };
    },
    async UpdateOneCustomOpportunityType(request: UpdateOneCustomOpportunityTypeRequest): Promise<UpdateOneCustomOpportunityTypeResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateOneCustomOpportunityType`,
            request: [{
                "clientName": request.clientName,
                "customOpportunityType": request.customOpportunityType,
            }]
        });
        return {
            success: response,
        };
    },
    async UpdateOneOppCriteria(request: UpdateOneOppCriteriaRequest): Promise<UpdateOneOppCriteriaResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateOneOppCriteria`,
            request: [{
                "clientName": request.clientName,
                "opportunityCriteria": request.opportunityCriteria,
            }]
        });
        return {
            success: response,
        };
    },
    async DeleteOneCustomOpportunityType(request: DeleteOneCustomOpportunityTypeRequest): Promise<DeleteOneCustomOpportunityTypeResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOneCustomOpportunityType`,
            request: [{
                "clientName": request.clientName,
                "customOpportunityTypeID": request.customOpportunityTypeID,
            }]
        });
        return {
            success: response,
        };
    },
    async DeleteOneOppCriteria(request: DeleteOneOppCriteriaRequest): Promise<DeleteOneOppCriteriaResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOneOppCriteria`,
            request: [{
                "clientName": request.clientName,
                "opportunityCriteriaID": request.opportunityCriteriaID,
            }]
        });
        return {
            success: response,
        };
    },
    async GenerateCustomOpps(request: GenerateCustomOppsRequest): Promise<GenerateCustomOppsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.GenerateCustomOpps`,
            request: [{
                "clientName": request.clientName,
                "customOpportunityTypeID": request.customOpportunityTypeID,
            }]
        });
        return {
            success: response,
        };
    },
}

export default Store