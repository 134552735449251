import {useTheme} from "@mui/material/styles";
import {useSnackbar} from "notistack";
import {Dialog, Drawer} from "@mui/material";
import {Box} from "@mui/system";
import Filter from "../filter/Filter";
import React from "react";
import ExcludeFilter from "./ExcludeFilter";
import {makeStyles} from "@mui/styles";
import ConfirmActionModal from "./ConfirmActionModal";

interface ExcludeFilterDrawerProps {

    open: boolean
    setOpen: (o: boolean) => void
    onClose: () => void
    onClear: () => void
}

const useStyles = makeStyles({
    drawerPaper: {
        marginTop: "7vh"
    }
});

let dialogBody = () => {
    return (
        <div>
        </div>
    )
};

export default function ExcludeFilterDrawer(props: ExcludeFilterDrawerProps) {
    const tempTheme = useTheme()
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const [confirmActionOpen, setConfirmActionOpen] = React.useState(false);
    const autoHideSnackDuration: number = 2500 // time it takes to hide snackbars

    const handleClickAway = () => {
        setConfirmActionOpen(false)
        props.onClose()
        enqueueSnackbar('Changes Discarded', {
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
            },
            variant: 'success',
            preventDuplicate: true,
            autoHideDuration: 2500
        });
    };



    // handleConfirmDialogue : for excluding multiple issues confirmation
    const handleConfirmDialogue = () => {
        setConfirmActionOpen(true)
        dialogBody = () => {
            return (
                <ConfirmActionModal
                    cancelAction={handleConfirmClose}
                    confirmAction={handleClickAway}
                    confirmationWarningMessage={"Warning, Closing will discard changes, continue?"}

                />
            );
        }
        // setExcDialogOpen(true);
    }

    // handleConfirmClose : for closing confirmation modal
    const handleConfirmClose = () => {
        setConfirmActionOpen(false);
        enqueueSnackbar('Action Cancelled', {
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'top'
            },
            variant: 'warning',
            autoHideDuration: autoHideSnackDuration,
            preventDuplicate: true
        });
    };

    return (
        <Drawer
            //disableEnforceFocus required to open chat if Dialog opened first
            disableEnforceFocus
            anchor="right"
            open={props.open}
            onClose={handleConfirmDialogue}
            classes={{paper: classes.drawerPaper}}>
            <Box
                //className required for CogSpeedDial positioning
                className={"PopoutSideBar"}
                sx={{
                backgroundColor: "background.default",
                border: "1px solid",
                zIndex: 1000,
                height: "100%",
                padding: 2,
                minWidth: "30vw"
            }}>
                <ExcludeFilter
                />
            </Box>
            <Dialog
                //disableEnforceFocus required to open chat if Dialog opened first
                disableEnforceFocus
                open={confirmActionOpen}
                onClose={handleConfirmClose}
                aria-labelledby="card-exclude-dialog"
                fullWidth
                maxWidth="md"
            >
                {dialogBody()}
            </Dialog>
        </Drawer>

    )
}