const PriceLastSold = (props)=>{
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="PriceLastSoldIcon" data-name="Business Icons" version="1.1" viewBox="0 0 203 203" height={height}>
            <defs id="defs52">
                <linearGradient id="priceLastSoldBlob" x1="60.1" x2="160.3" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop id="stop4" offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="priceLastSoldLine" x1="23.7" x2="27.9" y1="170.1" y2="165.9" gradientUnits="userSpaceOnUse">
                    <stop id="stop9" offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-2" x1="86.3" x2="90.5" y1="5.1" y2=".9" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-3" x1="197.9" x2="202.1" y1="138.6" y2="134.4" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-4" x1=".6" x2="2.5" y1="79.6" y2="77.7" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-5" x1="2.7" x2="4.7" y1="77.5" y2="75.6" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-6" x1="4.9" x2="6.8" y1="79.6" y2="77.7" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-7" x1="2.7" x2="4.7" y1="81.7" y2="79.8" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-8" x1="175.6" x2="177.5" y1="28.5" y2="26.6" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-9" x1="177.8" x2="179.7" y1="26.4" y2="24.4" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-10" x1={180} x2="181.9" y1="28.5" y2="26.6" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-11" x1="177.8" x2="179.7" y1="30.6" y2="28.7" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-12" x1="121.6" x2="123.6" y1="200.4" y2="198.4" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-13" x1="123.8" x2="125.7" y1="198.2" y2="196.3" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-14" x1={126} x2="127.9" y1="200.3" y2="198.4" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-15" x1="123.8" x2="125.7" y1="202.5" y2="200.5" />
                <linearGradient id="priceLastSoldFill" x1="63.5" x2="77.1" y1="167.8" y2="154.2" gradientUnits="userSpaceOnUse">
                    <stop id="stop28" offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#priceLastSoldFill" id="priceLastSoldFill-2" x1="57.1" x2={102} y1="104.8" y2="59.9" gradientTransform="translate(-117.8 -3)" />
                <linearGradient xlinkHref="#priceLastSoldFill" id="priceLastSoldFill-3" x1="119.2" x2="151.7" y1="83.5" y2={51} />
                <linearGradient xlinkHref="#priceLastSoldFill" id="priceLastSoldFill-4" x1="42.6" x2="93.1" y1={114} y2="63.5" />
                <linearGradient xlinkHref="#priceLastSoldFill" id="priceLastSoldFill-5" x1={129} x2="162.4" y1="77.4" y2="43.9" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-16" x1="67.9" x2="142.9" y1="184.5" y2="109.5" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-17" x1="56.4" x2="102.7" y1="105.5" y2="59.2" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-18" x1="67.3" x2="92.2" y1="94.4" y2="69.6" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-19" x1="118.5" x2="152.4" y1="84.3" y2="50.3" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-20" x1="126.4" x2="144.8" y1="76.3" y2="57.9" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-21" x1="41.9" x2="93.8" y1="114.8" y2="62.9" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-22" x1="118.8" x2="162.7" y1="86.7" y2="42.8" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-23" x1="64.6" x2={95} y1="97.4" y2="67.1" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-24" x1={120} x2="121.4" y1="57.6" y2="56.2" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-25" x1="133.9" x2="135.3" y1={50} y2="48.6" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-26" x1="147.9" x2="149.3" y1="56.6" y2="55.2" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-27" x1="151.5" x2="152.9" y1="71.3" y2="69.9" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-28" x1="143.4" x2="144.8" y1="84.4" y2={83} />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-29" x1={128} x2="129.4" y1="84.4" y2={83} />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-30" x1="118.5" x2="119.9" y1="72.9" y2="71.4" />
                <linearGradient xlinkHref="#priceLastSoldFill" id="priceLastSoldFill-36" x1="91.1" x2={114} y1="72.3" y2="49.4" gradientUnits="userSpaceOnUse">
                </linearGradient>
                <linearGradient xlinkHref="#priceLastSoldFill" id="priceLastSoldFill-2-3" x1="76.8" x2="115.8" y1="86.2" y2="47.2" gradientTransform="matrix(.8 0 0 .8 -5 36.6)" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-18-5" x1="80.9" x2="122.8" y1="82.3" y2="40.3" gradientTransform="matrix(.8 0 0 .8 -5 36.6)" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-16-2" x1="76.1" x2="127.3" y1={87} y2="35.8" gradientTransform="matrix(.8 0 0 .8 -5 36.6)" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-17-7" x1="90.9" x2="114.3" y1="72.6" y2="49.2" gradientTransform="matrix(.8 0 0 .8 -5 36.6)" />
                <linearGradient xlinkHref="#priceLastSoldFill-36" id="priceLastSoldLinearGradient2945" x1="91.1" x2={114} y1="72.3" y2="49.4" gradientTransform="matrix(.8 0 0 .8 -5 36.6)" gradientUnits="userSpaceOnUse" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLine-92" x1=".9" x2="5.1" y1="117.9" y2="113.6" gradientUnits="userSpaceOnUse">
                </linearGradient>
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-2-3" x1="121.6" x2="125.8" y1="5.1" y2=".9" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-3-7" x1="197.9" x2="202.1" y1="174.8" y2="170.5" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-4-5" x1="29.5" x2="31.5" y1="29.5" y2="27.6" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-5-9" x1="31.7" x2="33.6" y1="27.4" y2="25.5" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-6-2" x1="33.9" x2="35.8" y1="29.5" y2="27.6" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-7-2" x1="31.7" x2="33.6" y1="31.6" y2="29.7" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-8-8" x1={180} x2="181.9" y1="64.4" y2="62.5" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-9-9" x1="182.2" x2="184.1" y1="62.3" y2="60.4" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-10-7" x1="184.4" x2="186.3" y1="64.4" y2="62.5" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-11-3" x1="182.2" x2="184.1" y1="66.5" y2="64.6" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-12-6" x1="74.8" x2="76.8" y1="200.4" y2="198.4" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-13-1" x1={77} x2="78.9" y1="198.2" y2="196.3" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-14-2" x1="79.2" x2="81.1" y1="200.3" y2="198.4" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-15-9" x1={77} x2="78.9" y1="202.5" y2="200.5" />
                <linearGradient xlinkHref="#priceLastSoldFill" id="priceLastSoldFill-31" x1="54.3" x2="149.9" y1="94.7" y2="94.7" gradientUnits="userSpaceOnUse">
                </linearGradient>
                <linearGradient xlinkHref="#priceLastSoldFill-31" id="priceLastSoldFill-2-7" x1="77.5" x2="168.6" y1="103.9" y2="103.9" />
                <linearGradient xlinkHref="#priceLastSoldFill-31" id="priceLastSoldFill-3-8" x1="34.5" x2="60.4" y1="78.5" y2="78.5" />
                <linearGradient xlinkHref="#priceLastSoldFill-31" id="priceLastSoldFill-4-4" x1="101.7" x2="124.7" y1={83} y2={83} />
                <linearGradient xlinkHref="#priceLastSoldFill-31" id="priceLastSoldFill-5-5" x1={83} x2="105.3" y1="91.8" y2="91.8" />
                <linearGradient xlinkHref="#priceLastSoldFill-31" id="priceLastSoldFill-6" x1="97.1" x2="107.2" y1="94.7" y2="94.7" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-16-0" x1="67.6" x2="136.6" y1="129.2" y2="60.3" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-17-3" x1="72.1" x2="132.1" y1="124.7" y2="64.8" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-18-6" x1="56.8" x2="156.3" y1="160.7" y2="61.2" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-19-1" x1={26} x2="76.1" y1="111.9" y2="61.8" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-20-0" x1="66.8" x2="72.7" y1="97.7" y2="91.8" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-21-6" x1="98.3" x2="128.7" y1="105.9" y2="75.5" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-22-3" x1="83.6" x2="85.8" y1="65.4" y2="63.2" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-23-2" x1="69.7" x2={74} y1="114.3" y2={110} />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-24-0" x1="118.4" x2="120.6" y1="126.3" y2="124.1" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-25-6" x1="99.2" x2={105} y1="65.3" y2="59.4" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-26-1" x1="70.8" x2={73} y1="78.4" y2="76.2" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-27-5" x1="82.5" x2="86.9" y1="127.5" y2={123} />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-28-5" x1="99.2" x2={105} y1="130.1" y2="124.2" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-29-4" x1="131.3" x2="133.4" y1="113.3" y2="111.1" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-30-7" x1="131.6" x2="137.5" y1="97.7" y2="91.8" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-31" x1="130.2" x2="134.5" y1="79.5" y2="75.2" />
                <linearGradient xlinkHref="#priceLastSoldLine-92" id="priceLastSoldLine-32" x1="117.3" x2="121.7" y1="66.5" y2={62} />
                <linearGradient xlinkHref="#priceLastSoldFill-31" id="priceLastSoldLinearGradient2321" x1="54.3" x2="149.9" y1="94.7" y2="94.7" gradientUnits="userSpaceOnUse" />
                <linearGradient xlinkHref="#priceLastSoldLine" id="priceLastSoldLinearGradient2464" x1="23.7" x2="27.9" y1="170.1" y2="165.9" gradientUnits="userSpaceOnUse" />
                <linearGradient xlinkHref="#priceLastSoldFill" id="priceLastSoldLinearGradient2466" x1="63.5" x2="77.1" y1="167.8" y2="154.2" gradientUnits="userSpaceOnUse" />
                <style id="style2" dangerouslySetInnerHTML={{__html: "\n            .iconWhite{fill:#fff}.priceLastSoldCls-18{fill:url(#priceLastSoldFill)}.priceLastSoldCls-21{fill:url(#priceLastSoldFill-4)}.priceLastSoldCls-22{fill:url(#priceLastSoldFill-5)}.priceLastSoldCls-23{fill:url(#priceLastSoldFill-6)}.priceLastSoldCls-24{fill:url(#priceLastSoldLine-16)}.priceLastSoldCls-28{fill:url(#priceLastSoldLine-20)}.priceLastSoldCls-29{fill:url(#priceLastSoldLine-21)}\n        " }} />
            </defs>
            <g id="Icon_40" transform="translate(0 2)">
                <path id="Contour" d="M54 132.1a2.9 2.9 0 0 0-3-1.4c-5.8 1-22.8 1.5-24.1-25-1.6-32 37.2-86.8 94.3-76 45.9 8.8 11.6 52.5 39.7 63.5 16.5 6.4 31.3 39.4-5.2 68.1-19.7 15.5-70.4 29.3-101.6-29.2Z" style={{fill: 'url(#priceLastSoldBlob)'}} />
                <path id="Contour-2" d="M25.8 171a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" style={{fill: 'url(#priceLastSoldLinearGradient2464)'}} />
                <path id="Contour-3" d="M88.4 6a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" style={{fill: 'url(#priceLastSoldLine-2)'}} />
                <path id="Contour-4" d="M200 139.5a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 0 0 0 2 1 1 0 1 0 0-2z" style={{fill: 'url(#priceLastSoldLine-3)'}} />
                <g id="Group">
                    <path id="Contour-5" d="M3 78.7a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1z" style={{fill: 'url(#priceLastSoldLine-4)'}} />
                    <path id="Contour-6" d="M3.7 78a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1z" style={{fill: 'url(#priceLastSoldLine-5)'}} />
                    <path id="Contour-7" d="M6.4 77.7h-1a1 1 0 0 0 0 2h1a1 1 0 1 0 0-2z" style={{fill: 'url(#priceLastSoldLine-6)'}} />
                    <path id="Contour-8" d="M3.7 79.3a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1z" style={{fill: 'url(#priceLastSoldLine-7)'}} />
                </g>
                <g id="Group-2">
                    <path id="Contour-9" d="M178.1 27.5a1 1 0 0 0-1-1H176a1 1 0 0 0 0 2h1a1 1 0 0 0 1.1-1z" style={{fill: 'url(#priceLastSoldLine-8)'}} />
                    <path id="Contour-10" d="M178.7 27a1 1 0 0 0 1-1v-1.2a1 1 0 0 0-1-.9 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1z" style={{fill: 'url(#priceLastSoldLine-9)'}} />
                    <path id="Contour-11" d="M181.4 26.5h-1a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1a1 1 0 0 0 0-2z" style={{fill: 'url(#priceLastSoldLine-10)'}} />
                    <path id="Contour-12" d="M178.7 28.1a1 1 0 0 0-1 1v1.1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1z" style={{fill: 'url(#priceLastSoldLine-11)'}} />
                </g>
                <g id="Group-3">
                    <path id="Contour-13" d="M124.2 199.4a1 1 0 0 0-1-1H122a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1z" style={{fill: 'url(#priceLastSoldLine-12)'}} />
                    <path id="Contour-14" d="M124.8 198.8a1 1 0 0 0 1-1v-1.1a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1z" style={{fill: 'url(#priceLastSoldLine-13)'}} />
                    <path id="Contour-15" d="M127.5 198.4h-1.1a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1a1 1 0 1 0 0-2z" style={{fill: 'url(#priceLastSoldLine-14)'}} />
                    <path id="Contour-16" d="M124.8 200a1 1 0 0 0-1 1v1a1 1 0 0 0 2 0v-1a1 1 0 0 0-1-1z" style={{fill: 'url(#priceLastSoldLine-15)'}} />
                </g>
                <path id="Contour-17" d="m51.9 154 19.4-6.4 2.6 1.8 8-4.1a37 37 0 0 1 24.9-15.6s15.6-3 20.4-5.6c0 0 4.6-.7 5.9 1.7 1.2 2.4-3.6 6-3.6 6l-5.3 2.7-7.8 5.3 15.7 4.4 15.9-12.5 4.6-2.7s3.3-.2 3.3 2.8l5-2.3s2.9 1.6 2 3.5c-1 1.8-14.9 17.3-14.9 17.3l-7.2 7.5-49 2.2-9.3 5.1.6 2.3-16.5 13s-7-3.2-11.3-20.5z" className="iconWhite" />
                <path id="Contour-18" d="M72.9 150s1.9 8.5-3.7 11.4c-5.6 3-14.5-3.6-14.5-3.6a13.5 13.5 0 0 0 7 6.7c5 2 16.3 5.8 21.7 2.3z" className="priceLastSoldCls-18" style={{fill: 'url(#priceLastSoldLinearGradient2466)'}} />
                <path id="Contour-22" d="M113.1 105.7a41.4 41.4 0 1 1-52-60.4.4.4 0 0 1 .5.2l2 4.6a.3.3 0 0 0 .6 0L69.3 40a.3.3 0 0 0-.1-.4l-10.4-5.1a.3.3 0 0 0-.4.4l1 4.4a.3.3 0 0 1-.3.4 45.2 45.2 0 1 0 53.3 67.2z" className="priceLastSoldCls-21" style={{fill: 'url(#priceLastSoldFill-4)'}} />
                <path id="Contour-23" d="M108.7 46.3a33 33 0 1 1 34.6 53 .3.3 0 0 1-.4-.2l-1-3.9a.3.3 0 0 0-.4 0l-5.1 7.4a.3.3 0 0 0 0 .4l7.6 5.1a.3.3 0 0 0 .4-.2l-.3-3.6a.3.3 0 0 1 .2-.3 36 36 0 1 0-34.8-58.6z" className="iconWhite" />
                <path id="Contour-24" d="M152.6 100.5c6-4.7 25-22.2 11.2-46 0 0-12.6-30.6-46.5-15.4 0 0 21.7-16.4 44 3.7 0 0 20.7 19.8 6.7 43.4 0 0-5.7 10.4-15.2 14.8a.3.3 0 0 1-.2-.5z" className="priceLastSoldCls-22" style={{fill: 'url(#priceLastSoldFill-5)'}} />
                <g id="Group-4" transform="translate(1 .4)">
                    <path id="Contour-25" d="M156.4 130a3.2 3.2 0 0 0-.7-1c-1.3-1.3-3.7-2-7.7 1.4l-16.8 12.4c-4-1.2-8.9-2.6-11.8-3.3l5.5-3.4.3-.2c5-2.7 11.2-6.1 8.8-10.6-2.4-4.5-9-1.8-12.1-.5-4.9 2.1-14.4 3.7-14.5 3.7a39 39 0 0 0-26.3 16.2l-7.4 3.2-.2-.3a2.2 2.2 0 0 0-2.5-1l-19 6.2a2.1 2.1 0 0 0-1.4 1.3 2.1 2.1 0 0 0 .3 2 28.3 28.3 0 0 0 3.5 4.2 44.4 44.4 0 0 0 10 20.5 2.1 2.1 0 0 0 2.9.2l16.3-12.7a1.5 1.5 0 0 0 .7-.7 1.5 1.5 0 0 0 0-1.4l-.5-.7 8.6-4.7a1.6 1.6 0 0 1 .7-.2l47.3-2.1a3.6 3.6 0 0 0 2.5-1.2l17.2-18.8c1.9-2 5.7-6.2 2.8-9-1.2-1-3.2-1.8-6.5.5zm-7 2c2.2-2 3.9-2.5 5-1.4a1.3 1.3 0 0 1 .3.8l-18.2 13.4-3-1.1 15.8-11.7zm-96.9 22.9a.1.1 0 0 1 .1 0l19-6.3a.1.1 0 0 1 .1 0l10.4 18c-4.8.8-20.7 2-29.6-11.5a.1.1 0 0 1 0-.2zM66 179.5a.1.1 0 0 1-.2 0c-1.8-1.9-6-7.2-8.7-16.7a32.2 32.2 0 0 0 22.6 6.1zm92.6-42.3L141.4 156a1.6 1.6 0 0 1-1.1.5L93 158.7a3.8 3.8 0 0 0-1.6.5l-8.6 4.6-8.1-14.1 7.2-3a2.2 2.2 0 0 0 1-.9 36.7 36.7 0 0 1 24.9-15.3c.4 0 9.8-1.6 15-3.8 5.6-2.4 8.4-2.6 9.5-.4 1.4 2.5-2.8 5-8 8l-13.3 8a1 1 0 0 0-.5 1.2 1 1 0 0 0 1.3.6 1.2 1.2 0 0 0 .2-.1l5-3c3.4.8 15.9 4.1 19 5.7a1.4 1.4 0 0 0 1.5-.1c1.9-1.4 18-13.2 19-14.1 2.3-2 4-2.5 5-1.5 1.5 1.5-1.4 4.7-2.9 6.2z" className="priceLastSoldCls-23" style={{fill: 'url(#priceLastSoldLine-16)'}} />
                    <path id="Contour-30" d="M83.5 127.5a45.9 45.9 0 0 0 29.6-20l.8-1.1a1 1 0 0 0 .3-.5 1 1 0 0 0-.8-1.2 1 1 0 0 0-1.1.6 40.4 40.4 0 0 1-56.5 11.2 40.8 40.8 0 0 1-11.6-54.8 40.4 40.4 0 0 1 16.6-15l1.7 4a1.4 1.4 0 0 0 1.2.8 1.3 1.3 0 0 0 1.3-.8l5.1-10.2a1.3 1.3 0 0 0 0-1 1.4 1.4 0 0 0-.6-.8l-10.3-5a1.4 1.4 0 0 0-1.9 1.4l.9 3.9a46.2 46.2 0 0 0 16.2 89.4 46.5 46.5 0 0 0 9.1-1zm-33.8-8.9a44.2 44.2 0 0 1-13-59.2 43.7 43.7 0 0 1 22.7-18.8 1.4 1.4 0 0 0 .9-1.5l-.7-3 8.4 4.1-4.2 8.4-1.4-3.3a1.4 1.4 0 0 0-.7-.8 1.3 1.3 0 0 0-1 0 42.3 42.3 0 0 0-18.2 16 42.8 42.8 0 0 0 12.2 57.6 42 42 0 0 0 31.8 6.5l-3.3.8a44 44 0 0 1-33.5-6.8z" className="priceLastSoldCls-28" style={{fill: 'url(#priceLastSoldLine-21)'}} />
                    <path id="Contour-31" d="M161.3 41.4a36.9 36.9 0 0 0-52.6 3.3l-.6.7a1 1 0 0 0 .7 1.7 1 1 0 0 0 .8-.4 32 32 0 0 1 45.3-2.7 32.2 32.2 0 0 1 3.3 44.1 31.9 31.9 0 0 1-14.4 9.9l-.9-3.1a1.3 1.3 0 0 0-1-1 1.3 1.3 0 0 0-1.2.6l-5.1 7.5a1.3 1.3 0 0 0-.2 1 1.3 1.3 0 0 0 .5.8l7.5 5.1a1.3 1.3 0 0 0 .7.2 1.3 1.3 0 0 0 1.3-1.3l-.2-3A36.5 36.5 0 0 0 165.4 92a36.9 36.9 0 0 0-4.1-50.6zm2.6 49.3A34.5 34.5 0 0 1 144 103a1.3 1.3 0 0 0-1 1.3l.2 2-5.7-3.8 3.8-5.6.6 2.4a1.3 1.3 0 0 0 1.7.8 34 34 0 0 0-7-66.1 34.9 34.9 0 0 1 27.2 56.7z" className="priceLastSoldCls-29" style={{fill: 'url(#priceLastSoldLine-22)'}} />
                </g>
                <g id="Icon_15" data-name="Icon 15" transform="matrix(.5 0 0 .5 83.3 19.4)">
                    <circle id="Contour-19" cx="102.1" cy="94.7" r="47.8" className="priceLastSoldCls-18" style={{fill: 'url(#priceLastSoldLinearGradient2321)'}} />
                    <circle id="Contour-20" cx="104.7" cy="91.8" r="44.3" className="iconWhite" />
                    <path id="Contour-23-9" d="m101.7 91.8 19.5-19.4a2 2 0 0 1 2.9 0 2 2 0 0 1 0 2.8L105 94.3Z" className="priceLastSoldCls-21" style={{fill: 'url(#priceLastSoldFill-4-4)'}} />
                    <path id="Contour-24-3" d="m104.4 97.3-20-7a2 2 0 0 1-1.2-2.6 2 2 0 0 1 2.6-1.2l19.5 6.9z" className="priceLastSoldCls-22" style={{fill: 'url(#priceLastSoldFill-5-5)'}} />
                    <circle id="Contour-25-7" cx="102.1" cy="94.7" r="5.1" className="priceLastSoldCls-23" style={{fill: 'url(#priceLastSoldFill-6)'}} />
                    <g id="Group-4-4">
                        <path id="Contour-26" d="M102.1 143.5a48.8 48.8 0 1 0-48.8-48.8 48.9 48.9 0 0 0 48.8 48.8zm0-95.5a46.8 46.8 0 1 1-46.8 46.7A46.8 46.8 0 0 1 102.1 48Z" className="priceLastSoldCls-24" style={{fill: 'url(#priceLastSoldLine-16-0)'}} />
                        <path id="Contour-27" d="M102.1 137.2a42.4 42.4 0 1 0-42.4-42.5 42.5 42.5 0 0 0 42.4 42.4zm0-82.9a40.4 40.4 0 1 1-40.4 40.4 40.5 40.5 0 0 1 40.4-40.4Z" style={{fill: 'url(#priceLastSoldLine-17-3)'}} />
                        <path id="Contour-30-5" d="M74.2 95.7a1 1 0 0 0 0-2h-9a1 1 0 0 0 0 2z" className="priceLastSoldCls-28" style={{fill: 'url(#priceLastSoldLine-20-0)'}} />
                        <path id="Contour-31-2" d="m84 91.2 12 4.3a6 6 0 0 0 9.5 4.2L121 117a1 1 0 0 0 .7.3 1 1 0 0 0 .7-1.6L107 98.3a6 6 0 0 0 .9-5.4L124.8 76a3 3 0 0 0-4.3-4.3l-17.2 17.1a5.4 5.4 0 0 0-1.2-.1 6 6 0 0 0-3.6 1.2l-12.4-4.4a3 3 0 0 0-2 5.7zm38-18.1a1 1 0 0 1 1.4 0 1 1 0 0 1 .3.7 1 1 0 0 1-.3.7L106.9 91a6.1 6.1 0 0 0-1.6-1.4zm-15.8 21.6a4 4 0 1 1-4-4 4 4 0 0 1 4 4zm-22-6.7a1 1 0 0 1 1.2-.6l11.6 4a6 6 0 0 0-.8 2l-11.5-4a1 1 0 0 1-.5-.6 1 1 0 0 1 0-.8Z" className="priceLastSoldCls-29" style={{fill: 'url(#priceLastSoldLine-21-6)'}} />
                        <path id="Contour-32" d="m82.7 62.8 2.3 3.9a1 1 0 0 0 .8.5 1 1 0 0 0 1-1 1 1 0 0 0-.1-.5l-2.2-3.9a1 1 0 1 0-1.7 1z" style={{fill: 'url(#priceLastSoldLine-22-3)'}} />
                        <path id="Contour-33-5" d="M74.2 112a1 1 0 0 0-.5-1.9 1 1 0 0 0-.5.2l-3.6 2a1 1 0 0 0-.5 1 1 1 0 0 0 1 1 1 1 0 0 0 .5-.2z" style={{fill: 'url(#priceLastSoldLine-23-2)'}} />
                        <path id="Contour-34-4" d="m121.5 126.7-2.2-3.9a1 1 0 0 0-1.9.5 1 1 0 0 0 .2.5l2.2 3.8a1 1 0 0 0 .8.5 1 1 0 0 0 1-1 1 1 0 0 0-.1-.5z" style={{fill: 'url(#priceLastSoldLine-24-0)'}} />
                        <path id="Contour-35-7" d="M102.1 67.8a1 1 0 0 0 1-1v-9a1 1 0 1 0-2 0v9a1 1 0 0 0 1 1z" style={{fill: 'url(#priceLastSoldLine-25-6)'}} />
                        <path id="Contour-36-4" d="m69.6 77.1 3.6 2.1a1 1 0 0 0 .5.1 1 1 0 0 0 1-1 1 1 0 0 0-.5-.8l-3.7-2.1a1 1 0 0 0-.5-.1 1 1 0 0 0-.4 1.8z" style={{fill: 'url(#priceLastSoldLine-26-1)'}} />
                        <path id="Contour-37-4" d="M85.8 122.3a1 1 0 0 0-.8.5l-2.3 3.9a1 1 0 0 0-.1.5 1 1 0 0 0 1.9.4l2.2-3.8a1 1 0 0 0 .1-.5 1 1 0 0 0-1-1z" style={{fill: 'url(#priceLastSoldLine-27-5)'}} />
                        <path id="Contour-38-3" d="M103.1 122.7a1 1 0 0 0-2 0v9a1 1 0 0 0 2 0z" style={{fill: 'url(#priceLastSoldLine-28-5)'}} />
                        <path id="Contour-39-0" d="m134.7 112.4-3.7-2.1a1 1 0 0 0-.5-.2 1 1 0 0 0-.5 1.9l3.6 2.1a1 1 0 0 0 .5.1 1 1 0 0 0 1-1 1 1 0 0 0-.4-.8z" style={{fill: 'url(#priceLastSoldLine-29-4)'}} />
                        <path id="Contour-40" d="M130 95.7h9a1 1 0 0 0 0-2h-9a1 1 0 0 0 0 2z" style={{fill: 'url(#priceLastSoldLine-30-7)'}} />
                        <path id="Contour-41" d="M130.5 79.3a1 1 0 0 0 .5 0l3.7-2.2a1 1 0 0 0-.5-1.8 1 1 0 0 0-.5.1l-3.7 2a1 1 0 0 0-.5 1 1 1 0 0 0 1 1z" style={{fill: 'url(#priceLastSoldLine-31)'}} />
                        <path id="Contour-42" d="M118.4 67.2a1 1 0 0 0 .9-.5l2.2-3.9a1 1 0 0 0 .1-.5 1 1 0 0 0-1-1 1 1 0 0 0-.8.5l-2.2 3.9a1 1 0 0 0-.2.5 1 1 0 0 0 1 1z" style={{fill: 'url(#priceLastSoldLine-32)'}} />
                    </g>
                </g>
            </g>
            <circle id="Contour-17-0" cx="76.1" cy="85.4" r={28} className="iconWhite" style={{fill: '#fff', strokeWidth: '.799681'}} />
            <path id="Contour-18-9" d="M74.8 69v4.2s-6.8 4-4.5 9.3c2.2 5 8.9 5.4 8.9 5.4s1.7 5.4-2.4 5.1c-4-.3-3.6-2.7-3.6-2.7h-4.3s1.4 5.6 5.5 6.5l.4 4.7 4 .3.6-4.7s4.8-2.4 4.7-6.5a9 9 0 0 0-6.2-8.2c-3.1-1.1-4.7-4-.9-5 0 0 2.3.8 2.5 2.3l4.3-.1s-1-4.4-3.6-5.3C77.6 73.4 79 69 79 69z" className="priceLastSoldCls-18" style={{fill: 'url(#priceLastSoldLinearGradient2945)', strokeWidth: '.799681'}} />
            <path id="Contour-19-3" d="M104.2 91s-16.3 21.8-34.5 15.8a23 23 0 0 1-15.6-22.4c.2-6.5 2.7-14.8 10.9-24-4 1.5-7.6 4-10.5 7.1-6.4 7.1-11 19.5-1.4 33.9 16.5 24.6 51 6.8 51-10.4z" style={{fill: 'url(#priceLastSoldFill-2-3)', strokeWidth: '.799681'}} />
            <path id="Contour-25-6" d="M76.2 114.7a29 29 0 1 0 0-58 29 29 0 0 0 0 58zm0-56.3a27.4 27.4 0 1 1 0 54.7 27.4 27.4 0 0 1 0-54.7z" className="priceLastSoldCls-22" style={{fill: 'url(#priceLastSoldLine-16-2)', strokeWidth: '.799681'}} />
            <path id="Contour-26-0" d="M70.8 95.5c1 .9 2 1.5 3.3 2v3c0 .8.6 1.4 1.4 1.4h3c.8 0 1.4-.6 1.4-1.4v-3.2a7.7 7.7 0 0 0 5.1-7.5c.1-1.7-.5-3.5-1.6-4.9a11.1 11.1 0 0 0-5-3c-1-.1-1.8-.5-2.5-1-.3-.3-.4-.6-.4-1s.1-.8.4-1c.3-.3.7-.5 1.1-.5.4 0 .7.1 1 .4.2.1.3.4.4.6.2.6.7 1 1.3 1h3a1.4 1.4 0 0 0 1.5-1.5 6.8 6.8 0 0 0-4.2-5.6v-3.2c0-.7-.7-1.4-1.4-1.4h-3c-1 0-1.6.8-1.5 1.7v2.9a7.1 7.1 0 0 0-4.6 6.9c0 1.6.5 3.3 1.6 4.5a11 11 0 0 0 5 2.8c.9.2 1.7.6 2.5 1.1.3.3.5.7.4 1.1 0 .6-.2 1.2-.6 1.7a2 2 0 0 1-1.6.6c-.6 0-1.2-.2-1.6-.6a2 2 0 0 1-.6-1.1c0-.7-.6-1.2-1.3-1.2h-3.2c-.9 0-1.5.8-1.4 1.6.1 1.2.8 3.6 2.1 4.8zm2.3-4.8c0 .8.5 1.4 1 2 .7.6 1.7 1 2.7 1 1 0 2-.4 2.7-1.2.8-.7 1.1-1.7 1.1-2.7a3 3 0 0 0-1-2.3c-1-.7-2-1.2-3.1-1.5-1.6-.4-3-1.2-4.2-2.3A5 5 0 0 1 71 80a5.5 5.5 0 0 1 3.7-5.4c.5-.2.9-.7.9-1.3v-3.1h2.6v3.2c0 .6.4 1.1 1 1.3a5.2 5.2 0 0 1 3.3 4h-2.7a3 3 0 0 0-.8-1.2c-.6-.5-1.3-.8-2-.7a3 3 0 0 0-2.2.8 3.1 3.1 0 0 0 0 4.4c1 .7 1.9 1.1 3 1.4 1.6.4 3.1 1.3 4.3 2.5.9 1 1.3 2.5 1.2 3.8a6 6 0 0 1-4.1 6c-.6.2-1 .7-1 1.3v3.2h-2.6v-3c0-.7-.4-1.2-1-1.4-1-.3-2-.8-2.8-1.5-.8-1-1.4-2.4-1.6-3.7z" className="priceLastSoldCls-23" style={{fill: 'url(#priceLastSoldLine-17-7)', strokeWidth: '.799681'}} />
            <path id="Contour-27-6" d="M76.4 109.4a23.7 23.7 0 1 0 0-47.4 23.7 23.7 0 0 0 0 47.4zm0-45.8a22.1 22.1 0 1 1 0 44.3 22.1 22.1 0 0 1 0-44.4z" className="priceLastSoldCls-24" style={{fill: 'url(#priceLastSoldLine-18-5)', strokeWidth: '.799681'}} />
        </svg>
    )
}
export default PriceLastSold