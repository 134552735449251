import {Responsive, WidthProvider} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css'
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    initializedSelector,
    renderWidgetsToLayoutSelectorFunc,
    selectWidgetVariables,
} from "../../../../slices/selectors";
import {useDispatch, useSelector} from "react-redux";
import { Backdrop, Box, Button, CircularProgress, Dialog, Grid } from "@mui/material";
import WidgetSwitcher from "../widget/WidgetSwitcher";
import {initBitool} from "../../../../slices/bitool";
import {useParams} from "react-router";
import WidgetStore from "../../../../cog/bitool/widget/Store";
import NewReportForm from "./reportsAndExports/NewReportForm";
import WidgetCreatorDialog from "./edittingFeatures/WidgetCreatorDialog";
import { useNavigate } from "react-router-dom";
import { Store, DashboardCloner } from "../../../../cog/bitool/dashboard";
import { useSnackbar } from "notistack";
import DashboardUpdateDialog from "./edittingFeatures/DashboardUpdateDialog";
import DashboardType  from "../../../../cog/bitool/dashboard/Dashboard";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import DashboardSkeleton from "./DashboardSkeleton";
import CloningVariablesForm from "./edittingFeatures/CloningVariablesForm";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayout {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number
}

interface GridLayouts {
    lg?: GridLayout[];
    md?: GridLayout[];
    sm?: GridLayout[];
    xl?: GridLayout[];
}

interface WidgetLayout {
    lg?: GridLayout;
    md?: GridLayout;
    sm?: GridLayout;
    xl?: GridLayout;
}

let dialogBody = () => {
    return (
      <div>
      </div>
    )
};

const Dashboard = () => {
    let {dashId} = useParams();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const [layouts, setLayouts] = useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [dash, setDash] = React.useState<DashboardType>(new DashboardType())


    const selectRenderWidgetsToLayoutSelector = useMemo(renderWidgetsToLayoutSelectorFunc, [])
    const widgetVariables = useSelector((state) => selectWidgetVariables(state));

    const renderWidgetsToLayout = useSelector((state) =>
        selectRenderWidgetsToLayoutSelector(state)
    )
    const initSelector = useSelector((state) =>
        initializedSelector(state)
    )

    const fetchDashboard = useCallback(async () => {
        let response = await Store.FindOne({
            ID: dashId
        })

        setDash(response.dashboard)
    }, [dashId])

    useEffect(() => {
        fetchDashboard()
    }, [fetchDashboard])

    useEffect(() => {
        dispatch(initBitool(dashId, false))
    }, [dashId])

    useEffect(() => {
        setLayouts(renderWidgetsToLayout.layouts)
    }, [renderWidgetsToLayout])

    const layoutsToWidgets = (layouts: GridLayouts) => {
        let newWidgets = {...renderWidgetsToLayout.widgets}
        Object.keys(layouts).map((k) => {
            layouts[k].map((layout, index) => {
                let currWidget = newWidgets[index]
                if (currWidget && currWidget.arguments && currWidget.arguments.location) {
                    newWidgets[index] = {
                        ...currWidget,
                        arguments: {...currWidget.arguments, location: {[k]: {...layout}}}
                    }
                }
            })
        })
        return newWidgets
    }

    const layoutsToWidgetsRequest = useCallback(async () => {
        const widgets = layoutsToWidgets(layouts)
        console.log(widgets, layouts)
        if (layouts && widgets) {
            let response = await WidgetStore.UpdateMany(
                {widgets: Object.keys(widgets).map((k) => widgets[k])}
            )
        }
    }, [layouts])

    const handleDialogOpen = () => {
        dialogBody = () => {
            return (
                <NewReportForm dashId={dashId} widgetVariables={widgetVariables} handleDialogClose={handleDialogClose}/>
            );
        }
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCreateWidget = () => {
        dialogBody = () => {
            return (
              <WidgetCreatorDialog handleDialogClose={handleDialogClose} />
            );
        }
        setDialogOpen(true);
    }

    const handleUpdateDashboard = () => {
        dialogBody = () => {
            return (
              <DashboardUpdateDialog handleDialogClose={handleDialogClose} dashboard={dash}/>
            );
        }
        setDialogOpen(true);
    }

    const handleWidgetList = () => {
        navigate('/app/bi/widgetchoose/' + dashId)
    }

    const handleCloneDashboard = () => {
        dialogBody = () => {
            return (
              <CloningVariablesForm handleDialogClose={handleDialogClose} dashboardId={dashId} organizationId={dash.organizationId}/>
            );
        }
        setDialogOpen(true);
    }

    if (!dashId) {
        return <div> No Dashboard ID found </div>
    }

    if (!initSelector) {
        return (
            <Grid item xs={8}>
                <Box sx={{minHeight: "100"}}>
                    <CircularProgress/>
                </Box>
            </Grid>
        )
    }

    if (renderWidgetsToLayout) {
        return (
            <Box>
                <Backdrop
                  sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                  open={open}
                >
                    <CircularProgress/>
                </Backdrop>
                <Box>
                    <Button variant="text" onClick={() => handleCreateWidget()}>
                        Create Widget    
                    </Button>
                    <Button variant="text" onClick={() => handleWidgetList()}>
                        Widget List
                    </Button>
                    <Button variant="text" onClick={layoutsToWidgetsRequest}>
                        Save Layout
                    </Button>
                    <Button variant="text" onClick={() => null}>
                        Save Report
                    </Button>
                    <Button onClick={() => handleDialogOpen()}>
                        Create Report
                    </Button>
                    <Button onClick={() => handleCloneDashboard()}>
                        Clone Dashboard
                    </Button>
                    <Button onClick={() => handleUpdateDashboard()}>
                        Update Dashboard
                    </Button>
                </Box>
                <Box>
                    <ResponsiveGridLayout className="layout"
                                          layouts={renderWidgetsToLayout.layouts}
                                          rowHeight={15}
                                          isDraggable={true}
                                          isResizable={true}
                                          compactType={null}
                                          preventCollision={true}
                                          onLayoutChange={(_, _layouts) => {
                                              console.log("Layouts: ", _layouts)
                                              setLayouts(_layouts)
                                          }}
                                          breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                          cols={{lg: 12, md: 12, sm: 12, xs: 4, xxs: 2}}>
                        {renderWidgetsToLayout.widgets.map((w) => {
                            return <Box sx={{ height: "100%"
                            }} key={w.id.toString()}>
                                <WidgetSwitcher widget={w}/>
                            </Box>
                        })}
                    </ResponsiveGridLayout>
                </Box>
                <Dialog
                  open={dialogOpen}
                  onClose={handleDialogClose}
                  aria-labelledby="form-dialog-title"
                  fullWidth
                  maxWidth="md"
                >
                    {dialogBody()}
                </Dialog>
            </Box>

        )
    } else {
        return <CircularProgress/>
    }
}

export default Dashboard