import type {FC} from 'react';
import * as React from "react";
import {useLayoutEffect} from "react";
import {CardContent, CardHeader, Divider} from "@mui/material";
import NPDGoalChart from "./ChartNPD";
import SVChart from "./ChartSV";
import Goal from "../../../../../../cog/keystone/goals/goal/Goal";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import NPSGoalChart from "./ChartNPS";

interface GoalChartProps {
    type?: string;
    goal?: Goal;
}

const GoalChart: FC<GoalChartProps> = ({type, goal}) => {
    const [hasData, setHasData] = React.useState(true)

    const handleHasData = () => {
        setHasData(false)
    }

    const getChartType = () => {
        switch (type) {
            case 'NPS':
                return <NPSGoalChart labelValue={'day'}
                                     goal={goal}
                                     hasDataHandler={handleHasData}/>;
            case 'NPD':
                return <NPDGoalChart labelValue={'day'}
                                     goal={goal}
                                     hasDataHandler={handleHasData}/>;
            case 'SV':
                return <SVChart labelValue={'day'}
                                goal={goal}
                                hasDataHandler={handleHasData}/>;
            default:
                return <Box>No Data</Box>;
        }
    }

    return (
        <>
            <CardHeader title={type + " Chart"}/>
            <Divider/>
            <CardContent>
                {hasData ? getChartType() : "No Chart Data"}
            </CardContent>
        </>
    );
}

export default GoalChart;