/* eslint-disable */

import React, {createContext, useContext, useEffect, useLayoutEffect, useState} from 'react';
import {RouteObject} from "react-router";
import {baseRoutes, dashboardRoutes} from "./routes";
import {Organization, OrganizationStore} from "../cog/organization";
import {User} from "../cog/user";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import ReactGA4 from 'react-ga4';
import "firebase/analytics"
import {
    confirmPasswordReset,
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import {doc, getDoc, getFirestore, serverTimestamp, setDoc, updateDoc,} from "firebase/firestore";

import SplashScreen from "../components/SplashScreen";
import config from 'react-global-configuration';
import {Member, MemberStore} from "../cog/keystone/member";
import {ListedOpportunityType} from "../cog/keystone/opportunity/Opportunity";
import {OpportunityStore} from "../cog/keystone/opportunity";

interface MainContext {
    isLoading: boolean;
    isInitialized: boolean;
    isAuthenticated: boolean;

    accessToken: string;
    user: User | null;
    setUser: (user: User) => void;
    
    slider: boolean;
    setSlider: (slider) => void;
    
    registerWithEmailAndPassword: (name: string, email: string, password: string) => Promise<any>;
    logInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
    resetPassword: (code:string, newPassword: string) => Promise<any>;
    sendPasswordReset: (email: string) => Promise<any>;
    logout: () => Promise<void>;
    updateUserInfo: (User) => Promise<void>;
    organizations: Organization[] | null;
    activeOrganization: Organization | null;
    setActiveOrganization: (Organization) => void;
    members: Member[] | null;
    opportunityTypes: ListedOpportunityType[] | null;

    routes: RouteObject[] | null,
}

const Context = createContext({} as MainContext);
Context.displayName = "MainContext"

export const MainContext: React.FC = ({children}: { children?: React.ReactNode }) => {
    const app = initializeApp(config.get('firebase'));
    const analytics = getAnalytics(app);
    const auth = getAuth(app);
    const firestoreDB = getFirestore(app);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(null);

    const [accessToken, setAccessToken] = useState<string>("");
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [organizations, setOrganizations] = useState<Organization[] | null>(null);
    const [activeOrganization, setActiveOrganization] = useState<Organization | null>(null);
    const [members, setMembers] = useState<Member[]>(null);
    const [opportunityTypes, setOpportunityTypes] = useState<ListedOpportunityType[]>(null)

    const [routes, setRoutes] = useState<RouteObject[]>(null);
    const [slider, setSlider] = useState<boolean>(true);
    
    useEffect(() => {
        setRoutes([...baseRoutes, ...dashboardRoutes])
        if (isAuthenticated) {
            getOrganizations()
        }
    }, [isAuthenticated]);

    useEffect(() => {
        setAccessToken(localStorage.getItem("accessToken"))
    }, [localStorage.getItem("accessToken")])

    // on first app load
    useLayoutEffect(() => {
      initialize();
    }, []);

    const initialize = async (): Promise<void> => {
        try {
          await checkAuth()
        } catch (err) {
          console.error(err);
        }
    };

    useEffect(() => {
        if (activeOrganization != null) {
            // do members
            doMembers().then((r) => setMembers(r))
        }
    }, [activeOrganization])

    // doMembers: Fetches members for the little icons
    const doMembers = (async () => {
        if (activeOrganization != null) {
            let membersIssue = await MemberStore.FindMany({orgID: activeOrganization.id});
            if (membersIssue.members == null) {
                let tempMembers: Member[] = []
                return tempMembers
            }
            return membersIssue.members;
        }
    });

    const fetchOpportunityTypes = async () => {
        if (activeOrganization != null) {
            let oppTypes = await OpportunityStore.FindAllListedOppTypes({clientName: activeOrganization.name});
            if (oppTypes.listedOpportunityTypes == null) {
                let tempOppTypes: ListedOpportunityType[] = []
                return tempOppTypes
            }
            return oppTypes.listedOpportunityTypes;
        }
    }

    useEffect(() => {
        if (activeOrganization != null) {
            fetchOpportunityTypes().then((r) => setOpportunityTypes(r))
        }
    }, [activeOrganization]);

    const logInWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
        try {
            await signInWithEmailAndPassword(auth, email, password);

            const uid = auth.currentUser.uid
            ReactGA4.set({userID: uid, user_id: auth.currentUser.uid})

            const userRef = doc(firestoreDB, "Users", uid)
            await updateDoc(userRef, {
                LastLoggedIn: serverTimestamp()
            })

            await checkAuth();
        } catch (err) {
            //console.error(err);
            return err
            //alert(err.message);
        }
    };
    const resetPassword = async (code: string, newPassword: string): Promise<any> => {
        try {
            await confirmPasswordReset(auth, code, newPassword)
            return true
        }
        catch (err) {
            console.error(err);
            return err
        }
    }

    const calculateSessionLength = (logInTime) => {
        const time1 = new Date(logInTime)
        const time2 = new Date()
        return time2.getTime() - time1.getTime()
    }

    const registerWithEmailAndPassword = async (name, email, password): Promise<any> => {
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);
            const user = res.user;
            await setDoc(doc(firestoreDB, "Users", user.uid), {
              Name: name,
              Avatar: "",
              Email: email,
              LastLoggedIn: "",
              LastLoggedOut: "",
              SessionLength: "0"
            });
        } catch (err) {
            console.error(err);
        }
    };

    const checkAuth =  async (): Promise<void> => {
        setIsLoading(true);
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const accessToken = await user.getIdToken()
                localStorage.setItem('accessToken', accessToken)
                const docRef = doc(firestoreDB, "Users", user.uid)

                const userDoc = await getDoc(docRef);
                if (userDoc.exists()) {
                    let userDetails = userDoc.data();

                    let entries = Object.entries(userDetails);
                    let capsEntries = entries.map((entry) => [entry[0][0].toUpperCase() + entry[0].slice(1), entry[1]]);
                    userDetails = Object.fromEntries(capsEntries);
                    const obj: User = {
                        id: user.uid,
                        avatar: userDetails.Avatar,
                        email: user.email,
                        name: userDetails.Name,
                        currency: userDetails.currency,
                    }
                    const today = new Date()
                    if (userDetails.LastAccessed && userDetails.DaysLogged) {
                    if (today.toDateString() != userDetails.LastAccessed.toDate().toDateString()) {
                        await updateDoc(docRef, {
                            DaysLogged: [...userDetails.DaysLogged, today.toDateString() ],
                            LastAccessed: serverTimestamp()
                        })
                        }} else {
                        await updateDoc(docRef, {
                            DaysLogged: [today.toDateString()],
                            LastAccessed: serverTimestamp()
                        })
                    }

                    setCurrentUser(obj)
                    setAccessToken(accessToken)
                    setIsAuthenticated(true)
                    setIsInitialized(true)


                } else {
                    console.log("No such document!")
                    //TODO: This needs to create a doc if not exists, the below method isnt getting the right values though (eg displayname)
                    await setDoc(doc(firestoreDB, "Users", user.uid), {
                        Name: user.displayName,
                        Avatar: "",
                        Email: user.email,
                        LastLoggedIn: "",
                        LastLoggedOut: "",
                        SessionLength: "0"
                    })
                }
            }
            else {setIsInitialized(true)}

        })
        setIsLoading(false);
    }

    const sendPasswordReset = async (email): Promise<any> => {
        try {
          await sendPasswordResetEmail(auth, email);
        } catch (err) {
          console.error(err);
          alert(err.message);
        }
    };

    const logout = async (): Promise<void> => {
        const uid = auth.currentUser.uid
        const userRef = doc(firestoreDB, "Users", uid)
        await updateDoc(userRef, {
          LastLoggedOut: serverTimestamp()
        })

        const docRef = doc(firestoreDB, "Users", uid)
        const userDoc = await getDoc(docRef);
        if (userDoc.exists()) {
        } else {
          console.log("No such document!")
        }

        // calculateSessionLength()
        setIsAuthenticated(false)
        await signOut(auth)
    };
    const updateUserInfo = async (user: User) => {

        await setDoc(doc(firestoreDB, "Users", currentUser.id), {
            name: user.name,
            Avatar: user.avatar,
            Email: currentUser.email,
            //Currency: user.currency.symbol
        }, {merge: true});
        setCurrentUser((prevState) => ({
            ...prevState,
            name: user.name,
            avatar: user.avatar,
        }));
    }
    const updateUserCustomFields = async (userDoc: object) => {

        await setDoc(doc(firestoreDB, "Users", currentUser.id), userDoc, {merge: true});
    }

    const getOrganizations = async (): Promise<void> => {
        setIsLoading(true);
        try {
            setOrganizations((await OrganizationStore.FindAll({})).organizations);
        } catch (e) {
            console.error(`${e}`);
        }
        setIsLoading(false)
    };

    // todo: fix this
    if (!isInitialized) {
        return <SplashScreen />;
    }

    return (
        <Context.Provider
            value={{
                isLoading: isLoading,
                isInitialized: isInitialized,
                isAuthenticated: isAuthenticated,

                accessToken: accessToken,
                user: currentUser,
                setUser: setCurrentUser,
                slider: slider,
                setSlider: setSlider,
                registerWithEmailAndPassword: registerWithEmailAndPassword,
                logInWithEmailAndPassword: logInWithEmailAndPassword,
                resetPassword: resetPassword,
                sendPasswordReset: sendPasswordReset,
                logout: logout,
                updateUserInfo: updateUserInfo,
                organizations: organizations,
                activeOrganization: activeOrganization,
                setActiveOrganization: setActiveOrganization,

                members: members,
                opportunityTypes: opportunityTypes,
                routes: routes,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useMainContext = () => useContext(Context);
export {
    useMainContext,
};
export default MainContext