/* eslint-disable */

import {PerformJSONRPCRequest, API} from '../../api/jsonrpc';
import Query from "./Query";

export interface FindOneRequest {
    id: string
}

export interface FindOneResponse {
    query: Query;
}

export interface FindAllRequest {
}

export interface FindAllResponse {
    queries: Query[];
}

export interface CreateOneRequest {
    name: string;
    templatedQuery: string;
}

export interface CreateOneResponse {
    id: string;
}

export interface DeleteOneRequest {
    id: string;
}

export interface DeleteOneResponse {
}

export interface UpdateOneRequest {
    id: string;
    name: string;
    templatedQuery: string;
}

export interface UpdateOneResponse {
    id: string;
}

const Store = {
    serviceProvider: 'Query-Store',
    async FindOne(request: FindOneRequest): Promise<FindOneResponse> { // redundant
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [request]
        });
        return {
            query: new Query(response.query),
        };
    },
    async FindAll(request: FindAllRequest): Promise<FindAllResponse> { // redundant
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAll`,
            request: []
        });
        return {
            queries: response.queries.map((mem) => (new Query(mem))),
        };
    },
    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> { // redundant
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                query: request
            }]
        });
        return {
            id: response.id
        };
    },
    async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> { // redundant
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOne`,
            request: [{
                id: request.id
            }]
        });
        return response.id;
    },
    async UpdateOne(request: UpdateOneRequest): Promise<UpdateOneResponse> { // redundant
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateOne`,
            request: [{
                query: request
            }]
        });
        return response.id;
    }
};

export default Store;
