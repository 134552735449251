/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import Comment from "./Comment";

export interface FindOneRequest {
    id:   string
}

export interface FindOneResponse {
    comment: Comment[]
}

export interface FindManyRequest {
    item_id:   string
}

export interface FindManyResponse {
    comments: Comment[]
}

export interface CreateOneRequest {
    comment: Comment
}

export interface CreateOneResponse {
    id: string
}

const Store = {
    serviceProvider: 'Comments-Store',
    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                "id": request.id
            }]
        });
        return response.history
    },
    async FindMany(request: FindManyRequest): Promise<FindManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Comments-Store.FindMany`,
            request: [{
                "item_id": request.item_id,
            }]
        })

        if (response.message == "record not found") {
            return null
        }
        // console.log("Hello vriende ",response)
        return {
            'comments': response.comment.map((comment) => (new Comment(comment)))
        }
    },


    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `Comments-Store.CreateOne`,
            request: [
                { "comment":{
                        "item_id": request.comment.item_id,
                        "user_id": request.comment.user_id.replace("auth0|", ""),
                        "content": request.comment.content
                    }}]
        })

        let nResponse = await response;
        if (nResponse.message == "record not found") {
            return null
        }
        return nResponse
    }
};

export default Store;