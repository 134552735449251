import {FilterItem} from "../../filter/Filter";
import {API, PerformJSONRPCRequest} from "../../../api/jsonrpc";
import {StatResults} from "../../card/Card";
import {TreeFromAPI} from "../../../../pages/app/keystone/productstoreuniverse/utility/NodeTypesAndOptions";

export interface StatsData {
    statName: string;
    statResults: StatResults;
}

export interface FinancialReportingStatsRequest {
    clientName: string
    dateSelectionStart: string
    dateSelectionEnd: string
    limit: string
    filters: FilterItem[]
    defaultFilters: FilterItem[]
    PSU: TreeFromAPI
}

export interface FinancialReportingStatsResponse {
    financialReportingStats: Record<string, StatsData>
}

const Store = {
    serviceProvider: 'FinancialReporting-Store',
    async FinancialReportingStats(request: FinancialReportingStatsRequest): Promise<FinancialReportingStatsResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FinancialReportingStats`,
            request: [{
                "client": request.clientName,
                "dateSelectionStart": request.dateSelectionStart,
                "dateSelectionEnd": request.dateSelectionEnd,
                "limit": request.limit,
                "filters": request.filters,
                "defaultFilters": request.defaultFilters,
                "PSU": request.PSU
            }]
        })
        return response.financialReportingStats
    },
};

export default Store