import * as React from 'react';
import {FC, useEffect, useLayoutEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {DataGridProProps, GridAlignment, GridToolbar} from '@mui/x-data-grid-pro';
import {Card as KanbanCardObj, KanbanStore} from "../../../../cog/keystone/card";
import {ListViewDefaultDisplayValues, ListViewIgnore} from "./IgnoreColumnList";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import Loader from "../kanban/util/Loader";
import {StyledDataGrid} from "./CustomDataGridTheme";
import {
    AccessTimeOutlined,
    AssessmentOutlined,
    AutorenewOutlined,
    BlockOutlined,
    CalendarTodayOutlined,
    CheckBoxOutlineBlank,
    CheckBoxOutlined,
    CheckCircleOutlineOutlined,
    ExpandLess,
    ExpandMore,
    Label,
    NewReleasesOutlined,
    TaskAltOutlined
} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {
    Autocomplete, Avatar, Button, CardHeader, Checkbox, Chip, CircularProgress, Grid, TextField, Theme, Tooltip
} from "@mui/material";
import {useMainContext} from "../../../../contexts/MainContext";
import {Member} from "../../../../cog/keystone/member";
import {Box} from "@mui/system";
import currencySwitcher from "../../../../utils/currencySwitcher";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {useNavigate} from "react-router-dom";
import {indexOf} from "lodash";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {NotificationStore} from "../../../../cog/keystone/notifications";

const checkedIcon = <CheckBoxIcon fontSize="small" />;
/**********************************************************************************************************************
 * DetailPanelContent: Creates the content for expanded DataGrid rows
 **********************************************************************************************************************/
function DetailPanelContent({
    row: rowProp,
    members: memProp,
    refreshRows: refreshRows,
    labelOptions : labelOptions
}: { row: any, members: Member[], refreshRows: () => void , labelOptions:OppLabel[]}) {
    // GridColDef array for the additional v0 fields
    const [additionalV0Columns, setAdditionalV0Columns] = useState<GridColDef[]>(null);
    // GridColDef array for the additional levelFilter fields
    const [myClientGridColumns, setMyClientGridColumns] = useState<GridColDef[]>(null);
    // For loading things
    const [loading, setLoading] = useState<boolean>(true);
    // Contexts
    const keystoneCtx = useKeystoneContext()
    const mainCtx = useMainContext()

    let navigate = useNavigate()

    // For currency displaying
    const currencySymbol = currencySwitcher(mainCtx.activeOrganization.name)

    // const [userToAssign, setUserToAssign] = useState<string>(Object.values(memProp)[0].id);
    // const [userToAssign, setUserToAssign] = useState<string>("");
    const [multiUserToAssign, setMultiUserToAssign] = useState([]);
    const [loadingAssign, setLoadingAssign] = useState<boolean>(false);
    // let multiUserToAssign: any

    //For Opportunity Labels
    const [opportunityLabels,setOpportunityLabels] = useState<OppLabel[]>([])

    // const handleToAssignChange = (event: SelectChangeEvent) => {
    //     setUserToAssign(event.target.value as string);
    // };
    //
    // const handleMultiAssignChange = (event: ChangeEvent) => {
    //     // console.log(event.target.id)
    //     // setMultiUserToAssign(event.target.value);
    // };
    // const [statusOptions, setStatusOptions] = useState<Record<string, string>>({})
    //
    // if (useKeystoneContext().statusCardOptions) {
    //     const transformedOptions: Record<string, string> = {};
    //     useKeystoneContext().statusCardOptions.forEach((option) => {
    //         transformedOptions[option.id] = option.name;
    //     });
    //
    //     // Update the state with the transformed options
    //     console.log("transformedOptions", transformedOptions)
    //     setStatusOptions(transformedOptions);
    //     console.log("statusOptionsSet")
    // }
    // Makes GridColDef arrays for the additional fields
    const makeColumns = () => {
        let tempColumns = []
        let tempClientColumns = []
        let gridColumns: GridColDef[] = []
        let tempClientGridColumns: GridColDef[] = []
        let labels:OppLabel[]
        //console.log("Make Columns")
        if(rowProp.listId == 'bf2e242d-525c-4218-a2e3-19d8602494c3'){
            //console.log("makeColumns list Id is the thing", rowProp.listId)
            labels = keystoneCtx.completedOptions
        }else{
            labels = labelOptions
        }
        // For v0 string
        for (const [key, value] of (Object.entries(JSON.parse(rowProp.v0)))) {
            if (value != null) {
                if (value != "" && value != "-" && value != "- ") {
                    if (tempColumns.indexOf(key) == -1) {
                        tempColumns.push(key)
                        gridColumns.push(BuildColumnObject(key, value, memProp, labels, currencySymbol, true, key == "id"))
                    }
                }
            }
        }
        // for levelFilter json fields
        for (const [key, value] of (Object.entries(JSON.parse(rowProp.levelFilter)))) {
            if (value != null) {
                if (value != "" && value != "-" && value != "- ") {
                    if (tempClientColumns.indexOf(key) == -1) {
                        tempClientColumns.push(key)
                        tempClientGridColumns.push(BuildColumnObject(key, value, memProp, labels, currencySymbol, true, key == "id"))
                    }
                }
            }
        }
        setMyClientGridColumns(tempClientGridColumns)
        setAdditionalV0Columns(gridColumns)
    }

    useLayoutEffect(() => {
        makeColumns();
        try {
            JSON.parse(`{"AssignedTo": ${rowProp.assignedTo} }`)
        } catch (e) {
            // cannot parse
            rowProp.assignedTo = ""
        }
        if (rowProp.assignedTo != "") {
            let tempUsers = []

            Object.values(memProp).map((item) => {
                if (indexOf(JSON.parse(`{"AssignedTo": ${rowProp.assignedTo} }`)['AssignedTo'], item.id) != -1) {
                    tempUsers.push(item)
                }

            })
            setMultiUserToAssign(tempUsers)
        } else {
            setMultiUserToAssign([])
        }

        try {
            JSON.parse(`{"OppLabels": ${rowProp.opportunityLabels} }`)
        } catch (e) {
            // cannot parse
            rowProp.opportunityLabels = ""
        }
        if (rowProp.opportunityLabels != "") {
            let tempLabels = []
            console.log("DataGridDetail ")
            if(rowProp.listId == 'bf2e242d-525c-4218-a2e3-19d8602494c3'){
                Object.values(keystoneCtx.completedOptions).map((item) => {
                    if (indexOf(JSON.parse(`{"OppLabels": ${rowProp.opportunityLabels} }`)['OppLabels'], item.id) != -1) {
                        tempLabels.push(item)
                    }

                })
            }else {
                Object.values(labelOptions).map((item) => {
                    if (indexOf(JSON.parse(`{"OppLabels": ${rowProp.opportunityLabels} }`)['OppLabels'], item.id) != -1) {
                        tempLabels.push(item)
                    }

                })
            }

            //console.log("tempLabels", tempLabels)
            setOpportunityLabels(tempLabels)
        } else {
            setOpportunityLabels([])
        }
        // This preps the user assign to have default value of currently assigned users

    }, []);

    useEffect(() => {
        if (additionalV0Columns != null && loading == true) {
            setLoading(false)
        }
    }, [additionalV0Columns])


    return (
        <Stack
            sx={{p: 2, height: 'fit-content', boxSizing: 'border-box'}}
            direction="column"
        >
            {loading ? <Loader/>
                :
                <Paper sx={{flex: 1, mx: 'auto', width: '90%', p: 1}}>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h6">{`${rowProp.name}`}</Typography>
                        <Typography variant="h6" color="textSecondary">{`Opportunity #${rowProp.id}`}</Typography>
                        {/*Additional refers to the v0 string*/}
                        <Typography variant="h5" color="textPrimary">
                            Additional
                        </Typography>
                        <StyledDataGrid
                            density="compact"
                            columns={additionalV0Columns}
                            rows={[rowProp]}
                            sx={{flex: 1}}
                            autoHeight={true}
                            hideFooter
                        />
                        <Typography variant="h5" color="textPrimary">
                            Additional Client Information
                        </Typography>
                        <StyledDataGrid
                            density="compact"
                            columns={myClientGridColumns}
                            rows={[rowProp]}
                            sx={{flex: 1}}
                            autoHeight={true}
                            hideFooter
                            getRowHeight={() => 'auto'}
                            getEstimatedRowHeight={() => 300}
                        />
                        <Button
                            onClick={() => {
                                const org = mainCtx.activeOrganization.name.replace(/ |_/g, '-');
                                navigate('/app/' + org.toLowerCase() + '/opportunity/' + rowProp.id)
                            }}
                            color={"primary"}
                            variant={"outlined"}
                        >More Info</Button>
                        <Grid container display={"grid"} gridTemplateColumns={"2fr 2fr 1fr"} gridAutoFlow={"row"}
                              gridAutoRows={"auto"} gap={1}>
                            <Autocomplete
                                value={multiUserToAssign}
                                onChange={(event, newValue) => {
                                    setMultiUserToAssign(newValue);
                                }}
                                multiple
                                id="checkboxes-tags-demo"
                                limitTags={2}
                                options={Object.values(memProp)}
                                defaultValue={rowProp.assignedTo != "" ?
                                    Object.values(memProp).map((item) => {
                                        if (indexOf(JSON.parse(`{"AssignedTo": ${rowProp.assignedTo} }`)['AssignedTo'], item.id) != -1) {
                                            return item
                                        }
                                    }) : []}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.displayName}
                                renderOption={(props, option, {selected}) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                            checkedIcon={<CheckBoxOutlined fontSize="small"/>}
                                            // style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    src={option.avatar}
                                                    alt={option.displayName}
                                                    variant={"circular"}
                                                    sx={{width: 24, height: 24}}
                                                />
                                            }
                                            title={option.displayName} disableTypography={true}
                                            sx={{padding: 0}}
                                            key={`AssignedUser_${option.id}`}
                                        >
                                        </CardHeader>
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Assignees" placeholder="Assignees"
                                               value={multiUserToAssign}/>
                                )}
                            />
                            <Autocomplete
                                value={opportunityLabels}
                                onChange={(event, newValue) => {
                                    // @ts-ignore
                                    setOpportunityLabels(newValue);
                                }}
                                multiple
                                id="checkboxes-tags-demo"
                                limitTags={2}
                                options={labelOptions}
                                defaultValue={rowProp.opportunityLabels != "" ?
                                    labelOptions.map((item) => {
                                        try {
                                            if(indexOf(JSON.parse(`{"OppLabels": ${rowProp.opportunityLabels} }`)['OppLabels'], item.id) != -1) {
                                                return item
                                            }
                                        }
                                        catch (e){

                                        }

                                    }) : [] }
                                disableCloseOnSelect
                                // @ts-ignore
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                            checked={selected}
                                        />
                                        <CardHeader
                                            title={option.label} disableTypography={true}
                                            sx={{padding: 0}}
                                            key={`OppLabel_${option.id}`}
                                        >
                                        </CardHeader>
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Labels" placeholder="Labels" value={opportunityLabels}  />
                                )}
                            />
                            <Button onClick={() => {
                                // console.log("AutoComplete Box", multiUserToAssign)
                                setLoadingAssign(true)
                                KanbanStore.AssignMultiUsers({
                                    assignUsers: multiUserToAssign.map((v) => {
                                        return v.id
                                    }),
                                    cardIDs: [rowProp.id],
                                    client: mainCtx.activeOrganization.name,
                                    userID: mainCtx.user.id
                                }).then((p) => {
                                    rowProp.assignedTo = p
                                    multiUserToAssign.map((card) => {
                                        NotificationStore.CreateOne({
                                            ClientName: mainCtx.activeOrganization.name,
                                            UserId: mainCtx.user.id,
                                            CardId: card,
                                            ActionType: "Assigned",
                                            Description: "",
                                            Platform: "Internal",
                                            IsRead: false
                                        })
                                    })
                                    // console.log("p", p)

                                })
                                KanbanStore.AssignMultiLabels({
                                    oppLabelIDs: opportunityLabels.map((v: any) => {
                                        return v.id
                                    }),
                                    cardIDs: [rowProp.id],
                                    client: mainCtx.activeOrganization.name,
                                    userID: mainCtx.user.id
                                }).then((p) => {
                                    // TODO: Needs to look at context to find card assignees
                                    // NotificationStore.CreateOne({
                                    //     ClientName: mainCtx.activeOrganization.name,
                                    //     UserId: mainCtx.user.id,
                                    //     CardId: rowProp.id,
                                    //     ActionType: "Updated",
                                    //     Description: "",
                                    //     Platform: "Internal",
                                    //     IsRead: false
                                    // })
                                    rowProp.opportunityLabels = p.toString()
                                    setLoadingAssign(false)
                                    refreshRows()
                                })
                            }} color={"primary"} variant={"outlined"}
                                    disabled={loadingAssign}
                            >
                                Save Changes
                            </Button>
                        </Grid>

                    </Stack>
                </Paper>
            }
        </Stack>
    );
}

/**********************************************************************************************************************
 * DatGrid with Detail Panel
 **********************************************************************************************************************/

// DataGridDetailProps: Props for DataGrid
interface DataGridDetailProps {
    cards: Record<string, KanbanCardObj>
    // members: Member[]
    myRows: any[]
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: any) => void
    allLabels: OppLabel[]
}

// DataGridDetail: Creates and returns a stylized DataGrid using given props
const DataGridDetail: FC<DataGridDetailProps> = (props) => {
    // Contexts
    const mainCtx = useMainContext();
    const keystoneCtx = useKeystoneContext();
    const tempTheme = useTheme() // fetches current theme being used
    const currencySymbol = currencySwitcher(mainCtx.activeOrganization.name)
    // Column that contains the keys of the fields used in the grid as strings
    // Basically only used when making the data grid Columns to ensure there are no duplicates,
    // having it as a Hook might be unnecessary
    const [myColumns, setMyColumns] = useState<string[]>(null);
    // This one is used to define the columns in the DataGrid
    const [myGridColumns, setMyGridColumns] = useState<GridColDef[]>(null);
    // Sets whether the data is still loading or not
    const [loading, setLoading] = useState<boolean>(true);
    // These are used as the rows of the dataGrid
    const [myRows, setMyRows] = useState<any[]>(props.myRows);
    // Theme Object
    const [myTheme, setMyTheme] = useState(tempTheme)
    // Used to adjust the width of the grid to be bigger than the screen when screen size gets too smol
    const [gridWidthResponse, setGridWidthResponse] = useState<string>("100%")
    // Members from org data, used for assigned_To display
    // const [members, setMembers] = useState<Member[]>(props.members);
    // Amount of records per page of the DataGrid, I think this needs to still exist despite not using pagination for the grid
    const [pageSize, setPageSize] = React.useState<number>(100);

    //All labels
    const [allLabels,setAllLabels] = useState<OppLabel[]>(props.allLabels)
    
    // const [statusOptions, setStatusOptions] = useState<Record<string, string>>({})
    //
    // if (useKeystoneContext().statusCardOptions) {
    //     const transformedOptions: Record<string, string> = {};
    //     useKeystoneContext().statusCardOptions.forEach((option) => {
    //         transformedOptions[option.id] = option.name;
    //     });
    //
    //     // Update the state with the transformed options
    //     console.log("transformedOptions", transformedOptions)
    //     setStatusOptions(transformedOptions);
    //     console.log("statusOptionsSet")
    // }
    
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    }, [])


    useLayoutEffect(() => {
        makeColumns();
        handleResize()
    }, []);

    useEffect(() => {
        if (myColumns != null && loading == true) {
            setLoading(false)

        }
    }, [myColumns, mainCtx.members])

    function refreshRows() {
        setLoading(true)
        let tempRows = myRows
        setMyRows(null)
        setMyRows(tempRows)
        setLoading(false)
    }

    // getDetailPanelContent: The expand button content for the DataGrid rows
    const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(({row}) =>
        <DetailPanelContent row={row} members={mainCtx.members} refreshRows={refreshRows} labelOptions={allLabels}/>, []);

    // getDetailPanelHeight: For the height of the detail Panel
    const getDetailPanelHeight = React.useCallback(() => 520, []);

    // Makes the DataGrid table width exceed screen size when screen gets too small
    const handleResize = () => {
        if (window.innerWidth <= 500) {
            setGridWidthResponse("250%")
            return;
        }
        if (window.innerWidth <= 800) {
            setGridWidthResponse("200%")
            return;
        }
        if (window.innerWidth > 800 && window.innerWidth <= 1100) {
            setGridWidthResponse("150%")
            return;
        }
        if (window.innerWidth > 1100) {
            setGridWidthResponse("100%")
            return;
        }
    }

    // TODO Might be better to use the colmap for this than extracting from the levelfilters/v0
    // makeColumns: Initializes the GridColDef array for the DataGrid to use for organizing the data
    const makeColumns = () => {
        let tempColumns = []
        let gridColumns: GridColDef[] = []
        let rowLables: OppLabel[] = allLabels.concat(keystoneCtx.completedOptions)
        // we check like 10 cards, and if they all have blank for a ting then we don't include that column,
        // we could go through all the cards, but that seems unnecessary
        for (let i = 0; keystoneCtx.levelMode ? i < (props.myRows).length : i < (props.myRows).length; i++) {
            for (const [key, value] of Object.entries((props.myRows)[i])) {
                if (value != null) {
                    // if (value != "" && value != "-") {
                    if (ListViewDefaultDisplayValues.indexOf(key) != -1) {
                        if (tempColumns.indexOf(key) == -1 && ListViewIgnore.indexOf(key) == -1) {
                            tempColumns.push(key)
                            if (key == "mValue") {
                                gridColumns.push(BuildColumnObject(key, value, mainCtx.members, rowLables, currencySymbol, false, false, "right"))
                            } else {
                                gridColumns.push(BuildColumnObject(key, value, mainCtx.members,rowLables, currencySymbol, false, false, "left"))
                            }
                        }
                    } else if (tempColumns.indexOf(key) == -1 && ListViewIgnore.indexOf(key) == -1) {
                        tempColumns.push(key)
                        gridColumns.push(BuildColumnObject(key, value, mainCtx.members,rowLables, currencySymbol, false, true))
                    }
                    // }
                }
            }
        }
        setMyGridColumns(gridColumns)
        setMyColumns(tempColumns)
    }

    return (
        <React.Fragment>
            {/*<ThemeProvider theme={myTheme}*/}
            <Paper sx={{width: gridWidthResponse, height: "84vh"}}>
                {loading ? <Loader/>
                    :
                    <StyledDataGrid
                        columns={myGridColumns}
                        rows={myRows}
                        getRowHeight={() => 'auto'}
                        getEstimatedRowHeight={() => 1000 / pageSize}
                        components={{
                            Toolbar: GridToolbar,
                            DetailPanelExpandIcon: ExpandMore,
                            DetailPanelCollapseIcon: ExpandLess,
                        }}
                        componentsProps={{
                            panel: {
                                sx: {
                                    '& .MuiDataGrid-panelWrapper': {
                                        backgroundColor: myTheme.palette.background.default,
                                        margin: 0.2,
                                        padding: 0.2
                                    },
                                    // replaces scroll bar
                                    '& .MuiDataGrid-panelContent': {
                                        overflowX: "hidden",
                                        paddingRight: 2,
                                        "&::-webkit-scrollbar": {
                                            width: 10
                                        },
                                        "&::-webkit-scrollbar-track": {
                                            backgroundColor: myTheme.palette.background.paper,
                                            borderRadius: 1,
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: myTheme.palette.text.secondary,
                                            borderRadius: 2,
                                        }
                                    }
                                }
                            }
                        }}
                        disableDensitySelector={true}
                        getDetailPanelHeight={getDetailPanelHeight}
                        getDetailPanelContent={getDetailPanelContent}
                        checkboxSelection={props.selectCards}
                        selectionModel={props.selectedCardsIDs}
                        onSelectionModelChange={(newSelectionModel) => {
                            // console.log(newSelectionModel)
                            // console.log(props.selectedCardsIDs)
                            let tempSelectedNames: string[] =[]
                            props.updateSelectedCards(newSelectionModel);
                            for (let i = 0; i < newSelectionModel.length; i++) {
                                // console.log(props.cards[newSelectionModel[i]].name)
                                tempSelectedNames.push(props.cards[newSelectionModel[i]].name)
                            }
                            keystoneCtx.setSelectedCardsNames(tempSelectedNames)
                        }}
                        disableSelectionOnClick={!props.selectCards}
                        // experimentalFeatures={{ rowGrouping: true }}
                        // disableVirtualization={true}
                        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        // rowsPerPageOptions={[10]}
                        // rowCount={pageSize}
                        // autoPageSize={true}
                        pageSize={pageSize}
                        // pagination
                    />
                }
            </Paper>
            {/*</ThemeProvider>*/}
        </React.Fragment>
    );
}

/**********************************************************************************************************************
 * Helper Functions
 * Todo: Move everything below this line into a utils folder
 **********************************************************************************************************************/

interface RendCellProps {
    params: GridRenderCellParams
    members: Member[]
    currencySymbol?: string
    labels: OppLabel[]
}

// RendCell: Handles the rendering of the DataGrid cells based on passed values
const RendCell: FC<RendCellProps> = (props) => {
    // mValue cell
    const mainCtx = useMainContext();
    if (props.params.field == "mValue") {
        return <span style={{overflow: "hidden", textOverflow: "ellipsis"}}>
            <Tooltip title={GetCellTooltip(props.params, props.members)}>
                <div> {props.currencySymbol + " " + props.params.value} </div>
            </Tooltip>
        </span>
    }

    // console.log("props", props)
    if (props.params.value == "" || props.params.value == null) {
        return <Tooltip title={"empty"}>
            <div>-</div>
        </Tooltip>
    }

    // assignTo custom cell
    if (props.params.field == "assignedTo") {
        {
            // console.log(props.params.value)
        }
        // return <Tooltip title={GetCellTooltip(props.params, props.members)}>
        //     {MakeValueNice(props.params.field, props.params.value, props.members)}
        return <FormatUsers k={props.params.field} v={props.params.value} m={props.members}
                            key={"assigned"}></FormatUsers>
        // </Tooltip>
    }
    // Custom Cell for dates
    if (props.params.field == "createdOn" || props.params.field == "due") {
        return <Tooltip title={GetCellTooltip(props.params, props.members)}>
            <Box sx={{display: "flex"}}>
                <CalendarTodayOutlined fontSize={"small"} color={"info"}
                                       sx={{mr: 1}}/>
                <div> {props.params.value} </div>
            </Box>
        </Tooltip>
    }
    // Custom chip Cell
    if (props.params.field == "status" || props.params.field == "resolved" || props.params.field == "repeat" || props.params.field == "active") {
        const [statusOptions, setStatusOptions] = useState<Record<string, string>>(useKeystoneContext().statusCardOptions)
        
        if (props.params.row.status && useKeystoneContext().statusCardOptions) {
            const [value, setValue] = useState<string>(props.params.row.status)
            const [loading, setLoading] = useState<boolean>(false)
            const handleStatusChange = (newValue) => {
                const updateOne = async (): Promise<any> => {
                    try{
                        return await KanbanStore.UpdateMultiple({
                            organisation: props.params.row.client,
                            columnName: "list_id",
                            valueChange: statusOptions[newValue],
                            ID: `('${props.params.row.id}')`,
                            UserId: mainCtx.user.id,
                        }).then((r) => {
                            // TODO: Needs to look at context to find card assignees
                            // NotificationStore.CreateOne({
                            //     ClientName: mainCtx.activeOrganization.name,
                            //     UserId: mainCtx.user.id,
                            //     CardId: `('${props.params.row.id}')`,
                            //     ActionType: "Updated",
                            //     Description: "",
                            //     Platform: "Internal",
                            //     IsRead: false
                            // })
                            console.log("r", r)
                            setLoading(false)
                            return r;
                        });
                    } catch (e) {
                        console.error(`${e}`);
                    }
                }
                updateOne()
                props.params.row.status = newValue
                // console.log("Chip.props.params", props.params.row.status, typeof props.params.row.status)
                // console.log("newValue", newValue)
            };
            
            
            // Travis
            return (
                <Tooltip title={GetCellTooltip(props.params, props.members, props.currencySymbol)}>
                    {!loading? <Autocomplete
                        disablePortal
                        disableClearable={true}
                        id={`"${props.params.row.status}"`}
                        options={Object.keys(statusOptions)}
                        value={value}
                        onChange={(event: any, newValue: any) => {
                            setLoading(true)
                            setValue(newValue);
                            // setStatusSelected(newValue.valueOf)
                            handleStatusChange(newValue)
                        }}
                        sx={{
                            width: 500,
                        }}
                        // renderInput={(params) => <TextField {...params}/>}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    style: {
                                        fontSize: '10px', // Set font size here for the input text
                                    },
                                }}
                            />
                        )}
                    /> : <CircularProgress color="inherit" sx={{marginX: "30%"}}/>}
                </Tooltip>
            )
        }
    }

    //Custom for Opportunity Labels
    if (props.params.field == "opportunityLabels") {
        //console.log("RendCell DataGridDetail",props.params.value,props.labels )
        return <FormatLabels field={props.params.field} value={props.params.value} oppLabels={props.labels}   theme={useTheme()}
                            key={"oppLabels"}></FormatLabels>
    }

    // Basic Cell
    return <Tooltip title={GetCellTooltip(props.params, props.members)}>
        <div> {props.params.field.includes("Value") != false || props.params.field.includes("Price") != false ? props.currencySymbol + " " + props.params.value : props.params.value} </div>
    </Tooltip>
}

// makeHeaderString: Formats the field header to display without camelCase or snake_case
// Can also change what is displayed based on specific field names
const makeHeaderString = (s: string) => {
    if (s == "v0") {
        return "Additional"
    }
    if (s == "due") {
        return "Goal Date"
    }
    if (s == "createdOn") {
        return "Start Date"
    }
    s = s.replace(/([A-Z])/g, ' $1').trim()
    s = s.replaceAll("_", " ")
    s = s.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
    return s
}

// GetColType: Returns whether the column is a date, number or string
const GetColType = (k: string, v: string) => {
    if (k == "due" || k == "createdOn") {
        return "date"
    }
    if (k == "repeat" || k == "mValue") {
        return "number"
    }
    return "string"
}

// GetColFlex: Used to set how much of the data grid width is dedicated to each field in the data grid
// Works great when the default values are displayed, once more columns are added users can just manually adjust it to
// their needs
const GetColFlex = (k: string, v: string) => {
    let tempType = GetColType(k, v)
    let tLength = 0
    if (tempType == "date") {
        return 0.3;
    }
    if (k == "name") {
        return 0.25;
    }
    if (tempType == "number") {
        return 0.2;
    }
    if (k == "resolved" || k == "status" || k == "assignedTo") {
        return 0.3;
    }
    if (k == "active") {
        return 0.25;
    }

    if (k == "opportunityType" || k == "opportunityLabels") {
        return 0.35;
    }
    if (k == "solution") {
        return 0.55;
    }
    if (k == "description") {
        return 0.65;
    }

    if (tempType == "string") {
        tLength = v.length
        if (tLength > 20) {
            return 0.6;
        }
    }
    return 0.5;
}

// GetCellTooltip: Creates custom or default tooltip titles based on passed props
const GetCellTooltip = (props, members?: Member[], currencySymbol?: string) => {
    //AssignedTo
    if (props.field == "assignedTo") {
        // let tempAssigns = JSON.parse(`{"AssignedTo": ${props.value} }`)['AssignedTo']
        // // console.log("assignedTo", props.value)
        // if (props.value == "" || !members) {
        //     return <div>No User Assigned</div>
        // } else if (members[props.value.replace("auth0|", "")] == null && props.value != "") {
        //     return <div>No User Assigned<br/>Uid: {props.value} is provided, but user not found</div>
        // }
        // return <div>Name: {members[props.value.replace("auth0|", "")].name}<br/>
        //     Email: {members[props.value.replace("auth0|", "")].email}<br/>
        //     MetaData: {members[props.value.replace("auth0|", "")].user_metadata}</div>

        return ""
    }
    // Repeat value and field
    if (props.field == "repeat" || props.value == "Repeat") {
        return <div>Repeats: {props.row.repeat}<br/>M
            Value: {currencySymbol + " " + props.row.mValue}<br/>Total: {currencySymbol + " " + props.row.mValue * props.row.repeat}
        </div>
    }
    // dates
    if (props.field == "due" || props.field == "createdOn") {
        // let overdue = false
        let today = new Date()
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let tempCreated = new Date(Date.parse(props.row.createdOn)).toISOString().slice(0, 10)
        let tempDue = new Date(Date.parse(props.row.due)).toISOString().slice(0, 10)
        let tempDueDays = dateConverter(date, tempDue)
        let overdueString = ' in ' + tempDueDays + ' day(s) '
        if (tempDueDays == 0) {
            tempDueDays = dateConverter(tempDue, date)
            overdueString = " " + tempDueDays + " day(s) ago "
            // overdue = true
        }
        let tempCreatedDays = dateConverter(tempCreated, date)
        return <div>Start Date: {tempCreated} ( {tempCreatedDays} day(s) ago )
            <br/>Goal: {tempDue} ({overdueString})</div>
    }


    return <div>{makeHeaderString(props.field)}:<br/>{props.value}</div>
}

// BuildColumnObject: Uses passed parameters to create and then return a GridColDef object
export function BuildColumnObject(key: string, value: any, members: Member[], labels:OppLabel[], currencySymbol: string, hideSortIcons: boolean, hideByDefault: boolean, contentAlign?: GridAlignment): GridColDef {
    if (key == "createdOn" || key == "due") {
        return {
            field: key,
            type: GetColType(key, value.toString()),
            valueGetter: ({row}) => {
                return new Date(Date.parse(row[key])).toISOString().slice(0, 10)
            },
            align: contentAlign != null ? contentAlign : "left",
            hideSortIcons: hideSortIcons,
            hide: hideByDefault,
            description: makeHeaderString(key),
            headerName: makeHeaderString(key),
            flex: GetColFlex(key, value.toString()),
            sortingOrder: ['desc', 'asc'],
            renderCell: (params) => <RendCell params={params} members={members} labels={labels}/>
        }
    }

    return {
        field: key,
        type: GetColType(key, value.toString()),
        headerName: makeHeaderString(key),
        description: makeHeaderString(key),
        flex: GetColFlex(key, value.toString()),
        sortingOrder: ['desc', 'asc'],
        align: contentAlign != null ? contentAlign : "left",
        hideSortIcons: hideSortIcons,
        hide: hideByDefault,
        renderCell: (params) => <RendCell params={params} members={members} labels={labels}
                                          currencySymbol={currencySymbol}/>
    }
}

// GetChipProps: I feel like this can be simplified somehow
// But for now it is long, but basically just returns a colorful chip for the DataGrid fields that want one
function GetChipProps(params: GridRenderCellParams, theme: Theme) {
    if (params.field === "repeat") {
        if (params.value != 0) {
            return {
                icon: <AutorenewOutlined style={{color: theme.palette.info.main}}/>,
                label: params.value,
                style: {
                    borderColor: theme.palette.info.main,
                    padding: 5
                }
            };
        }
        return {
            // icon: <AutorenewOutlined style={{color: theme.palette.info.main}}/>,
            label: params.value,
            style: {
                borderColor: theme.palette.success.main,
                padding: 5
            }
        };
    }
    if (params.field === "active") {
        if (params.value == "active") {
            return {
                icon: <CheckCircleOutlineOutlined style={{color: theme.palette.success.main}}/>,
                label: params.value,
                style: {
                    borderColor: theme.palette.success.main,
                    // padding: 1,
                    // paddingLeft: 10
                }
            };
        }
        return {
            icon: <AccessTimeOutlined style={{color: theme.palette.warning.main}}/>,
            // label: params.value,
            style: {
                borderColor: theme.palette.warning.main,
                padding: 5
            }

        };
    }
    if (params.value === "Repeat") {
        return {
            icon: <AutorenewOutlined style={{color: theme.palette.info.main}}/>,
            label: params.value,
            // color: "info",
            style: {
                borderColor: theme.palette.info.main,
                // color: "error",
                padding: 5
            }
        };
    }
    if (params.value === "In Progress") {
        return {
            icon: <AccessTimeOutlined style={{color: theme.palette.success.main}}/>,
            label: params.value,
            style: {
                borderColor: theme.palette.success.main,
                padding: 5
            }
        };
    }
    if (params.value === "QA") {
        return {
            icon: <AssessmentOutlined style={{color: theme.palette.success.main}}/>,
            label: params.value,
            style: {
                borderColor: theme.palette.success.main,
                padding: 5
            }
        };
    }
    if (params.value === "True") {
        return {
            icon: <TaskAltOutlined style={{color: theme.palette.success.main}}/>,
            // label: params.value,
            style: {
                borderColor: theme.palette.success.main,
                padding: 5
            }
        };
    }
    if (params.value === "False") {
        return {
            icon: <BlockOutlined style={{color: theme.palette.warning.main}}/>,
            label: params.value,
            style: {
                borderColor: theme.palette.warning.main,
                padding: 5
            }
        };
    }
    return {
        icon: <NewReleasesOutlined style={{color: theme.palette.primary.main}}/>,
        label: params.value,
        style: {
            borderColor: theme.palette.primary.main,
            padding: 5
        }
    };

}

interface UserAssignStyleProps {
    k: string
    m: Member[]
    v: string
}

// FormatUsers: Formats and returns the users for display
const FormatUsers: FC<UserAssignStyleProps> = (props) => {

    if (props.k != "assignedTo" || !props.m) {
        return <div>{props.v}</div>
    }
    if (props.v == "" || props.v.length < 5) {
        return <div>No User Assigned</div>
    }

    try {
        let test = JSON.parse(`{"AssignedTo": ${props.v} }`)['AssignedTo']
    } catch (e) {
        // console.log(props.v)
        return <Tooltip title={"empty"}>
            <div>-</div>
        </Tooltip>
    }

    // This creates a JSON string and extracts just the user array from the parsed JSON
    // The "AssignedTo" string could be anything, it's just there to extract the json things
    let tempAssigns = JSON.parse(`{"AssignedTo": ${props.v} }`)['AssignedTo']
    let userMap: Member[] = []
    for (let i = 0; i < Object.values(props.m).length; i++) {
        userMap[props.m[i].id] = props.m[i]
    }
    // console.log(tempAssigns)
    // console.log(props)
    return <div>
        {tempAssigns.map((val, index) => (
            userMap[val] != null &&
            <Tooltip
                title={<div>Name: {userMap[val].displayName}<br/>
                    Email: {userMap[val].email}</div>}
                key={`Tooltip_${index}`}
            >
                <div>
                    <CardHeader
                        avatar={
                            <Avatar
                                src={userMap[val].avatar}
                                alt={userMap[val].displayName}
                                variant={"circular"}
                                sx={{width: 24, height: 24}}
                            />
                        }
                        title={userMap[val].displayName} disableTypography={true}
                        sx={{padding: 0}}
                        key={`AssignedUser_${index}`}
                    >
                    </CardHeader>
                </div>
            </Tooltip>
        ))}
    </div>
    //<div>{m[v.replace("auth0|", "")].name}</div>
}

interface OppLabelsDisplayProps {
    field: string
    oppLabels: OppLabel[]
    value: string
    theme: Theme
}

const FormatLabels: FC<OppLabelsDisplayProps> = (props) => {

    if (props.field != "opportunityLabels" || !props.oppLabels) {
        return <div>No Labels</div>
    }
    if (props.value == "" || props.value.length < 5) {
        return <div>No Labels</div>
    }

    try {
        let test = JSON.parse(`{"opportunityLabels": ${props.value} }`)['opportunityLabels']
    } catch (e) {
        // console.log(props.v)
        return <Tooltip title={"empty"}>
            <div></div>
        </Tooltip>
    }

    // This creates a JSON string and extracts just the user array from the parsed JSON
    // The "AssignedTo" string could be anything, it's just there to extract the json things
    let tempOppLabels = JSON.parse(`{"opportunityLabels": ${props.value} }`)['opportunityLabels']
    let labelMap: OppLabel[] = []
    for (let i = 0; i < Object.values(props.oppLabels).length; i++) {
        labelMap[props.oppLabels[i].id] = props.oppLabels[i]
    }
    // console.log(tempAssigns)
    // console.log(props)
    tempOppLabels.map((val, index)=>{console.log("Valindex", val, index)})
    return <div>
        {tempOppLabels.map((val, index) => (
            labelMap[val] != null &&
            <Tooltip
                title={<div>Name: {labelMap[val].label}</div>}
                key={`OppTooltip_${index}`}
            >
                <div style={{marginTop:3}}>
                    <Chip variant="outlined"
                          size="small"
                          icon={<Label style={{color: props.theme.palette.primary.main}}/>}
                          label={labelMap[val].label}
                          style={{
                            borderColor: props.theme.palette.primary.main,
                            padding:5
                          }}

                    />
                </div>
            </Tooltip>
        ))}
    </div>

}

// dateConverter: Returns amount of days that has passed from the start date to the end date
const dateConverter = (startDate, timeEnd) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    const one_day = 1000 * 60 * 60 * 24;
    let result
    result = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / (one_day))
    // console.log('date Converter result', result)
    if (result < 0) {
        return 0
    }
    return result
}


export default DataGridDetail;