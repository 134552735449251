import React, {useEffect, useState} from "react";
import LandingPagePie from "./widgets/OverviewPie";
import OverviewColumn from "./widgets/OverviewColumn";
import OverviewTable from "./widgets/OverviewTable";
import OverviewArea from "./widgets/OverviewArea";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  LinearProgress, ToggleButtonGroup, ToggleButton, Alert
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import {useTheme} from "@mui/material/styles";
import { useMainContext } from "../../../../../contexts/MainContext";
import { KanbanStore } from "../../../../../cog/keystone/card";
import HomeIssueSkeleton from "../../summary/Skeleton";
import { useKeystoneContext } from "../../../../../contexts/KeystoneContext";
import FilterAsDrawer from "../../filter/FilterAsDrawer";
import ReactGA from "react-ga4";
import {BubbleChart, TableChart} from "@mui/icons-material";
import OverviewSwitchTable from "./widgets/OverviewSwitchTable";
import {
  ConvertTreeFromBeingUsedToApiSave,
  GenerateNewPsuTree
} from "../../productstoreuniverse/utility/NodeTypesAndOptions";

const ExploreOverviewPage = () => {
  const mainCtx = useMainContext()
  const keystoneCtx = useKeystoneContext()
  const firstName = mainCtx.user.name.split(/\s+/)
  const timeElapsed = Date.now();
  const now = new Date(timeElapsed);
  const curHr = now.getHours()

  const [landingStatData, setLandingStatData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [tableMode1, setTableMode1] = React.useState<string | null>('graphic');
  const [tableMode2, setTableMode2] = React.useState<string | null>('graphic');
  const [tableMode3, setTableMode3] = React.useState<string | null>('graphic');
  const [tableMode4, setTableMode4] = React.useState<string | null>('graphic');
  const [tableMode5, setTableMode5] = React.useState<string | null>('graphic');
  const [tableMode6, setTableMode6] = React.useState<string | null>('graphic');


  // Sets which options to display in the toolbar
  useEffect(() => {
    if (keystoneCtx.toolBarDisplayOptions.selectOpps != false || keystoneCtx.toolBarDisplayOptions.filterToMe != false || keystoneCtx.toolBarDisplayOptions.stats != false || keystoneCtx.toolBarDisplayOptions.filter != true) {
      keystoneCtx.setToolBarDisplayOptions({filter: true, stats: false, selectOpps: false, filterToMe: false, landingDateSelector: true})
    }
  }, [keystoneCtx.toolBarDisplayOptions])

  let greetingMessage: string

  if (curHr < 12) {
    greetingMessage = "Good Morning "
  } else if (curHr < 18) {
    greetingMessage = "Good Afternoon "
  } else {
    greetingMessage = "Good Evening "
  }

  // fetchStatData: Fetches data for the stats
  const fetchLandingStatData = async () => {
    setLoading(true)

    const dateSelectionStart = keystoneCtx.dateValue[0].toISOString().slice(0, 10)
    const dateSelectionEnd = keystoneCtx.dateValue[1].toISOString().slice(0, 10)
    let limit = "15"

    if (window.innerWidth <= 800) {
      limit = "5"
    }

    // const tempStartDate = "2021-10-02"
    // const tempEndDate = "2024-05-27"

    const response = await KanbanStore.LandingPageStats(
        {
          clientName: mainCtx.activeOrganization.name,
          // dateSelectionStart: tempStartDate,
          dateSelectionStart: dateSelectionStart,
          // dateSelectionEnd: tempEndDate,
          dateSelectionEnd: dateSelectionEnd,
          limit: limit,
          filters: keystoneCtx.filters,
          defaultFilters: keystoneCtx.defaultFilters,
          PSU: keystoneCtx.activePSU != null
              ? ConvertTreeFromBeingUsedToApiSave(keystoneCtx.activePSU)
              : ConvertTreeFromBeingUsedToApiSave(GenerateNewPsuTree(mainCtx.activeOrganization.name))

        })

    if (response) {
      console.log("LandingPageStatsResponse", response)
      setLandingStatData(response)
    }
    setLoading(false)
  }

  // Checks if the filter is ready to use and then fetches cards with filter
  // useEffect(() => {
  //   if (keystoneCtx.filtersReady == true && keystoneCtx.activePsuReady == true) {
  //     keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
  //   }
  // }, [keystoneCtx.filtersReady, keystoneCtx.activePsuReady])


  useEffect(() => {
    if (keystoneCtx.filtersReady == true && keystoneCtx.activePsuReady == true) {
      fetchLandingStatData()
    }
  }, [mainCtx.activeOrganization.name, keystoneCtx.dateValue, keystoneCtx.flipSwitchForCards])

  useEffect(() => {

    ReactGA.event("keystone_view" ,{
      userID: mainCtx.user.id,
      orgID: mainCtx.activeOrganization.id,
      pageName: 'Explore Overview Page'
    });
  }, [mainCtx.activeOrganization])

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode1(newMode);
  };
  const handleAlignment2 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode2(newMode);
  };
  const handleAlignment3 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode3(newMode);
  };
  const handleAlignment4 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode4(newMode);
  };
  const handleAlignment5 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode5(newMode);
  };
  const handleAlignment6 = (event: React.MouseEvent<HTMLElement>, newMode: string | null,) => {
    setTableMode6(newMode);
  };


  return (
      <>
        <Helmet>
          <title>Explore Overview</title>
        </Helmet>
        <Box sx={{mt: 1}}>
          <Box
              sx={{
                flexGrow: 1,
                py: 1
              }}
          >
            <Container maxWidth={false}>
              <Box marginLeft={0}>
                <FilterAsDrawer
                    setFilters={keystoneCtx.setFilters}
                    setLevelMode={keystoneCtx.setLevelMode}
                    filters={keystoneCtx.filters}
                    open={keystoneCtx.filterOpen}
                    setOpen={keystoneCtx.setFilterOpen}
                    onClose={keystoneCtx.handleFilterClose}
                    client={mainCtx.activeOrganization.name}
                    organizationId={mainCtx.activeOrganization.id}
                    userID={mainCtx.user.id}
                    onClear={keystoneCtx.handleFilterClear}
                />
              </Box>
              {!loading && keystoneCtx.filtersReady ?
                <Box>
                  <Box sx={{ mb: 4 }}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                    >
                      <Grid item>
                        <Typography variant="h4">{greetingMessage + firstName[0] + ", Here Is Your Opportunity Summary"}</Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ marginTop: 3 }} />
                  </Box>
                  <Grid
                      container
                      spacing={4}
                  >
                    <Grid item lg={6} xs={12}>
                      <Card>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <CardHeader
                              title={"Value Available Per Opportunity Type"}
                          />
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <ToggleButtonGroup
                                value={tableMode1}
                                exclusive
                                onChange={handleAlignment}
                                aria-label="text alignment"
                                sx={{ mr: 1 }}
                            >
                              <ToggleButton value="graphic"
                                            sx={{padding: '8px', width: '40px', height: '40px'}}>
                                <BubbleChart fontSize="small"/>
                              </ToggleButton>
                              <ToggleButton value="table"
                                            sx={{padding: '8px', width: '40px', height: '40px'}}>
                                <TableChart fontSize="small"/>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                        <Divider />
                        <CardContent>
                          {tableMode1 === "graphic" ? (
                            landingStatData["TotalOpportunityValues"] && Object.keys(landingStatData["TotalOpportunityValues"]).length > 0 ? (
                              <LandingPagePie
                                  series={landingStatData["TotalOpportunityValues"]["Sum_Opp"]}
                                  labels={landingStatData["TotalOpportunityValues"]["opportunity_type"]}
                                  org={mainCtx.activeOrganization.name}
                              />
                            ) : (
                              <Alert
                                  severity="warning"
                                  sx={{
                                    height: 165,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: '15px',
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? useTheme().palette.background.default
                                            : "warning",
                                  }}
                              >
                                No Data Available for Selected Period & Filters
                              </Alert>
                            )
                          ) : (
                            <Box sx={{ minHeight: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                              {landingStatData["TotalOpportunityValues"] && Object.keys(landingStatData["TotalOpportunityValues"]).length > 0 ? (
                                <OverviewSwitchTable
                                    statRows={landingStatData["TotalOpportunityValues"]}
                                    chartName = 'TotalOpportunityValues'
                                />
                              ) : (
                                <Alert
                                    severity="warning"
                                    sx={{
                                      height: 165,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: '15px',
                                      backgroundColor: (theme) =>
                                          theme.palette.mode === 'dark'
                                              ? useTheme().palette.background.default
                                              : "warning",
                                    }}
                                >
                                  No Data Available for Selected Period & Filters
                                </Alert>
                              )}
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Card>
                        <CardHeader
                            title={"Priority Opportunities in Progress"}
                        />
                        <Divider />
                        <CardContent>
                          {landingStatData["HighestValueOpportunities"] && Object.keys(landingStatData["HighestValueOpportunities"]).length > 0 ?
                              <OverviewTable statRows={landingStatData["HighestValueOpportunities"]} /> : <Alert
                                  severity="warning"
                                  sx={{
                                    height: 165,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: '15px',
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? useTheme().palette.background.default
                                            : "warning",
                                  }}
                              >
                                No Data Available for Selected Period & Filters
                              </Alert>}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Card>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <CardHeader
                              title={"Opportunities By Category"}
                          />
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <ToggleButtonGroup
                                value={tableMode2}
                                exclusive
                                onChange={handleAlignment2}
                                aria-label="text alignment"
                                sx={{ mr: 1 }}
                            >
                              <ToggleButton value="graphic"
                                            sx={{padding: '8px', width: '40px', height: '40px'}}>
                                <BubbleChart fontSize="small"/>
                              </ToggleButton>
                              <ToggleButton value="table"
                                            sx={{padding: '8px', width: '40px', height: '40px'}}>
                                <TableChart fontSize="small"/>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>
                        <Divider />
                        <CardContent>
                          {tableMode2 === "graphic" ? (
                            landingStatData["OpportunitiesByCategory"] && Object.keys(landingStatData["OpportunitiesByCategory"]).length > 0 ? (
                              <OverviewColumn
                                  key={0} header={keystoneCtx.colMap.Product_Hierarchy_1}
                                  data={landingStatData["OpportunitiesByCategory"]}
                                  labelValue={keystoneCtx.colMap.Product_Hierarchy_1}
                                  org={mainCtx.activeOrganization.name}
                              />
                            ) : (
                              <Alert
                                  severity="warning"
                                  sx={{
                                    height: 165,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: '15px',
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? useTheme().palette.background.default
                                            : "warning",
                                  }}
                              >
                                No Data Available for Selected Period & Filters
                              </Alert>
                            )
                          ) : (
                            <Box sx={{ minHeight: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                              {landingStatData["OpportunitiesByCategory"] && Object.keys(landingStatData["OpportunitiesByCategory"]).length > 0 ? (
                                <OverviewSwitchTable
                                    statRows={landingStatData["OpportunitiesByCategory"]}
                                    chartName = 'OpportunitiesByCategory'
                                />
                              ) : (
                                <Alert
                                    severity="warning"
                                    sx={{
                                      height: 165,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: '15px',
                                      backgroundColor: (theme) =>
                                          theme.palette.mode === 'dark'
                                              ? useTheme().palette.background.default
                                              : "warning",
                                    }}
                                >
                                  No Data Available for Selected Period & Filters
                                </Alert>
                              )}
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    {mainCtx.activeOrganization.name !== "DKSH_HK" ?
                      <Grid item lg={6} xs={12}>
                        <Card>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <CardHeader
                                title={"Opportunities By Area"}
                            />
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                              <ToggleButtonGroup
                                  value={tableMode3}
                                  exclusive
                                  onChange={handleAlignment3}
                                  aria-label="text alignment"
                                  sx={{ mr: 1 }}
                              >
                                <ToggleButton value="graphic"
                                              sx={{padding: '8px', width: '40px', height: '40px'}}>
                                  <BubbleChart fontSize="small"/>
                                </ToggleButton>
                                <ToggleButton value="table"
                                              sx={{padding: '8px', width: '40px', height: '40px'}}>
                                  <TableChart fontSize="small"/>
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                          <Divider />
                          <CardContent>
                            {tableMode3 === "graphic" ? (
                                landingStatData["OpportunitiesByArea"] && Object.keys(landingStatData["OpportunitiesByArea"]).length > 0 ? (
                                    <OverviewColumn key={1} header={keystoneCtx.colMap.Area_Hierarchy_1}
                                                    data={landingStatData["OpportunitiesByArea"]}
                                                    labelValue={keystoneCtx.colMap.Area_Hierarchy_1}
                                                    org={mainCtx.activeOrganization.name}
                                    />
                                ) : (
                                    <Alert
                                        severity="warning"
                                        sx={{
                                          height: 165,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginBottom: '15px',
                                          backgroundColor: (theme) =>
                                              theme.palette.mode === 'dark'
                                                  ? useTheme().palette.background.default
                                                  : "warning",
                                        }}
                                    >
                                      No Data Available for Selected Period & Filters
                                    </Alert>
                                )
                            ) : (
                                <Box sx={{ minHeight: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                                  {landingStatData["OpportunitiesByArea"] && Object.keys(landingStatData["OpportunitiesByArea"]).length > 0 ? (
                                      <OverviewSwitchTable
                                          statRows={landingStatData["OpportunitiesByArea"]}
                                          chartName = 'OpportunitiesByArea'
                                      />
                                  ) : (
                                      <Alert
                                          severity="warning"
                                          sx={{
                                            height: 165,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: '15px',
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? useTheme().palette.background.default
                                                    : "warning",
                                          }}
                                      >
                                        No Data Available for Selected Period & Filters
                                      </Alert>
                                  )}
                                </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid> : null}
                    {mainCtx.activeOrganization.name !== "PEPSICO" && mainCtx.activeOrganization.name !== "PEPSICO_NIGERIA" && mainCtx.activeOrganization.name !== "PEPSICO_TANZANIA" && mainCtx.activeOrganization.name !== "PEPSICO_MOZAMBIQUE" && mainCtx.activeOrganization.name !== "BEVCO" && mainCtx.activeOrganization.name !== "KIMBERLY_CLARK" && mainCtx.activeOrganization.name !== "CASALESPNP" && mainCtx.activeOrganization.name !== "CASALESPNP_UNILEVER" && mainCtx.activeOrganization.name !== "CASALESPNP_UNILEVER_TOP10STORES100SKUS" ? (
                      <Grid item lg={6} xs={12}>
                        <Card>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <CardHeader
                                title={
                                  mainCtx.activeOrganization.name === "DKSH" ||
                                  mainCtx.activeOrganization.name === "DKSH_HK" ||
                                  mainCtx.activeOrganization.name === "DKSH_MO"
                                      ? "Opportunities By Channel"
                                      : "Opportunities By Buying Group"
                                }
                            />
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                              <ToggleButtonGroup
                                  value={tableMode4}
                                  exclusive
                                  onChange={handleAlignment4}
                                  aria-label="text alignment"
                                  sx={{ mr: 1 }}
                              >
                                <ToggleButton
                                    value="graphic"
                                    sx={{ padding: '8px', width: '40px', height: '40px' }}
                                >
                                  <BubbleChart fontSize="small" />
                                </ToggleButton>
                                <ToggleButton
                                    value="table"
                                    sx={{ padding: '8px', width: '40px', height: '40px' }}
                                >
                                  <TableChart fontSize="small" />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                          <Divider />
                          <CardContent>
                            {tableMode4 === "graphic" ? (
                                landingStatData["OpportunitiesByBuyingGroup"] && Object.keys(landingStatData["OpportunitiesByBuyingGroup"]).length > 0 ? (
                                    <OverviewColumn
                                        key={2}
                                        header={keystoneCtx.colMap.Customer_Hierarchy_1}
                                        data={landingStatData["OpportunitiesByBuyingGroup"]}
                                        labelValue={keystoneCtx.colMap.Customer_Hierarchy_1}
                                        org={mainCtx.activeOrganization.name}
                                    />
                                ) : (
                                    <Alert
                                        severity="warning"
                                        sx={{
                                          height: 165,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginBottom: '15px',
                                          backgroundColor: (theme) =>
                                              theme.palette.mode === 'dark'
                                                  ? useTheme().palette.background.default
                                                  : "warning",
                                        }}
                                    >
                                      No Data Available for Selected Period & Filters
                                    </Alert>
                                )
                            ) : (
                                <Box sx={{ minHeight: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                                  {landingStatData["OpportunitiesByBuyingGroup"] &&
                                  Object.keys(landingStatData["OpportunitiesByBuyingGroup"]).length > 0 ? (
                                      <OverviewSwitchTable
                                          statRows={landingStatData["OpportunitiesByBuyingGroup"]}
                                          chartName="OpportunitiesByBuyingGroup"
                                      />
                                  ) : (
                                      <Alert
                                          severity="warning"
                                          sx={{
                                            height: 165,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: '15px',
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? useTheme().palette.background.default
                                                    : "warning",
                                          }}
                                      >
                                        No Data Available for Selected Period & Filters
                                      </Alert>
                                  )}
                                </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : null}
                    {mainCtx.activeOrganization.name !== "KIMBERLY CLARK" && mainCtx.activeOrganization.name !== "LOGICO" && mainCtx.activeOrganization.name !== "CASALESPNP" && mainCtx.activeOrganization.name !== "CASALESPNP_UNILEVER" && mainCtx.activeOrganization.name !== "CASALESPNP_UNILEVER_TOP10STORES100SKUS" && mainCtx.activeOrganization.name !== "PEPSICO" && mainCtx.activeOrganization.name !== "PEPSICO_NIGERIA" && mainCtx.activeOrganization.name !== "PEPSICO_TANZANIA" && mainCtx.activeOrganization.name !== "PEPSICO_MOZAMBIQUE"  && mainCtx.activeOrganization.name !== "BEVCO" && mainCtx.activeOrganization.name !== "KIMBERLY_CLARK" ?
                      <Grid item lg={6} xs={12}>
                        <Card>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                          <CardHeader
                              title={mainCtx.activeOrganization.name === "DKSH" || mainCtx.activeOrganization.name === "DKSH_HK" || mainCtx.activeOrganization.name === "DKSH_MO" ? "Opportunities By Customer Group" : "Opportunities By Buying Sub Group"}
                          />
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                              <ToggleButtonGroup
                                  value={tableMode5}
                                  exclusive
                                  onChange={handleAlignment5}
                                  aria-label="text alignment"
                                  sx={{ mr: 1 }}
                              >
                                <ToggleButton value="graphic"
                                              sx={{padding: '8px', width: '40px', height: '40px'}}>
                                  <BubbleChart fontSize="small"/>
                                </ToggleButton>
                                <ToggleButton value="table"
                                              sx={{padding: '8px', width: '40px', height: '40px'}}>
                                  <TableChart fontSize="small"/>
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                          <Divider />
                          <CardContent>
                            {tableMode5 === "graphic" ? (
                            landingStatData["OpportunitiesByFranchiseGroup"] && Object.keys(landingStatData["OpportunitiesByFranchiseGroup"]).length > 0 ? (
                                <OverviewColumn
                                    key={3}
                                    header={keystoneCtx.colMap.Customer_Hierarchy_2}
                                    data={landingStatData["OpportunitiesByFranchiseGroup"]}
                                    labelValue={keystoneCtx.colMap.Customer_Hierarchy_2}
                                    org={mainCtx.activeOrganization.name}
                                />
                            ) : (
                                <Alert
                                    severity="warning"
                                    sx={{
                                      height: 165,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: '15px',
                                      backgroundColor: (theme) =>
                                          theme.palette.mode === 'dark'
                                              ? useTheme().palette.background.default
                                              : "warning",
                                    }}
                                >
                                  No Data Available for Selected Period & Filters
                                </Alert>
                            )
                            ) : (
                                <Box sx={{ minHeight: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                                  {landingStatData["OpportunitiesByFranchiseGroup"] &&
                                  Object.keys(landingStatData["OpportunitiesByFranchiseGroup"]).length > 0 ? (
                                      <OverviewSwitchTable
                                          statRows={landingStatData["OpportunitiesByFranchiseGroup"]}
                                          chartName="OpportunitiesByFranchiseGroup"
                                      />
                                  ) : (
                                      <Alert
                                          severity="warning"
                                          sx={{
                                            height: 165,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: '15px',
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? useTheme().palette.background.default
                                                    : "warning",
                                          }}
                                      >
                                        No Data Available for Selected Period & Filters
                                      </Alert>
                                  )}
                                </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid> : null}
                    {mainCtx.activeOrganization.name === "WUTOW" || mainCtx.activeOrganization.name === "CASALES" || mainCtx.activeOrganization.name === "LOGICO" || mainCtx.activeOrganization.name === "DKSH_HK" || mainCtx.activeOrganization.name === "DKSH_MO" ?
                      <Grid item lg={mainCtx.activeOrganization.name === "DKSH_HK" || mainCtx.activeOrganization.name === "DKSH_MO" || mainCtx.activeOrganization.name === "LOGICO" ? 6 : 12} xs={12}>
                        <Card>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                          <CardHeader
                              title={mainCtx.activeOrganization.name === "DKSH_HK" || mainCtx.activeOrganization.name === "DKSH_MO" ? "Opportunities By Client" : "Opportunities By Supplier"}
                          />
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                              <ToggleButtonGroup
                                  value={tableMode6}
                                  exclusive
                                  onChange={handleAlignment6}
                                  aria-label="text alignment"
                                  sx={{ mr: 1 }}
                              >
                                <ToggleButton value="graphic" sx={{padding: '8px', width: '40px', height: '40px'}}>
                                  <BubbleChart fontSize="small"/>
                                </ToggleButton>
                                <ToggleButton value="table" sx={{padding: '8px', width: '40px', height: '40px'}}>
                                  <TableChart fontSize="small"/>
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                          <Divider />
                          <CardContent>
                            {tableMode6 === "graphic" ? (
                            landingStatData["OpportunitiesBySupplier"] && Object.keys(landingStatData["OpportunitiesBySupplier"]).length > 0 ? (
                              <OverviewColumn
                                key={3}
                                header={keystoneCtx.colMap.Overall_Hierarchy_1}
                                data={landingStatData["OpportunitiesBySupplier"]}
                                labelValue={keystoneCtx.colMap.Overall_Hierarchy_1}
                                org={mainCtx.activeOrganization.name}
                              />
                            ) : (
                              <Alert
                                severity="warning"
                                sx={{
                                  height: 165,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginBottom: '15px',
                                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? useTheme().palette.background.default : "warning",
                                }}
                              >
                                No Data Available for Selected Period & Filters
                              </Alert>
                            )
                            ) : (
                              <Box sx={{ minHeight: 500, width: '100%', overflowY: 'auto', overflowX: 'auto' }}>
                                {landingStatData["OpportunitiesBySupplier"] &&
                                Object.keys(landingStatData["OpportunitiesBySupplier"]).length > 0 ? (
                                  <OverviewSwitchTable
                                    statRows={landingStatData["OpportunitiesBySupplier"]}
                                    chartName="OpportunitiesBySupplier"
                                  />
                                ) : (
                                  <Alert
                                    severity="warning"
                                    sx={{
                                      height: 165,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: '15px',
                                      backgroundColor: (theme) => theme.palette.mode === 'dark' ? useTheme().palette.background.default : "warning",
                                    }}
                                  >
                                    No Data Available for Selected Period & Filters
                                  </Alert>
                                )}
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid> : null}
                    <Grid item lg={12} xs={12}>
                      <Card>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <CardHeader
                              title={"KeyStone Impact: NSV with & without KeyStone"}
                          />
                        </Box>
                        <Divider />
                        <CardContent>
                          {landingStatData["NSVWithKeystone"] && Object.keys(landingStatData["NSVWithKeystone"]).length > 0 ? (
                            <OverviewArea
                              data={landingStatData["NSVWithKeystone"]}
                              org={mainCtx.activeOrganization.name}
                              labelValue={"Date"}
                            />
                          ) : (
                            <Alert
                              severity="warning"
                              sx={{
                                height: 165,
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: '15px',
                                backgroundColor: (theme) =>
                                  theme.palette.mode === 'dark'
                                    ? useTheme().palette.background.default
                                    : "warning",
                              }}
                            >
                              No Data Available for Selected Period & Filters
                            </Alert>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
                :
                <Box>
                  <LinearProgress
                    sx={{
                      marginLeft: 5,
                      marginRight: 5,
                      marginTop: 2,
                      justifySelf: "center"
                    }} />
                  <HomeIssueSkeleton />
                </Box>
              }
            </Container>
          </Box>
        </Box>
      </>
  );
}

export default ExploreOverviewPage;