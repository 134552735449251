/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import {FC} from "react";
import {Box, Paper, Typography, Grid} from "@mui/material";
import BasicWidget from "../BasicWidget";
import {WidgetProps} from "../../../WidgetSwitcher";

const vertical = {


}
const LineInst: FC<WidgetProps> = ({widget}) => {
    return (
        <hr
            style={{
                color: "grey",
                backgroundColor: "grey",
                border :0,
                borderLeft: 5,
                height:"100%",
                width: 3,
                marginLeft: "50%"
            }}
        />
    );
}

const BasicVerticalLineDivider: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<LineInst />}/>
}

export default BasicVerticalLineDivider;
