/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import Dashboard from "./Dashboard";

export interface CreateOneRequest {
    name: string;
    organizationId: string;
}

export interface CreateOneResponse {
    id: string;
}

export interface UpdateOneRequest {
    oldDashboard: Dashboard;
    dashboard: Dashboard;
}

export interface UpdateOneResponse {
    id: string;
}

export interface FindOneRequest {
    ID: string
}

export interface FindOneResponse {
    dashboard: Dashboard
}

export interface FindAllRequest {
    organizationId: string
}

export interface FindAllResponse {
    dashboards: Dashboard[];
}

const Store = {
    serviceProvider: 'Dashboard-Store',
    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                dashboard: request
            }]
        });
        return {
            id: response.id,
        };
    },

    async UpdateOne(request: UpdateOneRequest): Promise<UpdateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateOne`,
            request: [{
                'dashboard': request.dashboard,
                'old_dashboard': request.oldDashboard,
            }]
        });
        return {
            id: response.id
        };
    },

    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                'ID': request.ID
            }]
        });
        return {
            dashboard: new Dashboard(response.dashboard),
        };
    },

    async FindAll(request: FindAllRequest): Promise<FindAllResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAll`,
            request: [{
                'org_id': request.organizationId
            }]
        });
        return {
            dashboards: response.dashboards.map((mem) => (new Dashboard(mem))),
        };
    }

};

export default Store;
