import React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  TextField,
} from '@mui/material';
import {Theme} from "@mui/material/styles";
import {useMainContext} from "../../contexts/MainContext";
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import makeStyles from '@mui/styles/makeStyles';
import {useSnackbar} from "notistack";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface FirebaseAuthLoginProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  googleButton: {
    backgroundColor: theme.palette.common.white
  },
  providerIcon: {
    marginRight: theme.spacing(2)
  },
  divider: {
    flexGrow: 1
  },
  dividerText: {
    margin: theme.spacing(2)
  }
}));

const LoginFirebase: FC<FirebaseAuthLoginProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const mainContext = useMainContext();
  const logInWithEmailAndPassword = mainContext.logInWithEmailAndPassword;
  const forgotPassword = mainContext.sendPasswordReset;
  const isMountedRef = useIsMountedRef();
  const {enqueueSnackbar} = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }
  const forgotPass = (email) => {
    if (isValidEmail(email)) {
      forgotPassword(email)
      enqueueSnackbar('Password reset link sent!', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
      handleClose()
    }
    else {
      enqueueSnackbar('Please enter a valid email', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'warning'
      });
    }

  }
  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        mt={2}
      >
        <Divider
          className={classes.divider}
          orientation="horizontal"
        />
      </Box>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async (values, {
          setErrors,
          setStatus,
          setSubmitting
        }) => {
          try {
            await logInWithEmailAndPassword(values.email, values.password).then((r) => {
              if (Object.values(r)[0] == "auth/user-not-found") {
                enqueueSnackbar('User does not exist', {
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  },
                  variant: 'warning'
                });
              }
              if (Object.values(r)[0] == "auth/wrong-password") {
                enqueueSnackbar('Password incorrect', {
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  },
                  variant: 'warning'
                });
              }
            });
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            //console.error(err);
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box mt={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Log In
              </Button>
            </Box>
            <Box>
              <Box sx={{mt: 2}}>
                <Divider />
              <Button variant="text" sx={{mt: 1}} onClick={handleClickOpen}>
                Forgot/Reset Password
              </Button>
              </Box>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Reset Password</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please provide your email and a password reset link will be sent to you.
                  </DialogContentText>
                  <TextField
                      required
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={() => forgotPass(values.email)}>Reset Password</Button>
                </DialogActions>
              </Dialog>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

LoginFirebase.propTypes = {
  className: PropTypes.string,
};

export default LoginFirebase;