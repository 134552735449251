import {API, PerformJSONRPCRequest} from "../../api/jsonrpc";
import {OppLabel} from "./OppLabel";


export interface CreateOneRequest {
    ClientName: string
    Label: string
    Action: string
}

export interface CreateOneResponse {
    success: boolean
    ID: string
}

export interface CreateManyRequest {
    ClientName: string
    labels: OppLabel[]
}

export interface CreateManyResponse {
    success: boolean
}

export interface FindAllRequest {
    ClientName: string
}

export interface FindAllResponse {
    Labels: OppLabel[]
}

export interface DeleteOneRequest {
    ClientName: string
    ID: string
}

export interface DeleteOneResponse {
    Deleted: boolean
}

const Store = {
    serviceProvider: 'Label-Store',

    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                "client_name": request.ClientName,
                "label": request.Label,
                "action": request.Action
            }]
        })
        return response
    },

    async CreateMany(request: CreateManyRequest): Promise<CreateManyResponse> {
        const response: CreateManyResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateMany`,
            request: [{
                "client_name": request.ClientName,
                "labels": request.labels
            }]
        })
        return response
    },

    async FindAll(request: FindAllRequest): Promise<FindAllResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAll`,
            request: [{
                "client_name": request.ClientName
            }]
        })
        return response
    },

    async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOne`,
            request: [{
                "client_name": request.ClientName,
                "id": request.ID
            }]
        })
        return response
    },
};

export default Store;