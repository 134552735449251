import {Box, Grid, Grow, Skeleton} from "@mui/material";
import React from "react";

const HomeStatsSkeleton = () => {
    return (
        <Box sx={{ marginBottom: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grow in={true}>
                        <Box sx={{
                            mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <Skeleton variant="rectangular" width={"100%"} height={230}
                                      sx={{padding: 5, borderRadius: "16px"}}/>
                        </Box>
                    </Grow>
                </Grid>
                <Grid item xs={4}>
                    <Grow in={true}>
                        <Box sx={{
                            mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <Skeleton variant="rectangular" width={"100%"} height={230}
                                      sx={{padding: 5, borderRadius: "16px"}}/>
                        </Box>
                    </Grow>
                </Grid>
                <Grid item xs={4}>
                    <Grow in={true}>
                        <Box sx={{
                            mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <Skeleton variant="rectangular" width={"100%"} height={230}
                                      sx={{padding: 5, borderRadius: "16px"}}/>
                        </Box>
                    </Grow>
                </Grid>
            </Grid>
        </Box>
    )
}
export default HomeStatsSkeleton