/* eslint-disable */

export default class EFLog {
    public org_name: string = '';
    public user_id: string = '';
    public date_actioned: string = '';
    public filter: string = '';
    public action: string = '';


    constructor(efLog?: EFLog) {
        if (!efLog) {
            return;
        }
        this.org_name = efLog.org_name;
        this.user_id = efLog.user_id;
        this.date_actioned = efLog.date_actioned;
        this.filter = efLog.filter;
        this.action = efLog.action;

    }
}