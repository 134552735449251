const ProductDetail = (props)=>{
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg viewBox="0 0 53.72517 53.70512" version="1.1" id="svg5" xmlns="http://www.w3.org/2000/svg" height={height}>
            <defs id="defs2">
                <style id="style4825" dangerouslySetInnerHTML={{__html: ".productDetailCls-1{fill:url(#productDetailBlob);}.productDetailCls-2{fill:url(#productDetailLine);}.productDetailCls-3{fill:url(#productDetailFill);}.iconWhite{fill:#fff;}.productDetailCls-5{fill:none;}.productDetailCls-6{fill:#44559b;}.productDetailCls-7{fill:#6c82d0;}" }} />
                <linearGradient id="productDetailBlob" x1="60.1" x2="160.3" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop id="stop4" offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="productDetailLine" x1="60.1" x2="160.3" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop id="stop4" offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient id="productDetailFill" x1="60.1" x2="160.3" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop id="stop4" offset={0} stopColor={fillColour} />
                </linearGradient>
            </defs>
            <g id="layer1" transform="translate(-99.956628,-70.558244)">
                <g id="g9585" transform="matrix(0.26458333,0,0,0.26458333,-30.131443,-385.13705)">
                    <g id="_15">
                        <g id="Group-279" transform="translate(-156.62853,470.79293)">
                            <path id="Contour-828" className="productDetailCls-1" d="m 827.61,1359.44 -4.17,-4.18 a 6.76,6.76 0 0 0 -9.53,0 l -2.93,2.92 c -4.15,3.9 -8.25,-0.88 -8.25,-0.88 v 0 l -4.28,-4.31 c -4.78,-4.94 0.26,-10 0.26,-10 l 23.63,-23.63 a 6.86,6.86 0 0 0 0,-9.67 l -4,-4 a 6.85,6.85 0 0 0 -9.68,0 L 796,1318.38 v 0 c -3.84,3.83 -7.35,1.84 -9.08,0.34 l -3.77,-3.8 c -4,-4.09 -3,-7.29 -1.87,-8.94 l 25.34,-25.34 a 6.92,6.92 0 0 0 0,-9.75 l -4,-4 a 6.92,6.92 0 0 0 -9.75,0 l -9,9 c -3.84,3.83 -7.74,0.12 -8.59,-0.78 l -4.15,-4.18 a 6.91,6.91 0 0 0 -9.73,0 l -25.61,25.61 a 6.23,6.23 0 0 1 -9.34,0.16 l -4.22,-4.22 a 6.73,6.73 0 0 0 -9.49,0 L 681.31,1324 a 6.73,6.73 0 0 0 0,9.49 l 4.22,4.22 a 6.14,6.14 0 0 0 0.73,0.62 c 1.4,1.6 3,4.79 -1,8.81 l -14.2,14.21 a 6.89,6.89 0 0 0 0,9.73 l 4,4 a 6.91,6.91 0 0 0 9.73,0 L 715,1344.79 c 0,0 3.38,-3.06 7.41,1 l 5.18,5.17 c 0,0 4.29,3.52 0.8,7.74 l -46.17,46.17 a 6.66,6.66 0 0 0 0,9.39 l 4.32,4.32 a 6.66,6.66 0 0 0 9.39,0 l 13.62,-13.62 c 4.09,-4.09 8.34,0.53 8.34,0.53 v 0 c 0.1,0.12 4.46,4.48 4.51,4.54 0.86,0.8 4.64,4.71 0.77,8.58 v 0 l -4.25,4.25 a 6.87,6.87 0 0 0 0,9.7 l 4,4 a 6.87,6.87 0 0 0 9.7,0 l 11.5,-11.51 c 0.47,-0.45 4.15,-3.75 8.77,0.77 l 4.18,4.17 a 6.76,6.76 0 0 0 9.53,0 l 61,-61 a 6.74,6.74 0 0 0 0.01,-9.55 z" />
                            <path id="Contour-829" className="productDetailCls-2" d="m 788.23,1451.33 a 3.16,3.16 0 1 1 3.16,3.17 3.18,3.18 0 0 1 -3.16,-3.17 z m 1.52,0 a 1.64,1.64 0 1 0 1.64,-1.65 1.64,1.64 0 0 0 -1.64,1.65 z" />
                            <path id="Contour-830" className="productDetailCls-2" d="m 816.14,1254.67 a 3.16,3.16 0 1 1 3.15,3.17 3.16,3.16 0 0 1 -3.15,-3.17 z m 1.51,0 a 1.64,1.64 0 1 0 1.64,-1.65 1.65,1.65 0 0 0 -1.64,1.65 z" />
                            <path id="Contour-831" className="productDetailCls-2" d="m 648.3,1346.41 a 3.16,3.16 0 1 1 3.15,3.17 3.16,3.16 0 0 1 -3.15,-3.17 z m 1.51,0 a 1.65,1.65 0 1 0 1.64,-1.65 1.65,1.65 0 0 0 -1.64,1.65 z" />
                            <g id="Group-280">
                                <path id="Contour-832" className="productDetailCls-3" d="m 695.91,1269.16 a 0.74,0.74 0 0 0 0.53,-0.22 l 4.8,-4.82 a 0.77,0.77 0 0 0 0,-1.07 0.75,0.75 0 0 0 -1.07,0 l -4.8,4.82 a 0.77,0.77 0 0 0 0,1.07 0.75,0.75 0 0 0 0.54,0.22 z" />
                                <path id="Contour-833" className="productDetailCls-3" d="m 700.7,1269.16 a 0.75,0.75 0 0 0 0.54,-0.22 0.77,0.77 0 0 0 0,-1.07 l -4.8,-4.82 a 0.75,0.75 0 0 0 -1.07,0 0.77,0.77 0 0 0 0,1.07 l 4.8,4.82 a 0.74,0.74 0 0 0 0.53,0.22 z" />
                            </g>
                            <g id="Group-281">
                                <path id="Contour-834" className="productDetailCls-3" d="m 671.63,1437.47 a 0.75,0.75 0 0 0 0.54,-0.22 l 4.79,-4.82 a 0.75,0.75 0 0 0 0,-1.07 0.74,0.74 0 0 0 -1.06,0 l -4.8,4.82 a 0.76,0.76 0 0 0 0.53,1.29 z" />
                                <path id="Contour-835" className="productDetailCls-3" d="m 676.43,1437.47 a 0.73,0.73 0 0 0 0.53,-0.22 0.75,0.75 0 0 0 0,-1.07 l -4.79,-4.82 a 0.76,0.76 0 0 0 -1.07,1.07 l 4.8,4.82 a 0.73,0.73 0 0 0 0.53,0.22 z" />
                            </g>
                            <g id="Group-282">
                                <path id="Contour-836" className="productDetailCls-3" d="m 845.75,1376.84 a 0.74,0.74 0 0 0 0.53,-0.22 l 4.8,-4.82 a 0.76,0.76 0 1 0 -1.07,-1.07 l -4.8,4.82 a 0.77,0.77 0 0 0 0,1.07 0.75,0.75 0 0 0 0.54,0.22 z" />
                                <path id="Contour-837" className="productDetailCls-3" d="m 850.54,1376.84 a 0.75,0.75 0 0 0 0.54,-0.22 0.77,0.77 0 0 0 0,-1.07 l -4.8,-4.82 a 0.76,0.76 0 1 0 -1.07,1.07 l 4.8,4.82 a 0.74,0.74 0 0 0 0.53,0.22 z" />
                            </g>
                            <g id="Group-283">
                                <path id="Contour-838" className="iconWhite" d="m 804.42,1393.12 h 3.35 a 1.54,1.54 0 0 1 1.54,1.53 v 3.6 a 3.33,3.33 0 0 1 -3.35,3.3 H 679.89 a 3.33,3.33 0 0 1 -3.35,-3.3 v -3.6 a 1.54,1.54 0 0 1 1.54,-1.53 z" />
                                <path id="Contour-839" className="productDetailCls-3" d="m 680.85,1400.68 c 0,0 83.29,1 88.51,-6.74 l 37.79,-0.82 c 0,0 2.16,1 2.16,3.08 0,2.08 0.55,4.71 -3.35,5.35 -3.9,0.64 -115.35,0 -115.35,0 z" />
                                <rect id="Contour-840" className="iconWhite" x="677.19" y="1294.89" width="130.97" height="98.230003" rx="3.74" />
                                <g id="Group-284">
                                    <rect id="Contour-841" className="productDetailCls-2" x="698.29999" y="1390.23" width="89.410004" height="3.02" />
                                    <polygon id="Contour-842" points="697.55,1389.48 788.46,1389.48 788.46,1393.26 786.96,1393.26 786.96,1390.98 699.05,1390.98 699.05,1393.26 697.55,1393.26 " />
                                </g>
                                <path id="Contour-843" className="productDetailCls-3" d="m 684,1384.14 c 0,0 106,4.78 106,-82.74 l 12.37,-0.25 a 3.26,3.26 0 0 1 1.81,3.06 c -0.11,2.19 -0.25,78.93 -0.25,78.93 0,0 0.3,1.67 -2.11,1.71 -2.41,0.04 -102,0 -102,0 z" />
                                <path id="Contour-844" d="M 804.15,1393.87 H 681.2 a 4.76,4.76 0 0 1 -4.76,-4.76 v -90.21 a 4.76,4.76 0 0 1 4.76,-4.76 h 122.95 a 4.77,4.77 0 0 1 4.76,4.76 v 90.21 a 4.77,4.77 0 0 1 -4.76,4.76 z M 681.2,1295.64 a 3.26,3.26 0 0 0 -3.26,3.26 v 90.21 a 3.26,3.26 0 0 0 3.26,3.26 h 122.95 a 3.26,3.26 0 0 0 3.26,-3.26 v -90.21 a 3.26,3.26 0 0 0 -3.26,-3.26 z" />
                                <path id="Contour-845" d="M 802.45,1385.62 H 683.31 a 2.2,2.2 0 0 1 -2.2,-2.2 v -81.16 a 2.21,2.21 0 0 1 2.2,-2.2 h 119.14 a 2.21,2.21 0 0 1 2.2,2.2 v 81.16 a 2.2,2.2 0 0 1 -2.2,2.2 z m -119.14,-84.06 a 0.7,0.7 0 0 0 -0.7,0.7 v 81.16 a 0.7,0.7 0 0 0 0.7,0.7 h 119.14 a 0.7,0.7 0 0 0 0.7,-0.7 v -81.16 a 0.7,0.7 0 0 0 -0.7,-0.7 z" />
                                <path id="Contour-846" d="M 806,1402.3 H 679.89 a 4.08,4.08 0 0 1 -4.1,-4 v -3.59 a 2.3,2.3 0 0 1 2.29,-2.29 h 2.84 v 1.5 h -2.84 a 0.79,0.79 0 0 0 -0.79,0.79 v 3.59 a 2.58,2.58 0 0 0 2.6,2.55 H 806 a 2.58,2.58 0 0 0 2.6,-2.55 v -3.59 a 0.79,0.79 0 0 0 -0.79,-0.79 h -3.35 v -1.5 h 3.35 a 2.3,2.3 0 0 1 2.29,2.29 v 3.59 a 4.08,4.08 0 0 1 -4.1,4 z" />
                            </g>
                            <circle id="Contour-847" cx="743.20001" cy="1297.8" r="0.85000002" />
                            <g id="Group-285">
                                <path id="Contour-848" d="m 711.06,1315.09 h 25 a 0.74,0.74 0 0 0 0,-1.47 v 0 h -25 a 0.75,0.75 0 0 0 0,1.49 z" />
                                <path id="Contour-849" d="m 711.06,1320 h 25 a 0.76,0.76 0 0 0 0.75,-0.75 0.75,0.75 0 0 0 -0.75,-0.74 h -25 a 0.74,0.74 0 0 0 -0.74,0.74 0.75,0.75 0 0 0 0.74,0.75 z" />
                                <path id="Contour-850" d="m 736,1323.41 h -25 a 0.75,0.75 0 0 0 0,1.49 h 25 a 0.75,0.75 0 1 0 0,-1.49 z" />
                            </g>
                            <g id="Group-286">
                                <path id="Contour-851" d="m 736,1344 v 0 a 0.74,0.74 0 0 0 0,-1.48 h -25 a 0.74,0.74 0 0 0 0,1.48 z" />
                                <path id="Contour-852" d="m 736,1347.42 h -25 a 0.74,0.74 0 0 0 0,1.48 h 25 a 0.74,0.74 0 0 0 0,-1.48 z" />
                                <path id="Contour-853" d="m 711.05,1339.09 h 25 a 0.74,0.74 0 0 0 0,-1.47 v 0 h -25 a 0.75,0.75 0 0 0 0,1.49 z" />
                            </g>
                            <g id="Group-287">
                                <path id="Contour-854" d="m 736,1368 v 0 a 0.74,0.74 0 0 0 0,-1.48 h -25 a 0.74,0.74 0 0 0 0,1.48 z" />
                                <path id="Contour-855" d="m 736,1371.42 h -25 a 0.75,0.75 0 0 0 0,1.49 h 25 v 0 a 0.74,0.74 0 0 0 0,-1.47 z" />
                                <path id="Contour-856" d="m 711,1363.09 h 25 v 0 a 0.73,0.73 0 0 0 0,-1.45 v 0 h -25 v 0 0 a 0.75,0.75 0 0 0 0,1.49 z" />
                            </g>
                            <g id="Group-288">
                                <circle id="Contour-857" className="iconWhite" cx="801.07001" cy="1302.39" r="21.15" />
                                <circle id="Contour-858" className="productDetailCls-3" cx="801.07001" cy="1302.39" r="18.219999" />
                                <path id="Contour-859" className="iconWhite" d="M 785.26,1312.09 A 18.22,18.22 0 1 0 811,1286.33 l -8.34,-2.43 -8.44,1.28 -6.48,5.12 v 0 l -4.45,6.62 -0.29,9.29 z" />
                                <path id="Contour-860" className="iconWhite" d="m 826,1321.76 15.68,15.69 c 0.35,0.35 0.21,1 -0.3,1.55 l -2.65,2.65 c -0.51,0.51 -1.2,0.64 -1.54,0.3 l -15.69,-15.69 z" />
                                <path id="Contour-861" d="m 837.74,1342.89 a 1.46,1.46 0 0 1 -1,-0.42 l -15.14,-15.14 a 1.82,1.82 0 0 1 0,-2.57 l 3,-3 a 1.83,1.83 0 0 1 2.58,0 l 15.13,15.13 a 1.52,1.52 0 0 1 0.39,1.39 2.48,2.48 0 0 1 -0.68,1.23 l -2.65,2.65 a 2.39,2.39 0 0 1 -1.23,0.67 1.24,1.24 0 0 1 -0.4,0.06 z m -11.92,-20.15 a 0.3,0.3 0 0 0 -0.23,0.1 l -3,3 a 0.31,0.31 0 0 0 0,0.45 l 15.14,15.14 a 1.13,1.13 0 0 0 0.49,-0.29 l 2.65,-2.65 a 0.74,0.74 0 0 0 0.27,-0.54 l -15.11,-15.09 a 0.32,0.32 0 0 0 -0.21,-0.12 z" />
                                <g id="Group-289">
                                    <rect id="Contour-862" className="productDetailCls-2" x="818.09003" y="1314.78" width="3.49" height="10.57" transform="rotate(-45,819.83764,1320.0621)" />
                                    <polygon id="Contour-863" points="817.86,1314.56 825.86,1322.56 822.34,1326.09 814.33,1318.09 815.39,1317.03 822.34,1323.97 823.74,1322.56 816.8,1315.62 " />
                                </g>
                                <path id="Contour-864" d="m 801.07,1324.28 a 21.89,21.89 0 1 1 15.49,-6.41 21.84,21.84 0 0 1 -15.49,6.41 z m 0,-42.28 a 20.4,20.4 0 1 0 14.42,6 v 0 a 20.3,20.3 0 0 0 -14.42,-6 z" />
                                <path id="Contour-865" d="M 801.07,1321.35 A 19,19 0 1 1 814.49,1289 v 0 a 19,19 0 0 1 -13.42,32.37 z m 0,-36.43 a 17.46,17.46 0 1 0 12.36,5.12 v 0 a 17.37,17.37 0 0 0 -12.36,-5.12 z" />
                                <path id="Contour-866" d="m 788.09,1299.38 a 0.75,0.75 0 0 0 -0.75,-0.75 0.74,0.74 0 0 0 -0.71,0.59 v 0 a 15.23,15.23 0 0 0 -0.34,3.17 v 0 a 0.75,0.75 0 0 0 0.74,0.75 0.76,0.76 0 0 0 0.75,-0.75 v 0 a 13,13 0 0 1 0.31,-2.85 v 0 a 0.9,0.9 0 0 0 0,-0.16 z" />
                                <path id="Contour-867" d="m 801.07,1287.59 v 0 a 14.8,14.8 0 0 0 -13.64,9 v 0 a 0.72,0.72 0 0 0 -0.06,0.29 0.74,0.74 0 0 0 1.43,0.29 v 0 a 13.27,13.27 0 0 1 12.25,-8.12 v 0 a 0.75,0.75 0 0 0 0.75,-0.74 0.76,0.76 0 0 0 -0.73,-0.72 z" />
                            </g>
                            <g id="Group-290">
                                <g id="Group-291">
                                    <rect id="Contour-868" className="iconWhite" x="754.78998" y="1326.02" width="5.3699999" height="35.830002" rx="1.37" />
                                    <path id="Contour-869" d="m 758.8,1362.6 h -2.64 a 2.12,2.12 0 0 1 -2.12,-2.12 v -33.09 a 2.12,2.12 0 0 1 2.12,-2.12 h 2.64 a 2.12,2.12 0 0 1 2.12,2.12 v 33.09 a 2.12,2.12 0 0 1 -2.12,2.12 z m -2.64,-35.83 a 0.62,0.62 0 0 0 -0.62,0.62 v 33.09 a 0.62,0.62 0 0 0 0.62,0.62 h 2.64 a 0.62,0.62 0 0 0 0.62,-0.62 v -33.09 a 0.62,0.62 0 0 0 -0.62,-0.62 z" />
                                </g>
                                <g id="Group-292">
                                    <rect id="Contour-870" className="iconWhite" x="764.70001" y="1332.4399" width="5.3699999" height="29.41" rx="1.37" />
                                    <path id="Contour-871" d="m 768.7,1362.6 h -2.63 a 2.12,2.12 0 0 1 -2.12,-2.12 v -26.67 a 2.12,2.12 0 0 1 2.12,-2.12 h 2.63 a 2.12,2.12 0 0 1 2.12,2.12 v 26.67 a 2.12,2.12 0 0 1 -2.12,2.12 z m -2.63,-29.41 a 0.62,0.62 0 0 0 -0.62,0.62 v 26.67 a 0.62,0.62 0 0 0 0.62,0.62 h 2.63 a 0.61,0.61 0 0 0 0.62,-0.62 v -26.67 a 0.62,0.62 0 0 0 -0.62,-0.62 z" />
                                </g>
                                <g id="Group-293">
                                    <rect id="Contour-872" className="iconWhite" x="774.60999" y="1338.98" width="5.3699999" height="22.879999" rx="1.37" />
                                    <path id="Contour-873" d="M 778.61,1362.6 H 776 a 2.12,2.12 0 0 1 -2.12,-2.12 v -20.13 a 2.12,2.12 0 0 1 2.12,-2.12 h 2.63 a 2.12,2.12 0 0 1 2.12,2.12 v 20.13 a 2.12,2.12 0 0 1 -2.14,2.12 z M 776,1339.73 a 0.61,0.61 0 0 0 -0.62,0.62 v 20.13 a 0.61,0.61 0 0 0 0.62,0.62 h 2.63 a 0.62,0.62 0 0 0 0.62,-0.62 v -20.13 a 0.62,0.62 0 0 0 -0.62,-0.62 z" />
                                </g>
                                <g id="Group-294">
                                    <rect id="Contour-874" className="iconWhite" x="784.51001" y="1343.9399" width="5.3699999" height="17.92" rx="1.21" />
                                    <path id="Contour-875" d="m 788.68,1362.6 h -2.95 a 2,2 0 0 1 -2,-2 v -15.49 a 2,2 0 0 1 2,-2 h 2.95 a 2,2 0 0 1 2,2 v 15.49 a 2,2 0 0 1 -2,2 z m -2.95,-17.91 a 0.47,0.47 0 0 0 -0.47,0.46 v 15.49 a 0.46,0.46 0 0 0 0.47,0.46 h 2.95 a 0.46,0.46 0 0 0 0.46,-0.46 v -15.49 a 0.47,0.47 0 0 0 -0.46,-0.46 z" />
                                </g>
                                <path id="Contour-876" d="m 798.54,1365.24 -2.43,-1.56 v 0 a 0.73,0.73 0 0 0 -0.39,-0.13 0.75,0.75 0 0 0 -0.75,0.75 0.72,0.72 0 0 0 0.35,0.62 v 0 l 0.76,0.48 h -48.77 a 0.75,0.75 0 1 0 0,1.5 h 48.75 l -0.76,0.49 v 0 a 0.72,0.72 0 0 0 -0.35,0.62 0.73,0.73 0 0 0 1.14,0.62 v 0 l 2.43,-1.56 a 1.13,1.13 0 0 0 0,-1.89 z" />
                                <path id="Contour-877" d="m 751.7,1321.25 v 0 l -1.55,-2.42 a 1.16,1.16 0 0 0 -1.89,0 l -1.56,2.42 v 0 a 0.73,0.73 0 0 0 -0.13,0.39 0.75,0.75 0 0 0 0.75,0.75 0.72,0.72 0 0 0 0.62,-0.35 v 0 l 0.49,-0.76 v 46.86 0 a 0.75,0.75 0 0 0 1.5,0 v 0 -46.86 l 0.49,0.76 v 0 a 0.74,0.74 0 0 0 1.37,-0.4 0.65,0.65 0 0 0 -0.09,-0.39 z" />
                            </g>
                            <g id="Group-295">
                                <g id="Group-296">
                                    <path id="Contour-878" className="productDetailCls-3" d="m 696.26,1318.78 -2.57,-3.17 a 0.76,0.76 0 0 0 -1.07,-0.1 l -0.94,0.78 a 0.76,0.76 0 0 0 -0.11,1.06 l 4.07,5.11 a 1.13,1.13 0 0 0 1.78,0 l 8.18,-10.5 a 0.71,0.71 0 0 0 -0.13,-1 l -1.1,-0.82 a 0.72,0.72 0 0 0 -1,0.13 l -6.56,8.52 a 0.35,0.35 0 0 1 -0.55,-0.01 z" />
                                    <path id="Contour-879" d="m 696.53,1323.64 a 1.89,1.89 0 0 1 -1.48,-0.71 l -4.07,-5.12 a 1.51,1.51 0 0 1 0.22,-2.1 l 0.94,-0.78 a 1.51,1.51 0 0 1 1.11,-0.34 1.53,1.53 0 0 1 1,0.55 l 2.24,2.77 6.26,-8.12 a 1.48,1.48 0 0 1 2,-0.28 l 1.1,0.83 a 1.4,1.4 0 0 1 0.57,1 1.46,1.46 0 0 1 -0.29,1.1 l -8.13,10.47 a 1.84,1.84 0 0 1 -1.47,0.73 z m -3.42,-7.55 -1,0.78 4.07,5.13 a 0.42,0.42 0 0 0 0.3,0.14 0.37,0.37 0 0 0 0.3,-0.15 l 8.18,-10.5 -1.09,-0.77 -6.52,8.51 a 1.1,1.1 0 0 1 -0.86,0.43 1.18,1.18 0 0 1 -0.87,-0.41 v 0 z m 3.73,2.21 z" />
                                </g>
                                <path id="Contour-880" d="m 703.79,1319.37 a 0.75,0.75 0 0 0 -1.5,0 v 0 4.08 a 2.2,2.2 0 0 1 -2.19,2.2 h -8.16 a 2.2,2.2 0 0 1 -2.19,-2.2 v -8.15 a 2.2,2.2 0 0 1 2.19,-2.2 h 6.64 a 0.75,0.75 0 0 0 0,-1.5 h -6.64 a 3.7,3.7 0 0 0 -3.69,3.7 v 8.15 a 3.7,3.7 0 0 0 3.69,3.7 h 8.16 a 3.7,3.7 0 0 0 3.69,-3.7 z" />
                            </g>
                            <g id="Group-297">
                                <g id="Group-298">
                                    <path id="Contour-881" className="productDetailCls-3" d="m 696.26,1342.78 -2.57,-3.17 a 0.76,0.76 0 0 0 -1.07,-0.1 l -0.94,0.78 a 0.76,0.76 0 0 0 -0.11,1.06 l 4.07,5.11 a 1.13,1.13 0 0 0 1.78,0 l 8.18,-10.5 a 0.71,0.71 0 0 0 -0.13,-1 l -1.1,-0.82 a 0.72,0.72 0 0 0 -1,0.13 l -6.56,8.52 a 0.35,0.35 0 0 1 -0.55,-0.01 z" />
                                    <path id="Contour-882" d="m 696.53,1347.64 a 1.89,1.89 0 0 1 -1.48,-0.71 l -4.07,-5.12 a 1.51,1.51 0 0 1 0.22,-2.1 l 0.94,-0.78 a 1.51,1.51 0 0 1 1.11,-0.34 1.53,1.53 0 0 1 1,0.55 l 2.24,2.77 6.26,-8.12 a 1.48,1.48 0 0 1 2,-0.28 l 1.1,0.83 a 1.4,1.4 0 0 1 0.57,1 1.46,1.46 0 0 1 -0.29,1.1 l -8.13,10.47 a 1.84,1.84 0 0 1 -1.47,0.73 z m -3.42,-7.55 -1,0.78 4.07,5.13 a 0.42,0.42 0 0 0 0.3,0.14 0.37,0.37 0 0 0 0.3,-0.15 l 8.18,-10.5 -1.09,-0.77 -6.52,8.51 a 1.1,1.1 0 0 1 -0.86,0.43 1.22,1.22 0 0 1 -0.87,-0.41 v 0 z m 3.73,2.21 z" />
                                </g>
                                <path id="Contour-883" d="m 703.79,1343.37 a 0.75,0.75 0 0 0 -1.5,0 v 0 4.08 a 2.2,2.2 0 0 1 -2.19,2.2 h -8.16 a 2.2,2.2 0 0 1 -2.19,-2.2 v -8.15 a 2.2,2.2 0 0 1 2.19,-2.2 h 6.64 a 0.75,0.75 0 0 0 0,-1.5 h -6.64 a 3.7,3.7 0 0 0 -3.69,3.7 v 8.15 a 3.7,3.7 0 0 0 3.69,3.7 h 8.16 a 3.7,3.7 0 0 0 3.69,-3.7 z" />
                            </g>
                            <g id="Group-299">
                                <g id="Group-300">
                                    <path id="Contour-884" className="productDetailCls-3" d="m 696.26,1366.78 -2.57,-3.17 a 0.76,0.76 0 0 0 -1.07,-0.1 l -0.94,0.78 a 0.76,0.76 0 0 0 -0.11,1.06 l 4.07,5.11 a 1.13,1.13 0 0 0 1.78,0 l 8.18,-10.5 a 0.71,0.71 0 0 0 -0.13,-1 l -1.1,-0.82 a 0.72,0.72 0 0 0 -1,0.13 l -6.56,8.52 a 0.35,0.35 0 0 1 -0.55,-0.01 z" />
                                    <path id="Contour-885" d="m 696.53,1371.64 a 1.89,1.89 0 0 1 -1.48,-0.71 l -4.07,-5.12 a 1.5,1.5 0 0 1 0.22,-2.1 l 0.94,-0.78 a 1.45,1.45 0 0 1 1.11,-0.34 1.53,1.53 0 0 1 1,0.55 l 2.24,2.77 6.26,-8.12 a 1.48,1.48 0 0 1 2,-0.28 l 1.1,0.82 a 1.5,1.5 0 0 1 0.28,2.09 l -8.13,10.49 a 1.84,1.84 0 0 1 -1.47,0.73 z m -3.42,-7.56 -1,0.79 4.07,5.13 a 0.36,0.36 0 0 0 0.3,0.14 0.37,0.37 0 0 0 0.3,-0.15 l 8.18,-10.5 -1.09,-0.77 -6.52,8.51 a 1.1,1.1 0 0 1 -0.86,0.43 1.11,1.11 0 0 1 -0.87,-0.41 v 0 z" />
                                </g>
                                <path id="Contour-886" d="m 703.79,1367.37 a 0.75,0.75 0 0 0 -1.5,0 v 0 4.08 a 2.2,2.2 0 0 1 -2.19,2.2 h -8.16 a 2.2,2.2 0 0 1 -2.19,-2.2 v -8.15 a 2.2,2.2 0 0 1 2.19,-2.2 h 6.64 a 0.75,0.75 0 0 0 0,-1.5 h -6.64 a 3.7,3.7 0 0 0 -3.69,3.7 v 8.15 a 3.7,3.7 0 0 0 3.69,3.7 h 8.16 a 3.7,3.7 0 0 0 3.69,-3.7 z" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}
export default ProductDetail