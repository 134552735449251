/**********************************************************************************************************************
 * Comparison Operator
 **********************************************************************************************************************/

export function DescendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

export function GetComparator<Key extends keyof any>(order: Order, orderBy: Key): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => DescendingComparator(a, b, orderBy)
        : (a, b) => -DescendingComparator(a, b, orderBy);
}

/**********************************************************************************************************************
 * StableSort
 * example Use: StableSort(array[], GetComparator("desc", "valueForSorting"))
 **********************************************************************************************************************/

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function StableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        // console.log("order: ", order)
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    // console.log("el: ", stabilizedThis)
    // console.log("array: ", array)
    // console.log("comparator: ", comparator)
    return stabilizedThis.map((el) => el[0]);
}