import React, {FC, useContext} from "react";
import {
    Avatar,
    Badge,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import {BubbleChart, TableChart} from "@mui/icons-material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import AbbreviateNumbers from "../../../../utils/abbreviateNumbers";
import SettingsContext from "../../../../contexts/SettingsContext";

interface DataProps {
    [key: string]: (string[] | number[]);
}

interface Top5SwitcherProps {
    tableModeValue: string
    tableModeId: string
    onChange: (mode:string, id: any) => void
    title: string
    data: DataProps
}
const Top5Switcher: FC<Top5SwitcherProps> = ({...props}) => {
    const colors = [ "#f2bc53", "#3aa9a6", "#4b4b4b", "#f45c5c", "#e1d5c6", "#8c9494"]
    const settingsCtx = useContext(SettingsContext)
    
    function stringAvatar(name: string | number) {
        if (typeof name === "number") {
            name = name.toString();
        }
        if (name && name.length > 0) {
            const n = name.split(' ');
            const names = n.length === 1
                ? n[0].replace(/[^a-zA-Z0-9&]/g, '').substring(0, 4)
                : n.map(word => word[0]).join('').replace(/[^a-zA-Z0-9&]/g, '');
            return { children: names.slice(0,5) };
        }
    }
    
    
    return (

        <Card>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <CardHeader title={props.title}/>
                <Box display="flex" alignItems="center" flexDirection="column">
                    <div style={{
                        backgroundColor: '#f45c5c', width: 15, height: 15, borderRadius: '50%'
                    }}/>
                    <Typography variant="body2" sx={{textAlign: 'center'}}>{Object.keys(props.data)[1]}</Typography>
                </Box>
                <Box display="flex" alignItems="center" flexDirection="column">
                    <div style={{
                        backgroundColor: useTheme().palette.mode === 'light' ? '#18456b' : "#f2bc53", width: 15, height: 15, borderRadius: '50%'
                    }}/>
                    <Typography variant="body2" sx={{textAlign: 'center'}}>{Object.keys(props.data)[2]}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <ToggleButtonGroup
                        value={props.tableModeValue}
                        exclusive
                        onChange={(_event, newMode) => props.onChange(newMode, props.tableModeId)}
                        aria-label="text alignment"
                        sx={{margin: "2px", marginRight: "4px"}}
                    >
                        <ToggleButton value="graphic"
                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                            <BubbleChart fontSize="small" color={"primary"}/>
                        </ToggleButton>
                        <ToggleButton value="table"
                                      sx={{padding: '8px', width: '40px', height: '40px'}}>
                            <TableChart fontSize="small" color={"primary"}/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <Divider/>
            <CardContent>
                {(props.tableModeValue === "graphic") ?
                    <Grid container justifyContent="center">
                        {Object.values(props.data)[1].map((_name: any, id: string | number) => (
                            <Box sx={{paddingLeft: 1.1, paddingRight: 2}} key={id}>
                                <Badge overlap="circular"
                                                                                 color={"error"}
                                                                                 badgeContent={AbbreviateNumbers(Object.values(props.data)[1][id], 1)}
                                                                                 anchorOrigin={{
                                                                                     vertical: 'top', horizontal: 'left',
                                                                                 }}>
                                <Badge
                                    overlap="circular" color={"primary"}
                                    badgeContent={(Object.values(props.data)[2][id])}>
                                    <Tooltip
                                        title={(Object.values(props.data)[0][id])}><Avatar
                                        sx={{
                                            bgcolor: colors[id],
                                            width: 66,
                                            height: 66,
                                            overflow: 'hidden',      // Prevent overflow
                                            textOverflow: 'ellipsis', // Use ellipsis (...) for overflow text
                                            whiteSpace: 'nowrap'
                                        }} {...stringAvatar((Object.values(props.data)[0][id]))}/></Tooltip>
                                </Badge>
                            </Badge>
                            </Box>
                        ))}
                    </Grid> :
                    <Box sx={{height: 390, width: '100%'}}>
                        <DataGridPro
                            autoHeight
                            columns={Object.keys(props.data).map((key) => ({
                                field: key,
                                headerName: key,
                                width: 150,
                            }))}
                            rows={props.data[Object.keys(props.data)[0]].map((_: any, rowIndex: number) => {
                                const row: { id: number } = { id: rowIndex + 1 };
                                Object.keys(props.data).forEach((key) => {
                                    row[key] = props.data[key][rowIndex];
                                });
                                return row;
                            })}/></Box>}
            </CardContent>
        </Card>)
}

export default Top5Switcher