import React from "react";
import type { FC } from 'react';
import logoPath from "./PulaSymbol.svg"
import './currencyStyles.css'
import { Typography } from "@mui/material";

interface LilangeniSymbolProps {
  [key: string]: any;
}

const LilangeniSymbol: FC<LilangeniSymbolProps> = (props) => {

  return (
    <h1
      // component="div"
      // sx={{
      //   fontSize: {
      //     lg: '2rem',
      //     md: '1.5rem',
      //     sm: '1rem',
      //     xs: '1rem'
      //   },
      //   fontWeight: 'bold'
      // }}    
      >Le

    </h1>
  );
};

export default LilangeniSymbol;