import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect'
import _ from "lodash";
import isEqual from 'lodash.isequal'
import Widget, {WidgetGroupItem, WidgetGroupItemWithWidget} from "../cog/bitool/widget/Widget";
import {BIDashboard} from "../types/bitool";
import Dashboard from "../cog/bitool/dashboard/Dashboard";
import {DefaultRootState} from "react-redux";

// Helper for Deep Equal Selection
const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual
)

export const exportCsvSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard,
    (dashboard: BIDashboard) => {
        return Object.keys(dashboard.widgets).map((k) => {
            const allowedWidgets = ["BasicTable", "BasicQuickStats", "BasicApexChart"]
            if (allowedWidgets.includes(dashboard.widgets[k].arguments.type)) {
                return {
                    "name": dashboard.widgets[k].name,
                    "data": dashboard.widgetData[k]
                }
            }
        })

    }
)

export const exportExcelSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard,
    (dashboard: BIDashboard) => {
        return Object.keys(dashboard.widgets).map((k) => {
            const allowedWidgets = ["BasicTable", "BasicQuickStats", "BasicApexChart"]
            if (allowedWidgets.includes(dashboard.widgets[k].arguments.type)) {
                return {
                    "name": dashboard.widgets[k].name,
                    "data": dashboard.widgetData[k]
                }
            }
        })

    }
)

// Loading Selectors
export const anyWidgetIsLoadingSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgetIsLoading,
    (isLoadings: Record<string, any>) => {
        return Object.values(isLoadings).includes(true);
    }
)

export const widgetIsLoadingSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgetIsLoading,
    (_, widgetId: string) => widgetId,
    (isLoadings: Record<string, any>, widgetId) => {
        return isLoadings[widgetId]
    }
)

export const widgetIsLoadingSelector = createSelector(
    (state: any) => state.biTool.dashboard.widgetIsLoading,
    (_, widgetId) => widgetId,
    (isLoadings: Record<string, any>, widgetId) => {
        return isLoadings[widgetId]
    }
)

export const widgetDependantsIsLoadingSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard,
    (_, widgetId) => widgetId,
    (dashboard: BIDashboard, widgetId) => {
        if (dashboard.widgets[widgetId] && dashboard.widgets[widgetId].arguments.templates) {
            const deps = Object.keys(dashboard.widgets[widgetId].arguments.templates).map((k) => {
                if (dashboard.widgets[widgetId].arguments.templates[k].widgetId)
                    return dashboard.widgetIsLoading[dashboard.widgets[widgetId].arguments.templates[k].widgetId]
            })
            return deps.some(item => item);
        }
        return false
    }
)

export const widgetVariablesSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgetVariables,
    (state, widgetId: string) => widgetId,
    (variables: Record<string, any>, widgetId) => {
        return variables[widgetId]
    }
)

export const allWidgetVariablesSelectorFunc = () => createSelector(
  (state: any) => state.biTool.dashboard.widgetVariables,
  (variables: Record<string, any>) => {
    return variables
  }
)

export const widgetVariablesSelector = createSelector(
    (state: any) => state.biTool.dashboard.widgetVariables,
    (variables: Record<string, any>) => {
        return variables
    }
)

// Widget Output Selectors
// export const widgetOutputsSelectorFunc = () => createSelector(
//     (state: any) => state.biTool.dashboard.widgetOutputs,
//     (_, widgetId) => widgetId,
//     (outputs: Record<string, any>, widgetId) => {
//         return outputs[widgetId]
//     }
// )

// export const widgetOutputDataSelector = createSelector(
//     (state: any) => state.biTool.dashboard.widgetOutputs,
//     (_, widgetId) => widgetId,
//     (outputs: Record<string, any>, widgetId) => {
//         return outputs[widgetId]
//     }
// )
//
// export const widgetOutputsSelector = createSelector(
//     (state: any) => state.biTool.dashboard.widgetOutputs,
//     (outputs: Record<string, any>) => {
//         return outputs
//     }
// )

// widget Template Selectors
export const widgetTemplatesSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgetTemplates,
    (t: Record<string, any>) => {
        return t
    }
)

export const widgetTemplatesSelector = createSelector(
    (state: any) => state.biTool.dashboard.widgetTemplates,
    (t: Record<string, any>) => {
        return t
    }
)

// Widget Input Data Selectors
export const widgetInputDataSelector = createSelector(
    (state: any) => state.biTool.dashboard.widgetInputs,
    (_, widgetId) => widgetId,
    (inputs: Record<string, any>, widgetId) => {
        return inputs[widgetId]
    }
)


// Widget Data Selectors
export const widgetDataSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgetData,
    (_, widgetId: string) => widgetId,
    (data: Record<string, any>, widgetId) => {
        return data[widgetId]
    }
)

export const widgetColumnsSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgetColumns,
    (_, widgetId: string) => widgetId,
    (cols: Record<string, any>, widgetId) => {
        return cols[widgetId]
    }
)

export const widgetDataSelector = createSelector(
    (state: any) => state.biTool.dashboard.widgetData,
    (_, widgetId) => widgetId,
    (data: Record<string, any>, widgetId) => {
        return data[widgetId]
    }
)

// Widget Selectors
export const widgetSelectorAll = createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (widgets: Record<string, Widget>) => {
        return widgets
    }
)

// -> deep equal of previous
export const allWidgetSelector = createDeepEqualSelector(
    (state: any) => state.biTool.dashboard.widgets,
    i => i
)

export const widgetsSelector = createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (_, widgetIds: string[] | null) => widgetIds,
    (widgets: Record<string, Widget>, widgetIds) => {
        if (widgetIds) return widgetIds.map((k) => widgets[k])
        else return null
    }
)

export const selectWidgetById = createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (_, widgetId: string) => widgetId,
    (widgets: Record<string, Widget>, widgetId) => {
        if (widgetId) return widgets[widgetId]
        else return null
    }
)

export const selectWidgetVariables = createSelector(
    (state: any) => state.biTool.dashboard.widgetVariables,
    (widgetVariables: any) => {
        return widgetVariables
    }
)

// Widget Output Selectors
// -> deep equal
// export const allWidgetOutputsSelector = createDeepEqualSelector(
//     (state: any) => state.biTool.dashboard.widgetOutputs,
//     i => i,
// )

export const allWidgetVariablesSelector = createDeepEqualSelector(
    (state: any) => state.biTool.dashboard.widgetVariables,
    i => i,
)


// export const widgetGroupOutputsSelector = () => createDeepEqualSelector(
//     allWidgetSelector,
//     allWidgetOutputsSelector,
//     (_, widgetId) => widgetId,
//     (widgets: Record<string, Widget>, widgetOutputs: Record<string, any>, widgetId: string) => {
//         if (widgets[widgetId]) {
//             const b = Object.keys(widgets[widgetId].arguments.group).map((g) => {
//                 return widgets[widgetId].arguments.group[g].id
//             })
//             const a = b.map((t) => {
//                 return {[t]: widgetOutputs[t]}
//             })
//             if (Object.keys(a).length == 0) return null
//             return _.merge({}, a)
//         }
//     }
// )

// export const widgetDependencyOutputsSelector = () => createSelector(
//     allWidgetSelector,
//     allWidgetOutputsSelector,
//     (_, widgetId) => widgetId,
//     (widgets: Record<string, Widget>, widgetOutputs: Record<string, any>, widgetId: string) => {
//         if (widgets[widgetId] && widgets[widgetId].arguments && widgets[widgetId].arguments.templates) {
//             const b = Object.keys(widgets[widgetId].arguments.templates).filter((t) => {
//                 return !!widgets[widgetId].arguments.templates[t].hasOwnProperty('widgetId');
//             })
//             const a = b.map((t) => {
//                 return {[t]: widgetOutputs[widgets[widgetId].arguments.templates[t].widgetId]}
//             })
//             if (Object.keys(a).length == 0) return null
//             return Object.assign({}, ...a)
//             // return _.merge({}, a)
//         }
//         return null
//     }
// )

export const widgetDependencyVariablesSelector = () => createSelector(
    allWidgetSelector,
    allWidgetVariablesSelector,
    (state: DefaultRootState, widgetId: string) => widgetId,
    (widgets, widgetVariables, widgetId: string) => {
        if (widgets[widgetId] && widgets[widgetId].arguments && widgets[widgetId].arguments.templates) {
            const b = Object.keys(widgets[widgetId].arguments.templates).filter((t) => {
                return !!(widgets[widgetId].arguments.templates[t].hasOwnProperty('variableId') ||
                    widgets[widgetId].arguments.templates[t].hasOwnProperty('widgetId'))
            })
            const a = b.map((t) => {
                if (widgets[widgetId].arguments.templates[t].hasOwnProperty('variableId'))
                    return {[t]: widgetVariables[widgets[widgetId].arguments.templates[t].variableId]}
                if (widgets[widgetId].arguments.templates[t].hasOwnProperty('widgetId'))
                    return {[t]: widgetVariables[widgets[widgetId].arguments.templates[t].widgetId]}
            })
            // console.log(a, Object.assign({}, ...a))
            return Object.assign({}, ...a)
        }
        return null
    }
)

// Widget Group Related Selectors
export const widgetMaxGroupItemsSelector = () => createDeepEqualSelector(
    allWidgetSelector,
    (state: DefaultRootState, widgetId: string) => widgetId,
    (widgets, widgetId: string) => {
        if (widgets[widgetId] && widgets[widgetId].arguments && widgets[widgetId].arguments.group) {
            const b = Object.keys(widgets[widgetId].arguments.group).map((g) => {
                return widgets[widgetId].arguments.group[g].id
            })
            return b.length
        }
    }
)

export const itemsFromGroupIdSelector = () => createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (_, widgetId: string) => widgetId,
    (widgets: Record<string, Widget>, widgetId) => {
        if (!(widgets[widgetId]) || !(widgets[widgetId].arguments) || !(widgets[widgetId].arguments.group)) return null
        const a: WidgetGroupItemWithWidget[]  = Object.keys(widgets[widgetId].arguments.group).map((k) => {
            if (widgets[widgetId].arguments.group[k].id) {
                return {
                    id: widgets[widgetId].arguments.group[k].id,
                    widget: widgets[widgets[widgetId].arguments.group[k].id],
                    gridLocation: widgets[widgetId].arguments.group[k].gridLocation,
                    row: widgets[widgetId].arguments.group[k].row,
                    label: widgets[widgetId].arguments.group[k].label,
                }
            }
            return {
                id: widgets[widgetId].arguments.group[k].id,
                widget: null,
                gridLocation: widgets[widgetId].arguments.group[k].gridLocation,
                row: widgets[widgetId].arguments.group[k].row,
                label: widgets[widgetId].arguments.group[k].label,
            }
        })
        return _.merge({}, a)
    }
)

export const widgetsFromGroupIdSelector = () => createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (_, widgetId: string) => widgetId,
    (widgets: Record<string, Widget>, widgetId) => {
        if (!(widgets[widgetId]) || !(widgets[widgetId].arguments) || !(widgets[widgetId].arguments.group)) return null
        return Object.keys(widgets[widgetId].arguments.group).map((k) => {
            return widgets[widgets[widgetId].arguments.group[k].id]
        })
    }
)


export const groupIdsSelector = createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (_, widgetId: string) => widgetId,
    (widgets: Record<string, Widget>, widgetId) => {
        if (!(widgets[widgetId]) || !(widgets[widgetId].arguments) || !(widgets[widgetId].arguments.group)) return null
        return Object.keys(widgets[widgetId].arguments.group).map((k) => {
            return widgets[widgetId].arguments.group[k].id
        })
    }
)

export const groupSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (_, widgetId: string) => widgetId,
    (widgets: Record<string, Widget>, widgetId) => {
        if (!(widgets[widgetId]) || !(widgets[widgetId].arguments) || !(widgets[widgetId].arguments.group)) return null
        return widgets[widgetId].arguments.group
        // return [...new Set(a)];
    }
)

export const selectedMemberIndexFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgetSelectedGroupMemberIndex,
    (_, widgetId: string) => widgetId,
    (widgetSelectedGroupMemberIndex: any, widgetId) => {
        return widgetSelectedGroupMemberIndex[widgetId]
    }
)

export const selectedMemberMaxIndexFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgetSelectedGroupMemberMaxIndex,
    (_, widgetId: string) => widgetId,
    (widgetSelectedGroupMemberMaxIndex: any, widgetId) => {
        return widgetSelectedGroupMemberMaxIndex[widgetId]
    }
)

// Dashboard Layout Selectors
export const renderWidgetsToLayoutSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (widgets: Record<string, Widget>) => {
        const renderWidgets = Object.keys(widgets).filter((k) => widgets[k].arguments.location != null)
        let layouts = {}
        renderWidgets.map((k) => {
            const w = widgets[k]
            if (w.arguments.location) {
                // if (_.intersection(Object.keys(w.arguments.location), ['lg', 'md', 'sm', 'xl']).length > 0) {
                if (!('x' in w.arguments.location)) {
                    Object.keys(w.arguments.location).map((j) => {
                        let tmp = layouts[j]
                        if (!tmp) tmp = []
                        tmp.push({  ...w.arguments.location[j], i: w.id})
                        layouts[j] = tmp
                    })

                } else {
                    let tmp = layouts['lg']
                    if (!tmp) tmp = []
                    tmp.push({  ...w.arguments.location, i: w.id})
                    layouts['lg'] = tmp
                }
            } else {
                return null
            }
        })
        return {
            widgets: renderWidgets.map((id) => widgets[id]),
            layouts: layouts
        }
    }
)

export const renderWidgetsToLayoutExportSelectorFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (widgets: Record<string, Widget>) => {
        const renderWidgets = Object.keys(widgets).filter((k) => widgets[k].arguments.location != null)
        let layouts = {}
        renderWidgets.map((k) => {
            const w = widgets[k]
            let offsetHeight = 0

            if (w.arguments.type == "BasicWidgetGroupStepper") {
                const numDrill = w.arguments.group.length
                if (w.arguments.location) {
                    // if (_.intersection(Object.keys(w.arguments.location), ['lg', 'md', 'sm', 'xl']).length > 0) {
                    Object.keys(w.arguments.location).map((j) => {
                        let tmp = layouts[j]
                        if (!tmp) tmp = []
                        tmp.push({
                            x: w.arguments.location[j].x,
                            y: w.arguments.location[j].y,
                            w: w.arguments.location[j].w,
                            h: w.arguments.location[j].h * numDrill + offsetHeight,
                            i: w.id
                        })
                        offsetHeight += (numDrill > 0 ? (numDrill - 1) * w.arguments.location[j].h : 0)
                        layouts[j] = tmp
                    })
                } else {
                    return null
                }
            } else {
                if (w.arguments.location) {
                    // if (_.intersection(Object.keys(w.arguments.location), ['lg', 'md', 'sm', 'xl']).length > 0) {
                    if (!('x' in w.arguments.location)) {
                        Object.keys(w.arguments.location).map((j) => {
                            let tmp = layouts[j]
                            if (!tmp) tmp = []
                            tmp.push({
                                x: w.arguments.location[j].x,
                                y: w.arguments.location[j].y,
                                w: w.arguments.location[j].w,
                                h: w.arguments.location[j].h + offsetHeight,
                                i: w.id
                            })

                            layouts[j] = tmp
                        })

                    } else {
                        let tmp = layouts['lg']
                        if (!tmp) tmp = []
                        tmp.push({
                            x: w.arguments.location.x,
                            y: w.arguments.location.y,
                            w: w.arguments.location.w,
                            h: w.arguments.location.h + offsetHeight,
                            i: w.id
                        })
                        layouts['lg'] = tmp
                    }
                } else {
                    return null
                }
            }
        })
        return {
            widgets: renderWidgets.map((id) => widgets[id]),
            layouts: layouts
        }
    }
)


export const initializedSelector = createSelector(
    (state: any) => state.biTool.dashboard.initialized,
    (initialized: boolean) => initialized
)

// todo: this is ugly, fix it
export const widgetDependencyIdsFunc = () => createSelector(
    (state: any) => state.biTool.dashboard.widgets,
    (_, widgetId: string) => widgetId,
    (widgets: Record<string, Widget>, widgetId) => {
        let widgetIdsToCheck: string[] = []
        // stepper || groups
        if (widgets[widgetId] && widgets[widgetId].arguments && widgets[widgetId].arguments.group) {
            widgets[widgetId].arguments.group.map((groupItem) => {
                if (groupItem.id) {
                    if (widgets[groupItem.id] && widgets[groupItem.id].arguments &&
                        widgets[groupItem.id].arguments.type != "BasicWidgetGroup" &&
                        widgets[groupItem.id].arguments.type != "BasicWidgetStepper") {
                        widgetIdsToCheck.push(groupItem.id)
                    }
                }
            })
        }
        else {
            if (widgetId) {
                if (widgets[widgetId] && widgets[widgetId].arguments &&
                    widgets[widgetId].arguments.type != "BasicWidgetGroup" &&
                    widgets[widgetId].arguments.type != "BasicWidgetStepper") {
                    widgetIdsToCheck.push(widgetId)
                }
            }
        }

        let newWidgetIds = widgetIdsToCheck.map((widId) => {
            if (widgets[widId] && widgets[widId].arguments.templates) {
                return Object.keys(widgets[widId].arguments.templates).map((k) => {
                    if (widgets[widId].arguments.templates[k].widgetId) {
                        if (widgets[widgets[widId].arguments.templates[k].widgetId]) {
                            if (widgets[widgets[widId].arguments.templates[k].widgetId].arguments.type != "BasicWidgetGroup" &&
                                widgets[widgets[widId].arguments.templates[k].widgetId].arguments.type != "BasicWidgetStepper") {
                                return widgets[widId].arguments.templates[k].widgetId
                        }}
                    }
                    return null
                })
            }
            return null
        })
        const finalIds = [...new Set(..._.flatten(newWidgetIds).filter(item => item != null))]
        if (finalIds.length > 0) return [...new Set(finalIds.map((_id) => widgets[_id]))];
        else return finalIds
    }
)