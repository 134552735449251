/* eslint-disable */

import {PerformJSONRPCRequest, API} from '../../../api/jsonrpc';
import Argument from "./Argument";

export interface FindAllRequest {
    queryId: string;
}

export interface FindAllResponse {
    queryArguments: Argument[];
}

export interface CreateOneRequest {
    name: string;
    queryId: string;
    argument: {};
}

export interface CreateOneResponse {
    id: string;
}

export interface DeleteOneRequest {
    query_arg_id: string;
}

export interface DeleteOneResponse {}

export interface UpdateOneRequest {
    id: string;
    name: string;
    queryId: string;
    argument: {};
}

export interface UpdateOneResponse {
    id: string;
}

const Store = {
    serviceProvider: 'QueryArgument-Store',
    async FindAll(request: FindAllRequest): Promise<FindAllResponse> { // redundant
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAll`,
            request: [{
                queryId: request.queryId
            }]
        });
        return {
            queryArguments: response.queryArguments.map((mem) => (new Argument({
                    id: mem.id,
                    name: mem.name,
                    queryId: mem.queryId,
                    argument: JSON.stringify(mem.argument, null, 4),
            }))),
        };
    },
    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> { // redundant
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                queryArgument: request
            }]
        });
        return response.id;
    },
    async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> { // redundant
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOne`,
            request: [{
                query_arg_id: request.query_arg_id
            }]
        });
        return response;
    },
    async UpdateOne(request: UpdateOneRequest): Promise<UpdateOneResponse> { // redundant
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateOne`,
            request: [{
                queryArgument: request
            }]
        });
        return response.id;
    }
};

export default Store;
