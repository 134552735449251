import type {FC} from 'react';
import * as React from 'react';
import {useEffect, useLayoutEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {Box, Container, Fade, Grid} from "@mui/material";
import KanbanColumn from "./column/KanbanColumn";
import {useMainContext} from "../../../../contexts/MainContext";
import useSettings from "../../../../hooks/useSettings";
import {Member} from "../../../../cog/keystone/member";
import {Card as KanbanCardObj, KanbanColumnObj, KanbanStore} from "../../../../cog/keystone/card";
import {ActionLogStore} from "../../../../cog/keystone/actionLog";
import {OppLabel} from "src/cog/keystone/oppLabel/OppLabel";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {NotificationStore} from "../../../../cog/keystone/notifications";

function reorderList(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

function reorderCards(list: KanbanCardObj[], startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

interface ViewProps {
    columns: Record<string, KanbanColumnObj>,
    all_cards: Record<string, KanbanCardObj>,
    updateCols: (newCols: Record<string, KanbanColumnObj>) => void
    setColumns: (newColumns: Record<string, KanbanColumnObj>) => void
    members: Member[]
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: string, vName: string) => void
    allLabels: OppLabel[]
}

const KanbanView: FC<ViewProps> = (props) => {

    const [colOrder, setColOrder] = useState<string[]>(null)
    const [columns, setColumns] = useState<Record<string, KanbanColumnObj>>(props.columns); // need this here as well as props for the drag drop to work properly
    const [loaded, setLoaded] = useState<boolean>(false);

    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const {settings} = useSettings();



    useLayoutEffect(() => {
        orderColumns(props.columns)
    }, [props.columns])

    useEffect(() => {
        if (colOrder != null) {
            setLoaded(true)
        }
    }, [colOrder])

    useEffect(() => {
        if (keystoneCtx.kanbanColumns != null) {
            setColumns(keystoneCtx.kanbanColumns)
        }
    }, [keystoneCtx.kanbanColumns])

    const orderColumns = (columns: Record<string, KanbanColumnObj>) => {
        setLoaded(false)
        let tempOrder: Record<number, string> = {}
        for (let columnsKey in columns) {
            tempOrder[columns[columnsKey].position] = columnsKey
        }
        let finalOrder: string[] = []
        for (let tempOrderKey in tempOrder) {
            finalOrder.push(tempOrder[tempOrderKey])
        }

        setColOrder(finalOrder)
    }

    const updateColumnOrder = (newOrder: any[]) => {
        //console.log("Update Column Order using View: ", newOrder)
        setColOrder(newOrder)
    }

    const updateColumns = (newColumns: Record<string, KanbanColumnObj>) => {
        //console.log("Update Columns: ", newColumns)
        setColumns(newColumns)
        keystoneCtx.setKanbanColumns(newColumns)
        props.updateCols(newColumns)

    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.type === "column") {
            const columnOrder = reorderList(
                colOrder,
                result.source.index,
                result.destination.index
            );
            updateColumnOrder(columnOrder)
            let newPos = []
            let ids = []
            newPos.push(result.source.index)
            ids.push(columnOrder[result.source.index])
            newPos.push((result.destination.index))
            ids.push(columnOrder[result.destination.index])
            // todo: I wouldn't use the swapPositions, it works but not well. When swapping a bunch quickly the request can be slow and cause issues
            // KanbanStore.SwapPositions({ids: ids, newPositions: newPos}) // this might cause the columns to have same positions saved, then one goes missing
            // KanbanStore.SwapPositions({
            //     IDOne: ids[0],// columnOrder[result.source.index],
            //     IDTwo: ids[1],//columnOrder[result.destination.index],
            //     PositionOne: newPos[0],//result.source.index,
            //     PositionTwo: newPos[1],//result.destination.index)
            // })
            return;
        }

        // moving between lists
        const sourceColumn = props.columns[result.source.droppableId];
        const destinationColumn = props.columns[result.destination.droppableId];
        let item = sourceColumn.cards[result.source.index];

        // reordering in same list
        if (result.source.droppableId === result.destination.droppableId) {
            const column = columns[result.source.droppableId];
            const items = reorderCards(
                column.cards,
                result.source.index,
                result.destination.index
            );

            // updating column entry
            const newState = {
                columns: {
                    ...columns,
                    [column.id]: {
                        ...column,
                        cards: items
                    }
                }
            };

            updateColumns(newState.columns)

            return;
        }


        // 1. remove item from source column
        const newSourceColumn = {
            ...sourceColumn,
            cards: [...sourceColumn.cards]
        };
        newSourceColumn.cards.splice(result.source.index, 1);

        // 2. insert into destination column
        if (!destinationColumn.cards) {

        }
        const newDestinationColumn = {
            ...destinationColumn,
            cards: [...destinationColumn.cards]
        };

        if (destinationColumn.text == 'QA' || destinationColumn.id == 'bf2e242d-525c-4218-a2e3-19d8602494c3') {
            item.status = 'QA'
            item.listId = `bf2e242d-525c-4218-a2e3-19d8602494c3`
        }

        // in line modification of items
        newDestinationColumn.cards.splice(result.destination.index, 0, item);

        const newState = {
            columns: {
                ...columns,
                [newSourceColumn.id]: newSourceColumn,
                [newDestinationColumn.id]: newDestinationColumn
            }
        };
        // updateColumns(newState.columns)
        let orgTable = mainCtx.activeOrganization.name

        if (orgTable == "KIMBERLY CLARK") {
            orgTable = "KC"
        }
        updateColumns(newState.columns)
        //console.log("destinationColumn", destinationColumn)
        //console.log("newDestinationColumn", newDestinationColumn)
        if (destinationColumn.text == 'Completed' || destinationColumn.id == 'bf2e242d-525c-4218-a2e3-19d8602494c3') {
            KanbanStore.UpdateMultiple({
                ID: "('" + item.id + "')",
                valueChange: newDestinationColumn.id,
                columnName: "list_id",
                organisation: orgTable,
                UserId: mainCtx.user.id
            })
            ActionLogStore.InsertOne({
                userID: mainCtx.user.id,
                orgID: mainCtx.activeOrganization.id,
                cardID: item.id,
                actionType: "Opportunity Completed",
                timezone: "",
                clientName: mainCtx.activeOrganization.name
            })
            // TODO: Needs to look at context to find card assignees
            // NotificationStore.CreateOne({
            //     ClientName: mainCtx.activeOrganization.name,
            //     UserId: mainCtx.user.id,
            //     CardId: item.id,
            //     ActionType: "Updated",
            //     Description: "",
            //     Platform: "Internal",
            //     IsRead: false
            // })
            KanbanStore.AssignMultiUsers({
                assignUsers: [mainCtx.user.id],
                cardIDs: [item.id],
                client: mainCtx.activeOrganization.name,
                userID: mainCtx.user.id
            })
            NotificationStore.CreateOne({
                ClientName: mainCtx.activeOrganization.name,
                UserId: mainCtx.user.id,
                CardId: item.id,
                ActionType: "Assigned",
                Description: "",
                Platform: "Internal",
                IsRead: false
            })
            ActionLogStore.InsertOne({
                userID: mainCtx.user.id,
                orgID: mainCtx.activeOrganization.id,
                cardID: item.id,
                actionType: "Opportunity Assigned",
                assignedUserID: mainCtx.user.id,
                timezone: "",
                clientName: mainCtx.activeOrganization.name
            })
            // KanbanStore.UpdateOne({ID: item.id, ColumnVal: "QA", ColumnName: "status", Org: orgTable, UserId:mainCtx.user.id}) // I think this needs to create an archive card or something
        } else if (destinationColumn.text == 'To Do' || destinationColumn.id == '3f504149-d481-497e-b7d1-a450ff10d82e') {
            KanbanStore.UpdateMultiple({
                ID: "('" + item.id + "')",
                valueChange: newDestinationColumn.id,
                columnName: "list_id",
                organisation: orgTable,
                UserId: mainCtx.user.id
            })
            ActionLogStore.InsertOne({
                userID: mainCtx.user.id,
                orgID: mainCtx.activeOrganization.id,
                cardID: item.id,
                actionType: "Opportunity To Do",
                timezone: "",
                clientName: mainCtx.activeOrganization.name
            })
            // TODO: Needs to look at context to find card assignees
            // NotificationStore.CreateOne({
            //     ClientName: mainCtx.activeOrganization.name,
            //     UserId: mainCtx.user.id,
            //     CardId: item.id,
            //     ActionType: "Updated",
            //     Description: "",
            //     Platform: "Internal",
            //     IsRead: false
            // })
            KanbanStore.AssignMultiUsers({
                assignUsers: [mainCtx.user.id],
                cardIDs: [item.id],
                client: mainCtx.activeOrganization.name,
                userID: mainCtx.user.id
            })
            ActionLogStore.InsertOne({
                userID: mainCtx.user.id,
                orgID: mainCtx.activeOrganization.id,
                cardID: item.id,
                actionType: "Opportunity Assigned",
                assignedUserID: mainCtx.user.id,
                timezone: "",
                clientName: mainCtx.activeOrganization.name
            })
            NotificationStore.CreateOne({
                ClientName: mainCtx.activeOrganization.name,
                UserId: mainCtx.user.id,
                CardId: item.id,
                ActionType: "Assigned",
                Description: "",
                Platform: "Internal",
                IsRead: false
            })
        } else if (destinationColumn.text == 'In Progress' || destinationColumn.id == 'ce5c6948-f521-4c52-bf53-45df4c2c0723') {
            KanbanStore.UpdateMultiple({
                ID: "('" + item.id + "')",
                valueChange: newDestinationColumn.id,
                columnName: "list_id",
                organisation: orgTable,
                UserId: mainCtx.user.id
            })
            ActionLogStore.InsertOne({
                userID: mainCtx.user.id,
                orgID: mainCtx.activeOrganization.id,
                cardID: item.id,
                actionType: "Opportunity In Progress",
                timezone: "",
                clientName: mainCtx.activeOrganization.name
            })
            // TODO: Needs to look at context to find card assignees
            // NotificationStore.CreateOne({
            //     ClientName: mainCtx.activeOrganization.name,
            //     UserId: mainCtx.user.id,
            //     CardId: item.id,
            //     ActionType: "Updated",
            //     Description: "",
            //     Platform: "Internal",
            //     IsRead: false
            // })
        } else {
            KanbanStore.UpdateMultiple({
                ID: "('" + item.id + "')",
                valueChange: newDestinationColumn.id,
                columnName: "list_id",
                organisation: orgTable,
                UserId: mainCtx.user.id
            })
        }
        // if (item.assignedTo.length <= 0) {
        //     assignUser(item, mainCtx.user.id, newDestinationColumn.id)
        // }
        keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
        // updateColumns(newState.columns)
        // ctx.actions.snackBar('Card Moved','success')

    }

    return (
        <>
            {loaded &&
                <Fade in={true}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            justifyContent: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                px: 1,
                                py: 3,

                            }}
                        >
                            <Container maxWidth={settings.compact ? 'xl' : false} disableGutters>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable
                                        droppableId="all-droppables"
                                        direction="horizontal"
                                        type="column"
                                    >
                                        {provided => (
                                            <Box
                                                className="columns"
                                                sx={{
                                                    display: 'flex'
                                                }}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-evenly"
                                                    spacing={2}
                                                >

                                                    {loaded && colOrder.map((columnId, index) => {
                                                        return (
                                                            <Grid item sx={{
                                                                pl: '20px'
                                                            }}
                                                                  key={columnId}>
                                                                <KanbanColumn
                                                                    org={mainCtx.activeOrganization.name}
                                                                    columnId={columnId}
                                                                    column={columns[columnId]}
                                                                    index={index}
                                                                    key={columnId}
                                                                    members={props.members}
                                                                    selectCards={props.selectCards}
                                                                    selectedCardsIDs={props.selectedCardsIDs}
                                                                    updateSelectedCards={props.updateSelectedCards}
                                                                    allLabels={props.allLabels}
                                                                />
                                                            </Grid>
                                                        )
                                                    })}
                                                    {provided.placeholder}
                                                </Grid>
                                            </Box>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Container>
                        </Box>
                        {/*<Dialog*/}
                        {/*  onClose={mainCtx.handleCardClose}*/}
                        {/*  open={mainCtx.cardOpen}*/}
                        {/*  maxWidth={"xl"}*/}
                        {/*>*/}
                        {/*    /!*<DialogTitle>Issue</DialogTitle>*!/*/}
                        {/*        {mainCtx.cardOpen ? <OpportunityCard /> : ""}*/}
                        {/*</Dialog>*/}
                    </Box>
                </Fade>
            }
        </>
    );
};

export default KanbanView;
