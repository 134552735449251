import React, {FC, useEffect, useState, useCallback} from "react";
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    Fab,
    Grid,
    LinearProgress,
    Tooltip
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Card as KanbanCardObj, KanbanStore } from "../../../../cog/keystone/card";
import GoalDialog from "./actions/goals/GoalDialog";
import LabelDialog from "../opportunitylabel/LabelDialog";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import AssignmentDialog from "./actions/assignment/AssignmentDialog";
import {useMainContext} from "../../../../contexts/MainContext";
import Goal from "../../../../cog/keystone/goals/goal/Goal";
import {GoalOpportunityStore, GoalStore} from "../../../../cog/keystone/goals";
import ExcludeModal from "../exlude/ExcludeModal";
import { OppLabel } from "../../../../cog/keystone/oppLabel/OppLabel";
import {CheckCircleOutline, DoNotDisturb, ListAlt, Send} from "@mui/icons-material";
import { FilterItem } from "../../../../cog/keystone/filter/Filter";
import {ActionLogStore} from "../../../../cog/keystone/actionLog";
import {NotificationStore} from "../../../../cog/keystone/notifications";
import {useSnackbar} from "notistack";

let dialogBody = () => {
    return (
        <div>
        </div>
    )
};
interface OpportunityCardSideBar {
    theIssue: KanbanCardObj
    multiUserToAssign: any[];
    setMultiUserToAssign: (users: any[]) => void;
    loadingAssign: boolean;
    setLoadingAssign: (state: boolean) => void;

}

const OpportunityCardSideBar : FC<OpportunityCardSideBar> = ({theIssue,setMultiUserToAssign,multiUserToAssign, setLoadingAssign,loadingAssign}) => {
    const {enqueueSnackbar} = useSnackbar();
    const keystoneCtx = useKeystoneContext();
    const mainCtx = useMainContext();
    let qaColID = ''
    let toDoColID = ''
    const emptyFilter: FilterItem[] = [];

    const [loading, setLoading] = useState<boolean>(false)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [addGoalLoading, setAddGoalLoading] = useState<boolean>(false);
    const [goals, setGoals] = useState<Goal[]>([])
    const [oppGoalIds, setOppGoalIds] = useState<string[]>([])
    const [oppLabels, setOppLabels] = useState<OppLabel[]>([])
    const [isCompletedLoading, setIsCompletedLoading] = useState<boolean>(false);
    const [completedDisabled, setCompletedDisabled] = useState<boolean>(false);
    const [isToDoLoading, setIsToDoLoading] = useState<boolean>(false);
    const [toDoDisabled, setToDoDisabled] = useState<boolean>(false);

    const checkKanbanColumns = async () => {
        if (keystoneCtx.kanbanColumns != null) {
            Object.values(keystoneCtx.kanbanColumns).map((column) => {
                if (column.name === 'Completed') {
                    qaColID = column.id
                } else if (column.name === 'To Do') {
                    toDoColID = column.id
                }
            })
        } else {
            await KanbanStore.FindAll(
                {
                    clientName: mainCtx.activeOrganization.name,
                    filter: emptyFilter,
                    PSU: {ID: "", Name: "", Tree: "", Org_Level: false, User_ID: ""}

                }).then((r) => {
                Object.values(r.columns).map((column) => {
                    if (column.name === 'Completed') {
                        qaColID = column.id
                    } else if (column.name === 'To Do') {
                        toDoColID = column.id
                    }
                })
            })
        }
    }

    const getData = async () => {
        setLoading(true)

        await keystoneCtx.fetchOppLabels()
            .then(() => processLabels())

        getGoalIds()
            .then(() =>setSelectedGoals())
            .then(() => checkKanbanColumns())
            .then(() => setIssueStatus())
            .then(() => (setLoading(false)))
    }

    const getGoalIds = async () =>{
        await GoalOpportunityStore.FindAllLinkedByOppId({opportunityID:theIssue.id, clientName:mainCtx.activeOrganization.name}).then((response)=> {
            let tempGoalIds = []
            if(response.goalOpps && response.goalOpps.length > 0){
                response.goalOpps.forEach((goalOpp) => {
                    tempGoalIds.push(goalOpp.goalID)
                })
            }

            return tempGoalIds
        }).then(setOppGoalIds)
    }

    const setSelectedGoals = async  () => {
        await GoalStore.FindAll({clientName: mainCtx.activeOrganization.name })
            .then((r)=> {
                let oppGoals: Goal[] = []
                r.goals.forEach((goal) => {
                    if(oppGoalIds.includes(goal.id,0)){
                        oppGoals.push(goal)
                    }
                })
                setGoals(oppGoals)
            })
    }

    const setIssueStatus= async () => {
            await KanbanStore.FindOne({id: theIssue.id, Org: mainCtx.activeOrganization.name}).then((r) => {
                // setCard(r.card)
                if (r.card.listId != null && r.card.listId == qaColID) {
                    setToDoDisabled(false)
                    setCompletedDisabled(true)
                } else if (r.card.listId != null && r.card.listId == toDoColID) {
                    setToDoDisabled(true)
                    setCompletedDisabled(false)
                }
            })

    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogOpen = (dialog:string) => {
        switch(dialog) {
            case 'goal': {
                dialogBody = () => {
                    return (
                        <GoalDialog
                            loadingGoal={addGoalLoading}
                            issueIdsToAssign={[theIssue.id]}
                            setLoadingGoal={setAddGoalLoading}
                            onClick={handleDialogClose}
                            setIssueGoals={processGoals}
                        />
                    );
                }
                break;
            }
            case 'label': {
                dialogBody = () => {
                    return (
                        <LabelDialog
                            labelOptions={keystoneCtx.opportunityLabels}
                            theIssue={theIssue}
                            onClick={handleDialogClose}
                            setIssueLabels={processLabels}

                        />
                    );
                }
                break;
            }
            case 'assignees': {
                dialogBody = () => {
                    return (
                        <AssignmentDialog
                            loadingAssign={loadingAssign}
                            theIssue={theIssue}
                            members={mainCtx.members}
                            multiUserToAssign={multiUserToAssign}
                            setMultiUserToAssign={setMultiUserToAssign}
                            setLoadingAssign={setLoadingAssign}
                            onClick={handleDialogClose} />
                    );
                }
                break;
            }
            case 'exclude': {
                dialogBody = () => {
                    return (
                        <ExcludeModal
                            clientName={mainCtx.activeOrganization.name}
                            issueName={theIssue.name}
                            onClick={handleDialogClose}
                            card={theIssue}
                        />
                    );
                }
                break;
            }
            default: {
                //statements;
                break;
            }
        }

        setDialogOpen(true);
    }

    const setCompleted = useCallback(async () => {
        setIsCompletedLoading(true)
        ActionLogStore.InsertOne({userID: mainCtx.user.id, orgID: mainCtx.activeOrganization.id, cardID: theIssue.id, actionType: "Opportunity Completed", timezone: "", clientName: mainCtx.activeOrganization.name})
        // TODO: Needs to look at context to find card assignees
        //     ClientName: mainCtx.activeOrganization.name,
        //     UserId: mainCtx.user.id,
        //     CardId: cardId,
        //     ActionType: "Updated",
        //     Description: "",
        //     Platform: "Internal",
        //     IsRead: false
        // })
        KanbanStore.AssignMultiUsers({
            assignUsers: [mainCtx.user.id],
            cardIDs: [theIssue.id],
            client: mainCtx.activeOrganization.name,
            userID: mainCtx.user.id
        }).then((p) => {
            theIssue.assignedTo = p.toString()
        })
        ActionLogStore.InsertOne({userID: mainCtx.user.id, orgID: mainCtx.activeOrganization.id, cardID: theIssue.id, actionType: "Opportunity Assigned", assignedUserID: mainCtx.user.id, timezone: "", clientName: mainCtx.activeOrganization.name})
        NotificationStore.CreateOne({
            ClientName: mainCtx.activeOrganization.name,
            UserId: mainCtx.user.id,
            CardId: theIssue.id,
            ActionType: "Assigned",
            Description: "",
            Platform: "Internal",
            IsRead: false
        })
        const response = await KanbanStore.UpdateMultiple({ID: '(\'' + `${theIssue.id}` + '\')', valueChange: qaColID, columnName: "list_id", organisation: mainCtx.activeOrganization.name, UserId:mainCtx.user.id})
        if (response) {
            setCompletedDisabled(true)
            setToDoDisabled(false)
            enqueueSnackbar("Add To 'QA' Pipeline", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "success",
            });
        } else {
            enqueueSnackbar("Error Adding To 'QA' Pipeline", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
        }
        setIsCompletedLoading(false)
    }, []);

    const setToDo = useCallback(async () => {
        setIsToDoLoading(true)
        ActionLogStore.InsertOne({userID: mainCtx.user.id, orgID: mainCtx.activeOrganization.id, cardID: theIssue.id, actionType: "Opportunity To Do", timezone: "", clientName: mainCtx.activeOrganization.name})
        // TODO: Needs to look at context to find card assignees
        //   NotificationStore.CreateOne({
        //       ClientName: mainCtx.activeOrganization.name,
        //       UserId: mainCtx.user.id,
        //       CardId: cardId,
        //       ActionType: "Updated",
        //       Description: "",
        //       Platform: "Internal",
        //       IsRead: false
        //   })
        KanbanStore.AssignMultiUsers({
            assignUsers: [mainCtx.user.id],
            cardIDs: [theIssue.id],
            client: mainCtx.activeOrganization.name,
            userID: mainCtx.user.id
        }).then((p) => {
            theIssue.assignedTo = p.toString()
        })
        ActionLogStore.InsertOne({userID: mainCtx.user.id, orgID: mainCtx.activeOrganization.id, cardID: theIssue.id, actionType: "Opportunity Assigned", assignedUserID: mainCtx.user.id, timezone: "", clientName: mainCtx.activeOrganization.name})
        NotificationStore.CreateOne({
            ClientName: mainCtx.activeOrganization.name,
            UserId: mainCtx.user.id,
            CardId: theIssue.id,
            ActionType: "Assigned",
            Description: "",
            Platform: "Internal",
            IsRead: false
        })
        const response = await KanbanStore.UpdateMultiple({ID: '(\'' + `${theIssue.id}` + '\')', valueChange: toDoColID, columnName: "list_id", organisation: mainCtx.activeOrganization.name, UserId:mainCtx.user.id})
        if (response) {
            setToDoDisabled(true)
            setCompletedDisabled(false)
            enqueueSnackbar("Added To 'To Do' Pipeline", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "success",
            });
        } else {
            enqueueSnackbar("Error Adding To 'To Do' Pipeline", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
        }
        setIsToDoLoading(false)
    }, []);

    const processLabels = () => {
        let Labels: OppLabel[] = []
        let tempLabels = []
        if(keystoneCtx.opportunityLabels.length >0){
            try {
                tempLabels = JSON.parse(`{"Labels": ${theIssue.opportunityLabels} }`)
            } catch (e) {
                console.log("err", e)
                return Labels
            }

            if(Object.values(tempLabels).length > 0){
                Object.values(tempLabels['Labels']).forEach((labelId)=> {
                    if(labelId != '') {
                        Labels.push(keystoneCtx.opportunityLabels.find((Label) => Label.id == labelId))
                    }
                })
            }
        }
        
        setOppLabels(Labels)
    }

    const processGoals = (issueGoals: Goal[]) => {
        setGoals(issueGoals)
    }

    const handleSendToExternal = async () => {
        const response = KanbanStore.SendToExternal({opportunityID: theIssue.id, clientName: mainCtx.activeOrganization.name})

        if (response) {
            enqueueSnackbar("Opportunity Sent", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "success",
            });
        } else {
            enqueueSnackbar("Failed to Send Opportunity!", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            {!loading ?
            <Box>
                <Grid container direction={"column"} alignItems={"stretch"} justifyContent={"center"} spacing={2}>
                    <Grid item >
                        <Tooltip title={"Goals this opportunity is attached to"}
                                 key={"Goal-tooltip"}>
                            <Button
                                variant="text"
                                onClick={() => handleDialogOpen('goal')}
                                // disabled={campaignLoading}
                            >
                                Goals
                            </Button>
                        </Tooltip>
                        <Box sx={{display: 'flex', flexDirection: 'row', mb: 1, marginLeft: 0.5}}>
                            <Stack direction="column" spacing={1}>
                                {(goals.length > 0) ?
                                        goals.map((goal, index) => <Chip label={goal.name} key={goal.name+ index} id={goal.id} size={'small'}/>)
                                        : <Chip label={"No goals assigned"} id={"0"} size={'small'} />
                                }
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Tooltip title={"Labels attached to this opportunity"} key={"Label-tooltip"}>
                            <Button variant="text" onClick={() => handleDialogOpen('label')}>
                                Labels
                            </Button>
                        </Tooltip>
                        <Box sx={{display: 'flex', flexDirection: 'row', mb: 1, marginLeft: 0.5}}>
                            <Stack direction="column" spacing={1}>
                                {(oppLabels.length > 0) ? oppLabels.map((label) =>
                                    <Chip label={label.label} id={label.id} size={'small'} key={label.id} />
                                    ) : <Chip label={"No labels"} id={"0"} size={'small'} />
                                }
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Tooltip title={"Assignees attached to this opportunity"} key={"Assignees-tooltip"}>
                            <Button variant="text" onClick={()=>handleDialogOpen('assignees')}>
                                Assignees
                            </Button>
                        </Tooltip>
                        <Box sx={{display: 'flex', flexDirection: 'row', mb: 1, marginLeft: 0.5}}>
                            <Stack direction="column" spacing={1}>
                                {multiUserToAssign.length > 0 ? multiUserToAssign.map((member) =>
                                    <Chip
                                        label={member.displayName}
                                        avatar={<Avatar
                                            src={member.avatar}
                                            alt={member.displayName}
                                            variant={"circular"}
                                            sx={{width: 24, height: 24}}
                                        />}
                                        key={member.id}
                                        id={member.id}
                                        size={'small'}
                                    />
                                ) : <Chip label={"No users assigned"} id={"0"} size={'small'} /> }
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item sx={{ mt: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', }}>
                            <Tooltip title={"Ignore Card"} PopperProps={{ modifiers: [{ name: "offset", options: { offset: [0, 20], }, },], }}>
                                <span>
                                    <Fab color="primary" onClick={() => handleDialogOpen('exclude')}>
                                        <DoNotDisturb />
                                    </Fab>
                                </span>
                            </Tooltip>
                            { !isToDoLoading ? <Tooltip title="To Do" PopperProps={{ modifiers: [{ name: "offset", options: { offset: [0, 20], }, }, ], }}>
                                <span>
                                    <Fab color="primary" disabled={toDoDisabled}
                                         onClick={() => setToDo()}
                                    >
                                        <ListAlt />
                                    </Fab>
                                </span>
                            </Tooltip> : <CircularProgress />}
                            { !isCompletedLoading ? <Tooltip title="Completed" PopperProps={{ modifiers: [{ name: "offset", options: { offset: [0, 20], }, } ], }}>
                                <span>
                                    <Fab color="primary" disabled={completedDisabled} onClick={() => setCompleted()}
                                    >
                                        <CheckCircleOutline />
                                    </Fab>
                                </span>
                            </Tooltip> : <CircularProgress />}
                            {mainCtx.activeOrganization.name === "CASALESPNP_UNILEVER_TOP10STORES100SKUS" &&
                                <Tooltip title="Send to External" PopperProps={{modifiers: [{name: "offset", options: {offset: [0, 20],}, } ], }}>
                                  <span>
                                    <Fab color="primary" onClick={() => handleSendToExternal()}>
                                      <Send />
                                    </Fab>
                                  </span>
                                </Tooltip>}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
                :
                <LinearProgress/>}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="card-level-dialog"
                fullWidth
                maxWidth="xl"
            >
                {dialogBody()}
            </Dialog>
        </div>

    )

}

export default OpportunityCardSideBar;