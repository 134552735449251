/* eslint-disable */

export default class Report {
    public id: string = '';
    public name: string = '';
    public dashboardId: string = '';

    public variableData: Record<string, any> = {};

    public cronString: string = '';
    public emailList: string = '';

    constructor(report?: Report) {
        if (!report) {
            return;
        }
        this.id = report.id;
        this.name = report.name;
        this.dashboardId = report.dashboardId;

        this.variableData = report.variableData;

        this.cronString = report.cronString;
        this.emailList = report.emailList;
    }
}
