import type { FC } from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import {
  Box
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from "apexcharts";
import currencyFormatter from "../../../../../../utils/currencyFormatter";
import currencySwitcher from "../../../../../../utils/currencySwitcher";
import { FilterItem } from "../../../../../../cog/keystone/filter/Filter";
import { Filter as FilterObject, FilterStore } from "../../../../../../cog/keystone/filter";
import { useMainContext } from "../../../../../../contexts/MainContext";
import { useNavigate } from "react-router-dom";
import { useKeystoneContext } from "../../../../../../contexts/KeystoneContext";
import { useContext } from "react";
import SettingsContext from "../../../../../../contexts/SettingsContext";

interface LandingColumnProps {
  data: {};
  org: string;
  labelValue: string;
  header: string;
}

const OverviewColumn: FC<LandingColumnProps> = ({org, data, labelValue, header}) => {
  const theme = useTheme();
  const chartType = "bar";
  let formatter = Intl.NumberFormat('en', {notation: 'compact'})
  const mainCtx = useMainContext()
  const keystoneCtx = useKeystoneContext()
  const settingsCtx = useContext(SettingsContext)
  const navigate = useNavigate()

  const removeNull = (array) => {
    return array.map((value) => {
      if (value === null) {
        return 0;
      }
      return value;
    });
  }

  const columnName = labelValue
  const seriesCols = Object.keys(data).filter((k) => k != columnName)
  const series = seriesCols ? seriesCols.map((c) => {
    return {
      name: c,
      data: data && data[c] ? removeNull(data[c]) : []
    }
  }) : [{name: "y", data: []}]

  series.unshift(series.pop())

  const updateFilter = async () => {
    keystoneCtx.RefreshCards()
    const org = mainCtx.activeOrganization.name.replace(/ |_/g,'-');
    navigate("/app/" + org.toLowerCase() + "/opportunities")
  }

  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      stacked: false,
      background: useTheme().palette.background.paper,
      foreColor: useTheme().palette.text.primary,
      events: {
        dataPointSelection(event, chartContext, config) {
          const formatted = series[config.seriesIndex].name.replaceAll("_", " ")

          const oppTypeFilter: FilterItem = {
            header: "opportunity_type",
            operation: "=",
            prefix: "AND",
            values: formatted
          }

          const categoryFilter: FilterItem = {
            header: header,
            operation: "=",
            prefix: "AND",
            values: data[columnName][config.dataPointIndex]
          }

          const filters: FilterItem[] = []

          if (series[config.seriesIndex].name == "Value") {
            filters.push(categoryFilter)

            const filteredFilter = filters.filter((f) => !f.level)
            const filteredWithDefault = filteredFilter.concat(keystoneCtx.filters)

            keystoneCtx.setClickThroughFilters(filteredWithDefault)
            updateFilter()
          } else {
            filters.push(oppTypeFilter)
            filters.push(categoryFilter)

            const filteredFilter = filters.filter((f) => !f.level)
            const filteredWithDefault = filteredFilter.concat(keystoneCtx.filters)

            keystoneCtx.setClickThroughFilters(filteredWithDefault)
            // keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
            updateFilter()
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      }
    },
    xaxis: {
      type: 'category',
      tickAmount: 'dataPoints',
      labels: {
        rotateAlways: true,
        rotate: -60,
        trim: true,
      },
      tickPlacement: 'on',
      categories: data && data[columnName] ? data[columnName] : [],
      // title: {
      //   text: labelValue,
      // },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return currencySwitcher(org) + formatter.format(val)
        }
      },
      title: {
        text: "Total Opportunity Value",
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: 'bottom',
    },
    grid: {
      padding: {
        bottom: 20,
        left: 80,
      }
    },
    colors: ["#3aa9a6", "#4b4b4b", "#f2bc53", "#f45c5c", "#e1d5c6", "#8c9494"],
    tooltip: {
      y: {
        formatter: function (val) {
          return currencySwitcher(org) + formatter.format(val)
        }
      },
      theme: settingsCtx.settings.theme === 'LIGHT' ? 'light' : 'dark'
    },
  };

  return (
    <Box sx={{ minHeight: 500, minWidth: 300 }}>
      <Chart
        type={chartType}
        options={chartOptions}
        height={"90%"}
        width={"90%"}
        series={series}
      />
    </Box>
  );
}

export default OverviewColumn;