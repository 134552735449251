import React, {useCallback, useEffect, useState} from "react";
import Board from "./Board";
import LeaderSummary from "./LeaderSummary";
import {Box} from "@mui/system";
import {Leaderboard as LeaderboardType, LeaderboardStore} from "../../../../cog/keystone/leaderboard";
import PeriodSelector from "./PeriodSelector";
import {CircularProgress, LinearProgress} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {Helmet} from "react-helmet-async";

export interface LeaderboardItemProps {
    position: number,
    avatar: string,
    username: string,
    value: number
    difference: number
}

export interface LeaderboardProps {
    items: LeaderboardType[]
}

export interface Periods {
}


export interface PeriodProps {
    periods: LeaderboardItemProps[]
}

interface dates {
    StartDate: Date
    EndDate: Date
}

// const StartDate = Date.getDay()

const Leaderboard = () => {

    const [leaderboardItems, setLeaderboardItems] = useState<LeaderboardType[]>([])
    const [period, setPeriod] = useState<string>("Month")
    const [isLoading, setIsLoading] = useState<boolean>(true)


    const checkDate = (value?) => {
        changeLoading(true)
        let tDates = {
            StartDate: new Date,
            EndDate: new Date
        }
        if (!value) {
            value = "Month"
        }


        if (value == "Week") {
            tDates.StartDate.setDate(new Date().getDate() - 7)
            setPeriod(value)
        } else if (value == "Month") {
            tDates.StartDate.setMonth(new Date().getMonth() - 1)
            setPeriod(value)
        } else if (value == "Year") {
            tDates.StartDate.setFullYear(new Date().getFullYear() - 1)
            setPeriod(value)
        }

        fetchAllDashboards(tDates)
        // console.log("ja ",fetchAllDashboards(tDates))
    }

    const changePeriod = (value: string) => {
        setPeriod(value)
    }

    const changeLoading = (value: boolean) => {
        setIsLoading(value)
    }

    const fetchAllDashboards = useCallback(async (dates: dates) => {
        let response = await LeaderboardStore.FindOne({
            StartDate: dates.StartDate.toISOString().slice(0, 10),
            EndDate: dates.EndDate.toISOString().slice(0, 10)
        })
        if (response.leaderboard != null) {
            setLeaderboardItems(response.leaderboard)
            changeLoading(false)
        } else {
            setLeaderboardItems([])
            changeLoading(false)
        }
    }, [])

    useEffect(() => {
        checkDate()
    }, [fetchAllDashboards])

    useEffect(() => {
        checkDate(period)
    }, [period])

    return (
        <Box sx={{
            paddingY: 1,
            marginY: 1,
            overflow: 'hidden',
        }}>
            <Helmet>
                <title>Leader Board</title>
            </Helmet>
            {(isLoading ?
                <LinearProgress
                    sx={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginTop: 2,
                        justifySelf: "center"
                    }} />
                :
            <Box sx={{
                background: useTheme().palette.background.paper,
                boxShadow: useTheme().shadows[2],
                borderRadius: '20px',
                padding: 1,
                margin: 2,
            }}>
                <Box>
                    <PeriodSelector period={period} periodAction={changePeriod} loadingAction={changeLoading}/>
                    <LeaderSummary items={leaderboardItems}/>
                    <Board items={leaderboardItems}/>
                </Box>
            </Box>
            )}
        </Box>
    )
}

export default Leaderboard