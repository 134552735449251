import type {FC} from 'react';
import {Box, Divider} from '@mui/material';
import KanbanView from "./KanbanView";
import KanbanViewMobile from "./KanbanViewMobile";
import Loader from "./util/Loader";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {useMainContext} from "../../../../contexts/MainContext";
import {Member, MemberStore} from "../../../../cog/keystone/member";
import LoaderHeader from "./util/LoaderHeader";
// import CardsToFilters from "./header/KanbanFilterLogic";
import {Card as KanbanCardObj, KanbanColumnObj, KanbanStore} from "../../../../cog/keystone/card";
import gtm from "../../../../lib/gtm";
// import {KanbanFilterStore} from "../../../../cog/keystone/filter";
import objFromArray from "../../../../utils/objFromArray";
// import {DKSHIgnoredFields, IgnoredFields} from "../../../../cog/keystone/filter/KanbanFilter";
import {Helmet} from "react-helmet-async";
// import React from "react";
import {useParams} from "react-router";
import BasicDetailPanels from "../listview/DataGridDetail";
import DataGridDetail from "../listview/DataGridDetail";
import AntDesignGrid from "../listview/CustomDataGridTheme";
import {ThemeProvider} from "@mui/styles";
import {createTheme, Theme, useTheme} from "@mui/material/styles";
import {Float2int} from "../../../../utils/floatToInt";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";

interface KanbanProps {
    columns: Record<string, KanbanColumnObj>
    allcards: Record<string, KanbanCardObj>
    members: Member[]
    setColumns: (newColumns: Record<string, KanbanColumnObj>) => void
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: string, vName: string) => void
    allLabels : OppLabel[]
}

const Kanban: FC<KanbanProps> = (props) => {
    let width = window.innerWidth

    const mainCtx = useMainContext() // for users
    const [isLoading, setIsLoading] = useState<boolean>(true); // to avoid passing nulls to props
    // const [columns, setColumns] = useState<Record<string, KanbanColumnObj>>(props.columns); // for KanbanView
    // const [allCards, setAllCards] = useState<Record<string, KanbanCardObj>>(props.allcards); // unfiltered cards
    // const [mvaluePotentialGain, setMvaluePotentialGain] = useState<number>(0); // filtered total gained
    // const [mvaluePotentialLoss, setMvaluePotentialLoss] = useState<number>(0); // filtered total opportunity
    // const [members, setMembers] = useState<Record<string, Member>>(props.members) // right now this is unused, meant for the pictures in the cards, but I can't figure out how to give it to row

    // const [filter, setFilter] = useState<Record<string, any>>(null) // List of filterable fields
    // const [filteredCards, setFilteredCards] = useState<Record<string, KanbanCardObj>>(null);// filtered cards
    // const [chippy, setChippy] = useState(null) // used to determine what is being filtered

    const [canAccess, setCanAccess] = useState<boolean>(false)
    const [myTheme, setMyTheme] = useState<Theme>(useTheme())

    // returns a saved filter
    // const fetchSavedFilter = async (req: { userID:any, orgID:any }) => {
    //     let outFilter: Record<string, Record<string, any>>
    //     outFilter = await KanbanStore.FindFilter(req)
    //     if (outFilter == undefined || null) {
    //         return null
    //     }
    //     return outFilter;
    // }

    // allows the filter to update the chips being displayed
    // const pushChippy = (chips: any[]) => {
    //     setChippy([])
    //     setChippy(chips)
    // }

    // Think this one is just for when the saved filter is fetched, to get their chips
    // const assessChips = (inFilter: Record<string, Record<string, any>>)=> {
    //     setIsLoading(true)
    //     if (inFilter == undefined || inFilter.filter == undefined) {
    //         setChippy([])
    //     } else {
    //         setChippy(inFilter.filter.chips.split(","))
    //     }
    // }

    // If allCards changes, we get the filterable fields and set filter
    // useEffect(() => {
    //     if (filteredCards == null && filter != null && chippy != null) {
    //         filterByChip() // only for the initial load
    //     }
    // }, [chippy]);

    // If allCards changes, we get the filterable fields and set filter
    // useEffect(() => {
    //     if (mainCtx.activeOrganization.name == "DKSH") {
    //         setFilter(CardsToFilters(allCards, DKSHIgnoredFields));
    //     } else {
    //         setFilter(CardsToFilters(allCards, IgnoredFields));
    //     }
    //     gtm.push({event: 'page_view'});
    // }, [allCards]);


    // Updates The Mvalues For the Dashboard
    // const updateMValues = (cards?: KanbanCardObj[]) => {
    //     // logStates("updateMValues Start", cards, "cards")
    //     if (isLoading == false) {
    //         setIsLoading(true)
    //     }
    //     let gains = 0
    //     let loss = 0
    //     if (cards) {
    //         Object.entries(cards).forEach(
    //             ([key, value]) =>
    //                 (value.listId == 'bf2e242d-525c-4218-a2e3-19d8602494c3' ?
    //                     gains = gains + Float2int(value.mValue)
    //                     : loss = loss + Float2int(value.mValue)))
    //
    //         setMvaluePotentialGain(gains)
    //         setMvaluePotentialLoss(loss)
    //     }
    // }
    //
    // // receives already filtered cards, and sets the columns and filtered cards states
    // const filterUpdate = (cards: KanbanCardObj[]) => {
    //     setIsLoading(true)
    //     let tempFilterUpdateReturn = KanbanFilterStore.filterUpdate(cards, columns)
    //     setFilteredCards(objFromArray(tempFilterUpdateReturn.cards, 'id'))
    //     updateMValues(tempFilterUpdateReturn.cards)
    //     setColumns(tempFilterUpdateReturn.columns)
    // }
    //
    // const filterByChip = () => {
    //     let ReturnedFilter: Record<string, Record<string, string | number>> = {}
    //     // @ts-ignore
    //     if (chippy != []) {
    //         chippy.map((chip) => {
    //             let chipStr = chip.split('|')[0].trim()
    //             Object.keys(filter).map((nK) => {
    //                 Object.keys(filter[nK]).map((cV) => {
    //                     if (cV == chipStr) {
    //                         let cenRecord: Record<string, string | number> = {}
    //                         cenRecord[cV] = filter[nK][cV]
    //                         ReturnedFilter[nK] = {...ReturnedFilter[nK], ...cenRecord}
    //                     }
    //                 })
    //             })
    //         })
    //     } else if (allCards != null){
    //         setFilteredCards(allCards)
    //     }
    //     updateMValues(Object.values(filtersToCards(ReturnedFilter)))
    // }
    //
    // // Receives fields for to filter for, and returns filtered cards
    // const filtersToCards = (inFilter: Record<string, Record<string, any>>) => {
    //     // I think the returned filter here is unused,
    //     let tempFiltersToCards = KanbanFilterStore.filtersToCards(inFilter, allCards, columns)
    //     filterUpdate(Object.values(tempFiltersToCards.allCards))
    //
    //     return tempFiltersToCards.allCards
    // }
    //
    // // Saves a filter
    // const saveFilter = (filterOut: Record<string, Record<string, any>>, chipsOut: string[]) => {
    //     const request = {
    //         UserId: mainCtx.user.id,
    //         Filter: filterOut,
    //         Name: "KANBANFILTER",
    //         Chips: chipsOut,
    //         OrgId: mainCtx.activeOrganization.id
    //     }
    //     KanbanStore.SaveFilter(request)
    // }

    // Gets all cards given an org name
    // const getCards = async (): Promise<any> => {
    //     try {
    //         const response = await KanbanStore.FindAll({clientName: mainCtx.activeOrganization.name, IncludeFilter: [], ExcludeFilter: []}).then((r) => {
    //             return r
    //         })
    //         return response
    //
    //     } catch (e) {
    //         console.error(`${e}`);
    //     }
    // };

    // Updates the columns, used by KanbanView to make the drag and drop not break
    const updateColumns = (newColumns: Record<string, KanbanColumnObj>) => {
        props.setColumns(newColumns)
    }

    // Gets the members
    // const doMembers = (async () => {
    //     let membersIssue = await MemberStore.FindMany({orgID: mainCtx.activeOrganization.id})
    //     return membersIssue.members
    // })

    // Calls all the things needed to get the page to show tings
    // useLayoutEffect(()=> {
    //     logStates("UseLayoutEffect", "","")
    //     // if (mainCtx.activeOrganization.name == "DKSH") {
    //     //     setFilter(CardsToFilters(allCards, DKSHIgnoredFields));
    //     // } else {
    //     //     setFilter(CardsToFilters(allCards, IgnoredFields));
    //     // }
    //     // populateColumns()
    //     fetchSavedFilter({userID: mainCtx.user.id, orgID: mainCtx.activeOrganization.id}).then(r => {
    //         setFilter(r.filter.filter)
    //         assessChips(r)
    //     }).then(() => {
    //         // if (filteredCards == null && filter != null && chippy != null) {
    //         //     filterByChip() // only for the initial load
    //         // }
    //     })
    //
    //     // gtm.push({event: 'page_view'});
    //     // doMembers().then(r => setMembers(r))
    //     logStates("UseLayoutEffect", "","")
    // }, [])

    // Populates the columns and cards
    // const populateColumns = () => {
    //     Promise.resolve().then(() => getCards().then(r => {
    //         setAllCards(r.allCards)
    //         setColumns(r.columns)
    //         }))
    // }

    // This one just checks that we can set loading to false, and also lets us filter ze cards if it hasn't been
    useEffect(() => {
        // logStates("useEffect", "", "")
        if (props.allcards != null) {
            if (props.columns != null) {
                // if (filteredCards == null && filter != null && chippy != null) {
                //     filterByChip() // only for the initial load
                // }
                if (isLoading) {
                    // if (members != null && filteredCards != null) {

                        if (mainCtx.user.id == '60e6c826290c670071f966ff')
                        {
                            setCanAccess(true) // hacky way to let cam be only access
                        }

                        setIsLoading(false) // nothing is null, so loading can happen
                    // }
                }
            }
        }
    },[props.allcards, props.columns, isLoading])
    // },[allCards, columns, isLoading, filteredCards, filter])

    return (
        <>
            {/*<KanbanContext>*/}
            {/*<ThemeProvider theme={myTheme}>*/} 
            <Helmet>
                <title>Kanban</title>
            </Helmet>
                <div>
                    {/*<Box sx={{*/}
                    {/*    // backgroundColor: 'background.default',*/}
                    {/*    display: 'flex',*/}
                    {/*    flexDirection: 'column',*/}
                    {/*    height: '100%',*/}
                    {/*    overflow: 'hidden'*/}
                    {/*}}>*/}
                    {/*    {isLoading ? <LoaderHeader/> : mainCtx.user.id == '60e6c826290c670071f966ff' ? <Header allCards={filteredCards}*/}
                    {/*        potentialGain={mvaluePotentialGain}*/}
                    {/*        potentialLoss={mvaluePotentialLoss}*/}
                    {/*        columns={columns}*/}
                    {/*        saveFilter={saveFilter}*/}
                    {/*        filtersToCards={filtersToCards}*/}
                    {/*        filter={filter}*/}
                    {/*        chippy={chippy}*/}
                    {/*        pushChippy={pushChippy}*/}
                    {/*        /> : <Box sx={{color: myTheme.palette.error.main}}>Cannot Access Permission Denied</Box>}*/}
                    {/*</Box>*/}
                    {/*<Divider variant="middle"/>*/}
                    <Box
                        sx={{
                            backgroundColor: 'background.default',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            overflow: 'hidden'
                        }}>

                        {/*{mainCtx.user.id == '60e6c826290c670071f966ff' ?*/}
                            <KanbanView
                                columns={props.columns}
                                all_cards={props.allcards}
                                updateCols={updateColumns}
                                members={props.members}
                                setColumns={props.setColumns}
                                selectCards={props.selectCards}
                                selectedCardsIDs={props.selectedCardsIDs}
                                updateSelectedCards={props.updateSelectedCards}
                                allLabels = {props.allLabels}
                            />
                            {/*// : <Box sx={{color: myTheme.palette.error.main}}>Cannot Access Permission Denied</Box>}*/}
                        {/*//<KanbanViewMobile columns={columns} all_cards={filteredCards} updateCols={updateColumns} members={members}/>}*/}
                    </Box>
                </div>
             {/*</ThemeProvider>*/}
        </>
    );
};

export default Kanban;
