import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import {
    Box,
    Grid,
    ThemeProvider,
    Tooltip,
    Typography
} from "@mui/material";
import {Card as KanbanCardObj} from "../../../../cog/keystone/card";
import {useTheme} from "@mui/material/styles";
import {Member} from "../../../../cog/keystone/member";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";
import CustomizedMenus from "./IssueCardMenu";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {useMainContext} from "../../../../contexts/MainContext";
import {useNavigate} from "react-router-dom";
import OppValueDisplay from "./IssueCardComponents/OppValueDisplay";
import IssueDescription from "./IssueCardComponents/IssueDescription";
import StatusAndAssignee from "./IssueCardComponents/StatusAndAssignee";


interface IssueCardProps {
    org: string
    issue: KanbanCardObj
    index: number
    members: Member[]
    selected: boolean
    allLabels: OppLabel[]
}

// IssueCard: A single Card for the home screen of keystone
const IssueCard: FC<IssueCardProps> = (props) => {
    // todo: replace gain section with text card
    const keystoneCtx = useKeystoneContext()
    const mainCtx = useMainContext()
    const[openDetails, setOpenDetails] = useState<boolean>(false)
    const ref = useRef(null)
    let navigate = useNavigate()
    let formatter = Intl.NumberFormat('en', {notation: 'compact'});
    let org = props.org


    if (props.issue && props.issue.region && props.issue.region == "MY") {
        org = "DKSH MY"
    } else if (props.issue && props.issue.region && props.issue.region == "HK") {
        org = "DKSH HK"
    }

    // const [myTheme, setMyTheme] = useState<Theme>(useTheme())
    // const [myAssignees, setMyAssignees] = useState<Member[]>([])

    const AssigneesToList = ()=>{
        let test = []
        try {
            test = JSON.parse(`{"AssignedTo": ${props.issue.assignedTo} }`)['AssignedTo']
        } catch (e) {
            // console.log(props.v)
            return test
        }
        //console.log("IssueCard AssigneesToList test", test)
        return test
    }

    const toggleDetailsOpen = () =>{
        console.log("toggleDetailsOpen")
        setOpenDetails((prevOpen)=> !prevOpen)
    }


    return <ThemeProvider theme={useTheme()}>
        <Box
            onClick={(e) => {
                // console.log("e")
                if (e.isPropagationStopped() || e.currentTarget == null) {
                    return;
                }
                if (keystoneCtx.selectCards) {
                    e.stopPropagation()
                    // console.log(props.issue.id)
                    keystoneCtx.updateSelectedCards(props.issue.id, props.issue.name)
                } else {
                    // !props.dragging && keystoneCtx.handleCardId(ti.id)
                    navigate('/app/' + mainCtx.activeOrganization.slug + '/opportunity/' + props.issue.id)
                }
            }}
            sx={{
                p: '20px',
                m: '10px',
                boxShadow: useTheme().shadows[3],
                backgroundColor: props.selected == false ? ( useTheme().palette.mode === 'dark' ? "#384762" : useTheme().palette.background.paper): useTheme().palette.background.default,
                borderRadius: 3,
                border: props.selected == false ? `1px solid ${useTheme().palette.divider}` : `1px solid ${useTheme().palette.success.main}`,
                animation: props.selected == true && "shake 2s cubic-bezier(.36,.07,.19,.97) both",
                animationIterationCount: props.selected == true && "infinite",
                "@keyframes shake": {
                    "0%, 100%": {transform: "translate(0px, 0px) rotate(0deg)"},
                    "10%, 90%": {transform: "translate(0px, 0px) rotate(1deg)"},
                    "20%, 80%": {transform: "translate(0px, 0px) rotate(0deg)"},
                    "30%, 70%": {transform: "translate(0px, 0px) rotate(-1deg)"},
                    "40%, 60%": {transform: "translate(0px, 0px) rotate(0deg)"},
                    "50%": {transform: "translate(0px, 0px) rotate(1deg)"},
                }
                // minWidth: "30rem",
                // maxWidth: "40rem"
            }}
        >
            <Grid container>
                <Grid item xs={10}>
                    <Tooltip title={props.issue.name} placement="top">
                        <Typography variant="h4" minHeight={50} noWrap>
                            {props.issue.name}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={2} textAlign={"right"}>
                    <CustomizedMenus oppCard={props.issue} toggleDetailsOpen={toggleDetailsOpen}></CustomizedMenus>
                </Grid>
            </Grid>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                <OppValueDisplay org={org} mValue={props.issue.mValue} createdOn={props.issue.createdOn} />
            </Box>
            <IssueDescription issue={props.issue} isDescriptionOpen={openDetails} toggleDetailsOpen={toggleDetailsOpen}/>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <StatusAndAssignee assignees={AssigneesToList()} status={props.issue.status}/>
            </Box>
        </Box>
    </ThemeProvider>
}

export default IssueCard