import {FC} from "react";
import {Box} from "@mui/system";
import {Avatar, Typography} from "@mui/material";
import moment from "moment";
import {Member} from "../../../../cog/keystone/member";

interface CommentCardProps {
    id?: string,
    itemId?: string,
    user: Member,
    content: string,
    timestamp: string
}

const CommentCard: FC<CommentCardProps> = ({content, timestamp, user}) => {
    const formattedTimestamp = moment(timestamp).format("DD-MMM:hh:mm A")
    // console.log("user: ",user)
    //avatar/user, date, message
    const formattedContent = content.replaceAll(new RegExp('\\(\\S*\\)', "g"), "")
        .replaceAll('@[', "").replaceAll(']', '')
    return (
        <Box mt={0} display="flex" justifyContent="flex-left" flexDirection="row" alignItems="flex-start" width={"100%"} >
            <Box sx={{padding: "10px"}}>
              {user && user.avatar && <Avatar
                alt={``}
                src={user.avatar}
              />}
            </Box>
            <Box mt={0} display="flex" justifyContent="flex-left" flexDirection="column" alignItems="flex-start">
                <Box display="flex" flexDirection="row">
                    {user && user.displayName &&
                        <Typography variant={"h4"}
                                sx={{
                                    paddingRight: "10px",
                                    fontSize: {
                                        lg: '1.25rem',
                                        md: '1.25rem',
                                        sm: '1rem',
                                        xs: '0.75rem'
                                    }
                                }}>
                        {user.displayName}
                    </Typography>
                    }
                    <Typography variant="overline" color="textSecondary"
                                sx={{
                                    fontSize: {
                                        lg: '0.75rem',
                                        md: '0.75rem',
                                        sm: '0.65rem',
                                        xs: '0.55rem'
                                    }
                                }}>
                        {formattedTimestamp}
                    </Typography>
                </Box>
                <Typography sx={{
                    fontSize: {
                        lg: '1rem',
                        md: '1rem',
                        sm: '0.75rem',
                        xs: '0.70rem'
                    }
                }}>
                    {formattedContent}
                </Typography>
            </Box>
        </Box>
    );
};

export default CommentCard;