/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../api/jsonrpc';
// import {useEffect, useRef, useState} from 'react';
import Organization from "./Organization";

export interface FindAllRequest {
}

export interface FindAllResponse {
    organizations: Organization[];
}

export interface CreateOneRequest {
    orgName: string
}

export interface CreateOneResponse {
    organizations: Organization
}

const Store = {
    serviceProvider: 'Organization-Store',
    async FindAll(request: FindAllRequest): Promise<FindAllResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAll`,
            request: [{}]
        });
        // console.log(response)
        return {
            organizations: response.organizations.map((mem) => (
                    new Organization(mem)
                )
            ),
        };
    },

    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{"organization": {
                "name": request.orgName
            }}]
        });
       return response
    }
};

export default Store;
