/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import {FC} from "react";
import {Box, Paper, Typography, Grid} from "@mui/material";
import BasicWidget from "../BasicWidget";
import {WidgetProps} from "../../../WidgetSwitcher";


const LineInst: FC<WidgetProps> = ({widget}) => {
    return (
        <hr
            style={{
                color: "grey",
                backgroundColor: "grey",
                height: 3
            }}
        />
    );
}

const BasicLineDivider: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<LineInst />}/>
}

export default BasicLineDivider
