import * as React from "react";
import {FC, useLayoutEffect, useState} from "react";
import {Box, ButtonBase, Divider, Tooltip, Typography} from "@mui/material";
import IssueCard from "./IssueCard";
import {useNavigate} from "react-router-dom";
import {Card as KanbanCardObj} from "../../../../cog/keystone/card";
import {Member} from "../../../../cog/keystone/member";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTheme} from "@mui/material/styles";

import {useMainContext} from "../../../../contexts/MainContext";
import {useParams} from "react-router";
import {ArrowLeft} from "@mui/icons-material";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {indexOf} from "lodash";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";


function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                // background: "red",
                backgroundColor: "blue",
                color: "black"
            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <ButtonBase
            className={className}
            onClick={onClick}
            style={{
                ...style,
                // display: "block",
                // background: useTheme().palette.text.primary,
                // backgroundBlendMode: "darken",
                // // opacity: 0.1,
                // // backgroundColor: "blue",
                // // color: "black",
                // position: 'absolute',
                zIndex: 1,
                // height: '100%',
                // width: '100%',
            }}
        >
            <ArrowLeft color={"primary"} fontSize={"large"}/>
        </ButtonBase>
    );
}


interface IssueCardsProps {
    theIssues: KanbanCardObj[]
    members: Member[]
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: string, vName: string) => void
    allLabels: OppLabel[]
}

// Receives issue cards, sorts them by opportunity type or level and displays them with sliding views
const IssueCards: FC<IssueCardsProps> = (props) => {
    const navigate = useNavigate()
    const keystoneCtx = useKeystoneContext()
    const mainCtx = useMainContext()
    const [issueOpTyped, setIssueOpType] = useState<Record<string, KanbanCardObj[]>>(null)
    const [issueLevel, setIssueLevel] = useState<Record<string, KanbanCardObj[]>>(null)
    const [dragging, setDragging] = useState<boolean>(false)

    // Settings for the sliding views
    const settings: Slider.settings = {
        dots: false,
        // this is in case i wanna make custom dots at the bottom
        // appendDots: dots => {
        //     return <div className={'slick-slider-dots'} key={'slick-slider-dots'}>
        //         <ul className={'slick-dots'} key={'slick-dots'}>{dots}</ul>
        //     </div>
        // },
        infinite: false,
        lazyLoad: "ondemand",
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        vertical: false,
        arrows: true,
        beforeChange: () => setDragging(true),
        afterChange: () => setDragging(false),
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        // style: {
        //     marginRight: 10,
        //     marginLeft: 10,
        // },
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    function makeOpTypes() {
        var tempIssueOps: Record<string, KanbanCardObj[]> = {}
        props.theIssues.map((ti, index) => {
            if (tempIssueOps[ti.opportunityType] == null) {
                tempIssueOps[ti.opportunityType] = [ti]
            } else {
                tempIssueOps[ti.opportunityType].push(ti)
            }
        })
        setIssueOpType(tempIssueOps)
        // console.log('tempIssueOps', tempIssueOps)
    }

    function makeLevels() {
        let tempIssueLevels: Record<string, KanbanCardObj[]> = {}
        let reverseLevels: Record<string, KanbanCardObj[]> = {}

        props.theIssues.map((ti, index) => {
            if (tempIssueLevels[ti.level] == null) {
                tempIssueLevels[ti.level] = [ti]
            } else {
                tempIssueLevels[ti.level].push(ti)
            }
        })

        let reverseKeys = Object.keys(tempIssueLevels).slice().reverse()
        let reverseValues = Object.values(tempIssueLevels).slice().reverse()

        for (let i = 0; i < reverseKeys.length; i++) {
            reverseLevels["-" + reverseKeys[i]] = reverseValues[i]
        }

        setIssueLevel(reverseLevels)
    }

    useLayoutEffect(() => {
        makeOpTypes()
        makeLevels()
    }, [])

    if (!keystoneCtx.levelMode) {
        return (<Box>
            {issueOpTyped != null ?
                Object.values(issueOpTyped).map((value, index, array) => {
                    return <MakeSlider dragging={dragging} key={index} settings={settings} issues={value}
                                       navigate={navigate} members={props.members}
                                       selectCards={props.selectCards}
                                       selectedCardsIDs={props.selectedCardsIDs}
                                       updateSelectedCards={props.updateSelectedCards}
                                       allLabels={props.allLabels}
                    />
                })
                : <Box/>
            }
            <br/>
            <Divider sx={{marginTop: 10}}/>
        </Box>);
    } else {
        return (<Box>
            {issueLevel != null ?
                Object.values(issueLevel).map((value, index) => {
                    return <MakeSlider dragging={dragging} key={index} settings={settings} issues={value}
                                       navigate={navigate} members={props.members}
                                       selectCards={props.selectCards}
                                       selectedCardsIDs={props.selectedCardsIDs}
                                       updateSelectedCards={props.updateSelectedCards}
                                       allLabels={props.allLabels}
                    />
                })
                : <Box/>
            }
            <br/>
            <Divider sx={{marginTop: 10}}/>
        </Box>);
    }
}

// Props for Opp type Carousel
interface makeSliderProps {
    settings: Slider.settings
    issues: KanbanCardObj[]
    navigate: any
    members: Member[]
    dragging: boolean
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: string, vName: string) => void
    allLabels:OppLabel[]
}

// Makes a sliding view of the cards
const MakeSlider: FC<makeSliderProps> = (props) => {
    let {org} = useParams();
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    let navigate = useNavigate()

    return <>
        <Tooltip title={'Total Count: #' + props.issues.length}>
            <Typography variant={'h4'} sx={{
                width: '100%',
                p: 1,
                textAlign: 'center',
                borderBottom: `2px solid ${useTheme().palette.text.primary}`
            }}>
                {!keystoneCtx.levelMode ? props.issues[0].opportunityType : props.issues[0].levelDescription}
            </Typography>
        </Tooltip>
        <Slider {...props.settings}
                key={`slider_${!keystoneCtx.levelMode ? props.issues[0].opportunityType : props.issues[0].levelDescription}`}>
            {
                props.issues.map((ti, index) => {
                    return <Box key={`${index}_card_` + ti.id} sx={{p: "0.3rem"}}>
                        <Box key={'box_' + ti.id} >
                            <IssueCard issue={ti} index={index} members={props.members} org={mainCtx.activeOrganization.name} selected={indexOf(props.selectedCardsIDs, ti.id) != -1} allLabels={props.allLabels}/>
                        </Box>
                    </Box>
                })
            }
        </Slider>
        <br/>
    </>
}


export default IssueCards