const currencySwitcher = (org) => {
  let currency : string

  switch (org) {
    case "WUTOW":
      currency = "N$"
      break;
    case "WUTOW UNILEVER":
      currency = "N$"
      break;
    case "WUTOW_UNILEVER":
      currency = "N$"
      break;
    case "WUTOW KC":
      currency = "N$"
      break;
    case "WUTOW_KC":
      currency = "N$"
      break;
    case "KIMBERLY CLARK":
      currency = "R"
      break;
    case "DKSH":
      currency = "SFr"
      break;
    case "DKSH_MO":
      currency = "RM"
      break;
    case "DKSH_HK":
      currency = "HK$"
      break;
    case "DKSH MO":
      currency = "RM"
      break;
    case "DKSH HK":
      currency = "HK$"
      break;
    case "CASALES":
      currency = "P"
      break;
    case "CASALESPNP":
      currency = "R"
      break;
    case "CASALESPNP_UNILEVER":
      currency = "R"
      break;
    case "CASALES UNILEVER":
      currency = "P"
      break;
    case "CASALES_UNILEVER":
      currency = "P"
      break;
    case "CASALES KC":
      currency = "P"
      break;
    case "CASALES_KC":
      currency = "P"
      break;
    case "Logico":
      currency = "Le"
      break;
    case "PEPSICO NIGERIA":
      currency = "₦"
      break;
    case "PEPSICO_NIGERIA":
      currency = "₦"
      break;
    case "PEPSICO TANZANIA":
      currency = "TSh"
      break;
    case "PEPSICO_TANZANIA":
      currency = "TSh"
      break;
    case "PEPSICO_MOZAMBIQUE":
      currency = "MTn"
      break;
    case "UNILEVER":
      currency = "ETB"
      break;
    default :
      currency = "R"
  }

  return currency
}

export default currencySwitcher;