import React, {FC, useEffect} from "react";
import { Avatar, Box, CircularProgress, Skeleton, Tooltip, Typography } from "@mui/material";
import {useMainContext} from "../../../../../contexts/MainContext";
// import {processUserForDisplay} from "../../../../../utils/processForDisplay";
import {processUserForDisplay} from "../../../../../utils/processForDisplay";
import {Group} from "@mui/icons-material";
import AvatarGroup from "@mui/material/AvatarGroup";

interface HomeAssigneeComponentProps {
    assignees: string;
}

const HomeAssigneeComponent: FC<HomeAssigneeComponentProps> = (props: HomeAssigneeComponentProps) => {
    let assignees
    try {
        assignees = JSON.parse(`{"AssignedTo": ${props.assignees} }`)['AssignedTo'];
    } catch (e) {
        assignees = []
    }

    const test = assignees
    const [targetLoading, setTargetLoading] = React.useState<boolean>(true);
    const ctx = useMainContext()
    let userDetails;

    useEffect(() => {
        if (ctx.members) {
            setTargetLoading(false)
        }
    }, [ctx.members])

    return <Box sx={{p: 1}}>

        <Box sx={{
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            gap: 1
        }}>
            <Box>
                <Tooltip title={"Assignees"}>
                    <Group fontSize={"large"}/>
                </Tooltip>
            </Box>
            <Box sx={{
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                alignItems: "center"
            }}>
                {targetLoading ? (
                    <Skeleton key={"skellyLoad"} variant="circular" sx={{ width: 24 * 1.25, height: 24 * 1.25 }} />
                ) : (
                    <AvatarGroup max={3}
                                 spacing={"medium"}
                                 sx={{
                                     '& .MuiAvatar-root': {
                                         border: '1px solid black',
                                     },
                                 }}>
                        {(assignees && assignees !== "" && assignees !== null && assignees.length > 0 && ctx.members) ? assignees.map((user) =>
                                (userDetails = processUserForDisplay(user, ctx.members)) ?
                                    <Tooltip title={userDetails.displayName}
                                             key={userDetails.id}><Avatar
                                        src={userDetails.avatar}
                                        sx={{
                                            width: 24 * 1.25,
                                            height: 24 * 1.25,
                                            backgroundColor: '#fff',
                                            border: '1px solid #000'
                                        }}/></Tooltip> : null) :
                            (ctx.members) ? <Typography>N/A</Typography> : <CircularProgress key={"avatarLoad"}/>}
                    </AvatarGroup>
                )}
            </Box>
        </Box>
    </Box>
};
export default HomeAssigneeComponent