const QuantityReturned = (props) => {
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Business_Icons" data-name="Business Icons" version="1.1" viewBox="0 0 203 203" height={height}>
            <defs id="defs55">
                <linearGradient id="quantityReturnedBlob" x1="59.9" x2="160.1" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop id="stop4" offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="quantityReturnedLine" x1=".9" x2="5.1" y1="117.9" y2="113.6" gradientUnits="userSpaceOnUse">
                    <stop id="stop9" offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-2" x1="121.6" x2="125.8" y1="5.1" y2=".9" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-3" x1="197.9" x2="202.1" y1="174.8" y2="170.5" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-4" x1="29.5" x2="31.5" y1="29.5" y2="27.6" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-5" x1="31.7" x2="33.6" y1="27.4" y2="25.5" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-6" x1="33.9" x2="35.8" y1="29.5" y2="27.6" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-7" x1="31.7" x2="33.6" y1="31.6" y2="29.7" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-8" x1={180} x2="181.9" y1="64.4" y2="62.5" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-9" x1="182.2" x2="184.1" y1="62.3" y2="60.4" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-10" x1="184.4" x2="186.3" y1="64.4" y2="62.5" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-11" x1="182.2" x2="184.1" y1="66.5" y2="64.6" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-12" x1="74.8" x2="76.8" y1="200.4" y2="198.4" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-13" x1={77} x2="78.9" y1="198.2" y2="196.3" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-14" x1="79.2" x2="81.1" y1="200.3" y2="198.4" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-15" x1={77} x2="78.9" y1="202.5" y2="200.5" />
                <linearGradient id="quantityReturnedFill" x1="54.3" x2="149.9" y1="94.7" y2="94.7" gradientUnits="userSpaceOnUse">
                    <stop id="stop28" offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#quantityReturnedFill" id="quantityReturnedFill-2" x1="77.5" x2="168.6" y1="103.9" y2="103.9" />
                <linearGradient xlinkHref="#quantityReturnedFill" id="quantityReturnedFill-3" x1="34.5" x2="60.4" y1="78.5" y2="78.5" />
                <linearGradient xlinkHref="#quantityReturnedFill" id="quantityReturnedFill-4" x1="101.7" x2="124.7" y1={83} y2={83} />
                <linearGradient xlinkHref="#quantityReturnedFill" id="quantityReturnedFill-5" x1={83} x2="105.3" y1="91.8" y2="91.8" />
                <linearGradient xlinkHref="#quantityReturnedFill" id="quantityReturnedFill-6" x1="97.1" x2="107.2" y1="94.7" y2="94.7" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-16" x1="67.6" x2="136.6" y1="129.2" y2="60.3" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-17" x1="72.1" x2="132.1" y1="124.7" y2="64.8" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-18" x1="56.8" x2="156.3" y1="160.7" y2="61.2" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-19" x1={26} x2="76.1" y1="111.9" y2="61.8" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-20" x1="66.8" x2="72.7" y1="97.7" y2="91.8" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-21" x1="98.3" x2="128.7" y1="105.9" y2="75.5" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-22" x1="83.6" x2="85.8" y1="65.4" y2="63.2" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-23" x1="69.7" x2={74} y1="114.3" y2={110} />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-24" x1="118.4" x2="120.6" y1="126.3" y2="124.1" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-25" x1="99.2" x2={105} y1="65.3" y2="59.4" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-26" x1="70.8" x2={73} y1="78.4" y2="76.2" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-27" x1="82.5" x2="86.9" y1="127.5" y2={123} />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-28" x1="99.2" x2={105} y1="130.1" y2="124.2" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-29" x1="131.3" x2="133.4" y1="113.3" y2="111.1" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-30" x1="131.6" x2="137.5" y1="97.7" y2="91.8" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-31" x1="130.2" x2="134.5" y1="79.5" y2="75.2" />
                <linearGradient xlinkHref="#quantityReturnedLine" id="quantityReturnedLine-32" x1="117.3" x2="121.7" y1="66.5" y2={62} />
                <style id="style2" dangerouslySetInnerHTML={{__html: "\n            .iconWhite{fill:#fff}.quantityReturnedStroke2{fill:none;stroke:#000;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.quantityReturnedStroke3,.quantityReturnedStroke4{fill:#fff}.quantityReturnedStroke4{stroke:#000;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.quantityReturnedStroke5,.quantityReturnedStroke8{fill:url(#quantityReturnedFill)}.quantityReturnedStroke8{stroke:#000;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}\n        " }} />
            </defs>
            <g id="Icon_15">
                <path id="Contour" d="M53.9 132.1a2.9 2.9 0 0 0-3-1.4c-6 1-23 1.5-24.3-25C25 73.7 64 19 121 29.7c45.9 8.8 11.6 52.5 39.7 63.5 16.5 6.4 31.3 39.4-5.2 68.1-19.7 15.5-70.4 29.3-101.7-29.2Z" style={{fill: 'url(#quantityReturnedBlob)'}} />
                <path id="Contour-2" d="M3 118.7a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" style={{fill: 'url(#quantityReturnedLine)'}} />
                <path id="Contour-3" d="M123.7 6a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" style={{fill: 'url(#quantityReturnedLine-2)'}} />
                <path id="Contour-4" d="M200 175.6a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 0 0 0 2 1 1 0 1 0 0-2Z" style={{fill: 'url(#quantityReturnedLine-3)'}} />
                <g id="Group">
                    <path id="Contour-5" d="M32 28.5a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#quantityReturnedLine-4)'}} />
                    <path id="Contour-6" d="M32.6 28a1 1 0 0 0 1-1v-1.2a1 1 0 0 0-2 0V27a1 1 0 0 0 1 1Z" style={{fill: 'url(#quantityReturnedLine-5)'}} />
                    <path id="Contour-7" d="M35.3 27.5h-1a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#quantityReturnedLine-6)'}} />
                    <path id="Contour-8" d="M32.6 29.1a1 1 0 0 0-1 1v1.1a1 1 0 0 0 2 0v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#quantityReturnedLine-7)'}} />
                </g>
                <g id="Group-2">
                    <path id="Contour-9" d="M182.5 63.5a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#quantityReturnedLine-8)'}} />
                    <path id="Contour-10" d="M183.1 62.8a1 1 0 0 0 1-1v-1a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#quantityReturnedLine-9)'}} />
                    <path id="Contour-11" d="M185.8 62.5h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#quantityReturnedLine-10)'}} />
                    <path id="Contour-12" d="M183.1 64a1 1 0 0 0-1 1v1.2a1 1 0 0 0 2 0V65a1 1 0 0 0-1-1Z" style={{fill: 'url(#quantityReturnedLine-11)'}} />
                </g>
                <g id="Group-3">
                    <path id="Contour-13" d="M77.4 199.4a1 1 0 0 0-1-1h-1.1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#quantityReturnedLine-12)'}} />
                    <path id="Contour-14" d="M78 198.8a1 1 0 0 0 1-1v-1.1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#quantityReturnedLine-13)'}} />
                    <path id="Contour-15" d="M80.7 198.4h-1.1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#quantityReturnedLine-14)'}} />
                    <path id="Contour-16" d="M78 200a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#quantityReturnedLine-15)'}} />
                </g>
                <path id="Contour-17" d="M55.8 56.9a.5.5 0 0 0-.6 0 56.2 56.2 0 0 0-6.9 76l-1.2-1.6a61.4 61.4 0 0 1 3.7-79 .5.5 0 0 0 0-.6l-3.2-4a.5.5 0 0 1 .4-.8l11.9.4a.5.5 0 0 1 .5.5L60 59.7a.5.5 0 0 1-.8.3Z" className="iconWhite" />
                <path id="Contour-18" d="M100.4 36.4a60.4 60.4 0 1 1-31.8 111.8.5.5 0 0 1-.2-.7l4.1-6.1a.5.5 0 0 0-.5-.8l-16.5 2a.5.5 0 0 0-.5.6l2 16.5a.5.5 0 0 0 .9.3l4.6-4.7a.5.5 0 0 1 .6 0 66 66 0 1 0 39.4-119Z" className="iconWhite" />
                <path id="Contour-21" d="M78 162.6c13.9 2.6 60.9 7.7 77.2-40 0 0 27.4-54.2-34.5-82.8 0 0 48.9 10.2 47.9 65.5 0 0-2.5 52.4-52.2 61.4 0 0-21.2 4.6-38.6-3.2a.5.5 0 0 1 .3-1Z" style={{fill: 'url(#quantityReturnedFill-2)'}} />
                <path id="Contour-22" d="M37.5 74.7s10.1-18.3 22.1-26.9a.5.5 0 0 1 .8.5L60 59.8a.5.5 0 0 1-.8.4l-4.6-3.5a.5.5 0 0 0-.6 0 60 60 0 0 0-16.5 52.5s-6.8-13.2 0-34.5Z" style={{fill: 'url(#quantityReturnedFill-3)'}} />
                <g id="Group-4">
                    <path id="Contour-28" d="M150.4 55.3a66.4 66.4 0 0 0-47.7-20h-2.2a1 1 0 0 0 0 2h.2A59.4 59.4 0 0 1 160 97.7a60 60 0 0 1-58 58.5 59.2 59.2 0 0 1-32.3-8.6l3.8-5.7a1.5 1.5 0 0 0-1.4-2.3l-16.6 2a1.5 1.5 0 0 0-1 .5 1.6 1.6 0 0 0-.3 1.2l2 16.5a1.5 1.5 0 0 0 2.6.9l4.2-4.4a66.2 66.2 0 0 0 39.5 13l2.8-.1a67 67 0 0 0 45-113.8Zm-45 111.8A64.4 64.4 0 0 1 64 154.4a1.5 1.5 0 0 0-2 .1l-3.9 4-1.8-15 15-1.8-3.5 5.2a1.5 1.5 0 0 0-.2 1.2 1.5 1.5 0 0 0 .7 1 61.4 61.4 0 0 0 76.1-95.4 62.8 62.8 0 0 0-8.5-7.2A65.7 65.7 0 0 1 149 56.7a65 65 0 0 1-43.6 110.4Z" style={{fill: 'url(#quantityReturnedLine-18)'}} />
                    <path id="Contour-29" d="M49 132.2A55.5 55.5 0 0 1 55.6 58l3 2.8a1.5 1.5 0 0 0 2.5-1l.4-12a1.5 1.5 0 0 0-.4-1 1.5 1.5 0 0 0-1-.5L48 46a1.5 1.5 0 0 0-1.4.8 1.5 1.5 0 0 0 .2 1.7l3 3.5a62.1 62.1 0 0 0-3.5 80l1.2 1.5a1 1 0 0 0 .8.4 1 1 0 0 0 1-1 1 1 0 0 0-.3-.6ZM51.6 53a1.5 1.5 0 0 0 0-2l-2.4-3 10.3.2-.3 10.3-2.6-2.3a1.5 1.5 0 0 0-2 0A57.2 57.2 0 0 0 35.8 101a60.1 60.1 0 0 1 15.7-48Z" style={{fill: 'url(#quantityReturnedLine-19)'}} />
                </g>
            </g>
            <switch id="switch3882" transform="matrix(.327 0 0 .327 -1039.4 -966)">
                <g id="g3880">
                    <g id="Moving_cleaning" transform="translate(2283.6 -284.3)">
                        <g id="Main_icom">
                            <g id="group_172_">
                                <g id="group_174_">
                                    <path id="contour_1136_" d="M1106.1 3521.5h190.2V3653h-190.2z" className="quantityReturnedStroke5" />
                                    <path id="contour_1135_" d="M1081.2 3609.8v-122.2l24.9 33.9V3653z" className="quantityReturnedStroke8" />
                                    <path id="contour_1134_" d="M1296.3 3521.5V3653h-49c-92.8-29.5-79.6-131.4-79.6-131.4l.4-.1z" className="quantityReturnedStroke3" />
                                    <path id="contour_1133_" d="M1106.1 3521.5h190.2V3653h-190.2z" className="quantityReturnedStroke2" />
                                    <path id="contour_1132_" d="M1227.5 3564.1h-46.6a6.4 6.4 0 0 1-6.4-6.4v0c0-3.5 2.9-6.4 6.4-6.4h46.6c3.5 0 6.4 2.9 6.4 6.4v0c0 3.5-2.9 6.4-6.4 6.4z" className="quantityReturnedStroke8" />
                                </g>
                                <g id="group_173_">
                                    <path id="contour_1131_" d="m1106.1 3521.5-24.9-33.9h183.6l31.5 33.9z" className="quantityReturnedStroke3" />
                                    <path id="contour_1130_" d="m1106.1 3521.5-24.9-33.9h183.6l31.5 33.9z" className="quantityReturnedStroke2" />
                                    <path id="contour_1129_" d="M1093.2 3503.9h81.2" className="quantityReturnedStroke4" />
                                </g>
                            </g>
                            <g id="group_171_">
                                <path id="group_177_" d="M1157.6 3489.8v-107l51.3 30.7v119.2z" className="quantityReturnedStroke8" />
                                <g id="group_176_">
                                    <path id="contour_1128_" d="M1208.9 3413.5h102.5v119.2h-102.5z" className="quantityReturnedStroke5" />
                                    <path id="contour_1127_" d="M1311.4 3413.5v95.4c-93.8.7-67.4-95.4-67.4-95.4z" className="quantityReturnedStroke3" />
                                    <path id="contour_1126_" d="M1208.9 3413.5h102.5v119.2h-102.5z" className="quantityReturnedStroke2" />
                                </g>
                                <g id="group_175_">
                                    <path id="contour_1125_" d="m1254.6 3381.8 10.7 6h.1l46 25.7h-102.5l-51.3-30.7z" className="quantityReturnedStroke3" />
                                    <path id="contour_1124_" d="m1254.6 3381.8 10.7 6h.1l46 25.7h-102.5l-51.3-30.7z" className="quantityReturnedStroke2" />
                                    <path id="contour_1123_" d="m1262.2 3413.5-49-30.7" className="quantityReturnedStroke4" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </switch>
        </svg>

    )
}
export default QuantityReturned