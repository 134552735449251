import React, {FC, useEffect, useState} from "react";
import {Autocomplete, Box, Switch, TextField, Tooltip, Typography} from "@mui/material";
import {Orientation, PathFunctionOption, TreeNodeDatum} from "react-d3-tree";
import {Close, ExpandLess, ExpandMore, InfoOutlined, SaveOutlined} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    PSUArrayToStringValue,
    PSUNodeTypeOptions,
    PSUOperationOptions,
    PSUValueStringToArray, SplitHerePattern,
    TreeForUseByPSU
} from "../utility/NodeTypesAndOptions";
import {ColumnMappingDictionaryWithData} from "../../exlude/components/ColumnMappingDictionaryWithData";
import {useMainContext} from "../../../../../contexts/MainContext";
import Button from "@mui/material/Button";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {DateRange, DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, {Dayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";


interface PSUSettingsComponentProps {
    needsSaving: boolean
    setNeedsSaving: (needsSaving: boolean) => void
    SaveTree: () => void
    DeleteTree: () => void
    treeOrGrid: boolean
    setTreeOrGrid: (tog: boolean) => void
    handleTreeNameChange: (e) => void

    setSelectedNode: (hpn: TreeNodeDatum) => void
    selectedNode: TreeNodeDatum

    orgLevelSwitch: boolean
    handleOrgLevelChange: (event, checked) => void

    colMapWithDataReference: ColumnMappingDictionaryWithData

    psuTree: TreeForUseByPSU
    // treeUserID: string

    UpdateTrees: (tree) => void

    treeOrientation: Orientation
    setTreeOrientation: (o: Orientation) => void

    treePathShape: PathFunctionOption
    setTreePathShape: (tps: PathFunctionOption) => void
}


const autoCompleteShadows = '2px 2px 2px rgb(0, 0, 0, 0.2), 4px 4px 2px 2px rgb(0, 0, 0, 0.15)'
export const PSUinsideShadows = '1px 1px 1px rgb(0, 0, 0, 0.1), 2px 2px 1px 1px rgb(0, 0, 0, 0.15)'
const headingVariant = "h5"
const autoCompleteStyle = {
    boxShadow: PSUinsideShadows,
    borderRadius: 1,
    minWidth: "45%"
}
const btnStyle = {
    // p: 1,
    display: "flex",
    gap: 1,
    flexGrow: 1,
    width: "100%"
}

export const TreeControls = <Typography variant={"body2"}>Controls: <br/>
    Mouse Wheel: Zoom <br/>
    Left Click: Navigate / Select Node <br/>
</Typography>

const PSUSettingsComponent: FC<PSUSettingsComponentProps> = (props) => {
    const cogTheme = useTheme()
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()

    const [minimizeSettings, setMinimizeSettings] = useState<boolean>(false)
    // amountOfValuesForOperation : -1 = multiple, 1 = 1, 2 = 2
    const [amountOfValuesForOperation, setAmountOfValuesForOperation] = useState<number>(-1)
    const [selectedNodeToolTip, setSelectedNodeToolTip] = useState<string>("")

    const handleOperationChange = (operation: string) => {
        // console.log("CurrentSelectedNode", props.selectedNode)
        switch (operation) {
            case "IN" :
                setAmountOfValuesForOperation(-1)
                break;
            case "NOT IN" :
                setAmountOfValuesForOperation(-1)
                break;
            case "=" :
                setAmountOfValuesForOperation(-1)
                break;
            case "!=" :
                setAmountOfValuesForOperation(-1)
                break;
            case ">" :
                setAmountOfValuesForOperation(1)
                break;
            case "GREATER THAN" :
                setAmountOfValuesForOperation(1)
                break;
            case ">=" :
                setAmountOfValuesForOperation(1)
                break;
            case "GREATER THAN OR EQUAL" :
                setAmountOfValuesForOperation(1)
                break;
            case "<" :
                setAmountOfValuesForOperation(1)
                break;
            case "LESS THAN" :
                setAmountOfValuesForOperation(1)
                break;
            case "<=" :
                setAmountOfValuesForOperation(1)
                break;
            case "LESS THAN OR EQUAL" :
                setAmountOfValuesForOperation(1)
                break;
            case "BETWEEN" :
                setAmountOfValuesForOperation(2)
                break;
            default:
                setAmountOfValuesForOperation(-1)
                break;
        }
    }

    const handleSelectedNodeTooltip = () => {
        if (props.selectedNode != null) {
            if (props.selectedNode["data"].attributes["nodeType"] == null) {
                setSelectedNodeToolTip("")
            }
            if (props.selectedNode["data"].attributes["nodeType"] == "Limit Node") {
                setSelectedNodeToolTip("Limits the amount of data points fetched of the chosen field")
            }
            if (props.selectedNode["data"].attributes["nodeType"] == "Filter Node") {
                setSelectedNodeToolTip("Choose a Field, an Operation and Values to filter data by")
            }
        } else {
            setSelectedNodeToolTip("")
        }
    }

    useEffect(() => {
        if (props.selectedNode != null) {
            handleSelectedNodeTooltip()
        }
    }, [props.selectedNode]);

    // This is basically only here to make sure there are only ever the expected amount of values in the values autocomplete
    useEffect(() => {
        if (props.selectedNode != null) {
            let hpn = props.selectedNode
            let tempValAsArray: string[] = []
            if (props.selectedNode["data"].attributes["value"] != null) {
                tempValAsArray = PSUValueStringToArray(props.selectedNode["data"].attributes["value"])
            }
            if (tempValAsArray.length > 0) {
                if (amountOfValuesForOperation == 1) {
                    hpn["data"].attributes["value"] = PSUArrayToStringValue([tempValAsArray.pop()])
                } else if (amountOfValuesForOperation == 2) {
                    if (tempValAsArray.length > 2) {
                        hpn["data"].attributes["value"] = PSUArrayToStringValue([tempValAsArray.pop(), tempValAsArray.pop()].reverse())
                    } else {
                        hpn["data"].attributes["value"] = PSUArrayToStringValue(tempValAsArray)
                    }
                } else {
                    hpn["data"].attributes["value"] = PSUArrayToStringValue(tempValAsArray)
                }
            } else {
                hpn["data"].attributes["value"] = null
            }
            props.setSelectedNode(hpn)
            props.UpdateTrees(hpn)
            // props.setNeedsSaving(true)
        }
    }, [amountOfValuesForOperation]);

    return <>
        {}
        {minimizeSettings == false &&
            <Box
                sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: 1,
                    border: `1px solid ${cogTheme.palette.divider}`,
                    borderRadius: 2,
                    backgroundColor: cogTheme.palette.background.paper,
                    boxShadow: cogTheme.shadows[3],
                    // minWidth: "15vw",
                    // maxWidth: "19vw"
                }}
            >

                <Box
                    sx={{
                        px: 1,
                        py: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        border: `1px solid ${cogTheme.palette.divider}`,
                        borderRadius: 2,
                        backgroundColor: cogTheme.palette.background.paper,
                        boxShadow: PSUinsideShadows,
                        minWidth: "14.5vw"
                        // boxShadow: cogTheme.shadows[3],
                    }}
                >
                    <Box
                        sx={{
                            // p: 1,
                            display: "flex",
                            // gap: 1,
                            justifySelf: "center",
                            justifyContent: "space-between"
                        }}
                    >

                        <Typography variant={headingVariant}>
                            PSU View:
                        </Typography>
                        <Tooltip title={"Minimize Settings"}>
                            <Box
                                sx={{
                                    border: `1px solid ${cogTheme.palette.divider}`,
                                    borderRadius: 2,
                                    // p: 1,
                                    backgroundColor: cogTheme.palette.background.paper,
                                    boxShadow: PSUinsideShadows,
                                    // boxShadow: cogTheme.shadows[3],
                                    display: "flex",
                                }}
                                onClick={() => {
                                    setMinimizeSettings(true)
                                }}
                            >
                                <ExpandLess fontSize={"medium"}/>
                            </Box>
                        </Tooltip>

                    </Box>
                    <Box
                        sx={{
                            // p: 1,
                            display: "flex",
                            gap: 0.5,
                            flexGrow: 1,
                            width: "100%"
                        }}
                    >
                        <Button fullWidth variant={props.treeOrGrid == false ? "contained" : "outlined"}
                                onClick={() => props.setTreeOrGrid(false)}
                                sx={{
                                    boxShadow: PSUinsideShadows,
                                    borderRadius: 1
                                }}
                        >
                            Tree
                        </Button>
                        <Button fullWidth variant={props.treeOrGrid == true ? "contained" : "outlined"}
                                onClick={() => {
                                    props.setTreeOrGrid(true)
                                    props.setSelectedNode(null)
                                    if (props.needsSaving) {
                                        props.SaveTree()
                                    }
                                }}
                                sx={{
                                    boxShadow: PSUinsideShadows,
                                    borderRadius: 1
                                }}
                        >
                            Data Preview
                        </Button>
                    </Box>
                    {/*{*/}
                    {/*    props.treeOrGrid == false && */}
                    <Box
                        sx={{
                            // p: 1,
                            display: "flex",
                            // gap: 1,
                            justifySelf: "center",
                            justifyContent: "space-between"
                        }}
                    >

                        <Typography variant={headingVariant}>
                            PSU Tree Settings:
                        </Typography>
                        {props.treeOrGrid == false && <Tooltip title={TreeControls}>
                            <Box
                                sx={{
                                    border: `1px solid ${cogTheme.palette.divider}`,
                                    borderRadius: 2,
                                    // p: 1,
                                    backgroundColor: cogTheme.palette.background.paper,
                                    boxShadow: PSUinsideShadows,
                                    display: "flex",
                                }}
                            >
                                <InfoOutlined fontSize={"medium"}/>
                            </Box>
                        </Tooltip>}
                    </Box>
                    {/*}*/}
                    <Box
                        sx={{
                            border: `1px solid ${cogTheme.palette.divider}`,
                            p: 1,
                            display: "flex",
                            boxShadow: PSUinsideShadows,
                            borderRadius: 1
                            // gap: 1,
                            // minWidth: "20%",
                            // flexGrow: 1
                        }}
                    >
                        <TextField
                            fullWidth={true}
                            id="standard-basic"
                            label="PSU Name"
                            variant="standard"
                            defaultValue={props.psuTree.Name}
                            onChange={props.handleTreeNameChange}
                        />
                    </Box>
                    {
                        props.treeOrGrid == false && <Box
                            sx={{
                                // p: 1,
                                display: "flex",
                                gap: 1,
                                // justifyContent: "flex-end"
                            }}
                        >
                            {
                                mainCtx.user != null && (mainCtx.user.id == props.psuTree.User_ID || props.psuTree.User_ID == "") &&
                                <Box
                                    sx={{
                                        p: 0,
                                        display: "flex",
                                        gap: 1,
                                        // justifyContent: "flex-end"
                                    }}
                                >
                                    <Tooltip
                                        title={props.orgLevelSwitch == true ? "Toggle to Make Personal" : "Toggle to Make Org Wide"}>
                                        <Box
                                            onClick={() => {
                                                // DeleteTree()
                                            }}
                                            sx={{
                                                border: `1px solid ${cogTheme.palette.divider}`,
                                                borderRadius: 1,
                                                p: 1,
                                                my: 1,
                                                backgroundColor: cogTheme.palette.background.paper,
                                                boxShadow: PSUinsideShadows,
                                                // boxShadow: cogTheme.shadows[3],
                                                display: "flex",
                                            }}
                                        >
                                            {/*<AccountTree fontSize={"medium"}/>*/}
                                            <Box>Org Level: </Box>
                                            <Switch checked={props.orgLevelSwitch}
                                                    size={"small"}
                                                    onChange={(event, checked) => {
                                                        // console.log("psuTrees[currentTab].Org_Level", psuTrees[currentTab].Org_Level)
                                                        props.handleOrgLevelChange(event, checked)
                                                    }}
                                            ></Switch>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            }
                            <Tooltip title={"Save PSU"}>
                                <Box
                                    onClick={() => {
                                        // DeleteTree()
                                        if (props.needsSaving) {
                                            // save
                                            props.SaveTree()
                                        } else {
                                            // Do Nothing
                                        }
                                    }}
                                    sx={{
                                        border: `1px solid ${cogTheme.palette.divider}`,
                                        borderRadius: 1,
                                        p: 1,
                                        my: 1,
                                        backgroundColor: cogTheme.palette.background.paper,
                                        boxShadow: PSUinsideShadows,
                                        // boxShadow: cogTheme.shadows[3],
                                        display: "flex",
                                    }}
                                >
                                    <SaveOutlined
                                        fontSize={"medium"}
                                        color={props.needsSaving == false ? "disabled" : "inherit"}
                                    />
                                </Box>
                            </Tooltip>
                            <Tooltip title={"Click to Delete Tree"}>
                                <Box
                                    onClick={props.DeleteTree}
                                    sx={{
                                        border: `1px solid ${cogTheme.palette.divider}`,
                                        borderRadius: 1,
                                        p: 1,
                                        my: 1,
                                        backgroundColor: cogTheme.palette.background.paper,
                                        boxShadow: PSUinsideShadows,
                                        // boxShadow: cogTheme.shadows[3],
                                        display: "flex",
                                    }}
                                >
                                    {/*<AccountTree fontSize={"medium"}/>*/}
                                    <DeleteIcon fontSize={"medium"}/>
                                </Box>
                            </Tooltip>

                        </Box>
                    }
                    {props.treeOrGrid == false &&
                        <Typography variant={headingVariant}>Orientation:</Typography>
                    }
                    {props.treeOrGrid == false && <Box
                        sx={{
                            // p: 1,
                            display: "flex",
                            gap: 1,
                            flexGrow: 1,
                            width: "100%"
                        }}
                    >
                        <Button fullWidth variant={props.treeOrientation == "horizontal" ? "contained" : "outlined"}
                                onClick={() => props.setTreeOrientation("horizontal")}
                                sx={{
                                    boxShadow: PSUinsideShadows,
                                    borderRadius: 1
                                }}
                        >
                            Horizontal
                        </Button>
                        <Button fullWidth variant={props.treeOrientation == "vertical" ? "contained" : "outlined"}
                                onClick={() => props.setTreeOrientation("vertical")}
                                sx={{
                                    boxShadow: PSUinsideShadows,
                                    borderRadius: 1
                                }}
                        >
                            Vertical
                        </Button>
                    </Box>}
                    {props.treeOrGrid == false && <Typography variant={headingVariant}>PathShape:</Typography>}
                    {props.treeOrGrid == false && <Box
                        sx={{
                            // p: 1,
                            display: "flex",
                            gap: 1,
                            flexGrow: 1,
                            width: "100%"
                        }}
                    >
                        <Button fullWidth variant={props.treePathShape == "step" ? "contained" : "outlined"}
                                onClick={() => props.setTreePathShape("step")}
                                sx={{
                                    boxShadow: PSUinsideShadows,
                                    borderRadius: 1
                                }}
                        >
                            Step
                        </Button>
                        <Button fullWidth variant={props.treePathShape == "straight" ? "contained" : "outlined"}
                                onClick={() => props.setTreePathShape("straight")}
                                sx={{
                                    boxShadow: PSUinsideShadows,
                                    borderRadius: 1
                                }}
                        >
                            Straight
                        </Button>
                    </Box>}
                    {props.treeOrGrid == false && <Box
                        sx={{
                            // p: 1,
                            display: "flex",
                            gap: 1,
                            // flexGrow: 1
                        }}
                    >
                        <Button fullWidth variant={props.treePathShape == "diagonal" ? "contained" : "outlined"}
                                onClick={() => props.setTreePathShape("diagonal")}
                                sx={{
                                    boxShadow: PSUinsideShadows,
                                    borderRadius: 1
                                }}
                        >
                            Diagonal
                        </Button>
                        <Button fullWidth variant={props.treePathShape == "elbow" ? "contained" : "outlined"}
                                onClick={() => props.setTreePathShape("elbow")}
                                sx={{
                                    boxShadow: PSUinsideShadows,
                                    borderRadius: 1
                                }}
                        >
                            Elbow
                        </Button>
                    </Box>}
                </Box>
                {props.selectedNode != null && <RenderSelectedNodeSettings
                    setSelectedNode={props.setSelectedNode}
                    selectedNode={props.selectedNode}
                    needsSaving={props.needsSaving}
                    setNeedsSaving={props.setNeedsSaving}
                    SaveTree={props.SaveTree}
                    DeleteTree={props.DeleteTree}
                    UpdateTrees={props.UpdateTrees}
                    colMapWithDataReference={props.colMapWithDataReference}
                    selectedNodeToolTip={selectedNodeToolTip}
                    handleOperationChange={handleOperationChange}
                    amountOfValuesForOperation={amountOfValuesForOperation}
                />}
            </Box>
        }
        {minimizeSettings == true &&
            <Tooltip title={"Expand Settings"}>
                <Box
                    sx={{
                        border: `1px solid ${cogTheme.palette.divider}`,
                        borderRadius: 2,
                        // p: 1,
                        backgroundColor: cogTheme.palette.background.paper,
                        boxShadow: PSUinsideShadows,
                        // boxShadow: cogTheme.shadows[3],
                        display: "flex",
                    }}
                    onClick={() => {
                        setMinimizeSettings(false)
                    }}
                >
                    <ExpandMore fontSize={"medium"}/>
                </Box>
            </Tooltip>
        }
    </>
}

interface RenderSelectedNodeSettingsProps {
    setSelectedNode: (hpn: TreeNodeDatum) => void
    selectedNode: TreeNodeDatum

    needsSaving: boolean
    setNeedsSaving: (needsSaving: boolean) => void
    SaveTree: () => void
    DeleteTree: () => void
    UpdateTrees: (tree) => void
    colMapWithDataReference: ColumnMappingDictionaryWithData

    selectedNodeToolTip: string
    handleOperationChange: (operation: string) => void

    amountOfValuesForOperation: number
}

const RenderSelectedNodeSettings: FC<RenderSelectedNodeSettingsProps> = (props) => {
    const cogTheme = useTheme()
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    // const [dateValue, setDateValue] = React.useState<DateRange<Date>>([
    //     dayjs('2022-04-17'),
    //     dayjs('2022-04-21'),
    // ]);

    const now = new Date(Date.now());
    const last2Weeks = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14);
    const [dateValue, setDateValue] = React.useState<DateRange<Date>>([last2Weeks, now]);

    const valToArr = (val: string) => {
        let tempValAsArray: string[] = []
        if (val != null) {
            tempValAsArray = PSUValueStringToArray(val)
            if (props.selectedNode["data"].attributes["value"] != null && val != PSUArrayToStringValue(tempValAsArray)) {
                let hpn = props.selectedNode
                hpn["data"].attributes["value"] = PSUArrayToStringValue(tempValAsArray)
                // setTreeFromNode(hierarchyPointNode)
                props.setSelectedNode(hpn)
                props.UpdateTrees(hpn)
                props.setNeedsSaving(true)

            }
        }
        return tempValAsArray
    }

    const percentageLimit = (val: string) => {
        if (val != null) {
            return val == "true"
        }
        return false
    }

    useEffect(() => {
        if (props.selectedNode["data"].attributes["operation"] != null && props.selectedNode["data"].attributes["operation"] == "Date" && props.selectedNode["data"].attributes["value"] != null) {
            let tempArr = (props.selectedNode["data"].attributes["value"].toString().substring(1, props.selectedNode["data"].attributes["value"].toString().length-1)).split(SplitHerePattern)
            setDateValue([new Date(tempArr[0]), new Date(tempArr[1])])
        } else {
            setDateValue([null, null])
            // setDateValue([last2Weeks, now])
        }
    }, [props.selectedNode]);

    return <>

        {props.selectedNode != null &&
            <Box sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                border: `1px solid ${cogTheme.palette.divider}`,
                boxShadow: PSUinsideShadows,
                // boxShadow: cogTheme.shadows[3],
                borderRadius: 2,
                minWidth: "13vw",
                maxWidth: "15vw"
                // maxHeight: "40vh"
            }}>
                <Box
                    sx={{
                        // p: 1,
                        display: "flex",
                        // gap: 1,
                        justifySelf: "center",
                        justifyContent: "space-between"
                    }}
                >
                    <Typography variant={headingVariant}>
                        Selected Node:
                    </Typography>
                    <Box
                        sx={{
                            // p: 1,
                            display: "flex",
                            gap: 1,
                            // justifySelf: "center",
                            justifyContent: "flex-end"
                        }}
                    >
                        <Tooltip title={props.selectedNodeToolTip}>
                            <Box
                                sx={{
                                    border: `1px solid ${cogTheme.palette.divider}`,
                                    borderRadius: 2,
                                    // p: 1,
                                    backgroundColor: cogTheme.palette.background.paper,
                                    boxShadow: PSUinsideShadows,
                                    // boxShadow: cogTheme.shadows[3],
                                    display: "flex",
                                }}
                                onClick={() => {
                                    // setMinimizeSettings(true)
                                }}
                            >
                                <InfoOutlined fontSize={"medium"}/>
                            </Box>
                        </Tooltip>
                        <Tooltip title={"Deselect Node"}>
                            <Box
                                sx={{
                                    border: `1px solid ${cogTheme.palette.divider}`,
                                    borderRadius: 2,
                                    // p: 1,
                                    backgroundColor: cogTheme.palette.background.paper,
                                    boxShadow: PSUinsideShadows,
                                    // boxShadow: cogTheme.shadows[3],
                                    display: "flex",
                                }}
                                onClick={() => {
                                    // setMinimizeSettings(true)
                                    props.setSelectedNode(null)
                                }}
                            >
                                <Close fontSize={"medium"}/>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
                {/* NodeType Selector */}
                {
                    props.selectedNode["data"].attributes != null &&
                    <Box
                        key={"nodeType" + " : " + props.selectedNode["data"].attributes["nodeType"]}
                        sx={{}}
                    >
                        <Autocomplete
                            disableCloseOnSelect
                            disablePortal={true}
                            options={PSUNodeTypeOptions}
                            value={props.selectedNode["data"].attributes["nodeType"]}
                            filterSelectedOptions
                            onChange={(_, value) => {
                                props.selectedNode["data"].attributes["nodeType"] = value
                                // setTreeFromNode(hierarchyPointNode)
                                let hpn = props.selectedNode
                                hpn["data"].attributes["nodeType"] = value
                                hpn["data"].attributes["value"] = null
                                hpn["data"].attributes["operation"] = null
                                if (value == "Limit Node") {
                                    hpn["data"].attributes["operation"] = "TOP"
                                    hpn["data"].attributes["value"] = PSUArrayToStringValue(["10"])
                                    hpn["data"].attributes["sortValue"] = keystoneCtx.colMap.Value
                                    // hpn["data"].attributes["operation"] = keystoneCtx.colMap.Value
                                }
                                props.setSelectedNode(hpn)
                                props.UpdateTrees(hpn)
                                props.setNeedsSaving(true)
                            }}
                            size={"small"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`${"Node Type"}`}
                                    placeholder="Select Node Type"

                                />
                            )}
                            sx={autoCompleteStyle}

                        />
                    </Box>
                }
                {/* ValueNode Operation Input */}
                {
                    props.selectedNode["data"].attributes["nodeType"] == "Filter Node" &&
                    <Box
                        key={"Operation" + " : " + props.selectedNode["data"].attributes["operation"]}>
                        <Autocomplete
                            disableCloseOnSelect
                            disablePortal={true}
                            options={PSUOperationOptions}
                            value={props.selectedNode["data"].attributes["operation"]}
                            filterSelectedOptions
                            onChange={(_, value) => {
                                let hpn = props.selectedNode
                                hpn["data"].attributes["operation"] = value
                                // hpn["data"].attributes["value"] = null
                                props.handleOperationChange(hpn["data"].attributes["operation"])
                                props.setSelectedNode(hpn)
                                props.UpdateTrees(hpn)
                                props.setNeedsSaving(true)
                                // hierarchyPointNode.data.attributes[key] = value
                                // setTreeFromNode(hierarchyPointNode)
                            }}
                            size={"small"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`${"Operation"}`}
                                    placeholder="Select Operation"
                                />
                            )}
                            sx={autoCompleteStyle}

                        />
                    </Box>
                }
                {/* Field Input */}
                {
                    (props.selectedNode["data"].attributes["nodeType"] == "Limit Node" ||
                        props.selectedNode["data"].attributes["nodeType"] == "Filter Node") &&
                    <Box
                        key={"field" + " : " + props.selectedNode["data"].attributes["field"]}>
                        <Autocomplete
                            disableCloseOnSelect
                            disablePortal={true}
                            options={props.selectedNode["data"].attributes["nodeType"] == "Limit Node" ? [...props.colMapWithDataReference.GetFieldsArray(), keystoneCtx.colMap.Date] : props.colMapWithDataReference.GetFieldsArray()}
                            // options={PSUFieldOptions}
                            value={props.selectedNode["data"].attributes["field"]}
                            filterSelectedOptions
                            onChange={(_, value) => {
                                let hpn = props.selectedNode
                                hpn["data"].attributes["field"] = value
                                if (props.selectedNode["data"].attributes["nodeType"] == "Filter Node"
                                    || (props.selectedNode["data"].attributes["nodeType"] == "Limit Node"
                                        && (hpn["data"].attributes["field"] == keystoneCtx.colMap.Date || value == keystoneCtx.colMap.Date)
                                    )) {
                                    hpn["data"].attributes["value"] = null
                                    if (value == keystoneCtx.colMap.Date) {
                                        // If we do this, we can check against this for the backend, as nowhere else will the operation be Date
                                        hpn["data"].attributes["operation"] = "Date"
                                    }
                                } else {
                                    if (value != keystoneCtx.colMap.Date) {
                                        hpn["data"].attributes["value"] = `[10]`
                                    }
                                }
                                props.setSelectedNode(hpn)
                                props.UpdateTrees(hpn)
                                props.setNeedsSaving(true)
                            }}
                            size={"small"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`${"Field"}`}
                                    placeholder="Select Field"
                                />
                            )}
                            sx={autoCompleteStyle}
                        />
                    </Box>
                }

                {/* ValueNode Value Input */}
                {
                    props.selectedNode["data"].attributes["nodeType"] == "Filter Node" && <Box
                        key={"Value" + " : " + props.selectedNode["data"].attributes["value"]}>
                        <Autocomplete
                            multiple={true}
                            limitTags={4}
                            disableCloseOnSelect
                            disablePortal={true}
                            options={props.colMapWithDataReference.GetValuesForField(props.selectedNode["data"].attributes["field"])}
                            value={valToArr(props.selectedNode["data"].attributes["value"])}
                            filterSelectedOptions
                            onChange={(_, value: string[]) => {
                                let hpn = props.selectedNode
                                if (value.length > 0) {
                                    if (props.amountOfValuesForOperation == 1) {
                                        hpn["data"].attributes["value"] = PSUArrayToStringValue([value.pop()])
                                    } else if (props.amountOfValuesForOperation == 2) {
                                        if (value.length > 2) {
                                            hpn["data"].attributes["value"] = PSUArrayToStringValue([value.pop(), value.pop()].reverse())
                                        } else {
                                            hpn["data"].attributes["value"] = PSUArrayToStringValue(value)
                                        }
                                    } else {
                                        hpn["data"].attributes["value"] = PSUArrayToStringValue(value)
                                    }
                                } else {
                                    hpn["data"].attributes["value"] = null
                                }
                                props.setSelectedNode(hpn)
                                props.UpdateTrees(hpn)
                                props.setNeedsSaving(true)

                            }}
                            size={"small"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`${"Value"}`}
                                    placeholder="Select Value"
                                />
                            )}
                            sx={autoCompleteStyle}
                        />


                    </Box>
                }
                {/* Limit Node Sort Value Input */}
                {
                    props.selectedNode["data"].attributes["nodeType"] == "Limit Node" && props.selectedNode["data"].attributes["field"] != keystoneCtx.colMap.Date && <Box
                        key={"Sort Value"}>
                        <Autocomplete
                            multiple={false}
                            disableCloseOnSelect
                            disablePortal={true}
                            disableClearable={true}
                            options={[keystoneCtx.colMap.Value, keystoneCtx.colMap.Qty]}
                            value={props.selectedNode["data"].attributes["sortValue"]}
                            filterSelectedOptions
                            onChange={(_, value: string) => {
                                let hpn = props.selectedNode
                                if (value.length > 0) {
                                    hpn["data"].attributes["sortValue"] = value //.substring(1, PSUArrayToStringValue([value]).length -1)
                                } else {
                                    hpn["data"].attributes["sortValue"] = null
                                }
                                // setTreeFromNode(hierarchyPointNode)
                                props.setSelectedNode(hpn)
                                props.UpdateTrees(hpn)
                                props.setNeedsSaving(true)

                            }}
                            size={"small"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`${"Sort Value"}`}
                                    placeholder="Select Sort Value"
                                />
                            )}
                            sx={autoCompleteStyle}
                        />
                    </Box>
                }
                {/*LimitNode Operation Input*/}
                {
                    props.selectedNode["data"].attributes["nodeType"] == "Limit Node" && props.selectedNode["data"].attributes["field"] != keystoneCtx.colMap.Date && <Box
                        key={"value" + " : " + props.selectedNode["data"].attributes["value"]}
                        sx={{
                            // display: "flex",
                        }}
                    >

                        <Autocomplete
                            multiple={false}
                            disableClearable={true}
                            disableCloseOnSelect
                            disablePortal={true}
                            options={["TOP", "NOT TOP", "BETWEEN(TOP)"]}
                            // options={PSUValueOptions}
                            // value={limitNodeValue}
                            value={props.selectedNode["data"].attributes["operation"]}
                            filterSelectedOptions
                            onChange={(_, value: string) => {
                                let hpn = props.selectedNode
                                if (value == "BETWEEN(TOP)") {
                                    hpn["data"].attributes["value"] = PSUArrayToStringValue([valToArr(props.selectedNode["data"].attributes["value"])[0], "100"])
                                } else {
                                    hpn["data"].attributes["value"] = PSUArrayToStringValue([valToArr(props.selectedNode["data"].attributes["value"])[0]])
                                }
                                hpn["data"].attributes["operation"] = value
                                props.setSelectedNode(hpn)
                                props.UpdateTrees(hpn)
                                props.setNeedsSaving(true)

                            }}
                            size={"small"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`${"Operation"}`}
                                    placeholder="Select Operation"
                                />
                            )}
                            sx={autoCompleteStyle}
                        />

                    </Box>
                }

                {/* Limit Node Date Value*/}
                {
                    props.selectedNode["data"].attributes["nodeType"] == "Limit Node" && props.selectedNode["data"].attributes["field"] == keystoneCtx.colMap.Date &&
                    <Box sx={{
                        display: "flex",
                        gap: 1,
                    }}>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            localeText={{start: 'Start Date', end: 'End Date'}}
                        >
                            <DateRangePicker
                                value={dateValue}
                                inputFormat="yyyy-MM-dd"
                                onChange={(newValue) => {
                                    console.log("NewValue date", newValue)
                                    let hpn = props.selectedNode
                                    // hpn["data"].attributes["value"] = newValue

                                    // to be totes honest, I stole this bit from a diff date range,
                                    // so not 100% which parts are needed, but the date seems to turn out right
                                    // with this here, and wrong without it, so i put it
                                    let dateStart: Date
                                    let dateEnd: Date
                                    if (newValue[0] != null) {
                                        dateStart = new Date(newValue[0])
                                        const offset1 = dateStart.getTimezoneOffset()
                                        dateStart = new Date(dateStart.getTime() - (offset1*60*1000))
                                    } else {
                                        dateStart = null
                                    }
                                    if (newValue[1] != null) {
                                        dateEnd = new Date(newValue[1])
                                        const offset2 = dateEnd.getTimezoneOffset()
                                        dateEnd = new Date(dateEnd.getTime() - (offset2*60*1000))
                                    } else {
                                        dateEnd = null
                                    }
                                    if (dateStart != null && dateEnd != null) {
                                        setDateValue([dateStart, dateEnd]);
                                    }
                                    let startDateString = ""
                                    if (dateStart != null) {
                                        startDateString = dateStart.getFullYear().toString() +
                                            "-"
                                        if (dateStart.getMonth() < 9) {startDateString+="0"}
                                        startDateString += (dateStart.getMonth() + 1).toString() +
                                            "-"
                                        if (dateStart.getDate() < 10) {startDateString+="0"}
                                        startDateString += dateStart.getDate().toString()
                                    }
                                    let endDateString = ""
                                    if (dateEnd != null) {
                                        endDateString = dateEnd.getFullYear().toString() +
                                            "-"
                                        if (dateEnd.getMonth() < 9) {endDateString+="0"}
                                        endDateString += (dateEnd.getMonth() + 1).toString() +
                                            "-"
                                        if (dateEnd.getDate() < 9) {endDateString+="0"}
                                        endDateString += (dateEnd.getDate()).toString()
                                    }
                                    hpn["data"].attributes["value"] = PSUArrayToStringValue([startDateString, endDateString])

                                    props.setSelectedNode(hpn)
                                    props.UpdateTrees(hpn)
                                    props.setNeedsSaving(true)
                                }}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <TextField {...startProps} sx={autoCompleteStyle} />
                                        <Box sx={{mx: 1}}> to </Box>
                                        <TextField {...endProps} sx={autoCompleteStyle}  />
                                    </React.Fragment>
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                }

                {/* LimitNode Value(s) for not Date input*/}
                {
                    props.selectedNode["data"].attributes["nodeType"] == "Limit Node" && props.selectedNode["data"].attributes["field"] != keystoneCtx.colMap.Date &&
                    <Box sx={{
                        display: "flex",
                        gap: 1,
                    }}>
                        <TextField
                            placeholder={"Value"}
                            label={"Value"}
                            type={"number"}
                            variant={"outlined"}
                            value={valToArr(props.selectedNode["data"].attributes["value"])[0]}
                            onChange={(event) => {
                                // if (value != null && value.length > 1 && value[0] == "0") {
                                //     value = value.substring(1)
                                // }
                                if (event.target.value != null && event.target.value.length > 1 && event.target.value[0] == "0") {
                                    event.target.value = event.target.value.substring(1)
                                }
                                let hpn = props.selectedNode
                                if (event.target.value != null && event.target.value.length > 0) {
                                    if (hpn["data"].attributes["operation"] == "BETWEEN(TOP)") {
                                        hpn["data"].attributes["value"] = PSUArrayToStringValue([event.target.value, valToArr(props.selectedNode["data"].attributes["value"])[1]]) //.substring(1, PSUArrayToStringValue([value]).length -1)
                                    } else {
                                        hpn["data"].attributes["value"] = PSUArrayToStringValue([event.target.value])
                                    }
                                } else {
                                    // hpn["data"].attributes["value"] = null
                                    if (hpn["data"].attributes["operation"] == "BETWEEN(TOP)") {
                                        hpn["data"].attributes["value"] = PSUArrayToStringValue(["0", valToArr(props.selectedNode["data"].attributes["value"])[1]]) //.substring(1, PSUArrayToStringValue([value]).length -1)
                                    } else {
                                        hpn["data"].attributes["value"] = PSUArrayToStringValue(["0"])
                                    }
                                }
                                props.setSelectedNode(hpn)
                                props.UpdateTrees(hpn)
                                props.setNeedsSaving(true)

                            }}
                        ></TextField>
                        {
                            props.selectedNode["data"].attributes["operation"] == "BETWEEN(TOP)" &&
                            <TextField
                                placeholder={"Value"}
                                label={"Value"}
                                type={"number"}
                                variant={"outlined"}
                                value={valToArr(props.selectedNode["data"].attributes["value"])[1]}
                                onChange={(event) => {
                                    if (event.target.value != null && event.target.value.length > 1 && event.target.value[0] == "0") {
                                        event.target.value = event.target.value.substring(1)
                                    }
                                    let hpn = props.selectedNode
                                    if (event.target.value != null && event.target.value.length > 0) {
                                        hpn["data"].attributes["value"] = PSUArrayToStringValue([valToArr(props.selectedNode["data"].attributes["value"])[0], event.target.value])
                                    } else {
                                        hpn["data"].attributes["value"] = PSUArrayToStringValue([valToArr(props.selectedNode["data"].attributes["value"])[0], "0"])
                                    }
                                    props.setSelectedNode(hpn)
                                    props.UpdateTrees(hpn)
                                    props.setNeedsSaving(true)

                                }}
                                // sx={autoCompleteStyle}
                            ></TextField>
                        }
                    </Box>
                }

                {/* LimitNode Percentage input*/}
                {
                    props.selectedNode["data"].attributes["nodeType"] == "Limit Node" && props.selectedNode["data"].attributes["field"] != keystoneCtx.colMap.Date &&
                    <Box
                        sx={{
                            // p: 1,
                            display: "flex",
                            gap: 1,
                            // flexGrow: 1
                        }}
                    >

                        <Box
                            sx={{
                                display: "flex",
                                mr: 3
                            }}
                        >
                            <Tooltip
                                title={"Toggle to limit by percentage"}>
                                <Box
                                    onClick={() => {
                                    }}
                                    sx={{
                                        border: `1px solid ${cogTheme.palette.divider}`,
                                        borderRadius: 1,
                                        p: 1,
                                        boxShadow: PSUinsideShadows,
                                        display: "flex",
                                    }}
                                >
                                    <Box>Percentage: </Box>
                                    <Switch
                                        checked={percentageLimit(props.selectedNode["data"].attributes["percentage"])}
                                        size={"small"}
                                        onChange={(event, checked) => {
                                            let hpn = props.selectedNode
                                            if (checked) {
                                                hpn["data"].attributes["percentage"] = "true"
                                            } else {
                                                hpn["data"].attributes["percentage"] = "false"
                                            }
                                            props.setSelectedNode(hpn)
                                            props.UpdateTrees(hpn)
                                            props.setNeedsSaving(true)
                                        }}
                                    ></Switch>
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>
                }
            </Box>
        }
    </>
}

export default PSUSettingsComponent