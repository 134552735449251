import type {FC} from 'react';
import React from 'react';
import logoPath from "./CASalesLogo.svg"

interface CASalesLogoProps extends LogoSwitcherProps{
    [key: string]: any;
}

interface LogoSwitcherProps {
    org?: string;
    icon?: boolean;
}

const CASalesLogo: FC<CASalesLogoProps> = (props) => (
    
    <img
        alt="CA Sales Logo"
        src={logoPath}
        height={props.icon ? 100 : 120}
        width={props.icon ? 100 : 120}
        style={{
            margin: 1,
        }}
        {...props}
    />
);
export default CASalesLogo;