import type { FC } from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import {
  Box, Button, Card, CardContent, CardHeader, Fade,
  Grid, Paper, Popper, Typography
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from "apexcharts";
import {InsightWidgetProps} from "../InsightWidgetSwitcher";

const GenericDonutChart: FC<InsightWidgetProps> = ({chartName, chartData, labelValue}) => {
  const theme = useTheme();

  const chartType = "donut"
  const columnName = labelValue
  const seriesCols = Object.keys(chartData).filter((k) => k != columnName)
  const series = seriesCols ? seriesCols.map((c) => {
    return {
      name: c,
      data: chartData && chartData[c] ? chartData[c] : []
    }
  }) : [{name: "y", data: []}]

  const chartOptions: ApexOptions = {
    chart: {
      type: "donut",
    },
    labels: chartData[columnName],
    title: {
      text: chartName,
      align: 'left'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '90%',
        minHeight: '500px',
        boxShadow: 3,
        p: 2,
        borderRadius: 1,
      }}
    >
      <Chart
        type={chartType}
        width={"100%"}
        height={"100%"}
        series={series}
        options={chartOptions}
      />
    </Box>
  );
}

export default GenericDonutChart;