import {Button, Grid} from "@mui/material";
import React, {FC, useState} from "react";
import {useTheme} from "@mui/material/styles";

interface PeriodSelectorProps {
    period?: string
    periodAction?: (string) => void;
    loadingAction?: (boolean) => void;
}

interface PeriodSelectorInstProps {
    value: string
    selected: boolean
    handleChange: (string) => void;
    text: string
}

const PeriodSelectorInst: FC<PeriodSelectorInstProps> = ({value, selected, handleChange, text}) => {


    return (
        <Button id={value} value={value} onClick={() => handleChange(value)} sx={{
            fontSize: {
                md: '1.5rem',
                xs: '1rem',
            },
            color: (selected == false) ? useTheme().palette.text.primary : useTheme().palette.background.paper,
            background: (selected) ? useTheme().palette.success.main : useTheme().palette.background.paper,
            ':hover': {
                color: (selected == false) ? useTheme().palette.text.secondary : useTheme().palette.text.secondary,
                background: (selected) ? useTheme().palette.success.main : useTheme().palette.background.default,
            },
            boxShadow: useTheme().shadows[2],
            textAlign: 'center',
            justifyContent: 'center',
            // @ts-ignore
            borderRadius: (useTheme().shape.borderRadius * 4),
            margin: 1,
            display: 'flex-box',
        }}>{text}</Button>
    )
}

const PeriodSelector: FC<PeriodSelectorProps> = ({periodAction, period, loadingAction}) => {

    const [value, setValue] = useState(period)

    const handleChange = (v: string) => {
        if (v != value) {
            setValue(v)
            periodAction(v)
            loadingAction(true)
        }
    };


    return (
        <Grid container display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"} gridAutoFlow={"row"}>
            <PeriodSelectorInst value={"Week"} selected={value == "Week"} handleChange={() => handleChange("Week")}
                                text={"Week"}/>

            <PeriodSelectorInst value={"Month"} selected={value == "Month"} handleChange={() => handleChange("Month")}
                                text={"Month"}/>

            <PeriodSelectorInst value={"Year"} selected={value == "Year"} handleChange={() => handleChange("Year")}
                                text={"Year"}/>
        </Grid>

    )

}

export default PeriodSelector