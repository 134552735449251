const Calculation = (props) => {
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Business_Icons" data-name="Business Icons" viewBox="0 0 203 203" height={height}>
            <defs>
                <linearGradient id="claculationBlob" x1="59.6" x2="159.8" y1="151.2" y2={51} gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="claculationLine" x1=".9" x2="5.1" y1="163.4" y2="159.2" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-2" x1="86.3" x2="90.5" y1="5.1" y2=".9" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-3" x1="197.9" x2="202.1" y1="137.3" y2="133.1" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-4" x1={14} x2={16} y1={61} y2="59.1" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-5" x1="16.2" x2="18.1" y1="58.9" y2={57} />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-6" x1="18.4" x2="20.3" y1={61} y2="59.1" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-7" x1="16.2" x2="18.1" y1="63.1" y2="61.2" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-8" x1="178.7" x2="180.6" y1={39} y2={37} />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-9" x1="180.8" x2="182.8" y1="36.8" y2="34.9" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-10" x1="183.1" x2={185} y1="38.9" y2={37} />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-11" x1="180.8" x2="182.8" y1="41.1" y2="39.1" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-12" x1="119.5" x2="121.5" y1="200.4" y2="198.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-13" x1="121.7" x2="123.6" y1="198.2" y2="196.3" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-14" x1="123.9" x2="125.8" y1="200.3" y2="198.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-15" x1="121.7" x2="123.6" y1="202.5" y2="200.5" />
                <linearGradient id="claculationFill" x1="35.1" x2="101.2" y1="164.4" y2="98.3" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#claculationFill" id="claculationFill-2" x1={54} x2="67.8" y1="66.3" y2="52.6" />
                <linearGradient xlinkHref="#claculationFill" id="claculationFill-3" x1="116.5" x2="181.8" y1="139.8" y2="74.5" />
                <linearGradient xlinkHref="#claculationFill" id="claculationFill-4" x1="115.3" x2="122.5" y1="112.8" y2="105.6" />
                <linearGradient xlinkHref="#claculationFill" id="claculationFill-5" x1="115.3" x2="122.5" y1="128.2" y2={121} />
                <linearGradient xlinkHref="#claculationFill" id="claculationFill-6" x1="115.3" x2="122.5" y1={142} y2="134.8" />
                <linearGradient xlinkHref="#claculationFill" id="claculationFill-7" x1="115.6" x2="122.3" y1="98.1" y2="91.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-16" x1="53.9" x2="68.3" y1="66.5" y2="52.2" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-17" x1={34} x2="153.7" y1="164.4" y2="44.8" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-18" x1="123.2" x2="157.1" y1="93.2" y2="59.3" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-19" x1="114.9" x2={123} y1="98.8" y2="90.7" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-20" x1="157.4" x2="165.4" y1="98.8" y2="90.7" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-21" x1="128.9" x2={137} y1="98.8" y2="90.7" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-22" x1="143.2" x2="151.2" y1="98.8" y2="90.7" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-23" x1="114.6" x2="123.2" y1="113.8" y2="105.2" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-24" x1="157.1" x2="165.7" y1="113.8" y2="105.2" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-25" x1="128.7" x2="137.3" y1="113.8" y2="105.2" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-26" x1="142.9" x2="151.5" y1="113.8" y2="105.2" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-27" x1="153.5" x2="169.3" y1="139.4" y2="123.7" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-28" x1="142.9" x2="151.5" y1="128.8" y2="120.2" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-29" x1="142.9" x2="151.5" y1={143} y2="134.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-30" x1="114.6" x2="123.2" y1="128.8" y2="120.2" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-31" x1="128.7" x2="137.3" y1="128.8" y2="120.2" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-32" x1="114.6" x2="123.2" y1={143} y2="134.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-33" x1="128.7" x2="137.3" y1={143} y2="134.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-34" x1="56.4" x2="72.5" y1="147.4" y2="131.3" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-35" x1="56.4" x2="72.5" y1="155.4" y2="139.3" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-36" x1="100.5" x2="116.7" y1="54.4" y2="38.3" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-37" x1="100.5" x2="116.7" y1="62.4" y2="46.3" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-38" x1="156.2" x2="158.8" y1="71.8" y2="69.3" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-39" x1={159} x2="161.6" y1="74.9" y2="72.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-40" x1="153.4" x2={156} y1="74.9" y2="72.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-41" x1={159} x2="161.6" y1="79.9" y2="77.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-42" x1="153.4" x2={156} y1="79.9" y2="77.4" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-43" x1="156.2" x2="158.8" y1={83} y2="80.5" />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-44" x1="161.9" x2="163.3" y1="82.5" y2={81} />
                <linearGradient xlinkHref="#claculationLine" id="claculationLine-45" x1="48.2" x2={73} y1="72.2" y2="47.4" />
                <style dangerouslySetInnerHTML={{__html: "\n      .cls-17{fill:#fff}\n    " }} />
            </defs>
            <g id="Icon_4">
                <path id="Contour" d="M53.5 132.7a2.9 2.9 0 0 0-3-1.5c-5.9 1-22.9 1.5-24.2-25-1.6-32 37.3-86.8 94.4-75.9 45.9 8.8 11.6 52.4 39.7 63.4 16.4 6.4 31.3 39.5-5.2 68.1-19.8 15.6-70.4 29.3-101.7-29.1Z" style={{fill: 'url(#claculationBlob)'}} />
                <path id="Contour-2" d="M3 164.3a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" style={{fill: 'url(#claculationLine)'}} />
                <path id="Contour-3" d="M88.4 6a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" style={{fill: 'url(#claculationLine-2)'}} />
                <path id="Contour-4" d="M200 138.2a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 0 0 0 2 1 1 0 1 0 0-2Z" style={{fill: 'url(#claculationLine-3)'}} />
                <g id="Group">
                    <path id="Contour-5" d="M16.6 60a1 1 0 0 0-1-1h-1.1a1 1 0 1 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#claculationLine-4)'}} />
                    <path id="Contour-6" d="M17.2 59.5a1 1 0 0 0 1-1v-1.1a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#claculationLine-5)'}} />
                    <path id="Contour-7" d="M19.9 59h-1.1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#claculationLine-6)'}} />
                    <path id="Contour-8" d="M17.2 60.7a1 1 0 0 0-1 1v1a1 1 0 0 0 2 0v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#claculationLine-7)'}} />
                </g>
                <g id="Group-2">
                    <path id="Contour-9" d="M181.2 38a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#claculationLine-8)'}} />
                    <path id="Contour-10" d="M181.8 37.4a1 1 0 0 0 1-1v-1.1a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#claculationLine-9)'}} />
                    <path id="Contour-11" d="M184.5 37h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#claculationLine-10)'}} />
                    <path id="Contour-12" d="M181.8 38.6a1 1 0 0 0-1 1v1a1 1 0 0 0 2 0v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#claculationLine-11)'}} />
                </g>
                <g id="Group-3">
                    <path id="Contour-13" d="M122 199.4a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#claculationLine-12)'}} />
                    <path id="Contour-14" d="M122.7 198.8a1 1 0 0 0 1-1v-1.1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#claculationLine-13)'}} />
                    <path id="Contour-15" d="M125.4 198.4h-1.1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#claculationLine-14)'}} />
                    <path id="Contour-16" d="M122.7 200a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#claculationLine-15)'}} />
                </g>
                <path id="Contour-17" d="M111 37.4h21.2a.5.5 0 0 1 .5.6v129.5H38.2a.5.5 0 0 1-.5-.6V38a.5.5 0 0 1 .5-.5h72.9Z" className="cls-17" />
                <path id="Contour-18" d="M38.7 60.2s1.4 92.4 49.7 96.7c0 0 35.7 3 44.3-27.2v37.8l-95-.5Z" style={{fill: 'url(#claculationFill)'}} />
                <path id="Contour-19" d="M59.5 47.3v3.1s-5.2 2.3-3.4 6.9 6.6 4 6.6 4 1 4.3-2 4-2.4-2.3-2.4-2.3h-3.1s1 4.1 3.8 5.1l.2 3.4h3.2l.5-3.5s3.5-1.8 3.5-4.9a6.7 6.7 0 0 0-4.6-6c-2.3-.8-3.5-2.9-.7-3.6 0 0 1.7.5 1.9 1.7l3-.2s-.3-3.5-2.2-4.2-1.4-3.6-1.4-3.6Z" style={{fill: 'url(#claculationFill-2)'}} />
                <rect id="Contour-20" width="64.3" height="90.5" x="108.1" y="61.9" className="cls-17" rx={5} />
                <rect id="Contour-21" width="46.4" height="89.3" x="125.9" y="62.5" rx="4.3" style={{fill: 'url(#claculationFill-3)'}} />
                <rect id="Contour-22" width="53.7" height="17.6" x="113.3" y="67.4" className="cls-17" rx="5.4" />
                <circle id="Contour-23" cx="118.9" cy="109.2" r="5.1" style={{fill: 'url(#claculationFill-4)'}} />
                <circle id="Contour-24" cx="118.9" cy="124.6" r="5.1" style={{fill: 'url(#claculationFill-5)'}} />
                <circle id="Contour-25" cx="118.9" cy="138.4" r="5.1" style={{fill: 'url(#claculationFill-6)'}} />
                <circle id="Contour-26" cx="147.2" cy="109.9" r="5.1" className="cls-17" />
                <circle id="Contour-27" cx="132.8" cy="109.9" r="5.1" className="cls-17" />
                <circle id="Contour-28" cx="147.2" cy="124.6" r="5.1" className="cls-17" />
                <circle id="Contour-29" cx="132.8" cy="124.6" r="5.1" className="cls-17" />
                <circle id="Contour-30" cx="147.2" cy="138.4" r="5.1" className="cls-17" />
                <circle id="Contour-31" cx="132.8" cy="138.4" r="5.1" className="cls-17" />
                <rect id="Contour-32" width="11.2" height="5.1" x="155.8" y="92.4" className="cls-17" rx="2.6" />
                <rect id="Contour-33" width="11.2" height="5.1" x="127.4" y="92.4" className="cls-17" rx="2.6" />
                <rect id="Contour-34" width="11.2" height="5.1" x="141.6" y="92.4" className="cls-17" rx="2.6" />
                <rect id="Contour-35" width="11.2" height="5.1" x="113.3" y="92.2" rx="2.6" style={{fill: 'url(#claculationFill-7)'}} />
                <path id="Contour-36" d="M124.3 108a4.2 4.2 0 0 1-4.3 4.4 4.4 4.4 0 0 1-4.4-4.4 4.2 4.2 0 0 1 4.4-4.3c.7 0 4.2 2.8 4.3 4.3Z" className="cls-17" />
                <path id="Contour-37" d="M124.3 122.9a4.2 4.2 0 0 1-4.3 4.4 4.4 4.4 0 0 1-4.4-4.4 4.2 4.2 0 0 1 4.4-4.3c.7 0 4.2 2.9 4.3 4.3Z" className="cls-17" />
                <path id="Contour-38" d="M124.3 137a4.2 4.2 0 0 1-4.3 4.4 4.4 4.4 0 0 1-4.4-4.4 4.2 4.2 0 0 1 4.4-4.3c.7 0 4.2 2.9 4.3 4.3Z" className="cls-17" />
                <circle id="Contour-39" cx="161.4" cy="109.5" r="5.1" className="cls-17" />
                <path id="Contour-40" d="M166.5 138.7a5.1 5.1 0 0 1-10.2 0v-14.2a5.1 5.1 0 0 1 10.2 0Z" className="cls-17" />
                <g id="Group-4">
                    <path id="Contour-41" d="M63.6 70.6v-2.1a5.8 5.8 0 0 0 2-1.3 6 6 0 0 0 1.8-4.5 5.7 5.7 0 0 0-1.3-3.9 8.7 8.7 0 0 0-4-2.3 4.8 4.8 0 0 1-1.6-.7l-.1-.5a.6.6 0 0 1 .1-.4.7.7 0 0 1 .6-.2.6.6 0 0 1 .4.1.7.7 0 0 1 .2.3 1.5 1.5 0 0 0 1.4 1h2.2a1.5 1.5 0 0 0 1.1-.5 1.4 1.4 0 0 0 .4-1 5.4 5.4 0 0 0-1.7-3.4 5.3 5.3 0 0 0-1.5-1v-2a1.4 1.4 0 0 0-1.4-1.5h-2.3a1.5 1.5 0 0 0-1.4 1.6v1.9a5.9 5.9 0 0 0-1.7 1.1 5.7 5.7 0 0 0-1.7 4.2 5.3 5.3 0 0 0 1.3 3.7 8.6 8.6 0 0 0 3.8 2.2 5.7 5.7 0 0 1 1.7.7.6.6 0 0 1 .2.5 1.2 1.2 0 0 1-.3 1 1.1 1.1 0 0 1-1 .3 1.2 1.2 0 0 1-.8-.3 1 1 0 0 1-.3-.6 1.4 1.4 0 0 0-1.4-1.3h-2.4a1.4 1.4 0 0 0-1.4 1.7 7.2 7.2 0 0 0 1.7 3.8 6.4 6.4 0 0 0 2.3 1.4v2a1.4 1.4 0 0 0 1.4 1.4h2.3a1.4 1.4 0 0 0 1.4-1.4Zm-2-.6h-1.1v-1.8a1.5 1.5 0 0 0-1-1.4 4.3 4.3 0 0 1-2-1 4.6 4.6 0 0 1-1-2h1.3a3.1 3.1 0 0 0 .8 1.2 3.4 3.4 0 0 0 4.6 0 3.2 3.2 0 0 0 .9-2.4 2.6 2.6 0 0 0-.9-2 7.5 7.5 0 0 0-2.4-1.1 7 7 0 0 1-3-1.6 3.3 3.3 0 0 1-.7-2.4 3.7 3.7 0 0 1 1-2.7 4 4 0 0 1 1.4-.9 1.4 1.4 0 0 0 1-1.3v-2h1.1v2a1.4 1.4 0 0 0 1 1.4 3.3 3.3 0 0 1 1.2.7 3.4 3.4 0 0 1 .9 1.5h-1.2a2.5 2.5 0 0 0-.6-.8 2.6 2.6 0 0 0-1.8-.7 2.7 2.7 0 0 0-2 .8 2.6 2.6 0 0 0-.7 1.9 2.5 2.5 0 0 0 .8 2 6.7 6.7 0 0 0 2.3 1 6.8 6.8 0 0 1 3 1.8 3.8 3.8 0 0 1 .9 2.6 4 4 0 0 1-1.3 3 3.9 3.9 0 0 1-1.5 1 1.4 1.4 0 0 0-1 1.3Z" style={{fill: 'url(#claculationLine-16)'}} />
                    <path id="Contour-42" d="M167.3 61h-32.6V38a2.5 2.5 0 0 0-2.5-2.5h-93a2.5 2.5 0 0 0-2.5 2.5v128a2.5 2.5 0 0 0 2.5 2.5h93a2.5 2.5 0 0 0 2.5-2.5v-12.6h32.6a6 6 0 0 0 6-6V67a6 6 0 0 0-6-6Zm-34.6 105a.5.5 0 0 1-.5.5h-93a.5.5 0 0 1-.6-.5V38a.5.5 0 0 1 .6-.5h93a.5.5 0 0 1 .5.5v23H113a6 6 0 0 0-6 6v18.6H64.3a1 1 0 0 0 0 2H107v8.9H49.7a1 1 0 1 0 0 2h57.4v8.4H49.7a1 1 0 0 0 0 2h57.4v8.5H49.7a1 1 0 0 0 0 2h57.4v28a6 6 0 0 0 6 6h19.5Zm38.6-18.6a4 4 0 0 1-4 4h-54.2a4 4 0 0 1-4-4V67a4 4 0 0 1 4-4h54.3a4 4 0 0 1 4 4Z" style={{fill: 'url(#claculationLine-17)'}} />
                    <path id="Contour-43" d="M161.6 66.4h-42.9a6.4 6.4 0 0 0-6.4 6.4v6.9a6.4 6.4 0 0 0 6.4 6.4h43a6.4 6.4 0 0 0 6.3-6.4v-6.9a6.4 6.4 0 0 0-6.4-6.4Zm4.4 13.3a4.4 4.4 0 0 1-4.4 4.4h-42.9a4.4 4.4 0 0 1-4.4-4.4v-6.9a4.4 4.4 0 0 1 4.4-4.4h43a4.4 4.4 0 0 1 4.3 4.4Z" style={{fill: 'url(#claculationLine-18)'}} />
                    <path id="Contour-44" d="M122 91.2h-6a3.6 3.6 0 1 0 0 7.1h6a3.6 3.6 0 1 0 0-7Zm0 5.1h-6a1.6 1.6 0 1 1 0-3h6a1.6 1.6 0 1 1 0 3Z" style={{fill: 'url(#claculationLine-19)'}} />
                    <path id="Contour-45" d="M164.4 91.2h-6a3.6 3.6 0 1 0 0 7.1h6a3.6 3.6 0 1 0 0-7Zm0 5.1h-6a1.6 1.6 0 1 1 0-3h6a1.6 1.6 0 1 1 0 3Z" style={{fill: 'url(#claculationLine-20)'}} />
                    <path id="Contour-46" d="M136 91.2h-6a3.6 3.6 0 1 0 0 7.1h6a3.6 3.6 0 0 0 0-7Zm0 5.1h-6a1.6 1.6 0 1 1 0-3h6a1.6 1.6 0 0 1 0 3Z" style={{fill: 'url(#claculationLine-21)'}} />
                    <path id="Contour-47" d="M150.2 91.2h-6a3.6 3.6 0 1 0 0 7.1h6a3.6 3.6 0 1 0 0-7Zm0 5.1h-6a1.6 1.6 0 1 1 0-3h6a1.6 1.6 0 1 1 0 3Z" style={{fill: 'url(#claculationLine-22)'}} />
                    <path id="Contour-48" d="M119 103.5a6 6 0 1 0 6 6 6.1 6.1 0 0 0-6-6Zm0 10.1a4 4 0 1 1 4-4 4.1 4.1 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-23)'}} />
                    <path id="Contour-49" d="M161.4 103.5a6 6 0 1 0 6 6 6.1 6.1 0 0 0-6-6Zm0 10.1a4 4 0 1 1 4-4 4 4 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-24)'}} />
                    <path id="Contour-50" d="M133 103.5a6 6 0 1 0 6 6 6 6 0 0 0-6-6Zm0 10.1a4 4 0 1 1 4-4 4 4 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-25)'}} />
                    <path id="Contour-51" d="M147.2 103.5a6 6 0 1 0 6 6 6 6 0 0 0-6-6Zm0 10.1a4 4 0 1 1 4-4 4 4 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-26)'}} />
                    <path id="Contour-52" d="M161.4 118.4a6.1 6.1 0 0 0-6.1 6v14.3a6.1 6.1 0 0 0 12.2 0v-14.2a6.1 6.1 0 0 0-6.1-6.1Zm4 20.3a4.1 4.1 0 0 1-8.1 0v-14.2a4.1 4.1 0 0 1 8.2 0Z" style={{fill: 'url(#claculationLine-27)'}} />
                    <path id="Contour-53" d="M147.2 118.4a6.1 6.1 0 1 0 6 6 6.1 6.1 0 0 0-6-6Zm0 10.2a4.1 4.1 0 1 1 4-4.1 4.1 4.1 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-28)'}} />
                    <path id="Contour-54" d="M147.2 132.6a6.1 6.1 0 1 0 6 6.1 6.1 6.1 0 0 0-6-6.1Zm0 10.2a4.1 4.1 0 1 1 4-4.1 4.1 4.1 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-29)'}} />
                    <path id="Contour-55" d="M119 118.4a6.1 6.1 0 1 0 6 6 6.1 6.1 0 0 0-6-6Zm0 10.2a4.1 4.1 0 1 1 4-4.1 4.1 4.1 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-30)'}} />
                    <path id="Contour-56" d="M133 118.4a6.1 6.1 0 1 0 6 6 6.1 6.1 0 0 0-6-6Zm0 10.2a4.1 4.1 0 1 1 4-4.1 4.1 4.1 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-31)'}} />
                    <path id="Contour-57" d="M119 132.6a6.1 6.1 0 1 0 6 6.1 6.1 6.1 0 0 0-6-6.1Zm0 10.2a4.1 4.1 0 1 1 4-4.1 4.1 4.1 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-32)'}} />
                    <path id="Contour-58" d="M133 132.6a6.1 6.1 0 1 0 6 6.1 6.1 6.1 0 0 0-6-6.1Zm0 10.2a4.1 4.1 0 1 1 4-4.1 4 4 0 0 1-4 4Z" style={{fill: 'url(#claculationLine-33)'}} />
                    <path id="Contour-59" d="M79.2 138.4H49.7a1 1 0 0 0 0 2h29.5a1 1 0 0 0 0-2Z" style={{fill: 'url(#claculationLine-34)'}} />
                    <path id="Contour-60" d="M79.2 146.4H49.7a1 1 0 0 0 0 2h29.5a1 1 0 0 0 0-2Z" style={{fill: 'url(#claculationLine-35)'}} />
                    <path id="Contour-61" d="M93.9 47.3h29.4a1 1 0 0 0 0-2H94a1 1 0 0 0 0 2Z" style={{fill: 'url(#claculationLine-36)'}} />
                    <path id="Contour-62" d="M93.9 55.3h29.4a1 1 0 0 0 0-2H94a1 1 0 0 0 0 2Z" style={{fill: 'url(#claculationLine-37)'}} />
                    <path id="Contour-63" d="M156.4 71.6h2.2a1 1 0 0 0 0-2h-2.2a1 1 0 0 0 0 2Z" style={{fill: 'url(#claculationLine-38)'}} />
                    <path id="Contour-64" d="M160.3 71.6a1 1 0 0 0-1 1v2.2a1 1 0 0 0 2 0v-2.2a1 1 0 0 0-1-1Z" style={{fill: 'url(#claculationLine-39)'}} />
                    <path id="Contour-65" d="M154.7 75.8a1 1 0 0 0 1-1v-2.2a1 1 0 0 0-2 0v2.2a1 1 0 0 0 1 1Z" style={{fill: 'url(#claculationLine-40)'}} />
                    <path id="Contour-66" d="M161.3 77.5a1 1 0 0 0-2 0v2.3a1 1 0 1 0 2 0v-2.3Z" style={{fill: 'url(#claculationLine-41)'}} />
                    <path id="Contour-67" d="M155.7 79.8v-2.3a1 1 0 1 0-2 0v2.3a1 1 0 0 0 2 0Z" style={{fill: 'url(#claculationLine-42)'}} />
                    <path id="Contour-68" d="M158.6 80.7h-2.2a1 1 0 0 0 0 2h2.2a1 1 0 0 0 0-2Z" style={{fill: 'url(#claculationLine-43)'}} />
                    <circle id="Contour-69" cx="162.6" cy="81.7" r={1} style={{fill: 'url(#claculationLine-44)'}} />
                    <path id="Contour-70" d="M78.2 59.8a17.5 17.5 0 1 0-17.6 17.5 17.6 17.6 0 0 0 17.6-17.5Zm-33.1 0a15.5 15.5 0 1 1 15.5 15.5 15.6 15.6 0 0 1-15.5-15.5Z" style={{fill: 'url(#claculationLine-45)'}} />
                </g>
            </g>
        </svg>

    )
}
export default Calculation