import React, { FC } from "react";
import CardAttribute from "../../../../../cog/keystone/card/cardAttribute/CardAttribute";
import AttributeCard from "./windowAttributes/AttributeCard";
import {Box, Grid, Typography} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";

interface AdditionalInfoSectionProps {
  cardName: string;
  opportunityType: string;
  displayValue: string;
  additionalDisplayValue;
  role: number;
  level: number;
  levelFilter: string;
  v0: string;
  org: string;
  attributes: CardAttribute[];
}

const AdditionalInfoSection: FC<AdditionalInfoSectionProps> = ({cardName, org, opportunityType, role, level, levelFilter, displayValue, additionalDisplayValue, attributes, v0}) => {
  const sortedAttributes: CardAttribute[] = attributes.sort((n1, n2) => {
    if (n1.attributeOrder > n2.attributeOrder) {
      return 1
    }

    if (n1.attributeOrder < n2.attributeOrder) {
      return -1
    }

    return 0
  });

  return (
    <Grid container spacing={4} sx={{ marginBottom: 2 }} >
      {sortedAttributes.length != 0 ?
      <Grid item xs={12} xl={12}>
        <Typography variant={"h3"} sx={{mt: 2, mb: 2}}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap"
          }}>
            <InfoOutlined sx={{fontSize: "2rem", mr: 2}}/>
            Insights
          </Box>
        </Typography>
      </Grid>
      :
      <Box/>}
      {sortedAttributes.length != 0 && sortedAttributes.map((attribute, index) => {
        return (
          <Grid key={index} item xl={3} md={4} xs={12}>

            <AttributeCard
              key={index}
              cardName={cardName}
              opportunityType={opportunityType}
              displayValue={displayValue}
              additionalDisplayValue={additionalDisplayValue}
              role={role}
              level={level}
              levelFilter={levelFilter}
              v0={v0}
              org={org}
              attribute={attribute}
              gridNumber={index}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AdditionalInfoSection;

