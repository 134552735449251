import type { FC } from 'react';
import {
    Avatar,
    Box,
    Card, Chip,
    Grid,
    LinearProgress, Stack,
    Typography
} from '@mui/material';
import CalendarIcon from '../icons/Calendar';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Label from "./Label";

interface QuickStatsProps {
    title: string;
    value: number;
    percentage?: number;
    color?: string;
    prefix?: string;
    suffix?: string;
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ZAR',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function numberWithCommas(x) {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

const QuickStats: FC<QuickStatsProps> = ({title, value, color, percentage, prefix, suffix}) =>
    (
    <Card
        sx={{
            backgroundColor: color,
            color: "white",
            fontSize: 14,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            // height: "80%"
        }}
    >
        <Box sx={{ flexGrow: 1 }}>
            <Typography
                gutterBottom
                variant="overline"
                sx={{ color: (theme) => theme.palette.text.primary }}
            >
                { title }
            </Typography>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    p: 1,
                }}
            >
                <Typography
                    sx={{ mr: 1, p: 2, color: (theme) => theme.palette.text.primary }}
                    variant="h4"
                >
                    {
                        (prefix == 'ZAR') ? formatter.format(+value.toFixed(2)):(suffix) ? prefix + " " + numberWithCommas(value) + " " + suffix: prefix + " " + numberWithCommas(value)
                    }
                </Typography>
                { percentage &&
                    <Stack
                        direction="column"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center">
                        { percentage > 0 &&
                            <Box sx={{
                                width: 0,
                                height: 0,
                                borderLeft: "20px solid transparent",
                                borderRight: "20px solid transparent",
                                borderBottom: "20px solid #00FF00",
                            }}
                            />
                        }
                        { percentage < 0 &&
                            <Box sx={{
                                width: 0,
                                height: 0,
                                borderLeft: "20px solid transparent",
                                borderRight: "20px solid transparent",
                                borderTop: "20px solid #f00",
                            }}
                            />
                        }
                        <Typography
                            variant="h6"
                            sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                            { percentage.toFixed(2) } %
                        </Typography>
                    </Stack>
                }


            </Box>
        </Box>
        {/*<Avatar*/}
        {/*    sx={{*/}
        {/*        backgroundColor: 'primary.main',*/}
        {/*        color: 'primary.contrastText',*/}
        {/*        height: 48,*/}
        {/*        width: 48*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <CalendarIcon fontSize="small" />*/}
        {/*</Avatar>*/}
    </Card>
);

export default QuickStats;
