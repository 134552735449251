import {
    Autocomplete, Avatar, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography
} from "@mui/material";
import {Box, Container} from "@mui/system";
import React, {FC, useEffect, useState} from "react";
import GoalPriorityRating from "./GoalPriorityRating";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import {Formik} from "formik";
import {useMainContext} from "../../../../contexts/MainContext";
import moment from "moment";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {CampaignGoalStore, CampaignStore} from "../../../../cog/keystone/goals/campaigns";
import {Member} from "../../../../cog/keystone/member";
import Goal from "../../../../cog/keystone/goals/goal/Goal";
import {GoalStore} from "../../../../cog/keystone/goals";


interface CreateCampaignDialogProps {
    open: boolean
    setOpen: (v) => void
    update?: () => void
    changed?: () => void
}

const CreateCampaignDialog: FC<CreateCampaignDialogProps> = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const ctx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const [allGoals, setAllGoals] = useState<Goal[]>()
    const [selectedGoals, setSelectedGoals] = useState<[]>()
    const [loading, setLoading] = useState<boolean>(true)

    const fetchAllGoals = async () => {
        const response = await GoalStore.FindAll({clientName: ctx.activeOrganization.name})

        if (response) {
            return response.goals
        }
    }

    useEffect(() => {
        let isMounted = true;
        fetchAllGoals().then((r) => {
            if (isMounted) {
                setAllGoals(r)
                setLoading(false)
            }
        })

        return () => {
            isMounted = false;
        };
    }, [])

    const handleAssignGoals = async (campaignID: string, goals: Goal[]) => {
        const goalIDs: string[] = []

        for (let i = 0; i < goals.length; i++) {
            goalIDs.push(goals[i].id)
        }

        const response = await CampaignGoalStore.CreateMany({
            campaignID: campaignID,
            goalIDs: goalIDs,
            clientName: ctx.activeOrganization.name
        })
        if (response){
            if (props.changed) {
                props.changed()
            }
            props.update()
        }
        return response
    }

    return (
        <Box>
            <Dialog
                open={props.open}
                //disableEnforceFocus required to open chat if Dialog opened first
                disableEnforceFocus
                onClose={() => {
                    console.log("CreateCampaignDialog onClose")
                    props.setOpen(false)
                }}
            >
                <DialogTitle textAlign="center">Create A Campaign</DialogTitle>
                <DialogContent>
                    <Container component="main"
                               maxWidth="xs">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                name: "",
                                dueDate: moment().format("YYYY-MM-DD"),
                                description: "",
                                priority: 0,
                                target: 0,
                                goals: [],
                                type: "",
                                owner: "",
                                submit: null,
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().max(255).required("Name is required"),
                                dueDate: Yup.string().required("Due Date is required"),
                                description: Yup.string(),
                                priority: Yup.number().required("Priority is required"),
                                target: Yup.number().required("Target is required"),
                                // goals: Yup.array(),
                                type: Yup.string(),
                                // owner: Yup.string().required("Owner is required"),
                            })}
                            onSubmit={async (
                                values,
                                {resetForm, setErrors, setStatus, setSubmitting}
                            ): Promise<void> => {
                                try {
                                    await CampaignStore.CreateOne({
                                        campaign: {
                                            description: values.description,
                                            name: values.name,
                                            type: values.type,
                                            target: +values.target,
                                            owner: values.owner['displayName'],
                                            priority: values.priority,
                                            dueDate: values.dueDate,
                                        },
                                        clientName: ctx.activeOrganization.name
                                    }).then((response) => {
                                        if (response) {
                                            if (values.goals.length > 0) {
                                                const secondaryResponse = handleAssignGoals(response.id, values.goals)
                                                if (secondaryResponse) {
                                                    setStatus({success: true});
                                                    enqueueSnackbar("Campaign Created", {
                                                        anchorOrigin: {
                                                            horizontal: "right",
                                                            vertical: "top",
                                                        },
                                                        variant: "success",
                                                    });
                                                } else {
                                                    setStatus({success: false});
                                                    enqueueSnackbar("Failed to Create Campaign!", {
                                                        anchorOrigin: {
                                                            horizontal: "right",
                                                            vertical: "top",
                                                        },
                                                        variant: "error",
                                                    });
                                                }
                                            } else {
                                                setStatus({success: true});
                                                enqueueSnackbar("Campaign Created", {
                                                    anchorOrigin: {
                                                        horizontal: "right",
                                                        vertical: "top",
                                                    },
                                                    variant: "success",
                                                });
                                            }
                                        } else {
                                            setStatus({success: false});
                                            enqueueSnackbar("Failed to Create Campaign!", {
                                                anchorOrigin: {
                                                    horizontal: "right",
                                                    vertical: "top",
                                                },
                                                variant: "error",
                                            });
                                        }
                                    })
                                    resetForm();
                                    setSubmitting(false);
                                    props.setOpen(false)
                                    props.update()
                                    keystoneCtx.setFlipSwitchForGoals(!keystoneCtx.flipSwitchForGoals)
                                } catch (err) {
                                    console.error(err);
                                    setStatus({success: false});
                                    setErrors({submit: err.message});
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue,
                                  values,
                              }): JSX.Element => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        sx={{
                                            marginTop: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid container
                                              spacing={2}>
                                            <Grid item
                                                  xs={12}
                                                  sm={6}>
                                                <TextField
                                                    name="name"
                                                    required
                                                    fullWidth
                                                    id="name"
                                                    label="Name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid item
                                                  xs={12}
                                                  sm={6}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="dueDate"
                                                    label="Due Date"
                                                    name="dueDate"
                                                    type={"date"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.dueDate}
                                                />
                                            </Grid>
                                            <Grid item
                                                  xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="description"
                                                    label="Description"
                                                    name="description"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.description}
                                                />
                                            </Grid>
                                            {/*<Grid item*/}
                                            {/*      xs={12}>*/}
                                            {/*    <TextField*/}
                                            {/*        required*/}
                                            {/*        fullWidth*/}
                                            {/*        id="type"*/}
                                            {/*        label="Type"*/}
                                            {/*        name="type"*/}
                                            {/*        onBlur={handleBlur}*/}
                                            {/*        onChange={handleChange}*/}
                                            {/*        value={values.type}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            <Grid item
                                                  xs={12}
                                                  lg={12}
                                                  md={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Box sx={{marginLeft: 1}}>
                                                            <GoalPriorityRating
                                                                rating={values.priority}
                                                                handleChange={(e, v) => {
                                                                    setFieldValue("priority", v || "");
                                                                }}
                                                                readOnly={false}
                                                            />
                                                        </Box>}
                                                    label="Priority"
                                                    labelPlacement={"start"}
                                                />
                                            </Grid>
                                            <Grid item
                                                  xs={12}>
                                                <Autocomplete
                                                    disablePortal
                                                    multiple={false}
                                                    freeSolo={true}
                                                    forcePopupIcon={true}
                                                    id="assignOwner"
                                                    options={ctx.members != null ? ctx.members : []}
                                                    onBlur={handleBlur}
                                                    onChange={(e, v) => {
                                                        setFieldValue("owner", v || "");
                                                    }}
                                                    getOptionLabel={(option: Member) => option.displayName || ""}
                                                    isOptionEqualToValue={(option: Member, value: Member) => option.displayName === value.displayName}
                                                    value={values.owner}
                                                    renderInput={(params) =>
                                                        <TextField {...params} label="Assign Owner"/>}
                                                    renderOption={(props, option) => (
                                                        <Box component="li"
                                                             sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                            <Avatar alt={option.displayName}
                                                                    src={option.avatar}/>
                                                            <Typography
                                                                component="div"
                                                                sx={{margin: "auto"}}
                                                            >
                                                                {option.displayName}
                                                            </Typography>
                                                        </Box>
                                                    )}

                                                />
                                            </Grid>
                                            {/*<Grid item*/}
                                            {/*      xs={12}>*/}
                                            {/*    <TextField*/}
                                            {/*        required*/}
                                            {/*        fullWidth*/}
                                            {/*        id="target"*/}
                                            {/*        label="Target"*/}
                                            {/*        name="target"*/}
                                            {/*        // variant={(autoTarget) ? "filled" : "outlined"}*/}
                                            {/*        onBlur={handleBlur}*/}
                                            {/*        onChange={handleChange}*/}
                                            {/*        value={values.target}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            <Grid item
                                                  xs={12}
                                                  sm={12}>
                                                <Autocomplete
                                                    disablePortal
                                                    fullWidth
                                                    freeSolo={true}
                                                    forcePopupIcon={true}
                                                    multiple={true}
                                                    id="goals"
                                                    options={allGoals != null ? allGoals : []}
                                                    getOptionLabel={(option: Goal) => option.name || ""}
                                                    isOptionEqualToValue={(option: Goal, value: Goal) => option.id === value.id}
                                                    onBlur={handleBlur}
                                                    onChange={(e, v) => {
                                                        setFieldValue("goals", v || "");
                                                    }}
                                                    value={values.goals}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {option.name}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params} label="Attach Goals"/>}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{mt: 3, mb: 2}}
                                        >
                                            Create
                                        </Button>
                                    </Box>
                                </form>)}
                        </Formik>
                    </Container>
                </DialogContent>
            </Dialog>

        </Box>
    )
}

export default CreateCampaignDialog;