import type {FC} from 'react';
import React from 'react';
import logoPath from "./LogicoLogo.svg"

interface LogicoLogoProps extends LogoSwitcherProps{
    [key: string]: any;
}

interface LogoSwitcherProps {
    org?: string;
    icon?: boolean;
}

const LogicoLogo: FC<LogicoLogoProps> = (props) => (
    <img
        alt="Logico Logo"
        src={logoPath}
        height={props.icon ? 70 : 100}
        width={props.icon ? 70 : 100}
        style={{
            margin: 1
        }}
        {...props}
    />
);
export default LogicoLogo;