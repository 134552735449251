import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import clsx from "clsx";
import { alpha, createTheme, Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import {Box, LinearProgress, styled, Tooltip} from "@mui/material";
import {FC, useEffect} from "react";

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                border: theme.palette.mode === 'dark' ? '2px solid' : '1px solid',
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: 26,
                fontWeight: "bold",
                borderRadius: 10,
                "&.low": {
                    borderColor: theme.palette.mode === 'dark' ? '#ed4841' : theme.palette.error.main,
                    backgroundColor: theme.palette.mode === 'dark' ? (alpha('#fe8a83', 0.9)) : (alpha(theme.palette.error.main, 0.08)),
                },
                "&.medium": {
                    borderColor: theme.palette.warning.main,
                    backgroundColor: theme.palette.mode === 'dark' ? (alpha('#ffb74d', 0.9)) : (alpha(theme.palette.warning.main, 0.08)),
                },
                "&.high": {
                  borderColor: theme.palette.mode === 'dark' ? '#4caf50' : theme.palette.success.main,
                  backgroundColor: theme.palette.mode === 'dark' ? (alpha('#80c883', 0.9)) : (alpha(theme.palette.success.main, 0.08)),
                }
            },
            value: {
                position: "absolute",
                lineHeight: "24px",
                width: "100%",
                display: "flex",
                justifyContent: "center"
            },
            bar: {
                height: "100%",
                //borderRadius: 5,
                "&.low": {
                    backgroundColor: theme.palette.mode === 'dark' ? '#ed4841' : theme.palette.error.main
                },
                "&.medium": {
                    backgroundColor: theme.palette.warning.main
                },
                "&.high": {
                    backgroundColor: theme.palette.mode === 'dark' ? '#4caf50' : theme.palette.success.main
                }
            }
        }),
);
interface ProgressBarProps {
    value: number;
}

const ProgressBar: FC<ProgressBarProps> = (props: ProgressBarProps) => {
    const { value } = props;
    const [valueInPercent, setValueInPercent] = React.useState<any>(0);
    const classes = useStyles();
    useEffect(() => {
        let isMounted = true;
        let count = 0;
        const interval = setInterval(() => {
            if (count <= value && count <= 100) {
                if (isMounted) {
                    setValueInPercent(count);
                    count++;
                }
            } else {
                clearInterval(interval);
            }
        }, 10);

        return () => {
            clearInterval(interval);
            isMounted = false;
        };
    }, [value]);

    return (
        <Tooltip title={`${valueInPercent.toFixed(3)} %`}>
        <Box className={clsx(classes.root, {
            low: valueInPercent < 30,
            medium: valueInPercent >= 30 && valueInPercent <= 70,
            high: valueInPercent > 70
        })}>
            <Box
                className={classes.value}
            >{`${valueInPercent.toLocaleString()} %`}</Box>
            <Box
                className={clsx(classes.bar, {
                    low: valueInPercent < 30,
                    medium: valueInPercent >= 30 && valueInPercent <= 70,
                    high: valueInPercent > 70
                })}
                sx={{ maxWidth: `${valueInPercent}%` }}
            />
        </Box>
        </Tooltip>
    );
};

export default ProgressBar;