import {FC} from "react";
import React from 'react';
import IconList from "./keystoneIcons"
import {Box} from "@mui/system";


interface CustomKeystoneIcon {
    [key: string]: any;
}
const CustomKeystoneIcon: FC<CustomKeystoneIcon> = (props) => {

    var colourPalette =  {
        0:{blob:'#404041', line:'#000', fill:'#f05c59',height:props.height},
        1:{blob:'#f05c59', line:'#000', fill:'#f6c743',height:props.height},
        2:{blob:'#f6c743', line:'#000', fill:'#404041',height:props.height},
    }
    const getColour = () => {
        if(props.position !== undefined){
            let pos = (props.position/3).toPrecision(4)
            pos = String((parseFloat(pos.charAt(pos.length-2))/3))
            return colourPalette[pos]
        }
        return props.colours
    }
    const getElement = () =>{
        let iconElement

        if(Object.keys(IconList).includes(props.name,0)){
            iconElement = React.createElement(IconList[props.name], getColour())
        }else{
            iconElement = React.createElement(IconList['ProductDetail'], getColour())
        }

        return iconElement
    }

    return getElement()

};

export default CustomKeystoneIcon;