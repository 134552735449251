import {FC} from "react";
import {Box} from "@mui/material";
import LeaderSummaryItem from "./LeaderSummaryItem";
import {Leaderboard} from "../../../../cog/keystone/leaderboard";
import {LeaderboardProps} from "./Leaderboard";

const LeaderSummary: FC<LeaderboardProps> = ({items}) => {
    let firstThreeItems = items.slice(0, 3)
    let swap = (arr,i,j)=> arr.map((e,k)=> k-i ? (k-j ? e : arr[i]) : arr[j]);
    firstThreeItems = swap(firstThreeItems, 0, 1)

    return (
        //LeaderBoard Ranking Top Block
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexWrap: 'wrap',
            padding: '0',
            margin: '0',
        }}>
            { firstThreeItems && firstThreeItems.map((item) => {
                return <LeaderSummaryItem {...item}/>
            })
            }
            {items.length == 0 && <Box sx={{
                width: '100%',
                textAlign: 'center',
                margin: 5
            }}>No Leaderboard Data Found</Box>}
        </Box>
    )
}

export default LeaderSummary