import {RawNodeDatum} from "react-d3-tree";

// GenerateNewPSUBaseNode : Makes a new node, using the const one changes it
export const GenerateNewPSUBaseNode = (ClientName: string) => {
    return {
        name: "BaseNode",
        attributes: {
            nodeType: "root",
            client: ClientName,
        },
        children: []
    }
}

export const GenerateNewPsuTree = (ClientName: string) => {
    return {
        ID: "",
        Name: "New PSU",
        Org_Level: false,
        Tree: GenerateNewPSUBaseNode(ClientName),
        User_ID: ""
    }
}

// GenerateGenericPSUNode : Generates a new node(not root node) for the tree
export const GenerateGenericPSUNode = () => {
    return {
        name: "Generic Node",
        attributes: {
            nodeType: "Filter Node",
            field: null,
            operation: null,
            value: null,
            sortValue: null,
            percentage: null
        },
        children: []
    }
}

export const PSUNodeTypeOptions = [
    // "LimitNode",
    // "ValueNode",
    "Limit Node",
    "Filter Node"
]

// probs don't need this
export const PSUOrderOptions = [
    "desc", "asc"
]

export const UpdatedPSUOperationOptions = [
    "INCLUDE", "EQUAL", "NOT EQUAL", "EXCLUDE", "GREATER THAN", "LESS THAN", "GREATER THAN OR EQUAL", "LESS THAN OR EQUAL", "BETWEEN"
]

export const PSUOperationOptions = [
    "IN", "NOT IN", ">", "<", ">=", "<=", "BETWEEN", "=", "!="
]

// Probably better to just have any text input instead of an autocomplete
export const PSULimitOptions = ["5",
    "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95", "100",
    // "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180", "185", "190", "195", "200",
]

/** SplitHerePattern : This is a value unlikely to be in a value for the filter, so we can split the value string into an array in order to save multiple values without nesting JSON
 *                     There is probably a better idea for this
 *                     Todo : Have a better idea for this
***/
export const SplitHerePattern = "-,<<SplitValHere>>,-"

// PSUValueStringToArray : Receives a string and returns an array of strings, used for the autocomplete part o the PSU nodes
export const PSUValueStringToArray = (valueString: string) => {
    // // console.log("result string to arr", tempAssigns)
    let tempAssigns: string[] = []
    tempAssigns = valueString.substring(1, valueString.length-1).split(SplitHerePattern)

    // This is important, makes sure the tree saves and loads right
    for (let i = 0; i < tempAssigns.length; i++) {
        tempAssigns[i] = tempAssigns[i].replaceAll('\\"', `"`)
        tempAssigns[i] = tempAssigns[i].replaceAll('"', `\\"`)
    }
    return tempAssigns
}

// PSUArrayToStringValue : Receives a string array and returns a string, for saving the value field of the PSU trees
export const PSUArrayToStringValue = (valueArray: string[]) => {
    let result = `[`
    for (let i = 0; i < valueArray.length; i++) {
        if (i > 0) {
            result += SplitHerePattern
        }
        result += `` + valueArray[i] + ``
    }
    result += `]`
    // This is important, makes sure the tree saves and loads right
    result = result.replaceAll('\\"', `"`)
    result = result.replaceAll('"', `\\"`)
    return result
}

// PSUArrayToStringValue : Receives a string array and returns a string, for saving the value field of the PSU trees
export const PSUArrayToStringValueButPretty = (valueArray: string[]) => {
    let result = ``
    for (let i = 0; i < valueArray.length; i++) {
        if (i > 1 ) {
            break;
        }
        if (i > 0) {
            result += ", "
        }
        result += `` + valueArray[i] + ``
    }
    if (valueArray.length > 2) {
        result += ", +" + (valueArray.length - 2) + " others"
    }
    result += ``
    // // console.log("result arr to string", result)

    // This is important, makes sure the tree saves and loads right
    result = result.replaceAll('\\"', `"`)
    result = result.replaceAll('"', `\\"`)
    return result
}

interface NodeThatKnowsItsParent {
    name: string
    attributes: {}
    children: NodeThatKnowsItsParent[]
    parent: NodeThatKnowsItsParent
}

// ConvertTreeFromRootNodeToString
export const ConvertTreeFromRootNodeToString = (theRootNode: RawNodeDatum) => {
    let resultString = ""
    let Client = theRootNode.attributes["client"].toString()
    if (theRootNode.children.length == 0){
        resultString = "All " +
            Client +
            " Data"
        return resultString
    }
    if (theRootNode.children.length > 0){
        resultString += "Using All " +
            Client +
            " Data\nWHERE"
    }
    // Okii lets track the amount of leaf nodes there are, to help track the amount of streams
    let leafNodes: NodeThatKnowsItsParent[] = GatherLeafNodesAndAddTheParentTrackToThem({
        name: theRootNode.name,
        attributes: theRootNode.attributes,
        parent: null,
        children: CopyChildrenAddParentField(theRootNode.children)
    }, {
        name: theRootNode.name,
        attributes: theRootNode.attributes,
        parent: null,
        children: CopyChildrenAddParentField(theRootNode.children)
    })
    // Okiii we have an array of the leaves, and they all lead back to the root through the parent field
    // so we go through the leaves follow em back up and print the stuff
    for (let i = 0; i < leafNodes.length; i++) {
        if (i > 0) {
            resultString += "\nOR"
        }
        resultString += "\n"
        resultString += "(" + FromLeafToRootWeMakeString(leafNodes[i], Client) + " \n)"
    }
    return resultString
}

// FromLeafToRootWeMakeString
export const FromLeafToRootWeMakeString = (currentNode: NodeThatKnowsItsParent, Client: string) => {
    let resultString = ""
    //  Case 1 : Leaf Node is Root Node
    if (currentNode.children != null && currentNode.children.length == 0 && currentNode.name == "BaseNode") {
        resultString = "All " +
            Client +
            " Data"
        return resultString
    }
    //  Case 1.5 : Leaf Node
    if (currentNode.children != null && currentNode.children.length == 0) {
        // This is a leaf node
        resultString = FieldOpValueStringBuilder(currentNode, Client) + resultString
        if (currentNode.parent.parent != null) {
            resultString = "\n\tAND" + resultString
        }
        resultString = FromLeafToRootWeMakeString(currentNode.parent, Client) + resultString
    }

    //  Case 2 : Root Node
    if (currentNode.parent == null) {
        // This is the root node
        // I guess we do nothing here for this one
    }
    //  Case 3 : One in the middle
    if (currentNode.children != null && currentNode.children.length > 0 && currentNode.parent != null) {
        // This one is in the middle
        resultString = FieldOpValueStringBuilder(currentNode, Client) + resultString
        if (currentNode.parent.parent != null) {
            resultString = "\n\tAND" + resultString
        }
        resultString = FromLeafToRootWeMakeString(currentNode.parent, Client) + resultString
    }
    return resultString
}

// FieldOpValueStringBuilder : Builds the string for the field value and operation thing
const FieldOpValueStringBuilder = (currentNode: NodeThatKnowsItsParent, Client: string) => {
    let resultString = ""
    if (currentNode.attributes["nodeType"] == "Filter Node") {
        if (currentNode.attributes["field"] != null) {
            resultString += "\n\t" + currentNode.attributes["field"]
        } else {
            resultString += " " + "<<Field>>"
        }
        if (currentNode.attributes["operation"] != null) {
            resultString += " " + currentNode.attributes["operation"]
        } else {
            resultString += " " + "<<Operation>>"
        }
        if (currentNode.attributes["value"] != null) {
            resultString += " " + currentNode.attributes["value"].replaceAll(SplitHerePattern, ', \n\t')
        }else {
            resultString += " " + "<<Value>>"
        }
    }

    // TODO date stuff
    if (currentNode.attributes["nodeType"] == "Limit Node") {
        if (currentNode.attributes["operation"] == "Date") {
            let tempArr = (currentNode.attributes["value"].substring(1, currentNode.attributes["value"].length-1)).split(SplitHerePattern)
            resultString += `\nPARSE_DATE("%Y-%m-%d", `+
                // `DateColumn`+
                currentNode.attributes["field"] +
                `) \n` +
                `BETWEEN ` +
                `PARSE_DATE("%Y-%m-%d", "` +
                tempArr[0] +
                // `2023-01-01` +
                `")\nAND ` +
                `PARSE_DATE("%Y-%m-%d", "`+
                tempArr[1] +
                // `2024-01-01`+
                `") \n` +
                currentNode.attributes["value"].replaceAll(SplitHerePattern, ', \n\t')

            return resultString
        }
        if (currentNode.attributes["field"] != null) {
            resultString += "\n\t" + currentNode.attributes["field"]
            if (currentNode.attributes["operation"] == "NOT TOP") {
                resultString += " NOT"
            }
            resultString += " IN ("
            resultString += "\n\t  SELECT DISTINCT " + currentNode.attributes["field"] + " FROM ( " +
                "\n\t    SELECT "  + currentNode.attributes["field"] + ", Val, "
            if (currentNode.attributes["percentage"] == "true") {
                resultString += "100*sum(Val)"
                // resultString += "100*percent_rank()"
            } else {
                resultString += "ROW_NUMBER()"
            }
            resultString += " over(order by Val desc)"
            if (currentNode.attributes["percentage"] == "true") {
                resultString += "/sum(Val) OVER()"
            }
            resultString += " prnk FROM (" +
                "\n\t      SELECT "  + currentNode.attributes["field"] + ", sum("
            if (currentNode.attributes["sortValue"] != null) {
                resultString += " " + currentNode.attributes["sortValue"]
            } else {
                resultString += " " + "<<Operation>>"
            }
            resultString += " ) Val FROM KEYSTONE_" +
                Client +
                ".SALES_VIEW"
        } else {
            resultString += "\n\t" + "<<Field>>" + " IN ( " +
                "\n\t  SELECT DISTINCT " + "<<Field>>" + " FROM ( " +
                "\n\t    SELECT "  + "<<Field>>" + ", Val, "
            if (currentNode.attributes["percentage"] == "true") {
                resultString += "100*sum(Val)"
                // resultString += "100*percent_rank()"
            } else {
                resultString += "ROW_NUMBER()"
            }
            resultString += " over(order by Val desc)"
            if (currentNode.attributes["percentage"] == "true") {
                resultString += "/sum(Val) OVER()"
            }
            resultString += " prnk FROM (" +
                "\n\t      SELECT "  + "<<Field>>" + ", sum("
            if (currentNode.attributes["sortValue"] != null) {
                resultString += " " + currentNode.attributes["sortValue"]
            } else {
                resultString += " " + "<<Sort Value>>"
            }
            resultString += " ) Val FROM KEYSTONE_" +
                Client +
                ".SALES_VIEW"
        }

        if (currentNode.parent.parent != null) {
            resultString += " WHERE " + FromLeafToRootWeMakeString(currentNode.parent, Client).replaceAll("\t", "\t\t")
        }

        resultString += "\n\t      GROUP BY " + currentNode.attributes["field"] + " "
        resultString += "\n\t      ORDER BY Val desc \n\t    )" +
            "\n\t  )" +
            "\n\tWHERE prnk "
        if (currentNode.attributes["operation"] == "BETWEEN(TOP)") {
            resultString += " BETWEEN "
        } else {
            resultString += "<=" +
                "  "
        }

        if (currentNode.attributes["value"] != null) {
            resultString += " " + currentNode.attributes["value"].replaceAll("[","").replaceAll("]","").replaceAll(SplitHerePattern, ' AND ') //.replaceAll(SplitHerePattern, ', ')
        } else {
            resultString += " " + "<<Value>>"
        }
        resultString += "\n\t)"


    }
    return resultString
}

// CopyChildren : Recursively Converts the children from a RawNodeDatum to my custom interface
const CopyChildrenAddParentField = (children) => {
    if (children == null) {
        return []
    }
    if (children.length < 1) {
        return []
    }
    let tempResults = children.map(cNode => {
        return {
            name: cNode.name,
            attributes: cNode.attributes,
            children: cNode.children != null ? CopyChildrenAddParentField(cNode.children) : [],
            parent: null
        }
    })
    return tempResults
}

// GatherLeafNodesAndAddTheParentTrackToThem : Recursively creates an array of leaf nodes that all lead back to the root node
export const GatherLeafNodesAndAddTheParentTrackToThem = (currentNode: NodeThatKnowsItsParent, parent: NodeThatKnowsItsParent) => {
    // Base Node has no parent
    if (currentNode.name == "BaseNode") {
        currentNode.parent = null
    } else {
        currentNode.parent = parent
    }
    if (currentNode.children.length == 0) {
        // this is a leaf
        return [currentNode]
    }
    let returnArray: NodeThatKnowsItsParent[] = []
    for (let i = 0; i < currentNode.children.length; i++) {
        // loop through the children, which will return the leaves in an array
        let tempArray = GatherLeafNodesAndAddTheParentTrackToThem(currentNode.children[i], currentNode)
        for (let j = 0; j < tempArray.length; j++) {
            returnArray.push(tempArray[j])
        }
    }
    return returnArray
}


// receives HPN returns RND
export const ConvertHPNtoRND = (hpn) => {
    //  Okii first up we need the root node. parent == null
    //  We can get the root node by going up the tree via the parent
    let tempNode = hpn
    while (tempNode.parent != null) {
        tempNode = tempNode.parent
    }
    // console.log("\t\t\t\tShould be root node: ", tempNode)
    //  once we have the root node, we can start copying the structure
    //  Do we go down the data or the children array, data is ND, children are HPN
    //  lets try going down the data, which means we should add the child to data as well
    let RND_Result = {
        name: tempNode.data["name"],
        attributes: {...tempNode.data["attributes"],
            tempID: tempNode.data.__rd3t != null ? tempNode.data.__rd3t.id : null
            // tempID: tempNode["attributes"].tempID == null ? tempNode.__rd3t.id : tempNode["attributes"].tempID
        },
        children: CopyChildren(tempNode.data["children"]),
        // __rd3t: {
        //     collapsed: tempNode.data.__rd3t != null ? tempNode.data.__rd3t.collapsed : false
        // }
    }

    return RND_Result
}


export const ConvertTreeFromValueLimitToFilterLimit = (cNode) => {
    //  Okii first up we need the root node. parent == null
    //  We can get the root node by going up the tree via the parent
    let tempNode = cNode
    while (tempNode.parent != null) {
        tempNode = tempNode.parent
    }
    // console.log("\t\t\t\tShould be root node: ", tempNode)
    //  once we have the root node, we can start copying the structure
    //  Do we go down the data or the children array, data is ND, children are HPN
    //  lets try going down the data, which means we should add the child to data as well
    let RND_Result = {
        name: tempNode["name"],
        attributes: {...tempNode["attributes"],
            tempID: tempNode.__rd3t != null ? tempNode.__rd3t.id : null
            // tempID: tempNode["attributes"].tempID == null ? tempNode.__rd3t.id : tempNode["attributes"].tempID
        },
        children: CopyChildren(tempNode["children"]),
        // __rd3t: {
        //     collapsed: tempNode.data.__rd3t != null ? tempNode.data.__rd3t.collapsed : false
        // }
    }

    return RND_Result
}

// CopyChildren : Copies and returns children recursively, removing the rd3t value, so it works for leaves
//                Only issue this causes, is that we don't track which of the nodes were collapsed, so when
//                adding or removing nodes, everything becomes not collapsed
export const CopyChildren = (children) => {
    if (children == null) {
        return []
    }
    if (children.length < 1) {
        return []
    }
    for (let i = 0; i < children.length; i++) {
        if (children[i].attributes["nodeType"] == "LimitNode") {
            children[i].attributes["nodeType"] = "Limit Node"
        }
        if (children[i].attributes["nodeType"] == "ValueNode") {
            children[i].attributes["nodeType"] = "Filter Node"
        }
    }
    let tempResults = children.map(cNode => {
        return {
            name: cNode.name,
            attributes: {...cNode["attributes"],
                tempID: cNode.__rd3t != null ? cNode.__rd3t.id : null // This temp ID is used to keep track of the selected node,
                // tempID: cNode["attributes"].tempID == null ? cNode.__rd3t.id : cNode["attributes"].tempID
            },
            children: cNode.children != null ? CopyChildren(cNode.children) : [],
            // __rd3t: {
            //     collapsed: cNode.__rd3t != null ? cNode.__rd3t.collapsed : false
            // }
        }
    })
    return tempResults
}

// TreeFromAPI : this is the structure fetched from the API
export interface TreeFromAPI {
    ID: string,
    Name: string,
    User_ID: string,
    Org_Level: boolean,
    Tree: string
}

// TreeForUseByPSU : This is the format for to be used, basically just unmarshall the tree
export interface TreeForUseByPSU {
    ID: string,
    Name: string,
    User_ID: string,
    Org_Level: boolean,
    Tree: RawNodeDatum
}

export async function ConvertTreeFromApiForUse (ApiTree: TreeFromAPI) {
    let tempStringTree = ApiTree.Tree
    const tempTree = await JSON.parse(tempStringTree)
    let TempTreeBeingUsed: TreeForUseByPSU = {
        ID: ApiTree.ID,
        Name: ApiTree.Name,
        Org_Level: ApiTree.Org_Level,
        Tree: tempTree,
        // Tree: JSON.parse(ApiTree.Tree),
        User_ID: ApiTree.User_ID
    }
    return TempTreeBeingUsed
}

export const ConvertTreeFromBeingUsedToApiSave = (usedTree: TreeForUseByPSU) => {
    if (usedTree == null) {
        return {
            ID: "",
            Name: "",
            Org_Level: false,
            Tree: "",
            User_ID: ""
        }
    }
    let PretendFromApi: TreeFromAPI = {
        ID: usedTree.ID,
        Name: usedTree.Name,
        Org_Level: usedTree.Org_Level,
        Tree: JSON.stringify(usedTree.Tree),
        User_ID: usedTree.User_ID
    }
    return PretendFromApi
}

export interface PSUFilterValue {
    Header: string
    Values: string[]
}

export const ConvertNodeToEnglish = (nodeDatum: RawNodeDatum): string => {
    let resultString = ""

    if (nodeDatum.attributes["nodeType"] != null && nodeDatum.attributes["nodeType"] == "Filter Node") {
        // field
        if (nodeDatum.attributes["field"] != null) {
            resultString += nodeDatum.attributes["field"]
        } else {
            resultString += ""
        }
        resultString +="\n"
        // operation
        if (nodeDatum.attributes["operation"] != null) {
            resultString += nodeDatum.attributes["operation"]
        } else {
            resultString += ""
        }
        resultString +="\n("
        // value
        if (nodeDatum.attributes["value"] != null) {
            resultString += PSUArrayToStringValueButPretty(PSUValueStringToArray((nodeDatum.attributes["value"]).toString()))
        } else {
            resultString += ""
        }
        resultString += ")"
    }

    if (nodeDatum.attributes["nodeType"] != null && nodeDatum.attributes["nodeType"] == "Limit Node") {
        // todo Date
        if (nodeDatum.attributes["operation"] != null && nodeDatum.attributes["operation"] == "Date") {
            resultString += "Between\n"
            resultString += (nodeDatum.attributes["value"]).toString().replaceAll("[","").replaceAll("]","").replaceAll(SplitHerePattern, (nodeDatum.attributes["percentage"] == "true" ? "% ":"") + ' AND ') + (nodeDatum.attributes["percentage"] == "true" ? "% ":"")
            // resultString +=
        }
        // todo not Date
        if (nodeDatum.attributes["operation"] != null && nodeDatum.attributes["operation"] != "Date") {
            // sortValue
            if (nodeDatum.attributes["operation"] != null) {
                resultString += nodeDatum.attributes["operation"] + " "
            } else {
                resultString += ""
            }
            // value
            if (nodeDatum.attributes["value"] != null) {
                resultString += (nodeDatum.attributes["value"]).toString().replaceAll("[","").replaceAll("]","").replaceAll(SplitHerePattern, (nodeDatum.attributes["percentage"] == "true" ? "% ":"") + ' AND ') + (nodeDatum.attributes["percentage"] == "true" ? "% ":"")
            } else {
                resultString += ""
            }
            resultString +=" ("
            // sortValue
            if (nodeDatum.attributes["sortValue"] != null) {
                resultString += nodeDatum.attributes["sortValue"]
            } else {
                resultString += ""
            }
            resultString +=")\n of "
            // field
            if (nodeDatum.attributes["field"] != null) {
                resultString += nodeDatum.attributes["field"]
            } else {
                resultString += ""
            }
        }
    }

    return resultString
}