import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import {Alert, Box, Grid, Paper} from '@mui/material';
// import {useCustomThemeContext} from "../util/Context";
import {ApexOptions} from "apexcharts";
import Chart from "react-apexcharts";
import {useTheme} from "@mui/material/styles";
import currencyFormatter from 'src/utils/currencyFormatter';
import { useMainContext } from 'src/contexts/MainContext';

// const ColourPickerPreviewApexCharts: FC = () => {
interface BarGraphStatProp{
    Series :any[]
    
}

const BarGraphStat: FC<BarGraphStatProp> = (props) => {
     
    const chartType = 'bar'
    const mainCtx = useMainContext()


    const chartOptions: (ApexOptions) = {
        chart: {
            type: "bar",
            stacked: false,
            stackType: '100%',
            offsetX: -30,
            offsetY: -20,
            background: '#FFFFFF'
        },
        markers: {
            colors: ['fff']
        },
        dataLabels: {
            formatter: function (val) {
                return currencyFormatter(mainCtx.activeOrganization.name, val.toString())
            }
        },

        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent']
            },
            // column: {
            //     colors: ['#9C27B0']
            // },
            padding: {
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: '99%',
                dataLabels: { 
                    
                    
                },
            }
        },
        // plotOptions: {
        //     bar: {
        //         horizontal: false,
        //         columnWidth: '100%',
        //
        //         // endingShape: 'rounded'
        //     }
        // },
        xaxis: {
            labels: {
                formatter: function (val) {
                    return val
                },
                rotateAlways: true,
                show: false,
                rotate: -45
            },
            categories: props.Series.map(value => value.name)

        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    //return val.toString()
                    return currencyFormatter(mainCtx.activeOrganization.name, val.toString())
                }
            },
        },
        theme: {
            mode: useTheme().palette.mode
        },
        legend: {
            show: true,
            position: 'top',
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    //return val.toString()
                    return currencyFormatter(mainCtx.activeOrganization.name, val.toString())
                }
            }
        }
    };

    return (

        (props.Series).length > 0 ?
            <Box
                sx={{
                    background: 'fff',
                    width: '100%',
                    height: '100%',
                    minHeight: {md:'400px', xs: '300'},
                    p: 2,
                    borderRadius: 1,
                    margin: 1,
                }}
            >
                    <Chart
                        type={chartType}
                        width={"100%"}
                        height={"150%"}

                        series={props.Series}
                        options={chartOptions}
                    />

            </Box> : <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center"}}>No Data Available for Selected Period</Alert>
    );
}

export default BarGraphStat;