import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {Box, Button, ButtonGroup, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";

interface ConfirmActionModalProps {
    confirmAction: () => void;
    cancelAction: () => void;
    confirmationWarningMessage: string
}

const ConfirmActionDialogue: FC<ConfirmActionModalProps> = (props) => {
    return <Box sx={{
        m: 2
    }}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
            <Grid container display={"grid"} gridTemplateColumns={"1fr"}
                  gridAutoFlow={"row"} gridAutoRows={"auto"} gap={1} >
                <Box>
                    {props.confirmationWarningMessage}
                </Box>
                <Box>Do you wish to continue?</Box>
            </Grid>
        </DialogContent>
        <DialogActions>
            <ButtonGroup variant={"outlined"} >
                <Button
                    sx={{

                    }}
                    onClick={() => {
                        // Confirm
                        props.confirmAction()
                    }}
                >
                    Confirm
                </Button>

                <Button
                    sx={{
                        // marginLeft: 2
                    }}
                    onClick={() => {
                    // Cancel
                    props.cancelAction()
                }}>
                    Cancel
                </Button>
            </ButtonGroup>
        </DialogActions>
    </Box>
}

export default ConfirmActionDialogue;