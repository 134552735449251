import type { FC } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useTheme} from "@mui/material/styles";

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
      // For custom scrollbar on site
      // "&::-webkit-scrollbar": {
      //   width: 10
      // },
      // "&::-webkit-scrollbar-track": {
      //   backgroundColor: useTheme().palette.background.paper,
      // },
      // "&::-webkit-scrollbar-thumb": {
      //   backgroundColor: useTheme().palette.text.secondary,
      //   borderRadius: 2,
      // }
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

const GlobalStyles: FC = () => {

  // @ts-ignore
  useStyles();
  return null;
};

export default GlobalStyles;
