import {ColumnMapDict} from "../../../../../cog/keystone/filter/Filter";
import {PSUFilterValue} from "../../productstoreuniverse/utility/NodeTypesAndOptions";

// ColumnMappingDictionaryWithData : Class containing data relevant to the Exclude Filter.
// Has some helper functions to return the object as JSON and to manipulate data more conveniently
export class ColumnMappingDictionaryWithData {
    public Overall_Hierarchy_1: string = '';
    public Overall_Hierarchy_1_Data: string[] = [];
    public Overall_Hierarchy_2: string = '';
    public Overall_Hierarchy_2_Data: string[] = [];
    public Product_Hierarchy_1: string = '';
    public Product_Hierarchy_1_Data: string[] = [];
    public Product_Hierarchy_2: string = '';
    public Product_Hierarchy_2_Data: string[] = [];
    public Product_Hierarchy_3: string = '';
    public Product_Hierarchy_3_Data: string[] = [];
    public Product_Hierarchy_4: string = '';
    public Product_Hierarchy_4_Data: string[] = [];
    public Product: string = '';
    public Product_Data: string[] = [];
    public Product_Code: string = '';
    public Product_Code_Data: string[] = [];
    public Customer_Hierarchy_1: string = '';
    public Customer_Hierarchy_1_Data: string[] = [];
    public Customer_Hierarchy_2: string = '';
    public Customer_Hierarchy_2_Data: string[] = [];
    public Customer_Hierarchy_3: string = '';
    public Customer_Hierarchy_3_Data: string[] = [];
    public Area_Hierarchy_1: string = '';
    public Area_Hierarchy_1_Data: string[] = [];
    public Area_Hierarchy_2: string = '';
    public Area_Hierarchy_2_Data: string[] = [];
    // Area_Hierarchy_3 is actually store
    public Area_Hierarchy_3: string = '';
    public Area_Hierarchy_3_Data: string[] = [];
    public Area_Hierarchy_3_Code: string = '';
    public Area_Hierarchy_3_Code_Data: string[] = [];

    // public Value: string = '';
    // public Value_Data: string[] = []


    // ValueList : This is basically the names of all the values without the _Data ending
    //             This makes it easier to add and remove fields, by only needing to add the field to this here class,
    //             and add the name of that field to this list
    //             Can then refer to the fields like this:
    //                 this[this.ValueList[i]] or this[this.ValueList[i]+"_Data"]
    public ValueList = [
        "Overall_Hierarchy_1",
        "Overall_Hierarchy_2",
        "Product_Hierarchy_1",
        "Product_Hierarchy_2",
        "Product_Hierarchy_3",
        "Product_Hierarchy_4",
        "Product",
        "Product_Code",
        "Customer_Hierarchy_1",
        "Customer_Hierarchy_2",
        "Customer_Hierarchy_3",
        "Area_Hierarchy_1",
        "Area_Hierarchy_2",
        "Area_Hierarchy_3",
        "Area_Hierarchy_3_Code",
        // "Value"
    ]

    public GetFieldsArray():string[] {
        let result: string[] = []
        for (let i = 0; i < this.ValueList.length; i++) {
            if (this[this.ValueList[i]] != "") {
                result.push(this[this.ValueList[i]])
            }
        }
        return result
    }

    public GetValuesForField(Field: string): string[] {
        for (let i = 0; i < this.ValueList.length; i++) {
            if (this[this.ValueList[i]] == Field) {
                return this[this.ValueList[i] + "_Data"]
            }
        }
        return []
    }

    // Overall_Hierarchy_1
    // Overall_Hierarchy_2
    // Product_Hierarchy_1
    // Product_Hierarchy_2
    // Product_Hierarchy_3
    // Product_Hierarchy_4
    // Product
    // Product_Code
    // Customer_Hierarchy_1
    // Customer_Hierarchy_2
    // Customer_Hierarchy_3
    // Area_Hierarchy_1
    // Area_Hierarchy_2
    // Area_Hierarchy_3
    // Area_Hierarchy_3_Code

    constructor(colMap?: ColumnMappingDictionaryWithData) {
        if (!colMap) {
            return;
        }
        this.Overall_Hierarchy_1 = colMap.Overall_Hierarchy_1;
        this.Overall_Hierarchy_1_Data = colMap.Overall_Hierarchy_1_Data
        this.Overall_Hierarchy_2 = colMap.Overall_Hierarchy_2;
        this.Overall_Hierarchy_2_Data = colMap.Overall_Hierarchy_2_Data;
        this.Product_Hierarchy_1 = colMap.Product_Hierarchy_1;
        this.Product_Hierarchy_1_Data = colMap.Product_Hierarchy_1_Data;
        this.Product_Hierarchy_2 = colMap.Product_Hierarchy_2;
        this.Product_Hierarchy_2_Data = colMap.Product_Hierarchy_2_Data;
        this.Product_Hierarchy_3 = colMap.Product_Hierarchy_3;
        this.Product_Hierarchy_3_Data = colMap.Product_Hierarchy_3_Data;
        this.Product_Hierarchy_4 = colMap.Product_Hierarchy_4;
        this.Product_Hierarchy_4_Data = colMap.Product_Hierarchy_4_Data;
        this.Product = colMap.Product;
        this.Product_Code = colMap.Product;
        this.Product_Data = colMap.Product_Data;
        this.Product_Code_Data = colMap.Product_Code_Data;
        this.Customer_Hierarchy_1 = colMap.Customer_Hierarchy_1;
        this.Customer_Hierarchy_1_Data = colMap.Customer_Hierarchy_1_Data;
        this.Customer_Hierarchy_2 = colMap.Customer_Hierarchy_2;
        this.Customer_Hierarchy_2_Data = colMap.Customer_Hierarchy_2_Data;
        this.Customer_Hierarchy_3 = colMap.Customer_Hierarchy_3;
        this.Customer_Hierarchy_3_Data = colMap.Customer_Hierarchy_3_Data;
        this.Area_Hierarchy_1 = colMap.Area_Hierarchy_1;
        this.Area_Hierarchy_1_Data = colMap.Area_Hierarchy_1_Data;
        this.Area_Hierarchy_2 = colMap.Area_Hierarchy_2;
        this.Area_Hierarchy_2_Data = colMap.Area_Hierarchy_2_Data;
        this.Area_Hierarchy_3 = colMap.Area_Hierarchy_3;
        this.Area_Hierarchy_3_Data = colMap.Area_Hierarchy_3_Data;
        this.Area_Hierarchy_3_Code = colMap.Area_Hierarchy_3_Code;
        this.Area_Hierarchy_3_Code_Data = colMap.Area_Hierarchy_3_Code_Data;

        // this.Value = colMap.Value;
        // this.Value_Data = colMap.Value_Data;
    }

    // PopulateWithColMap : Give me the colMap variable in keystoneContext,
    // I empty data and populate names appropriately
    public PopulateWithColMap(colMap: ColumnMapDict) {
        for (let i = 0; i < this.ValueList.length; i++) {
            this[this.ValueList[i]] = colMap[this.ValueList[i]]
            this[this.ValueList[i]+"_Data"] = []
        }
    };

    public ClearData() {
        for (let i = 0; i < this.ValueList.length; i++) {
            this[this.ValueList[i]+"_Data"] = []
        }
    }

    // CleanWithBoolRecord : Pass a corresponding Record<string, boolean> to remove data where false.
    // Basically just for use by the excludeModal when excluding a single opportunity.
    // That page uses checkboxes and so the disabled values gets emptied here
    public CleanWithBoolRecord(checked : Record<string, boolean>) {
        for (let i = 0; i < this.ValueList.length; i++) {
            if (checked[this[this.ValueList[i]]] != null) {
                if (checked[this[this.ValueList[i]]] == false) {
                    this[this.ValueList[i]+"_Data"] = []
                }
            }
        }
    }

    // PopulateWithFakeData : for testing things
    public PopulateWithFakeData() {
        // this.Overall_Hierarchy_1 = colMap.Overall_Hierarchy_1;
        this.Overall_Hierarchy_1_Data = ["testOne", "testTwo"]
        // this.Overall_Hierarchy_2 = colMap.Overall_Hierarchy_2;
        this.Overall_Hierarchy_2_Data = ["Another", "test"]
        // this.Product_Hierarchy_1 = colMap.Product_Hierarchy_1;
        this.Product_Hierarchy_1_Data = ["Product Test"]
        // this.Product_Hierarchy_2 = colMap.Product_Hierarchy_2;
        this.Product_Hierarchy_2_Data = ["Product Two Test"]
        // this.Product_Hierarchy_3 = colMap.Product_Hierarchy_3;
        this.Product_Hierarchy_3_Data = []
        // this.Product_Hierarchy_4 = colMap.Product_Hierarchy_4;
        this.Product_Hierarchy_4_Data = []
        // this.Product = colMap.Product;
        this.Product_Data = ["Product"]
        // this.Customer_Hierarchy_1 = colMap.Customer_Hierarchy_1;
        this.Customer_Hierarchy_1_Data = ["Cust Hierarchy 1 test"]
        // this.Customer_Hierarchy_2 = colMap.Customer_Hierarchy_2;
        this.Customer_Hierarchy_2_Data = []
        // this.Customer_Hierarchy_3 = colMap.Customer_Hierarchy_3;
        this.Customer_Hierarchy_3_Data = []
        // this.Area_Hierarchy_1 = colMap.Area_Hierarchy_1;
        this.Area_Hierarchy_1_Data = []
        // this.Area_Hierarchy_2 = colMap.Area_Hierarchy_2;
        this.Area_Hierarchy_2_Data = []
        // this.Area_Hierarchy_3 = colMap.Area_Hierarchy_3;
        this.Area_Hierarchy_3_Data = []
    };

    // PopulateWithOppRows : Point of this one is to populate with options for use by something else.
    // basically as a reference for a different one of these.
    // The way it works, you pass it an array of cards from the MyRows variable in KeystoneContext,
    // and it fills up the relevant data points. Assuming that PopulateWithColMap has already been used correctly.
    // Also, could probably do this less hardcoded, with some generic helper functions, next time.
    public PopulateWithOppRows(rows: any[]) {
        let dupeChecker = false

        for (let i = 0; i < rows.length; i++) {

            for (let vl = 0; vl < this.ValueList.length; vl++) {
                if (rows[i][this[this.ValueList[vl]]] != null) {
                    dupeChecker = false
                    for (let j = 0; j < this[this.ValueList[vl]+"_Data"].length; j++) {
                        if (rows[i][this[this.ValueList[vl]]] == this[this.ValueList[vl]+"_Data"][j]){
                            dupeChecker = true
                        }
                    }
                    if (dupeChecker == false) {
                        this[this.ValueList[vl]+"_Data"].push(rows[i][this[this.ValueList[vl]]])
                    }
                }
            }
        }
    }

    // gimmeJsonHelper : Helps the gimmeJson function not have duplicate code
    private gimmeJsonHelper(header: string, data: string[], currResult: string) : string {
        let result = ""
        if (header != "") {
            if (data.length > 0) {
                if (currResult != "{") {
                    result += ", "
                }
                result+= `"` + header + `" : [`
                for (let i = 0; i < data.length; i++) {
                    if (i > 0) {
                        result += ", "
                    }
                    result += `"` + data[i] + `"`
                }
                result += "]"
            }
        }
        return result
    }

    // GimmeJsonString : Returns object values as a Json string
    // Could probably do this less hard coded, but this way guarantees an order,
    // which might help for checking for duplicates later
    public GimmeJsonString(): string {
        let result = "{"

        for (let vl = 0; vl < this.ValueList.length; vl++) {
            result += this.gimmeJsonHelper(this[this.ValueList[vl]], this[this.ValueList[vl]+"_Data"], result)
        }
        result += "}"
        return result
    }

    // PopulateWithPSUFilterValues : Receives a PSUFilterValue array, and populates this bad boi, assuming headers are set
    public PopulateWithPSUFilterValues(filter: PSUFilterValue[]) {
        for (let i = 0; i < filter.length; i++) {
            for (let vl = 0; vl < this.ValueList.length; vl++) {
                if (this[this.ValueList[vl]] == filter[i].Header) {
                    this[this.ValueList[vl]+"_Data"] = filter[i].Values
                }
            }
        }
    }

    // PopulateWithString : Pass in a string exclude filter, populate the appropriate values
    public PopulateWithString(filter: string) {
        // console.log("==============================================================================")
        // console.log("this", this)
        // console.log("filter", filter)
        this.ClearData()
        let tempFilter = JSON.parse(filter)

        for (let vl = 0; vl < this.ValueList.length; vl++) {
            if (tempFilter[this[this.ValueList[vl]]] != null) {
                this[this.ValueList[vl]+"_Data"] = tempFilter[this[this.ValueList[vl]]]
            }
        }
        // console.log("tempFilter", tempFilter)
        // console.log("this", this)
        // console.log("==============================================================================")
    }

    // PopulateWithLevelFilter : Pass levelilterString, and populate data
    public PopulateWithLevelFilter(levelFilter : string) {
        // console.log("--------------------------------------------------------------------------------------------------")
        // console.log("this", this)
        // console.log("filter", levelFilter)
        this.ClearData()
        let tempFilter = JSON.parse(levelFilter)

        for (let vl = 0; vl < this.ValueList.length; vl++) {
            if (tempFilter[this[this.ValueList[vl]]] != null) {
                this[this.ValueList[vl]+"_Data"][0] = tempFilter[this[this.ValueList[vl]]]
            }
        }
        // console.log("tempFilter", tempFilter)
        // console.log("this", this)
        // console.log("--------------------------------------------------------------------------------------------------")
    }

    // helpPrettyPrint : Just helps the prettyPrint so there's less duplicate code
    private helpPrettyPrint(header: string, data: string[]): string {
        let result = ""
        if (header != "" && data.length > 0) {
            result += header + " : "
            for (let i = 0; i < data.length; i++) {
                if (i > 0) {
                    result += ", "
                }
                result += data[i]
            }
            result += "\n"
        }
        return result
    }

    // PrettyPrint : Returns a string of this object, but it looks nicer than JSON does.
    //               Just gotta wrap it in a box with the sx property whiteSpace: "pre-wrap"
    //               Could do it more dynamically, but this way guarantees order of the headings and tings
    //               Although maybe should sort the data under each heading
    public PrettyPrint(): string {
        let result: string = ""

        for (let vl = 0; vl < this.ValueList.length; vl++) {
            result += this.helpPrettyPrint(this[this.ValueList[vl]], this[this.ValueList[vl]+"_Data"])
        }
        result = result.trim()
        return result
    }
}