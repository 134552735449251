import type { FC } from 'react';
import * as React from "react";
import {
  Box,
  Tooltip,
  Slider,
  SliderValueLabelProps,
  styled,
  TooltipProps, tooltipClasses
} from "@mui/material";
import currencySwitcher from "../../../../../../utils/currencySwitcher";
import { useEffect, useState } from "react";
import wait from "../../../../../../utils/wait";

interface StatsBarProgressProps {
  actual: number;
  target: number;
  org: string;
}

const StatsBarProgress: FC<StatsBarProgressProps> = ({actual, target, org}) => {
  const percentage = Math.round(actual * 100 / target);
  let formatter = Intl.NumberFormat('en', {notation: 'compact'})
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  let color = ''
  if ( percentage >= 80) {
    color = '#00e396'
  } else if ( percentage < 80 && percentage >= 50 ) {
    color = '#feb019'
  } else {
    color = '#ff4560'
  }

  const marks = [
    {
      value: 0,
      label: currencySwitcher(org) + formatter.format(0),
    },
    {
      value: 50,
      label: currencySwitcher(org) + formatter.format(target * 0.5),
    },
    {
      value: 100,
      label: currencySwitcher(org) + formatter.format(target),
    },
  ];

  useEffect(() => {
    wait(2000)
    setTooltipOpen(true)
  }, [])

  return (
    <Box sx={{ height: 170, width: 400, alignItems: "center", justifyContent: "center"}}>
      <Slider
        sx={{
          width: "80%",
          color: color,
          marginLeft: 4,
          marginTop: 10,
          '& .MuiSlider-markLabel': {
            fontSize: 20
          },
          '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            fontWeight: 700,
            background: 'unset',
            padding: 0,
            width: 60,
            height: 60,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: color,
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
              transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
              transform: 'rotate(45deg)',
            },
          },
        }}
        defaultValue={percentage}
        valueLabelDisplay="on"
        valueLabelFormat={currencySwitcher(org) + formatter.format(actual)}
        marks={marks}
      />
    </Box>
  );
}

export default StatsBarProgress