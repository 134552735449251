import React, {FC, useLayoutEffect} from "react";
import {Box} from "@mui/system";
import {Grow, LinearProgress, Slide} from "@mui/material";
import {User} from "../../../../cog/user";
import {CommentsStore, Comment} from "../../../../cog/keystone/comments";
import CommentCard from "./CommentCard";
import Comments from "./Comments";
import {Member} from "../../../../cog/keystone/member";
import {useTheme} from "@mui/material/styles";

interface CommentCardsProps {
    cardID: string
    displayLimit?: number | null
    updated?: number,
    user: User,
    orgMembers: Member[],
    updateHistory: () => void
}

const Loader: FC = () => {
    return <LinearProgress sx={{
        // backgroundColor: "#d6ddde",
        padding: 1,
        border: 1,
        width: "98%",
        borderColor: "#ededed",
        margin: 1,
        borderRadius: useTheme().shape.borderRadius
    }}/>
}

interface CommentBoxProps {
    com?: Comment,
    index?: number,
    user: Member
}

const CommentBox: FC<CommentBoxProps> = ({com, index, user}) => {

    return (
        <Slide key={"animation_"+com.id} direction="up" in={true} timeout={(index / 2 % 10) * 200}>
            <Box key={com.id} display="flex" flexDirection="row" sx={{
                backgroundColor: (index % 2) ? useTheme().palette.background.default : useTheme().palette.background.paper,
                padding: 1,
                border: 1,
                width: "98%",
                borderColor: useTheme().palette.divider,
                margin: 1,
                borderRadius: useTheme().shape.borderRadius
            }}>
                <CommentCard id={com.id} user={user} itemId={com.item_id} content={com.content}
                             timestamp={com.time_stamp}/>
            </Box>
        </Slide>
    )
}


interface EmptyCommentBoxProps {
}

const EmptyCommentBox: FC<EmptyCommentBoxProps> = () => {
    return (
        <Slide direction="up" timeout={1000} in={true} easing={'true'}>
            <Box key={"empty"} display="flex" flexDirection="row" sx={{
                // backgroundColor: (0 % 2) ? "#d6ddde" : "#ededed",
                padding: 1,
                border: 1,
                width: "98%",
                borderColor: "#ededed",
                mt: 0,
                mb: 0,
                ml: 2,
                mr: 1,
                borderRadius: 1,
                fontSize: {
                    lg: '1rem',
                    md: '1rem',
                    sm: '0.75rem',
                    xs: '0.70rem'
                }
            }}>
                No comments yet...
            </Box>
        </Slide>
    )
}

const CommentCards: FC<CommentCardsProps> = ((props) => {

    // const ctx = useMainContext();

    const [comments, setComments] = React.useState<Comment[]>(null);
    const [updated, setUpdated] = React.useState<number>(0)

    const onClose = () => {
        updateComments()
    }

    const updateComments = () => {
        setUpdated(Math.random() * (1000))
    }

    useLayoutEffect(() => {
        CommentsStore.FindMany({item_id: props.cardID}).then((r) => {
            setComments(null) // i do this first so that it animates again
            setComments(r.comments)
            props.updateHistory()
        })
    }, [updated])

    return (
        <Box mt={1} mb={1} display="flex" justifyContent="flex-left" flexDirection="column" alignItems="flex-start" width={"100%"} maxHeight={'80vh'} >
            <Box mt={2} display="flex" justifyContent="flex-left" flexDirection="row" alignItems="flex-start"
                 width={"100%"} maxHeight={'70vh'} style={{
                overflow: "hidden",
                overflowY: "auto"
            }}>
                <Box width="100%" mb={2}>
                    {
                        comments ?
                            (
                                comments.length == 0 ? <EmptyCommentBox/>
                                    : comments.slice((comments.length < props.displayLimit ? 0 : comments.length - props.displayLimit),
                                        (comments.length < props.displayLimit ? 0 : comments.length)).map((com, index) =>
                                        <CommentBox
                                            key={"CommentBox_" + com.id}
                                            com={com}
                                            index={index}
                                            user={props.orgMembers.find(x => x.id == com.user_id)}/>)
                            )
                            : <Loader/>
                    }
                </Box>
            </Box>
            <Comments onClose={() => onClose()} issueID={props.cardID} user={props.user} orgMembers={props.orgMembers}/>
        </Box>
    );
})

export default CommentCards;