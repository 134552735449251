import type { FC } from 'react';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  selectedDate: {
    "& .MuiPickersDay-root": {
      "&.Mui-selected": {
        backgroundColor: useTheme().palette.primary.main,
        color: '#fff',
      },
    },
    "& .MuiDateRangePickerDay-dayInsideRangeInterval": {
      // backgroundColor: useTheme().palette.primary.main,
      color: '#fff',
    },
    "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
      backgroundColor: useTheme().palette.primary.main,
      color: '#fff',
    },
    "& .MuiSvgIcon-root": {
      color: useTheme().palette.mode === 'dark' ? "#f2bc53" : '#6b778c',
    },
  },
}));

interface LandingPageDateSelectorProps {
  dateValue: DateRange<Date>;
  setDateValue: (dates: DateRange<Date>) => void;
}

const OverviewDateSelector: FC<LandingPageDateSelectorProps> = ({dateValue, setDateValue}) => {
  const classes = useStyles();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: 'Start Date', end: 'End Date' }}
    >
      <StaticDateRangePicker
        className={classes.selectedDate}
        displayStaticWrapperAs="desktop"
        value={dateValue}
        onChange={(newValue) => {
          let dateStart: Date
          let dateEnd: Date
          if (newValue[0] != null) {
            dateStart = new Date(newValue[0])
            const offset1 = dateStart.getTimezoneOffset()
            dateStart = new Date(dateStart.getTime() - (offset1*60*1000))
          } else {
            dateStart = null
          }
          if (newValue[1] != null) {
            dateEnd = new Date(newValue[1])
            const offset2 = dateEnd.getTimezoneOffset()
            dateEnd = new Date(dateEnd.getTime() - (offset2*60*1000))
          } else {
            dateEnd = null
          }
          if (dateStart != null && dateEnd != null) {
            setDateValue([dateStart, dateEnd]);
          }
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </React.Fragment>
        )}
        // @ts-ignore
      />
    </LocalizationProvider>
  );
}

export default OverviewDateSelector;