/* eslint-disable */

export default class GlobalVariable {
  public id: string = '';
  public name: string = '';
  public value: string = '';
  public organizationId: string = '';

  constructor(globalVariable?: GlobalVariable) {
    if (!globalVariable) {
      return;
    }
    this.id = globalVariable.id;
    this.name = globalVariable.name;
    this.value = globalVariable.value;
    this.organizationId = globalVariable.organizationId;
  }
}
