/* eslint-disable @typescript-eslint/no-use-before-define */
import { FC, useCallback, useEffect, useState } from "react";
import {
    Autocomplete,
    TextField,
    Box,
    Typography,
    Popper,
    ButtonGroup,
    Button,
    Paper,
    Fade
} from "@mui/material";
import {useDispatch} from "react-redux";
import equal from "fast-deep-equal";
import BasicWidget from "../BasicWidget";
import slice, {widgetChangeVariable} from "../../../../../../../slices/bitool";
import {WidgetProps} from "../../../WidgetSwitcher";
import _ from "lodash";

const intersection = (newWidgetData: any, currentOutput: any): any => {
    if (currentOutput == null) return null
    if (newWidgetData) {
        if (!(Object.keys(newWidgetData) && Object.keys(currentOutput) &&
            (Object.keys(newWidgetData)[0] == Object.keys(currentOutput)[0]))) return null
        const intersect = _.intersection(newWidgetData[Object.keys(newWidgetData)[0]], currentOutput[Object.keys(currentOutput)[0]])
        if (intersect) {
            return {[Object.keys(currentOutput)[0]]: intersect}
        }
    }
    else return null
}

const AutoCompleteFilterInst: FC<WidgetProps> = ({widget, widgetData, widgetVariables, setVariableOutputs}) => {

    const multiple = !!(widget.arguments.templates.Multiple && widget.arguments.templates.Multiple.value)
    const intersectVariables = !!(widget.arguments.templates.Intersection &&
        widget.arguments.templates.Intersection.value)
    let defaultValue = null
    let selectAll = false
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    let url = window.location.pathname.toString()

    useEffect(() => {
        let newOutputData: any = null
        if (intersectVariables) {
            if (widgetData && ((Array.isArray(widgetData) && widgetData.length > 0) || (Object.keys(widgetData)))) {
                newOutputData = intersection(widgetData, widgetVariables)
                if (newOutputData) {
                    if (!equal(widgetVariables, newOutputData)) {
                        dispatch(slice.actions.widgetChangeVariable({id: widget.id, data: newOutputData}));
                    }
                }
            }
        }
        if (!widgetData && widgetVariables) {
            dispatch(slice.actions.widgetChangeVariable({id: widget.id, data: null}));
        }
    }, [widgetData])

    const setOutputDataCallback = useCallback(async (value) => {
        if (widgetData && Object.keys(widgetData).length) {
            let k: Record<string, any> = {}

            if (value != null) {
                k[Object.keys(widgetData)[0]] = multiple ? value : [value]
            } else {
                k[Object.keys(widgetData)[0]] = multiple ? [""] : ""
            }

            dispatch(widgetChangeVariable(widget.id,  k));
        }
    }, [widgetData, widget])

    const code = widget.arguments.code ? unescape(widget.arguments.code) : null
    try {
        eval(code);
    } catch (e) {
    }

    const widgetOutputToAutocompleteValue = (): string[] => {
        if (widgetVariables && Array.isArray(widgetVariables) && !multiple) {
            return widgetVariables[0]
        } else if (widgetVariables && Object.keys(widgetVariables).length) {
            if (Object.keys(widgetVariables).length > 1) return []
            return  widgetVariables[Object.keys(widgetVariables)[0]]
        } else if (defaultValue != null) {
            setOutputDataCallback(defaultValue).then(r => null)
            return defaultValue
        } else if (selectAll == true) {
            let options = widgetDataToOptions(widgetData)
            setOutputDataCallback(options).then(r => null)
            return options
        } else {
            if (multiple) return []
            return null
        }
    };

    const widgetDataToOptions = (widgetData: any): string[] => {
        if (widgetData && Object.keys(widgetData).length > 0) {
            return widgetData[Object.keys(widgetData)[0]]
        } else {
            return []
        }
    };

    const autoCompleteFilterPopper = function (props) {
        const selectAll = () => {
            console.log("Select All")
        }

        return (
          <Popper {...props}>
             <ButtonGroup
               color="primary"
               aria-label="outlined primary button group"
             >
                 <Button
                   color="primary"
                   variant="contained"
                   sx={{mt: 2}}
                   onClick={() => {
                       console.log("Select All")
                   }}
                 >
                     Select All
                 </Button>
             </ButtonGroup>
              {props.children}
          </Popper>
        );
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    return (
        <div
          // style={{
          //   width: '100%',
          //   height: '100%'
          // }}
        >
            <Autocomplete
                // PopperComponent={autoCompleteFilterPopper}
                multiple={multiple}
                limitTags={2}
                value={
                    widgetOutputToAutocompleteValue()
                }
                key={widget.id}
                id="tags-outlined"
                onChange={(_, value) => {
                    setOutputDataCallback(value).then(r => null)
                }}
                options={widgetDataToOptions(widgetData)}
                getOptionLabel={(d) => d.toString()}
                filterSelectedOptions
                disableCloseOnSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={widget.name}
                    />
                )}
            />
            {url.includes("/bi/") &&
              <Box>
                  <Button variant="text" onClick={handleClick}>
                      Widget Output
                  </Button>
                  <Popper id={id} open={open} anchorEl={anchorEl} placement="right-end" transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <Typography sx={{ p: 2 }}>WidgetOutput: {JSON.stringify(widgetVariables)}</Typography>
                            </Paper>
                        </Fade>
                      )}
                  </Popper>
              </Box>
            }
        </div>

    );
}

const BasicAutoCompleteFilter: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<AutoCompleteFilterInst />}/>
}

export default BasicAutoCompleteFilter
