import React, { FC } from "react";
import {
  Button,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Box, Container } from "@mui/system";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import { useMainContext } from "../../../../contexts/MainContext";
import { OppLabelStore } from "src/cog/keystone/oppLabel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';

interface CreateOppLabelDialogProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  onCancel: () => void;
  onLabelCreated: () => Promise<void>;
}

const CreateOppLabelDialog: FC<CreateOppLabelDialogProps> = (props) => {
  const mainCtx = useMainContext();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box>
      <Dialog
          //disableEnforceFocus required to open chat if Dialog opened first
          disableEnforceFocus
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <DialogTitle textAlign="center">Create A Label</DialogTitle>
        <DialogContent>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Formik
              enableReinitialize
              initialValues={{
                batchLabels: [''], // Initialize with one empty label
                submit: null
              }}
              validationSchema={Yup.object().shape({
                batchLabels: Yup.array().of(
                  Yup.string()
                    .matches(/^[a-zA-Z0-9\s/-]*$/, "Please use only letters and numbers.")
                    .max(255)
                )
              })}
              onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
              ): Promise<void> => {
                setSubmitting(true);

                try {
                  // Create single / multiple labels
                  const oppLabels = values.batchLabels.map((label) => ({
                    label: label,
                    id: "",
                    action: "todo"
                  }));
                  const response = await OppLabelStore.CreateMany({
                    ClientName: mainCtx.activeOrganization.name,
                    labels: oppLabels
                  });
                  props.onLabelCreated();
                  if (response.success == true) {
                    enqueueSnackbar("Labels successfully created", {
                      anchorOrigin: {
                        horizontal: "right",
                        vertical: "top"
                      },
                      variant: "success"
                    });
                  } else {
                    enqueueSnackbar("Failed to create labels", {
                      anchorOrigin: {
                        horizontal: "right",
                        vertical: "top"
                      },
                      variant: "error"
                    });
                  }
                  props.setOpen(false);
                } catch (error) {
                  console.error("Error in onSubmit:", error);
                  setStatus({ success: false });
                  setErrors({ submit: "An error occurred while processing your request" });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                values
              }): JSX.Element => (
                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      marginTop: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <Box>
                      {values.batchLabels.map((batchLabel, index) => (
                        <Grid container spacing={0} key={index} sx={{ justifyContent: 'center', alignItems: "center", mt: 2.5, mb: 2.5 }}>
                          <Grid item xs={8}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <TextField
                                label="Enter Label Details"
                                required
                                fullWidth
                                id={`batchLabels[${index}]`}
                                name={`batchLabels[${index}]`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={batchLabel}
                                error={Boolean(errors.batchLabels && errors.batchLabels[index])}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              {/* Add textfield for new label */}
                              <Tooltip title="Add Label">
                                <Fab
                                  size="small"
                                  color="primary"
                                  aria-label="Add Label"
                                  onClick={() => {
                                    setFieldValue("batchLabels", [...values.batchLabels, '']);
                                  }}
                                  sx={{ ml: 1 }}
                                >
                                  <AddIcon />
                                </Fab>
                              </Tooltip>
                              {/* Remove label at the current index */}
                              {values.batchLabels.length > 1 && (
                                <Tooltip title="Remove Label">
                                  <Fab
                                    size="small"
                                    color="primary"
                                    aria-label="Remove Label"
                                    onClick={() => {
                                      const updatedLabels = [...values.batchLabels];
                                      updatedLabels.splice(index, 1);
                                      setFieldValue("batchLabels", updatedLabels);
                                    }}
                                    sx={{ ml: 1 }}
                                  >
                                    <RemoveIcon />
                                  </Fab>
                                </Tooltip>
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {errors.batchLabels && errors.batchLabels[index] && (
                                <Typography variant="body2" color="error" sx={{ marginTop: '20px', marginLeft: '8px' }}>
                                  {errors.batchLabels && errors.batchLabels[index]}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      ))}

                    </Box>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {isSubmitting ? <CircularProgress size={20} /> : "Create"}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CreateOppLabelDialog;