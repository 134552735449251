import type {FC, ReactNode} from 'react';
import {createContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {THEMES} from '../constants';

interface Settings {
  compact?: boolean;
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

interface SettingsProviderProps {
  children?: ReactNode;
}

const initialSettings: Settings = {
  compact: false,
  direction: 'ltr',
  responsiveFontSizes: false,
  roundedCorners: true,
  theme: THEMES.LIGHT
};

export const restoreSettings = (): Settings | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem('settings');
    if (storedData) {
      settings = JSON.parse(storedData);
      const versionNumber = settings.versionNumber;
      //This is when we have changed parameters affected by Local Storage - to force a clear.
      //Probably need to choose something better than "version number" to track if storage needs to be cleared but for now
      //we can just increment it.
      if (versionNumber === null || versionNumber !== 1) {
        window.localStorage.clear();
        settings = {
          versionNumber: 1,
          compact: true,
          direction: 'ltr',
          responsiveFontSizes: true,
          roundedCorners: true,
          theme: window.matchMedia('(prefers-color-scheme: dark)').matches
              ? THEMES.LIGHT
              : THEMES.LIGHT
        };
        window.localStorage.setItem('settings', JSON.stringify(settings));
      }
    } else {
      settings = {
        versionNumber: 1,
        compact: true,
        direction: 'ltr',
        responsiveFontSizes: true,
        roundedCorners: true,
        theme: window.matchMedia('(prefers-color-scheme: dark)').matches
            ? THEMES.LIGHT
            : THEMES.LIGHT
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  saveSettings: () => {}
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState<Settings>(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  const saveSettings = (updatedSettings: Settings): void => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
