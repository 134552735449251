/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import * as React from "react";

export interface InsertOneRequest {
  userID: string;
  orgID: string;
  cardID: string;
  actionType: string;
  assignedUserID?: string;
  timezone: string;
  clientName: string;
}

export interface InsertOneResponse {
  inserted: boolean;
}

const Store = {
  serviceProvider: 'Action-Log-Store',
  async InsertOne(request: InsertOneRequest): Promise<InsertOneResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.InsertOne`,
      request: [{
        "userID": request.userID,
        "orgID": request.orgID,
        "cardID": request.cardID,
        "actionType": request.actionType,
        "assignedUserID": request.assignedUserID,
        "timezone": request.timezone,
        "clientName": request.clientName,
      }]
    });

    return response.inserted
  }
}

export default Store;