import React, {useEffect, useLayoutEffect, useState} from "react";
import {Box, Container, Grid, IconButton, ThemeProvider, Tooltip, Typography} from "@mui/material";
import {useParams} from "react-router";
import {GoalStore} from "../../../../../cog/keystone/goals";
import Goal from "../../../../../cog/keystone/goals/goal/Goal";
import {Helmet} from "react-helmet-async";
import {Theme, useTheme} from "@mui/material/styles";
import GoalPageSkeleton from "./GoalPageSkeleton";
import GoalDetails from "./details/GoalDetails";
import GoalSettings from "./settings/GoalSettings";
import {useMainContext} from "../../../../../contexts/MainContext";
import {Member, MemberStore} from "../../../../../cog/keystone/member";
import ReactGA from "react-ga4";
import {KeyboardBackspace} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import {CampaignStore} from "../../../../../cog/keystone/goals/campaigns";

const GoalPage = () => {
    let {goalId, org, campaignId} = useParams();
    let campaign = campaignId ? campaignId : 'd2057666-6ec6-4682-9f1f-101c28dd3c2a'
    const mainCtx = useMainContext();
    let navigate = useNavigate();
    const location = useLocation();
    const doesAnyHistoryEntryExist = location.key

    const [goal, setGoal] = useState<Goal>(null);
    const [goalStatData, setGoalStatData] = useState<any>({});
    const [campaigns, setCampaigns] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [myTheme, setMyTheme] = useState<Theme>(useTheme());

    const handleGoalsReturn = () => {
        if (doesAnyHistoryEntryExist !== "default") {
            navigate(-1)
        } else {
            navigate('/app/' + org + '/campaigns/' + campaign)
        }
    }

    const fetchOne = async () => {
        setIsLoading(true)

        let responseIssue = await GoalStore.FindOne({id: goalId, clientName: mainCtx.activeOrganization.name});
        if (responseIssue) {
            setGoal(responseIssue.goal);

            let responseStats = await GoalStore.GoalStats({
                clientName: mainCtx.activeOrganization.name,
                goalID: goalId,
                goalCriteria: JSON.parse(responseIssue.goal.criteria)
            })
            if (responseStats) {
                setGoalStatData(responseStats)
            }

            let responseCampaigns = await CampaignStore.FindMany({clientName: mainCtx.activeOrganization.name})
            if (responseCampaigns) {
                setCampaigns(responseCampaigns.campaigns)
            }
        }
        setIsLoading(false)
    }

    const update = () => {
        setIsLoading(true)
        fetchOne().then(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        fetchOne()
        ReactGA.event("keystone_view", {
            userID: mainCtx.user.id,
            orgID: mainCtx.activeOrganization.id,
            pageName: 'Goal',
            goalID: goalId,
        });
    }, [goalId])


    return (
        <>
            <Helmet>
                <title>Goal</title>
            </Helmet>
            {/* <ThemeProvider theme={myTheme}> */}
                <Box sx={{flexGrow: 1}}>
                    <Container maxWidth={false}>
                        {goal && !isLoading && mainCtx.members ?
                            <Box>
                                <Box sx={{mb: 2, mt: 2}}>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >
                                        <Grid item
                                              sm={12}
                                              sx={{display: 'flex'}}>
                                            <Tooltip title="Return to Previous Page">
                                                <IconButton color="primary"
                                                            onClick={handleGoalsReturn}>
                                                    <KeyboardBackspace sx={{ color: (theme) => (theme.palette.mode === 'dark' ? "#f2bc53" : '#6b778c') }}/>
                                                </IconButton>
                                            </Tooltip>
                                            <Box>
                                                <Typography variant={"h4"}>
                                                    {goal.name}
                                                </Typography>
                                                <Typography variant={"body1"}>
                                                    {goal.description}
                                                </Typography></Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >
                                        <Grid item
                                              md={10}
                                              xs={12}>
                                            <GoalDetails goalStatData={goalStatData}
                                                         goal={goal}
                                                         setGoal={setGoal}
                                                         update={update}/>
                                        </Grid>
                                        <Grid item
                                              md={2}
                                              xs={12}>
                                            <GoalSettings goal={goal}
                                                          setGoal={setGoal}
                                                          campaigns={campaigns}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box> : <Box>
                                <GoalPageSkeleton/>
                            </Box>
                        }
                    </Container>
                </Box>
            {/* </ThemeProvider> */}
        </>
    )
}

export default GoalPage