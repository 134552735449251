import {useMainContext} from "../../../../contexts/MainContext";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {KanbanStore,} from "../../../../cog/keystone/card";
import {useTheme} from "@mui/material/styles";
import KeystoneHome from "../summary/Home";
import {Box, Dialog, Drawer, LinearProgress, TableContainer,} from "@mui/material";
import ListViewPage from "../listview/ListViewPage";
import Kanban from "../kanban/Kanban";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {AppsOutlined, ViewKanbanOutlined, ViewListOutlined,} from "@mui/icons-material";
import OpportunityCard from "../opportunity/OpportunityCard";
import HomeIssueSkeleton from "../summary/Skeleton";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import KeystoneStatsBar from "./stats/KeystoneStatsBar";
import {makeStyles} from "@mui/styles";
import FilterAsDrawer from "../filter/FilterAsDrawer";
import {OppLabelStore} from "../../../../cog/keystone/oppLabel";
import ReactGA from "react-ga4";
import {ExcludeStore} from "../../../../cog/keystone/exclude";
import {useSnackbar} from "notistack";
import CreateGoalDialog from "../goals/goalDataGrid/dialogs/CreateGoalDialog";
import {CreateOneRequest} from "../../../../cog/keystone/goals/goal/Store";
import {GoalStore} from "../../../../cog/keystone/goals";
import ExcludeFilterDrawer from "../exlude/ExcludeFilterDrawer";

const useStyles = makeStyles({
    drawerPaper: {
        marginTop: "50px",
    },
});

// Cont: Container for all the Keystone Pages
const Cont = () => {
    // Contexts
    const mainCtx = useMainContext();
    const keystoneCtx = useKeystoneContext();
    const {enqueueSnackbar} = useSnackbar();
    // Don't think navigate is being used here anywhere
    const navigate = useNavigate();
    //
    const classes = useStyles();


    // Used to see if levels are currently loading or not
    const [levelsLoading, setLevelsLoading] = useState<boolean>(false);

    //for selecting which page to show
    const [displayPage, setDisplayPage] = useState<string>("Home");
    const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
        setDisplayPage(newValue);
    };

    // Used to change if we should show the tab labels or not
    const [displayTabLabel, setDisplayTabLabel] = useState<boolean>(true);

    const handleGoalCreation = async (goal: CreateOneRequest) => {
        const response = await GoalStore.CreateOne(goal)

        if (response && response.id) {
            enqueueSnackbar("Goal Created", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "success",
            });
        } else {
            enqueueSnackbar("Failed to Create Goal!", {
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
                variant: "error",
            });
        }

        keystoneCtx.setFlipSwitchForGoals(!keystoneCtx.flipSwitchForGoals)
    }

    // handleResize: Turns off tab labels for small screens, so there are only icons
    const handleResize = () => {
        if (window.innerWidth <= 800) {
            setDisplayTabLabel(false);
            return;
        }
        if (window.innerWidth > 800) {
            setDisplayTabLabel(true);
            return;
        }
    };

    // Sets which options to display in the toolbar
    useLayoutEffect(() => {
        if (
            keystoneCtx.toolBarDisplayOptions.selectOpps != true ||
            keystoneCtx.toolBarDisplayOptions.filterToMe != true ||
            keystoneCtx.toolBarDisplayOptions.stats != true ||
            keystoneCtx.toolBarDisplayOptions.filter != true
        ) {
            keystoneCtx.setToolBarDisplayOptions({
                filter: true,
                stats: true,
                selectOpps: true,
                filterToMe: true,
                landingDateSelector: false,
            });
            // if (keystoneCtx.selectCards == true) {
            //     keystoneCtx.handleSelectCards()
            // }
        }
        // console.log("Pls pay attention to me", keystoneCtx.filters)
    }, [keystoneCtx.toolBarDisplayOptions]);


    // useEffect: This one creates a listener for screen size adjustments
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
    });


    useEffect(() => {
        ReactGA.event("keystone_view", {
            userID: mainCtx.user.id,
            orgID: mainCtx.activeOrganization.id,
            pageName: "Opportunities",
        });
    }, [mainCtx.activeOrganization]);


    return (
        <Box sx={{mt: 1}}>
            <Box
                sx={{width: "auto"}}
                onClick={() => keystoneCtx.setStatsDrawerOpen(false)}
                onKeyDown={() => keystoneCtx.setStatsDrawerOpen(false)}
            >
                <Drawer
                    //disableEnforceFocus required to open chat if Dialog opened first
                    disableEnforceFocus
                    anchor="right"
                    open={keystoneCtx.statsDrawerOpen}
                    onClose={() => keystoneCtx.setStatsDrawerOpen(false)}
                    classes={{paper: classes.drawerPaper}}
                >
                    <KeystoneStatsBar statData={keystoneCtx.statData}/>
                </Drawer>
            </Box>
            <Box>
                <FilterAsDrawer
                    setFilters={keystoneCtx.setFilters}
                    setLevelMode={keystoneCtx.setLevelMode}
                    filters={keystoneCtx.filters}
                    open={keystoneCtx.filterOpen}
                    setOpen={keystoneCtx.setFilterOpen}
                    onClose={keystoneCtx.handleFilterClose}
                    client={mainCtx.activeOrganization.name}
                    organizationId={mainCtx.activeOrganization.id}
                    userID={mainCtx.user.id}
                    onClear={keystoneCtx.handleFilterClear}
                />
            </Box>
            <Box>
                <ExcludeFilterDrawer
                    open={keystoneCtx.excludeFilterOpen}
                    setOpen={keystoneCtx.setExcludeFilterOpen}
                    onClose={keystoneCtx.handleExcludeFilterClose}
                    onClear={keystoneCtx.handleExcludeFilterClear}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    px: 0.2,
                    alignItems: "center",
                }}
            >
                {/* todo This box below seems to be doing nothing? or it is there to take space?*/}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        px: 0.2,
                        alignItems: "center",
                    }}
                >

                </Box>

                {/*Tabs for selecting page*/}
                <Tabs
                    value={displayPage}
                    onChange={handleTabsChange}
                    sx={{
                        // minHeight: "1rem",
                        flexWrap: "wrap",
                        "& .MuiTabs-flexContainer": {
                            flexWrap: "nowrap",
                            padding: 0.1,
                            justifyContent: "space-evenly",
                        },
                        "& .MuiButtonBase-root": {
                            paddingY: 0,
                            minHeight: "50px",
                            // width: "10rem"
                        },
                    }}
                >
                    <Tab
                        value={"Home"}
                        iconPosition="start"
                        icon={<AppsOutlined fontSize="small"/>}
                        label={displayTabLabel ? "Card View" : ""}
                    />
                    <Tab
                        value={"ListView"}
                        iconPosition="start"
                        icon={<ViewListOutlined fontSize="small"/>}
                        label={displayTabLabel ? "List View" : ""}
                    />
                    <Tab
                        value={"Kanban"}
                        iconPosition="start"
                        icon={<ViewKanbanOutlined fontSize="small"/>}
                        label={displayTabLabel ? "Kanban" : ""}
                    />
                </Tabs>
            </Box>

            <TableContainer
                sx={{
                    height: "85vh",
                    offset: "50px",
                    px: 1,
                    "&::-webkit-scrollbar": {
                        width: 10,
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: useTheme().palette.background.default,
                        borderRadius: 1,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: useTheme().palette.text.secondary,
                        borderRadius: 2,
                    },
                }}
            >
                {/* The linear progress bar shows up whenever a request is made to update the cards,
            Just so that there is feedback when the request is made, making it a little less jarring when things refresh
            */}
                {keystoneCtx.loadingBar && (
                    <LinearProgress
                        sx={{
                            marginLeft: 5,
                            marginRight: 5,
                            marginTop: 2,
                            justifySelf: "center",
                        }}
                    />
                )}
                {/*Keystone Page Selector*/}
                {displayPage == "Home" ? (
                    mainCtx.members != null && keystoneCtx.myRows != null && !levelsLoading ? (
                        <KeystoneHome
                            members={mainCtx.members}
                            issues={keystoneCtx.myRows} // todo change to use context
                            selectCards={keystoneCtx.selectCards}
                            selectedCardsIDs={keystoneCtx.selectedCardsID}
                            updateSelectedCards={keystoneCtx.updateSelectedCards}
                            allLabels={keystoneCtx.opportunityLabels} // todo change to use context
                        />
                    ) : (
                        <HomeIssueSkeleton/>
                    )
                ) : displayPage == "ListView" ? (
                    mainCtx.members != null &&
                    keystoneCtx.allCards != null &&
                    keystoneCtx.myRows != null &&
                    !levelsLoading ? (
                        <ListViewPage
                            // members={members}
                            allCards={keystoneCtx.allCards}
                            myRows={keystoneCtx.myRows} // todo change to use context
                            selectedCardsIDs={keystoneCtx.selectedCardsID}
                            updateSelectedCards={keystoneCtx.setSelectedCardsID}
                            selectCards={keystoneCtx.selectCards}
                            allLabels={keystoneCtx.opportunityLabels} // todo change to use context
                        />
                    ) : (
                        <HomeIssueSkeleton/>
                    )
                ) : displayPage == "Kanban" ? (
                    mainCtx.members != null &&
                    keystoneCtx.allCards != null &&
                    keystoneCtx.kanbanColumns != null &&
                    !levelsLoading ? (
                        <Kanban
                            members={mainCtx.members}
                            allcards={keystoneCtx.allCards} // todo change to use context
                            columns={keystoneCtx.kanbanColumns} // todo change to use context ?
                            setColumns={keystoneCtx.setKanbanColumns}
                            selectCards={keystoneCtx.selectCards}
                            selectedCardsIDs={keystoneCtx.selectedCardsID}
                            updateSelectedCards={keystoneCtx.updateSelectedCards}
                            allLabels={keystoneCtx.opportunityLabels} // todo change to use context
                        />
                    ) : (
                        <HomeIssueSkeleton/>
                    )
                ) : (
                    <HomeIssueSkeleton/>
                )}

                <Dialog
                    //disableEnforceFocus required to open chat if Dialog opened first
                    disableEnforceFocus
                    onClose={() => {
                        keystoneCtx.handleCardClose();
                    }}
                    open={keystoneCtx.cardOpen}
                    maxWidth={"xl"}
                >
                    {keystoneCtx.cardOpen ? (
                        <OpportunityCard
                            onClose={() => {
                                keystoneCtx.handleCardClose();
                            }}
                        />
                    ) : (
                        ""
                    )}
                </Dialog>
            </TableContainer>
            <CreateGoalDialog
                open={keystoneCtx.createGoalOpen}
                setOpen={keystoneCtx.setCreateGoalOpen}
                handleGoalCreation={handleGoalCreation}
            />
        </Box>
    );
};

const Container = () => {
    return <Cont/>;
};

export default Container;
