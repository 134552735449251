/* eslint-disable */

import {useEffect} from 'react';
import type {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {
    Box,
    Card,
    CardContent,
    Container,
    Typography
} from "@mui/material";
import Logo from '../../components/Logo';
import gtm from '../../lib/gtm';
import makeStyles from '@mui/styles/makeStyles';
import LoginFirebase from "./LoginFirebase";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: 'url(https://source.unsplash.com/1920x1080/?supermarket)',
        backgroundRepeat: 'no-repeat'
    },
    methodIcon: {
        height: 30,
    },
    cardContainer: {
        paddingBottom: 80,
        paddingTop: 80,
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 300
    },
    currentMethodIcon: {
        height: 40,
        '& > img': {
            width: 'auto',
            maxHeight: '100%'
        }
    },
    div: {
        alignItems: 'center'
    }
}));

const Login: FC = () => {
    const classes = useStyles();

    useEffect(() => {
        gtm.push({event: 'page_view'});
    }, []);

    return (
        <div>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Box
                className={classes.root}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{py: '80px'}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 8
                        }}
                    >
                        <RouterLink to="/">
                            <Logo
                                sx={{
                                    height: 40,
                                    width: 40
                                }}
                            />
                        </RouterLink>
                    </Box>
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 4
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'center',
                                    justifyContent: 'center',
                                    mb: 1
                                }}
                            >
                                <Typography
                                    color="textPrimary"
                                    gutterBottom
                                    variant="h4"
                                >
                                    Welcome
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'center',
                                    justifyContent: 'center',
                                    mb: 1
                                }}
                            >
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    Log in on the internal platform
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3
                                }}
                            >
                                <LoginFirebase/>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </div>
    );
};

export default Login;