import React, {FC, useEffect} from "react";
import {Box, CircularProgress} from "@mui/material";
import {motion} from 'framer-motion/dist/framer-motion'

interface AnimationComponentProps {
    component?: any;
    keyId?: any;
}

const AnimationComponent: FC<AnimationComponentProps> = (props: AnimationComponentProps) => {

    return (
        <motion.div
            key={props.keyId}
            initial={{opacity: 0, width: '100%', position: 'absolute', justifyContent: 'center', display: 'flex'}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5}}
        >
            {props.component}
        </motion.div>
    )
};

export default AnimationComponent