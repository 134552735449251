import {Alert, AlertTitle, Box, ThemeProvider, Typography} from "@mui/material";
import React, {FC, useState} from "react";
import {Card as KanbanCardObj} from "../../../../cog/keystone/card";
import {FindHomeTopWidgetValuesResponse} from "../../../../cog/keystone/card/Store";
import IssueCards from "../cards/IssueCards";
import { alpha, Theme, useTheme } from "@mui/material/styles";
import {Helmet} from "react-helmet-async";
import {Member} from "../../../../cog/keystone/member";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";

interface KeystoneHomeProps {
    issues: KanbanCardObj[]
    members: Member[]
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: string, vName: string) => void
    allLabels: OppLabel[]
}

const KeystoneHome: FC<KeystoneHomeProps> = (props) => {
    const [theIssues, setIssues] = useState<KanbanCardObj[]>(props.issues)
    const [myTheme, setMyTheme] = useState<Theme>(useTheme())
    const [topWidget, setTopWidget] = useState<FindHomeTopWidgetValuesResponse>(null)
    const [members, setMembers] = useState<Member[]>(props.members)
    const [labels, setLabels] = useState<OppLabel[]>(props.allLabels)

    return (
        <><Helmet>
            <title>Home Page</title>
        </Helmet>
            <Box sx={{
                backgroundColor: useTheme().palette.background.default,
                m: 0,
                p: 0,
                color: useTheme().palette.text.primary
            }}>
                <Box
                  sx={{
                      maxWidth: "95%",
                      margin: 'auto',
                      backgroundColor: useTheme().palette.background.default
                  }}
                >
                    {
                        members != null && theIssues != null && theIssues.length > 0 ?
                          <IssueCards theIssues={theIssues} members={members} selectCards={props.selectCards}
                                      updateSelectedCards={props.updateSelectedCards}
                                      selectedCardsIDs={props.selectedCardsIDs}
                                      allLabels={labels}/>
                          :
                          <Alert
                            severity="info"
                            variant={"filled"}
                            sx={{
                                paddingTop: "50Ppx",
                                // paddingX: "10%",
                                marginTop: "20%",
                                marginLeft: "35%",
                                marginRight: "35%",
                                whiteSpace: 'pre-wrap',
                                display: "flex",
                                textAlign: "justify",
                                color: "white",
                                // color: (theme) => alpha(theme.palette.primary.main,0.5),
                                backgroundColor: useTheme().palette.primary.main,
                                // text: useTheme().palette.primary.contrastText
                            }}
                          >
                              <AlertTitle>
                                  <Typography variant={"h5"} color={useTheme().palette.primary.contrastText}>There are currently no opportunities that match your search criteria</Typography>
                              </AlertTitle>
                              <Typography variant={"h6"} color={useTheme().palette.primary.contrastText}>Please check your selected filters or contact an administrator</Typography>
                          </Alert>
                    }
                </Box>

            </Box>
        </>

    )
}

export default KeystoneHome