import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FC, useCallback, useEffect} from "react";
import Widget, {Location, Template, WidgetArguments} from "../../../../../cog/bitool/widget/Widget";
import {
  Autocomplete,
  Box,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { ArgumentStore } from "../../../../../cog/bitool/query";

interface FormDialogProps {
  label: string;
  onAdd: any;
  allWids: Widget[];
  queryID: string;
  widget: Widget;
}

const WidgetFormDialog: FC<FormDialogProps> = ({label, onAdd, allWids, queryID, widget}) => {
  const [open, setOpen] = React.useState(false);
  const [numWid, setNumWid] = React.useState<number>(0)
  const [qArgs, setQArgs] = React.useState<string>("")

  useEffect(()=>{
    if (queryID && queryID != '00000000-0000-0000-0000-000000000000') {
      fetchQueryArgs(queryID)
    }
  },[])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchQueryArgs = useCallback(async (val) => {
    let response
    try { response = await ArgumentStore.FindAll({
      queryId: val
    })}
    catch {response= "No Query Found"}
    if (response.queryArguments) {

      setQArgs(response.queryArguments[0].argument)
    }
    else {
      setQArgs("")
      setNumWid(0)
    }
  }, [])

  useEffect( () => {
      try {
        let len = Object.keys(JSON.parse(qArgs)).length
        setNumWid(len)}
      catch {return}},
    [qArgs])

  const handleSubmit = (event)=> {
    event.preventDefault()

    let allTemplates : Record<string, Template> = {}
    let nWidTemplate : Template

    Array.from({length: numWid}, (_, i) => i ).map((i)=> {
      let wI
      try { wI =event.target.elements["WidgetID" +i].value.split(" | ")[1] }
      catch {}
      let key = event.target.elements["KeyName"+ i].value
      nWidTemplate = {
        value: event.target.elements["WidValue"+ i].value,
        widgetId: wI,
        keyOrIndex: event.target.elements["keyOrIndex"+ i].value,
        serializerStringFunction: event.target.elements["radioSerialzer" + i].value
      }
      allTemplates[key.toString()] = nWidTemplate
    })

    widget.arguments.templates = allTemplates
    onAdd(JSON.stringify(widget.arguments, null, "\t"))
    handleClose()
  }

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} >
        {label}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Build Widget</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <FormControl  component="fieldset" style={{width: "100%"}}>
              { qArgs.length > 0 &&
                <div style={{ margin: 10}}>
                  <Typography >Templates</Typography>
                </div>
              }
              { qArgs.length > 0 &&
                Object.entries(JSON.parse(qArgs)).map((k,i) => {
                  return <Box key={i} sx={{border: 1, padding: 1, borderRadius: 1, marginTop: 1, marginBottom: 1, justifyContent: "space-around", alignItems: "center"}}>
                    <TextField sx={{margin: 1}} name={"KeyName" +i} label={"Key Name"} defaultValue={k[0]} key={"KN" + i}/>
                    <TextField sx={{margin: 1}} name={"WidValue" +i} label={"WidValue"} key={"WV" + i}/>
                    <Autocomplete
                      disablePortal
                      id={"WidgetID" + i}
                      key={"WI" + i}
                      sx = {{marginTop: 1, marginBottom: 1, width: "100%", height: "100%"}}
                      options={allWids}
                      getOptionLabel={(option: Widget) => option.name + " | " + option.id}
                      renderInput={(params) => <TextField {...params}  name={"WidgetID" + i}  label="Add Widgets ID" />}
                    />
                    <TextField sx={{margin: 1}} name= {"keyOrIndex" + i} label={"KeyOrIndex"} defaultValue={k[0]}/>
                    <RadioGroup
                      row
                      aria-label="type"
                      defaultValue="BasicTable"
                      key={"radioSerialzer" + i}
                      name={"radioSerialzer" + i}
                    >
                      <FormControlLabel key={"$StringSerializer"+i} value="$StringSerializer" control={<Radio />} label="StringSerializer" />
                      <FormControlLabel key={"$StringArraySerializer"+i} value="$StringArraySerializer" control={<Radio />} label="StringArraySerializer" />
                      <FormControlLabel key={"$DateSerializer"+i} value="$DateSerializer" control={<Radio />} label="DateSerializer" />
                    </RadioGroup>
                  </Box>})
              }
            </FormControl>
            <ButtonGroup variant="contained" sx={{justifyContent: "center", alignItems: "center", width: "100%"}}>
              <Button type="submit">Submit</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </ButtonGroup>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default WidgetFormDialog;