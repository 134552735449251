import React, { ChangeEvent, FC, useState } from "react";
import {
  Box, Button,
  Divider,
  Tab,
  Tabs
} from "@mui/material";
import 'react-grid-layout/css/styles.css'
import QueryEditor from "../../query/QueryEditor";
import WidgetEditorInterface from "./WidgetEditorInterface";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

const tabs = [
  {
    value: 'edit query',
    label: 'Edit Query'
  },
  {
    value: 'edit widget',
    label: 'Edit Widget'
  }
];

const WidgetEditorLayout: FC = () => {
    let {dashId} = useParams();
    let navigate = useNavigate()

    const [currentTab, setCurrentTab] = useState<string>('edit query');

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };


    const handleListReturn = () => {
      navigate('/app/bi/dashchoose')
    }

    const handleDashReturn = () => {
        navigate('/app/bi/dashboard/' + dashId)
    }

    return (
        <Box mt={3}>
            <Box>
                <Button onClick={handleListReturn}>Back to Dashboard List</Button>
                <Button onClick={handleDashReturn}>Back to Dashboard</Button>
            </Box>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="fullWidth"
            >
                {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      value={tab.value}
                      label={tab.label}
                    />
                ))}
            </Tabs>
            <Divider />
            <Box mt={3}>
              {currentTab === 'edit query' && <QueryEditor />}
              {currentTab === 'edit widget' && <WidgetEditorInterface />}
            </Box>
        </Box>
    );
}

export default WidgetEditorLayout;