/* eslint-disable */
import React, {useState, useEffect, FC} from 'react';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-tomorrow_night";
import "ace-builds/src-noconflict/ext-language_tools"
import 'ace-builds/webpack-resolver';
import useSettings from "../hooks/useSettings";
import {THEMES} from "../constants";

interface PrismProps {
    code: string
    setCode: (string) => void

    language: string
}

const AceCodeEditor: FC<PrismProps> = ({code, setCode, language}) => {
    const settings = useSettings()
    const themeMode = settings.settings.theme == THEMES.DARK

    return (
        <div style={{
          direction: "ltr",
          fontFamily: "monospace !important",
          fontSize: "16px !important",
          height: "100%",
          textAlign: "left",
          width: "100%",
          padding: "10px"
        }}>
            <AceEditor
                height="100%"
                width="auto"
                value={code}
                mode={language}
                theme={ themeMode ? "tomorrow_night" : "github" }
                onChange={setCode}
                name={`${Math.random()}-div`}
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                }}
            />
        </div>
    );
};

export default AceCodeEditor;