import {useEffect, useState} from 'react';
import type { FC } from 'react';
import {
    Box, Button,
    Card,
    CardContent,
    CardHeader, Fade, Paper, Popper,
    Typography
} from "@mui/material";
import {WidgetProps} from "../../../WidgetSwitcher";
import * as React from "react";
import _ from "lodash";
import BasicWidget from "../BasicWidget";
import {
    DataGrid,
    GridComparatorFn,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector
} from "@mui/x-data-grid";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import {useTheme} from "@mui/system";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { startOfWeek, endOfWeek } from "date-fns";

const useStyles = makeStyles({
        root: {
            height: '100%',
            width: '100%',
            '& .red': {
                color: '#f00',
            },
            '& .green': {
                color: '#00FF00',
            },
            '& .super-app-theme--Filled': {
                backgroundColor: '#6b778c',
                '&:hover': {
                    backgroundColor: '#42526e',
                },
            },
        }
});

const customComparator: GridComparatorFn = (v1, v2) => {
    const numArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let flag1 = false;
    let flag2 = false;

    if (v1 == null && v2 != null) {
        let num = (v2 as number)
        return num
    } else if (v1 != null && v2 == null) {
        let num = (v1 as number)
        return num
    } else  if(v1 != null && v2 != null) {
        let temp1 = v1.toString().replace(/,/g, "")
        let temp2 = v2.toString().replace(/,/g, "")

        for (let i=0; i<numArray.length; i++) {
            if (temp1.includes(numArray[i])) {
                flag1 = true;
            }
        }

        for (let i=0; i<numArray.length; i++) {
            if (temp2.includes(numArray[i])) {
                flag2 = true;
            }
        }

        if (flag1 == true && flag2 == true) {
            let tempNum1 = parseInt(temp1)
            let tempNum2 = parseInt(temp2)

            return tempNum1 - tempNum2
        }

        return temp1.localeCompare(temp2)
    } else {
        console.log("Cannot Sort")
        return 0
    }

}

const setCSVFilename = () => {
    const tab = document.title
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const timestamp = today.toISOString().slice(0, 19);
    const filename = tab + "_" + timestamp
    return filename
}

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport
                csvOptions={{
                    fileName: setCSVFilename()
                }}
            />
        </GridToolbarContainer>
    );
}

const TableInst: FC<WidgetProps> = ({widget, widgetData, widgetColumns, widgetVariables, allWidgetVariables,
                                        setVariableOutputs}) => {
    const theme = useTheme()
    const classes = useStyles(theme)
    interface tableData {
        columns: any[],
        rows: any[]
    }

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    let url = window.location.pathname.toString()
    let hasHVTitle = false;
    let hasDateTitle = false;
    let HVTitle = '';
    let dateTitle = '';

    function numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    const [data, setData] = useState<tableData>({columns: [], rows: []});
    const [page, setPage] = React.useState(0);
    let customCols = false;
    const clsxWrapper = (params: {}) => clsx('super-app', params)


    let formatCol = (k: string) => {
        if (k.includes("perc") || k.includes("Perc") || k.includes("PERC")) {
            return {
                field: k,
                headerName: k,
                width: 200,
                sortComparator: customComparator,
                cellClassName: (params) =>
                    clsxWrapper({
                        negative: typeof params.value == 'string' ? params.value.includes('-') :  params.value < 0,
                        positive: typeof params.value == 'string' ? !params.value.includes('-') :  params.value > 0,
                    })
            }
        } else {
            return {
                field: k,
                headerName: k,
                width: 200,
                sortComparator: customComparator,
            }
        }
    };

    let formatColCustom = (k: string) => {};
    let includeTotal = false;

    const code = widget.arguments.code ? unescape(widget.arguments.code) : null
    let additionalProps = {}
    try {
        eval(code);
    } catch (e) {
    }

    let formatData = () => {
        if (widgetData && Object.keys(widgetData) && Object.keys(widgetData).length &&
                widgetData[Object.keys(widgetData)[0]]) {

            const cols = widgetColumns.map((k, index) => {
                if (customCols == false) {
                    return formatCol(k)
                } else {
                    return formatColCustom(k)
                }
            })

            // console.log("Widget Data: ", widgetData)

            let rows = []
            let totalRow = {}
            let totalFlag = 0
            totalRow['id'] = 0

            if (includeTotal != false) {
                Object.keys(widgetData).map((k) => {
                    if (k.includes("perc") || k.includes("Perc") || k.includes("PERC")) {
                        totalRow[k] = null
                    } else if (Number.isFinite(widgetData[k][0]) && widgetData[k][0] !== null) {
                        let count = 0
                        count = _.sum(widgetData[k])
                        let num = Math.round((count + Number.EPSILON) * 100) / 100
                        totalRow[k] = numberWithCommas(num)
                    } else if (totalFlag == 0) {
                        totalRow[k] = "Total"
                        totalFlag++
                    } else {
                        totalRow[k] = null
                    }
                })

                rows.push(totalRow)
            }

            _.range(widgetData[Object.keys(widgetData)[0]].length).map((idx) => {
                let row = {}
                row['id'] = idx + 1
                Object.keys(widgetData).map((k) => {
                    if (Number.isFinite(widgetData[k][idx]) && widgetData[k][idx] !== null) {
                        let temp = numberWithCommas(widgetData[k][idx])
                        row[k] = temp
                    } else {
                        row[k] = widgetData[k][idx]
                    }
                })
                rows.push(row)
            })
            setData({
                columns: cols,
                rows: rows,
            })
        }
        else {
            const cols = [{"field": "NoData", "headerName": "NO DATA TO DISPLAY", "width": 400}]
            const rows = [{id: 0, NoData: "Query returned no results"}]
            setData({
                columns:cols,
                rows: rows
            })
        }

    }

    let setOutputData = setVariableOutputs

    useEffect(() => {
        formatData()
    }, [widgetData, widgetColumns])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    if (widgetData && Object.keys(widgetData) && Object.keys(widgetData).length && widgetData[Object.keys(widgetData)[0]]) {
            return (
                  <div className={classes.root}>
                          <div style={{ display: 'flex', height: '100%', width: '100%'}}>
                                  <div>
                                          {hasDateTitle && <Box>
                                                  <Typography>
                                                        {dateTitle}
                                                  </Typography>
                                          </Box>}
                                  </div>
                                  <div>
                                          {hasHVTitle && <Box>
                                                  <Typography>
                                                        {HVTitle}
                                                  </Typography>
                                          </Box>}
                                  </div>
                                  <Box
                                        sx={{
                                                height: "100%",
                                                flexGrow: 1,
                                                '& .super-app.negative': {
                                                    color: '#af092c',
                                                    fontWeight: 600
                                                },
                                                '& .super-app.positive': {
                                                    color: '#09af28',
                                                    fontWeight: 600
                                                },
                                                '& .super-app.amber': {
                                                    color: '#f7b900',
                                                    fontWeight: 600
                                                },
                                                '& .super-app.red': {
                                                    color: '#af092c',
                                                    fontWeight: 600
                                                },
                                                '& .super-app.green': {
                                                    color: '#09af28',
                                                    fontWeight: 600
                                                },
                                                '& .super-app.bold': {
                                                    fontWeight: 900
                                                },
                                        }}
                                  >
                                          <DataGridPro
                                            autoHeight
                                            page={page}
                                            onPageChange={(newPage) => setPage(newPage)}
                                            loading={data.rows.length === 0}
                                            rowHeight={38}
                                            columns={data.columns}
                                            rows={data.rows}
                                            pageSize={10}
                                            columnBuffer={2}
                                            rowsPerPageOptions={[10]}
                                            pagination
                                            components={{ Toolbar: CustomToolbar}}
                                            {...additionalProps}
                                          />
                                  </Box>
                                  {url.includes("/bi/") &&
                                        <Box>
                                            <Button variant="text" onClick={handleClick}>
                                                Widget Output
                                            </Button>
                                            <Popper id={id} open={open} anchorEl={anchorEl} placement="right-end" transition>
                                                {({ TransitionProps }) => (
                                                  <Fade {...TransitionProps} timeout={350}>
                                                      <Paper>
                                                          <Typography sx={{ p: 2 }}>WidgetOutput: {JSON.stringify(widgetVariables)}</Typography>
                                                      </Paper>
                                                  </Fade>
                                                )}
                                            </Popper>
                                        </Box>
                                  }
                          </div>
                  </div>
            );
    } else {
        return (
          <Card>
              <CardHeader
                title="NO DATA TO DISPLAY"
              />
              <CardContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                  >
                      Query returned no results
                  </Typography>
              </CardContent>
          </Card>
        )
    }

};

const BasicTable: FC<WidgetProps> = (props) => {
    return  <BasicWidget {...props} children={<TableInst />}/>
};

export default BasicTable
