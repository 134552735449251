import type { FC } from 'react';
import * as React from "react";
import Chart from 'react-apexcharts';
import {
  Box
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from "apexcharts";
import currencyFormatter from "../../../../../../utils/currencyFormatter";
import currencySwitcher from "../../../../../../utils/currencySwitcher";
import { FilterItem } from "../../../../../../cog/keystone/filter/Filter";
import { Filter as FilterObject, FilterStore } from "../../../../../../cog/keystone/filter";
import { useMainContext } from "../../../../../../contexts/MainContext";
import { useNavigate } from "react-router-dom";
import { useKeystoneContext } from "../../../../../../contexts/KeystoneContext";

interface LandingColumnArea {
  data: {};
  org: string;
  labelValue: string;
}

const OverviewArea: FC<LandingColumnArea> = ({org, data, labelValue}) => {
  const theme = useTheme();
  const chartType = "area";
  let formatter = Intl.NumberFormat('en', {notation: 'compact'})
  const mainCtx = useMainContext()
  const keystoneCtx = useKeystoneContext()

  const columnName = labelValue
  const seriesCols = Object.keys(data).filter((k) => k != columnName)
  const series = seriesCols ? seriesCols.map((c) => {
    return {
      name: c,
      data: data && data[c] ? data[c] : []
    }
  }) : [{name: "y", data: []}]

  series.unshift(series.pop())

  const chartOptions: ApexOptions = {
    chart: {
      type: "area",
      stacked: true
    },
    xaxis: {
      type: 'category',
      tickAmount: 'dataPoints',
      labels: {
        rotateAlways: true,
        rotate: -60,
        trim: true,
      },
      tickPlacement: 'on',
      categories: data && data[columnName] ? data[columnName] : []
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return currencySwitcher(org) + formatter.format(val)
        }
      }
    },
    theme: {
      mode: theme.palette.mode
    },
    stroke: {
      curve: 'straight'
    },
    colors: ["#4b4b4b", "#f2bc53", "#f45c5c", "#e1d5c6", "#8c9494"],
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      }
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: 'bottom',
    },
    grid: {
      padding: {
        bottom: 20,
        left: 80,
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return currencySwitcher(org) + formatter.format(val)
        }
      }
    },
  };

  return (
    <Box sx={{ minHeight: 500, minWidth: 300 }}>
      <Chart
        type={chartType}
        options={chartOptions}
        height={"90%"}
        width={"90%"}
        series={series}
      />
    </Box>
  );
}

export default OverviewArea;