
import {Avatar, Box, Button, Grid, Typography} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faCrown } from '@fortawesome/free-solid-svg-icons'

import {FC} from "react";
import {WidgetProps} from "../../bitool/widget/WidgetSwitcher";
import {LeaderboardItemProps, LeaderboardProps} from "./Leaderboard";
import LeaderSummary from "./LeaderSummary";
import {Leaderboard} from "../../../../cog/keystone/leaderboard";
import {slice} from "lodash";
import {useTheme} from "@mui/material/styles";

// https://codepen.io/saarthak-slathia/pen/VwWKxbm
const BoardItem: FC<LeaderboardItemProps> = ({position, avatar, username, value, difference}) => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            margin: 1,
            borderRadius: 'circular',
        }}>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginX: 1,
                flexDirection: 'row-reverse'
            }}>
                <Box
                    component="i"
                    sx={{
                        fontSize: {
                            md:'1.3rem',
                            xs: '1rem',
                        },
                        color: difference >= 0 ? useTheme().palette.success.main : useTheme().palette.error.main,
                    }}
                >
                    { difference > 0 ?
                        <FontAwesomeIcon icon={faCaretUp} />
                        : difference == 0 ? <Box sx={{marginX: 0.5}}></Box>
                    :<FontAwesomeIcon icon={faCaretDown} />
                    }
                </Box>
                {/*<Typography*/}
                {/*    component="div"*/}
                {/*    sx={{*/}
                {/*        display: 'block',*/}
                {/*        paddingTop: '1rem',*/}
                {/*        color: '#EEEEEE',*/}
                {/*        fontSize: {*/}
                {/*            lg: '27px',*/}
                {/*            md: '27px',*/}
                {/*            sm: '13px',*/}
                {/*            xs: '13px'*/}
                {/*        },*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {position}*/}

                {/*</Typography>*/}
                <Typography
                    component="div"
                    sx={{
                        marginX: 0.5,
                        color: useTheme().palette.text.primary,
                        fontSize: {
                            md:'1.3rem',
                            xs: '1rem',
                        },
                    }}
                >
                    { difference > 0 ? '+' : ''}{ difference }
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '70px',
                padding: 0.5,
                border: '2px',
                borderStyle: 'solid',
                borderColor: difference >= 0 ? useTheme().palette.success.main : useTheme().palette.error.main,
                width: '70%',
                // background: useTheme().palette.background.paper,
            }}>
                <Box
                    component="img"
                    sx={{
                        width: {
                            lg: '60px',
                            md: '60px',
                            sm: '50px',
                            xs: '40px',
                        },
                        // background: '#fff',
                        borderRadius: '50%',
                    }}
                    alt={ username }
                    src={ avatar }
                />
                <Typography
                    component="div"
                    sx={{
                        color: useTheme().palette.text.primary,
                        fontSize: {
                            md:'1.3rem',
                            xs: '1rem',
                        },
                        margin: 1,
                        overflow: 'hidden',
                    }}
                >
                    {username}

                </Typography>
                <Typography
                    component="div"
                    sx={{
                        fontSize: {
                            md:'1.5rem',
                            xs: '1rem',
                        },
                        color: useTheme().palette.info.main,
                        marginLeft: 'auto',
                        marginRight: '1rem',
                    }}
                >
                    { formatter.format(value) }
                </Typography>
            </Box>
        </Box>
    )
}

const Board: FC<LeaderboardProps> = ({items}) => {
    return (
        <Box sx={{
            marginTop: 1,
        }}>
            { items && items.map((item) => {
                return <BoardItem {...item} />
            })}
        </Box>
    )
}

export default Board