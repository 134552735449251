import {PlatformOptions} from "src/pages/app/notifications/NotificationsGeneralSettings";
import {API, PerformJSONRPCRequest} from "../../api/jsonrpc";
import {NotificationLog} from "./NotificationLog";

/* Notification_Log */

export interface CreateOneRequest {
    ClientName: string
    UserId: string
    CardId: string
    ActionType: string
    Description: string
    Platform: string
    IsRead: boolean
}

export interface CreateOneResponse {
    Created: boolean
    ID: string
}

export interface CreateManyRequest {
    ClientName: string
    notification_logs: NotificationLog[]
}

export interface CreateManyResponse {
    Created: boolean
}

export interface FindManyByUserIdRequest {
    ClientName: string
    UserID: string
}

export interface FindManyByUserIdResponse {
    notification_log: NotificationLog[]
}

export interface ReadOneRequest {
    ClientName: string
    ID: string
}

export interface ReadOneResponse {
    Read: boolean
}

export interface ReadManyRequest {
    ClientName: string
    UserID: string
}

export interface ReadManyResponse {
    ReadAll: boolean
}

export interface UnreadOneRequest {
    ClientName: string
    ID: string
}

export interface UnreadOneResponse {
    Unread: boolean
}

export interface UnreadManyRequest {
    ClientName: string
    UserID: string
}

export interface UnreadManyResponse {
    UnreadAll: boolean
}

/* Platform_Log */

export interface CreateOneByUserIdRequest {
    ClientName: string
    UserID: string
    ActionType: string
    Description: string
    Platform: string
}

export interface CreateOneByUserIdResponse {
    Created: boolean
    ID: string
}

export interface FindAllByUserIdRequest {
    ClientName: string
    UserID: string
}

export interface FindAllByUserIdResponse {
    notification_params: Record<string, PlatformOptions>
}

export interface DeleteOneByUserIdRequest {
    ClientName: string
    UserID: string
    ActionType: string
    Platform: string
}

export interface DeleteOneByUserIdResponse {
    Deleted: boolean
}

const Store = {
    serviceProvider: "Notifications-Store",
    
    /* Notification_Log */
    
    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response: CreateOneResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                "client_name": request.ClientName,
                "userID": request.UserId,
                "cardID": request.CardId,
                "actionType": request.ActionType,
                "description": request.Description,
                "platform": request.Platform,
                "isRead": request.IsRead
            }]
        })
        return response
    },
    
    async CreateMany(request: CreateManyRequest): Promise<CreateManyResponse> {
        const response: CreateManyResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateMany`,
            request: [{
                "client_name": request.ClientName,
                "notification_logs": request.notification_logs
            }]
        })
        return response
    },
    
    async FindManyByUserId(request: FindManyByUserIdRequest): Promise<FindManyByUserIdResponse> {
        const response: FindManyByUserIdResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindManyByUserId`,
            request: [{
                "client_name": request.ClientName,
                "user_id": request.UserID,
            }]
        })
        return response
    },
    
    async ReadOne(request: ReadOneRequest): Promise<ReadOneResponse> {
        const response: ReadOneResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.ReadOne`,
            request: [{
                "client_name": request.ClientName,
                "id": request.ID,
            }]
        })
        return response
    },
    
    async ReadMany(request: ReadManyRequest): Promise<ReadManyResponse> {
        const response: ReadManyResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.ReadMany`,
            request: [{
                "client_name": request.ClientName,
                "user_id": request.UserID,
            }]
        })
        return response
    },
    
    async UnreadOne(request: UnreadOneRequest): Promise<UnreadOneResponse> {
        const response: UnreadOneResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UnreadOne`,
            request: [{
                "client_name": request.ClientName,
                "id": request.ID,
            }]
        })
        return response
    },
    
    async UnreadMany(request: UnreadManyRequest): Promise<UnreadManyResponse> {
        const response: UnreadManyResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UnreadMany`,
            request: [{
                "client_name": request.ClientName,
                "user_id": request.UserID,
            }]
        })
        return response
    },
    
    /* Platform_Log */
    
    async CreateOneByUserId(request: CreateOneByUserIdRequest): Promise<CreateOneByUserIdResponse> {
        const response: CreateOneByUserIdResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOneByUserId`,
            request: [{
                "client_name": request.ClientName,
                "user_id": request.UserID,
                "action_type": request.ActionType,
                "description": request.Description,
                "platform": request.Platform,
            }]
        })
        return response
    },
    
    async FindAllByUserId(request: FindAllByUserIdRequest): Promise<FindAllByUserIdResponse> {
        const response: FindAllByUserIdResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindAllByUserId`,
            request: [{
                "client_name": request.ClientName,
                "user_id": request.UserID,
            }]
        })
        return response
    },
    
    async DeleteOneByUserId(request: DeleteOneByUserIdRequest): Promise<DeleteOneByUserIdResponse> {
        const response: DeleteOneByUserIdResponse = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOneByUserId`,
            request: [{
                "client_name": request.ClientName,
                "user_id": request.UserID,
                "action_type": request.ActionType,
                "platform": request.Platform,
            }]
        })
        return response
    }
};

export default Store;