const OpportunityType = (props) => {
    let blobColour = Boolean(props.blob)? props.blob : '#404041'
    let lineColour = Boolean(props.line)? props.line : '#000'
    let fillColour = Boolean(props.fill)? props.fill : '#f6c743'
    let height = Boolean(props.height)? props.height : 50
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Business_Icons" viewBox="0 0 203 203" height={height}>
            <defs>
                <linearGradient id="opportunityTypeBlob" x1={60} x2="160.2" y1="150.7" y2="50.5" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={blobColour} />
                </linearGradient>
                <linearGradient id="opportunityTypeLine" x1="107.4" x2="111.6" y1="202.1" y2="197.9" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={lineColour} />
                </linearGradient>
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-2" x1="12.9" x2="17.2" y1="84.8" y2="80.6" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-3" x1="176.5" x2="180.7" y1={30} y2="25.8" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-4" x1="70.3" x2="72.3" y1="4.6" y2="2.6" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-5" x1="72.5" x2="74.4" y1="2.5" y2=".5" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-6" x1="74.7" x2="76.6" y1="4.6" y2="2.7" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-7" x1="72.5" x2="74.4" y1="6.7" y2="4.8" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-8" x1="196.2" x2="198.1" y1="128.4" y2="126.5" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-9" x1="198.3" x2="200.3" y1="126.3" y2="124.4" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-10" x1="200.5" x2="202.5" y1="128.4" y2="126.5" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-11" x1="198.3" x2="200.3" y1="130.6" y2="128.6" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-12" x1=".6" x2="2.5" y1="175.8" y2="173.9" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-13" x1="2.7" x2="4.7" y1="173.7" y2="171.8" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-14" x1="4.9" x2="6.8" y1="175.8" y2="173.9" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-15" x1="2.7" x2="4.7" y1="177.9" y2={176} />
                <linearGradient id="opportunityTypeFill" x1="81.8" x2="131.2" y1="166.3" y2="116.8" gradientUnits="userSpaceOnUse">
                    <stop offset={0} stopColor={fillColour} />
                </linearGradient>
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-2" x1="88.8" x2="94.6" y1="133.2" y2="127.4" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-3" x1="96.8" x2="101.5" y1="132.7" y2="128.1" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-4" x1="88.8" x2="94.6" y1="155.4" y2="149.6" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-5" x1="96.6" x2="101.4" y1="154.8" y2={150} />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-6" x1="84.5" x2="90.3" y1="112.6" y2="106.8" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-7" x1="95.7" x2="100.2" y1="112.3" y2="107.8" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-8" x1="108.8" x2="114.5" y1={176} y2="170.2" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-9" x1="98.9" x2="103.4" y1="175.1" y2="170.6" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-10" x1="75.5" x2="79.6" y1="121.1" y2={117} />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-11" x1="75.5" x2="79.6" y1="166.1" y2={162} />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-12" x1="109.6" x2="113.7" y1="98.5" y2="94.4" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-13" x1="138.2" x2="142.3" y1="137.3" y2="133.1" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-14" x1="56.1" x2="60.3" y1="150.5" y2="146.3" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-15" x1="78.8" x2="116.4" y1="77.9" y2="40.3" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-16" x1="38.2" x2="64.9" y1="81.3" y2="54.6" />
                <linearGradient xlinkHref="#opportunityTypeFill" id="opportunityTypeFill-17" x1="134.4" x2="167.7" y1="85.8" y2="52.5" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-16" x1={86} x2="114.7" y1="155.9" y2="127.1" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-17" x1="67.1" x2="127.1" y1="166.3" y2="106.3" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-18" x1="119.8" x2="125.3" y1="121.8" y2="116.3" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-19" x1="74.9" x2="80.4" y1="121.8" y2="116.3" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-20" x1="119.6" x2="125.2" y1="167.2" y2="161.6" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-21" x1="74.8" x2="80.3" y1="167.2" y2="161.6" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-22" x1="77.4" x2="121.4" y1="77.9" y2="33.9" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-23" x1="89.4" x2="109.4" y1="66.1" y2={46} />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-24" x1={87} x2="111.8" y1="68.5" y2="43.6" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-25" x1="86.7" x2={92} y1="37.2" y2={32} />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-26" x1="106.8" x2="112.1" y1="37.2" y2={32} />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-27" x1="115.5" x2="120.7" y1="73.3" y2="68.1" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-28" x1="96.8" x2={102} y1="81.9" y2="76.6" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-29" x1="120.1" x2="125.3" y1="52.9" y2="47.6" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-30" x1="73.6" x2="78.9" y1="52.9" y2="47.6" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-31" x1="78.1" x2="83.3" y1="73.3" y2="68.1" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-32" x1="133.7" x2="168.4" y1="86.5" y2="51.8" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-33" x1="141.6" x2="160.3" y1="77.6" y2="58.9" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-34" x1="37.5" x2="65.6" y1={82} y2="53.9" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-35" x1="45.2" x2="58.4" y1="74.4" y2="61.2" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-36" x1="135.3" x2="142.3" y1="138.7" y2="131.7" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-37" x1="127.4" x2="161.1" y1="144.1" y2="110.4" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-38" x1="56.6" x2="64.4" y1="152.3" y2="144.5" />
                <linearGradient xlinkHref="#opportunityTypeLine" id="opportunityTypeLine-39" x1="46.3" x2={68} y1="127.8" y2="106.1" />
                <style dangerouslySetInnerHTML={{__html: ".iconWhite{fill:#fff}" }} />
            </defs>
            <g id="Icon_35">
                <path id="Contour" d="M54 132.1a2.9 2.9 0 0 0-3-1.4c-6 1-22.9 1.5-24.2-25-1.6-32 37.3-86.8 94.4-76 45.8 8.8 11.6 52.5 39.7 63.5 16.4 6.4 31.3 39.4-5.2 68.1-19.8 15.5-70.5 29.3-101.7-29.2Z" style={{fill: 'url(#opportunityTypeBlob)'}} />
                <path id="Contour-2" d="M109.5 203a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" style={{fill: 'url(#opportunityTypeLine)'}} />
                <path id="Contour-3" d="M15 85.7a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" style={{fill: 'url(#opportunityTypeLine-2)'}} />
                <path id="Contour-4" d="M178.6 30.9a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 0 0 0 2 1 1 0 1 0 0-2Z" style={{fill: 'url(#opportunityTypeLine-3)'}} />
                <g id="Group">
                    <path id="Contour-5" d="M72.8 3.6a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#opportunityTypeLine-4)'}} />
                    <path id="Contour-6" d="M73.4 3a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#opportunityTypeLine-5)'}} />
                    <path id="Contour-7" d="M76.1 2.6h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#opportunityTypeLine-6)'}} />
                    <path id="Contour-8" d="M73.4 4.2a1 1 0 0 0-1 1v1.1a1 1 0 0 0 2 0v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#opportunityTypeLine-7)'}} />
                </g>
                <g id="Group-2">
                    <path id="Contour-9" d="M198.7 127.5a1 1 0 0 0-1-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#opportunityTypeLine-8)'}} />
                    <path id="Contour-10" d="M199.3 126.8a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#opportunityTypeLine-9)'}} />
                    <path id="Contour-11" d="M202 126.5h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#opportunityTypeLine-10)'}} />
                    <path id="Contour-12" d="M199.3 128a1 1 0 0 0-1 1v1.2a1 1 0 0 0 1 .9 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#opportunityTypeLine-11)'}} />
                </g>
                <g id="Group-3">
                    <path id="Contour-13" d="M3 174.9a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z" style={{fill: 'url(#opportunityTypeLine-12)'}} />
                    <path id="Contour-14" d="M3.7 174.2a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Z" style={{fill: 'url(#opportunityTypeLine-13)'}} />
                    <path id="Contour-15" d="M6.4 173.9h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2Z" style={{fill: 'url(#opportunityTypeLine-14)'}} />
                    <path id="Contour-16" d="M3.7 175.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z" style={{fill: 'url(#opportunityTypeLine-15)'}} />
                </g>
                <path id="Contour-17" d="M126.2 170.5a2.8 2.8 0 0 0 2.8-2.8v-9.5a.5.5 0 0 0-.5-.5h-4.8a.5.5 0 0 1-.5-.4v-5.7a.5.5 0 0 1 .5-.5h4.8a.5.5 0 0 0 .5-.4v-5a.5.5 0 0 0-.5-.5h-4.8a.5.5 0 0 1-.5-.4V139a.5.5 0 0 1 .5-.5h4.8a.5.5 0 0 0 .5-.4V133a.5.5 0 0 0-.5-.5h-4.8a.5.5 0 0 1-.5-.4v-5.7a.5.5 0 0 1 .5-.5h4.8a.5.5 0 0 0 .5-.4v-10.2a2.8 2.8 0 0 0-2.8-2.8H73.8a2.8 2.8 0 0 0-2.8 2.8v10.2a.5.5 0 0 0 .5.5h4.8a.5.5 0 0 1 .5.4v5.7a.5.5 0 0 1-.5.4h-4.8a.5.5 0 0 0-.5.5v5.2a.5.5 0 0 0 .5.4h4.8a.5.5 0 0 1 .5.5v5.6a.5.5 0 0 1-.5.5h-4.8a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.4h4.8a.5.5 0 0 1 .5.5v5.6a.5.5 0 0 1-.5.5h-4.8a.5.5 0 0 0-.5.5v9.5a2.8 2.8 0 0 0 2.8 2.8Z" className="iconWhite" />
                <path id="Contour-18" d="M85.9 112.5v57.8h41.5l1.6-2.6V157l-6 .3v-5.7l6-.9v-5l-6-.9.5-6.1 5.5-.5-.5-5.6-5.5-.5.5-6.1 5.5-.5v-11l-2.9-2H85.9z" style={{fill: 'url(#opportunityTypeFill)'}} />
                <rect id="Contour-19" width="20.4" height={29} x="85.9" y={127} className="iconWhite" rx="2.4" />
                <path id="Contour-20" d="M106.3 127h5v6.2a1.2 1.2 0 0 1-1.3 1.2h-2.5a1.2 1.2 0 0 1-1.2-1.3V127Z" className="iconWhite" />
                <path id="Contour-21" d="M89.3 127h5v6.2a1.2 1.2 0 0 1-1.3 1.2h-2.5a1.2 1.2 0 0 1-1.2-1.3V127Z" style={{fill: 'url(#opportunityTypeFill-2)'}} />
                <path id="Contour-22" d="M98.3 127h5v6.2a1.2 1.2 0 0 1-1.3 1.2h-2.5a1.2 1.2 0 0 1-1.2-1.3V127Z" className="iconWhite" />
                <path id="Contour-23" d="M97.9 127h2.5v6.5a.9.9 0 0 1-1 .9h-.6a.9.9 0 0 1-.9-.9V127Z" style={{fill: 'url(#opportunityTypeFill-3)'}} />
                <path id="Contour-24" d="M107.5 148.4h2.5a1.2 1.2 0 0 1 1.2 1.2v6.2h-4.9v-6.2a1.2 1.2 0 0 1 1.2-1.2Z" className="iconWhite" />
                <path id="Contour-25" d="M90.5 148.4H93a1.2 1.2 0 0 1 1.2 1.2v6.2h-5v-6.2a1.2 1.2 0 0 1 1.3-1.2Z" style={{fill: 'url(#opportunityTypeFill-4)'}} />
                <path id="Contour-26" d="M98.5 148.4h2.5a1.2 1.2 0 0 1 1.2 1.2v6.2h-5v-6.2a1.2 1.2 0 0 1 1.3-1.2Z" className="iconWhite" />
                <path id="Contour-27" d="M98.5 148.4h1a1 1 0 0 1 .9 1v6.4h-2.8v-6.5a1 1 0 0 1 .9-.9Z" style={{fill: 'url(#opportunityTypeFill-5)'}} />
                <path id="Contour-28" d="M84.4 112.5V107a.4.4 0 0 1 .4-.3H90a.4.4 0 0 1 .5.3v5.4Z" style={{fill: 'url(#opportunityTypeFill-6)'}} />
                <path id="Contour-29" d="M96.5 112.5V107a.4.4 0 0 1 .5-.3h5.1a.4.4 0 0 1 .5.3v5.4Z" className="iconWhite" />
                <path id="Contour-30" d="M96.3 112.9v-5.6c0-.2 0-.3.2-.3h2.8c.2 0 .3.1.3.3v5.6Z" style={{fill: 'url(#opportunityTypeFill-7)'}} />
                <path id="Contour-31" d="M108.6 112.5V107a.4.4 0 0 1 .5-.3h5.1a.4.4 0 0 1 .5.3v5.4Z" className="iconWhite" />
                <path id="Contour-32" d="M114.7 170.3v5.4a.4.4 0 0 1-.5.4h-5.1a.4.4 0 0 1-.5-.4v-5.3Z" style={{fill: 'url(#opportunityTypeFill-8)'}} />
                <path id="Contour-33" d="M102.6 170.3v5.4a.4.4 0 0 1-.5.4H97a.4.4 0 0 1-.5-.4v-5.3Z" className="iconWhite" />
                <path id="Contour-34" d="M102.8 170v5.5c0 .2-.1.4-.3.4h-2.8l-.2-.4V170Z" style={{fill: 'url(#opportunityTypeFill-9)'}} />
                <path id="Contour-35" d="M90.4 170.3v5.4a.4.4 0 0 1-.4.4h-5.2a.4.4 0 0 1-.4-.4v-5.3Z" className="iconWhite" />
                <circle id="Contour-36" cx="122.5" cy={119} r="2.9" className="iconWhite" />
                <circle id="Contour-37" cx="77.5" cy={119} r="2.9" style={{fill: 'url(#opportunityTypeFill-10)'}} />
                <circle id="Contour-38" cx="77.5" cy="164.1" r="2.9" style={{fill: 'url(#opportunityTypeFill-11)'}} />
                <circle id="Contour-39" cx="122.4" cy="164.4" r="2.9" className="iconWhite" />
                <circle id="Contour-40" cx="111.6" cy="96.5" r="2.9" style={{fill: 'url(#opportunityTypeFill-12)'}} />
                <circle id="Contour-41" cx="140.3" cy="135.2" r="2.9" style={{fill: 'url(#opportunityTypeFill-13)'}} />
                <circle id="Contour-42" cx="58.2" cy="148.4" r="2.9" style={{fill: 'url(#opportunityTypeFill-14)'}} />
                <path id="Contour-43" d="M121.7 63.5a24.1 24.1 0 0 1-4.5 4.7 2.8 2.8 0 1 1-1.3.9 24.2 24.2 0 0 1-37.8-17 2.7 2.7 0 1 1-.6-4.3 3 3 0 0 1 .5.3 25 25 0 0 1 .7-5l.3-.1a4.8 4.8 0 0 1-.8.2l-6.7 1a.4.4 0 0 0-.4.3l-2 8.6a.4.4 0 0 0 .2.4l5.6 3.9a5.3 5.3 0 0 1 1.6 7l-3.4 5.9a.4.4 0 0 0 0 .5l5.5 6.8a.4.4 0 0 0 .5.2l6.5-2A5.3 5.3 0 0 1 92 79l2.5 6.3a.4.4 0 0 0 .4.3h8.8a.4.4 0 0 0 .4-.3l2.5-6.3a5.3 5.3 0 0 1 6.5-3.1l6.5 2a.4.4 0 0 0 .4-.2l5.5-6.8a.4.4 0 0 0 0-.5l-3.4-6a5.3 5.3 0 0 1 1.6-7l5.6-3.8h.1l-8.3 5.4Zm-39 9.1a2.7 2.7 0 1 1 0-3.8 2.7 2.7 0 0 1 0 3.8Zm18.7 8.6a2.7 2.7 0 1 1 0-4 2.7 2.7 0 0 1 0 4Z" style={{fill: 'url(#opportunityTypeFill-15)'}} />
                <path id="Contour-44" d="M127.7 43.6h-4.4l-2.7-.4a5.2 5.2 0 0 1-1.4-.4h-.1a5.3 5.3 0 0 1-2.4-2.4l.4-.7-.8-.7a5.4 5.4 0 0 1-.1-1.3l.5-6.8a.4.4 0 0 0-.3-.5l-7.9-3.8a.4.4 0 0 0-.4.1l-2.4 1.4c-1.3 1.3-2.6 2-3.3 2.9a3 3 0 0 1-2.6 1.1c-1.4.1-2.8.3-4-.8l-5-4.6a.4.4 0 0 0-.5 0l-7.9 3.7a.4.4 0 0 0-.2.5l.5 6.8v.5a4.4 4.4 0 0 1-1.8 3.8l-.6.4a5.3 5.3 0 0 1-.8.4 5.2 5.2 0 0 1-.5.2h-.3a25 25 0 0 0-.7 5 2.7 2.7 0 0 1 .7 3.5 2.6 2.6 0 0 1-.6.7A24.2 24.2 0 0 0 116 69a2 2 0 0 1 .2-.3 2.8 2.8 0 0 1 1-.7 24.1 24.1 0 0 0 4.5-4.6l.9-3.9 7-6.1a.4.4 0 0 0 0-.4ZM109.5 32a2.7 2.7 0 1 1-2.8 2.7 2.7 2.7 0 0 1 2.8-2.7Zm-20.1 0a2.7 2.7 0 1 1-2.8 2.7 2.7 2.7 0 0 1 2.8-2.7Zm10 37.4A13.2 13.2 0 1 1 112.6 56a13.2 13.2 0 0 1-13.2 13.3ZM124.6 52a2.7 2.7 0 1 1 0-3.8 2.7 2.7 0 0 1 0 3.9Z" className="iconWhite" />
                <path id="Contour-45" d="m67.9 67.7 2.6-2a.5.5 0 0 0 .2-.6l-1.4-5a.5.5 0 0 0-.5-.4l-3.3-.3a4.3 4.3 0 0 1-3.8-4.4l.1-3.3a.6.6 0 0 0-.3-.5l-4.8-2a.5.5 0 0 0-.6.1l-2.3 2.4a4.3 4.3 0 0 1-5.8.3l-2.5-2.1a.5.5 0 0 0-.6 0l-4.6 2.4a.5.5 0 0 0-.3.5l.4 3.3a4.2 4.2 0 0 1-3.3 4.7l-3.3.7a.5.5 0 0 0-.4.4l-.9 5.1a.6.6 0 0 0 .3.6l2.8 1.7a4.2 4.2 0 0 1 1.6 5.5l-1.5 3a.5.5 0 0 0 0 .6l3.5 4a.5.5 0 0 0 .5 0l3.2-1a4.2 4.2 0 0 1 5.3 2.2l1.3 3a.5.5 0 0 0 .5.3l5.2-.3a.5.5 0 0 0 .5-.3l1-3.2a4.3 4.3 0 0 1 5.1-2.7l3.2.8a.6.6 0 0 0 .6-.2l3-4.2a.6.6 0 0 0 0-.6l-1.8-2.8a4.2 4.2 0 0 1 1-5.7ZM52.2 76a8.4 8.4 0 1 1 8-8.7 8.3 8.3 0 0 1-8 8.7Z" style={{fill: 'url(#opportunityTypeFill-16)'}} />
                <path id="Contour-46" d="M69.1 59.4s-4.4.2-6.3-1-1.3-7.2-1.3-7.2l-4.8-2-3 3.3a13.6 13.6 0 1 0 13.2 16.7l4.1-3.7ZM52.2 76.1a8.4 8.4 0 1 1 8-8.7 8.3 8.3 0 0 1-8 8.7Z" className="iconWhite" />
                <path id="Contour-47" d="m169.7 71.5 4.6-2.7a.5.5 0 0 0 .3-.6l-1.1-6.5a.5.5 0 0 0-.4-.4l-5.3-1.1a4 4 0 0 1-3-4.4l.6-5.3a.5.5 0 0 0-.2-.6l-5.8-3.2a.5.5 0 0 0-.6 0l-4.2 3.5a4 4 0 0 1-5.3-.3l-3.7-3.9a.6.6 0 0 0-.6-.1l-6 2.5a.5.5 0 0 0-.4.5v5.9a3.4 3.4 0 0 1-3 3.4l-5.9.6a.5.5 0 0 0-.4.3l-1.9 6.4a.5.5 0 0 0 .2.5l4.3 3.3a4 4 0 0 1 .9 5.3l-3 4.5a.5.5 0 0 0 0 .6l3.8 5.4a.5.5 0 0 0 .6.2l5.2-1.3a4 4 0 0 1 4.7 2.6l1.7 5.1a.5.5 0 0 0 .4.4l6.6.4a.5.5 0 0 0 .5-.4l2.3-4.8a4 4 0 0 1 5-2l5 1.8a.6.6 0 0 0 .5-.1l4.4-4.9a.5.5 0 0 0 0-.6l-2.3-4.8a4 4 0 0 1 1.5-5.2Zm-6.6-2.5a12.2 12.2 0 1 1-11.4-12.9A12.2 12.2 0 0 1 163 69Z" style={{fill: 'url(#opportunityTypeFill-17)'}} />
                <path id="Contour-48" d="m154 51-3 .3-5.4-5.3-7 3-.3 8.2-2.4 1.8-6.7.8-1.8 6.7 6 5 .2 1.7-3.8 6.7 3.9 5.4 8-1.4A17.6 17.6 0 1 0 154 51Zm-3.8 29.4a12.2 12.2 0 1 1 13-11.4 12.2 12.2 0 0 1-13 11.4Z" className="iconWhite" />
                <g id="Group-4">
                    <path id="Contour-49" d="M112.2 126H88.5a3.8 3.8 0 0 0-3.6 3.8v23.4a3.8 3.8 0 0 0 3.8 3.8H112a3.8 3.8 0 0 0 3.8-3.8v-23.4a3.8 3.8 0 0 0-3.7-3.8Zm-2 2v5.2a.2.2 0 0 1-.2.2h-2.5a.2.2 0 0 1-.2-.3V128Zm-8.4 0v5.2a.2.2 0 0 1-.2.2H99a.2.2 0 0 1-.2-.3V128Zm-8.4 0v5.2a.2.2 0 0 1-.2.2h-2.5a.2.2 0 0 1-.2-.3V128Zm-2.9 26.8v-5.1a.2.2 0 0 1 .2-.3h2.5a.2.2 0 0 1 .2.3v5.1Zm8.4 0v-5.1a.2.2 0 0 1 .2-.3h2.5a.2.2 0 0 1 .2.3v5.1Zm8.4 0v-5.1a.2.2 0 0 1 .2-.3h2.5a.2.2 0 0 1 .2.3v5.1Zm6.6-1.6a1.8 1.8 0 0 1-1.7 1.8v-5.3a2.2 2.2 0 0 0-2.2-2.3h-2.5a2.2 2.2 0 0 0-2.2 2.3v5.3h-1.5v-5.3a2.2 2.2 0 0 0-2.2-2.3H99a2.2 2.2 0 0 0-2.2 2.3v5.3h-1.5v-5.3a2.2 2.2 0 0 0-2.2-2.3h-2.5a2.2 2.2 0 0 0-2.2 2.3v5.3a1.8 1.8 0 0 1-1.6-1.8v-23.4a1.8 1.8 0 0 1 1.6-1.8v5.1a2.2 2.2 0 0 0 2.2 2.3h2.5a2.2 2.2 0 0 0 2.2-2.3V128H97v5.2a2.2 2.2 0 0 0 2.2 2.2h2.5a2.2 2.2 0 0 0 2.2-2.3V128h1.5v5.2a2.2 2.2 0 0 0 2.2 2.2h2.5a2.2 2.2 0 0 0 2.2-2.3V128a1.8 1.8 0 0 1 1.7 1.8Z" style={{fill: 'url(#opportunityTypeLine-16)'}} />
                    <path id="Contour-50" d="M126.2 111.5h-10.5V107a1.4 1.4 0 0 0-1.5-1.3h-5.1a1.4 1.4 0 0 0-1.5 1.3v4.4h-4V107a1.4 1.4 0 0 0-1.5-1.3h-1.6v-8.3h7.4a4 4 0 1 0 0-2H90.2a2 2 0 0 1-2-2V81.7a1 1 0 0 0-2 0v11.8a4 4 0 0 0 4 4h8.3v8.3H97a1.4 1.4 0 0 0-1.5 1.3v4.4h-4V107a1.4 1.4 0 0 0-1.5-1.3h-5.2a1.4 1.4 0 0 0-1.4 1.3v4.4h-9.6a3.8 3.8 0 0 0-3.8 3.8v10.2a1.5 1.5 0 0 0 1.5 1.5h4.3v4.6h-4.3A1.5 1.5 0 0 0 70 133v5.2a1.5 1.5 0 0 0 1.5 1.4h4.3v4.6h-4.3a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5h4.3v4.5h-4.3a1.5 1.5 0 0 0-1.5 1.5v9.5a3.8 3.8 0 0 0 3.8 3.8h9.6v4a1.4 1.4 0 0 0 1.4 1.3H90a1.4 1.4 0 0 0 1.5-1.3v-4h4v4a1.4 1.4 0 0 0 1.5 1.4h5.2a1.4 1.4 0 0 0 1.4-1.4v-4h4v4a1.4 1.4 0 0 0 1.5 1.4h5.1a1.4 1.4 0 0 0 1.5-1.4v-4h10.5a3.8 3.8 0 0 0 3.8-3.8v-9.5a1.5 1.5 0 0 0-1.5-1.4h-4.3v-4.6h4.3a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5h-4.3v-4.5h4.3a1.5 1.5 0 0 0 1.5-1.5V133a1.5 1.5 0 0 0-1.5-1.5h-4.3V127h4.3a1.5 1.5 0 0 0 1.5-1.5v-10.2a3.8 3.8 0 0 0-3.8-3.8Zm-14.6-17a2 2 0 1 1-1.9 2 2 2 0 0 1 2-2Zm-2 13.3h4.1v3.7h-4.1Zm-12 0h4v3.7h-4.1Zm-12.2 0h4v3.7h-4Zm4 67h-4v-3.3h4Zm12.2 0h-4.1v-3.3h4Zm12.1 0h-4.1v-3.3h4ZM128 125h-4.3a1.5 1.5 0 0 0-1.5 1.5v5.6a1.5 1.5 0 0 0 1.5 1.5h4.3v4h-4.3a1.5 1.5 0 0 0-1.5 1.6v5.6a1.5 1.5 0 0 0 1.5 1.5h4.3v3.9h-4.3a1.5 1.5 0 0 0-1.5 1.4v5.7a1.5 1.5 0 0 0 1.5 1.4h4.3v9a1.8 1.8 0 0 1-1.8 1.8H73.8a1.8 1.8 0 0 1-1.8-1.8v-9h4.3a1.5 1.5 0 0 0 1.5-1.4v-5.7a1.5 1.5 0 0 0-1.5-1.4H72v-4h4.3a1.5 1.5 0 0 0 1.5-1.4V139a1.5 1.5 0 0 0-1.5-1.4H72v-4.1h4.3a1.5 1.5 0 0 0 1.5-1.5v-5.6a1.5 1.5 0 0 0-1.5-1.5H72v-9.7a1.8 1.8 0 0 1 1.8-1.8h52.4a1.8 1.8 0 0 1 1.8 1.8Z" style={{fill: 'url(#opportunityTypeLine-17)'}} />
                    <path id="Contour-51" d="M122.5 115.1a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm0 5.9a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z" style={{fill: 'url(#opportunityTypeLine-18)'}} />
                    <path id="Contour-52" d="M77.7 115.1a4 4 0 1 0 3.9 4 4 4 0 0 0-4-4Zm0 5.9a2 2 0 1 1 1.9-2 2 2 0 0 1-2 2Z" style={{fill: 'url(#opportunityTypeLine-19)'}} />
                    <path id="Contour-53" d="M118.5 164.4a4 4 0 1 0 4-4 4 4 0 0 0-4 4Zm4-2a2 2 0 1 1-2 2 2 2 0 0 1 2-2Z" style={{fill: 'url(#opportunityTypeLine-20)'}} />
                    <path id="Contour-54" d="M77.5 160.5a4 4 0 1 0 4 3.9 4 4 0 0 0-4-4Zm0 5.8a2 2 0 1 1 2-1.9 2 2 0 0 1-2 2Z" style={{fill: 'url(#opportunityTypeLine-21)'}} />
                    <path id="Contour-55" d="m68.8 54.4 5.6 3.9a4.3 4.3 0 0 1 1.3 5.7l-3.4 6a1.4 1.4 0 0 0 .1 1.5l5.5 6.9a1.4 1.4 0 0 0 1.5.4l6.5-2a4.3 4.3 0 0 1 5.3 2.6l2.5 6.3a1.4 1.4 0 0 0 1.3 1h8.8a1.4 1.4 0 0 0 1.3-1l2.5-6.3a4.3 4.3 0 0 1 5.3-2.6l6.5 2a1.4 1.4 0 0 0 1.5-.4l5.5-6.9a1.4 1.4 0 0 0 .1-1.6l-3.4-5.9a4.3 4.3 0 0 1 1.3-5.7l5.7-3.9a1.4 1.4 0 0 0 .5-1.4l-2-8.6a1.4 1.4 0 0 0-1.1-1l-6.8-1a4.3 4.3 0 0 1-3.6-4.7l.5-6.8a1.4 1.4 0 0 0-.8-1.3l-8-3.9a1.4 1.4 0 0 0-1.5.3l-5 4.6a4.3 4.3 0 0 1-5.8 0l-5-4.6a1.4 1.4 0 0 0-1.6-.3L82 29.5a1.4 1.4 0 0 0-.8 1.4l.5 6.8a4.3 4.3 0 0 1-3.7 4.5l-6.7 1a1.4 1.4 0 0 0-1.2 1.1L68 53a1.4 1.4 0 0 0 .7 1.5Zm3.2-9.2 6.3-1a6.3 6.3 0 0 0 5.4-6.7l-.5-6.4 7.2-3.5 4.7 4.4a6.3 6.3 0 0 0 8.6 0l4.7-4.4 7.2 3.5-.5 6.4a6.3 6.3 0 0 0 5.4 6.7l6.3 1 1.8 7.8-5.3 3.6a6.3 6.3 0 0 0-1.9 8.4l3.2 5.6-5 6.2-6.1-1.9a6.3 6.3 0 0 0-7.8 3.7l-2.3 6h-8l-2.4-6a6.3 6.3 0 0 0-7.7-3.7l-6.1 2-5-6.3 3.2-5.6a6.3 6.3 0 0 0-1.9-8.4L70.2 53Z" style={{fill: 'url(#opportunityTypeLine-22)'}} />
                    <path id="Contour-56" d="M99.4 70.3A14.2 14.2 0 1 0 85.2 56a14.2 14.2 0 0 0 14.2 14.3Zm0-26.4A12.2 12.2 0 1 1 87.2 56a12.2 12.2 0 0 1 12.2-12.3Z" style={{fill: 'url(#opportunityTypeLine-23)'}} />
                    <path id="Contour-57" d="M99.4 73.6a17.6 17.6 0 1 0-17.5-17.5 17.6 17.6 0 0 0 17.5 17.5Zm0-33.1A15.6 15.6 0 1 1 83.9 56a15.6 15.6 0 0 1 15.5-15.5Z" style={{fill: 'url(#opportunityTypeLine-24)'}} />
                    <path id="Contour-58" d="M89.4 38.3a3.7 3.7 0 1 0-3.8-3.7 3.7 3.7 0 0 0 3.8 3.7Zm0-5.4a1.7 1.7 0 1 1-1.8 1.7 1.7 1.7 0 0 1 1.8-1.7Z" style={{fill: 'url(#opportunityTypeLine-25)'}} />
                    <path id="Contour-59" d="M109.5 38.3a3.7 3.7 0 1 0-3.8-3.7 3.7 3.7 0 0 0 3.8 3.7Zm0-5.4a1.7 1.7 0 1 1-1.8 1.7 1.7 1.7 0 0 1 1.8-1.7Z" style={{fill: 'url(#opportunityTypeLine-26)'}} />
                    <path id="Contour-60" d="M115.5 73.3a3.7 3.7 0 0 0 2.6 1.1 3.7 3.7 0 0 0 2.6-6.3 3.8 3.8 0 0 0-5.2 0 3.7 3.7 0 0 0 0 5.3Zm1.4-3.8a1.7 1.7 0 0 1 2.4 2.4 1.7 1.7 0 0 1-2.4-2.4Z" style={{fill: 'url(#opportunityTypeLine-27)'}} />
                    <path id="Contour-61" d="M96.8 76.6a3.7 3.7 0 0 0 0 5.3 3.7 3.7 0 0 0 5.2-5.3 3.8 3.8 0 0 0-5.2 0Zm3.8 3.8a1.7 1.7 0 0 1-3-1.2 1.7 1.7 0 0 1 1.8-1.7 1.7 1.7 0 0 1 1.2 3Z" style={{fill: 'url(#opportunityTypeLine-28)'}} />
                    <path id="Contour-62" d="M122.7 54a3.7 3.7 0 0 0 2.6-6.4 3.8 3.8 0 0 0-5.2 0 3.7 3.7 0 0 0 0 5.3 3.7 3.7 0 0 0 2.6 1.1Zm-1.2-5a1.7 1.7 0 0 1 1.2-.5 1.7 1.7 0 1 1-1.7 1.7 1.7 1.7 0 0 1 .5-1.2Z" style={{fill: 'url(#opportunityTypeLine-29)'}} />
                    <path id="Contour-63" d="M79.5 52a3.7 3.7 0 0 0-6.6-3.5 3.7 3.7 0 0 0 3.3 5.5 3.8 3.8 0 0 0 3.3-2Zm-4-.2a1.7 1.7 0 0 1-.9-1 1.7 1.7 0 0 1 .1-1.3 1.7 1.7 0 0 1 1-.9 1.5 1.5 0 0 1 .5 0 1.7 1.7 0 0 1 .8.1 1.8 1.8 0 0 1 .9 1 1.7 1.7 0 0 1-.1 1.3 1.7 1.7 0 0 1-2.4.8Z" style={{fill: 'url(#opportunityTypeLine-30)'}} />
                    <path id="Contour-64" d="M78 73.3a3.7 3.7 0 0 0 2.7 1.1 3.7 3.7 0 0 0 2.6-6.3 3.8 3.8 0 0 0-5.2 0 3.7 3.7 0 0 0 0 5.3Zm1.5-3.8a1.7 1.7 0 1 1 2.4 2.4 1.7 1.7 0 0 1-2.4-2.4Z" style={{fill: 'url(#opportunityTypeLine-31)'}} />
                    <path id="Contour-65" d="m175.6 68-1.1-6.4a1.5 1.5 0 0 0-1.2-1.3l-5.3-1.1a3 3 0 0 1-2.3-3.2l.7-5.3a1.5 1.5 0 0 0-.8-1.6L160 46a1.5 1.5 0 0 0-1.7.2l-4.2 3.4a3 3 0 0 1-4-.3l-3.7-3.9a1.5 1.5 0 0 0-1.7-.3l-6.1 2.5a1.5 1.5 0 0 0-1 1.4l.1 6a2.4 2.4 0 0 1-2.1 2.3l-6 .6a1.5 1.5 0 0 0-1.2 1l-1.9 6.4a1.5 1.5 0 0 0 .6 1.6l4.2 3.3a3 3 0 0 1 .7 4l-3 4.5a1.5 1.5 0 0 0 0 1.7l3.8 5.4a1.5 1.5 0 0 0 1.6.6l5.4-1.3a3 3 0 0 1 3.4 2l1.7 5a1.5 1.5 0 0 0 1.4 1l6.6.4a1.5 1.5 0 0 0 1.4-.9l2.3-4.9a3 3 0 0 1 3.7-1.5l5 2a1.5 1.5 0 0 0 1.7-.5l4.4-4.9a1.5 1.5 0 0 0 .2-1.7l-2.4-4.8a3 3 0 0 1 1.1-3.8l4.6-2.8a1.5 1.5 0 0 0 .8-1.5Zm-6.4 2.7a5 5 0 0 0-1.9 6.4l2.3 4.6-4 4.4-4.8-1.8a5 5 0 0 0-6.2 2.6l-2 4.6-6-.4-1.6-4.8a5 5 0 0 0-5.8-3.3l-5 1.2-3.4-4.8 2.8-4.3a5 5 0 0 0-1.1-6.5l-4-3.1 1.6-5.7 5.6-.6a4.4 4.4 0 0 0 4-4.4l-.1-5.6L145 47l3.5 3.6a5 5 0 0 0 6.7.4l4-3.2 5.1 2.9-.7 5a5 5 0 0 0 3.9 5.4l5 1 1 6Z" style={{fill: 'url(#opportunityTypeLine-32)'}} />
                    <path id="Contour-66" d="M151.7 55.1a13.2 13.2 0 1 0-1.5 26.3h.8A13.2 13.2 0 0 0 164 69a13.2 13.2 0 0 0-12.4-13.9Zm10.4 13.8A11.2 11.2 0 1 1 151 57.1h.7a11.2 11.2 0 0 1 10.5 11.8Z" style={{fill: 'url(#opportunityTypeLine-33)'}} />
                    <path id="Contour-67" d="M67.7 72.8a3.3 3.3 0 0 1 .8-4.3l2.6-2a1.5 1.5 0 0 0 .6-1.7l-1.5-5a1.5 1.5 0 0 0-1.3-1l-3.3-.4a3.3 3.3 0 0 1-2.9-3.3v-3.3a1.5 1.5 0 0 0-.8-1.5l-4.8-2a1.5 1.5 0 0 0-1.7.4L53 51a3.3 3.3 0 0 1-4.4.2L46 49.1a1.5 1.5 0 0 0-1.7-.2L40 51.4A1.5 1.5 0 0 0 39 53l.4 3.3a3.3 3.3 0 0 1-2.6 3.6l-3.2.7a1.5 1.5 0 0 0-1.3 1.2l-.9 5.1a1.5 1.5 0 0 0 .8 1.6L35 70a3.3 3.3 0 0 1 1.2 4.3l-1.5 3A1.5 1.5 0 0 0 35 79l3.4 3.9a1.5 1.5 0 0 0 1.7.4l3.1-1.1a3.3 3.3 0 0 1 4 1.7l1.4 3a1.5 1.5 0 0 0 1.4.9h.1l5.2-.3a1.5 1.5 0 0 0 1.4-1l1-3a3.3 3.3 0 0 1 4-2.2l3.1.8a1.5 1.5 0 0 0 1.6-.6l3-4.2a1.5 1.5 0 0 0 0-1.7ZM65 80.1l-2.9-.7a5.3 5.3 0 0 0-6.3 3.4l-1 2.8-4.5.2-1.2-2.7a5.3 5.3 0 0 0-4.8-3.1 5.2 5.2 0 0 0-1.8.3l-2.8 1-3-3.4 1.4-2.7a5.3 5.3 0 0 0-2-6.8l-2.5-1.6.8-4.5 3-.6a5.3 5.3 0 0 0 4-5.7l-.3-3 4-2.2 2.3 2a5.3 5.3 0 0 0 7.1-.4l2-2.1 4.3 1.7v3a5.3 5.3 0 0 0 4.6 5.3l3 .4 1.3 4.3-2.4 1.8a5.3 5.3 0 0 0-1.2 7l1.6 2.6Z" style={{fill: 'url(#opportunityTypeLine-34)'}} />
                    <path id="Contour-68" d="M58 60.9a9.4 9.4 0 1 0-6.2 16.3h.5A9.4 9.4 0 0 0 58 60.9ZM52.3 75a7.4 7.4 0 1 1-.8-14.7h.4a7.4 7.4 0 0 1 .4 14.8Z" style={{fill: 'url(#opportunityTypeLine-35)'}} />
                    <path id="Contour-69" d="M136.5 136.2a4 4 0 1 0 0-2H133a1 1 0 0 0 0 2h3.4Zm3.8-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2Z" style={{fill: 'url(#opportunityTypeLine-36)'}} />
                    <path id="Contour-70" d="M148.6 98.3a1 1 0 0 0-1 1v46.1a2 2 0 0 1-2 2h-12.4a1 1 0 0 0 0 2h12.4a4 4 0 0 0 4-4V99.3a1 1 0 0 0-1-1Z" style={{fill: 'url(#opportunityTypeLine-37)'}} />
                    <path id="Contour-71" d="M67 147.4h-5a4 4 0 1 0 0 2h5a1 1 0 0 0 0-2Zm-8.8 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z" style={{fill: 'url(#opportunityTypeLine-38)'}} />
                    <path id="Contour-72" d="M67 134.2H56.2a2 2 0 0 1-2-2V92.8a1 1 0 0 0-2 0v39.4a4 4 0 0 0 4 4h10.6a1 1 0 0 0 0-2Z" style={{fill: 'url(#opportunityTypeLine-39)'}} />
                </g>
            </g>
        </svg>

    )
}
export default OpportunityType