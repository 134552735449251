import Campaign, { CreateCampaign, UpdateCampaign } from "./Campaign";
import {API, PerformJSONRPCRequest} from "../../../api/jsonrpc";
import Dashboard from "../../../bitool/dashboard/Dashboard";

export interface FindManyRequest {
    clientName: string;
}

export interface FindManyResponse {
    campaigns: Campaign[];
}

export interface FindOneRequest {
    id: string;
    clientName: string;
}

export interface FindOneResponse {
    campaign: Campaign;
}

export interface UpdateOneRequest {
    campaign: UpdateCampaign;
    clientName: string;
}

export interface UpdateOneResponse {
    success: boolean;
}

export interface DeleteOneRequest {
    id: string;
    clientName: string;
}

export interface DeleteOneResponse {
    success: boolean;
}

export interface CreateOneRequest {
    campaign: CreateCampaign;
    clientName: string
}

export interface CreateOneResponse {
    id: string
}

export interface CalculateCampaignValueRequest {
    campaignID: string;
    clientName: string;
}

export interface CalculateCampaignValueResponse {
    value: number;
}

const Store = {
    serviceProvider: 'Campaign-Store',
    async FindMany(request: FindManyRequest): Promise<FindManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindMany`,
            request: [{
                "clientName": request.clientName
            }]
        });
        if (response.campaigns && response.campaigns.length > 0) {
            return {
                campaigns: response.campaigns.map((mem) => (new Campaign(mem))),
            };
        }else {
            return {
                campaigns: [],
            };
        }

    },
    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                "id": request.id,
                "clientName": request.clientName
            }]
        });
        console.log(response)
        return {
            campaign: new Campaign(response.campaign),
        };
    },
    async UpdateOne(request: UpdateOneRequest): Promise<UpdateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateOne`,
            request: [{
                "campaign": request.campaign,
                "clientName": request.clientName
            }]
        });
        return response
    },
    async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOne`,
            request: [{
                "id": request.id,
                "clientName": request.clientName
            }]
        });
        return response
    },
    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                "campaign": request.campaign,
                "clientName": request.clientName
            }]
        });
        return response
    },
    async CalculateCampaignValue(request: CalculateCampaignValueRequest): Promise<CalculateCampaignValueResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CalculateCampaignValue`,
            request: [{
                "campaignID": request.campaignID,
                "clientName": request.clientName
            }]
        });
        return response
    }
};

export default Store;