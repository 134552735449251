/* eslint-disable */

import type { FC, ReactNode } from 'react';
import {useEffect, useState} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from '../../pages/authentication/Login';
import { useMainContext } from '../../contexts/MainContext';
import {KanbanStore} from "../../cog/keystone/card";
import AuthorizationRequired from "./AuthorizationRequired";
import {AdminStore} from "../../cog/adminManagement";
import {CircularProgress} from "@mui/material";
import HomeIssueSkeleton from "../../pages/app/keystone/summary/Skeleton";
import {KeystoneAccessResponse} from "../../cog/adminManagement/Store";

interface BiGuardProps {
    children: ReactNode;
}

const KanbanGuard: FC<BiGuardProps> = (props) => {
    const { children } = props;
    const ctx = useMainContext();
    const [authorised, setAuthorised] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    let url = window.location.pathname.toString()

    const checkAccess = async (): Promise<KeystoneAccessResponse> => {
        try {
            return AdminStore.CheckKeystoneAccess(
                {
                    user_id: ctx.user.id,
                    org_id: ctx.activeOrganization.id
                }
            )
        } catch (e) {
            console.error(`${e}`);
        }
    }
    //TODO: Find better methods of checking which page is being accessed
    useEffect(() => {
        checkAccess()
            .then((r)=> {
                if (r && r.admin_permission && r.user_permission) {
                    setAuthorised(true)
                    setIsLoading(false)
                }
                else if (r && url.endsWith('reporting') && r.admin_permission) {
                    setAuthorised(true)
                    setIsLoading(false)
                }
                else if (r && r.user_permission && (url.endsWith('home') || url.endsWith('opportunities') || url.endsWith('leaderboard'))) {
                    setAuthorised(true)
                    setIsLoading(false)
                } else {
                    setAuthorised(false)
                    setIsLoading(false)}
            })
    }, [])


    if (!isLoading) {
        if (authorised) {
            return <>{children}</>
        }
    else return <AuthorizationRequired/>} else {
        return <HomeIssueSkeleton/>
    }

};

KanbanGuard.propTypes = {
    children: PropTypes.node
};

export default KanbanGuard;
