export default class Template {
  public name: string = '';
  public value: string = '';

  constructor(template?: Template) {
    if (!template) {
      return;
    }
    this.name = template.name;
    this.value = template.value;
  }
}