import {API, PerformJSONRPCRequest} from "../api/jsonrpc";


interface sendFeedbackRequest {
    Sender: string
    Message: string
    ImageB64:  string
}

interface sendFeedbackResponse{

    response: string

}

const Store = {
    async SendFeedback(request: sendFeedbackRequest) : Promise<sendFeedbackResponse>{

        const response = await PerformJSONRPCRequest({

            api: API.Authorised,
            method: `FeedbackSystem-Store.SendFeedback`,
            request: [{
                "sender": request.Sender,
                "message": request.Message,
                "image": request.ImageB64,
            }]
        })
        console.log("Chat Store response", response)
        return response
    }



}
export default Store;