import React, {FC, useState} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress, TextField, Avatar, Typography, Autocomplete
} from "@mui/material";
import { GoalAssigneeStore } from "../../../../../../cog/keystone/goals";
import { useSnackbar } from "notistack";
import { useKeystoneContext } from "../../../../../../contexts/KeystoneContext";
import { useMainContext } from "../../../../../../contexts/MainContext";
import { Member } from "../../../../../../cog/keystone/member";

interface AssignUsersDialogProps {
  goalIDs: string[];
  handleDialogClose: () => void
}

const AssignUsersDialog: FC<AssignUsersDialogProps> = ({goalIDs, handleDialogClose}) => {
  const {enqueueSnackbar} = useSnackbar();
  const keystoneCtx = useKeystoneContext();
  const mainCtx = useMainContext();

  const [isDisabled, setIsDisabled] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<Member[]>([])

  const formatUserIDs = (users: Member[]) => {
    const userIDs: string[] = []

    if (users.length > 0) {
      users.forEach(assignee => {
        userIDs.push(assignee.id)
      })
    }

    return userIDs
  }

  const assignUsers = async () => {
    const userIDs = formatUserIDs(selectedUsers)

    setIsDisabled(true)
    const response = await GoalAssigneeStore.InsertMultipleAssignees({userIDs: userIDs, goalIDs: goalIDs, clientName: mainCtx.activeOrganization.name})
    if (response) {
      setIsDisabled(false)
      enqueueSnackbar('Users Assigned', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
    } else {
      setIsDisabled(false)
      enqueueSnackbar('Failed to Assign Users', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
    handleDialogClose();
    keystoneCtx.setFlipSwitchForGoals(!keystoneCtx.flipSwitchForGoals)
  }

  return (
    <Box sx={{ m: 2, minHeight: "15vh" }}>
      <DialogTitle>Assign Users</DialogTitle>
      <DialogContent>
        <Grid container display={"grid"} gridTemplateColumns={"1fr"} gridAutoFlow={"row"} gridAutoRows={"auto"} gap={1}>
          <Box>Select Users to Assign</Box>
          <Box>
            <Autocomplete
              multiple={true}
              id="assignMembers"
              options={mainCtx.members != null ? mainCtx.members : []}
              // disableCloseOnSelect
              onChange={(e, v) => {
                setSelectedUsers([...v as Member[]])
              }}
              getOptionLabel={(option: Member) => option.displayName}
              value={selectedUsers.map((a) => a)}
              renderInput={(params) => <TextField {...params} label="Users"/>}
              renderOption={(props, option) => (
                <Box component="li"
                     sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                  <Avatar alt={option.displayName} src={option.avatar}/>
                  <Typography
                    component="div"
                    sx={{margin: "auto"}}
                  >
                    {option.displayName}
                  </Typography>
                </Box>
              )}
            />
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant={"text"} >
          <Button
            onClick={assignUsers}
            disabled={isDisabled}
          >
            {isDisabled ? <CircularProgress size={20}/> : "Confirm"}
          </Button>
          <Button
            onClick={() => handleDialogClose()}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Box>
  );
}

export default AssignUsersDialog;