import type {FC} from 'react';
import React, {memo} from "react";
import BasicAutoCompleteFilter from "./widgets/basicWidget/filters/BasicAutoCompleteFilter";
import BasicApexChart from "./widgets/basicWidget/charts/BasicApexChart";
import BasicWidgetGroup from "./widgets/basicWidget/group/BasicWidgetGroup";
import BasicDateRangeWidget from "./widgets/basicWidget/filters/BasicDateRangeWidget";
import BasicTable from "./widgets/basicWidget/tables/BasicTable";
import BasicQuickStats from "./widgets/basicWidget/charts/BasicQuickStats";
import BasicLabel from "./widgets/basicWidget/misc/BasicLabel";
import BasicDateWidget from "./widgets/basicWidget/filters/BasicDateWidget";
import BasicImage from "./widgets/basicWidget/misc/BasicImage";
import BasicDateDisplay from "./widgets/basicWidget/charts/BasicDateDisplay";
import BasicWidgetGroupStepper from "./widgets/basicWidget/group/BasicWidgetGroupStepper";

// import BasicVariable from "./widgets/old/basicVariable/BasicVariable";
// import WidgetExample from "./widgets/Example";

import Widget, {GridLocation, WidgetGroupItem} from "../../../../cog/bitool/widget/Widget";

import { useErrorBoundary } from "use-error-boundary"
import Button from "@mui/material/Button";
import {Box} from "@mui/material";
import BasicLineDivider from "./widgets/basicWidget/misc/BasicLineDivider";
import BasicVerticalLineDivider from "./widgets/basicWidget/misc/BasicVerticalLineDivider";
import BasicWidgetGroupStepperExport from "./widgets/basicWidget/group/BasicWidgetGroupStepperExport";

interface WidgetGroupItemWithWidget extends WidgetGroupItem {
    widget?: Widget;
}

export interface WidgetProps {
    widget?: Widget;
    widgetData?: {};
    widgetColumns?: [];
    widgetVariables?: {};
    allWidgetVariables?: {};
    dashVariables?: {};

    items?: Record<string, WidgetGroupItemWithWidget>;

    height?: number;

    index?: number;
    maxIndex?: number;
    maxItemsIndex?: number;
    nextStep?: () => void;
    resetSteps?: () => void;

    setVariableOutputs?: (data: any) => void;

    report?: boolean;
}

const WidgetSwitcherInst: FC<WidgetProps> = memo((widgetProps) => {
    if (!widgetProps.widget) {
        return <div> Widget not valid </div>
    }
    return {
        'BasicAutoCompleteFilter': <BasicAutoCompleteFilter {...widgetProps} />,
        'BasicWidgetGroupStepper': !widgetProps.report ? <BasicWidgetGroupStepper {...widgetProps} /> :
            <BasicWidgetGroupStepperExport {...widgetProps}/>,
        'BasicApexChart': <BasicApexChart {...widgetProps} />,
        'BasicWidgetGroup': <BasicWidgetGroup {...widgetProps} />,
        'BasicTable': <BasicTable {...widgetProps} />,
        'BasicQuickStats': <BasicQuickStats {...widgetProps} />,
        'BasicLabel': <BasicLabel {...widgetProps} />,
        'BasicDateRange': <BasicDateRangeWidget {...widgetProps} />,
        'BasicDate': <BasicDateWidget {...widgetProps} />,
        'BasicImage': <BasicImage {...widgetProps} />,
        'BasicDateDisplay': <BasicDateDisplay {...widgetProps} />,
        'BasicLineDivider': <BasicLineDivider {...widgetProps} />,
        'BasicVerticalLineDivider': <BasicVerticalLineDivider {...widgetProps} />
    }[widgetProps.widget.arguments.type] || <div> Widget type not supported </div>
})

const WidgetSwitcher: FC<WidgetProps> = (props) => {
    const { ErrorBoundary, didCatch, error, reset } = useErrorBoundary()


    return (
        <>
            {didCatch ? (
                <Box>
                    <p>An error has been caught: {error.message}</p>
                    <Button onClick={reset} variant="contained">Reset</Button>
                </Box>
                ) : (
                <ErrorBoundary>
                    <WidgetSwitcherInst {...props}/>
                </ErrorBoundary>
            )}
        </>
    )
}

export default WidgetSwitcher;
