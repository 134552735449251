import React, {useLayoutEffect, useRef} from 'react';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {FixedSizeList} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import KanbanCard from "../card/KanbanCard";
import {Box} from "@mui/material";
import {Member} from "../../../../../cog/keystone/member";
import {KanbanColumnObj} from "../../../../../cog/keystone/card";
import {GetComparator, StableSort} from "../../../../../utils/stableSort";
import {useTheme} from "@mui/material/styles";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";

interface KanbanItemListProps {
    columnId: string;
    column: KanbanColumnObj;
    index: number;
    members: Member[]
    org: string
    selectCards: boolean
    selectedCardsIDs: any[]
    updateSelectedCards: (v: string, vName: string) => void
    allLabels:OppLabel[]
}

// KanbanItemList: Creates virtualized lists to handle the cards in the columns
const KanbanItemList = ({columnId, column, index, members, org, selectCards, selectedCardsIDs, updateSelectedCards, allLabels}: KanbanItemListProps) => {
    const listRef = useRef();
    let counter = 0

    function printProps() {
        console.log("\tcolumnId", columnId)
        console.log("\tcolumn", column)
        console.log("\tindex", index)
        console.log("\tmembers", members)
    }

    useLayoutEffect(() => {
        // @ts-ignore
        column.cards = StableSort(column.cards, GetComparator("desc", "mValue"))
        const list = listRef.current;
        if (list) {
            // @ts-ignore
            list.scrollTo(1);
        }
        // printProps()
    }, [index]);

    // KanbanRow: moved to over here, because it was the only way I could figure out how to pass it my own values from here
    const KanbanRow = function Row(props) {
        // console.log("\t\tRow: ", props)
        // @ts-ignore
        const {data: cards, index, style} = props;
        const card = cards[index];

        useLayoutEffect(() => {
            // Need to do anything here?
            // console.log("\t\tRow: ", props)
            // console.log("\t\tcard: ", card)
        })

        const disabled = cardStatus => {
            if (cardStatus == 'QA' || cardStatus == 'Archive') {
                return true
            } else {
                return false
            }
        }

        // We are rendering an extra item for the placeholder
        if (!card) {
            return null;
        }

        return (
            <Draggable draggableId={card.id} index={index} key={card.id}
                       isDragDisabled={selectCards == false ? disabled(card.status) : true}>
                {provided => <KanbanCard provided={provided}
                                         card={card}
                                         style={style}
                                         members={members}
                                         selectCards={selectCards}
                                         selectedCardsIDs={selectedCardsIDs}
                                         updateSelectedCards={updateSelectedCards}
                                         allLabels={allLabels}/>}
                {/*    Members don't do anything */}
            </Draggable>
        );
    };

    return (
        <Droppable
            droppableId={columnId}
            mode="virtual"
            // @ts-ignore
            renderClone={(provided, snapshot, rubric, style) => (
                <KanbanCard
                    provided={provided}
                    card={column.cards[rubric.source.index]}
                    style={style}
                    dragging={snapshot.isDragging}
                    members={members}
                    selectCards={selectCards}
                    selectedCardsIDs={selectedCardsIDs}
                    updateSelectedCards={updateSelectedCards}
                    allLabels={allLabels}
                />
            )}
        >
            {(provided, snapshot) => {
                const itemCount = snapshot.isUsingPlaceholder
                    ? column.cards && column.cards.length + 1
                    : column.cards && column.cards.length;

                return (
                    <Box
                        ref={provided.innerRef}
                        sx={{
                            flexGrow: 1,
                            minHeight: 100,
                            overflowY: 'auto',
                            px: 2,
                            py: 1,
                            height: '80vh',
                            "& ::-webkit-scrollbar": {
                                width: 10
                            },
                            "& ::-webkit-scrollbar-track": {
                                backgroundColor: useTheme().palette.background.paper,
                            },
                            "& ::-webkit-scrollbar-thumb": {
                                backgroundColor: useTheme().palette.text.secondary,
                                borderRadius: 2,
                            }
                        }}
                    >
                        {column.cards != null &&
                            <AutoSizer>
                                {({height, width}) => (
                                    <FixedSizeList
                                        height={height}
                                        itemCount={itemCount}
                                        itemSize={250}
                                        width={width}
                                        outerRef={provided.innerRef}
                                        itemData={column.cards}
                                        className="task-list"
                                        ref={listRef}
                                    >
                                        {KanbanRow}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        }

                    </Box>
                );
            }}
        </Droppable>
    );
};

export default KanbanItemList;
