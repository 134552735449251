import * as React from "react";
import {FC, useEffect, useState} from "react";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {ExcludeStore} from "../../../../cog/keystone/exclude";
import {Autocomplete, Box, Dialog, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import {useMainContext} from "../../../../contexts/MainContext";
import {ColumnMappingDictionaryWithData} from "./components/ColumnMappingDictionaryWithData";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import ConfirmActionModal from "./ConfirmActionModal";
import {SendOutlined} from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";

export interface ExcludeFilterProps {

}

let dialogBody = () => {
    return (
        <div>
        </div>
    )
};

// ExcludeFilter : Interface for excluding items currently selected in the keystoneContext.
const ExcludeFilter: FC = (props) => {
    const keystoneCtx = useKeystoneContext()
    const mainCtx = useMainContext();
    const {enqueueSnackbar} = useSnackbar();

    const [colMapWithData, setColMapWithData] = useState<ColumnMappingDictionaryWithData>(null)
    const [colMapWithDataReference, setColMapWithDataReference] = useState<ColumnMappingDictionaryWithData>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [disableIgnoreButton, setDisableIgnoreButton] = useState<boolean>(false)
    const [confirmActionOpen, setConfirmActionOpen] = React.useState(false);


    useEffect(() => {
        // Promise.resolve().then(() => AddToIgnore())
    }, []);

    useEffect(() => {
        if (keystoneCtx.colMap != null && loading == true) {
            let tempMap = new ColumnMappingDictionaryWithData()
            let tempMapRef = new ColumnMappingDictionaryWithData()
            tempMap.PopulateWithColMap(keystoneCtx.colMap)
            tempMapRef.PopulateWithColMap(keystoneCtx.colMap)
            setColMapWithData(tempMap)
            setColMapWithDataReference(tempMapRef)
        }
    }, [keystoneCtx.colMap]);

    useEffect(() => {
        if (colMapWithData != null && loading == true) {
            populateColMapDataReference()
        }
    }, [colMapWithData]);

    // AddToIgnore : Adds the colMapWithData to the exclude stuff
    async function AddToIgnore() {
        keystoneCtx.setLoadingBar(true)
        setDisableIgnoreButton(true)
        setConfirmActionOpen(false)
        keystoneCtx.handleExcludeFilterClose()
        // todo : this default cognizance for the client might not be the way
        let response = await ExcludeStore.AddToExclude({
            client: mainCtx.activeOrganization != null ? mainCtx.activeOrganization.name : "COGNIZANCE",
            filter: colMapWithData != null ? colMapWithData.GimmeJsonString() : "",
            userID: mainCtx.user.id
        })
        enqueueSnackbar('Exclude Filter Updated', {
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
            },
            variant: 'success',
            autoHideDuration: 2500,
            preventDuplicate: true
        });
        keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
    }

    // handleConfirmDialogue : for excluding multiple issues confirmation
    const handleConfirmDialogue = () => {
        setConfirmActionOpen(true)
        dialogBody = () => {
            return (
                <ConfirmActionModal
                    cancelAction={handleConfirmClose}
                    confirmAction={AddToIgnore}
                    confirmationWarningMessage={"Warning, This will exclude all items that match at least one value under each header, for all users, continue?"}

                />
            );
        }
        // setExcDialogOpen(true);
    }

    // handleConfirmClose : for closing confirmation modal
    const handleConfirmClose = () => {
        setConfirmActionOpen(false);
        enqueueSnackbar('Action Cancelled', {
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'top'
            },
            variant: 'warning',
            autoHideDuration: 2500,
            preventDuplicate: true
        });
    };

    const discardChanges = () => {
        enqueueSnackbar('Action Cancelled', {
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'top'
            },
            variant: 'warning',
            autoHideDuration: 2500,
            preventDuplicate: true
        });
        keystoneCtx.handleExcludeFilterClose()
    }

    // populateColMapDataReference : This one fetches the cards that are selected from keystoneContext,
    // and then adds the exclude-able fields to the colMapWithDataReference, for use as options
    const populateColMapDataReference = () => {
        let tempRef = colMapWithDataReference
        if (keystoneCtx.selectedCardsID.length > 0 && keystoneCtx.selectCards) {
            let tempRow: any[] = []
            for (let i = 0; i < keystoneCtx.selectedCardsID.length; i++) {
                let tempVal = keystoneCtx.myRows.find(o => o.id == keystoneCtx.selectedCardsID[i])
                tempRow.push(tempVal)
            }
            tempRef.PopulateWithOppRows(tempRow)
        } else {
            tempRef.PopulateWithOppRows(keystoneCtx.myRows)
        }
        setColMapWithDataReference(tempRef)
        setLoading(false)
    }

    const AutoCompStyle = {
        width: "30vw"
    }

    const limitTags = 2

    // todo : maybe make this less hard coded and more procedural? Can at least make a generic FC for the autocompletes
    return <>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            p: '5px',
            justifyContent: "space-between"
        }}>
            <Box>
                <Typography variant={"h4"}>Exclude Filter</Typography>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Tooltip title={"Save Exclude Filter"}>
                    <IconButton size="large" color="primary" onClick={handleConfirmDialogue}>
                        <SendOutlined/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Discard Changes"}>
                    <IconButton size="large" color="primary" onClick={discardChanges}>
                        <ClearIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: 1,
            overflowY: "auto",
            maxHeight: "85vh",
            "&::-webkit-scrollbar": {
                width: "8px",
                height: "10px"
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: '15px', background: '#c2c9d2'
            }
        }}>
            {
                colMapWithData != null
                && colMapWithDataReference != null
                && loading == false
                && colMapWithDataReference.ValueList.map((HeaderValue, index) => {
                    if (colMapWithData[HeaderValue] != "") {
                        return <Box
                            key={HeaderValue}
                        >
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                limitTags={limitTags}
                                sx={AutoCompStyle}
                                // id="tags-outlined"
                                options={colMapWithDataReference[HeaderValue + "_Data"]}
                                getOptionLabel={(option) => option}
                                // defaultValue={[top100Films[13]]}
                                value={colMapWithData[HeaderValue + "_Data"]}
                                filterSelectedOptions
                                onChange={(_, value) => {
                                    let tempData = new ColumnMappingDictionaryWithData(colMapWithData)
                                    tempData[HeaderValue + "_Data"] = value
                                    setColMapWithData(tempData)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`${colMapWithData[HeaderValue]}`}
                                        placeholder="Select Filters"
                                    />
                                )}
                            />
                        </Box>
                    }
                })
            }

            <Box>
                <Button onClick={handleConfirmDialogue} variant={"outlined"} disabled={disableIgnoreButton}>Save Exclude
                    Filter</Button>
            </Box>
            <Dialog
                //disableEnforceFocus required to open chat if Dialog opened first
                disableEnforceFocus
                open={confirmActionOpen}
                onClose={handleConfirmClose}
                aria-labelledby="card-exclude-dialog"
                fullWidth
                maxWidth="md"
            >
                {dialogBody()}
            </Dialog>

        </Box>
    </>
}

export default ExcludeFilter;