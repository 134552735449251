import React, { FC, useContext, useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton, Snackbar,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {useMainContext} from "../../../../contexts/MainContext";
import ChatMessage from "./ChatMessage";
import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from '@mui/icons-material/Send';
import html2canvas from "html2canvas";
import {ChatStore} from "../../../../cog/chat";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CogLogo from "../../../../logo.svg"
import KeystoneLogo from "../../../../KsBulletWhite.svg"
import KeystoneLogoDark from "../../../../ksBulletDark.png"
import Paper from "@mui/material/Paper";
import orgChecker from "../../../../utils/orgChecker";
import { THEMES } from "../../../../constants";
import SettingsContext from "../../../../contexts/SettingsContext";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import excludeFilter from "../../keystone/exlude/ExcludeFilter";

interface ChatMessagesSide{
    id:number,
    messageGroup:string[],
    side:string,
    avatar:string,
}

interface CogChat{
    handleClose : () => void
}

const CogChat :FC<CogChat> = (props) => {
    const mainCtx = useMainContext();
    const settingsCtx = useContext(SettingsContext);
    const bottomRef = useRef(null);
    const [userMessage, setUserMessage] = React.useState<string>("")
    const [serverReply, setServerReply] = React.useState<string>("")
    const [screenshot, setScreenshot] = useState<string>("")
    const [messages, setMessages] = useState<ChatMessagesSide[]>([])
    const [toggleMsgRefresh, setToggleMsgRefresh] = useState<boolean>(false)

    // const getOrgName = () =>{
    //     if(mainCtx.isLoading){
    //         return ""
    //     }
    //     return mainCtx.activeOrganization.name
    // }

    const sendMessage = async () => {
        //right = user
        updateMessages('right', userMessage,mainCtx.user.avatar)
        setUserMessage('')
        ChatStore.SendFeedback({Sender:mainCtx.user.email,Message:userMessage, ImageB64: "" }).then((r)=>{setServerReply(r.response)})
    }

    const getAvatar = (org: string, theme: string) => {
        const keyLogo = theme !== THEMES.LIGHT ? KeystoneLogo : KeystoneLogoDark
        return orgChecker(org) ? CogLogo : keyLogo
    }

    const handleDownloadCapture = async ( ) => {

        html2canvas(document.body, {backgroundColor: 'rgba(0,0,0,0)'})
            .then(canvas => {
                const imageB64 = canvas.toDataURL().split(';base64,')[1];
                setScreenshot(imageB64)
                ChatStore.SendFeedback({
                    Sender:mainCtx.user.email,
                    Message: "Screenshot",
                    ImageB64: imageB64,
                }).then((response) => {setServerReply(response.response)})
                canvas.remove()
            })
    }


    const handleClose = () => {
        props.handleClose()
    }

    const updateMessages = (side: string, msg:string, avatar:string)=>{
        //same side => add to message array
        if(side == messages[messages.length-1].side){
            let updatedMsg = messages
            updatedMsg[messages.length-1].messageGroup.push(msg)
            setMessages(updatedMsg)
        }else {
            //different side, create new message object w. new side
            let msgGroup: string[] = [msg]
            let newMsg:ChatMessagesSide= {
                id: messages.length,
                side: side,
                messageGroup: [],
                avatar:avatar,
            }
            newMsg.messageGroup.push(msg)
            let updatedMsg = messages
            updatedMsg.push(newMsg)
            setMessages(updatedMsg)

        }
        setToggleMsgRefresh(!toggleMsgRefresh)

    }

    useEffect(() =>{
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    },[messages,userMessage, toggleMsgRefresh])

    useEffect(() =>{
        if(serverReply != ''){
            updateMessages('left', serverReply, getAvatar(mainCtx.activeOrganization.name, settingsCtx.settings.theme))
            setServerReply('')
            bottomRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    },[serverReply])

    useEffect(() => {
        const tempMessage: ChatMessagesSide = {id: 0, messageGroup: ["Hello, how may I be of assistance?", "Feel free to chat to us, or click the screenshot button in the top right hand corner to show us what you're seeing"], side: "left", avatar: getAvatar(mainCtx.activeOrganization.name, settingsCtx.settings.theme)}

        const index = messages.findIndex(m => m.id === 0);
        if (index === -1) {
            const tempMessages = [tempMessage].concat(messages)
            setMessages(tempMessages)
        }
    }, []);

    return(
        <Snackbar
            // anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            open={true}
            sx={{

                // translate: "14vw"
            }}
        >

            <Paper  sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                zIndex: 2000,
                boxShadow:2
                //width: "50%",
                //padding: 2,
                // marginTop: 1,
                // marginLeft: 0.5,
                // marginRight: 0.5
            }}>
                <form style={{height:'100%'}}  onSubmit={(event) => {event.preventDefault(); sendMessage()}}>
                    <Grid container sx={{
                        // border:'2px solid blue',
                        display: 'flex',
                        alignItems:'center' ,
                        justifyContent:"space-between",
                        width: "100%",

                    }}>
                        <Grid item sx={{
                            // border:'2px solid red',
                            alignItems:'right',
                            display: 'flex',
                            paddingLeft: 1,
                            marginLeft:1,
                        }}>
                            <Typography variant='subtitle1'>
                                {orgChecker(mainCtx.activeOrganization.name) ? "Cognizance Technical Support" : "Keystone Technical Support"}
                            </Typography>
                        </Grid>
                        <Grid item sx={{
                            // border:'2px solid green',
                            alignItems:'right',
                            display: 'flex',
                            pb: '10px',
                        }}>
                            <Tooltip title={"Send Screenshot"}>
                                <IconButton size="large" color="primary" onClick={handleDownloadCapture}>
                                    <PhotoCameraIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Close"}>
                                <IconButton size="large" color="primary" onClick={handleClose}>
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                            <Divider/>
                        </Grid>
                        <Box sx={{
                            // border:'2px solid yellow',
                            display: 'flex',
                            flexDirection: 'column',
                            width: "100%",
                            marginTop: 1,
                            marginLeft: 0.5,
                            marginRight: 0.5,
                            overflowY: 'auto',
                            height: 520,
                            padding:1,
                        }}
                        >
                            {messages.map((mes)=> {
                                return(
                                    <ChatMessage id={mes.id} key={mes.id} avatar={mes.avatar} messages={mes.messageGroup} side={mes.side}/>
                                )
                            })}
                            <div ref={bottomRef} />
                        </Box >
                        <Grid container sx={{
                            // border:'2px solid green',
                            display: 'flex',
                            flexDirection:'row',
                            alignItems:'center' ,
                            justifyContent:"space-between",
                            width: "100%"
                        }}>
                            <Grid item sx={{
                                // border:'2px solid red',
                                paddingTop:1.5,
                                paddingBottom:1.5,
                                paddingLeft:1.5,
                                fontSize:14,
                                height:'100%',
                                width:'80%',
                            }}>
                                <TextField fullWidth id="UserChat"
                                           value={userMessage}
                                           variant="standard"
                                           onChange={(r) => {setUserMessage(r.target.value)}}
                                           placeholder="Type a message"
                                           autoComplete="off"
                                           aria-autocomplete="none"
                                           type="text"
                                />
                            </Grid>
                            <Grid item sx={{
                                // border:'2px solid purple',
                            }}>
                                <Button
                                    type="submit"
                                >
                                    <SendIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        {/*</Grid>*/}
                    </Grid>
                </form>
            </Paper>
        </Snackbar>

    );
}

export default CogChat