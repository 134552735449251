import {API, PerformJSONRPCRequest} from "../../../../api/jsonrpc";
import {GoalCriteria, Progress, SVDetails} from "./SVTarget";

export interface CreateSVTargetRequest {
    type: string;
    target: number;
    goalID: string;
    clientName: string;
}

export interface CreateSVTargetResponse {
    success: boolean;
}

export interface FetchProductNamesRequest {
  clientName: string;
}

export interface FetchProductNamesResponse {
  productNames: Record<string, string[]>;
}

export interface CreateNPDTargetRequest {
  goalID: string;
  percentageDistribution: number;
  clientName: string;
}

export interface CreateNPDTargetResponse {
    success: boolean;
}

export interface CreateNPSTargetRequest {
  goalID: string;
  pps: string;
  percentageDistribution: number;
  clientName: string;
}

export interface CreateNPSTargetResponse {
  success: boolean;
}

export interface CalculateSVProgressRequest {
    type: string;
    target: number;
    startDate: string;
    dueDate: string;
    criteriaFilters: GoalCriteria[];
    clientName: string;
}

export interface CalculateSVProgressResponse {
    progress: Progress;
}

export interface CalculateSVDetailsRequest {
    goalID: string;
    startDate: string;
    dueDate: string;
    criteriaFilters: GoalCriteria[];
    clientName: string;
}

export interface CalculateSVDetailsResponse {
    details: Record<string, SVDetails>;
}

export interface CalculateNPDProgressRequest {
    goalID: string;
    criteriaFilters: GoalCriteria[];
    productHeader: string;
    clientName: string;
}

export interface CalculateNPDProgressResponse {
    progress: Progress;
}

export interface CalculateNPSProgressRequest {
  goalID: string;
  criteriaFilters: GoalCriteria[];
  productHeader: string;
  clientName: string;
}

export interface CalculateNPSProgressResponse {
  progress: Progress;
}

export interface CalculateNPDDetailsRequest {
    goalID: string;
    criteriaFilters: GoalCriteria[];
    clientName: string;
}

export interface CalculateNPDDetailsResponse {
    details: Record<string, SVDetails>;
}

export interface CalculateNPSDetailsRequest {
  goalID: string;
  criteriaFilters: GoalCriteria[];
  clientName: string;
}

export interface CalculateNPSDetailsResponse {
  details: Record<string, SVDetails>;
}

export interface CalculateStaticProgressRequest {
    goalID: string;
    clientName: string;
}

export interface CalculateStaticProgressResponse {
    progress: Progress;
}

function convertToGoalInterface(input: any) {
    const headers = Object.keys(input);
    const values = Object.values(input);
    const result = headers.map((header, index) => ({
        header,
        values: values[index],
    }));
    return {result};
}

const Store = {
  serviceProvider: 'Goal-Target-Store',
  async CreateSVTarget(request: CreateSVTargetRequest): Promise<CreateSVTargetResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CreateSVTarget`,
      request: [{
        "type": request.type,
        "target": request.target,
        "goalID": request.goalID,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async FetchProductNames(request: FetchProductNamesRequest): Promise<FetchProductNamesResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.FetchProductNames`,
      request: [{
        "clientName": request.clientName
      }]
    });
    return response
  },
  async CreateNPDTarget(request: CreateNPDTargetRequest): Promise<CreateNPDTargetResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CreateNPDTarget`,
      request: [{
        "goalID": request.goalID,
        "percentageDistribution": request.percentageDistribution,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async CreateNPSTarget(request: CreateNPSTargetRequest): Promise<CreateNPSTargetResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CreateNPSTarget`,
      request: [{
        "goalID": request.goalID,
        "pps": request.pps,
        "percentageDistribution": request.percentageDistribution,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async CalculateSVProgress(request: CalculateSVProgressRequest): Promise<CalculateSVProgressResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CalculateSVProgress`,
      request: [{
        "type": request.type,
        "target": request.target,
        "startDate": request.startDate.split(' ')[0],
        "dueDate": request.dueDate,
        "criteriaFilters": request.criteriaFilters,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async CalculateSVDetails(request: CalculateSVDetailsRequest): Promise<CalculateSVDetailsResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CalculateSVDetails`,
      request: [{
        "goalID": request.goalID,
        "startDate": request.startDate.split(' ')[0],
        "dueDate": request.dueDate,
        "criteriaFilters": convertToGoalInterface(request.criteriaFilters).result,
        "clientName": request.clientName
      }]
    });
    return response.details
  },
  async CalculateNPDProgress(request: CalculateNPDProgressRequest): Promise<CalculateNPDProgressResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CalculateNPDProgress`,
      request: [{
        "goalID": request.goalID,
        "criteriaFilters": request.criteriaFilters,
        "productHeader": request.productHeader,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async CalculateNPDDetails(request: CalculateNPDDetailsRequest): Promise<CalculateNPDDetailsResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CalculateNPDDetails`,
      request: [{
        "goalID": request.goalID,
        "criteriaFilters": convertToGoalInterface(request.criteriaFilters).result,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async CalculateNPSProgress(request: CalculateNPSProgressRequest): Promise<CalculateNPSProgressResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CalculateNPSProgress`,
      request: [{
        "goalID": request.goalID,
        "criteriaFilters": request.criteriaFilters,
        "productHeader": request.productHeader,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async CalculateNPSDetails(request: CalculateNPSDetailsRequest): Promise<CalculateNPSDetailsResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CalculateNPSDetails`,
      request: [{
        "goalID": request.goalID,
        "criteriaFilters": convertToGoalInterface(request.criteriaFilters).result,
        "clientName": request.clientName
      }]
    });
    return response
  },
  async CalculateStaticProgress(request: CalculateStaticProgressRequest): Promise<CalculateStaticProgressResponse> {
    const response = await PerformJSONRPCRequest({
      api: API.Authorised,
      method: `${Store.serviceProvider}.CalculateStaticProgress`,
      request: [{
        "goalID": request.goalID,
        "clientName": request.clientName
      }]
    });
    return response
  },
}

export default Store;