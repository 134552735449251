/* eslint-disable */
import React, {useEffect, FC, useCallback} from 'react';
import {Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, Popover} from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import ListItemIcon from '@mui/material/ListItemIcon';
import QueryStore, {CreateOneRequest} from "../../../../cog/bitool/query/Store";
import Query from "../../../../cog/bitool/query/Query";
import {useNavigate} from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import userEvent from "@testing-library/user-event";
interface QueryChooserProps {
}

const QueryChooser: FC<QueryChooserProps> = () => {
    let navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [queries, setQueries] = React.useState<Query[]>([]);
    const fetchAllQueries = useCallback(async () => {
        let response = await QueryStore.FindAll({})
        setQueries(response.queries)
    }, [])

    useEffect(() => {
        fetchAllQueries()
    }, [queries])

    const handleClick = (itemId) => {
        console.log(itemId)
        navigate('/app/bi/queryedit/' + itemId)
    }

    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleDelete = useCallback(async (ItemId) => {
        let response = await QueryStore.DeleteOne({
            id: ItemId,
        })
        console.log(ItemId)
        handleClose()
    },[]);

    const handleNewQueryClick = useCallback(async () => {
        let response = await QueryStore.CreateOne({
            name: "Example Query",
            templatedQuery: "Select * from {{A_TABLE}}"
        })
        if (response) {
            navigate('/app/bi/queryedit/' + response.id)
        }
    }, [])

    return (
        <div style={{height: "100%", width: "100%"}}>
            <Box>
                <Box>
                    <Button variant="text" onClick={handleNewQueryClick}>
                        Create New Query
                    </Button>
                </Box>
                <Box>
                    <List component="nav">
                        { queries.map((q) => (
                            <ListItem button key={q.id} secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={()=> handleDelete(q.id)}>
                                    <DeleteIcon />
                                </IconButton>

                            }>
                                <ListItemButton onClick={() => handleClick(q.id)}>
                                <ListItemIcon>
                                    <FolderIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={q.name}
                                    secondary={q.id}
                                />
                                </ListItemButton>

                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        </div>
    );
};

export default QueryChooser;




























