import * as React from 'react';
import {FC, useMemo} from 'react';
import Box from '@mui/material/Box';
import {DataGridPro, GridSortModel} from "@mui/x-data-grid-pro";
import {
  IconButton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import {useMainContext} from "../../../../contexts/MainContext";
import Card from "../../../../cog/keystone/card/Card";
import {useNavigate} from "react-router-dom";
import {GridColDef} from "@mui/x-data-grid";
import { Pageview, } from "@mui/icons-material";
import currencySwitcher from "../../../../utils/currencySwitcher";


interface HomeOpportunityTableProps {
  opportunities: Card[];
}

const HomeOpportunityTable: FC<HomeOpportunityTableProps> = ({opportunities}) => {
  const mainCtx = useMainContext()
  let navigate = useNavigate()

  const StyledDataGrid = styled(DataGridPro)(({theme}) => ({
    '& .homeOpportunityGrid': {
      borderRadius: 5,
      margin: 2,
    },
    '& .homeOpportunityCell': {
      borderRadius: 5,
      margin: 2,
    },
    '& .homeOpportunityHeader': {
      fontSize: "medium",
      margin: 2,
    },
  }))

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.1,
      resizable: true,
      headerAlign: 'center',
      headerClassName: 'homeOpportunityHeader',
      align: 'center',
      renderCell: (cellValue) => {
        return <Box sx={{
        }}><Tooltip title={cellValue.row.description}>
          <Typography variant="body2" fontSize="small" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: '' }}>
            {cellValue.value}
          </Typography>
        </Tooltip>
        </Box>
      }
    },
    {
      field: 'opportunityType',
      headerName: 'Opportunity Type',
      flex: 0.1,
      resizable: false,
      headerAlign: 'center',
      headerClassName: 'homeOpportunityHeader',
      align: 'center',
      renderCell: (cellValue) => {
        return <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Tooltip title={cellValue.row.description}>
            <Typography variant="body2" fontSize="small">{cellValue.value}</Typography>
          </Tooltip>
        </Box>
      }
    },
    {
      field: 'mValue',
      headerName: 'Value',
      flex: 0.1,
      resizable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'homeOpportunityHeader',
      renderCell: (cell) => {
        return <Typography variant="body2" fontSize="small">{currencySwitcher(mainCtx.activeOrganization.name) + cell.value}</Typography>
      }
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 0.1,
      resizable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'homeOpportunityHeader',
      renderCell: (params) => {

        const handleNavigate = () => {
          navigate('/app/' + mainCtx.activeOrganization.slug + '/opportunity/' + params.row.id)
        }

        return (
          <Box>
            <Tooltip title="View Opportunity">
               <IconButton
                 aria-label="delete"
                 onClick={handleNavigate}
                 sx={{
                   color: (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                 }}>
                <Pageview/>
               </IconButton>
            </Tooltip>
          </Box>
        );
      }
    },
  ];

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'mValue',
      sort: 'desc',
    },
  ]);

  const StyledDataGridMemo = useMemo(() => <StyledDataGrid
    columns={columns}
    rows={opportunities}
    sortModel={sortModel}
    onSortModelChange={(model) => setSortModel(model)}
    hideFooter
    sx={{
      height: "50vh",
      m: 2,
    }}
    getRowClassName={() => `homeOpportunityGrid`}
    getCellClassName={() => `homeOpportunityCell`}
  />, []);

  return (
    <Box>
      <Box>
        {StyledDataGridMemo}
      </Box>
    </Box>
  );
}

export default HomeOpportunityTable;
