import React, {FC, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";
import {CampaignStore} from "../../../../../cog/keystone/goals/campaigns";
import {useSnackbar} from "notistack";

interface ConfirmDialogDeleteCampaignProps {
    handleDialogClose: () => void
    loadingMethod: (v: boolean) => void;
    admin: boolean;
    goalID: string
    clientName: string
    updateCampaigns: () => void
    changed: () => void
}

const ConfirmDialogDeleteCampaignDialog: FC<ConfirmDialogDeleteCampaignProps> = ({
                                                                                     handleDialogClose,
                                                                                     loadingMethod,
                                                                                     admin,
                                                                                     goalID,
                                                                                     clientName,
                                                                                     updateCampaigns,
                                                                                     changed
                                                                                 }) => {
    const {enqueueSnackbar} = useSnackbar();
    const [isDisabled, setIsDisabled] = useState(false)

    const handleUpdateDelete = async () => {
        loadingMethod(true)
        setIsDisabled(true)

        console.log(admin)

        if (admin) {
            const response = await CampaignStore.DeleteOne({
                clientName: clientName,
                id: goalID
            })
            if (response) {
                changed()
                setIsDisabled(false)
                enqueueSnackbar('Campaign Deleted', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                updateCampaigns()
            } else {
                setIsDisabled(false)
                enqueueSnackbar('Failed to Delete Campaign', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        } else {
            setIsDisabled(false)
            enqueueSnackbar('Cannot Delete Campaign, Admin Permissions Required.  Please Contact an Administrator.', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        loadingMethod(false)
        handleDialogClose()
    }

    return (
        <Box sx={{m: 2}}>
            <DialogTitle>{"Delete Campaign(s)"}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    gridAutoFlow={"row"}
                    gridAutoRows={"auto"}
                    gap={1}
                >
                    <Box>Are you sure you want to delete the Campaign(s)?</Box>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonGroup variant={"text"}>
                    <Button
                        onClick={handleUpdateDelete}
                        disabled={isDisabled}
                    >
                        {isDisabled ? <CircularProgress size={20}/> : "Confirm"}
                    </Button>
                    <Button
                        onClick={() => handleDialogClose()}
                        disabled={isDisabled}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Box>
    );
}

export default ConfirmDialogDeleteCampaignDialog;