import {Card as KanbanCardObj} from "../../../../../cog/keystone/card";
import {FC, useEffect, useRef, useState} from "react";
import * as React from "react";
import {Box, Card, ClickAwayListener, Popover, Popper, Tooltip, Typography} from "@mui/material";
import {CalendarMonth, Category, LocationOn, PostAdd} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import FormatLabels from "../../opportunitylabel/FormatLabels";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {Label} from "@mui/icons-material";

interface IssueDescription{
    issue:KanbanCardObj
    isDescriptionOpen:boolean
    toggleDetailsOpen: () => void;

}

const IssueDescription : FC<IssueDescription> = (props) => {

    const keystoneCtx = useKeystoneContext()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [popoverHeight, setPopoverHeight] = React.useState(0)
    const [popoverWidth, setPopoverWidth] = React.useState(0)
    const ref = useRef(null)
    const ref2 = useRef(null)

    const CanBeOpen = open && Boolean(anchorEl);
    const id = CanBeOpen ? 'simple-popover' : undefined;


    const createdOn = new Date(Date.parse(props.issue.createdOn)).toISOString().slice(0, 10)

    useEffect(() => {
        setAnchorEl(ref.current);
        setPopoverHeight(ref.current.clientHeight)
        setPopoverWidth(ref.current.clientWidth)
    },[ref, anchorEl, props.isDescriptionOpen])

    const handleClose = () => {
        if(props.isDescriptionOpen){
            props.toggleDetailsOpen()
        }
    };

    return (
        <ClickAwayListener mouseEvent={'onPointerDown'} onClickAway={handleClose}>
            <Box ref={ref}
                 sx={{
                     //border: '2px solid blue',
                     display:'flex',
                     minHeight: '70px',
                     whiteSpace: 'pre-wrap',
                     flexDirection:'column',
                     alignItems:'flex-start',

                 }}>
                <Tooltip title="Location" placement="left" >
                    <Box sx={{
                        //border: '2px solid red',
                        display: 'flex',
                        alignItems: 'center',

                    }}>
                        <LocationOn  sx={{margin:1, color:useTheme().palette.error.main}} />
                        <Typography>{props.issue.displayValue}</Typography>
                    </Box>
                </Tooltip>
                <Tooltip title="Created On" placement="left" >
                    <Box sx={{
                        //border: '2px solid yellow',
                        display: 'flex',
                        alignItems: 'center',

                    }}>
                        <CalendarMonth sx={{margin:1, color:useTheme().palette.warning.main}}/>
                        <Typography>{createdOn}</Typography>
                    </Box>
                </Tooltip>
                <Tooltip title="Labels" placement="left" >
                    <Box sx={{
                        //border: '2px solid red',
                        display: 'flex',
                        alignItems: 'center',

                    }}>

                        <Label sx={{margin:1, color:useTheme().palette.info.main}}/>
                        <FormatLabels oppLabels={keystoneCtx.opportunityLabels} value={props.issue.opportunityLabels} theme={useTheme()} condensed={true}/>
                    </Box>
                </Tooltip>
                <Popover
                    id={id}
                    sx={{

                        pointerEvents: 'none',
                        display:'flex',
                    }}
                    open={props.isDescriptionOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleClose}
                    disableRestoreFocus
                >
                    <Card
                        ref={ref2}
                        sx={{
                            paddingLeft:1,
                            paddingRight:1,
                            paddingTop:1,

                            width: popoverWidth,
                            borderRadius: '10px',
                        }}>
                        <Typography variant="h6" component="div" sx={{ whiteSpace: 'pre-wrap'}} gutterBottom>
                            {props.issue.description}
                        </Typography>
                    </Card>
                </Popover>
            </Box>
        </ClickAwayListener>
    )
}

export default IssueDescription