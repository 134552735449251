import React, {useEffect, useState} from "react"
import {useMainContext} from "../../../../../contexts/MainContext";
import {useKeystoneContext} from "../../../../../contexts/KeystoneContext";
import {FinancialReportingStore} from "../../../../../cog/keystone/reporting/index";
import StackedBar from "../StackedBar";
import TreeMap from "../TreeMap";
import ColumnBar from "../ColumnChart";
import {
    Alert, Box, Card, CardContent, CardHeader, Container, Divider, Grid, LinearProgress, Typography
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import Top5Switcher from "../Top5Switcher";
import ValueAndCountIndicator from "../ValueAndCountIndicator";
import currencySwitcher from "../../../../../utils/currencySwitcher";
import HomeIssueSkeleton from "../../summary/Skeleton";
import FilterAsDrawer from "../../filter/FilterAsDrawer";
import {
    ConvertTreeFromBeingUsedToApiSave,
    GenerateNewPsuTree
} from "../../productstoreuniverse/utility/NodeTypesAndOptions";

const FinancialReportingPage = () => {
    const mainCtx = useMainContext()
    const keystoneCtx = useKeystoneContext()
    const [loading, setLoading] = useState<boolean>()
    const [tableModes, setTableModes] = React.useState<Array<{ id: string, mode: string | null }>>([
        { id: 'mode1', mode: 'graphic' },
        { id: 'mode2', mode: 'graphic' },
        { id: 'mode3', mode: 'graphic' },
        // Add more mode objects as needed
    ]);
    const [financialRepData, setFinancialRepData] = useState<any>({})

    const handleAlignment = (newMode: string | null, target: string) => {
        setTableModes((prevModes) =>
            prevModes.map((mode) =>
                (mode.id === target ? { ...mode, mode: newMode } : mode)));
    };
    // Sets which options to display in the toolbar
    useEffect(() => {
        if (keystoneCtx.toolBarDisplayOptions.selectOpps != false ||
            keystoneCtx.toolBarDisplayOptions.filterToMe != false ||
            keystoneCtx.toolBarDisplayOptions.stats != false ||
            keystoneCtx.toolBarDisplayOptions.filter != true) {
            keystoneCtx.setToolBarDisplayOptions({filter: true, stats: false, selectOpps: false, filterToMe: false, landingDateSelector: true})
            
        }
    }, [keystoneCtx.toolBarDisplayOptions])
    
    // fetchStatData: Fetches data for the stats
    const fetchFinancialRepStatData = async () => {
        setLoading(true)
        
        const dateSelectionStart = keystoneCtx.dateValue[0].toISOString().slice(0, 10)
        const dateSelectionEnd = keystoneCtx.dateValue[1].toISOString().slice(0, 10)
        // const tempStartDate = "2021-10-02"
        // const tempEndDate = "2024-05-27"
        let limit = "15"
        
        if (window.innerWidth <= 800) {
            limit = "5"
        }
        
        const response = await FinancialReportingStore.FinancialReportingStats(
            {
                clientName: mainCtx.activeOrganization.name,
                // dateSelectionStart: tempStartDate,
                dateSelectionStart: dateSelectionStart,
                // dateSelectionEnd: tempEndDate,
                dateSelectionEnd: dateSelectionEnd,
                limit: limit,
                filters: keystoneCtx.filters,
                defaultFilters: keystoneCtx.defaultFilters,
                PSU: keystoneCtx.activePSU != null
                    ? ConvertTreeFromBeingUsedToApiSave(keystoneCtx.activePSU)
                    : ConvertTreeFromBeingUsedToApiSave(GenerateNewPsuTree(mainCtx.activeOrganization.name))
            })
        
        if (response) {
            console.log(response)
            setFinancialRepData(response)
        }
        setLoading(false)
    }

    // Keystone context is already doing this
    // Checks if the filter is ready to use and then fetches cards with filter
    // useEffect(() => {
    //     if (keystoneCtx.filtersReady == true) {
    //         keystoneCtx.setFlipSwitchForCards(!keystoneCtx.flipSwitchForCards)
    //     }
    // }, [keystoneCtx.filtersReady])
    
    
    useEffect(() => {
        if (keystoneCtx.filtersReady == true && keystoneCtx.activePsuReady == true) {
            fetchFinancialRepStatData()
        }
    }, [mainCtx.activeOrganization.name, keystoneCtx.dateValue, keystoneCtx.flipSwitchForCards])
    
    useEffect(() => {
/*        
        ReactGA.event("keystone_view" ,{
            userID: mainCtx.user.id,
            orgID: mainCtx.activeOrganization.id,
            pageName: 'Financial Rep Page'
        });*/
    }, [mainCtx.activeOrganization])

    return (
        <>
        <Helmet>
            <title>Financial Reporting</title>
        </Helmet>
            <Box>
                <FilterAsDrawer
                    setFilters={keystoneCtx.setFilters}
                    setLevelMode={keystoneCtx.setLevelMode}
                    filters={keystoneCtx.filters}
                    open={keystoneCtx.filterOpen}
                    setOpen={keystoneCtx.setFilterOpen}
                    onClose={keystoneCtx.handleFilterClose}
                    client={mainCtx.activeOrganization.name}
                    organizationId={mainCtx.activeOrganization.id}
                    userID={mainCtx.user.id}
                    onClear={keystoneCtx.handleFilterClear}
                />
            </Box>
            {!loading && keystoneCtx.filtersReady ?
        <Box sx={{mt: 1}}>
            <Box
                sx={{
                    flexGrow: 1, py: 1
                }}
            >
                <Container maxWidth={false}>
                    {/* Causes problems if the filter drawer is conditionally rendered */}
                    {/*<Box marginLeft={0}>*/}
                    {/*    <FilterAsDrawer*/}
                    {/*        setFilters={keystoneCtx.setFilters}*/}
                    {/*        setLevelMode={keystoneCtx.setLevelMode}*/}
                    {/*        filters={keystoneCtx.filters}*/}
                    {/*        open={keystoneCtx.filterOpen}*/}
                    {/*        setOpen={keystoneCtx.setFilterOpen}*/}
                    {/*        onClose={keystoneCtx.handleFilterClose}*/}
                    {/*        client={mainCtx.activeOrganization.name}*/}
                    {/*        organizationId={mainCtx.activeOrganization.id}*/}
                    {/*        userID={mainCtx.user.id}*/}
                    {/*        onClear={keystoneCtx.handleFilterClear}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                    <Box>
                        <Box sx={{mb: 4}}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item>
                                    <Typography variant="h4">Maximizing Revenue Potential: Keystone Impact
                                        Analytics</Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{marginTop: 3}}/>
                        </Box>
                        <Grid
                            container
                            spacing={4}
                        >
                            <Grid item lg={6} xs={12}>
                                {financialRepData['RealizedOpportunitiesValue'] && financialRepData['RealizedOpportunitiesValue'].Value[0] != null ?
                                <ValueAndCountIndicator title={"Realized Opportunities: Total Value and Count"} currency={currencySwitcher(mainCtx.activeOrganization.name)} value={financialRepData['RealizedOpportunitiesValue'].Value} count={financialRepData['RealizedOpportunitiesValue'].num_opps} countLabel={"Opps"} /> :
                                    <Card sx={{padding: "2"}}><CardHeader title={"Realized Opportunities: Total Value and Count"}/><Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", margin: '5px', backgroundColor: "warning" }}>No Data Available for Selected Period & Filters</Alert></Card>}
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                {financialRepData['PendingOpportunitiesValue'] && financialRepData['PendingOpportunitiesValue'].Value[0] != null ?
                                    <ValueAndCountIndicator title={"Pending Opportunities: Total Value and Count"} currency={currencySwitcher(mainCtx.activeOrganization.name)} value={financialRepData['PendingOpportunitiesValue'].Value} count={financialRepData['PendingOpportunitiesValue'].num_opps} countLabel={"Opps"} /> :
                                    <Card sx={{padding: "2"}}><CardHeader title={"Pending Opportunities: Total Value and Count"}/><Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", margin: '5px', backgroundColor: "warning" }}>No Data Available for Selected Period & Filters</Alert></Card>}
                            </Grid>
                            <Grid item lg={4} xs={15}>{financialRepData['Top5ProductsRealized'] && Object.values(financialRepData['Top5ProductsRealized']).length > 0 ?
                                <Top5Switcher tableModeValue={tableModes[0].mode} tableModeId={tableModes[0].id} onChange={handleAlignment} title={"Top 5 " + Object.keys(financialRepData['Top5ProductsRealized'])[0] + " by Value"} data={financialRepData['Top5ProductsRealized']} /> : <Card sx={{padding: "2"}}><CardHeader title={"Top 5 " + keystoneCtx.colMap.Product + " by Value Realized"}/><Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", margin: '5px', backgroundColor: "warning" }}>No Data Available for Selected Period & Filters</Alert></Card>}
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                {financialRepData['Top5StoresRealized'] && Object.values(financialRepData['Top5StoresRealized']).length > 0 ?
                                    <Top5Switcher tableModeValue={tableModes[1].mode} tableModeId={tableModes[1].id} onChange={handleAlignment} title={"Top 5 " + Object.keys(financialRepData['Top5StoresRealized'])[0] + " by Value"} data={financialRepData['Top5StoresRealized']} /> : <Card sx={{padding: "2"}}><CardHeader title={"Top 5 " + keystoneCtx.colMap.Area_Hierarchy_3 + " by Value Realized"}/><Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", margin: '5px', backgroundColor: "warning" }}>No Data Available for Selected Period & Filters</Alert></Card>}
                        </Grid>
                            <Grid item lg={4} xs={12}>
                                {financialRepData['Top5TradeChannelsRealized'] && Object.values(financialRepData['Top5TradeChannelsRealized']).length > 0 ?
                                    <Top5Switcher tableModeValue={tableModes[2].mode} tableModeId={tableModes[2].id} onChange={handleAlignment} title={"Top 5 " + Object.keys(financialRepData['Top5TradeChannelsRealized'])[0] + " by Value"} data={financialRepData['Top5TradeChannelsRealized']} /> : <Card sx={{padding: "2"}}><CardHeader title={"Top 5 " + keystoneCtx.colMap.Overall_Hierarchy_1 + " by Value Realized"}/><Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", margin: '5px', backgroundColor: "warning" }}>No Data Available for Selected Period & Filters</Alert></Card>}
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <CardHeader
                                        title={"Net Sales Value Grouped Biweekly: Keystone Impact Analysis"}
                                    />
                                    <Divider/>
                                    <CardContent>
                                        {financialRepData['NettSalesValueGroupedImpactAnalysis'] && Object.values(financialRepData['NettSalesValueGroupedImpactAnalysis']).length > 0 ?
                                            <StackedBar categories={financialRepData['NettSalesValueGroupedImpactAnalysis']['Date']} data={Object.keys(financialRepData['NettSalesValueGroupedImpactAnalysis'])
                                                .filter(key => key !== "Date")
                                                .map(key => ({
                                                    name: key.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
                                                    data: financialRepData['NettSalesValueGroupedImpactAnalysis'][key]
                                                }))}/> : <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", marginBottom: '15px', backgroundColor: "warning" }}>No Data Available for Selected Period & Filters</Alert>}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <CardHeader
                                        title={"Top 10 Products Generating Revenue Through Keystone"}
                                    />
                                    <Divider/>
                                    <CardContent>
                                        {financialRepData['TopTenProductsGeneratingRev'] && Object.values(financialRepData['TopTenProductsGeneratingRev']).length > 0 ? <TreeMap data={financialRepData['TopTenProductsGeneratingRev']['Value'].map((y, index) => ({
                                            y,
                                            x: Object.values(financialRepData['TopTenProductsGeneratingRev'])[0][index],
                                        }))}/> : <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", marginBottom: '15px', backgroundColor: "warning" }}>No Data Available for Selected Period & Filters</Alert> }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Card>
                                    <CardHeader
                                        title={"Comparing Value of Keystone Realization by Category"}
                                    />
                                    <Divider/>
                                    <CardContent>
                                        {financialRepData['CompareValueRealizedbyCategory'] && Object.values(financialRepData['CompareValueRealizedbyCategory']).length > 0 ?
                                            <ColumnBar
                                                xLabel={keystoneCtx.colMap.Product_Hierarchy_1}
                                                data={financialRepData['CompareValueRealizedbyCategory']['Value']}
                                                categories={financialRepData['CompareValueRealizedbyCategory'][keystoneCtx.colMap.Product_Hierarchy_1]}/> :
                                            <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", marginBottom: '15px', backgroundColor: "warning" }}>No Data Available for Selected Period & Filters</Alert> }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Card>
                                    <CardHeader
                                        title={"Comparing Value of Keystone Realization by Region"}
                                    />
                                    <Divider/>
                                    <CardContent>
                                        {financialRepData['CompareValueRealizedbyRegion'] && Object.values(financialRepData['CompareValueRealizedbyRegion']).length > 0 ?
                                            <ColumnBar
                                                xLabel={keystoneCtx.colMap.Area_Hierarchy_1}
                                                data={financialRepData['CompareValueRealizedbyRegion']['Value']}
                                                categories={financialRepData['CompareValueRealizedbyRegion'][keystoneCtx.colMap.Area_Hierarchy_1]}/> :
                                            <Alert severity="warning" sx={{ height: 165, alignItems: "center", justifyContent: "center", marginBottom: '15px', backgroundColor: "warning" }}>No Data Available for Selected Period & Filters</Alert> }
                                    </CardContent>
                                </Card>
                            </Grid>
                        
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </Box> :
                <Box>
                    <LinearProgress
                        sx={{
                            marginLeft: 5,
                            marginRight: 5,
                            marginTop: 2,
                            justifySelf: "center"
                        }} />
                    <HomeIssueSkeleton />
                </Box> }
</>);
}

export default FinancialReportingPage;