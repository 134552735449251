import {Autocomplete, TextField} from '@mui/material';
import * as React from 'react';
import {FC, Fragment, useEffect, useState} from 'react';
import {FilterItemProps} from "../../Filter";
import {Box} from "@mui/system";
import _ from "lodash"
import {useKeystoneContext} from "../../../../../../contexts/KeystoneContext";
import {useMainContext} from "../../../../../../contexts/MainContext";

const getHighlightedText = (text, highlight) => {
    // Split text on highlight term, include term itself into parts, ignore case
    let parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, i) => (
        <Fragment key={i}>
            {part.toLowerCase() === highlight.toLowerCase() ? (
                <b style={{color: "#0072E5"}}>{part}</b>
            ) : (
                part
            )}
        </Fragment>
    ));
}



const TextListCheckbox: FC<FilterItemProps> = ({ name, header, filterData, filters, setFilters, searchString, viewFiltersOnly, filterGoals, filterCampaigns }) => {
    const keystoneCtx = useKeystoneContext()
    const mainCtx = useMainContext()
    const searchFilteredData = searchString.trim() != "" && filterData != null ?
        filterData.filter(  (fil) => (fil != null) ?
            (mainCtx.members.find(o => o.id == fil) == undefined ? fil.toString().toLowerCase().trim().includes(searchString.toLowerCase().trim())
                : mainCtx.members.find(o => o.id == fil).displayName) : false
        ) : filterData
    
    const renderedData = viewFiltersOnly ? filters.filter(fil => fil.header === header).map(f => f.values) : searchFilteredData
    
    
    const handleChange = (value: string[]) => {
        // When value param gives empty array, we go through the selected filters and remove them one by one
        if (value.length == 0) {
            for (let i = 0; i < selectedFilters.length; i++) {
                const idx = filters.findIndex(fil => fil.header === header && _.isEqual(fil.values, selectedFilters[i]))
                if (idx > -1) { // remove
                    setFilters([
                        ...filters.slice(0, idx),
                        ...filters.slice(idx + 1)
                    ])
                    if (header == keystoneCtx.colMap.Overall_Hierarchy_1) {
                        keystoneCtx.handleSupplierChange(value[i], false, true)
                    }
                    
                }
            }
            setSelectedFilters(value)
            return
        } else if (value.length > selectedFilters.length) {
            setFilters([...filters,
                {
                    prefix: "AND",
                    header: header,
                    operation: "=",
                    values: value[value.length -1]
                }
            ])
            if (header == keystoneCtx.colMap.Overall_Hierarchy_1) {
                keystoneCtx.handleSupplierChange(value[value.length -1], true, true)
            }
        } else if (value.length < selectedFilters.length) {
            for (let i = 0; i < selectedFilters.length; i++) {
                if (value.findIndex(val => val == selectedFilters[i]) == -1) {
                    const idx = filters.findIndex(fil => fil.header === header && fil.values == selectedFilters[i])
                    if (idx > -1) { // remove
                        setFilters([
                            ...filters.slice(0, idx),
                            ...filters.slice(idx + 1)
                        ])
                        if (header == keystoneCtx.colMap.Overall_Hierarchy_1) {
                            keystoneCtx.handleSupplierChange(selectedFilters[i], false, true)
                        }
                        // setSelectedFilters(value)
                    }
                }
            }
        }
    }
    
    const getValue = (options:string[]) => {
        if (options != null) {
            let values = []
            for (let i = 0; i < options.length; i++) {
                const idx = filters.findIndex(fil => fil.header === header && _.isEqual(fil.values, options[i]))
                
                if (idx > -1) {
                    values.push(options[i])
                }
                
            }
            return values
        }
    }
    
    // const [value, setValue] = useState<any[]>([])
    const [selectedFilters, setSelectedFilters] = useState<string[]>(getValue(filterData))
    
    // Make filters happen on page load
    useEffect(() => {
        // doMembers().then(r => setMembers(r))
        setSelectedFilters(getValue(filterData))
    }, [filters])
    
    const getUserNameWithID = (userID: string): string => {
        if (userID == "") {
            return "--No Assignees--"
        }
        if (mainCtx.members != null && mainCtx.members.find(o => o.id == userID) != null) {
            let tempName = mainCtx.members.find(o => o.id == userID).displayName.toString()
            return tempName
        }
        return userID
    }

    const getOppTypeName = (oppTypeID: string): string => {
        if (oppTypeID == "") {
            return "--No Opportunity Types--"
        }
        if (mainCtx.opportunityTypes != null && mainCtx.opportunityTypes.find(o => o.id == oppTypeID) != null) {
            return mainCtx.opportunityTypes.find(o => o.id === oppTypeID).name
        }
        return oppTypeID
    }
    
    const getGoalNameWithID = (Goal_ID: string): string => {
        if (Goal_ID == "") {
            return "--No Goals--"
        }
        if (filterGoals != null && filterGoals.find(o => o.id == Goal_ID) != null) {
            let tempName = filterGoals.find(o => o.id == Goal_ID).name.toString()
            return tempName
        }
        return Goal_ID
    }
    

    const getCampaignNameWithID = (Campaign_ID: string): string => {
        if (Campaign_ID == "") {
            return "--No Campaigns--"
        }
        if (filterCampaigns != null && filterCampaigns.find(o => o.id == Campaign_ID) != null) {
            let tempName = filterCampaigns.find(o => o.id == Campaign_ID).name.toString()
            return tempName
        }
        return Campaign_ID
    }
    
    
    return (
        <Box marginY={1}>
            { renderedData && renderedData.length > 0 && renderedData[0] != "" &&
                <Box
                    role="group"
                    aria-labelledby="filter-status"
                >
                    <Box>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            // id="tags-outlined"
                            options={filterData}
                            getOptionLabel={header == "assigned_to" ? (option: string) => getUserNameWithID(option) : header == "opportunity_type" ? (option: string) => getOppTypeName(option) : header == "Goal_ID" ?  (option: string) => getGoalNameWithID(option) : header == "Campaign_ID" ? (option: string) => getCampaignNameWithID(option) : (option: string) => option }
                            value={selectedFilters}
                            filterSelectedOptions
                            onChange={(_, value) => {
                                handleChange(value)
                            }}
                            renderOption={(props, option: string) => {
                                const label =
                                    header === "assigned_to"
                                        ? getUserNameWithID(option)
                                        : header === "opportunity_type"
                                            ? getOppTypeName(option)
                                            : header === "Goal_ID"
                                                ? getGoalNameWithID(option)
                                                : header === "Campaign_ID"
                                                    ? getCampaignNameWithID(option)
                                                    : option;
                                return (
                                    <li {...props} key={option}>
                                        {label}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`${name}`}
                                    // placeholder="Select Filters"
                                />
                            )}
                        />
                    </Box>
                </Box>
            }
        </Box>
    );
}

export default TextListCheckbox