import React, {FC, useState} from "react";
import {Box, LinearProgress} from "@mui/material";
import {StyledDataGrid} from "../../listview/CustomDataGridTheme";
import {GridToolbar} from "@mui/x-data-grid-pro";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useMainContext} from "../../../../../contexts/MainContext";
import {useTheme} from "@mui/material/styles";
import EFLog from "../../../../../cog/keystone/exclude/EFLog";
import {RemoveOneRequest} from "../../../../../cog/keystone/exclude/Store";
import {GridColDef} from "@mui/x-data-grid";

interface ExcludedManagerCurrentExcludesComponentProps {
    excludeRows: any[]
    isLoading: boolean
    currentExcludesColumns: GridColDef[]
}

const ExcludedManagerCurrentExcludesComponent: FC<ExcludedManagerCurrentExcludesComponentProps> = (props) => {
    const mainctx = useMainContext()// Theme Object
    const tempTheme = useTheme() // fetches current theme being used
    const [myTheme, setMyTheme] = useState(tempTheme)
    const [excludeRows, setExcludeRows] = useState<any[]>(props.excludeRows);
    const [isLoading, setIsLoading] = useState<boolean>(props.isLoading)

    const [currentExcludesColumns, setCurrentExcludesColumns] = useState<GridColDef[]>(props.currentExcludesColumns);


    return <Box>
        <Box sx={{width: "100%", height: "40vh"}}>
            {isLoading == true && <LinearProgress
                sx={{
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: 2,
                    justifySelf: "center"
                }}/>}
            {excludeRows != null && mainctx.members != null && isLoading == false &&
                <StyledDataGrid
                    columns={currentExcludesColumns}
                    rows={excludeRows}
                    getRowHeight={() => 'auto'}
                    // getEstimatedRowHeight={() => 1000 / 100}
                    components={{
                        Toolbar: GridToolbar,
                        DetailPanelExpandIcon: ExpandMore,
                        DetailPanelCollapseIcon: ExpandLess,
                    }}
                    componentsProps={{
                        panel: {
                            sx: {
                                '& .MuiDataGrid-panelWrapper': {
                                    backgroundColor: myTheme.palette.background.default,
                                    margin: 0.2,
                                    padding: 0.2
                                },
                                // replaces scroll bar
                                '& .MuiDataGrid-panelContent': {
                                    overflowX: "hidden",
                                    paddingRight: 2,
                                    "&::-webkit-scrollbar": {
                                        width: 10
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        backgroundColor: myTheme.palette.background.paper,
                                        borderRadius: 1,
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: myTheme.palette.text.secondary,
                                        borderRadius: 2,
                                    }
                                }
                            }
                        }
                    }}
                    disableDensitySelector={true}
                    pageSize={100}
                />
            }
        </Box>
    </Box>
}

export default ExcludedManagerCurrentExcludesComponent;