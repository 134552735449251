import {FC} from "react";
import HeaderLoader from "./HeaderLoader";
import Box from "@mui/material/Box";
import {Divider, Grid, Typography} from "@mui/material";
import Dashboard from "../../../../../cog/bitool/dashboard/Dashboard";
import LogoSwitcher from "./LogoSwitcher";

interface HeaderProps {
    dashboard: Dashboard
    org: string
    loading: boolean
}

const Header: FC<HeaderProps> = ({dashboard, org, loading}) => {

    return(
        <div>
            {loading &&
              <HeaderLoader />
            }
            {!loading && dashboard && dashboard.name &&
                <div>
                    <Box
                        sx={{
                          backgroundColor: 'background.default',
                          minHeight: '100%',
                          //paddingTop: 0.5,
                          paddingBottom: 3,
                        }}
                    >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={3}
                          sx={{
                              ml: 2,
                              mb: 1
                          }}
                        >
                            <Grid item>
                                <LogoSwitcher icon={false} org={org} />
                            </Grid>
                            <Grid item>
                                <Typography
                                  color="textPrimary"
                                  variant="h4"
                                >
                                    {dashboard.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Box>
                </div>
            }
        </div>
    );
}

export default Header;