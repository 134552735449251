import React from 'react';
import type { FC } from 'react';
import logoPath from "./ExcelLogo.svg"

interface ExcelFileIconProps {
    [key: string]: any;
}

const ExcelFileIcon: FC<ExcelFileIconProps> = (props) => (
    <img
        alt="ExcelFile"
        src={logoPath}
        style={{
            margin: 1
        }}
        {...props}
    />
);

export default ExcelFileIcon;