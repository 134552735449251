import {Theme} from "@mui/material/styles";
import {FC, useRef, useState} from "react";
import {Card, Chip, ClickAwayListener, Popover, Tooltip} from "@mui/material";
import {Label} from "@mui/icons-material";
import * as React from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {OppLabel} from "../../../../cog/keystone/oppLabel/OppLabel";

interface OppLabelsDisplayProps {
    oppLabels: OppLabel[]
    value: string
    theme: Theme
    condensed ? :boolean

}

const FormatLabels: FC<OppLabelsDisplayProps> = (props) => {

    try {
        // console.log("Format Laels value", props.value)
        let test = JSON.parse(`{"opportunityLabels": ${props.value} }`)['opportunityLabels']
        if(test.length == 0){
            return <div>No Labels</div>
        }
    } catch (e) {
        // console.log("FormatLabels is Err")
        return <Tooltip title={"empty"}>
            <div></div>
        </Tooltip>
    }

    // This creates a JSON string and extracts just the label array from the parsed JSON
    // The "tempOppLabels" string could be anything, it's just there to extract the json things
    let tempOppLabels = JSON.parse(`{"opportunityLabels": ${props.value} }`)['opportunityLabels']
    // console.log("Format Labels tempOpLabels", tempOppLabels)
    let labelMap: OppLabel[] = []
    for (let i = 0; i < Object.values(props.oppLabels).length; i++) {
        labelMap[props.oppLabels[i].id] = props.oppLabels[i]
    }

    const [popOpen, setPopOpen] = useState<boolean>(false)
    const popoverRef = useRef(null)

    const handleClose = () => {
        setPopOpen(false)
    }

    const labelBlockDisplay = () => {
        return <div>

            {tempOppLabels.map((val, index) => (
                labelMap[val] != null &&
                <Tooltip
                    title={<div>Name: {labelMap[val] && labelMap[val].label ? labelMap[val].label : "oops"}</div>}
                    key={`OppTooltip_${index}`}
                    placement="right"
                >
                    <div style={{marginTop: 3}}>
                        <Chip variant="outlined"
                              size="small"
                              icon={<Label style={{color: props.theme.palette.primary.main}}/>}
                              label={labelMap[val].label}
                              style={{
                                  borderColor: props.theme.palette.primary.main,
                                  padding: 5
                              }}

                        />
                    </div>
                </Tooltip>
            ))}
        </div>
    }

    return (
        Boolean(!tempOppLabels[0])?
            <div>No Labels</div>:
        Boolean(props.condensed)?
        <ClickAwayListener mouseEvent={'onPointerDown'} onClickAway={handleClose}>

            <div ref={popoverRef}>

            <Tooltip
                title={<div>Name: {labelMap[tempOppLabels[0]] && labelMap[tempOppLabels[0]].label ? labelMap[tempOppLabels[0]].label : ''}</div>}
                key={`OppTooltipSingle_1`}
                placement="right"
            >
                <div style={{marginTop: 3}} onClick={(event) => {
                    event.stopPropagation()
                    setPopOpen(prevState => !prevState)

                }}>
                    <Chip variant="outlined"
                          size="small"
                          icon={<Label style={{color: props.theme.palette.primary.main}}/>}
                          label={labelMap[tempOppLabels[0]] && labelMap[tempOppLabels[0]].label ? labelMap[tempOppLabels[0]].label : ''}
                          style={{
                              borderColor: props.theme.palette.primary.main,
                              padding: 5
                          }}

                    />
                    { tempOppLabels.length >1 &&
                    <Chip variant="outlined"
                          size="small"
                          icon={<MoreHorizIcon style={{color: props.theme.palette.primary.main}}/>}
                          sx={{
                              p:0.5,
                              paddingRight:1.5,
                              "& .MuiChip-label": {
                                  display: "none"
                              }
                          }}
                          style={{
                              borderColor: props.theme.palette.primary.main,
                              // padding: 5
                          }}
                    />}
                </div>
            </Tooltip>
            <Popover open={popOpen}
                     disableRestoreFocus
                     style={{
                         borderRadius: '10px',
                         pointerEvents: 'none',
                         display:'flex',
                     }}
                     anchorEl={popoverRef.current}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     transformOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     onClose={handleClose}
            >
                <Card
                    sx={{
                        p:0.5,
                        display:'flex',
                    }}
                >

                    {labelBlockDisplay()}
                </Card>
            </Popover>
        </div>
        </ClickAwayListener>
            :
            <div>{labelBlockDisplay()}</div>
    )



}
export default FormatLabels