/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { FC, useLayoutEffect, useState } from "react";
import { Box, Button, Fade, Paper, Popper, TextField, Typography } from "@mui/material";
import { enZA } from 'date-fns/locale'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {WidgetProps} from "../../../WidgetSwitcher";
import BasicWidget from "../BasicWidget";
import slice from "../../../../../../../slices/bitool";
import {useDispatch} from "react-redux";

const KEY = 'date'

const BasicDatePickerWidgetInst: FC<WidgetProps> = ({widget, widgetVariables}) => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    let url = window.location.pathname.toString()
    let dateDefault = widget && widget.arguments && widget.arguments.templates &&
    widget.arguments.templates[KEY] ? widget.arguments.templates[KEY].value : null

    let getValue = ():Date | null => {
        if (widgetVariables && widgetVariables[KEY]) {
            return widgetVariables[KEY]
        } else {
            return null
        }
    }

    const code = widget.arguments.code ? decodeURI(widget.arguments.code) : null
    try {
        eval(code);
    } catch (e) {
    }

    useLayoutEffect(() => {
        if (!widgetVariables) {
            dispatch(slice.actions.widgetChangeVariable({id: widget.id, data: {
                    [KEY]: new Date(dateDefault),
                }}));
        }
    }, [widgetVariables])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={enZA}>
                <DatePicker
                    label={widget.name}
                    value={getValue()}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                        dispatch(slice.actions.widgetChangeVariable({id: widget.id, data: {
                                 [KEY]: newValue
                            }}));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                {url.includes("/bi/") &&
                  <Box>
                      <Button variant="text" onClick={handleClick}>
                          Widget Output
                      </Button>
                      <Popper id={id} open={open} anchorEl={anchorEl} placement="right-end" transition>
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper>
                                    <Typography sx={{ p: 2 }}>WidgetOutput: {JSON.stringify(widgetVariables)}</Typography>
                                </Paper>
                            </Fade>
                          )}
                      </Popper>
                  </Box>
                }
            </LocalizationProvider>
        </div>
    );
}

const BasicDateWidget: FC<WidgetProps> = ({widget}) => {
    return  <BasicWidget widget={widget} children={<BasicDatePickerWidgetInst />}/>
}

export default BasicDateWidget