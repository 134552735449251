import type {FC} from 'react';
import {ChangeEvent, useContext, useEffect, useRef, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Divider,
    FormControlLabel,
    FormGroup,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography
} from '@mui/material';
import UserIcon from '../../icons/User';
import {useMainContext} from "../../contexts/MainContext";
import {MaterialUISwitch} from '../../components/Switch'
import {THEMES} from "../../constants";
import SettingsContext from "../../contexts/SettingsContext";
import {User} from "../../cog/user";
import orgChecker from "../../utils/orgChecker";
import SettingsIcon from '@mui/icons-material/Settings'
import {KeystoneAccessResponse} from "../../cog/adminManagement/Store";
import {AdminStore} from "../../cog/adminManagement";

interface AccountPopoverProps {
    user: User | null
    logout: () => void;
}

const AccountPopover: FC<{props: AccountPopoverProps}> = ({props}) => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState<boolean>(false);
    const [route, setRoute] = useState<string>("/account");
    const ctx = useContext(SettingsContext);
    const mainCtx = useMainContext();
    const [settingsRoute, setSettingsRoute] = useState<string>("/notifications");
    const [showSettings, setShowSettings] = useState<boolean>(false)
    useEffect(() => {

    }, [props.user, ctx.settings]);

    useEffect(() => {
        if (mainCtx.activeOrganization && mainCtx.activeOrganization.name) {
            const org = mainCtx.activeOrganization.name.replace(/ |_/g,'-');
            setRoute("/account/" + org.toLowerCase())
            setSettingsRoute("/notifications/" + org.toLowerCase())
        }
    }, [mainCtx.activeOrganization]);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = ()  => {
        try {
            handleClose();
            props.logout();

        } catch (err) {
            console.error(err);
            enqueueSnackbar('Unable to logout', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    };

    const handleSettingsChange =  (event: ChangeEvent<HTMLInputElement>) => {
        let change: boolean | string = event.target.checked
        if (event.target.name == 'theme') {
            change = event.target.checked ? orgChecker(mainCtx.activeOrganization.name) ? THEMES.OLDCOGNIZANCE : THEMES.LIGHT : THEMES.DARK
        }
        ctx.saveSettings({
            ...ctx.settings,
            [event.target.name]: change,
        });
    }

    const checkAccess = async (): Promise<KeystoneAccessResponse> => {
        try {
            return AdminStore.CheckKeystoneAccess(
                {
                    user_id: mainCtx.user.id,
                    org_id: mainCtx.activeOrganization.id
                }
            )
        } catch (e) {
            console.error(`${e}`);
        }
    }

    useEffect(() => {
        setShowSettings(false)
        if (mainCtx.activeOrganization && mainCtx.user) {
            checkAccess()
                .then((r) => {
                    if (r && r.admin_permission && r.user_permission) {
                        setShowSettings(true)
                    } else {
                        setShowSettings(false)
                    }
                })
        }
    }, [mainCtx.activeOrganization, mainCtx.user])
    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex'
                }}
            >
                <Avatar
                    alt={props.user.name}
                    src={props.user.avatar}
                    sx={{
                        height: 32,
                        width: 32,
                        display: "flex"
                    }}
                />
            </Box>
            <Popover
                //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                disableEnforceFocus
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom'
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {width: 240}
                }}
            >
                <Box sx={{p: 2}}>
                    <Typography
                        color="textPrimary"
                        variant="subtitle2"
                    >
                        {props.user.name}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="subtitle2"
                    >
                        {props.user.email}
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={{mt: 2}}>
                    <MenuItem
                        component={RouterLink}
                        to={route}
                    >
                        <ListItemIcon
                            sx={{
                                ml: 1,
                                color: (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                            }}
                        >
                            <UserIcon fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={(
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                    sx={{ ml: 1 }}
                                >
                                    Account
                                </Typography>
                            )}
                        />
                    </MenuItem>
                    {showSettings ? <MenuItem
                        //component={RouterLink}
                        //to={settingsRoute}
                        onClick={() => navigate(`/app/${mainCtx.activeOrganization.slug}/organization-settings`)}
                    >
                        <ListItemIcon
                            sx={{
                                ml: 1,
                                color: (theme) => (theme.palette.mode === 'dark' ? "#ffffff" : '#6b778c'),
                            }}
                        >
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={(
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                    sx={{ ml: 1 }}
                                >
                                    Settings
                                </Typography>
                            )}
                        />
                    </MenuItem> : null}
                </Box>
                <Box sx={{m: 2}}>
                    <FormGroup>
                        {mainCtx.activeOrganization && mainCtx.activeOrganization.name &&
                          <FormControlLabel
                            control={<MaterialUISwitch
                              checked={orgChecker(mainCtx.activeOrganization.name) ? ctx.settings.theme === THEMES.OLDCOGNIZANCE : ctx.settings.theme === THEMES.LIGHT}
                              onChange={handleSettingsChange}
                              name="theme"
                            />}
                            label={
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                  sx={{ ml: 2 }}
                                >
                                    Dark Mode
                                </Typography>
                            }
                          />
                        }
                    </FormGroup>
                </Box>
                <Box sx={{p: 2}}>
                    <Button
                        // sx={{background: "#f05c59"}}
                        color="error"
                        fullWidth
                        onClick={handleLogout}
                        variant="outlined"
                    >
                        Logout
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default AccountPopover;