export const LabelTemplates = {
  "labelText": {
    "value": "Label TextList :"
  },
  "labelAlign": {
    "value": "left"
  },
  "labelColour": {
    "value": "grey"
  },
  "labelVariant": {
    "value": "h4"
  }
}

export const DateLabelTemplates = {
  "dateDisplay": {
    "value": "",
    "keyOrIndex": "date_1",
    "serializerStringFunction": "$StringSerializer"
  }
}

export const ImageTemplates = {
  "imageUrl": {
    "value": "https://storage.googleapis.com/static-assets-cog/org_logos/WutowIcon.svg"
  }
}