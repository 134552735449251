import React, {FC, useEffect, useState} from "react";
import {
    Autocomplete,
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    Divider,
    Grid,
    Popover,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import Goal, { UpdateGoal } from "../../../../../../cog/keystone/goals/goal/Goal";
import {Member} from "../../../../../../cog/keystone/member";
import {useMainContext} from "../../../../../../contexts/MainContext";
import GoalPriorityRating from "../../GoalPriorityRating";
import dayjs, {Dayjs} from 'dayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';
import ConfirmSettingsDialog from "./ConfirmSettingsDialog";
import {AdminStore} from "../../../../../../cog/adminManagement";
import {KeystoneAccessResponse} from "../../../../../../cog/adminManagement/Store";
import Campaign from "../../../../../../cog/keystone/goals/campaigns/Campaign";
import Stack from "@mui/material/Stack";
import ConfirmCampaignSettingsDialog from "./ConfirmCampaignSettingsDialog";
import ConfirmAssigneeSettingsDialog from "./ConfirmAssigneeSettingsDialog";
import { useTheme } from "@mui/material/styles";
import { KeyboardArrowLeft, KeyboardArrowRight, UnfoldMore } from "@mui/icons-material";
import moment from "moment";

let dialogBody = () => {
    return (
        <div>
        </div>
    )
};

interface GoalSettingsProps {
    goal: Goal;
    setGoal: (goal: Goal) => void;
    campaigns: Campaign[]
}

const GoalSettings: FC<GoalSettingsProps> = ({goal, setGoal, campaigns}) => {
    const mainCtx = useMainContext();

    const [multiUserToAssign, setMultiUserToAssign] = useState([]);
    const [multiCampaignToAssign, setMultiCampaignToAssign] = useState([]);
    const [dueDate, setDueDate] = useState<Dayjs | null>(null);
    const [owner, setOwner] = useState(null);
    const [priority, setPriority] = useState(goal.priority);
    const [assignPopoverAnchorEl, setAssignPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [campaignPopoverAnchorEl, setCampaignPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [dueDatePopoverAnchorEl, setDueDatePopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [priorityPopoverAnchorEl, setPriorityPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [ownerPopoverAnchorEl, setOwnerPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [assignLoading, setAssignLoading] = useState<boolean>(false);
    const [campaignLoading, setCampaignLoading] = useState<boolean>(false);
    const [dueDateLoading, setDueDateLoading] = useState<boolean>(false);
    const [priorityLoading, setPriorityLoading] = useState<boolean>(false);
    const [ownerLoading, setOwnerLoading] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const checkAccess = async (): Promise<KeystoneAccessResponse> => {
        try {
            return AdminStore.CheckKeystoneAccess(
                {
                    user_id: mainCtx.user.id,
                    org_id: mainCtx.activeOrganization.id
                }
            )
        } catch (e) {
            console.error(`${e}`);
        }
    }

    const getCurrentAssignees = () => {
        const currentAssignees: Member[] = []
        const userIDs: string[] = JSON.parse(goal.userID)

        for (let i = 0; i < mainCtx.members.length; i++) {
            for (let j = 0; j < userIDs.length; j++) {
                if (mainCtx.members[i].id === userIDs[j]) {
                    currentAssignees.push(mainCtx.members[i])
                }
            }
        }

        setMultiUserToAssign(currentAssignees)
    }

    const getCurrentCampaigns = () => {
        const currentCampaignsFinal: Campaign[] = []
        const assignedCampaignIDs: string[] = JSON.parse(goal.campaignID)
        const campaignIDs: Campaign[] = campaigns

        for (let i = 0; i < campaignIDs.length; i++) {
            for (let j = 0; j < assignedCampaignIDs.length; j++) {
                if (campaignIDs[i].id === assignedCampaignIDs[j]) {
                    currentCampaignsFinal.push(campaignIDs[i])
                }
            }
        }
        setMultiCampaignToAssign(currentCampaignsFinal)
    }

    const getCurrentOwner = () => {
        let currentOwner: Member
        for (let i = 0; i < mainCtx.members.length; i++) {
            if (mainCtx.members[i].id === goal.owner) {
                currentOwner = mainCtx.members[i]
            }
        }
        setOwner(currentOwner)
    }

    const getCurrentDueDate = () => {
        const d = new Date(goal.dueDate)
        const day = dayjs(d)
        setDueDate(day)
    }

    const getPriorityLabel = (priority: number) => {
        switch (priority) {
            case 0.5:
                return <Chip label="Somewhat Low"
                             color="success"/>
            case 1:
                return <Chip label="Low"
                             color="success"/>
            case 1.5:
                return <Chip label="Extremely Low"
                             color="success"/>
            case 2:
                return <Chip label="Medium"
                             color="warning"/>
            case 2.5:
                return <Chip label="Somewhat High"
                             color="warning"/>
            case 3:
                return <Chip label="High"
                             color="error"/>
            case 3.5:
                return <Chip label="Extremely High"
                             color="error"/>
            case 4:
                return <Chip label="Somewhat Urgent"
                             color="error"/>
            case 4.5:
                return <Chip label="Urgent"
                             color="error"/>
            case 5:
                return <Chip label="Extremely Urgent"
                             color="error"/>
        }
    }

    const handleAssigneeUpdate = async () => {
        const userIDs = []
        for (let i = 0; i < multiUserToAssign.length; i++) {
            userIDs.push(multiUserToAssign[i].id)
        }

        if (goal.userID !== JSON.stringify(userIDs)) {

            dialogBody = () => {
                return (
                    <ConfirmAssigneeSettingsDialog
                      confirmMessage={"Are you sure you want to update the goal's assignees?"}
                      type={"Assignees"}
                      userIDs={userIDs}
                      goalID={goal.id}
                      handleDialogClose={handleDialogClose}
                      loadingMethod={setAssignLoading}
                      admin={isAdmin}/>
                );
            }

            setDialogOpen(true);
        }
    }

    const handleCampaignUpdate = async () => {
        const campaignIDs = []
        for (let i = 0; i < multiCampaignToAssign.length; i++) {
            campaignIDs.push(multiCampaignToAssign[i].id)
        }

        if (goal.campaignID !== JSON.stringify(campaignIDs)) {
            const tempGoal: Goal = goal
            tempGoal.campaignID = JSON.stringify(campaignIDs)

            dialogBody = () => {
                return (
                    <ConfirmCampaignSettingsDialog confirmMessage={"Are you sure you want to attach the goal to the campaign(s)?"}
                                                              type={"CampaignGoals"}
                                                              goal={tempGoal}
                                                              handleDialogClose={handleDialogClose}
                                                              loadingMethod={setCampaignLoading}
                                                              setGoal={setGoal}
                                                              admin={isAdmin}
                                                              campaigns={multiCampaignToAssign}/>
                );
            }

            setDialogOpen(true);
        }
    }

    const handleDueDateUpdate = async () => {

        if (dayjs(dueDate).format('YYYY-MM-DD') !== goal.dueDate) {
            const tempGoal: Goal = goal
            tempGoal.dueDate = dayjs(dueDate).format('YYYY-MM-DD')

            const goalRequest: UpdateGoal = {
                id: goal.id,
                name: goal.name,
                type: goal.type,
                owner: goal.owner,
                description: goal.description,
                priority: goal.priority,
                status: goal.status,
                dueDate: dayjs(dueDate).format('YYYY-MM-DD')
            }

            dialogBody = () => {
                return (
                    <ConfirmSettingsDialog
                       confirmMessage={"Are you sure you want to update the goal's due date?"}
                       type={"Due Date"}
                       goal={tempGoal}
                       goalRequest={goalRequest}
                       handleDialogClose={handleDialogClose}
                       loadingMethod={setDueDateLoading}
                       setGoal={setGoal}
                       admin={isAdmin}
                    />
                );
            }

            setDialogOpen(true);
        }
    }

    const handlePriorityUpdate = async () => {
        if (priority !== goal.priority) {
            const tempGoal: Goal = goal
            tempGoal.priority = priority

            const goalRequest: UpdateGoal = {
                id: goal.id,
                name: goal.name,
                type: goal.type,
                owner: goal.owner,
                description: goal.description,
                priority: priority,
                status: goal.status,
                dueDate: goal.dueDate
            }

            dialogBody = () => {
                return (
                    <ConfirmSettingsDialog confirmMessage={"Are you sure you want to update the goal's priority?"}
                                           type={"Priority"}
                                           goal={tempGoal}
                                           goalRequest={goalRequest}
                                           handleDialogClose={handleDialogClose}
                                           loadingMethod={setPriorityLoading}
                                           setGoal={setGoal}
                                           admin={isAdmin}/>
                );
            }
            setDialogOpen(true);
        }
    }

    const handleOwnerUpdate = async () => {
        if (owner.id !== goal.owner) {
            const tempGoal: Goal = goal
            tempGoal.owner = owner.id

            const goalRequest: UpdateGoal = {
                id: goal.id,
                name: goal.name,
                type: goal.type,
                owner: owner.id,
                description: goal.description,
                priority: goal.priority,
                status: goal.status,
                dueDate: goal.dueDate
            }

            dialogBody = () => {
                return (
                    <ConfirmSettingsDialog confirmMessage={"Are you sure you want to update the goal's owner?"}
                                           type={"Owner"}
                                           goal={tempGoal}
                                           goalRequest={goalRequest}
                                           handleDialogClose={handleDialogClose}
                                           loadingMethod={setOwnerLoading}
                                           setGoal={setGoal}
                                           admin={isAdmin}/>
                );
            }

            setDialogOpen(true);
        }
    }

    const handlePriorityChange = (event, newValue) => {
        setPriority(newValue)
    }

    const handleAssignPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAssignPopoverAnchorEl(event.currentTarget);
    };
    const handleAssignPopoverClose = () => {
        setAssignPopoverAnchorEl(null);
        handleAssigneeUpdate()
    };
    const assignOpen = Boolean(assignPopoverAnchorEl);
    const assignID = assignOpen ? 'assign-popover' : undefined;

    const handleCampaignPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCampaignPopoverAnchorEl(event.currentTarget);
    };

    const handleCampaignPopoverClose = () => {
        setCampaignPopoverAnchorEl(null);
        handleCampaignUpdate()
    };

    const campaignOpen = Boolean(campaignPopoverAnchorEl);
    const campaignPopID = campaignOpen ? 'campaign-popover' : undefined;


    const handleDueDatePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDueDatePopoverAnchorEl(event.currentTarget)
    }
    const handleDueDatePopoverClose = () => {
        setDueDatePopoverAnchorEl(null)
        handleDueDateUpdate()
    }
    const dueDateOpen = Boolean(dueDatePopoverAnchorEl);
    const dueDateID = dueDateOpen ? 'due-date-popover' : undefined;

    const handlePriorityPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPriorityPopoverAnchorEl(event.currentTarget)
    }
    const handlePriorityPopoverClose = () => {
        setPriorityPopoverAnchorEl(null)
        handlePriorityUpdate()
    }
    const priorityOpen = Boolean(priorityPopoverAnchorEl);
    const priorityID = priorityOpen ? 'priority-popover' : undefined;

    const handleOwnerPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOwnerPopoverAnchorEl(event.currentTarget)
    }
    const handleOwnerPopoverClose = () => {
        setOwnerPopoverAnchorEl(null)
        handleOwnerUpdate()
    }
    const ownerOpen = Boolean(ownerPopoverAnchorEl);
    const ownerID = ownerOpen ? 'owner-popover' : undefined;

    useEffect(() => {
        getCurrentAssignees()
        getCurrentOwner()
        getCurrentDueDate()
        getCurrentCampaigns()
    }, [])

    useEffect(() => {
        checkAccess()
            .then((r) => {
                if (r && r.admin_permission && r.user_permission) {
                    setIsAdmin(true)
                }
            })
    }, [])

    return (
        <Box>
            <Grid container
                  direction={"column"}
                  alignItems={"stretch"}
                  justifyContent={"center"}
                  spacing={2}>
                <Grid item>
                    <Tooltip title={"Campaigns this goal is attached to"}
                             key={"campaign-tooltip"}>
                        <Button
                            variant="text"
                            onClick={handleCampaignPopoverClick}
                            disabled={campaignLoading}
                        >
                            Campaigns
                        </Button>
                    </Tooltip>
                    <Box sx={{display: 'flex', flexDirection: 'row', mb: 1, marginLeft: 0.5}}>
                        <Stack direction="column"
                               spacing={1}>
                            {multiCampaignToAssign.length > 0 && multiCampaignToAssign.map((campaign) =>
                                <Chip label={campaign.name}
                                      key={campaign.id}
                                      id={campaign.id}
                                      size={'small'}/>)
                            }
                        </Stack>
                    </Box>
                    <Popover
                        //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                        disableEnforceFocus
                        id={campaignPopID}
                        open={campaignOpen}
                        anchorEl={campaignPopoverAnchorEl}
                        onClose={handleCampaignPopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                direction: 'column',
                                px: 0.2,
                                gap: "10px",
                                margin: 1
                            }}>
                            <Card sx={{border: "none", boxShadow: "none"}}>
                                <CardHeader title={"Assign goal to campaigns"}
                                            sx={{textAlign: "center"}}/>
                                <Divider/>
                                <CardContent>
                                    <Autocomplete
                                        sx={{
                                            width: "15vw",
                                        }}
                                        value={multiCampaignToAssign}
                                        onChange={(event, newValue) => {
                                            setMultiCampaignToAssign(newValue);
                                        }}
                                        multiple={true}
                                        id="Campaign_Autocomplete"
                                        limitTags={1}
                                        options={Object.values(campaigns)}
                                        defaultValue={multiCampaignToAssign}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Assign Campaigns"/>}
                                        renderOption={(props, option) => (
                                            <Box component="li"
                                                 sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                <Chip label={option.name}
                                                      key={option.id}
                                                      id={option.id}
                                                      size={'small'}/>
                                            </Box>
                                        )}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Popover>
                </Grid>
                <Grid item>
                    <Divider sx={{mt: 2}}/>
                    <Tooltip title={"The individuals that will be part of the goal and responsible for it’s completion"}
                             key={"assignee-tooltip"}>
                        <Button
                            variant="text"
                            onClick={handleAssignPopoverClick}
                            disabled={assignLoading}
                        >
                            Assignees
                        </Button>
                    </Tooltip>
                    <Box sx={{display: 'flex', flexDirection: 'row', mb: 1}}>
                        <AvatarGroup max={5}>
                            {multiUserToAssign.length > 0 && multiUserToAssign.map((user) =>
                                <Tooltip title={user.displayName}
                                         key={user.id}>
                                    <Avatar
                                        src={user.avatar}
                                        sx={{
                                            backgroundColor: '#fff'
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </AvatarGroup>
                    </Box>
                    <Divider sx={{mt: 2}}/>
                    <Popover
                        //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                        disableEnforceFocus
                        id={assignID}
                        open={assignOpen}
                        anchorEl={assignPopoverAnchorEl}
                        onClose={handleAssignPopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {mainCtx.members != null &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    direction: 'column',
                                    px: 0.2,
                                    gap: "10px",
                                    margin: 1
                                }}>
                                <Card sx={{border: "none", boxShadow: "none"}}>
                                    <CardHeader title={"Assign users to the goal"}
                                                sx={{textAlign: "center"}}/>
                                    <Divider/>
                                    <CardContent>
                                        <Autocomplete
                                            sx={{
                                                width: "15vw",
                                            }}
                                            value={multiUserToAssign}
                                            onChange={(event, newValue) => {
                                                setMultiUserToAssign(newValue);
                                            }}
                                            multiple={true}
                                            id="Assignee_Autocomplete"
                                            limitTags={1}
                                            options={Object.values(mainCtx.members)}
                                            defaultValue={multiUserToAssign}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.displayName}
                                            renderInput={(params) => <TextField {...params} label="Assign Members"/>}
                                            renderOption={(props, option) => (
                                                <Box component="li"
                                                     sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <Avatar alt={option.displayName}
                                                            src={option.avatar}/>
                                                    <Typography
                                                        component="div"
                                                        sx={{margin: "auto"}}
                                                    >
                                                        {option.displayName}
                                                    </Typography>
                                                </Box>
                                            )}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        }
                    </Popover>
                </Grid>
                <Grid item>
                    <Tooltip title={"The date that the goal should be completed by"}
                             id={"due-date-tooltip"}>
                        <Button
                            variant="text"
                            onClick={handleDueDatePopoverClick}
                            disabled={dueDateLoading}
                        >
                            Due Date
                        </Button>
                    </Tooltip>
                    <Typography variant={"body1"}
                                sx={{ml: 1, mb: 1}}>
                        {dueDate != null && dueDate.toString().slice(0, 16)}
                    </Typography>
                    <Divider sx={{mt: 2}}/>
                    <Popover
                        //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                        disableEnforceFocus
                        id={dueDateID}
                        open={dueDateOpen}
                        anchorEl={dueDatePopoverAnchorEl}
                        onClose={handleDueDatePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                px: 0.2,
                                gap: "10px",
                                margin: 1,
                            }}>
                            <Card sx={{border: "none", boxShadow: "none"}}>
                                <CardHeader title={"Set the due date of the goal"}
                                            sx={{textAlign: "center"}}/>
                                <Divider/>
                                <CardContent>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <StaticDatePicker
                                            label="Due Date"
                                            value={dueDate}
                                            onChange={(newValue) => setDueDate(newValue)}
                                            renderInput={props => <TextField/>}
                                            components={{
                                                SwitchViewIcon: () => (
                                                    <UnfoldMore fontSize="small" sx={{ color: useTheme().palette.mode === 'dark' ? "#f2bc53" : '#6b778c', }} />
                                                ),
                                                LeftArrowIcon: () => (
                                                    <KeyboardArrowLeft sx={{ color: useTheme().palette.mode === 'dark' ? "#f2bc53" : '#6b778c', }} />
                                                ),
                                                RightArrowIcon: () => (
                                                    <KeyboardArrowRight sx={{ color: useTheme().palette.mode === 'dark' ? "#f2bc53" : '#6b778c', }} />
                                                ),
                                            }}
                                        />
                                    </LocalizationProvider>
                                </CardContent>
                            </Card>
                        </Box>
                    </Popover>
                </Grid>
                <Grid item>
                    <Tooltip title={"How important is this goal relative to others that are currently ongoing"}
                             id={"priority-tooltip"}>
                        <Button
                            variant="text"
                            onClick={handlePriorityPopoverClick}
                            disabled={priorityLoading}
                        >
                            Priority
                        </Button>
                    </Tooltip>
                    <Box sx={{mb: 1}}>
                        {getPriorityLabel(goal.priority)}
                    </Box>
                    <Divider sx={{mt: 2}}/>
                    <Popover
                        //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                        disableEnforceFocus
                        id={priorityID}
                        open={priorityOpen}
                        anchorEl={priorityPopoverAnchorEl}
                        onClose={handlePriorityPopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                px: 0.2,
                                gap: "10px",
                                margin: 1,
                            }}>
                            <Card sx={{border: "none", boxShadow: "none"}}>
                                <CardHeader title={"Set the priority of the goal"}
                                            sx={{textAlign: "center"}}/>
                                <Divider/>
                                <CardContent>
                                    <GoalPriorityRating rating={goal.priority}
                                                        handleChange={handlePriorityChange}
                                                        readOnly={false}
                                                        source={"Create"}/>
                                </CardContent>
                            </Card>
                        </Box>
                    </Popover>
                </Grid>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={handleOwnerPopoverClick}
                        disabled={ownerLoading}
                    >
                        Owner
                    </Button>
                    <Box sx={{ml: 1}}>
                        {owner != null &&
                            <Tooltip title={owner.displayName}
                                     key={owner.id}>
                                <Avatar src={owner.avatar}/>
                            </Tooltip>
                        }
                    </Box>
                    <Popover
                        //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                        disableEnforceFocus
                        id={ownerID}
                        open={ownerOpen}
                        anchorEl={ownerPopoverAnchorEl}
                        onClose={handleOwnerPopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                px: 0.2,
                                gap: "10px",
                                margin: 1
                            }}
                        >
                            <Card sx={{border: "none", boxShadow: "none"}}>
                                <CardHeader title={"Set goal owner"}
                                            sx={{textAlign: "center"}}/>
                                <Divider/>
                                <CardContent>
                                    <Autocomplete
                                        sx={{
                                            width: "15vw",
                                        }}
                                        value={owner}
                                        onChange={(event, newValue) => {
                                            setOwner(newValue);
                                        }}
                                        multiple={false}
                                        id="Owner_Autocomplete"
                                        limitTags={2}
                                        options={Object.values(mainCtx.members)}
                                        defaultValue={owner}
                                        getOptionLabel={(option) => option.displayName}
                                        renderInput={(params) => <TextField {...params} label="Assign Owner"/>}
                                        renderOption={(props, option) => (
                                            <Box component="li"
                                                 sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                <Avatar alt={option.displayName}
                                                        src={option.avatar}/>
                                                <Typography
                                                    component="div"
                                                    sx={{margin: "auto"}}
                                                >
                                                    {option.displayName}
                                                </Typography>
                                            </Box>
                                        )}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Popover>
                </Grid>
            </Grid>
            <Dialog
                //disableEnforceFocus required to open chat if Dialog opened first
                disableEnforceFocus
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="confirm-dialog"
                fullWidth
                maxWidth="md"
            >
                {dialogBody()}
            </Dialog>
        </Box>
    );
}

export default GoalSettings;