import type {FC} from 'react';
import React from 'react';
import logoPath from "./MondelezLogo.svg"

interface BullRedLogoProps {
    [key: string]: any;
}

const MondelezLogo: FC<BullRedLogoProps> = (props) => (
    <img
        alt="Mondelez Logo"
        src={logoPath}
        height={100}
        width={200}
        style={{
            margin: 1
        }}
        {...props}
    />
);

export default MondelezLogo;