import {FC, useRef, useState} from "react";
import {LeaderboardItemProps} from "./Leaderboard";
import {Box, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp, faCrown} from "@fortawesome/free-solid-svg-icons";
import {Leaderboard} from "../../../../cog/keystone/leaderboard";
import {useTheme} from "@mui/material/styles";

// https://codepen.io/saarthak-slathia/pen/VwWKxbm
const LeaderSummaryItem: FC<Leaderboard> = ({position, avatar, username, value, difference}) => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    const fontSizes = {
        md:'1.5rem',
        xs: '1rem',
    }

    const parentRef = useRef(null);
    const [avatarSize, setAvatarSize] = useState<[]>()


    return (
        <Box ref={parentRef} sx={{
            // fontFamily: '"Josefin Sans", Arial',
            display: 'flex',
            margin: '1rem 0',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            // transform: position == 1 ? 'translateY(-15%)' : '',
            borderRadius: '15px',
        }}>
            {/*<Typography*/}
            {/*    component="div"*/}
            {/*    sx={{*/}
            {/*        // paddingTop: '1rem',*/}
            {/*        // color: '#EEEEEE',*/}
            {/*        fontSize: {*/}
            {/*            md:'1.5rem',*/}
            {/*            xs: '1rem',*/}
            {/*        },*/}
            {/*    }}*/}
            {/*>*/}
            {/*    { position }*/}
            {/*</Typography>*/}
            {position != 1 ?
                position == 2 ?
                    <Box
                        component="i"
                        sx={{
                            fontSize: {
                                md:'1.5rem',
                                xs: '1rem',
                            },
                            color: 'silver'
                        }}
                    >
                        <FontAwesomeIcon icon={faCrown}/>
                    </Box> :
                    <Box
                        component="i"
                        sx={{
                            fontSize: {
                                md:'1.5rem',
                                xs: '1rem',
                            },
                            color: '#b66300'
                        }}
                    >
                        <FontAwesomeIcon icon={faCrown}/>
                    </Box> :
                <Box
                    component="i"
                    sx={{
                        fontSize: {
                            md:'1.5rem',
                            xs: '1rem',
                        },
                        color: 'gold'
                    }}
                >
                    <FontAwesomeIcon icon={faCrown}/>
                </Box>
            }

            <Box
                component="img"
                sx={{
                    width: {
                        // lg: position == 0 ? '175px' : '150px',
                        md: position == 1 ? '135px' : '110px',
                        sm: position == 1 ? '115px' : '90px',
                        xs: position == 1 ? '95px' : '70px',
                    },
                    // background: '#fff',
                    borderRadius: '50%',
                    border: `5px solid ${position == 1 ? 'gold'
                        : position == 2 ? 'silver'
                    : '#b66300'}`,
                    boxShadow: `0 2px 20px ${position == 1 ? 'gold' : useTheme().palette.background.paper}`,
                    margin: '1rem 0'
                }}
                alt={ username }
                src={ avatar }
            />
            <Typography
                component="div"
                sx={{
                    fontSize: {
                        md:'1.5rem',
                        xs: '1rem',
                    },
                    // color: '#EEEEEE'
                }}
            >
                { username }
            </Typography>
            <Typography
                component="div"
                sx={{
                    fontSize: {
                        lg: '27px',
                        md: '27px',
                        sm: '13px',
                        xs: '13px'
                    },
                    color: useTheme().palette.info.main,
                }}
            >
                { formatter.format(value) }
            </Typography>
        </Box>
    )
}

export default LeaderSummaryItem