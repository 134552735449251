/* eslint-disable */

import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from '../../pages/authentication/Login';
import { useMainContext } from '../../contexts/MainContext';
import {BiStore} from "../../cog/bitool";
import AuthorizationRequired from "./AuthorizationRequired";
import {AdminStore} from "../../cog/adminManagement";
import DashboardSkeleton from "../../pages/app/bitool/dashboard/DashboardSkeleton";
import HomeIssueSkeleton from "../../pages/app/keystone/summary/Skeleton";

interface DashGuardProps {
    children: ReactNode;
}

const DashGuard: FC<DashGuardProps> = (props) => {
    const { children } = props;
    const ctx = useMainContext();
    const [authorised, setAuthorised] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    let url = window.location.pathname.toString()
    const checkAccess = async (): Promise<any> => {
        try {
            return (await AdminStore.CheckAccess(
                {resource: {object_id:url.split("/").pop(), object_type: "dashboard"},
                    subject: {subject_id: ctx.user.id, subject_type: "user", optional_relation: ""},
                    permission_type: "view"}))
        } catch (e) {
            console.error(`${e}`);
        }
    }

    checkAccess()
        .then((r)=> {
            console.log(r)
            if (r == true) {
                setAuthorised(true)
                setIsLoading(false)
        } else {
            setAuthorised(false)
            setIsLoading(false)}
        })

    if (!isLoading) {
        if (authorised) {
            return <>{children}</>
        }
        else return <AuthorizationRequired/>} else {
        return <DashboardSkeleton/>
    }
};

DashGuard.propTypes = {
    children: PropTypes.node
};

export default DashGuard;
