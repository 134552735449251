import React from 'react';
import type { FC } from 'react';
import logoPath from "./PNSLogo.svg"

interface PNSProps {
  [key: string]: any;
}

const PNSLogo: FC<PNSProps> = (props) => (
  <img
    alt="PNS Logo"
    src={logoPath}
    height={100}
    width={100}
    style={{
      margin: 1
    }}
    {...props}
  />
);

export default PNSLogo;