import React, {FC} from "react";
import { History } from "../../../../../cog/keystone/history/index";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { Info} from "@mui/icons-material";
import currencyFormatter from "../../../../../utils/currencyFormatter";
import {alpha, Theme, useTheme} from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import KeystoneCustomIcon from "../../../../../icons/KeystoneCustomIcon";
const useStyles = makeStyles((theme:Theme) => ({
  card: {
    border: '1px solid #d1d1d1',
    fontSize: '0.875rem',
    fontWeight: '700',
    fontFamily: 'Roboto',
    backgroundColor: 'f9f9f9',
    minHeight: 200
  },
}));

let myIcon = () => {
  return (

      <div>
      </div>
  )
}

interface DetailSectionProps {
  org: string;
  oppType: string;
  mValue: number;
  description: string;
  history: History[];
  status: string;
  repeat: number;
}

const DetailSection: FC<DetailSectionProps> = ({org, mValue, description, history, oppType, status, repeat}) => {
  const formattedMValue = currencyFormatter(org, mValue as unknown as string)
  const classes = useStyles();

  let mValueCardHeader = ""
  if (oppType === "Sales Decline") {
    mValueCardHeader = "Daily Sales Difference"
  } else if (oppType === "Returns Increase") {
    mValueCardHeader = "Daily Returns Difference"
  } else {
    mValueCardHeader = "Potential Gain"
  }

  const splitDescription = () => {
    let descriptions = description.split("\n")
    let keyValues: Record<string,string> = {}
    keyValues[mValueCardHeader] = formattedMValue
    let subValues :string[]
    descriptions.forEach((desc) => {
      subValues = desc.split(":")
      if(subValues.length>1)
      keyValues[subValues[0]] = subValues[1].trim()
    })
    keyValues['Status'] = status
    return keyValues
  }

  function breakString(inputString: string, maxLength: number): string[] {
    // Check if the inputString is already within the maxLength or contains white space characters
    if (inputString.length <= maxLength || /\s/.test(inputString)) {
      return [inputString];
    }

    const result: string[] = [];
    let currentSubstring = '';

    for (const char of inputString) {
      if (currentSubstring.length === maxLength) {
        // If the current substring has reached the maxLength and the current character is a space,
        // push the current substring to the result array and reset the currentSubstring.
        result.push(currentSubstring);
        result.push(' ')
        currentSubstring = '';
      } else {
        // Otherwise, continue building the current substring.
        currentSubstring += char;
      }
    }

    // Push the remaining part of the string, if any, to the result array.
    if (currentSubstring.length > 0) {
      result.push(currentSubstring);
    }

    return result;
  }

  const getIcon = (name:string, index:number) => {
      myIcon = () => {
        return(
            <KeystoneCustomIcon key={name} name={name.replace(/\s/g,'')
                .replace('(Days)', '')
                .replace('-Sufficient', '')
                .replace('-Insufficient', '')}
                                position={index}
                                height={100}
            />

        )
    }
    return myIcon()
  }

  return (
    <Box>
      <Grid container spacing={4} sx={{ marginBottom: 2 }} >
        <Grid item xs={12} xl={12}>
          <Typography variant={"h3"} sx={{ mb:2 }}>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap"
            }}>
              <Info sx={{ fontSize: "2rem",mr:2 }} />
              Details
            </Box>
          </Typography>
        </Grid>
        {Object.entries(splitDescription()).map((entry, index)=> {
          return (
            <Grid key={index} item xl={3} md={4} xs={12}>
              <Card
                key={index}
                className={classes.card}
                sx={{
                  maxHeight: 400,
                  minHeight: 400,
                  height: "100%",
                  boxShadow: 2,
              }}>
                <CardContent key={index} sx={{ height: '100%', }} >
                  <Box sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection:'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    {getIcon(entry[0], index)}
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <Typography variant='body2'>
                        {entry[0]}
                      </Typography>
                      <Typography
                        variant='h6'
                        sx={{
                          paddingBottom: "0px",
                          whiteSpace: 'pre-wrap',
                          overflowX: "auto",
                          overflowY: "hide",
                          display: "flex",
                          flexDirection: "row",
                          fontWeight: 'fontWeightBold',
                          "&::-webkit-scrollbar": {
                            height: 10,
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: useTheme().palette.background.default,
                            borderRadius: 1,
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: useTheme().palette.text.secondary,
                            borderRadius: 2,
                          }
                        }}
                      >
                        {breakString(entry[1], 15)}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
          </Grid>
          )
        })}
      </Grid>
    </Box>
  );
}

export default DetailSection