import SKUName from "./SKUName";
import DateLastOrdered from "./DateLastOrdered";
import CustomerName from "./CustomerName";
import OpportunityType from "./OpportunityType"
import SKUCode from "./SKUCode"
import Status from "./Status"
import PotentialGain from "./PotentialGain"
import DateLastSold from "./DateLastSold"
import AverageOrderAmount from "./AverageOrderAmount"
import ValueLastSold from "./ValueLastSold"
import PriceLastSold from "./PriceLastSold"
import StoreRanking from "./StoreRanking"
import Calculation from "./Calculation"
import SalesFrequency from "./SalesFrequency"
import StockValue from "./StockValue"
import ProductDetail from "./ProductDetail"
import NetSalesValue from "./NetSalesValue"
import ProductRanking from "./ProductRanking"
import AverageReplenishmentCycle from "./AverageReplenishmentCycle"
import CategoryAnalysis from "./CategoryAnalysis"
import IsCustomerActive from "./IsCustomerActive"
import QuantityReturned from "./QuantityReturned"
import QuantitySold from "./QuantitySold"
import RegionAnalysis from "./RegionAnalysis"
import ValueReturned from "./ValueReturned"


const IconList = {
    AverageOrderAmount,
    AverageReplenishmentCycle,
    Calculation,
    CategoryAnalysis,
    CustomerName,
    DateLastOrdered,
    DateLastSold,
    IsCustomerActive,
    NetSalesValue,
    OpportunityType,
    PotentialGain,
    PriceLastSold,
    ProductDetail,
    ProductRanking,
    QuantityReturned,
    QuantitySold,
    RegionAnalysis,
    SalesFrequency,
    SKUCode,
    SKUName,
    Status,
    StockValue,
    StoreRanking,
    ValueLastSold,
    ValueReturned,
}

export default IconList