import React, {FC, useEffect, useRef, useState} from "react";
import {Box} from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CogChat from "./chat/CogChat";
import {useKeystoneContext} from "../../../contexts/KeystoneContext";
interface CogSpeedDial {
}

const CogSpeedDial :FC<CogSpeedDial> = () => {

    const actions = [
        // { icon: , name: 'Share' },
        // { icon: <FileCopyIcon />, name: 'Copy' },
        // { icon: <SaveIcon />, name: 'Save' },
        // { icon: <PrintIcon />, name: 'Print' },
        { icon: <ContactSupportIcon color="primary" />, name: 'Contact Support', operation:'contact'},
    ];


    const keystoneContext = useKeystoneContext()
    const [showFeedback, setShowFeedback] = useState<boolean>(false)
    const [chatOpen, setChatOpen] = React.useState<boolean>(false)
    const [speedDialRight, setSpeedDialRight] = useState(16)
    const [cogCahtRight, setCogChatRight] = useState(100)

    const feedbackChecker = (url: string) => {
        switch (true) {
            case url.includes('home'):
                setShowFeedback(true)
                break;
            case url.includes('explore'):
                setShowFeedback(true)
                break;
            case url.includes('keystone'):
                setShowFeedback(true)
                break;
            case url.includes('opportunities'):
                setShowFeedback(true)
                break;
            case url.includes('opportunity'):
                setShowFeedback(true)
                break;
            case url.includes('goal'):
                setShowFeedback(true)
                break;
            case url.includes('campaigns'):
                setShowFeedback(true)
                break;
            case url.includes('reporting'):
                setShowFeedback(true)
                break;
            default:
                setShowFeedback(false)
        }
    }

    const handleClick= ((e,operation:String)=> {
        e.preventDefault();
        setChatOpen(!chatOpen)
    })

    const handleClose = () => {
        setChatOpen(false);
    };

    useEffect(() => {

        if(keystoneContext.filterOpen || keystoneContext.excludeFilterOpen){
            window.requestAnimationFrame(()=>{
                if(document.getElementsByClassName("PopoutSideBar") && document.getElementsByClassName("PopoutSideBar").length >0){

                    let sideBarWidth = document.getElementsByClassName("PopoutSideBar").item(0).clientWidth
                    setSpeedDialRight( sideBarWidth + 16)
                    setCogChatRight(sideBarWidth + 100)
                }
            })
        }else {
            setSpeedDialRight(16)
            setCogChatRight(100)
        }

    }, [keystoneContext.filterOpen, keystoneContext.excludeFilterOpen]);


    useEffect(() => {
        let url = window.location.pathname.toString()
        feedbackChecker(url)
    }, [window.location.pathname]);

    return(
        showFeedback?
        <Box
            sx={{
                zIndex: 2000,
                display: (!location.pathname.includes('--') ? "block" : "none")
            }}>
            <Box
                sx={{
                    display: (chatOpen ? "flex" : "none"),
                    flexDirection: 'column',
                    position: 'fixed',
                    bottom: 100,
                    right: cogCahtRight,
                    height: 640,
                    width: 420,
                    transform: 'translateZ(0px)'
                }}>
                <CogChat handleClose={handleClose}/>


            </Box>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 8,
                    right: 8,
                    height: 320,
                    transform: 'translateZ(0px)',
                    flexGrow: 1
                }}>
                <SpeedDial
                    onClick={(e) => {

                    }}
                    ariaLabel="SpeedDial basic example"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: speedDialRight,
                    }}
                    icon={<SupportAgentIcon/>}
                    FabProps={{
                        size: "small"
                    }}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={(e) => {
                                console.log("CogSpeedDial action onClick e", e)
                                e.preventDefault()
                                handleClick(e, action.operation)
                            }}

                        />
                    ))}
                </SpeedDial>
            </Box>
        </Box>
            :
            <div/>

    )
}

export default CogSpeedDial