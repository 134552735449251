import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {FC, useEffect, useState} from "react";
import {FilterItem} from "../../../../../cog/keystone/filter/Filter";
import {DateRange} from "@mui/x-date-pickers-pro/DateRangePicker";
import {FilterItemProps} from "../Filter";
import {Typography} from "@mui/material";
import {round} from "lodash";

const NumberRange: FC<FilterItemProps> = ({ name, header, filterData, filters, setFilters}) => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    const marks = [
        {
            value: filterData[0],
            label: formatter.format(filterData[0]),
        },
        {
            value: filterData[1],
            label: formatter.format(filterData[1]),
        },
    ];

    const handleChange = (event: Event, newValue: number | number[]) => {
        const idx = filters.findIndex(fil => fil.header === header)
        const newItem = {prefix: "AND", operation: "BETWEEN", values: newValue as number[], header: header} as FilterItem

        if (idx > -1) {
            setFilters([
                ...filters.slice(0, idx),
                newItem,
                ...filters.slice(idx + 1)
            ]);
            // setFilters([...filters].splice(idx, 1, newItem));
        } else {
            setFilters([...filters, newItem])
        }
    }

    const getValue = (): number[] => {
        const idx = filters.findIndex(fil => fil.header === header)
        return (idx > -1) ? filters[idx].values as number[] : [filterData[0], filterData[1]]
    }

    return (
        <Box sx={{width: "90%"}}>
            <Typography variant="h6" gutterBottom>
                { name }
            </Typography>
            <Slider
                valueLabelDisplay="auto"
                value={getValue()}
                onChange={handleChange}
                min={filterData[0]}
                max={filterData[1]}
                step={round((filterData[1] - filterData[0]) / 1000)}
                marks={marks}
                sx={{m: 3}}
            />
        </Box>
    );
}

export default NumberRange