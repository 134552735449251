/* eslint-disable */

import {API, PerformJSONRPCRequest} from '../../api/jsonrpc';
import Widget from "./Widget";

export interface FindAllRequest {
    dashboardId: string
    report?: boolean
    embed?: boolean
}

export interface FindAllResponse {
    widgets: Widget[];
}

export interface FindOneRequest {
    id: string
}

export interface FindOneResponse {
    widget: Widget;
}

export interface UpdateOneRequest {
    widget: Widget;
}

export interface UpdateOneResponse {
    id: string
}

export interface UpdateManyRequest {
    widgets: Widget[];
}

export interface UpdateManyResponse {
    ids: string[]
}

export interface CreateOneRequest {
    widget: Widget;
}

export interface CreateOneResponse {
    id: string
}

export interface DeleteOneRequest {
    id: string
}

export interface DeleteOneResponse {
    deleted: boolean;
}

const Store = {
    serviceProvider: 'Widget-Store',
    async FindAll(request: FindAllRequest): Promise<FindAllResponse> {
        const response = await PerformJSONRPCRequest({
            api: request.report || request.embed ? API.Public : API.Authorised,
            method: `${Store.serviceProvider}.FindAll`,
            request: [{
                'dashboardId': request.dashboardId
            }]
        });
        return {
            widgets: (response.widgets) ? response.widgets.map((mem) => (new Widget(mem))) : [],
        };
    },
    async FindOne(request: FindOneRequest): Promise<FindOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.FindOne`,
            request: [{
                'id': request.id
            }]
        });
        return {
            widget: response.widget
        };
    },
    async UpdateOne(request: UpdateOneRequest): Promise<UpdateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateOne`,
            request: [{
                'widget': request.widget
            }]
        });
        return {
            id: response.id
        };
    },
    async UpdateMany(request: UpdateManyRequest): Promise<UpdateManyResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.UpdateMany`,
            request: [{
                'widgets': request.widgets
            }]
        });
        return {
            ids: response.ids
        };
    },
    async CreateOne(request: CreateOneRequest): Promise<CreateOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.CreateOne`,
            request: [{
                'widget': request.widget
            }]
        });
        return {
            id: response.id
        };
    },
    async DeleteOne(request: DeleteOneRequest): Promise<DeleteOneResponse> {
        const response = await PerformJSONRPCRequest({
            api: API.Authorised,
            method: `${Store.serviceProvider}.DeleteOne`,
            request: [{
                'id': request.id
            }]
        });
        return {
            deleted: response.deleted
        };
    },
};

export default Store;
