/* eslint-disable */

import type { FC } from 'react';
import { useNavigate, useRoutes } from "react-router-dom";
import {SnackbarProvider, useSnackbar} from 'notistack';
import {ThemeProvider, StyledEngineProvider, CssBaseline, IconButton} from '@mui/material';
import GlobalStyles from './components/GlobalStyles';
import SplashScreen from './components/SplashScreen';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import { createCogTheme } from './theme';
import {baseRoutes, dashboardRoutes} from "./contexts/routes";
import {useMainContext} from "./contexts/MainContext";
import React, { useEffect } from "react";
import { LicenseInfo } from '@mui/x-data-grid-pro';
import ReactGA from "react-ga4";
import config from "react-global-configuration";
import {Close} from "@mui/icons-material";

LicenseInfo.setLicenseKey(
  '9ba2f2a858da258f9002a4cc22039a9dT1JERVI6NDA3ODEsRVhQSVJZPTE2ODAyNjkwMzgwMDAsS0VZVkVSU0lPTj0x',
);

const withRouter = WrappedComponent => props => {
  const navigate = useNavigate();

  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
    />
  )
}

const App: FC = () => {
  const { settings } = useSettings();
  const ctx = useMainContext();
  const content = useRoutes([...baseRoutes, ...dashboardRoutes])

  useEffect(() => {
      //Initialize Google Analytics
      if(ctx.user != null) {
        ReactGA.initialize(config.get('analytics4ID'), {
          gaOptions: {
            userID: ctx.user.id,
            user_id: ctx.user.id
          }
        })
        //To report page view
        ReactGA.send({ hitType: "pageview", userID: ctx.user.id , page: window.location.pathname + window.location.search });

      }
    }, [ctx.user])


  useScrollReset();

  const theme =
      createCogTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  // SnackbarCloseButton : adds a close button to enqueued snacks
  function SnackbarCloseButton({ skey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(skey)}>
          <Close />
        </IconButton>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          dense
          maxSnack={3}
          action={skey => <SnackbarCloseButton skey={skey}/>}
          >
          <GlobalStyles />
          { ctx.isInitialized ? content : <SplashScreen /> }
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default withRouter(App);
