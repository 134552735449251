import React, {FC, useEffect, useState} from "react";
import {Box, IconButton, InputBase, LinearProgress, Tooltip, useTheme} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import TextList from "./items/text/TextList";
import {ColumnMapDict, FilterItem} from "../../../../cog/keystone/filter/Filter";
import NumberRange from "./items/NumberRange";
import DateRange from "./items/DateRange";
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {SendOutlined} from "@mui/icons-material";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";


interface FilterProps {
    userID: string
    organizationId: string
    client: string

    filters: FilterItem[]
    setFilters: (f: FilterItem[]) => void
    setLevelMode: (b: boolean) => void

    open: boolean
    setOpen: (o: boolean) => void
    onClose?: () => void
    onClear?: () => void;
    allCampaigns?: any;
    setAllCampaigns?: (allCampaings) => void;
    allGoals?: any;
    setAllGoals?: (allGoals) => void;
}

export interface FilterItemProps {
    header: string
    name: string
    filterData: any[]
    filters: FilterItem[]
    setFilters: (f: FilterItem[]) => void
    filterGoals: any,
    filterCampaigns: any,
    searchString?: string
    viewFiltersOnly?: boolean
}

interface GetFiltersItem {
    name: string,
    type: string,
    data: any[]
}

interface FilterItemGoals {
    prefix: string
    header: string
    operation: string
    values: any
    level?: boolean
    FilterItemProps?: any
}

// {userID, organizationId, client}
const Filter: FC<FilterProps> = ({ userID, organizationId, client, filters, setFilters, setLevelMode, open, onClose, onClear, allGoals, allCampaigns }) => {
    const keystoneCtx = useKeystoneContext()
    const [getFilter, setGetFilter] = useState<Record<string, GetFiltersItem>>({})
    const [onlyFilters, setOnlyFilters] = useState<boolean>(false)
    const [localFilters, setLocalFilters] = useState<FilterItem[]>(filters)
    const [colMap, setColMap] = useState<ColumnMapDict>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [searchString, setSearchString] = useState("")
    const { setAppliedFiltersCount } = useKeystoneContext()

    const handleFilterClose = () => {
        // setFilters(localFilters)
        onClose()
    }

    // The drawer doesn't really call handleClose(), so instead we do this to compensate
    useEffect(() => {
        if (open == false) {
            // handleFilterClose()
        }
    }, [open])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    }

    const handleOnlyFilters = () => {
        setOnlyFilters(!onlyFilters)
    }

    const handleClear = () => {
        setLocalFilters(keystoneCtx.defaultFilters)
        keystoneCtx.setClickThroughFilters([])
        onClear()
    }

    // This one updates the keystone Filters whenever localFilters have changed
    useEffect(() => {
        if (localFilters != null && keystoneCtx.filters != null) {
            if (localFilters.length != keystoneCtx.filters.length) {
                keystoneCtx.setFilterHasChanged(true)
            }
        }
        setFilters(localFilters)
    }, [localFilters])

    useEffect(() => {
        setIsLoading(true)
        if (localFilters != null && getFilter != {} && colMap != null) {
            setIsLoading(false)
        }
    }, [colMap, localFilters, getFilter])

    useEffect(() => {
        if (keystoneCtx.colMap != null) {
            setColMap(keystoneCtx.colMap)
        }
    }, [keystoneCtx.colMap])

    // this one might clash with the one that does the other way around
    useEffect(() => {
        if (keystoneCtx.filterableFields != {}) {
            setGetFilter(keystoneCtx.filterableFields)
        }
    }, [keystoneCtx.filterableFields])

    // Calculate the number of applied filters whenever localFilters change
    useEffect(() => {

        // Filter the 'localFilters' array to get only filters which have at least one value selected
        const activeCategoryHeaders = localFilters
            .filter(filter => filter.values.length > 0) // Keep only filters with selected values
            .map(filter => filter.header) // Extract the headers of active filter items

        // Find unique headers among the active filter items    
        const uniqueActiveCategories = [...new Set(activeCategoryHeaders)] 

        // Set count of unique filter categories
        setAppliedFiltersCount(uniqueActiveCategories.length)
        // console.log("Number of filters applied", uniqueActiveCategories.length)

    }, [localFilters]);

    return (
        <div>
            {/*<Modal*/}
            {/*    open={open}*/}
            {/*    onClose={handleFilterClose}*/}
            {/*    aria-labelledby="modal-modal-title"*/}
            {/*    aria-describedby="modal-modal-description"*/}
            {/*>*/}
                <Box
                    component="form"
                    // sx={style}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', pb: '10px'}}>
                        <IconButton type="button" sx={{p: '10px'}} aria-label="search">
                            <SearchIcon
                            sx={{
                                color: useTheme().palette.primary.main
                            }}/>
                        </IconButton>
                        <InputBase
                            sx={{ml: 1, flex: 1}}
                            placeholder="Search to Filter Items"
                            inputProps={{'aria-label': 'Search to Filter Items'}}
                            value={searchString}
                            onChange={handleChange}
                        />
                        <Tooltip title={"Apply Filters"}>
                            <IconButton size="large" color="primary" onClick={handleFilterClose}>
                                <SendOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Clear All Filters"}>
                            <IconButton size="large" color="primary" onClick={handleClear}>
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Show Filtered Items"}>
                            <IconButton size="large" color="primary" onClick={handleOnlyFilters}>
                                { onlyFilters ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {keystoneCtx.filterableSearching ?
                        <LinearProgress
                            sx={{
                                marginLeft: 1,
                                marginRight: 1,
                                height: "0.5vh",
                                marginBottom: "0.5vh",
                                justifySelf: "center"
                            }}/> : <Box sx={{
                            height: "1vh"
                        }}/>
                    }
                    <Box sx={{
                        pr: "5px",
                        overflowY: "auto",
                        maxHeight: "84vh",
                        "&::-webkit-scrollbar": {
                            width: "8px",
                            height: "10px"
                        },
                        "&::-webkit-scrollbar-thumb": {
                            borderRadius: '15px', background: '#c2c9d2'
                        }
                    }}>
                        {keystoneCtx.filtersReady == true
                            && colMap != null
                            && Object.keys(getFilter).length > 0
                            && Object.keys(getFilter).map((key) => {
                            if (key == colMap.Overall_Hierarchy_1) {
                                const item = getFilter[key]
                                const itemProps: FilterItemProps = {
                                    name: item.name,
                                    header: key,
                                    filterData: item.data,
                                    filters: localFilters,
                                    setFilters: setLocalFilters,
                                    viewFiltersOnly: onlyFilters,
                                    filterGoals: allGoals,
                                    filterCampaigns: allCampaigns,
                                }
                                switch (item.type) {
                                    case "TEXT":
                                        return <div key={key}>
                                            <TextList {...itemProps} searchString={searchString}/>
                                        </div>
                                        break
                                    case "NUMBER":
                                        return <div key={key}>
                                            <NumberRange {...itemProps} />
                                        </div>
                                        break
                                    case "DATE":
                                        return <div key={key}>
                                            <DateRange {...itemProps} />
                                        </div>
                                        break
                                    default:
                                        return <div> Wrong type </div>
                                    }
                            }
                            })
                        }
                        {keystoneCtx.filtersReady == true
                            && colMap != null
                            && Object.keys(getFilter).length > 0
                            && Object.keys(getFilter).map((key) => {
                            if (key != colMap.Overall_Hierarchy_1) {
                                const item = getFilter[key]
                                // console.log("Item", item)
                                const itemProps: FilterItemProps = {
                                    name: (key == 'Goal_ID') ?  "Goals" : (key == 'Campaign_ID') ? "Campaigns" : item.name ,
                                    header: key,
                                    filterData: item.data,
                                    filters: localFilters,
                                    setFilters: setLocalFilters,
                                    viewFiltersOnly: onlyFilters,
                                    filterGoals: allGoals,
                                    filterCampaigns: allCampaigns
                                }
                                    switch (item.type) {
                                        case "TEXT":
                                            return <div key={key}>
                                                <TextList {...itemProps} searchString={searchString}/>
                                            </div>
                                            break
                                        case "NUMBER":
                                            return <div key={key}>
                                                <NumberRange {...itemProps} />
                                            </div>
                                            break
                                        case "DATE":
                                            return <div key={key}>
                                                <DateRange {...itemProps} />
                                            </div>
                                            break
                                        default:
                                            return <div> Wrong type </div>
                                    }
                                }
                            })

                        }
                    </Box>
                    {/*<Button*/}
                    {/*    variant="outlined"*/}
                    {/*    onClick={() => setFiltered([])}*/}
                    {/*    sx={{ px: 1.5, mt: 1 }}*/}
                    {/*>*/}
                    {/*    Clear All*/}
                    {/*</Button>*/}
                </Box>
            {/*</Modal>*/}
        </div>
    );
}

export default Filter;
