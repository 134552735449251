import React, {ElementType, FC, useEffect, useRef, useState} from "react";
import {
    Avatar,
    Badge,
    Box,
    Button,
    CircularProgress,
    IconButton,
    LinearProgress,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    Skeleton,
    Tab,
    Tabs,
    Typography,
    useTheme,
} from "@mui/material";
import {NotificationStore} from "../cog/keystone/notifications";
import {useMainContext} from "../contexts/MainContext";
import {useNavigate} from "react-router";
import {Pending} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";

interface NotificationItem {
    id: string;
    userID: string;
    cardID: string;
    timestamp: string;
    actionType: string;
    description: string;
    platform: string;
    isRead: boolean;
};

const iconsMap: Record<string, ElementType> = {
    "Assigned": PersonAddAlt1Icon,
    "Tagged": ThreePOutlinedIcon,
    "Comment": ChatOutlinedIcon,
    "Updated": Pending,
};


const NotificationsPopover: FC = () => {
    const mainCtx = useMainContext();
    const navigate = useNavigate();
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<NotificationItem[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0);
    const activeTabRef = useRef<number>(0);
    const [isReadButtonLoading, setIsReadButtonLoading] = useState(false);
    const [isUnreadButtonLoading, setIsUnreadButtonLoading] = useState(false);
    const [isLoadingAllTab, setIsLoadingAllTab] = useState(true);
    const [isLoadingUnreadTab, setIsLoadingUnreadTab] = useState(true);
    const [isNotificationIdLoading, setIsNotificationIdLoading] = useState<string[]>([]);
    const unreadNotifications = notifications.filter(notification => !notification.isRead);

    // Fetch notifications when the selected organization changes or when the tab changes
    useEffect(() => {
        setIsLoadingAllTab(true);
        setIsLoadingUnreadTab(true);

        if (mainCtx.activeOrganization && mainCtx.activeOrganization.name) {
        setIsLoadingUnreadTab(true);
        // console.log("Fetching notifications...");
        getNotifications()
            .then((r) => {
                // console.log("Fetched notifications:", r);
                if (activeTabRef.current === 0) {
                    setNotifications(r);
                } else if (activeTabRef.current === 1) {
                    setNotifications(unreadNotifications);
                  }
                setIsLoadingAllTab(false);
                setIsLoadingUnreadTab(false);
            })
            .catch((error) => {
                console.error("Error fetching notifications:", error);
                setIsLoadingAllTab(false);
                setIsLoadingUnreadTab(false);
            });
    }}, [mainCtx.activeOrganization]);

    // Function to fetch notifications from the server
    const getNotifications = async (): Promise<NotificationItem[]> => {
        const response = await NotificationStore.FindManyByUserId({
            ClientName: mainCtx.activeOrganization.name,
            UserID: mainCtx.user.id
        })
        
        const notifications = response.notification_log;
        
        if (notifications && notifications.length > 0) {
            const sortedNotifications = notifications.sort((a, b) => {
                const timestampA = Date.parse(a.timestamp);
                const timestampB = Date.parse(b.timestamp);
                return timestampB - timestampA
            })
            return sortedNotifications
        } else {
            return []
        }
    };

    // Styling of components
    const useStyles = makeStyles((theme) => ({
        readNotification: {
            backgroundColor: "transparent",
            "&:hover": {
                backgroundColor: useTheme().palette.mode === 'dark' ? "#555555" : "#dadada",
                cursor: "pointer",
            },
        },
        unreadNotification: {
            backgroundColor: useTheme().palette.mode === 'dark' ? "#6f6f6f" : "#eeeeee",
            "&:hover": {
                backgroundColor: useTheme().palette.mode === 'dark' ? "#555555" : "#dadada",
                cursor: "pointer",
            },
        },
    }));

    const classes = useStyles();
    
    // Function to handle the opening of the popover
    const handleOpen = (): void => {
        setOpen(true);
    };
    
    // Function to handle the closing of the popover
    const handleClose = (): void => {
        setOpen(false);
    };
    
    // Function to handle tab change
    const handleTabChange = (event: React.SyntheticEvent, newTab: number): void => {
        activeTabRef.current = newTab;
        setActiveTab(newTab);
    };

    // Function to navigate to a specific card (opportunity)
    const openCard = (cardID: string) => {
        navigate(`/app/${mainCtx.activeOrganization.slug}/opportunity/${cardID}`)
    };
    
    // Function to mark a single notification as read
    const markAsRead = (notification: NotificationItem) => {
        // Add the notification id to the loading state array
        setIsNotificationIdLoading((prevLoadingIds) => [...prevLoadingIds, notification.id])
        NotificationStore.ReadOne({ ClientName: mainCtx.activeOrganization.name, ID: notification.id })
            .then(() => {
                const updatedNotifications = notifications.map((n) =>
                    n.id === notification.id ? { ...n, isRead: true } : n
                )
                setNotifications(updatedNotifications)
            })
            .catch((error) => {
                console.error("Error marking notification as read:", error);
            })
            .finally(() => {
                // Remove the notification id from the loading state array after completion
                setIsNotificationIdLoading((prevLoadingIds) => prevLoadingIds.filter((id) => id !== notification.id))
            })
    };
    
    // Function to mark all notifications as read
    const markAllAsRead = () => {
        setIsReadButtonLoading(true)
        NotificationStore.ReadMany({ ClientName: mainCtx.activeOrganization.name, UserID: mainCtx.user.id })
            .then(getNotifications)
            .then((updatedNotifications) => {
                setNotifications(updatedNotifications)
                setIsReadButtonLoading(false)
            })
            .catch((error) => {
                console.error("Error marking all notifications as read:", error)
                setIsReadButtonLoading(false)
            })
    };
    
    // Function to mark all notifications as unread
    const markAllAsUnread = () => {
        setIsUnreadButtonLoading(true)
        NotificationStore.UnreadMany({ ClientName: mainCtx.activeOrganization.name, UserID: mainCtx.user.id })
            .then(getNotifications)
            .then((updatedNotifcations) => {
                setNotifications(updatedNotifcations)
                setIsUnreadButtonLoading(false)
            })
            .catch((error) => {
                console.error("Error marking all notifications as unread:", error)
                setIsUnreadButtonLoading(false)
            })
    };

    // Function to render notification item
    const renderNotificationItem = (notification) => {
        const { id, actionType, cardID, isRead, timestamp, description } = notification;
        const Icon = iconsMap[notification.actionType] || NotificationsIcon;
        const isLoading = isNotificationIdLoading.includes(notification.id);
        
        const formattedTimestamp = timestamp.toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          });


        return (
            
            <ListItem
                divider
                key={notification.id}
                className={notification.isRead ? classes.readNotification : classes.unreadNotification}
                onClick={() => markAsRead(notification)}
            >
                <ListItemAvatar>
                    <Avatar
                        sx={{
                            backgroundColor: "primary.main",
                            color: "primary.contrastText",
                        }}
                    >
                        <Icon fontSize="small" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    disableTypography={true}
                    primary={
                        <Typography
                            variant="body1"
                            fontWeight={isRead ? "normal" : "bold"}
                        >
                            {actionType}
                        </Typography>
                    }
                    secondary={
                        <>
                            <Link
                                color="textPrimary"
                                sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" }, pt: 3 }}
                                variant="body2"
                                onClick={() => openCard(cardID)}
                            >
                                {description}
                            </Link>
                            <Box sx={{ display: "flex", flexDirection: "column", mt: 0.5 }}>
                                <Typography variant="caption" color="textSecondary">
                                    {formattedTimestamp}
                                </Typography>
                            </Box>
                        </>
                    }
                />
                {isLoading && (
                    <LinearProgress
                        variant="indeterminate"
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                    />
                )}
            </ListItem>
        )
    };
    
    // Display loader during tab change
    const SkeletonLoader = () => (
        <List disablePadding>
            {Array.from({ length: 5 }).map((_, index) => (
                <ListItem key={index} divider>
                    <ListItemAvatar>
                        <Avatar>
                            <Skeleton variant="circular" width={24} height={24} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        disableTypography={true}
                        primary={<Skeleton variant="text" width={100} height={24} />}
                        secondary={
                            <>
                                <Box sx={{ display: "flex", flexDirection: "column", mt: 0 }}>
                                    <Skeleton variant="text" width={180} height={18} />
                                    <Skeleton variant="text" width={150} height={18} />
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                                    <Skeleton variant="text" width={40} height={16} />
                                </Box>
                            </>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );

    return (
        <>
            {/* Badge to show the count of unread notifications */}
            <Badge
                color="error"
                badgeContent={unreadNotifications.length > 0 ? unreadNotifications.length : null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                overlap="circular"
            >
                <IconButton
                    color="inherit"
                    ref={anchorRef}
                    onClick={handleOpen}
                    size="medium"
                >
                    <NotificationsIcon />
                </IconButton>
            </Badge>
            
            
            {/* Popover component */}
            <Popover
                //disableEnforceFocus required to open Chat if PopoverOpenedFirst
                disableEnforceFocus
                anchorEl={anchorRef.current}
                anchorPosition={{
                    top: anchorRef.current ? anchorRef.current.getBoundingClientRect().bottom : 0,
                    left: anchorRef.current ? anchorRef.current.getBoundingClientRect().left : 0,
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={handleClose}
                open={open}
                PaperProps={{
                    style: {
                        maxWidth: "300px",
                        width: "100%",
                    },
                }}
            >
                
                {/* Close button */}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: useTheme().palette.mode === 'dark' ? "#f2bc53" : '#6b778c'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                
                <Box sx={{ p: 2, textAlign: "center" }}>
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        Notifications
                    </Typography>
                </Box>
                
                <Tabs value={activeTab} onChange={handleTabChange} centered>
                    <Tab label="All" />
                    <Tab label="Unread" />
                </Tabs>
                
                {/* Display the list of "All" notification items or the skeleton loader while loading */}
                {activeTab === 0 && (
                    <Box>
                        {isLoadingAllTab ? (
                            <SkeletonLoader />
                        ) : notifications.length > 0 ? (
                            <List disablePadding>
                                {notifications.map((notification) =>
                                    renderNotificationItem(notification)
                                )}
                            </List>
                        ) : (
                            <Box sx={{ p: 2, textAlign: "center" }}>
                                <Typography color="textPrimary" variant="subtitle2">
                                    There are no notifications
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
                
                {/* Display the list of "Unread" notification items or the skeleton loader while loading */}
                {activeTab === 1 && (
                    <Box>
                        {isLoadingUnreadTab ? (
                            <SkeletonLoader />
                        ) : unreadNotifications.length > 0 ? (
                            <List disablePadding>
                                {unreadNotifications.map((notification) =>
                                    renderNotificationItem(notification)
                                )}
                            </List>
                        ) : (
                            <Box sx={{ p: 2, textAlign: "center" }}>
                                <Typography color="textPrimary" variant="subtitle2">
                                    There are no unread notifications
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
                
                {/* "Mark all as read" and "Mark all as unread" button conditional rendering */}
                {activeTab === 0 && notifications.length > 0 && (
                    <>
                        {/* Render "Mark all as unread" button if there are ONLY read notifications */}
                        {notifications.every((notification) => notification.isRead) && (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2 }}>
                                {isUnreadButtonLoading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Button
                                        color="error"
                                        size="small"
                                        variant="outlined"
                                        onClick={markAllAsUnread}
                                    >
                                        Mark all as unread
                                    </Button>
                                )}
                            </Box>
                        )}
                        
                        {/* Render "Mark all as read" button if there are ONLY unread notifications */}
                        {notifications.every((notification) => !notification.isRead) && (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2 }}>
                                {isReadButtonLoading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Button
                                        color="error"
                                        size="small"
                                        variant="outlined"
                                        onClick={markAllAsRead}
                                    >
                                        Mark all as read
                                    </Button>
                                )}
                            </Box>
                        )}
                        
                        {/* Render both buttons with space between if there are both read and unread notifications */}
                        {!notifications.every((notification) => notification.isRead) && !notifications.every((notification) => !notification.isRead) && (
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 2 }}>
                                {notifications.some((notification) => !notification.isRead) && (
                                    <>
                                        {isReadButtonLoading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <Button
                                                color="error"
                                                size="small"
                                                variant="outlined"
                                                onClick={markAllAsRead}
                                            >
                                                Mark all as read
                                            </Button>
                                        )}
                                    </>
                                )}
                                {notifications.some((notification) => notification.isRead) && (
                                    <>
                                        {isUnreadButtonLoading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <Button
                                                color="error"
                                                size="small"
                                                variant="outlined"
                                                onClick={markAllAsUnread}
                                            >
                                                Mark all as unread
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Box>
                        )}
                    </>
                )}
                
                {activeTab === 1 && unreadNotifications.length > 0 && (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2 }}>
                        {isReadButtonLoading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Button
                                color="error"
                                size="small"
                                variant="outlined"
                                onClick={markAllAsRead}
                            >
                                Mark all as read
                            </Button>
                        )}
                    </Box>
                )}
            
            </Popover>
        </>
    )
};

export default NotificationsPopover;