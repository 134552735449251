import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDemoData } from '@mui/x-data-grid-generator';
import {FC, useState} from "react";
import {Card, CardContent, CardHeader, Divider, Grid, Typography} from "@mui/material";
import {Theme, useTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/styles";


interface TableProps {
    data: {
        category: string

        dataTop: {
            columns: any[],
            rows: any[]
        }
        dataBottom: {
            columns: any[],
            rows: any[]
        }
    }
}


const StatsTable: FC<TableProps> = (props) => {

    const [myTheme, setMyTheme] = useState<Theme>(useTheme())
    return (


            <Grid container spacing={1} height={'100%'}>
                <Grid item xs={12} md={6} height={'100%'} >
                    <Card style={{height:'100%'}}>
                        <CardHeader title={"Top 3 " + props.data.category}/>
                        <Divider/>
                        <CardContent style={{height:'100%'}}>
                            <DataGridPro
                                pagination
                                pageSize={3}
                                {...props.data.dataTop}
                                //loading={props.data.dataTop.rows.length === 0}
                                rowHeight={38}
                                // checkboxSelection
                                disableSelectionOnClick
                                rowsPerPageOptions={[3]}
                                experimentalFeatures={{ newEditingApi: true }}
                                autoHeight={true}
                            />
                        </CardContent>
                    </Card>

                </Grid>

                <Grid item xs={12} md={6} height={'100%'}>
                    <Card style={{height:'100%'}}>
                        <CardHeader title={"Bottom 3 " + props.data.category}/>
                        <Divider/>
                        <CardContent style={{height:'100%'}}>
                            <DataGridPro
                                pagination
                                pageSize={3}
                                {...props.data.dataBottom}
                                //loading={props.data.dataBottom.rows.length === 0}
                                rowHeight={38}
                                // checkboxSelection
                                disableSelectionOnClick
                                rowsPerPageOptions={[3]}
                                experimentalFeatures={{ newEditingApi: true }}
                                autoHeight={true}
                            />
                        </CardContent>
                    </Card>

                </Grid>

            </Grid>
    );
}

export default StatsTable;

