import React, {FC, useState} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress, TextField, Autocomplete
} from "@mui/material";
import { GoalTagStore } from "../../../../../../cog/keystone/goals";
import { useSnackbar } from "notistack";
import { useKeystoneContext } from "../../../../../../contexts/KeystoneContext";
import { useMainContext } from "../../../../../../contexts/MainContext";

interface AddTagsDialogProps {
  goalIDs: string[];
  handleDialogClose: () => void
}

const AddTagsDialog: FC<AddTagsDialogProps> = ({goalIDs, handleDialogClose}) => {
  const {enqueueSnackbar} = useSnackbar();
  const keystoneCtx = useKeystoneContext();
  const mainCtx = useMainContext();

  const [isDisabled, setIsDisabled] = useState(false)
  const [tags, setTags] = useState<string[]>(["testing", "failed recently", "first", "recurring"])
  const [selectedTags, setSelectedTags] = useState<string[]>([])

  const addTags = async () => {
    setIsDisabled(true)
    const response = await GoalTagStore.CreateMany({tags: selectedTags, goalIDs: goalIDs, clientName: mainCtx.activeOrganization.name})
    if (response) {
      setIsDisabled(false)
      enqueueSnackbar('Tags Added', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
    } else {
      setIsDisabled(false)
      enqueueSnackbar('Failed to Assign Users', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
    handleDialogClose();
    keystoneCtx.setFlipSwitchForGoals(!keystoneCtx.flipSwitchForGoals)
  }

  return (
    <Box sx={{ m: 2, minHeight: "15vh" }}>
      <DialogTitle>Add Tags</DialogTitle>
      <DialogContent>
        <Grid container display={"grid"} gridTemplateColumns={"1fr"} gridAutoFlow={"row"} gridAutoRows={"auto"} gap={1}>
          <Box sx={{ marginTop: 1 }}>
            <Autocomplete
              fullWidth
              multiple={true}
              id="tag"
              options={tags}
              getOptionLabel={(option) => option}
              onChange={(e, v) => {
                setSelectedTags([...v])
              }}
              value={selectedTags.map((a) => a)}
              renderInput={(params) => <TextField {...params}
                                                  label="Tags"/>}
            />
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant={"text"} >
          <Button
            onClick={addTags}
            disabled={isDisabled}
          >
            {isDisabled ? <CircularProgress size={20}/> : "Confirm"}
          </Button>
          <Button
            onClick={() => handleDialogClose()}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Box>
  );
}

export default AddTagsDialog;