import * as React from 'react';
import {alpha, styled, useTheme} from '@mui/material/styles';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import {InfoOutlined, PostAdd} from "@mui/icons-material";
import {useKeystoneContext} from "../../../../contexts/KeystoneContext";
import {Card} from "../../../../cog/keystone/card";


const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.text.primary,
        border: `1px ${theme.palette.background.default} solid`,
        boxShadow: theme.shadows[3],
        // 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

interface IssueMenuProps {
    // handleSelectCards: () => void;
    toggleDetailsOpen: () => void;
    oppCard: Card
}

export default function CustomizedMenus(props: IssueMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const keystoneCtx = useKeystoneContext()
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setAnchorEl(null);
    };

    return (
        <div>

            <InfoOutlined
                sx={{
                    ":hover": {
                        color: useTheme().palette.info.main
                    },
                    color: open ? useTheme().palette.info.main : useTheme().palette.text.primary
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    handleClick(e)
                }}
            ></InfoOutlined>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation()
                        e.nativeEvent.stopImmediatePropagation()
                        // console.log("e.target")
                        // console.log(e.target)
                        if (e.target != null) {
                            if (keystoneCtx.selectCards == false) {
                                keystoneCtx.setSelectCards(true)
                            }
                            // keystoneCtx.selectCards == false && keystoneCtx.handleSelectCards
                            keystoneCtx.updateSelectedCards(props.oppCard.id, props.oppCard.name)
                        }
                        handleClose(e)
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    disableRipple
                >
                    <EditIcon/>
                    Select Opportunity
                </MenuItem>
                <MenuItem
                    onClick={ (e)=>{
                        e.stopPropagation()
                        props.toggleDetailsOpen()
                        handleClose(e)
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    disableRipple
                >
                    <PostAdd/>
                    Show Details
                </MenuItem>
            </StyledMenu>
        </div>
    );
}