import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    CardHeader,
    Checkbox,
    CircularProgress,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {indexOf} from "lodash";
import {CheckBoxOutlineBlank, CheckBoxOutlined} from "@mui/icons-material";
import {Card as KanbanCardObj, KanbanStore} from "../../../../cog/keystone/card";
import {useMainContext} from "../../../../contexts/MainContext";
import { OppLabel } from "src/cog/keystone/oppLabel/OppLabel";

interface LabelDialogProps {
    theIssue: KanbanCardObj;
    labelOptions: OppLabel[];
    onClick: ()=>void;
    setIssueLabels?: ()=>void;

}



const LabelDialog: FC<LabelDialogProps> = ({theIssue, onClick, labelOptions,setIssueLabels}) => {
    const mainCtx = useMainContext();

    const [opportunityLabels, setOpportunityLabels] = useState<OppLabel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function handleOnChange(event, newValue) {
        setOpportunityLabels(newValue)

    }
    // This one is used to establish the defa
    useLayoutEffect(() => {
        // console.log("Opp labels in the thing", labelOptions)
        // console.log("issue label in the thing", theIssue.opportunityLabels)

        try {
            JSON.parse(`{"OppLabels": ${theIssue.opportunityLabels} }`)
        } catch (e) {
            // cannot parse
            theIssue.opportunityLabels = ""
        }
        if (theIssue.opportunityLabels != "") {
            let tempLabels = []

            Object.values(labelOptions).map((item) => {
                if (indexOf(JSON.parse(`{"OppLabels": ${theIssue.opportunityLabels} }`)['OppLabels'], item.id) != -1) {
                    tempLabels.push(item)
                }

            })
            //console.log("tempLabels", tempLabels)
            setOpportunityLabels(tempLabels)
        } else {
            setOpportunityLabels([])
        }


    }, []);

    useEffect(() => {
        console.log("Label Dialog ")
    }, [theIssue.opportunityLabels]);

    // useEffect(() => {
    //     // PrintEverything("Start of second Use effect")
    //     if (memberOptions != null && usersToAssign != null) {
    //         setIsLoading(false)
    //     }
    //     // PrintEverything("End of second Use effect")
    // }, [memberOptions, usersToAssign])

    return (
        <Box>
            <DialogTitle>Labels</DialogTitle>
            <DialogContent>

                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item sx={{
                            mt: 1,
                            mb: 5,
                            flex: 1

                        }}>
                            <Autocomplete
                                value={opportunityLabels}
                                onChange={(event, newValue) => {
                                    // @ts-ignore
                                    setOpportunityLabels(newValue);
                                    handleOnChange(event, newValue);
                                }}
                                multiple
                                disabled={isLoading}
                                id="checkboxes-tags-demo"
                                limitTags={2}
                                options={labelOptions}
                                defaultValue={theIssue.opportunityLabels != "" ?
                                    labelOptions.map((item) => {
                                        try {
                                            if(indexOf(JSON.parse(`{"OppLabels": ${theIssue.opportunityLabels} }`)['OppLabels'], item.id) != -1) {
                                                return item
                                            }
                                        }
                                        catch (e){

                                        }

                                    }) : [] }
                                disableCloseOnSelect
                                // @ts-ignore
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBoxOutlined fontSize="small" />}
                                            checked={selected}
                                        />
                                        <CardHeader
                                            title={option.label} disableTypography={true}
                                            sx={{padding: 0}}
                                            key={`OppLabel_${option.id}`}
                                        >
                                        </CardHeader>
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Labels" placeholder="" value={opportunityLabels}  />
                                )}
                            />
                        </Grid>
                    </Grid>
                <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
                    {isLoading? <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            gap: "10px",
                            px: 0.2}}>
                            <Typography sx={{pt:1}}>Saving</Typography>
                            <CircularProgress/>
                        </Box>
                        :
                        <ButtonGroup>
                            <Button
                                onClick={() => {
                                    setIsLoading(true)
                                    KanbanStore.AssignMultiLabels({
                                        oppLabelIDs: opportunityLabels.map((v: any) => {
                                            return v.id
                                        }),
                                        cardIDs: [theIssue.id],
                                        client: mainCtx.activeOrganization.name,
                                        userID: mainCtx.user.id
                                    }).then((p) => {
                                        // TODO: Needs to look at context to find card assignees
                                        // NotificationStore.CreateOne({
                                        //     ClientName: mainCtx.activeOrganization.name,
                                        //     UserId: mainCtx.user.id,
                                        //     CardId: theIssue.id,
                                        //     ActionType: "Updated",
                                        //     Description: "",
                                        //     Platform: "Internal",
                                        //     IsRead: false
                                        // })
                                        theIssue.opportunityLabels = p.toString()
                                        if(typeof setIssueLabels != 'undefined'){
                                            setIssueLabels()
                                        }

                                        setIsLoading(false)
                                        onClick()
                                    })

                                }}
                                variant="text"
                                disabled={isLoading}
                            >
                                Save Changes
                            </Button>
                            <Button variant="text" onClick={onClick}>
                                Close
                            </Button>
                        </ButtonGroup>
                    }
                </Box>
            </DialogContent>
        </Box>
    );
}

export default LabelDialog;